"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RightsManagerClient = void 0;
var CommandReadModels_1 = require("../../server/webserver/commands/CommandReadModels");
var RightsManagerClient = function () {
  function RightsManagerClient() {
    (0, _classCallCheck2.default)(this, RightsManagerClient);
  }
  return (0, _createClass2.default)(RightsManagerClient, [{
    key: "isCompanyAdmin",
    value: function () {
      var _isCompanyAdmin = (0, _asyncToGenerator2.default)(function* (userId, companyId) {
        var c = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `userId eq '${userId}' and companyId eq '${companyId}' and role eq 'admin' and deleted ne true`
        });
        return c.length > 0;
      });
      function isCompanyAdmin(_x, _x2) {
        return _isCompanyAdmin.apply(this, arguments);
      }
      return isCompanyAdmin;
    }()
  }, {
    key: "hasWriteRight",
    value: function () {
      var _hasWriteRight = (0, _asyncToGenerator2.default)(function* (projectId, userId, right) {
        var role = yield this.getRoleForUserInProject(userId, projectId);
        if (role != null && role.writeRights[right]) {
          return true;
        }
        try {
          var project = yield CommandReadModels_1.CommandReadModels.project.getById(projectId);
          return project.creator === userId;
        } catch (_e) {
          return false;
        }
      });
      function hasWriteRight(_x3, _x4, _x5) {
        return _hasWriteRight.apply(this, arguments);
      }
      return hasWriteRight;
    }()
  }, {
    key: "hasReadRight",
    value: function () {
      var _hasReadRight = (0, _asyncToGenerator2.default)(function* (projectId, userId, right) {
        var role = yield this.getRoleForUserInProject(userId, projectId);
        if (role != null && (role.readRights[right] == null || role.readRights[right])) {
          return true;
        }
        try {
          var project = yield CommandReadModels_1.CommandReadModels.project.getById(projectId);
          return project.creator === userId;
        } catch (_e) {
          return false;
        }
      });
      function hasReadRight(_x6, _x7, _x8) {
        return _hasReadRight.apply(this, arguments);
      }
      return hasReadRight;
    }()
  }, {
    key: "getRoleForUserInProject",
    value: function () {
      var _getRoleForUserInProject = (0, _asyncToGenerator2.default)(function* (userId, projectId) {
        try {
          var getUser = yield CommandReadModels_1.CommandReadModels.projectUser.get({
            filter: `userId eq '${userId}' and projectId eq '${projectId}' and status eq 'accepted'`
          });
          if (getUser.length > 0) return yield CommandReadModels_1.CommandReadModels.projectRoles.getById(getUser[0].roleId);
        } catch (_e) {
          return undefined;
        }
        return undefined;
      });
      function getRoleForUserInProject(_x9, _x10) {
        return _getRoleForUserInProject.apply(this, arguments);
      }
      return getRoleForUserInProject;
    }()
  }, {
    key: "hasVersion",
    value: function () {
      var _hasVersion = (0, _asyncToGenerator2.default)(function* (userId) {
        var getSub = yield CommandReadModels_1.CommandReadModels.subscription.get({
          filter: `userId eq '${userId}' and subscription eq 'pro' and expireDate gt ${new Date().toISOString()} and locked ne true`,
          orderby: 'createdAt DESC'
        });
        var hasPro = getSub.length > 0;
        var companies = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `userId eq '${userId}' and deleted ne true and (role eq 'admin' or role eq 'user' or role eq 'collaborator')`
        });
        var hasEnterprise = companies.length > 0;
        if (hasPro && hasEnterprise) return 'proAndEnterprise';
        if (hasPro) return 'pro';
        if (hasEnterprise) return 'enterprise';
        return 'basic';
      });
      function hasVersion(_x11) {
        return _hasVersion.apply(this, arguments);
      }
      return hasVersion;
    }()
  }]);
}();
exports.RightsManagerClient = RightsManagerClient;
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
export class JournalSettingsAdditionalFields extends PureComponent {
    constructor(props) {
        super(props);
        this.addField = () => {
            const { onChanged } = this.props;
            const { additionalFields } = this.state;
            const fields = [...additionalFields];
            fields.push({ name: '', id: uuid.v1(), active: true });
            this.setState({ additionalFields: fields }, () => {
                onChanged(fields);
                this.forceUpdate();
            });
        };
        this.onChangeFieldName = (i, text) => {
            const { onChanged } = this.props;
            const { additionalFields } = this.state;
            const fields = [...additionalFields];
            const field = fields[i];
            fields[i] = { active: field.active, id: field.id, name: text };
            this.setState({ additionalFields: fields }, () => onChanged(fields));
        };
        this.onPressIcon = (i) => {
            const { onChanged } = this.props;
            const { additionalFields } = this.state;
            const fields = [...additionalFields];
            const field = fields[i];
            fields[i] = { active: !field.active, id: field.id, name: field.name };
            this.setState({ additionalFields: fields }, () => onChanged(fields));
        };
        this.state = {
            additionalFields: props.additionalFields != null ? props.additionalFields.slice() : [],
        };
    }
    render() {
        const { additionalFields } = this.state;
        const fields = [];
        if (additionalFields != null) {
            for (let i = 0; i < additionalFields.length; i += 1) {
                fields.push(<View key={`field_${i}`} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              <FormInputFilled key={`fi${i}}`} labelText={I18n.m.getMessage('journalSettingsAdditionalFieldName')} value={additionalFields[i].name} onChange={(text) => this.onChangeFieldName(i, text)}/>
            </View>
            <Icon toolTip={!additionalFields[i].active
                        ? I18n.m.getMessage('journalSettingsFieldActivate')
                        : I18n.m.getMessage('journalSettingsFieldDeactivate')} icon={!additionalFields[i].active ? 'eye-off-outline' : 'eye-outline'} onPress={() => this.onPressIcon(i)}/>
          </View>);
            }
        }
        return (<View key={`additionalFields${fields.length}`}>
        <View style={{
                height: 0,
                width: '100%',
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                marginTop: ThemeManager.style.contentPaddingValue,
                marginBottom: ThemeManager.style.contentPaddingValue + 21,
            }}/>
        <MaterialText type={MaterialTextTypes.Body1} strong>
          {I18n.m.getMessage('journalAdditionalFields')}
        </MaterialText>
        <View style={{ height: 8 }}/>
        {fields}
        <ContainedButton icon={{ icon: 'plus', toolTip: '' }} title={I18n.m.getMessage('journalSettingsAdditionalField')} onPress={this.addField}/>
      </View>);
    }
}

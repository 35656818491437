import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../i18n/I18n';
export class NoRights extends PureComponent {
    render() {
        const { error } = this.props;
        const mes = I18n.m.getMessage(error);
        return (<View>
        <View style={{
                width: '100%',
                paddingTop: 48,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
          <MaterialText centeredBox centeredText type={MaterialTextTypes.H5}>
            {mes}
          </MaterialText>
          <View style={{ height: 16 }}/>
        </View>
      </View>);
    }
}
NoRights.defaultProps = {
    error: 'forbiddenCommand',
};

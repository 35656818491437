var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialTextTypes = exports.MaterialText = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _reactNativeHyperlink = _interopRequireDefault(require("react-native-hyperlink"));
var _ThemeManager = require("../../ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var _require = require('abortcontroller-polyfill/dist/cjs-ponyfill'),
  AbortController = _require.AbortController;
var MaterialTextTypes;
(function (MaterialTextTypes) {
  MaterialTextTypes[MaterialTextTypes["Body1"] = 0] = "Body1";
  MaterialTextTypes[MaterialTextTypes["Body2"] = 1] = "Body2";
  MaterialTextTypes[MaterialTextTypes["Button"] = 2] = "Button";
  MaterialTextTypes[MaterialTextTypes["H1"] = 3] = "H1";
  MaterialTextTypes[MaterialTextTypes["H2"] = 4] = "H2";
  MaterialTextTypes[MaterialTextTypes["H3"] = 5] = "H3";
  MaterialTextTypes[MaterialTextTypes["H4"] = 6] = "H4";
  MaterialTextTypes[MaterialTextTypes["H5"] = 7] = "H5";
  MaterialTextTypes[MaterialTextTypes["H6"] = 8] = "H6";
  MaterialTextTypes[MaterialTextTypes["Subtitle1"] = 9] = "Subtitle1";
  MaterialTextTypes[MaterialTextTypes["Subtitle2"] = 10] = "Subtitle2";
  MaterialTextTypes[MaterialTextTypes["Overline"] = 11] = "Overline";
  MaterialTextTypes[MaterialTextTypes["Caption"] = 12] = "Caption";
})(MaterialTextTypes || (exports.MaterialTextTypes = MaterialTextTypes = {}));
var MaterialText = exports.MaterialText = function (_PureComponent) {
  function MaterialText(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, MaterialText);
    _this = _callSuper(this, MaterialText, [props, context]);
    _this.controller = new AbortController();
    _this.state = {
      tooltip: undefined,
      ellipsize: true
    };
    return _this;
  }
  (0, _inherits2.default)(MaterialText, _PureComponent);
  return (0, _createClass2.default)(MaterialText, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.controller.abort();
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        type = _this$props.type,
        centeredText = _this$props.centeredText,
        textPadding = _this$props.textPadding,
        centeredBox = _this$props.centeredBox,
        color = _this$props.color,
        strong = _this$props.strong,
        backgroundColor = _this$props.backgroundColor,
        fontSize = _this$props.fontSize,
        fixedWidth = _this$props.fixedWidth,
        textAlign = _this$props.textAlign,
        onPress = _this$props.onPress;
      var textStyle = MaterialText.getStyleForType(type, color, backgroundColor, centeredText, centeredBox, textPadding, strong, fontSize, fixedWidth, textAlign);
      return this.getTextObject(textStyle, onPress);
    }
  }, {
    key: "getTextObject",
    value: function getTextObject(textStyle, onPress) {
      var _this$props2 = this.props,
        recognizeUrl = _this$props2.recognizeUrl,
        children = _this$props2.children,
        additionalTextStyle = _this$props2.additionalTextStyle;
      if (_reactNativeWeb.Platform.OS === 'web' && this.props.numberOfLines === 1) {
        textStyle.whiteSpace = 'nowrap';
        textStyle.padding = 0;
      }
      var t = (0, _jsxRuntime.jsx)(_reactNativeWeb.Text, {
        onPress: onPress,
        ellipsizeMode: !this.state.ellipsize ? 'clip' : this.props.ellipsizeMode,
        style: additionalTextStyle != null ? Object.assign({}, textStyle, additionalTextStyle) : textStyle,
        numberOfLines: this.props.numberOfLines,
        allowFontScaling: this.props.allowFontScaling,
        adjustsFontSizeToFit: this.props.adjustsFontSizeToFit,
        children: children
      });
      return recognizeUrl ? (0, _jsxRuntime.jsx)(_reactNativeHyperlink.default, {
        linkDefault: true,
        children: t
      }) : t;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        type: MaterialTextTypes.Body1,
        textPadding: 2,
        centeredText: false,
        centeredBox: false,
        color: _ThemeManager.ThemeManager.style.defaultTextColor,
        backgroundColor: 'transparent',
        numberOfLines: undefined,
        ellipsizeMode: 'tail',
        allowFontScaling: false,
        adjustsFontSizeToFit: false,
        recognizeUrl: false
      };
    }
  }, {
    key: "getStyleForType",
    value: function getStyleForType(type) {
      var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _ThemeManager.ThemeManager.style.defaultTextColor;
      var backgroundColor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'transparent';
      var centeredText = arguments.length > 3 ? arguments[3] : undefined;
      var centerBlock = arguments.length > 4 ? arguments[4] : undefined;
      var textPadding = arguments.length > 5 ? arguments[5] : undefined;
      var bold = arguments.length > 6 ? arguments[6] : undefined;
      var fontSize = arguments.length > 7 ? arguments[7] : undefined;
      var width = arguments.length > 8 ? arguments[8] : undefined;
      var textAlign = arguments.length > 9 ? arguments[9] : undefined;
      var textStyle = [_ThemeManager.ThemeManager.style.text];
      if (type == null) {
        textStyle.push(_ThemeManager.ThemeManager.style.Body1);
      } else if (_ThemeManager.ThemeManager.style[MaterialTextTypes[type]] != null) {
        textStyle.push(_ThemeManager.ThemeManager.style[MaterialTextTypes[type]]);
      } else {
        textStyle.push(_ThemeManager.ThemeManager.style.Body1);
      }
      textStyle.push({
        textAlign: textAlign != null ? textAlign : centeredText ? 'center' : 'left'
      });
      textStyle.push({
        alignSelf: centerBlock ? 'center' : 'flex-start'
      });
      if (bold === true) {
        textStyle.push({
          fontWeight: 'bold'
        });
      }
      if (textPadding != null) {
        textStyle.push({
          padding: textPadding
        });
      }
      if (backgroundColor != null) {
        textStyle.push({
          backgroundColor: backgroundColor
        });
      }
      if (fontSize != null) {
        textStyle.push({
          fontSize: fontSize,
          lineHeight: fontSize * 1.2
        });
      }
      textStyle.push({
        color: color
      });
      if (width != null) {
        textStyle.push({
          width: width
        });
      }
      textStyle.push({
        maxWidth: '100%'
      });
      var returnedObject = textStyle.reduce(function (obj, item) {
        var propsCollector = Object.assign({}, obj, item);
        return propsCollector;
      }, {});
      if (returnedObject.lineHeight == null && returnedObject.fontSize != null) {
        returnedObject.lineHeight = returnedObject.fontSize * 1.2;
      }
      return returnedObject;
    }
  }]);
}(_react.PureComponent);
import { View, FlatList } from 'react-native';
import React, { useRef } from 'react';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { DropArea } from 'materialTheme/src/theme/components/dragndrop/DropArea';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import * as uuid from 'uuid';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { compareDates, getItemKey, getKW, getOrderedWeekDays, isS } from './calendarUtil';
export default function MonthCalendar(props) {
    const { currentDate, iosContentInset, onDrop, renderTicketName, width, height, noDateWidth, setNewDate, hideRightBorder, getTicketsForDate, } = props;
    const weekdayNamesShort = useRef(getOrderedWeekDays());
    const noDateWidth2 = isS() ? 0 : noDateWidth;
    const { startWithMonday, showKW } = CurrentUser.settings;
    const firstDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let firstDay = firstDayDate.getDay();
    if (firstDay < (startWithMonday ? 1 : 0))
        firstDay += 7;
    const dayWidth = (width - noDateWidth2 - 34 - (showKW ? 20 : 0)) / 7;
    const dayHeight = (height - 184) / 6;
    const weeks = [];
    weeks.push(<View key={weekdayNamesShort.current.join(',')} style={{
            flexDirection: 'row',
            paddingLeft: showKW ? 20 : 0,
            height: 40,
            borderBottomWidth: 0.5,
            borderColor: '#ccc',
        }}>
      {weekdayNamesShort.current.map((weekday, i) => (<View key={weekday.slice(0, 2)} style={{
                justifyContent: 'center',
                height: '100%',
                borderLeftWidth: i === 0 && !showKW ? 0 : 0.5,
                borderRightWidth: i === 6 && !hideRightBorder ? 0.5 : 0,
                borderColor: '#ccc',
                width: dayWidth,
            }}>
          <View>
            <MaterialText fixedWidth={dayWidth} textAlign="left">
              {weekday.slice(0, 2)}
            </MaterialText>
          </View>
        </View>))}
    </View>);
    const firstKW = getKW(firstDayDate);
    let week = showKW
        ? [
            <Ripple key={`kw_${firstDayDate.getFullYear()}_${firstDayDate.getMonth()}_${firstDayDate.getDate()}`} style={{
                    height: dayHeight,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    borderColor: '#ccc',
                    borderBottomWidth: 0.5,
                }} onPress={() => setNewDate(firstDayDate, 'week')}>
          <MaterialText fontSize={10} textAlign="right" fixedWidth={20} color="#555">
            {firstKW}
          </MaterialText>
        </Ripple>,
        ]
        : [];
    for (let i = 0; i < firstDay - (startWithMonday ? 1 : 0); i += 1) {
        const dateOfDay = new Date(firstDayDate.getTime());
        dateOfDay.setDate(dateOfDay.getDate() - firstDay + i + (startWithMonday ? 1 : 0));
        const filteredTickets = getTicketsForDate(dateOfDay);
        week.push(<View key={uuid.v1()} style={{ backgroundColor: ThemeManager.style.brandPrimary }}>
        <DropArea dropFilter={() => true} onDrop={onDrop(new Date(dateOfDay.getFullYear(), dateOfDay.getMonth(), dateOfDay.getDate(), 0, 0, 0, 0))} zIndex={1}>
          <View style={{
                backgroundColor: ThemeManager.style.appBgColor,
                borderLeftWidth: 0.5,
                borderBottomWidth: 0.5,
                borderColor: '#ccc',
                width: dayWidth,
                height: dayHeight,
            }}>
            <View style={{
                opacity: 0.5,
                height: '100%',
            }}>
              <MaterialText color="#777" textAlign="left" fixedWidth="100%">
                {dateOfDay.getDate()}
              </MaterialText>
              {filteredTickets.length > 0 && (<FlatList style={{ height: 0 }} key="ticketList" keyExtractor={getItemKey(true)} renderItem={renderTicketName} numColumns={1} data={filteredTickets} contentContainerStyle={{ padding: 2, width: '100%', alignSelf: 'center' }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>)}
            </View>
          </View>
        </DropArea>
      </View>);
    }
    let n = 0;
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const today = new Date();
    const toCompare = new Date(currentDate.getTime());
    while (daysInMonth > n) {
        n += 1;
        const filteredTickets = getTicketsForDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), n));
        toCompare.setDate(n);
        week.push(<View key={uuid.v1()} style={{ backgroundColor: ThemeManager.style.brandPrimary }}>
        <DropArea dropFilter={() => true} onDrop={onDrop(new Date(currentDate.getFullYear(), currentDate.getMonth(), n, 0, 0, 0, 0))} zIndex={1}>
          <View style={{ backgroundColor: ThemeManager.style.appBgColor }}>
            <View style={{
                width: dayWidth,
                height: dayHeight,
                borderLeftWidth: 0.5,
                borderRightWidth: week.length === (showKW ? 7 : 6) && !hideRightBorder ? 0.5 : 0,
                borderBottomWidth: weeks.length !== 6 ? 0.5 : 0,
                borderColor: '#ccc',
                backgroundColor: compareDates(today, toCompare) ? 'rgba(0, 115, 255, 0.05)' : '#f8f8f8',
            }}>
              <MaterialText textAlign="left" fixedWidth="100%" additionalTextStyle={{
                textDecorationLine: compareDates(today, toCompare) ? 'underline' : 'none',
            }}>
                {n}
              </MaterialText>
              {filteredTickets.length > 0 && (<FlatList style={{ height: 0 }} key="ticketList" keyExtractor={getItemKey(true)} renderItem={renderTicketName} numColumns={1} data={filteredTickets} contentContainerStyle={{ padding: 2, width: '100%', alignSelf: 'center' }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>)}
            </View>
          </View>
        </DropArea>
      </View>);
        if (week.length >= (showKW ? 8 : 7)) {
            weeks.push(<View key={uuid.v1()} style={{ flexDirection: 'row' }}>
          {week}
        </View>);
            const nextDay = new Date(toCompare);
            nextDay.setDate(nextDay.getDate() + 2);
            week = showKW
                ? [
                    <Ripple key={`kwNumber${getKW(nextDay)}`} style={{
                            height: dayHeight,
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            borderBottomWidth: weeks.length !== 6 ? 0.5 : 0,
                            borderColor: '#ccc',
                        }} onPress={() => setNewDate(nextDay, 'week')}>
              <MaterialText fontSize={10} textAlign="right" fixedWidth={20} color="#555">
                {getKW(nextDay)}
              </MaterialText>
            </Ripple>,
                ]
                : [];
        }
    }
    if (week.length > (showKW ? 1 : 0)) {
        let i = 1;
        while (week.length < (showKW ? 8 : 7)) {
            const dateOfDay = new Date(firstDayDate.getTime());
            dateOfDay.setMonth(dateOfDay.getMonth() + 1);
            dateOfDay.setDate(i);
            const filteredTickets = getTicketsForDate(dateOfDay);
            week.push(<View key={uuid.v1()} style={{ backgroundColor: ThemeManager.style.brandPrimary }}>
          <DropArea dropFilter={() => true} onDrop={onDrop(new Date(dateOfDay.getFullYear(), dateOfDay.getMonth(), dateOfDay.getDate(), 0, 0, 0, 0))} zIndex={1}>
            <View style={{
                    backgroundColor: ThemeManager.style.appBgColor,
                    width: dayWidth,
                    height: dayHeight,
                    borderLeftWidth: 0.5,
                    borderRightWidth: week.length === (showKW ? 7 : 6) && !hideRightBorder ? 0.5 : 0,
                    borderBottomWidth: weeks.length !== 6 ? 0.5 : 0,
                    borderColor: '#ddd',
                    borderLeftColor: i === 1 ? '#ccc' : undefined,
                }}>
              <View style={{
                    opacity: 0.5,
                    height: '100%',
                }}>
                <MaterialText textAlign="left" fixedWidth="100%" color="#777">
                  {dateOfDay.getDate()}
                </MaterialText>
                {filteredTickets.length > 0 && (<FlatList style={{ height: 0, marginTop: 16 }} keyExtractor={getItemKey(true)} renderItem={renderTicketName} numColumns={1} data={filteredTickets} contentContainerStyle={{ padding: 2, width: '100%', alignSelf: 'center' }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>)}
              </View>
            </View>
          </DropArea>
        </View>);
            i += 1;
        }
        weeks.push(<View key={uuid.v1()} style={{ flexDirection: 'row' }}>
        {week}
      </View>);
    }
    const rowHeight = dayHeight;
    return (<View key={`MonthCalendar${currentDate}`} style={{
            backgroundColor: ThemeManager.style.appBgColor,
            flexDirection: 'column',
            height: height - 144,
            overflow: 'hidden',
        }}>
      {weeks.map((w, i) => {
            return (<View style={{ height: i > 0 ? rowHeight : 40 }} key={uuid.v1()}>
            {w}
          </View>);
        })}
    </View>);
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteCompanyProjectState = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CompanyProjectStateDeleted_1 = require("../../../../server/events/companies/projectstates/CompanyProjectStateDeleted");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var DeleteCompanyProjectState = function (_CommandRoot_1$Comman) {
  function DeleteCompanyProjectState(data, companyId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteCompanyProjectState);
    _this = _callSuper(this, DeleteCompanyProjectState, [data, companyId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'DeleteCompanyProjectState';
    return _this;
  }
  (0, _inherits2.default)(DeleteCompanyProjectState, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteCompanyProjectState, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.entityId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var settings = ReadModels_1.ReadModels.get('CompanySettings');
        try {
          var current = yield settings.getById(this.entityId);
          var myState = current.projectStateNames.find(function (elem) {
            return elem.id === _this2.data.id;
          });
          if (myState != null) {
            return new CompanyProjectStateDeleted_1.CompanyProjectStateDeleted(this.entityId, this.data);
          }
        } catch (e) {}
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var errors = [];
        var companyProjectInfo = ReadModels_1.ReadModels.get('CompanyProjectInfo');
        var hasCorresponding = yield companyProjectInfo.get({
          filter: `projectStateId eq '${this.data.id}' and deleted ne true`
        });
        if (hasCorresponding != null && hasCorresponding.length > 0) {
          errors.push({
            key: 'id',
            messageCode: 'badRequest',
            message: 'Status wird in einem Projekt verwendet und kann nicht gelöscht werden'
          });
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteCompanyProjectState = DeleteCompanyProjectState;
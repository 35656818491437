import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../i18n/I18n';
export class FileInfoDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { init: false };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant initfile upload', err));
    }
    async init() {
        const { file } = this.props;
        let project;
        let ticket;
        let folder;
        if (file.data && file.data['projectId']) {
            try {
                project = await UpmeshClient.instance.modals.project.getById(file.data['projectId']);
            }
            catch (e) {
                console.debug('cant get project', e);
            }
        }
        if (file.data && file.data['forEntityId'] && file.data['forEntity'] === 'Ticket') {
            try {
                ticket = await UpmeshClient.instance.modals.ticket.getById(file.data['forEntityId']);
            }
            catch (e) {
                console.debug('cant get project', e);
            }
        }
        else if (file.data && file.data['folder']) {
            try {
                if (file.data['folder'] === 'public') {
                    folder = new FolderEntity({
                        id: '',
                        title: I18n.m.getMessage('filesFolderPublic'),
                        projectId: file.data['projectId'],
                    });
                }
                else {
                    folder = await UpmeshClient.instance.modals.folder.getById(file.data['folder']);
                }
            }
            catch (e) {
                console.debug('cant get project', e);
            }
        }
        else {
            folder = new FolderEntity({
                id: 'private',
                title: I18n.m.getMessage('filesFolderPrivate'),
                projectId: file.data['projectId'],
            });
        }
        this.setState({ project, ticket, folder, init: true });
    }
    goTo(link) {
        Dialog.instance?.close(() => {
            requestAnimationFrame(() => {
                Routing.instance.goTo(link);
            });
        });
    }
    render() {
        const { file } = this.props;
        const { project, ticket, folder, init } = this.state;
        if (!init)
            return (<View>
          <Spinner />
        </View>);
        const content = [];
        if (project)
            content.push(<View key="project" style={{ paddingBottom: 8 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('project')}</MaterialText>
          <MaterialText onPress={() => this.goTo(`/projects/${project.id}/project`)}>{project.title}</MaterialText>
        </View>);
        if (ticket)
            content.push(<View key="ticket" style={{ paddingBottom: 8 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('ticket')}</MaterialText>
          <MaterialText onPress={() => this.goTo(`/projects/${ticket.projectId}/tickets?d=ticket&data=id%3D${ticket.id}`)}>
            {ticket.title}
          </MaterialText>
        </View>);
        if (folder)
            content.push(<View key="folder" style={{ paddingBottom: 8 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('folderName')}</MaterialText>
          <MaterialText onPress={() => this.goTo(`/projects/${folder.projectId}/files/${folder.id}`)}>
            {folder.title}
          </MaterialText>
        </View>);
        return (<View>
        <View style={{ paddingBottom: 8 }}>
          <MaterialText type={MaterialTextTypes.H6}>{file.name}</MaterialText>
        </View>
        {content}
      </View>);
    }
}
FileInfoDialog.open = (f) => {
    Dialog.instance?.open({
        fullscreenResponsive: true,
        showCloseIcon: true,
        content: <FileInfoDialog file={f}/>,
    });
};

import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { MapMarker } from 'materialTheme/src/theme/components/zoom/MapMarker';
import { ZoomTile } from 'materialTheme/src/theme/components/zoom/ZoomTile';
import { ZoomTileHelper } from 'materialTheme/src/theme/components/zoom/ZoomTileHelper';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from './CompanyUserInfo';
export function ProjectsOnMap(props) {
    const [marker, setMarker] = useState([]);
    const getAddressEqual = (a, b) => {
        return a.latitude === b.latitude && a.longitude === b.longitude;
    };
    const openProjectSelection = (e, addresses, projects) => {
        const x = e.originalEvent?.pageX != null ? e.originalEvent.pageX : e.properties.screenPointX;
        const y = e.originalEvent?.pageY != null ? e.originalEvent.pageY : e.properties.screenPointY;
        const client = {
            height: 0,
            width: 300,
            x,
            y,
        };
        const items = addresses.map((a) => ({
            title: projects.find((p) => p.id === a.projectId)?.title || '',
            onPress: () => Routing.instance.goTo(`/projects/${a.projectId}/${StoredStartPages.getStoredProjectStartPage()}`),
        }));
        MenuRaw.instance?.open({
            client,
            items,
        });
    };
    const loadAddresses = async () => {
        await DateLocale.waitFor(() => UpmeshClient.instance.ready, 100, 10000);
        const { projects } = props;
        const projectIds = projects.map((p) => p.id);
        let addresses = await UpmeshClient.instance.modals.projectAddress.get({
            filter: `(projectId in ${JSON.stringify(projectIds)}) and type eq 'constructionSite' and deleted ne true`,
        });
        addresses = addresses.filter((a) => {
            const address = new AddressEntity(a.address);
            return address.addressInput != null && address.addressInput.length > 0;
        });
        const singleAddresses = [];
        const doubledAddresses = [];
        addresses.forEach((add) => {
            const index = singleAddresses.findIndex((a) => getAddressEqual(a.address, add.address));
            if (index >= 0) {
                const doubledAddress = doubledAddresses.find((d) => getAddressEqual(d[0].address, add.address)) || [];
                doubledAddress.push(add);
                doubledAddress.push(singleAddresses[index]);
                doubledAddresses.push(doubledAddress);
                singleAddresses.splice(index, 1);
            }
            else {
                const dIndex = doubledAddresses.findIndex((d) => getAddressEqual(d[0].address, add.address));
                if (dIndex >= 0) {
                    doubledAddresses[dIndex].push(add);
                }
                else
                    singleAddresses.push(add);
            }
        });
        let marker = [];
        for (const a of singleAddresses) {
            const project = projects.find((p) => p.id === a.projectId);
            let color = project && project.color ? project.color : 'blue';
            try {
                if (project) {
                    const projectInfo = await UpmeshClient.instance.modals.companyProjectInfo.get({
                        filter: `projectId eq '${project.id}' and companyId eq '${CompanyUserInfo.companySettings?.id}' and deleted ne true`,
                    });
                    if (projectInfo != null && projectInfo.length > 0) {
                        const pState = CompanyUserInfo.companySettings?.projectStateNames.find((elem) => elem.id === projectInfo[0].projectStateId);
                        if (pState != null) {
                            color = pState.color;
                        }
                    }
                }
            }
            catch (err) {
                console.error('could not load company project info');
            }
            marker.push(new MapMarker(a.projectId, color, { x: a.address.longitude, y: a.address.latitude }, <MaterialText>{projects.find((p) => p.id === a.projectId)?.title}</MaterialText>, () => Routing.instance.goTo(`/projects/${a.projectId}/${StoredStartPages.getStoredProjectStartPage()}`)));
        }
        marker = marker.concat(doubledAddresses.map((d, index) => new MapMarker(index.toString(), 'blue', { x: d[0].address.longitude, y: d[0].address.latitude }, <MaterialText>{d.length + I18n.m.getMessage('projects')}</MaterialText>, (e) => openProjectSelection(e, d, projects))));
        setMarker(marker);
    };
    useEffect(() => {
        loadAddresses().catch((err) => console.debug('cant load adresses', err));
    }, [props.projects]);
    const getZoomLevel = () => {
        let center = { x: 0, y: 0 };
        let zoomLevel = 15;
        if (marker != null && marker.length > 0) {
            if (marker.length === 1) {
                center = marker[0].position;
                zoomLevel = 16;
            }
            else {
                const points = [];
                let biggestX = marker[0].position.x;
                let biggestY = marker[0].position.y;
                let smallestX = marker[0].position.x;
                let smallestY = marker[0].position.y;
                marker.forEach((marker) => {
                    const m = marker.position;
                    points.push([m.x, m.y]);
                    if (m.x > biggestX)
                        biggestX = m.x;
                    if (m.y > biggestY)
                        biggestY = m.y;
                    if (m.x < smallestX)
                        smallestX = m.x;
                    if (m.y < smallestY)
                        smallestY = m.y;
                });
                const getCenterOfPoints = ZoomTileHelper.getCenterOfPoints(points);
                center = { x: getCenterOfPoints[1], y: getCenterOfPoints[0] };
                const diffX = biggestX - smallestX;
                const diffY = biggestY - smallestY;
                const zoomLevelX = Math.log(360 / diffX) / Math.LN2;
                const zoomLevelY = Math.log(360 / diffY) / Math.LN2;
                zoomLevel = Math.floor(Math.min(zoomLevelX, zoomLevelY)) - 1;
            }
        }
        return { point: center, zoom: zoomLevel };
    };
    const initialView = getZoomLevel();
    if (marker.length <= 0)
        return <View />;
    return (<View style={{
            ...props.style,
            height: props.mapHeight,
            width: '100%',
            flex: 1,
        }}>
      <ZoomTile type="map" url="" clusteringDisabled={!(CurrentUser.settings.appSettings == null || CurrentUser.settings.appSettings['clusterMarker'] === true)} zoomAble marker={marker} initialView={initialView} info={{
            dx: 0,
            dy: 0,
            height: 0,
            imageHeight: 0,
            imageWidth: 0,
            maxLevel: 19,
            orgImageHeight: 0,
            orgImageWidth: 0,
            width: 0,
        }}/>
    </View>);
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputOutlined = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _FormInputBase2 = require("./FormInputBase");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FormInputOutlined = exports.FormInputOutlined = function (_FormInputBase) {
  function FormInputOutlined() {
    var _this;
    (0, _classCallCheck2.default)(this, FormInputOutlined);
    _this = _callSuper(this, FormInputOutlined, arguments);
    _this.inputTopDelta = -4;
    _this.labelTop = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(0);
    _this.labelTopDownDelta = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(12);
    _this.getColor = function () {
      var _this$props = _this.props,
        color = _this$props.color,
        errorColor = _this$props.errorColor,
        error = _this$props.error,
        unfocusedColor = _this$props.unfocusedColor;
      var focused = _this.state.focused;
      if (error) {
        return errorColor != null ? errorColor : _ThemeManager.ThemeManager.style.brandDanger;
      }
      if (focused) {
        return color != null ? color : _ThemeManager.ThemeManager.style.brandPrimary;
      }
      return unfocusedColor != null ? unfocusedColor : _ThemeManager.ThemeManager.style.black42;
    };
    return _this;
  }
  (0, _inherits2.default)(FormInputOutlined, _FormInputBase);
  return (0, _createClass2.default)(FormInputOutlined, [{
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
        style = _this$props2.style,
        backgroundColor = _this$props2.backgroundColor;
      var focused = this.state.focused;
      var height = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.getHeight());
      var width = this.getWidth();
      var textInput = this.renderBase();
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: width,
          height: height + this.helperTextHeight,
          position: 'relative'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: [style, {
            backgroundColor: backgroundColor,
            height: height - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
            borderWidth: focused ? 2 : 1,
            borderRadius: 4,
            borderColor: this.getColor(),
            position: 'absolute',
            top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
            left: 0,
            right: 0
          }]
        }), textInput]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return Object.assign({}, _FormInputBase2.FormInputBase.defaultProps, {
        color: _ThemeManager.ThemeManager.style.brandPrimary,
        unfocusedColor: _ThemeManager.ThemeManager.style.black42,
        width: 200,
        backgroundColor: _ThemeManager.ThemeManager.style.appBgColor
      });
    }
  }]);
}(_FormInputBase2.FormInputBase);
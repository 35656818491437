"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeProjectState = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var ProjectStateChanged_1 = require("../../../../server/events/companies/projectstates/ProjectStateChanged");
var ProjectEntity_1 = require("../../../query/entities/ProjectEntity");
var ChangeProjectState = function (_CommandRoot_1$Comman) {
  function ChangeProjectState(data) {
    var _this;
    var companyProjectInfoId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeProjectState);
    _this = _callSuper(this, ChangeProjectState, [data, companyProjectInfoId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeProjectState';
    return _this;
  }
  (0, _inherits2.default)(ChangeProjectState, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeProjectState, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.data.companyId);
        var settings = ReadModels_1.ReadModels.get('CompanySettings');
        var companyProjectInfo = ReadModels_1.ReadModels.get('CompanyProjectInfo');
        var queryCompareEnt = yield companyProjectInfo.get({
          filter: `companyId eq '${this.data.companyId}' and projectId eq '${this.data.projectId}'`
        });
        if (queryCompareEnt != null && queryCompareEnt.length > 0) {
          this.existingId = queryCompareEnt[0].id;
        } else {
          this.existingId = this.entityId;
        }
        this.companySettings = yield settings.getById(company.id);
        yield ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(this.data.projectId, userId);
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.data.projectId, userId, 'commandChangeProject')) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ProjectStateChanged_1.ProjectStateChanged(this.existingId, {
          projectStateId: this.data.stateId,
          projectId: this.data.projectId,
          companyId: this.data.companyId
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var errors = [];
        if (key == null || key === 'stateId') {
          if (this.data.stateId != null) {
            if (this.companySettings.projectStateNames.findIndex(function (elem) {
              return elem.id === _this2.data.stateId;
            }) === -1) {
              errors.push({
                key: 'stateId',
                messageCode: 'required',
                message: 'Status wird benötigt'
              });
            }
          }
        }
        if (key == null || key === 'projectId') {
          if (this.data.projectId == null || this.data.projectId.length === 0) {
            errors.push({
              key: 'projectId',
              messageCode: 'required',
              message: 'Projekt wird benötigt'
            });
          }
        }
        if (key == null || key === 'companyId') {
          if (this.data.companyId == null || this.data.companyId.length === 0) {
            errors.push({
              key: 'companyId',
              messageCode: 'required',
              message: 'Firma wird benötigt'
            });
          }
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeProjectState = ChangeProjectState;
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class CompanyViewMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.sections = [
            {
                title: I18n.m.getMessage('company'),
                key: 'company',
                options: [
                    { title: I18n.m.getMessage('profile'), key: 'profile', icon: 'office-building' },
                    { title: I18n.m.getMessage('employee'), key: 'user', icon: 'account-outline' },
                    { title: I18n.m.getMessage('groups'), key: 'groups', icon: 'account-group-outline' },
                    { title: I18n.m.getMessage('projects'), key: 'projects', icon: 'clipboard-list-outline' },
                    { title: I18n.m.getMessage('reportTemplates'), key: 'reportTemplates', icon: 'file-chart-outline' },
                ],
            },
            {
                title: I18n.m.getMessage('companyTemplatesForProjects'),
                key: 'templates',
                options: [
                    { title: I18n.m.getMessage('projectState'), key: 'projectStatus', icon: 'progress-check' },
                    { title: I18n.m.getMessage('ticketState'), key: 'status', icon: 'state-machine' },
                    { title: I18n.m.getMessage('ticketTypes'), key: 'types', icon: 'ticket-outline' },
                    { title: I18n.m.getMessage('listsTitle'), key: 'lists', icon: 'format-list-bulleted' },
                    { title: I18n.m.getMessage('ticketLayouts'), key: 'ticketLayouts', icon: 'form-select' },
                    { title: I18n.m.getMessage('crafts'), key: 'crafts', icon: 'wrench-outline' },
                    { title: I18n.m.getMessage('tags'), key: 'tags', icon: 'tag-outline' },
                    {
                        title: I18n.m.getMessage('reportTicketActivitiesCategories'),
                        key: 'categories',
                        icon: 'format-list-bulleted-type',
                    },
                ],
            },
            {
                title: I18n.m.getMessage('timeTracking'),
                key: 'timeTracking',
                options: [
                    { title: I18n.m.getMessage('workingTimeModels'), key: 'timeModels', icon: 'calendar-clock' },
                    { title: I18n.m.getMessage('costCenters'), key: 'costCenters', icon: 'hand-coin-outline' },
                    { title: I18n.m.getMessage('tasks'), key: 'tasks', icon: 'briefcase-outline' },
                    { title: I18n.m.getMessage('extraPays'), key: 'extraPay', icon: 'database-plus-outline' },
                    { title: I18n.m.getMessage('regions'), key: 'region', icon: 'earth' },
                    { title: I18n.m.getMessage('holidays'), key: 'holiday', icon: 'balloon' },
                ],
            },
            {
                title: I18n.m.getMessage('projects'),
                key: 'projects',
                options: [],
            },
        ];
        this.init = async () => {
            const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                filter: `userId eq '${CurrentUser.userId}' and deleted ne true`,
                top: 1,
            });
            let company = null;
            if (companyMember.length > 0)
                company = await UpmeshClient.instance.modals.company.getById(companyMember[0].companyId);
            let companySettings;
            if (company != null) {
                try {
                    companySettings = await UpmeshClient.instance.modals.companySettings.getById(company.id);
                }
                catch (e) {
                    console.debug('cant get company Settings!', e);
                }
            }
            if (company == null) {
                CompanyUserInfo.company = null;
            }
            else if ((company != null && CompanyUserInfo.company == null) ||
                JSON.stringify(company) !== JSON.stringify(CompanyUserInfo.company)) {
                CompanyUserInfo.company = company;
            }
            this.setState({ companySettings });
        };
        this.selectFolder = (key) => {
            const { onPress } = this.props;
            onPress(key);
        };
        this.state = {
            companySettings: null,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    render() {
        const { companySettings } = this.state;
        const { size } = this.props;
        return (<ScrollView style={{
                width: '100%',
                padding: 8,
                height: size.contentHeight - ThemeManager.style.headerHeight,
            }}>
        {this.renderSection(this.sections[0])}
        {this.renderSection(this.sections[1])}
        {companySettings && companySettings.hasModule('timeTracking') === true
                ? this.renderSection(this.sections[2])
                : null}
      </ScrollView>);
    }
    renderSection(section) {
        const renderedSubsections = [];
        section.options.forEach((o) => renderedSubsections.push(this.renderElement(o)));
        return (<View style={{ paddingBottom: 8 }}>
        <View style={{ flexDirection: 'row', borderRadius: ThemeManager.style.borderRadius }}>
          <MaterialText type={MaterialTextTypes.Caption} centeredBox>
            {section.title}
          </MaterialText>
        </View>
        {renderedSubsections}
      </View>);
    }
    renderElement(s) {
        const { selected, sMode } = this.props;
        let isSelected = selected != null && s.key === selected;
        if (!sMode === true && selected == null && s.key === 'profile')
            isSelected = true;
        return (<Ripple key={s.key} style={{
                flexDirection: 'row',
                width: '100%',
                borderRadius: ThemeManager.style.borderRadius,
                backgroundColor: isSelected ? ThemeManager.style.brandPrimary : 'transparent',
                paddingLeft: 6,
                height: 40,
            }} onPress={() => this.selectFolder(s.key)}>
        <Icon toolTip="" icon={s.icon} color={isSelected ? '#ffffff' : ThemeManager.style.defaultIconColor}/>
        <MaterialText type={MaterialTextTypes.Body1} color={isSelected ? '#ffffff' : ThemeManager.style.black87} centeredBox>
          {s.title}
        </MaterialText>
      </Ripple>);
    }
}

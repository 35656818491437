import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
const pathMap = new Map();
export function PathCellRenderer(props) {
    const [path, setPath] = useState('');
    useEffect(() => {
        const { item } = props;
        if (item.entityName === 'StoredFile') {
            if (item.folder == null || item.folder.length === 0 || item.folder === 'private') {
                if (item.forEntity != null && item.forEntity.length > 0) {
                    if (item.forEntity === 'Journal')
                        setPath(I18n.m.getMessage('menuProjectDailyReports'));
                    else if (item.forEntity === 'Ticket')
                        setPath(I18n.m.getMessage('tickets'));
                }
                else {
                    setPath(I18n.m.getMessage('filesFolderPrivate'));
                }
            }
            else if (item.folder === 'public') {
                setPath(I18n.m.getMessage('filesFolderPublic'));
            }
            else if (pathMap.has(item.folder ?? 'public')) {
                setPath(pathMap.get(item.folder ?? 'public') ?? '');
            }
            else {
                UpmeshClient.instance.modals.folder
                    .getById(item.folder ?? '')
                    .then((resultFolder) => {
                    let pathString = `${resultFolder.title}`;
                    FolderEntity.getMainFolders(resultFolder)
                        .then((folderPath) => {
                        folderPath.forEach((folder) => {
                            pathString = `${folder.title} > ${pathString}`;
                        });
                        pathString = `${I18n.m.getMessage('filesFolderPublic')} > ${pathString}`;
                        pathMap.set(item.folder ?? 'public', pathString);
                        setPath(pathString);
                    })
                        .catch((e) => {
                        DefaultErrorHandler.showDefaultErrorAlert(e);
                    });
                })
                    .catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            }
        }
        else if (item.entityName === 'JournalProtocol') {
            setPath(I18n.m.getMessage('menuProjectDailyReports'));
        }
    }, []);
    return (<View style={[{ justifyContent: 'center', paddingHorizontal: 16 }, props.column.style]}>
      <MaterialText onPress={(_e) => {
            const { projectId, folder, forEntity } = props.item;
            const folderId = forEntity === 'Ticket'
                ? 'tickets'
                : forEntity === 'Journal'
                    ? 'reports'
                    : folder == null || folder.length === 0 || folder === 'private'
                        ? 'private'
                        : folder;
            Routing.instance.goTo(`/projects/${projectId}/files/${folderId}`);
        }}>
        {path}
      </MaterialText>
    </View>);
}

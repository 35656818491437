var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContainedButton = ContainedButton;
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _Button = require("./Button");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function ContainedButton(props) {
  return (0, _jsxRuntime.jsx)(_Button.Button, Object.assign({}, props));
}
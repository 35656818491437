var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentFooterStatus = exports.ContentFooterEventHandler = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _tsEvents = require("ts-events");
var ContentFooterStatus = exports.ContentFooterStatus = (0, _createClass2.default)(function ContentFooterStatus() {
  (0, _classCallCheck2.default)(this, ContentFooterStatus);
});
var ContentFooterEventHandler = exports.ContentFooterEventHandler = (0, _createClass2.default)(function ContentFooterEventHandler() {
  (0, _classCallCheck2.default)(this, ContentFooterEventHandler);
});
ContentFooterEventHandler.heightChangeEvent = new _tsEvents.AsyncEvent();
ContentFooterEventHandler.statusEvent = new _tsEvents.AsyncEvent();
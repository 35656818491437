"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MongoDBoDataFilterParser = void 0;
var Parser_1 = require("./Parser");
var MongoDBoDataFilterParser = function () {
  function MongoDBoDataFilterParser() {
    (0, _classCallCheck2.default)(this, MongoDBoDataFilterParser);
  }
  return (0, _createClass2.default)(MongoDBoDataFilterParser, null, [{
    key: "parse",
    value: function parse(filter) {
      var regExWithFlag = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      try {
        var q = (0, Parser_1.createFilter)(filter, regExWithFlag);
        return q;
      } catch (e) {
        throw new Error(`Syntax Error: ${e.toString()}`);
      }
    }
  }]);
}();
exports.MongoDBoDataFilterParser = MongoDBoDataFilterParser;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlanVersionEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var PlanVersionEntity = function (_ReadModelEntity_1$Re) {
  function PlanVersionEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, PlanVersionEntity);
    _this = _callSuper(this, PlanVersionEntity, [obj]);
    _this.entityName = 'PlanVersion';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(PlanVersionEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(PlanVersionEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new PlanVersionEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('planId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('orgFilename', new OdataEntity_1.OdataIndex());
      baseMap.set('fileId', new OdataEntity_1.OdataIndex());
      baseMap.set('version', new OdataEntity_1.OdataIndex());
      baseMap.set('pageWidth', new OdataEntity_1.OdataIndex());
      baseMap.set('pageHeight', new OdataEntity_1.OdataIndex());
      baseMap.set('scale', new OdataEntity_1.OdataIndex());
      baseMap.set('markerScaleShift', new OdataEntity_1.OdataIndex());
      baseMap.set('tileStatus', new OdataEntity_1.OdataIndex());
      baseMap.set('tileError', new OdataEntity_1.OdataIndex());
      baseMap.set('fileSizeInBytes', new OdataEntity_1.OdataIndex());
      baseMap.set('basicAuth', new OdataEntity_1.OdataIndex());
      baseMap.set('info', new OdataEntity_1.OdataIndex());
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('creator', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }, {
    key: "getRealpointInCMFromPagepoint",
    value: function getRealpointInCMFromPagepoint(pagepoint) {
      var s = (this.scale != null ? this.scale : 100) / 10 * 0.352777777;
      var p = {
        x: Math.round(pagepoint.x * s * 1000) / 1000,
        y: Math.round(pagepoint.y * s * 1000) / 1000
      };
      if (this.markerScaleShift == null) return p;
      var markerScaleShift = this.markerScaleShift;
      return {
        x: (p.x - markerScaleShift.shift.x) / markerScaleShift.scale,
        y: (p.y - markerScaleShift.shift.y) / markerScaleShift.scale
      };
    }
  }, {
    key: "getPointInPageFromRealpointInCM",
    value: function getPointInPageFromRealpointInCM(realpoint) {
      var s = (this.scale != null ? this.scale : 100) / 10 * 0.352777777;
      var p = {
        x: Math.round(realpoint.x / s * 1000) / 1000,
        y: Math.round(realpoint.y / s * 1000) / 1000
      };
      if (this.markerScaleShift == null) return p;
      var markerScaleShift = this.markerScaleShift;
      return {
        x: p.x * markerScaleShift.scale + markerScaleShift.shift.x,
        y: p.y * markerScaleShift.scale + markerScaleShift.shift.y
      };
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.PlanVersionEntity = PlanVersionEntity;
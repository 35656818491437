import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { SwitchListItem } from 'materialTheme/src/theme/components/SwitchListItem';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { LayoutFields } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { I18n } from '../../../../i18n/I18n';
export function TicketLayoutDefaultOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const [formError, setFormError] = useState(new Map());
    useEffect(() => {
        props.onChange(currentField);
    }, [currentField]);
    useEffect(() => {
        if (formError.size === 0)
            props.onSuccess();
        else
            props.onError(formError);
    }, [formError]);
    return (<>
      <FormInputFilled helperText={formError.has('label') ? formError.get('label') : ''} error={formError.has('label')} labelText={I18n.m.getMessage('ticketLayoutOptionsLabel')} initValue={currentField.label} onChange={(v) => {
            if (v.length === 0) {
                const tMap = new Map();
                tMap.set('label', I18n.m.getMessage('required'));
                setFormError(tMap);
            }
            else {
                setFormError(new Map());
            }
            setCurrentField({ ...currentField, label: v });
        }}/>
      <FormInputFilled helperText={formError.has('identifier')
            ? formError.get('identifier')
            : I18n.m.getMessage('ticketLayoutOptionsIdentifierHelperText')} error={formError.has('identifier')} labelText={I18n.m.getMessage('ticketLayoutOptionsIdentifier')} value={currentField.identifier ? currentField.identifier : LayoutFields.filterIdentifier(currentField.label)} filterText={LayoutFields.filterIdentifier} onChange={(v) => {
            if (formError.has('identifier'))
                setFormError(new Map());
            setCurrentField({ ...currentField, identifier: v });
        }}/>
      <FormInputFilled labelText={I18n.m.getMessage('ticketLayoutOptionsDescription')} initValue={currentField.description} onChange={(v) => {
            setCurrentField({ ...currentField, description: v });
        }}/>

      {props.children}

      {(props.assigneeCanEditVisible || props.approverCanVisible) && (<View style={{ paddingHorizontal: 8, paddingTop: 8, paddingBottom: 0 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('ticketLayoutEditText')}</MaterialText>
        </View>)}
      {props.assigneeCanEditVisible && (<SwitchListItem title={I18n.m.getMessage('ticketLayoutEditAssignee')} value={!!currentField.assigneeCanEdit} onChange={(e) => {
                setCurrentField({ ...currentField, assigneeCanEdit: e });
            }}/>)}

      {props.approverCanVisible && (<SwitchListItem title={I18n.m.getMessage('ticketLayoutEditApprover')} value={!!currentField.approverCanEdit} onChange={(e) => {
                setCurrentField({ ...currentField, approverCanEdit: e });
            }}/>)}
    </>);
}

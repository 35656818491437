import { Linking } from 'react-native';
export class Download {
    static async downloadDataAsString(filename, mimeType, data) {
        const blob = new Blob([data], { type: mimeType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    }
    static async download(link) {
        Linking.openURL(link).catch((err) => console.debug(err));
    }
}

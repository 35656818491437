var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _crossFetch = _interopRequireDefault(require("cross-fetch"));
var mime = _interopRequireWildcard(require("mime"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var AudioPlayer = exports.default = function (_React$Component) {
  function AudioPlayer(props) {
    var _this;
    (0, _classCallCheck2.default)(this, AudioPlayer);
    _this = _callSuper(this, AudioPlayer, [props]);
    _this.getUrl = (0, _asyncToGenerator2.default)(function* () {
      var _this$props = _this.props,
        src = _this$props.src,
        withAuth = _this$props.withAuth;
      if (!src) return;
      var audioSource = document.getElementById(`audio_${src}`);
      if (audioSource != null) {
        if (!withAuth) {
          audioSource['src'] = src;
        } else {
          var headers = {};
          if (_CurrentUser.CurrentUser.token != null && _CurrentUser.CurrentUser.token.length > 0) {
            headers['authorization'] = `Bearer ${_CurrentUser.CurrentUser.token}`;
          }
          var result = yield (0, _crossFetch.default)(`${src}?toMp3=1`, {
            headers: headers
          });
          var blob = yield result.blob();
          if (blob) {
            audioSource['src'] = URL.createObjectURL(blob);
          }
        }
        if (audioSource.parentElement != null && typeof audioSource.parentElement['load'] === 'function') {
          audioSource.parentElement['load']();
        }
      }
    });
    var mimeType = null;
    if (props.src != null) {
      var split = props.src.split('/');
      mimeType = mime.getType(split[split.length - 1]);
    }
    _this.state = {
      mime: mimeType
    };
    return _this;
  }
  (0, _inherits2.default)(AudioPlayer, _React$Component);
  return (0, _createClass2.default)(AudioPlayer, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.getUrl().catch(function () {});
    }
  }, {
    key: "render",
    value: function render() {
      var src = this.props.src;
      return (0, _jsxRuntime.jsx)("audio", {
        controls: true,
        controlsList: "nodownload",
        style: {
          width: '100%'
        },
        children: (0, _jsxRuntime.jsx)("source", {
          id: `audio_${src}`
        })
      });
    }
  }]);
}(_react.default.Component);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CurrentUser = void 0;
var B64 = require("base-64");
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var UserSettingsEntity_1 = require("./query/entities/UserSettingsEntity");
var CurrentUser = function () {
  function CurrentUser() {
    (0, _classCallCheck2.default)(this, CurrentUser);
  }
  return (0, _createClass2.default)(CurrentUser, null, [{
    key: "entity",
    get: function get() {
      return CurrentUser._entity;
    }
  }, {
    key: "token",
    get: function get() {
      return CurrentUser._token;
    }
  }, {
    key: "settings",
    get: function get() {
      return CurrentUser._settings != null && CurrentUser._settings.id === CurrentUser.userId ? CurrentUser._settings : new UserSettingsEntity_1.UserSettingsEntity({
        id: CurrentUser.userId,
        userId: CurrentUser.userId
      });
    },
    set: function set(s) {
      CurrentUser._settings = s;
    }
  }, {
    key: "userId",
    get: function get() {
      if (CurrentUser._entity != null) {
        return CurrentUser._entity.id;
      }
      return '';
    }
  }, {
    key: "setUser",
    value: function setUser(user, token, settings) {
      CurrentUser._entity = user;
      CurrentUser._settings = settings;
      CurrentUser._token = token;
    }
  }, {
    key: "getUnVerifiedDataFromToken",
    value: function getUnVerifiedDataFromToken(token) {
      try {
        var bValue = JSON.parse(B64.decode(token.split('.')[1]));
        if (bValue != null && bValue.jti != null && bValue.jti.length > 0) {
          return bValue;
        }
        throw new ApplicationError_1.ApplicationError('TokenError', 'tokenError', ApplicationError_1.ApplicationErrorStatusCode.UnauthorizedError);
      } catch (e) {}
      return {};
    }
  }]);
}();
exports.CurrentUser = CurrentUser;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unclusteredPointLayer = exports.clusterLayer = exports.clusterCountLayer = void 0;
var _ThemeManager = require("../../ThemeManager");
var clusterLayer = exports.clusterLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'earthquakes',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], _ThemeManager.ThemeManager.style.brandPrimary, 200, _ThemeManager.ThemeManager.style.brandWarning],
    'circle-opacity': 0.5,
    'circle-radius': ['step', ['get', 'point_count'], 25, 100, 30, 750, 40]
  }
};
var clusterCountLayer = exports.clusterCountLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'earthquakes',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Open Sans Bold'],
    'text-size': 12,
    'text-allow-overlap': true
  }
};
var unclusteredPointLayer = exports.unclusteredPointLayer = function unclusteredPointLayer() {
  var iconSize = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return {
    id: 'unclustered-point',
    type: 'symbol',
    source: 'earthquakes',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': ['case', ['boolean', ['has', 'color'], false], ['get', 'color'], 'orange'],
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
      'icon-size': iconSize
    }
  };
};
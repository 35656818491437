"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AbsenceFilter = exports.AbsenceExtendedEntity = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var UserEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserEntity");
var Filter_1 = require("./Filter");
var AbsenceExtendedEntity = function () {
  function AbsenceExtendedEntity() {
    (0, _classCallCheck2.default)(this, AbsenceExtendedEntity);
    this.creatorName = '?';
    this.memberName = '?';
    this.memberId = '';
    this.deleted = false;
  }
  return (0, _createClass2.default)(AbsenceExtendedEntity, null, [{
    key: "getUserById",
    value: function () {
      var _getUserById = (0, _asyncToGenerator2.default)(function* (userId) {
        if (userId !== 'all') {
          try {
            var user = yield AuthClient_1.AuthClient.instance.modals.user.getById(userId);
            return user;
          } catch (e) {}
        }
        return undefined;
      });
      function getUserById(_x) {
        return _getUserById.apply(this, arguments);
      }
      return getUserById;
    }()
  }, {
    key: "convert",
    value: function () {
      var _convert = (0, _asyncToGenerator2.default)(function* (entry, isPayroll) {
        var c = new AbsenceExtendedEntity();
        c.projectId = entry.projectId;
        c.state = entry.state;
        c.deleted = entry.deleted;
        c.foreignId = entry.foreignId;
        c.orgEntry = entry;
        c.id = entry.id;
        c.note = entry['note'] != null ? entry['note'] : undefined;
        c.starts = entry.starts;
        c.ends = entry.ends;
        c.companyId = entry.companyId;
        c.memberId = entry.memberId;
        var task = entry.taskId != null && entry.taskId.length > 0 ? yield ReadModels_1.ReadModels.get('Task').getById(entry.taskId) : undefined;
        c.taskId = entry.taskId;
        c.taskName = `${task.mark} - ${task.description}`;
        var member = yield ReadModels_1.ReadModels.get('CompanyMember').getById(entry.memberId);
        c.userId = member.userId == null ? member.id : member.userId;
        if (member.userId != null) {
          var user = yield ReadModels_1.ReadModels.get('User').getById(member.userId);
          c.memberName = user.getFullName();
          c.user = user;
        } else {
          c.memberName = `${member.firstName} ${member.lastName}`;
        }
        var costCenterName = '';
        if (isPayroll) {
          var projectId = entry.projectId,
            costCenterId = entry.costCenterId;
          try {
            if (costCenterId != null && costCenterId.length > 0) {
              var costCenter = yield ReadModels_1.ReadModels.get('CostCenter').getById(costCenterId);
              costCenterName = costCenter.getName();
              c.costCenterId = costCenter.id;
              if (projectId != null && projectId.length > 0) {
                var project = yield ReadModels_1.ReadModels.get('CompanyProject').getById(projectId);
                c.projectTitle = project.title;
              }
            }
          } catch (err) {}
          c.costCenterName = costCenterName;
        }
        try {
          if (entry.createdBy !== 'all') {
            var creator = yield AbsenceExtendedEntity.getUserById(entry.createdBy);
            if (creator != null) c.creatorName = creator.getFullName();
          }
        } catch (err) {}
        try {
          c.memberName = `${member.firstName} ${member.lastName}`;
          if (member.userId != null) {
            var _user = yield AbsenceExtendedEntity.getUserById(member.userId);
            if (_user) {
              c.memberName = _user.getFullName();
              c.user = _user;
            }
          } else {
            c.user = new UserEntity_1.UserEntity({
              firstname: member.firstName,
              lastname: member.lastName,
              id: member.id
            });
          }
        } catch (err) {}
        return c;
      });
      function convert(_x2, _x3) {
        return _convert.apply(this, arguments);
      }
      return convert;
    }()
  }]);
}();
exports.AbsenceExtendedEntity = AbsenceExtendedEntity;
var AbsenceFilter = function () {
  function AbsenceFilter() {
    (0, _classCallCheck2.default)(this, AbsenceFilter);
  }
  return (0, _createClass2.default)(AbsenceFilter, null, [{
    key: "filterByText",
    value: function filterByText(text, tt) {
      var words = text.trim().split(' ');
      if (tt == null || tt.length === 0) {
        return tt;
      }
      var filterByNote = function filterByNote(element, _index, _array) {
        if (element.note != null && element.note.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.note.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByMemberName = function filterByMemberName(element, _index, _array) {
        if (element.memberName != null && element.memberName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.memberName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByCostCenterName = function filterByCostCenterName(element, _index, _array) {
        if (element.costCenterName != null && element.creatorName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.costCenterName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByCreatorName = function filterByCreatorName(element, _index, _array) {
        if (element.creatorName != null && element.creatorName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.creatorName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByTask = function filterByTask(element, _index, _array) {
        var taskName = element['taskName'] ? element['taskName'] : null;
        if (taskName != null && taskName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = taskName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByProject = function filterByProject(element, _index, _array) {
        if (element.projectTitle != null && element.projectTitle.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.projectTitle.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      if (text != null && text.length > 0) {
        var filteredByNotes = tt.filter(filterByNote);
        var filteredByMemberNames = tt.filter(filterByMemberName);
        var filteredByCreatorNames = tt.filter(filterByCreatorName);
        var filteredByCostCenterNames = tt.filter(filterByCostCenterName);
        var filteredByTasks = tt.filter(filterByTask);
        var filteredByProjects = tt.filter(filterByProject);
        var result = [].concat((0, _toConsumableArray2.default)(filteredByNotes), (0, _toConsumableArray2.default)(filteredByMemberNames), (0, _toConsumableArray2.default)(filteredByCreatorNames), (0, _toConsumableArray2.default)(filteredByCostCenterNames), (0, _toConsumableArray2.default)(filteredByTasks), (0, _toConsumableArray2.default)(filteredByProjects));
        var counts = [];
        result.forEach(function (item) {
          if (counts[item.id] === undefined) {
            counts[item.id] = 0;
          }
          counts[item.id] += 1;
          return counts;
        });
        var unique = result.filter(function (x, i, l) {
          return l.indexOf(x) === i;
        });
        var sorted = unique.sort(function (v1, v2) {
          return counts[v2.id] - counts[v1.id];
        });
        return sorted;
      }
      return tt;
    }
  }, {
    key: "filterByMember",
    value: function filterByMember(memberIds, tt) {
      if (memberIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.memberId != null && memberIds.includes(t.memberId);
      });
    }
  }, {
    key: "filterByUser",
    value: function filterByUser(userIds, tt) {
      if (userIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.userId != null && userIds.includes(t.userId);
      });
    }
  }, {
    key: "filterByProjects",
    value: function filterByProjects(projectIds, tt) {
      if (projectIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.projectId == null ? false : projectIds.includes(t.projectId);
      });
    }
  }, {
    key: "filterByState",
    value: function filterByState(state, tt) {
      if (state.length === 0) return tt;
      return tt.filter(function (t) {
        return t.state == null ? false : state.includes(t.state);
      });
    }
  }, {
    key: "filterByDateRange",
    value: function filterByDateRange(tt, from, to) {
      var dateFrom = from != null ? new Date(from) : null;
      var dateTo = to != null ? new Date(to) : null;
      var start = dateFrom == null ? null : new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0, 0);
      var end = dateTo == null ? null : new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59, 59);
      return tt.filter(function (t) {
        return (start == null || t.starts >= start) && (end == null || t.ends < end);
      });
    }
  }, {
    key: "filterByTaskId",
    value: function filterByTaskId(tt, taskIds) {
      return tt.filter(function (t) {
        var taskId = t['taskId'] != null ? t['taskId'] : null;
        if (taskId != null) {
          return taskIds.findIndex(function (elem) {
            return elem === taskId;
          }) >= 0;
        }
        return false;
      });
    }
  }, {
    key: "filterByCostCenters",
    value: function filterByCostCenters(costCenterIds, tt) {
      if (costCenterIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.costCenterId == null ? false : costCenterIds.includes(t.costCenterId);
      });
    }
  }]);
}();
exports.AbsenceFilter = AbsenceFilter;
AbsenceFilter.filter = function (tts, filter) {
  var projectIds = filter.projectIds,
    date = filter.date,
    words = filter.words,
    dateTo = filter.dateTo,
    state = filter.state,
    taskIds = filter.taskIds,
    memberIds = filter.memberIds,
    userIds = filter.userIds,
    costCenterIds = filter.costCenterIds;
  var filtered = (0, _toConsumableArray2.default)(tts);
  if (memberIds != null) filtered = AbsenceFilter.filterByMember(memberIds, filtered);
  if (userIds != null) filtered = AbsenceFilter.filterByUser(userIds, filtered);
  if (projectIds != null) filtered = AbsenceFilter.filterByProjects(projectIds, filtered);
  if (state != null) filtered = AbsenceFilter.filterByState(state, filtered);
  if (costCenterIds != null) filtered = AbsenceFilter.filterByCostCenters(costCenterIds, filtered);
  if (date != null || dateTo != null) filtered = AbsenceFilter.filterByDateRange(filtered, date, dateTo);
  if (words != null && words.length > 0) {
    filtered = AbsenceFilter.filterByText(words, filtered);
  }
  if (taskIds != null && taskIds.length > 0) filtered = AbsenceFilter.filterByTaskId(filtered, taskIds);
  return filtered;
};
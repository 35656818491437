import { View } from 'react-native';
import React, { useMemo } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { getKW, getMonth } from './calendarUtil';
import { I18n } from '../../../i18n/I18n';
export function CalendarHeader(props) {
    const { showProject, currentDate, range, rangeIndex, useMonth, changeView, changeRange, setNewDate } = props;
    const { showKW } = CurrentUser.settings;
    const buttonSegments = useMemo(() => {
        const buttonSegments = [
            { title: I18n.m.getMessage('day'), key: 'day' },
            { title: I18n.m.getMessage('week'), key: 'week' },
        ];
        if (useMonth)
            buttonSegments.push({ title: I18n.m.getMessage('month'), key: 'month' });
        return buttonSegments;
    }, [useMonth]);
    return (<View style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 8,
        }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', height: 40 }}>
        <Icon icon="chevron-left" toolTip="" onPress={() => changeView(-1)} color={showProject && showProject === true ? '#ffffff' : undefined}/>
        <View style={{ width: 8 }}/>
        <Icon icon="chevron-right" toolTip="" onPress={() => changeView(1)} color={showProject && showProject === true ? '#ffffff' : undefined}/>
        <View style={{ width: 8 }}/>
        <Ripple style={{ height: '100%', justifyContent: 'center' }} onPress={() => Datepicker.open({
            mode: 'date',
            onChange: (currentDate) => {
                if (currentDate instanceof Date)
                    setNewDate(currentDate);
            },
            quickSelections: [{ name: I18n.m.getMessage('today'), days: 0 }],
            selectedDate: currentDate,
        })}>
          <MaterialText centeredBox color={showProject && showProject === true ? '#ffffff' : undefined}>
            {getMonth(currentDate, range)}
          </MaterialText>
        </Ripple>
        {range !== 'month' && showKW && (<Chip title={`${I18n.m.getMessage('calendarWeekShort')} ${getKW(currentDate)}`}/>)}
      </View>
      <View style={{ flex: 1 }}/>
      <View style={{ width: 350, backgroundColor: '#ffffff', borderRadius: ThemeManager.style.borderRadius }}>
        <SegmentedButton buttons={buttonSegments} onPress={changeRange} singleSelectSelected={rangeIndex} backgroundColor="white" textColor={ThemeManager.style.defaultTextColor} selectedColor="rgba(0, 115, 255, 0.1)" density={2}/>
      </View>
    </View>);
}

import { TextButton } from 'materialTheme/src/theme/components/button/TextButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeExtraPay } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPay';
import { CreateExtraPay } from 'upmesh-core/src/client/commands/companies/extrapay/CreateExtraPay';
import { I18n } from '../../i18n/I18n';
import { ExtraPayFieldComponent } from './ExtraPayFieldComponent';
export class AddOrChangeExtraPayToCompanyDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
        this.fieldComponent = (props) => {
            if (props['currentData'] != null && props['currentData']['fieldType'] === 'number') {
                const field = this.state.field != null ? this.state.field : { unit: '' };
                return <ExtraPayFieldComponent field={field} {...props}/>;
            }
            return <View style={{ height: 0 }}/>;
        };
        this.state = { field: props.extraPay?.field };
    }
    render() {
        const { company, extraPay } = this.props;
        const list = [
            { title: I18n.m.getMessage('extraPayFieldTypeNone'), data: 'none' },
            { title: I18n.m.getMessage('extraPayFieldTypeNumber'), data: 'number' },
            { title: I18n.m.getMessage('extraPayFieldTypeDuration'), data: 'duration' },
        ];
        if (extraPay != null) {
            const currentData = {
                mark: extraPay.mark,
                description: extraPay.description == null ? '' : extraPay.description,
                wageType: extraPay.wageType,
                foreignId: extraPay.foreignId,
                onceADay: extraPay.onceADay,
                fieldType: extraPay.fieldType,
                field: extraPay.field,
            };
            const dataOptions = new Map();
            dataOptions.set('mark', {
                formType: 'string',
                cols: 1,
                props: { labelText: I18n.m.getMessage('extraPayMark') },
            });
            dataOptions.set('description', {
                formType: 'string',
                cols: 1,
                props: { labelText: I18n.m.getMessage('extraPayDescription') },
            });
            dataOptions.set('wageType', {
                formType: 'string',
                cols: 1,
                props: { labelText: I18n.m.getMessage('extraPayWageType') },
            });
            dataOptions.set('fieldType', {
                formType: 'Picker',
                cols: 1,
                props: { labelText: I18n.m.getMessage('extraPayFieldType'), list },
                mapValue: (value, fromFormInput) => {
                    if (fromFormInput)
                        return value.value.data;
                    let index = 0;
                    if (value === 'number')
                        index = 1;
                    if (value === 'duration')
                        index = 2;
                    return { index, value };
                },
            });
            dataOptions.set('field', {
                formType: 'individiual',
                cols: 1,
                props: {},
                component: this.fieldComponent,
            });
            dataOptions.set('onceADay', {
                formType: 'switch',
                cols: 1,
                props: { labelText: I18n.m.getMessage('extraPayOnceAday') },
            });
            return (<Form key="AddOrChangeExtraPayToCompanyForm" formHeaderProps={{
                    formTitle: I18n.m.getMessage('companyChangeExtraPayDialogTitle'),
                }} stickyActions stickyHeader command={new ChangeExtraPay(currentData, extraPay.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
        }
        const currentData = {
            mark: '',
            fieldType: 'none',
            onceADay: false,
            description: '',
            companyId: company.id,
            wageType: '',
            foreignId: '',
        };
        const dataOptions = new Map();
        dataOptions.set('mark', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('extraPayMark') },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('extraPayDescription') },
        });
        dataOptions.set('wageType', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('extraPayWageType') },
        });
        dataOptions.set('fieldType', {
            formType: 'Picker',
            cols: 1,
            props: { labelText: I18n.m.getMessage('extraPayFieldType'), list },
            mapValue: (value, fromFormInput) => {
                if (fromFormInput)
                    return value.value.data;
                let index = 0;
                if (value === 'number')
                    index = 1;
                if (value === 'duration')
                    index = 2;
                return { index, value };
            },
        });
        dataOptions.set('field', {
            formType: 'individiual',
            cols: 1,
            props: {},
            component: this.fieldComponent,
        });
        dataOptions.set('onceADay', {
            formType: 'switch',
            cols: 1,
            props: { labelText: I18n.m.getMessage('extraPayOnceAday') },
        });
        return (<Form key="AddOrChangeExtraPayToCompanyForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyAddExtraPayDialogTitle'),
            }} stickyActions stickyHeader command={new CreateExtraPay(currentData)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('add')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocaleDetector = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var LocaleDetector = exports.LocaleDetector = function () {
  function LocaleDetector() {
    (0, _classCallCheck2.default)(this, LocaleDetector);
  }
  return (0, _createClass2.default)(LocaleDetector, null, [{
    key: "detect",
    value: function detect() {
      if (navigator.language != null) {
        return navigator.language;
      }
      return 'de';
    }
  }]);
}();
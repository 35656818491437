import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { TicketEntity } from 'upmesh-core/src/client/query/entities/TicketEntity';
export class PlanMarker {
    constructor(id, position, color, ticketStatus, completionOn, title = '') {
        this.dragable = false;
        this.x = 0;
        this.y = 0;
        this.ticketStatus = ticketStatus;
        this.id = id;
        this.completionOn = completionOn;
        this.position = position;
        this.x = position.x;
        this.y = position.y;
        this.color = color;
        this.title = title;
    }
    get borderStyle() {
        if (this.ticketStatus != null && this.completionOn != null) {
            const completionStatus = TicketEntity.getStatusForCompletionOn(this.ticketStatus, this.completionOn);
            if (completionStatus === 'overdue') {
                return { size: 5, color: ThemeManager.style.brandDanger };
            }
        }
        return { size: 1, color: ThemeManager.style.black87 };
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("../../../../../upmesh-client/node_modules/react");
var useComponentSize = function useComponentSize() {
  var _useState = (0, _react.useState)({
      width: 0,
      height: 0
    }),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    size = _useState2[0],
    setSize = _useState2[1];
  var onLayout = (0, _react.useCallback)(function (event) {
    var _event$nativeEvent$la = event.nativeEvent.layout,
      width = _event$nativeEvent$la.width,
      height = _event$nativeEvent$la.height;
    setSize({
      width: width,
      height: height
    });
  }, []);
  return [size, onLayout];
};
var _default = exports.default = useComponentSize;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AGB = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../upmesh-client/node_modules/react-native-web");
var _MaterialText = require("../theme/components/text/MaterialText");
var _jsxRuntime = require("../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var AGB = exports.AGB = function (_PureComponent) {
  function AGB() {
    (0, _classCallCheck2.default)(this, AGB);
    return _callSuper(this, AGB, arguments);
  }
  (0, _inherits2.default)(AGB, _PureComponent);
  return (0, _createClass2.default)(AGB, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Allgemeine Gesch\xE4tsbedingungen"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Inhalt
          
Allgemeines
Anwendungsbereich
Registrierung, Passwort
Beauftragung kostenpflichtiger Leistungen
Lizenz
Vergütung
Pflichten des Nutzers
Verknüpfung zu anderen Webseiten oder
Diensten
Haftung für Rechts- und Sachmängel
Freistellung
Support und Updates
Laufzeit und Kündigung
Vertraulichkeit und Datenschutz
Sonstiges


1. Allgemeines 
Der von der upmesh UG (haftungsbeschränkt) (“upmesh”) angebotene Service (“Service”) ist eine cloud-basierte Plattform für eine planbasierte Dokumentation von Vorgängen auf Baustellen. Alle mit diesem Service erfassten Daten werden während der Laufzeit dieser Vereinbarung in diesem Service gespeichert und können mit allen Projektbeteiligten (Bauherr/Auftraggeber, Architekt, Bauleitung, Ausführung, Nachunternehmer etc.) geteilt werden.
Der Service kann über die Webseite von umesh der upmesh als Web-Applikation oder über entsprechende mobile Applikationen für Android und/oder iOS genutzt werden. Sämtliche Daten werden in der Cloud von upmesh verarbeitet und gespeichert und können so von unterschiedlichen Endgeräten abgerufen werden. Die Funktionalitäten des Service sind unter www.upmesh.de beschrieben.
Die Nutzung des Service mit einem eingeschränkten Funktionsumfang ist kostenfrei. Für die professionelle Nutzung des Service hat upmesh verschiedene Preismodelle definiert, die im Preismodell www.upmesh.de/preise näher beschrieben sind.
Eine über bestimmte Basisfunktionen hinausgehende Nutzung ist nur entgeltlich möglich. Dabei kann der Nutzer zwischen verschiedenen Preismodellen wählen, für die jeweils unterschiedliche Vergütungen fällig werden.
Nutzer eines kostenpflichtigen Preismodells haben die Möglichkeit, andere Nutzer, die mit dem kostenfreien Basis-Preismodell arbeiten, in die eigenen Projekt einzuladen (z.B. als Nachunternehmer). Diese Nutzer haben in diesen Projekten dann jedoch nur im Funktionsumfang eingeschränkte Rollen einnehmen.


2. Anwendungsbereich 
Die Nutzung des Service ist ausschließlich nach diesen allgemeinen Geschäfts- und Nutzungsbedingungen (“Vereinbarung”) zulässig. Durch Aufnahme der Nutzung des Service akzeptiert der Nutzer die Geltung dieser Vereinbarung in ihrer jeweiligen Fassung. Die Bedingungen dieser Vereinbarung gelten für sämtliche Upgrades, Updates, Änderungen und Aktualisierungen des Service (“Updates”) sowie für alle durch die Nutzung des Service verfügbaren Dienste und Informationen, sofern für diese keine gesonderten Regelungen getroffen werden, die dann vorrangig sind. Für die Beziehung zwischen upmesh und dem Nutzer gilt ausschließlich diese Vereinbarung. Entgegenstehende oder von dieser Vereinbarung oder von gesetzlichen Bestimmungen abweichende Bedingungen des Nutzers gelten nicht, auch wenn upmesh ihnen nicht gesondert widersprochen hat.
upmesh kann diese Vereinbarung jederzeit ändern. Die geänderte Vereinbarung teilt upmesh dem Nutzer unter Hervorhebung der Änderungen mit einer angemessenen Widerspruchsfrist mit. Widerspricht der Nutzer den Änderungen nicht innerhalb dieser Frist und nutzt den Service nach diesem Zeitpunkt weiter, gilt dies als Anerkennung der geänderten Vereinbarung. Auf diese Folge wird upmesh den Nutzer zusammen mit der Mitteilung der geänderten Vereinbarung hinweisen. Im Falle eines fristgerechten Widerspruchs durch den Nutzer gegen die geänderte Vereinbarung kann upmesh dem Nutzer die Nutzung des Service kündigen.
Wird der Service durch Unternehmen oder andere juristische Personen genutzt, wird das jeweilige Unternehmen oder die juristische Person durch den Nutzer vertreten und muss sich dessen Handeln und Wissen zurechnen lassen.
Diese Vereinbarung zwischen dem Nutzer und upmesh kommt mit der Installation einer entsprechenden App, der ersten Nutzung des Service oder spätestens mit Abschluss der Registrierung für den Service zustande.
Ein Anspruch auf Abschluss der Vereinbarung oder die Nutzung des Service besteht nicht. upmesh kann jede Registrierungsanfrage oder jeden Nutzungsversuch jederzeit ohne Angabe von Gründen ablehnen. Die vertraglichen Leistungspflichten von upmesh bei vereinbarter kostenpflichtiger Nutzung bleiben unberührt.


3. Registrierung, Passwort
Die Nutzung des Service ist nur nach vorheriger Registrierung möglich. Hierbei muss der Nutzer bestimmte Informationen (Kontaktdaten, ggfs. Unternehmensname usw.) zur Verfügung stellen (“Registrierungsdaten”) und ein Passwort festlegen. Damit wird für den Nutzer ein Benutzerkonto angelegt.
Der Nutzer ist verpflichtet, richtige, aktuelle und vollständige Registrierungsdaten anzugeben und die Registrierungsdaten stets aktuell und vollständig zu halten.
Der Nutzer ist verpflichtet, sein Passwort jederzeit geheim zu halten und sicher zu verwahren und alle anderen angemessenen Maßnahmen in seinem Verantwortungsbereich zu treffen, um einen unbefugten Zugriff auf sein Benutzerkonto zu verhindern.
upmesh betrachtet unter Verwendung der Registrierungsdaten des Nutzers im Rahmen der Inanspruchnahme des Service erteilte Weisungen als Weisungen des Nutzers. Der Nutzer ist für alle Aktivitäten verantwortlich, die unter Verwendung seiner Registrierungsdaten vorgenommen werden.
Der Nutzer hat upmesh sofort zu benachrichtigen, wenn ihm eine unbefugte Nutzung seiner Registrierungsdaten bekannt wird oder er eine solche vermutet.


4. Beauftragung kostenpflichtiger Leistungen 
Die Nutzung von Basisfunktionalitäten des Service ist nicht vergütungspflichtig. Eine über bestimmte die Basisfunktionalitäten hinausgehende Nutzung des Service ist kostenpflichtig. Die kostenlosen Basisfunktionalitäten sowie die in den einzelnen Preismodellen enthaltenen Funktionalitäten und Rechte sind in www.upmesh.de/preise beschrieben. Möchte der Nutzer über die Basisfunktionalitäten hinausgehende Funktionalitäten des Service nutzen, muss er den Service mit dem entsprechenden Preismodell bei upmesh beauftragen. Der Nutzer kann die Funktionalitäten seines beauftragten Preismodells nur in durch den Nutzer selbst erstellten oder verwalteten Projekten oder in Projekten, die von Nutzern des gleichen oder eines höheren Preismodells erstellt und verwaltet werden, voll nutzen.
Als registrierter Nutzer kann der Nutzer über die von upmesh bereitgestellte elektronische Bestellfunktion den gewünschten Service beauftragen. Der Bestellvorgang läuft in drei Schritten ab: Im ersten Schritt wird das vom Nutzer gewünschte Preismodell und die Mindestlaufzeit (monatlich oder jährlich) ausgewählt. Im zweiten Schritt werden die Daten des Nutzers bestätigt und ggfs. ergänzt und die Bezahlmethode gewählt. Im dritten Schritt kann der Nutzer eine abschließende Prüfung des Auftrags vornehmen und ihn durch Betätigen des Bestell-Buttons verbindlich an upmesh absenden. Damit gibt er ein verbindliches Angebot ab. Der Nutzer erhält dann eine E-Mail-Bestätigung über den Eingang und die Annahme des Auftrags. Mit Zugang dieser Email kommt der Vertrag zustande.
Eine Beauftragung kann nach vorheriger Absprache mit upmesh auch außerhalb der von upmesh angebotenen elektronischen Bestellfunktion durch schriftliche Übermittlung eines Auftrags mit den notwendigen Angaben und Übermittlung einer entsprechenden schriftlichen Auftragsbestätigung von upmesh an den Nutzer erfolgen.


5. Lizenz
upmesh räumt dem Nutzer ein einfaches, nicht ausschließliches, nicht übertragbares, unentgeltliches und auf die Laufzeit dieser Vereinbarung befristetes Recht ein, den Service zu den Zwecken zu nutzen, für die er von upmesh nach dieser Vereinbarung zur Verfügung gestellt wird. Der Umfang der Nutzung ist auf den im Preismodell von upmesh beschriebenen Rahmen beschränkt.
Der Nutzer darf den Service und die für seine Erbringung eingesetzten Komponenten insbesondere nicht kopieren, verändern, anpassen oder abgeleitete Werke davon erstellen, veröffentlichen, offenlegen, verkaufen, vermieten, verpachten, verleihen, verteilen, online zugänglich machen, unterlizenzieren, oder einer dritten Partei zur Verfügung stellen, ohne dass upmesh hierzu seine vorherige schriftliche Zustimmung gegeben hat. Er darf Urheberrechtshinweise oder sonstige Hinweise auf das geistige Eigentum nicht entfernen oder verändern. Ein Zugriff auf Source Codes ist in der Lizenz nicht enthalten. Der Nutzer darf Komponenten des Service nicht dekompilieren, disassemblieren oder zurückentwickeln (Reverse Engineering), soweit sich aus zwingendem Gesetzesrecht oder dieser Vereinbarung nichts anderes ergibt.


6. Vergütung 
Die Nutzung mit Basisfunktionalität (Preismodell “Basic”) sind unentgeltlich. Upmesh behält sich das Recht vor, Nutzern dieses Preismodell Werbebanner innerhalb der WebApp und App einzublenden.
Die Nutzung des Service in allen anderen Preismodellen ist kostenpflichtig. Es gilt das von upmesh im Zeitpunkt der Bestellbestätigung angebotene Preismodell www.upmesh.de/preise und die für das jeweilige Preismodell jeweils festgelegte Vergütung. Die Vergütung gilt zuzüglich der jeweils anwendbaren gesetzlichen Mehrwertsteuer.
upmesh kann die Vergütung für den Service durch einseitige Erklärung mit einer Ankündigungsfrist von mindestens einem Monat ändern und wird den Nutzer in diesem Fall darüber schriftlich informieren. Hat der Nutzer den Service mit jährlicher Kündigungsmöglichkeit beauftragt, ist eine Änderung der Vergütung jeweils nur mit einer Ankündigungsfrist von 3 Monaten möglich und der Nutzer kann im Falle einer Preiserhöhung die Vereinbarung mit Wirkung zum Inkrafttreten der Preiserhöhung kündigen. Das Kündigungsrecht kann der Nutzer nur innerhalb eines Monats nach Zugang der Nachricht über die Preiserhöhung schriftlich ausüben. Macht der Nutzer von seinem Kündigungsrecht keinen Gebrauch, wird die Preisänderung zum angekündigten Zeitpunkt automatisch wirksam.
Die Rechnungsstellung erfolgt im Voraus für die jeweils vereinbarte Laufzeit (Monat/Jahr). Rechnungen sind mit Erhalt sofort fällig und innerhalb von 10 Tagen zu begleichen. Nach Ablauf der Zahlungsfrist gerät der Nutzer automatisch in Verzug.


7. Pflichten des Nutzers
Der Nutzer hat über den Service die Möglichkeit, Nutzerdaten (z.B. Pläne, Fotos, Zeichnungen, technische Beschreibungen, eMails, Dokumente, Kommentare, Sprachnachrichten, Videos etc.) zu speichern. An diesen Nutzerdaten gewährt der Nutzer upmesh ein unwiderrufliches, weltweites, kostenloses, übertragbares, zeitlich unbefristetes, unterlizensierbares Recht zur Nutzung, Vervielfältigung, Verbreitung, Übersetzung und Veröffentlichung, jedoch ausschließlich für den Zweck der vertragsmäßigen Erbringung des Service (z.B. Teilen der Daten mit anderen Projektbeteiligten).
Soweit der Nutzer bei der Nutzung des Service upmesh Nutzerdaten zur Verfügung stellt (z.B. in den von upmesh bereitgestellten Speicher hochlädt), gewährleistet der Nutzer insbesondere, dass 1. Der Nutzer die zur Erbringung des Service durch upmesh erforderlichen Rechte besitzt und alle dafür ggfs. notwendigen Einwilligungen eingeholt hat;
Die zur Erbringung des Service durch upmesh erforderliche Verwendung der Nutzerdaten keine Urheberrechte, Markenrechte, Patentrechte, Geschäftsgeheimnisse oder sonstigen Rechte Dritter (z.B. das Datenschutzrecht oder andere Persönlichkeitsrechte) verletzt;
Die Übermittlung der Nutzerdaten und ihre vertragsgemäße Verwendung durch upmesh zur Erbringung des Service auch im Übrigen keine Gesetze oder Rechte verletzen;
Die Nutzerdaten keine Viren, Trojaner oder andere Schadsoftware enthalten. Insbesondere werden die Daten keine pornografischen, gewaltverherrlichenden oder volksverhetzenden Inhalte enthalten. Der Nutzer wird upmesh unverzüglich informieren, sobald Anhaltspunkte für eine Verletzung der vorstehend beschriebenen Gewährleistungen vorliegen.
Der Nutzer ist für die mit dem Service verarbeiteten Nutzerdaten und sonstigen Daten selbst verantwortlich. upmesh ist nicht verpflichtet, Nutzerdaten auf Rechtsverstöße zu überprüfen. upmesh ist jedoch berechtigt, die Verarbeitung der Nutzerdaten durch den Service zu verweigern oder übermittelte Nutzerdaten zu löschen, wenn nach Ermessen von upmesh Anhaltspunkte dafür vorliegen, dass ihre Verwendung gegen diese Vereinbarung verstoßen oder zu Rechtsverletzungen führen könnte.
upmesh darf den Zugang zum Service für den Nutzer jederzeit sperren, wenn der Nutzer gegen seine Pflichten aus dieser Vereinbarung (insbesondere gegen die Gewährleistungen in Ziffer 7.2) verstößt.


8. Verknüpfungen zu anderen Webseiten oder Diensten (Hyperlinks)
Elemente des Service oder die Webseite von upmesh können Hyperlinks auf Webseiten Dritter enthalten. upmesh übernimmt für die Inhalte dieser Webseiten weder eine Verantwortung noch macht upmesh sich diese Webseiten und ihre Inhalte zu eigen.


9. Haftung für Rechts- und Sachmängel
upmesh übernimmt keinerlei Gewährleistungen oder Garantien, dass der Service 1. Mit der Hardware oder Software des Nutzers kompatibel ist;
Jederzeit oder zu bestimmten Zeiten uneingeschränkt zur Verfügung steht;
Die Anforderungen des Nutzers oder sonstige spezielle Leistungs- oder Funktionsanforderungen, die in dieser Vereinbarung nicht vereinbart sind, erfüllt;
Keine Datenverluste verursacht;
Frei von Viren, Schadprogrammen, Störungen oder anderen sicherheitsgefährdenden Bestandteilen ist.
Zum eigenen Schutz sowie zum Schutz des Service wird der Nutzer angemessene und aktuelle Sicherheitsvorrichtungen (Firewall, Virenscanner etc.) einsetzen.
Stellt der Nutzer einen Mangel des Service fest, wird er upmesh unverzüglich darüber informieren und den Mangel und die Begleitumstände seines Auftretens möglichst detailliert beschreiben. upmesh wird sich bemühen, den Mangel innerhalb angemessener Zeit zu beheben. Erst nach zweimalig fehlgeschlagener Nachbesserung kann der Nutzer (bei entgeltlicher Nutzung) eine angemessene Herabsetzung der Vergütung für den jeweiligen Berechnungszeitraum verlangen oder den Vertrag kündigen, wenn ihm dessen Fortsetzung nicht mehr zumutbar ist. Weitere Ansprüche des Nutzers bei Mängeln sind ausgeschlossen.
Keine Bestimmung in dieser Vereinbarung schließt die Haftung von upmesh dem Nutzer gegenüber aus für Personenschäden, Vorsatz oder arglistige Täuschung sowie sonstige Fälle zwingender Haftung, die gesetzlich nicht ausgeschlossen werden können.
Bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten haftet upmesh nur bis zur Höhe des vertragstypischen und vorhersehbaren Schadens. Eine wesentliche Vertragsverletzung liegt vor, wenn sich die Pflichtverletzung auf eine Pflicht bezieht, auf deren Erfüllung der Nutzer vertraut hat und auch vertrauen durfte.
Die Haftung von upmesh für fahrlässige Vertragspflichtverletzungen ist außerdem insgesamt gegenüber dem Nutzer auf einen Betrag von EUR 1.000 pro Kalenderjahr beschränkt. Ziffer 9.4 bleibt unberührt.
Bei unentgeltlicher Nutzung des Service ist eine Haftung von upmesh gegenüber dem Nutzer außer in den in Ziffer 9.4 genannten Fällen vollständig ausgeschlossen.


10. Freistellung
Der Nutzer stellt upmesh, ihre Führungskräfte, Mitarbeiter und Subunternehmer von allen Forderungen und Ansprüchen Dritter sowie von sämtlichen Verlusten, Schäden, Kosten und Ausgaben, einschließlich angemessener Rechtsverteidigungskosten frei, die aus einer nicht dieser Vereinbarung entsprechenden Nutzung des Service oder einer Verletzung von Gesetzen oder Rechten Dritter durch Nutzerdaten, den Nutzer oder eine Person, der der Nutzer die Nutzung des Service erlaubt, resultieren und vom Nutzer zu vertreten sind.


11. Support und Updates
upmesh bietet dem Nutzer einen Support gemäß des gewählten Preismodells an. Die Mängelbeseitigungspflichten von upmesh gemäß Ziffer 9 bleiben unberührt.


12. Laufzeit und Kündigung
Diese Vereinbarung tritt in dem in Ziffer 2.4 beschriebenen Zeitpunkt in Kraft und gilt bei entgeltpflichtiger Nutzung für die jeweils beauftragte Laufzeit. Bei nicht entgeltpflichtiger Nutzung gilt die Vereinbarung unbefristet.
Bei entgeltpflichtiger Nutzung des Service ist eine Kündigung nur zum Ablauf der jeweils beauftragten Laufzeit möglich. Erfolgt keine Kündigung, verlängert sich die Laufzeit automatisch jeweils um den ursprünglich beauftragten Zeitraum (d.h. einen oder zwölf Monate). Bei monatlicher Laufzeit beträgt die Kündigungsfrist 14 Tage zum Monatsende, bei jährlicher Laufzeit 30 Tage zum Ende des Vertragsjahres. Nutzt der Nutzer den Service entgeltfrei, kann die Vereinbarung von beiden Parteien jederzeit mit Wirkung zum Ende des laufenden Monats gekündigt werden.
Das Recht, diese Vereinbarung aus wichtigem Grund zu kündigen, bleibt in jedem Fall unberührt.
Nach Kündigung dieser Vereinbarung hat der Nutzer seine erfassten Nutzerdaten in dem Dienst endgültig zu löschen, sofern diese nicht noch von anderen Nutzern mit weiterbestehender Vereinbarung weitergenutzt werden. Projekte, die von dem Nutzer verwaltet werden, müssen auf andere Nutzer übertragen oder gelöscht werden. Nimmt der Nutzer die Löschung oder Übertragung nicht selbst vor, behält upmesh sich das Recht vor, die von dem Nutzer verwalteten Projekte (und die zug. Nutzerdaten) nach Beendigung der Vereinbarung zu löschen. Der Nutzer kann über die im Service angebotenen Berichtsfunktionen vor Beendigung der Vereinbarung nutzen, um eine Kopie seiner Nutzerdaten herunterzuladen und diese auf seinen Systemen speichern oder ausdrucken.
upmesh behält sich das Recht vor, den Service jederzeit auch ohne Benachrichtigung vollständig oder teilweise zu modifizieren. Dabei wird upmesh gewährleisten, dass die Nutzer mit einem kostenpflichtigen Preismodell die zugesagten Funktionen weiterhin nutzen können.


13. Vertraulichkeit und Datenschutz
Der Nutzer verpflichtet sich, sämtliche ihm im Zusammenhang mit dieser Vereinbarung und den unter ihr erbrachten Leistungen bekanntwerdenden Informationen, die als vertraulich bezeichnet oder aufgrund sonstiger Umstände als vertraulich oder als Geschäfts- oder Betriebsgeheimnisse erkennbar sind, unbefristet geheim zu halten und sie nur für Zwecke dieser Vereinbarung zu nutzen.
upmesh speichert und verarbeitet vom Nutzer übermittelte personenbezogene Daten ausschließlich zur Erbringung des Service und unter Beachtung des anwendbaren Datenschutzrechts. Der Nutzer hat sicherzustellen, dass eine eventuelle Übermittlung personenbezogener Daten an upmesh (insbesondere als Teil der Nutzerdaten) in Übereinstimmung mit dem anwendbaren Datenschutzrecht erfolgt. upmesh ist bei Bedarf grundsätzlich bereit, mit dem Nutzer eine Auftragsdatenverarbeitungsvereinbarung gemäß § 11 BDSG abzuschließen. Eine Vertragsvorlagen kann unter www.upmesh.de/datenschutz heruntergeladen werden.
upmesh weist ausdrücklich darauf hin, dass Nutzerdaten unter Nutzung von Cloud-Services, die Subunternehmer von upmesh zur Verfügung stellen, gespeichert werden. Eine Speicherung von Nutzerdaten an Standorten außerhalb der EU und des EWR findet nicht statt.
Soweit upmesh im Rahmen der Nutzung des Service durch den Nutzer selbst personenbezogene Daten erhebt und verarbeitet, geschieht dies in Übereinstimmung mit der Datenschutzerklärung, die www.upmesh.de/datenschutz abrufbar ist.


14. Sonstiges
Sollte sich eine Bestimmung dieser Vereinbarung als ungültig oder nicht durchsetzbar erweisen, wird die Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen dieser Vereinbarung nicht berührt. An Stelle der ungültigen Bestimmung gilt mit Rückwirkung diejenige wirksame und durchführbare Regelung als vereinbart, die dem von den Parteien rechtlich und wirtschaftlich Gewollten am nächsten kommt.
Nebenabreden bedürfen der Schriftform.
Soweit in dieser Vereinbarung für Erklärungen die Schriftform vorgeschrieben wird, ist eine Erklärung in Textform ausreichend. Dies gilt nicht für Kündigungen gemäß Ziffer 11, für die die gesetzliche Schriftform gemäß § 126 Abs. 1 BGB gilt.
Es gilt deutsches Recht unter Ausschluss des Kollisionsrechts und des UN-Kaufrechts. Gerichtsstand ist, wenn der Nutzer Kaufmann im Sinne des Handelsgesetzbuches ist, Ibbenbüren.
`
        })]
      });
    }
  }]);
}(_react.PureComponent);
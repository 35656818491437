var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAddressInfoFromGoogleResponse = exports.GooglePlacesInput = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _FormInputFilled = require("../forminput/FormInputFilled");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var getAddressInfoFromGoogleResponse = exports.getAddressInfoFromGoogleResponse = function getAddressInfoFromGoogleResponse(description, details) {
  var requiredProps = ['street_number', 'route', 'locality', 'country', 'postal_code', 'floor'];
  var storedPropsFromGoogleProps = {
    floor: 'floor',
    street_number: 'houseNumber',
    route: 'road',
    country: 'country',
    postal_code: 'postcode',
    locality: 'city'
  };
  var result = {};
  details.address_components.forEach(function (component) {
    var propType = component.types[0];
    if (requiredProps.includes(propType)) {
      result[storedPropsFromGoogleProps[propType]] = component.long_name;
      if (propType === 'country') {
        result.countryCode = component.short_name;
      }
    }
  });
  result.latitude = details.geometry.location.lat.toString();
  result.longitude = details.geometry.location.lng.toString();
  result.addressInput = description;
  return result;
};
var GooglePlacesInput = exports.GooglePlacesInput = function GooglePlacesInput(_ref) {
  var _ref$initialText = _ref.initialText,
    initialText = _ref$initialText === void 0 ? '' : _ref$initialText,
    _ref$lang = _ref.lang,
    lang = _ref$lang === void 0 ? 'de' : _ref$lang,
    onChange = _ref.onChange,
    onChangeText = _ref.onChangeText,
    backendURL = _ref.backendURL,
    labelText = _ref.labelText,
    onPressMapMarker = _ref.onPressMapMarker,
    onUpdateFirstListItem = _ref.onUpdateFirstListItem;
  var _useState = (0, _react.useState)(initialText),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    text = _useState2[0],
    setText = _useState2[1];
  var _useState3 = (0, _react.useState)(1),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    inputKey = _useState4[0],
    setInputKey = _useState4[1];
  var _useState5 = (0, _react.useState)([]),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    autoCompleteList = _useState6[0],
    setAutoCompleteList = _useState6[1];
  var abortController;
  var fetchDetailsTO;
  var getDetails = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (t, callback) {
      try {
        if (fetchDetailsTO) clearTimeout(fetchDetailsTO);
        if (abortController) abortController.abort();
      } catch (e) {}
      if (t.place_id == null) {
        return;
      }
      abortController = new AbortController();
      var _abortController = abortController,
        signal = _abortController.signal;
      fetchDetailsTO = setTimeout(function () {
        fetch(`${backendURL}/place/details/json?&placeid=${t.place_id}&language=${lang}`, {
          signal: signal
        }).then(function (result) {
          if (result.status === 200) {
            result.json().then(function (json) {
              if (json.result && callback != null) callback(getAddressInfoFromGoogleResponse(t.description, json.result));
            }).catch(function (err) {
              return void 0;
            });
          }
        }).catch(function (err) {
          setAutoCompleteList([]);
        });
      }, 400);
    });
    return function getDetails(_x, _x2) {
      return _ref2.apply(this, arguments);
    };
  }();
  var onPressAutoComplete = function onPressAutoComplete(d) {
    return function () {
      setText(d.description);
      setInputKey(inputKey + 1);
      if (onChange != null) {
        getDetails(d, onChange).catch(function (err) {
          return void 0;
        });
      }
    };
  };
  var getFirstListItem = function getFirstListItem(d) {
    if (onUpdateFirstListItem != null) {
      getDetails(d, onUpdateFirstListItem).catch(function (err) {
        return void 0;
      });
    }
  };
  var fetchTO;
  var getLocations = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(function* (t) {
      try {
        if (fetchTO) clearTimeout(fetchTO);
        if (abortController) abortController.abort();
      } catch (e) {}
      if (t.length < 3) {
        setAutoCompleteList([]);
        return;
      }
      abortController = new AbortController();
      var _abortController2 = abortController,
        signal = _abortController2.signal;
      fetchTO = setTimeout(function () {
        fetch(`${backendURL}/place/autocomplete/json?&input=${t}&language=${lang}`, {
          signal: signal
        }).then(function (result) {
          var autoComplete = [];
          if (result.status === 200) {
            result.json().then(function (json) {
              if (json != null && json.predictions != null && json.predictions.length > 0) {
                if (onUpdateFirstListItem != null) getFirstListItem(json.predictions[0]);
                json.predictions.forEach(function (e) {
                  autoComplete.push({
                    title: e['description'],
                    onPress: onPressAutoComplete(e)
                  });
                });
              }
              setAutoCompleteList(autoComplete);
            }).catch(function (err) {
              return void 0;
            });
          }
        }).catch(function (err) {
          setAutoCompleteList([]);
        });
      }, 400);
    });
    return function getLocations(_x3) {
      return _ref3.apply(this, arguments);
    };
  }();
  var onChanged = function onChanged(t) {
    setText(t);
    if (onChangeText != null) onChangeText(t);
    getLocations(t).catch(function (err) {
      return void 0;
    });
  };
  var iconRight = onPressMapMarker != null ? {
    icon: 'map-marker',
    toolTip: '',
    onPress: onPressMapMarker
  } : undefined;
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: {
      width: '100%',
      height: 'auto',
      overflow: 'visible'
    },
    children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
      initValue: text,
      style: {
        width: '100%'
      },
      labelText: labelText != null && labelText.length > 0 ? labelText : _I18n.I18n.m.getMessage('address'),
      onChange: onChanged,
      autoCompleteList: autoCompleteList,
      autoCompleteSearchEnabled: false,
      iconRight: iconRight
    }, `input_${inputKey.toString()}`)
  });
};
GooglePlacesInput.defaultProps = {
  lang: 'de',
  labelText: '',
  onPressMapMarker: undefined,
  onChangeText: undefined
};
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileDropHandler = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var uuid = _interopRequireWildcard(require("uuid"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var FileDropHandler = exports.FileDropHandler = function () {
  function FileDropHandler() {
    (0, _classCallCheck2.default)(this, FileDropHandler);
  }
  return (0, _createClass2.default)(FileDropHandler, null, [{
    key: "targets",
    get: function get() {
      return Array.from(FileDropHandler._targets.values());
    }
  }, {
    key: "addTarget",
    value: function addTarget(target) {
      var key = target.id != null ? target.id : uuid.v1();
      if (target.id == null) {
        target.id = key;
      }
      FileDropHandler._targets.set(key, target);
      return key;
    }
  }, {
    key: "removeTarget",
    value: function removeTarget(key) {
      if (FileDropHandler._targets.has(key)) {
        FileDropHandler._targets.delete(key);
      }
    }
  }]);
}();
FileDropHandler._targets = new Map();
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Folder = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var CommandReadModels_1 = require("../../server/webserver/commands/CommandReadModels");
var FolderEntity_1 = require("./entities/FolderEntity");
var Folder = function (_ReadModelClientOffli) {
  function Folder() {
    var _this;
    (0, _classCallCheck2.default)(this, Folder);
    _this = _callSuper(this, Folder, arguments);
    _this.exampleEntity = new FolderEntity_1.FolderEntity();
    _this.onEvents = [{
      eventName: 'FolderCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'FolderChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'FolderDeleted',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(Folder, _ReadModelClientOffli);
  return (0, _createClass2.default)(Folder, [{
    key: "onFolderCreated",
    value: function () {
      var _onFolderCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity._access = [];
        var p = yield CommandReadModels_1.CommandReadModels.project.getById(entity.projectId);
        entity._access.push({
          userId: p.creator
        });
        var roles = yield CommandReadModels_1.CommandReadModels.projectRoles.get({
          select: ['id'],
          filter: `(projectId eq '${p.id}' or projectId eq '0') and writeRights/commandEditFolders eq true and delete ne true`
        });
        var roleIds = [];
        roles.forEach(function (r) {
          return roleIds.push(r.id);
        });
        if (roleIds.length > 0) {
          var user = yield CommandReadModels_1.CommandReadModels.projectUser.get({
            select: 'userId',
            filter: `projectId eq '${p.id}' and roleId in ${JSON.stringify(roleIds)}`
          });
          user.forEach(function (_ref) {
            var _entity$_access;
            var userId = _ref.userId;
            if (((_entity$_access = entity._access) == null ? void 0 : _entity$_access.findIndex(function (a) {
              return a.userId === userId;
            })) === -1) entity._access.push({
              userId: userId
            });
          });
        }
        return entity;
      });
      function onFolderCreated(_x, _x2) {
        return _onFolderCreated.apply(this, arguments);
      }
      return onFolderCreated;
    }()
  }, {
    key: "onFolderDeleted",
    value: function () {
      var _onFolderDeleted = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.deleted = e.data.deleted;
        return entity;
      });
      function onFolderDeleted(_x3, _x4) {
        return _onFolderDeleted.apply(this, arguments);
      }
      return onFolderDeleted;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.Folder = Folder;
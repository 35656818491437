import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
export function ProjectTemplateStatus(props) {
    if (!props.project.template) {
        return null;
    }
    return (<View>
      <View style={{ marginTop: 16 }}>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectTemplateStateHeader')}</MaterialText>
      </View>
      <MaterialText>{I18n.m.getMessage('projectTemplateStateText')}</MaterialText>
    </View>);
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormHeader = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FormHeader = exports.FormHeader = function (_PureComponent) {
  function FormHeader() {
    (0, _classCallCheck2.default)(this, FormHeader);
    return _callSuper(this, FormHeader, arguments);
  }
  (0, _inherits2.default)(FormHeader, _PureComponent);
  return (0, _createClass2.default)(FormHeader, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        formText = _this$props.formText,
        formTitle = _this$props.formTitle,
        titlePaddingBottom = _this$props.titlePaddingBottom;
      if (formText == null && formTitle == null) {
        return null;
      }
      var returner = [];
      if (formTitle != null) {
        returner.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 'auto',
            minHeight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
            width: '100%',
            paddingBottom: titlePaddingBottom == null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16) : titlePaddingBottom
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.H6,
            children: formTitle
          })
        }, "formTitle"));
      }
      if (formText != null) {
        returner.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 'auto',
            width: '100%',
            paddingBottom: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16)
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            children: formText
          })
        }, "formText"));
      }
      return returner;
    }
  }]);
}(_react.PureComponent);
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeProjectTicketType, } from 'upmesh-core/src/client/commands/project/tickettypes/ChangeProjectTicketType';
import { I18n } from '../../../i18n/I18n';
export class ChangeTicketTypeProjectDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    render() {
        const { project, type } = this.props;
        const currentData = { oldValue: type, newValue: type };
        const dataOptions = new Map();
        dataOptions.set('newValue', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('ticketType') },
        });
        return (<Form key="changeProjectUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('projectChangeTicketTypeDialogTitle'),
            }} stickyActions={false} command={new ChangeProjectTicketType(currentData, project.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[
                <ContainedButton key="cancel" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React from 'react';
import { View } from 'react-native';
export function IframeWidget(props) {
    return (<View style={{ width: '100%', height: '100%' }}>
      {props.title != null && props.title.length > 0 && (<View style={{ padding: 16 }}>
          <MaterialText type={MaterialTextTypes.H5}>{props.title}</MaterialText>
        </View>)}
      <View style={{ flex: 1 }}>
        <iframe style={{ flex: 1, width: '100%' }} src={props.url} title={props.title}/>
      </View>
    </View>);
}

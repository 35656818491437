var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateRangeInputFormFilled = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _DateLocale = require("upmesh-i18n/build/src/i18n/DateLocale");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Datepicker = require("../datepickerv2/Datepicker");
var _KeyboardEvents = require("../utils/KeyboardEvents");
var _FormInputFilled = require("./FormInputFilled");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DateRangeInputFormFilled = exports.DateRangeInputFormFilled = function (_PureComponent) {
  function DateRangeInputFormFilled(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DateRangeInputFormFilled);
    _this = _callSuper(this, DateRangeInputFormFilled, [props]);
    _this.clearText = function (errorCode) {
      var error = errorCode != null ? errorCode : '';
      _this.setState({
        error: error,
        textValue: '',
        selectedDate: undefined
      }, function () {
        if (_this.props.onChange != null) {
          _this.props.onChange(undefined);
        }
      });
    };
    _this.focusNextField = function () {
      if (_this.props.nextField != null) {
        if (typeof _this.props.nextField === 'function') {
          _this.props.nextField();
        } else if (typeof _this.props.nextField['focus'] === 'function') {
          _this.props.nextField.focus();
        }
      }
    };
    _this.changeDate = function (date) {
      if (date instanceof Date) return;
      var textValue = _this.createTextValue(date);
      if (textValue === 'invalidDate') {
        _this.clearText('invalidDate');
      } else {
        _this.setState({
          textValue: textValue,
          selectedDate: {
            from: new Date(date.from),
            to: new Date(date.to)
          },
          error: ''
        }, function () {
          if (_this.props.onChange != null) {
            _this.props.onChange(date);
          }
        });
      }
    };
    _this.onBlur = function () {
      _this.stopKeyboardEvents();
      _this.onChangedTextValue();
    };
    _this.onChangeTextValue = function (text) {
      var textValue = text.replace(/[^\d./: -]/g, '');
      _this.setState({
        textValue: textValue,
        error: ''
      }, function () {
        if (text == null || text.length === 0) {
          _this.onChangedTextValue();
        }
        _this.forceUpdate();
      });
    };
    _this.onChangedTextValue = function () {
      var text = _this.state.textValue;
      if ((text == null || text.length === 0) && _this.state.formInput == null) {
        _this.clearText();
        return;
      }
      var dateString = text;
      if (text == null && _this.state.formInput != null) {
        dateString = _this.state.formInput.getValue();
      }
      if (dateString == null || dateString.length === 0) {
        _this.clearText();
        return;
      }
      if (_this.props.selectTime === true) {
        try {
          var split = dateString.split('-');
          var from = _this.locale.localeDateFromStringWithTime(split[0].trim());
          var to = _this.locale.localeDateFromStringWithTime(split[1].trim());
          var _selectedDate = {
            from: from,
            to: to
          };
          if (_this.state.selectedDate == null || _selectedDate.from.toISOString() !== _this.state.selectedDate.from.toISOString() || _selectedDate.to.toISOString() !== _this.state.selectedDate.to.toISOString()) {
            _this.changeDate(_selectedDate);
          }
        } catch (_e) {
          if (dateString.length > 0) {
            _this.clearText('invalidDate');
          } else {
            _this.clearText();
          }
        }
      } else {
        try {
          var _split = dateString.split('-');
          var _from = _this.locale.localeDateFromString(_split[0].trim());
          var _to = _this.locale.localeDateFromString(_split[1].trim());
          var _selectedDate2 = {
            from: _from,
            to: _to
          };
          if (_this.state.selectedDate == null || _selectedDate2.from.toISOString() !== _this.state.selectedDate.from.toISOString() || _selectedDate2.to.toISOString() !== _this.state.selectedDate.to.toISOString()) {
            _this.changeDate(_selectedDate2);
          }
        } catch (_e) {
          if (dateString.length > 0) {
            _this.clearText('invalidDate');
          } else {
            _this.clearText();
          }
        }
      }
    };
    _this.onFocus = function () {
      _this.startKeyboardEvents();
    };
    _this.openDatePicker = function (e) {
      var _this$state$formInput, _this$state$formInput2;
      var _this$props = _this.props,
        selectTime = _this$props.selectTime,
        timeFormat = _this$props.timeFormat,
        startDate = _this$props.startDate,
        labelText = _this$props.labelText,
        coloredDates = _this$props.coloredDates,
        quickSelections = _this$props.quickSelections,
        timeQuickSelections = _this$props.timeQuickSelections;
      var selectedDate = _this.state.selectedDate;
      _this.stopKeyboardEvents();
      var date = selectedDate != null ? {
        from: new Date(selectedDate.from),
        to: new Date(selectedDate.to)
      } : startDate != null ? {
        from: new Date(startDate.from),
        to: new Date(startDate.to)
      } : undefined;
      _Datepicker.Datepicker.open({
        onChange: _this.changeDate,
        mode: selectTime ? 'both' : 'date',
        range: true,
        labelText: labelText,
        timeFormat: timeFormat,
        selectedDate: ((_this$state$formInput = _this.state.formInput) == null ? void 0 : _this$state$formInput.getValue()) === null || ((_this$state$formInput2 = _this.state.formInput) == null ? void 0 : _this$state$formInput2.getValue()) === '' ? undefined : date,
        selectTimeMinutesIntervall: _this.props.selectTimeMinutesIntervall,
        coloredDates: coloredDates,
        quickSelections: quickSelections,
        timeQuickSelections: timeQuickSelections
      }, e);
    };
    _this.setFocus = function (_e) {
      _this.openDatePicker(_e);
    };
    _this.setRef = function (formInput) {
      _this.setState({
        formInput: formInput
      });
    };
    _this.startKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        if (e.keyCode === 40) {
          e.preventDefault();
          _this.openDatePicker();
        }
      }, 'DatePickerFormInput');
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('DatePickerFormInput');
    };
    var selectedDate = props.initValue != null ? props.initValue : props.value;
    var timeFormat = _this.props.timeFormat != null ? _this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
    var locale = _CurrentUser.CurrentUser.entity != null && _CurrentUser.CurrentUser.entity.locale != null ? _CurrentUser.CurrentUser.entity.locale : 'de';
    _this.locale = new _DateLocale.DateLocale(locale, timeFormat);
    _this.state = {
      selectedDate: selectedDate,
      formInput: null,
      textValue: _this.createTextValue(selectedDate)
    };
    return _this;
  }
  (0, _inherits2.default)(DateRangeInputFormFilled, _PureComponent);
  return (0, _createClass2.default)(DateRangeInputFormFilled, [{
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {
      this.clearText();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.stopKeyboardEvents();
    }
  }, {
    key: "focus",
    value: function focus() {
      var formInput = this.state.formInput;
      if (formInput != null) {
        formInput.focus();
      } else {
        this.openDatePicker();
      }
    }
  }, {
    key: "getId",
    value: function getId() {
      if (this.state.formInput != null) {
        return this.state.formInput.getId();
      }
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.state.selectedDate;
    }
  }, {
    key: "render",
    value: function render() {
      var formProps = Object.assign({}, this.props);
      var keyboardType = _reactNativeWeb.Platform.OS !== 'web' ? 'numeric' : undefined;
      var _this$state = this.state,
        error = _this$state.error,
        textValue = _this$state.textValue;
      if (error != null && error.length > 0) {
        formProps.error = true;
        formProps.helperText = _I18n.I18n.m.getMessage('invalidDate');
      }
      var disabled = this.props.disabled;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          overflow: 'visible',
          width: '100%',
          flexDirection: 'row',
          height: 'auto'
        },
        children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, Object.assign({}, formProps, {
          ref: this.setRef,
          keyboardType: keyboardType,
          overrideOnPressFocus: _reactNativeWeb.Platform.OS === 'web' ? undefined : this.openDatePicker,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          iconRight: disabled ? undefined : {
            icon: 'menu-down',
            toolTip: '',
            onPress: this.setFocus
          },
          disabled: disabled,
          value: textValue,
          clearButtonAlwaysVisible: this.props.clearButton,
          clearButton: this.props.clearButton,
          onChange: this.onChangeTextValue,
          focusAfterClear: false
        }))
      });
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      var textValue = this.createTextValue(value);
      if (textValue === 'invalidDate') {
        this.clearText('invalidDate');
      } else {
        this.setState({
          textValue: textValue,
          selectedDate: value
        });
      }
    }
  }, {
    key: "createTextValue",
    value: function createTextValue(date) {
      if (date == null) return '';
      var t1 = this.createSingleTextValue(date.from);
      var t2 = this.createSingleTextValue(date.to);
      return `${t1} - ${t2}`;
    }
  }, {
    key: "createSingleTextValue",
    value: function createSingleTextValue(date) {
      var selectTime = this.props.selectTime;
      if (date == null) return '';
      var text = `${this.locale.localeDateString(date)}`;
      if (text === 'Invalid date') {
        return 'invalidDate';
      }
      if (selectTime) {
        var time = this.locale.getLocalTimeString(date);
        text = `${text} ${time}`;
      }
      return text;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        labelText: 'Datum',
        disabled: false,
        value: undefined,
        datePickerColor: _ThemeManager.ThemeManager.style.datePickerColor,
        datePickerTextColor: _ThemeManager.ThemeManager.style.datePickerTextColor,
        selectTimeMinutesIntervall: 5,
        clearButton: true,
        showKW: _CurrentUser.CurrentUser.settings.showKW
      };
    }
  }]);
}(_react.PureComponent);
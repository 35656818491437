import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
export default class VCardGenerator {
    static fromUserEntity(user) {
        const vcard = new VCardGenerator(`${user.firstname} ${user.lastname}`);
        vcard.addName(user.firstname, user.lastname);
        if (user.phone != null) {
            vcard.addSingleTelephone(user.phone);
        }
        vcard.addLang(user.locale);
        vcard.addKind('individual');
        if (user.emails != null && user.emails.length >= 1) {
            vcard.addEmail(user.emails[0]);
        }
        return vcard;
    }
    static async fromUserEntityWithLogo(user) {
        const vcard = VCardGenerator.fromUserEntity(user);
        if (user.userImageFileId != null && user.userImageFileId.length > 0) {
            const headers = {};
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            headers['Accept'] = 'image/png';
            const result = await fetch(`${UpmeshClient.instance.url}/user/${user.userId}/image/${user.userImageFileId}?w=32&h=32`, { headers, method: 'GET' });
            if (result.status === 200) {
                const data = await result.arrayBuffer();
                vcard.addBase64Photo('image/jpeg', VCardGenerator.arrayBufferToString(data));
            }
        }
        return vcard;
    }
    static arrayBufferToString(inputData) {
        const byteArray = new Uint8Array(inputData);
        let binary = '';
        byteArray.forEach((byte) => {
            binary += String.fromCharCode(byte);
        });
        return btoa(binary);
    }
    static fromCompanyEntity(company) {
        const vCard = new VCardGenerator(company.company);
        vCard.addName(company.company, '');
        vCard.addOrganization([company.company]);
        vCard.addSingleTelephone(company.phone);
        vCard.addEmail(company.email ?? '');
        vCard.addAddress(company.street, company.streetNr, company.city, company.zip, company.country);
        return vCard;
    }
    static async fromCompanyEntityWithLogo(company) {
        const vcard = VCardGenerator.fromCompanyEntity(company);
        if (company.logo != null && company.logo.length > 0) {
            const headers = {};
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            headers['Accept'] = 'image/png';
            const result = await fetch(`${UpmeshClient.instance.url}/company/${company.id}/logo/${company.logo}?w=32&h=32`, { headers, method: 'GET' });
            if (result.status === 200) {
                const data = await result.arrayBuffer();
                vcard.addBase64Photo('image/jpeg', VCardGenerator.arrayBufferToString(data));
            }
        }
        return vcard;
    }
    constructor(vCardName) {
        this.telArray = [];
        this.contentSet = new Map();
        this.addObject('FN:', `${vCardName}`);
    }
    addName(firstname, lastname) {
        this.addObject('N:', `${lastname};${firstname};;;`);
        this.addKind('individual');
    }
    addCategorie(categories) {
        if (categories.length > 0) {
            const formatedCategories = categories.join(',');
            this.addObject('CATEGORIES:', formatedCategories);
        }
    }
    addOrganization(organizations) {
        if (organizations.length > 0) {
            const formattedOrganizations = organizations.join(';');
            this.addObject('ORG:', formattedOrganizations);
        }
    }
    addSingleTelephone(telephone) {
        if (telephone.length > 0) {
            this.addTelephone([{ tel: telephone, type: 'WORK' }]);
        }
    }
    addTelephone(telephone) {
        if (telephone.length > 0) {
            telephone.forEach((tel) => {
                this.telArray.push(`TEL;TYPE=${tel.type.toUpperCase()},VOICE:${tel.tel}`);
            });
        }
    }
    addBase64Photo(mimeType, data) {
        if (mimeType.length > 0 && data.length > 0) {
            this.addObject('PHOTO;ENCODING=B;TYPE=PNG:', data);
        }
    }
    addBase64Logo(mimeType, data) {
        if (mimeType.length > 0 && data.length > 0) {
            this.addObject('LOGO:', `${mimeType};base64,${data}`);
        }
    }
    addLang(lang) {
        if (lang.length > 0) {
            this.addObject('LANG:', lang);
        }
    }
    addKind(kind) {
        if (kind.length > 0) {
            this.addObject('KIND:', kind);
        }
    }
    addEmail(email) {
        if (email.length > 0) {
            this.addObject('EMAIL:', email);
        }
    }
    addAddress(street, streetNr, city, zip, country) {
        if ((street != null && streetNr != null) || city != null || zip != null || country != null) {
            this.addObject('ADR;', `TYPE=WORK:;;${street ?? ''}${streetNr ?? ''};${city ?? ''};${zip ?? ''};${country ?? ''}`);
        }
    }
    addObject(key, value) {
        this.contentSet.set(key, value);
    }
    getAsVcard() {
        const result = [];
        result.push('BEGIN:VCARD');
        result.push('VERSION:3.0');
        result.push('PROID:-//upmesh UG//');
        const contentArray = [];
        this.contentSet.forEach((value, key) => {
            contentArray.push(`${key}${value}`);
        });
        result.push(...contentArray);
        result.push(...this.telArray);
        result.push(`REV:${new Date().toISOString()}`);
        result.push('END:VCARD');
        return result.join('\n');
    }
}

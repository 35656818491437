import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { SwitchListItem } from 'materialTheme/src/theme/components/SwitchListItem';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { convertProject, ProjectFilter, } from 'upmesh-core/src/client/query/filter/ProjectFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { ProjectsFilter } from '../../project/ProjectsFilter';
import { CompanyUserInfo } from '../CompanyUserInfo';
export function ProjectWidgetOptions(props) {
    const [title, setTitle] = useState(props.initValues && props.initValues.title ? props.initValues.title : '');
    const [projectList, setProjectList] = useState([]);
    const [projects, setProjects] = useState(props.initValues && props.initValues.projects ? props.initValues.projects : undefined);
    const [allProjects, setAllProjects] = useState([]);
    const [filter, setFilter] = useState(props.initValues && props.initValues.filter ? props.initValues.filter : undefined);
    const [view, setView] = useState(props.initValues && props.initValues.view ? props.initValues.view : 'list');
    const [showMap, setShowMap] = useState(props.initValues && props.initValues.showMap ? props.initValues.showMap : false);
    const loadProjects = async () => {
        const p = await UpmeshClient.instance.modals.project.get({
            filter: 'deleted ne true and archived ne true',
        });
        const pr = [];
        p.forEach((project) => {
            pr.push({ title: project.title, id: project.id, groupId: 'all' });
        });
        const projects = [];
        for (const project of p) {
            const pe = await convertProject(project, CompanyUserInfo.company?.id);
            projects.push(pe);
        }
        setAllProjects(projects);
        setProjectList(pr);
    };
    useEffect(() => {
        loadProjects().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    }, []);
    const save = () => {
        props.onSaved({ projects: projects == null ? [] : [...projects], view, showMap, title, filter });
    };
    const projectChips = [];
    projects?.forEach((a) => {
        const f = projectList.find((b) => b.id === a);
        if (f)
            projectChips.push(f);
    });
    return (<>
      <DialogTitle>
        {props.type === 'edit'
            ? I18n.m.getMessage('widgetOptionDialogEditTitle')
            : I18n.m.getMessage('widgetOptionDialogAddTitle')}
      </DialogTitle>
      <DialogContent>
        <FormInputFilled initValue={title} labelText={I18n.m.getMessage('widgetIframeTitle')} onChange={(t) => setTitle(t)}/>
        <FormInputPicker labelText={I18n.m.getMessage('projectsWidgetViewType')} list={[
            { title: I18n.m.getMessage('ticketsDetailsViewChangeToListView'), data: 'list' },
            { title: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'), data: 'table' },
        ]} selectedIndex={view === 'table' ? 1 : 0} onChange={(a) => {
            if (a.value.data === 'list')
                setView('list');
            else
                setView('table');
        }}/>
        <SwitchListItem title={I18n.m.getMessage('projectsWidgetShowMap')} initValue={showMap} onChange={(a) => setShowMap(a)}/>
        <View style={{ height: 5 }}/>
        <ChipGroup chips={projectChips} chipGroups={[{ id: 'all', groupName: ' ', backgroundColor: '' }]} sortFormByGroup label={I18n.m.getMessage('projects')} editable availableChips={projectList} onChanged={(chips) => {
            const p = [];
            chips.forEach((a) => {
                if (a.id != null)
                    p.push(a.id);
            });
            setProjects(p);
        }}/>
        <View style={{ height: 5 }}/>
        <ProjectsFilter key={`projectFilter_${allProjects.length}`} allProjects={allProjects} currentFilter={filter ? JSON.parse(filter) : new ProjectFilter()} onChanged={(f) => {
            setFilter(JSON.stringify(f));
        }} hideArchived/>
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="#ffffff" onPress={Dialog.instance?.close}/>
        <ContainedButton title={I18n.m.getMessage('save')} onPress={save}/>
      </DialogActions>
    </>);
}

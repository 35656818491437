var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormElementsHandler = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var FormElementsHandler = exports.FormElementsHandler = function () {
  function FormElementsHandler() {
    (0, _classCallCheck2.default)(this, FormElementsHandler);
  }
  return (0, _createClass2.default)(FormElementsHandler, null, [{
    key: "getCurrentFocus",
    value: function getCurrentFocus() {
      if (FormElementsHandler._currentFocusId == null) {
        return '';
      }
      return FormElementsHandler._currentFocusId;
    }
  }, {
    key: "setCurrentFocus",
    value: function setCurrentFocus(id) {
      FormElementsHandler._currentFocusId = id;
    }
  }]);
}();
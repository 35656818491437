"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaveJournal = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ClientStore_1 = require("../../../ClientStore");
var JournalSaved_1 = require("../../../events/project/JournalSaved");
var JournalEntity_1 = require("../../../query/entities/JournalEntity");
var ProjectEntity_1 = require("../../../query/entities/ProjectEntity");
var SaveJournal = function (_CommandRoot_1$Comman) {
  function SaveJournal(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, SaveJournal);
    _this = _callSuper(this, SaveJournal, [data, entityId, token]);
    _this.commandName = 'SaveJournal';
    _this.aggregate = 'project';
    return _this;
  }
  (0, _inherits2.default)(SaveJournal, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(SaveJournal, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var project = yield CommandReadModels_1.CommandReadModels.instance.project.getById(this.data.projectId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.instance.companyMember.get({
          filter: `userId eq '${userId}' and deleted ne true and role ne 'inactive'`
        });
        if (companyMember != null && companyMember.length > 0) {
          if (project.creator !== userId) {
            if (companyMember[0].role === 'timeTracker') {
              var cs = yield CommandReadModels_1.CommandReadModels.companySettings.getById(companyMember[0].companyId);
              if (!cs.hasModule('timeTrackingJournalEdit')) {
                throw StdApplicationError_1.StdApplicationError.forbidden('keine Berechtigung');
              }
            }
            if (companyMember[0].disableJournalEdit) {
              throw StdApplicationError_1.StdApplicationError.forbidden('keine Berechtigung');
            }
          }
          this.company = yield CommandReadModels_1.CommandReadModels.company.getById(companyMember[0].companyId);
        }
        yield ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(this.data.projectId, userId);
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'projectId') {
          if (this.data.projectId.length <= 0) {
            throw {
              key: 'projectId',
              messageCode: 'required',
              message: 'wird benötigt'
            };
          }
        }
        if (key == null || key === 'forDay') {
          if (this.data.forDay == null) {
            throw {
              key: 'forDay',
              messageCode: 'required',
              message: 'wird benötigt'
            };
          }
          if (this.data.forDay.length !== 8) {
            throw {
              key: 'forDay',
              messageCode: 'required',
              message: 'forDay format: YYYYMMDD'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this$data$ticketRepo;
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var userOrCompanyId = this.company != null ? this.company.id : userId;
        var alreadyExistsForDay = yield CommandReadModels_1.CommandReadModels.instance.journal.get({
          filter: `projectId eq '${this.data.projectId}' and owner/id eq '${userOrCompanyId}' and forDay eq ${Number.parseInt(this.data.forDay, 10)}`
        });
        if (alreadyExistsForDay != null && alreadyExistsForDay.length > 0 && this.entityId !== alreadyExistsForDay[0].id) {
          throw StdApplicationError_1.StdApplicationError.badRequest('journal already present: wrong entityId submitted');
        }
        var data = {
          journal: this.data.journal,
          workDone: this.data.workDone,
          specialOccurrences: this.data.specialOccurrences,
          employee: this.data.employee,
          weather: this.data.weather,
          projectId: this.data.projectId,
          ticketReportFilter: this.data.ticketReportFilter,
          attendance: this.data.attendance,
          owner: {
            id: this.company != null ? this.company.id : userId,
            type: this.company != null ? 'company' : 'user'
          },
          forDay: Number.parseInt(this.data.forDay, 10),
          ticketReportSettings: this.data.ticketReportSettings,
          includeTicketReport: this.data.includeTicketReport,
          imagesPerLine: this.data.imagesPerLine,
          photoQuality: this.data.photoQuality,
          additionalFields: this.data.additionalFields,
          ticketReportTemplateId: (_this$data$ticketRepo = this.data.ticketReportTemplateId) != null ? _this$data$ticketRepo : ''
        };
        var equal = JSON.stringify(alreadyExistsForDay[0]) === JSON.stringify(new JournalEntity_1.JournalEntity(Object.assign({}, alreadyExistsForDay[0], data)));
        if (equal) return [];
        return new JournalSaved_1.JournalSaved(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* () {
        var onStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ClientStore_1.ClientStore.commandStore;
        return _superPropGet(SaveJournal, "execute", this, 3)([onStore]);
      });
      function execute() {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.SaveJournal = SaveJournal;
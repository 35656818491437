var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconWithText = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _reactNativeVectorIcons = require("react-native-vector-icons");
var _MaterialCommunityIcons = _interopRequireDefault(require("react-native-vector-icons/MaterialCommunityIcons"));
var icoMoonConfig = _interopRequireWildcard(require("../../assets/fonts/selection.json"));
var _ThemeManager = require("../ThemeManager");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ICON = (0, _reactNativeVectorIcons.createIconSetFromIcoMoon)(icoMoonConfig);
var IconWithText = exports.IconWithText = function (_PureComponent) {
  function IconWithText(props) {
    var _this;
    (0, _classCallCheck2.default)(this, IconWithText);
    _this = _callSuper(this, IconWithText, [props]);
    _this.state = Object.assign({}, _this.props);
    return _this;
  }
  (0, _inherits2.default)(IconWithText, _PureComponent);
  return (0, _createClass2.default)(IconWithText, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        iconColor = _this$props.iconColor,
        iconIconMoon = _this$props.iconIconMoon,
        iconName = _this$props.iconName,
        size = _this$props.size,
        iconSize = _this$props.iconSize,
        children = _this$props.children,
        textProps = _this$props.textProps,
        style = _this$props.style;
      var ICO = iconIconMoon === true ? ICON : _MaterialCommunityIcons.default;
      var iconSize2 = iconSize != null ? iconSize : size != null ? size + 10 : 24;
      var iconElement = (0, _jsxRuntime.jsx)(ICO, {
        name: iconName,
        size: iconSize2,
        color: iconColor,
        style: {
          width: iconSize2,
          minWidth: iconSize2,
          height: iconSize2,
          alignSelf: 'center',
          textAlign: 'center',
          color: iconColor
        }
      });
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'center'
        }, style),
        children: [iconElement, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            paddingLeft: 8
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({}, textProps, {
            centeredBox: true,
            fontSize: size,
            children: children
          }))
        })]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        iconIconMoon: false,
        iconName: '',
        size: 14,
        textColor: _ThemeManager.ThemeManager.style.defaultTextColor,
        iconColor: _ThemeManager.ThemeManager.style.defaultIconColor,
        textProps: {
          children: ''
        },
        children: ''
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, _prevState) {
      return nextProps;
    }
  }]);
}(_react.PureComponent);
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { TicketEntity, TicketStatus } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
export class TicketEntitySynced extends TicketEntity {
    static async convertFromTicket(t, includeCustomFields) {
        const tu = new TicketEntitySynced(t);
        const getUser = async (id) => {
            if (id === '0' || id.length === 0)
                return undefined;
            try {
                const cache = CachedEntities.knownUsers.get(id);
                if (cache)
                    return cache;
                return await UserOrCompany.getById(id);
            }
            catch (err) {
                return new UserEntity({ id, firstname: 'not', lastname: 'found' });
            }
        };
        const getProject = async (id) => {
            const cache = CachedEntities.knownProjects.get(id);
            if (cache)
                return cache;
            return UpmeshClient.instance.modals.project.getById(id);
        };
        try {
            if (t.approverUserId != null && t.approverUserId.length > 1) {
                const user = await getUser(t.approverUserId);
                if (user) {
                    tu.approverUser = user;
                    tu.approver = user.getFullName();
                }
            }
        }
        catch (e) {
            console.debug('cant get user by approverUserId', t.approverUserId, e);
        }
        try {
            if (t.assignedToUserId != null && t.assignedToUserId.length > 1) {
                const user = await getUser(t.assignedToUserId);
                if (user) {
                    tu.assigneeUser = user;
                    tu.assignee = user.getFullName();
                }
            }
        }
        catch (e) {
            console.debug('cant get user by assignedToUserId', t.assignedToUserId, e);
        }
        try {
            if (t.createdBy != null && t.createdBy.length > 1) {
                const user = await getUser(t.createdBy);
                if (user) {
                    tu.creatorName = user.getFullName();
                    tu.creatorUser = user;
                }
            }
        }
        catch (e) {
            console.debug('cant get user by createdBy', t.createdBy, e);
        }
        try {
            if (t.ticketStatus != null) {
                const project = await getProject(t.projectId);
                tu.ticketStatusText = `${I18n.m.getMessage(`ticketsDetailsState${TicketStatus[t.ticketStatus]}`, {}, project != null ? project.messagesOverride : undefined)}`;
            }
        }
        catch (e) {
            console.debug('cant get ticket status', t.ticketStatus, t.projectId, e);
        }
        if (includeCustomFields) {
            if (t.fields && t.fields.length > 0) {
                for (const a of t.fields) {
                    const ident = a.name;
                    tu[`fields.${ident}Value`] = a.value;
                    tu[`fields.${ident}`] = a.formattedValue;
                    if (a.type === 'Date' && a.value)
                        tu[`fields.${ident}`] = new Date(a.formattedValue);
                    else if (a.type === 'DateRange' && a.value && a.value.from && a.value.to)
                        tu[`fields.${ident}`] = `${I18n.m.dateCurrent.localeDateString(new Date(a.value.from))} - ${I18n.m.dateCurrent.localeDateString(new Date(a.value.to))}`;
                    else if (a.type === 'person' && a.formattedValue) {
                        tu[`fields.${ident}`] = a.formattedValue;
                    }
                    else if (a.type === 'multiperson' && a.formattedValue && a.formattedValue.length > 0) {
                        tu[`fields.${ident}`] = a.formattedValue;
                    }
                }
            }
            if (t.subTicketFrom != null) {
                tu.superTicket = await UpmeshClient.instance.modals.ticket.getById(t.subTicketFrom);
            }
            if (t.subTicketsCounter > 0) {
                tu.subTickets = await UpmeshClient.instance.modals.ticket.get({
                    filter: `subTicketFrom eq '${t.id}' and projectId eq '${t.projectId}'`,
                });
            }
        }
        return tu;
    }
}

import React, { useEffect, useState } from 'react';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { View } from 'react-native';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SUPPORTED_EXPORTS, } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { Download } from '../../repo/file/Download';
const iniFileContent = () => {
    const result = [];
    result.push('[Allgemein]');
    result.push('');
    result.push(`Feldanzahl = 8`);
    result.push(`Feldtrennzeichen = Strichpunkt`);
    result.push(`Zahlenkomma = .`);
    result.push(`Datumstrennzeichen = /`);
    result.push('');
    result.push('[Feldinhalt]');
    result.push('Feld1 = Personalnummer');
    result.push('Feld2 = Kalendertag');
    result.push('Feld3 = Ausfallschlüssel');
    result.push('Feld4 = Lohnartennummer');
    result.push('Feld5 = Stundenzahl');
    result.push('Feld6 = Kostenstellennummer');
    result.push('Feld7 = Kostenträger');
    result.push('Feld8 = Bemerkung');
    return result.join('\n');
};
export function DatevLohnOptions(props) {
    const [datevMandantNr, setDatevMandantNr] = useState('');
    const [datevBeraterNr, setDatevBeraterNr] = useState('');
    const [markAsCompanyPersNumber, setMarkAsCompanyPersNumber] = useState(false);
    const [payrollMonth, setPayrollMonth] = useState(new Date().getMonth() + 1);
    const [payrollYear, setPayrollYear] = useState(new Date().getFullYear());
    useEffect(() => {
        const value = SimpleStorage.get(`timeTrackingOptions${SUPPORTED_EXPORTS.datev.short}`);
        if (value != null && value.length > 0) {
            const storedData = JSON.parse(value);
            setDatevMandantNr(storedData.datevMandantNr);
            setDatevBeraterNr(storedData.datevBeraterNr);
            setMarkAsCompanyPersNumber(storedData.markAsCompanyPersNumber);
            setPayrollMonth(Number.parseInt(storedData.payrollMonth, 10));
            setPayrollYear(Number.parseInt(storedData.payrollYear, 10));
        }
    }, []);
    useEffect(() => {
        const data = SUPPORTED_EXPORTS.datev;
        data.additionalData.datevMandantNr = datevMandantNr;
        data.additionalData.datevBeraterNr = datevBeraterNr;
        data.additionalData.markAsCompanyPersNumber = markAsCompanyPersNumber;
        data.additionalData.payrollMonth = payrollMonth;
        data.additionalData.payrollYear = payrollYear;
        props.onChange(data);
    }, [datevMandantNr, datevBeraterNr, markAsCompanyPersNumber, payrollMonth, payrollYear]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            SimpleStorage.set(`timeTrackingOptions${SUPPORTED_EXPORTS.datev.short}`, JSON.stringify(SUPPORTED_EXPORTS.datev.additionalData));
        }, 250);
        return () => {
            clearTimeout(timeout);
        };
    }, [datevMandantNr, datevBeraterNr]);
    return (<View>
      <MaterialText>{I18n.m.getMessage('timeTrackingExportDatevMandantenNrNeeded')}</MaterialText>
      <FormInputFilled labelText={I18n.m.getMessage('timeTrackingExportDatevMandantenNrNeeded')} onChange={setDatevMandantNr} numberOfLines={1} value={datevMandantNr}/>
      <FormInputFilled labelText={I18n.m.getMessage('timeTrackingExportDatevBeraterNrNeeded')} onChange={setDatevBeraterNr} numberOfLines={1} value={datevBeraterNr}/>
      <MaterialText>{I18n.m.getMessage('timeTrackingExportDatevLohnAccountingDate')}</MaterialText>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ flex: 1, paddingRight: 8 }}>
          <FormInputFilled keyboardType="numeric" clearButton={false} labelText={I18n.m.getMessage('month')} onChange={(value) => setPayrollMonth(Number.parseInt(value, 10))} numberOfLines={1} value={payrollMonth.toString()} error={!(payrollMonth > 0 && payrollMonth < 13)}/>
        </View>
        <View style={{ flex: 1, paddingLeft: 8 }}>
          <FormInputFilled keyboardType="numeric" clearButton={false} labelText={I18n.m.getMessage('year')} onChange={(value) => setPayrollYear(Number.parseInt(value, 10))} numberOfLines={1} value={payrollYear.toString()} error={payrollYear.toString().length < 4 || payrollYear.toString().length > 4}/>
        </View>
      </View>
      <View style={{
            width: '100%',
            paddingVertical: 8,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}>
        <View style={{ width: '100%', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          <MaterialText fixedWidth="100%" centeredBox>
            {I18n.m.getMessage('timeTrackingExportDatevLohnPersAsCompanyPers')}
          </MaterialText>
        </View>
        <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <Switch value={markAsCompanyPersNumber} onPress={(value) => {
            setMarkAsCompanyPersNumber(value);
        }}/>
        </View>
      </View>
      <View style={{
            width: '100%',
            paddingVertical: 8,
            alignItems: 'flex-end',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        }}>
        <MaterialText fixedWidth="100%" centeredBox/>
        <ContainedButton title={I18n.m.getMessage('timeTrackingExportDatevLohnIniFileDownload')} onPress={() => {
            Download.downloadDataAsString('INI_DATEI.ini', 'text/plain', iniFileContent()).catch((e) => {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            });
        }}/>
      </View>
      {!(Number(datevBeraterNr) >= 1000 && Number(datevBeraterNr) <= 9999999) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('timeTrackingExportDatevLohnBeraterInvalid', { error: datevBeraterNr })}
        </MaterialText>) : null}
      {!(Number(datevMandantNr) >= 1 && Number(datevMandantNr) <= 99999) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('timeTrackingExportDatevMandantenInvalid', { error: datevMandantNr })}
        </MaterialText>) : null}
    </View>);
}

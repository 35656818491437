import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';
export class SubscriptionCard extends PureComponent {
    render() {
        const { subscriptionType, subscriptionDescription, subscriptionPrice, subscriptionPriceDescription, subscriptionButtonText, subscriptionPossibilitiesDescription, isCurrentSubscription, buttonDisabled, } = this.props;
        return (<View style={{
                flex: 1,
                minWidth: 300,
                borderWidth: ThemeManager.style.borderWidth,
                borderRadius: 3,
                margin: 20,
                paddingTop: 50,
                paddingHorizontal: 50,
                paddingBottom: 50,
                shadowColor: '#000',
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
            }}>
        <Text style={{
                fontSize: 32,
                fontFamily: ThemeManager.style.fontFamily,
                fontWeight: '600',
                color: '#0c0042',
                marginBottom: 15,
                overflow: 'visible',
            }}>
          {subscriptionType}
        </Text>
        <Text style={{
                fontSize: 16,
                fontFamily: ThemeManager.style.fontFamily,
                fontWeight: ThemeManager.style.fontWeightRegular,
                color: '#0c0042',
                marginBottom: 15,
                overflow: 'visible',
            }}>
          {subscriptionDescription}
        </Text>
        <Text style={{
                fontSize: 50,
                fontFamily: ThemeManager.style.fontFamily,
                fontWeight: ThemeManager.style.fontWeightBold,
                color: '#0c0042',
                marginBottom: 15,
                overflow: 'visible',
            }}>
          {subscriptionPrice}
        </Text>
        <Text style={{
                fontSize: 14,
                fontFamily: ThemeManager.style.fontFamily,
                fontWeight: ThemeManager.style.fontWeightRegular,
                color: '#0c0042',
                marginBottom: 15,
                overflow: 'visible',
            }}>
          {subscriptionPriceDescription}
        </Text>
        <View style={{
                alignSelf: 'center',
                marginBottom: 15,
            }}>
          <ContainedButton title={subscriptionButtonText} onPress={!isCurrentSubscription ? this.props.onSubscriptionButtonPress : Routing.instance.goToButton(`/projects`)} textColor="#FFFFFF" backgroundColor={!buttonDisabled ? '#00cd9e' : '#FFFFFF'} disabled={buttonDisabled}/>
        </View>
        <Text style={{
                fontSize: 16,
                fontFamily: ThemeManager.style.fontFamily,
                fontWeight: ThemeManager.style.fontWeightRegular,
                color: '#0c0042',
                overflow: 'visible',
                marginBottom: 15,
            }}>
          {subscriptionPossibilitiesDescription}
        </Text>
      </View>);
    }
}

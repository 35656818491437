var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Map = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _maplibreGl = _interopRequireDefault(require("!maplibre-gl"));
require("maplibre-gl/dist/maplibre-gl.css");
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactMapGl = _interopRequireWildcard(require("react-map-gl"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _AddressEntity = require("upmesh-auth-core/build/src/client/query/entities/AddressEntity");
var _I18n = require("../i18n/I18n");
var _Routing = require("../routing/Routing");
var _GooglePlacesInput = require("./addressCard/GooglePlacesInput");
var _FormInputFilled = require("./forminput/FormInputFilled");
var _Icon = require("./Icon");
var _IconGroup = require("./IconGroup");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var markerImage = require("../../assets/marker/blue.png");
var Map = exports.Map = function (_PureComponent) {
  function Map(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Map);
    _this = _callSuper(this, Map, [props]);
    _this.apiUrl = '';
    _this.setMapRef = function (m) {
      _this.map = m;
      if (_this.map != null) {
        _this.map.loadImage(markerImage, function (e, image) {
          if (e) {}
          if (image != null) {
            if (!_this.map.hasImage('blue')) {
              _this.map.addImage('blue', image, {
                sdf: false,
                pixelRatio: 1
              });
            }
          }
        });
      }
    };
    _this.onGoogleAddressSelected = function (newAddress) {
      _this.setState(function (prevState) {
        var updatedAddress = Object.assign({}, prevState.address, newAddress);
        return {
          address: new _AddressEntity.AddressEntity(updatedAddress)
        };
      }, function () {
        if (newAddress != null && newAddress.latitude != null && newAddress.longitude != null) {
          _this.onAddressChanged();
          _this.zoomTo({
            lat: Number.parseFloat(newAddress.latitude),
            lng: Number.parseFloat(newAddress.longitude)
          }, 15);
        }
      });
    };
    _this.onFirstListItem = function (newAddress) {
      _this.setState(function (prevState) {
        var updatedAddress = Object.assign({}, prevState.address, newAddress);
        return {
          address: new _AddressEntity.AddressEntity(updatedAddress)
        };
      }, function () {
        if (newAddress != null && newAddress.latitude != null && newAddress.longitude != null) {
          _this.onAddressChanged();
          _this.zoomTo({
            lat: Number.parseFloat(newAddress.latitude),
            lng: Number.parseFloat(newAddress.longitude)
          }, 15);
        }
      });
    };
    _this.onDragEnd = function (e) {
      var viewPort = _this.state.viewPort;
      _this.onDragChanged({
        center: e.lngLat,
        zoom: viewPort != null ? viewPort.zoom : 17
      });
    };
    _this.onClick = function (_e) {
      if (_this.longPressed) {
        _this.longPressed = false;
      }
    };
    _this.longPressed = false;
    _this.onLongPress = function (e) {
      _this.longPressed = true;
      var viewPort = _this.state.viewPort;
      _this.onDragChanged({
        center: e.point,
        zoom: viewPort != null ? viewPort.zoom : 17
      });
    };
    _this.mouseDown = false;
    _this.mousedown = function (e) {
      _this.mouseDown = true;
      _this.mapTapHoldTimeout = setTimeout(function () {
        e.originalEvent.preventDefault();
        e.originalEvent.persist();
        if (e['lngLat'] != null) {
          _this.onLongPress({
            point: e['lngLat'],
            nativeEvent: e['originalEvent']
          });
        }
      }, 500);
    };
    _this.mouseup = function (_e) {
      _this.mouseDown = false;
      if (_this.mapTapHoldTimeout) clearTimeout(_this.mapTapHoldTimeout);
    };
    _this.mousemove = function (_e) {
      if (_this.mapTapHoldTimeout) clearTimeout(_this.mapTapHoldTimeout);
    };
    _this.onAddressChanged = function () {
      var onChangeAddressInput = _this.props.onChangeAddressInput;
      var address = _this.state.address;
      if (onChangeAddressInput != null && address != null) {
        onChangeAddressInput(address);
      }
    };
    _this.goToCurrentLocation = function () {
      if (navigator.geolocation != null) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (position['coords']['latitude'] != null && position['coords']['longitude'] != null) {
            _this.zoomTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }, 16);
          }
        }, function (error) {
          _Routing.Routing.instance.alert.post({
            text: `${_I18n.I18n.m.getMessage('addressGetCurrentLocationError')}\n${error.toString()}`
          });
        });
      }
    };
    _this.onViewChange = function (e) {
      if (e.viewState != null) {
        var v = {
          center: {
            lng: e.viewState.longitude,
            lat: e.viewState.latitude
          },
          zoom: e.viewState.zoom
        };
        _this.setState({
          viewPort: v
        });
        if (_this.props.onRegionChange != null) {
          _this.props.onRegionChange(v);
        }
      }
    };
    _this.onDragChanged = function (region, zoomTo) {
      var address = _this.state.address;
      try {
        if (_this.fetchTO != null) clearTimeout(_this.fetchTO);
        if (_this.abortController) _this.abortController.abort();
      } catch (e) {}
      _this.abortController = new AbortController();
      var signal = _this.abortController.signal;
      if (zoomTo) _this.zoomTo(region.center, region.zoom);
      _this.fetchTO = setTimeout(function () {
        fetch(`${_this.apiUrl}/geocode/json?&lat=${region.center.lat}&long=${region.center.lng}`, {
          signal: signal
        }).then(function (result) {
          if (result.status === 200) {
            result.json().then(function (json) {
              if (json == null || json.results == null) {
                return;
              }
              var placesKey = _this.state.placesKey;
              var bestMatch = json.results.filter(function (addressItem) {
                return addressItem.address_components.length >= 7;
              })[0];
              if (bestMatch != null) {
                var newAddress = (0, _GooglePlacesInput.getAddressInfoFromGoogleResponse)(bestMatch.formatted_address, bestMatch);
                var adr = address == null ? new _AddressEntity.AddressEntity(newAddress) : address;
                adr.fill(Object.assign({
                  addressInput: newAddress.addressInput,
                  contactName: adr.contactName,
                  latitude: region.center.lat,
                  longitude: region.center.lng,
                  email: adr.email,
                  phone: adr.phone
                }, newAddress));
                adr.latitude = region.center.lat;
                adr.longitude = region.center.lng;
                _this.setState({
                  address: adr,
                  inputValue: bestMatch.formatted_address,
                  placesKey: placesKey + 1
                }, _this.onAddressChanged);
              } else {
                _this.setState(function (prevState) {
                  var address = prevState.address;
                  if (address != null) {
                    address.latitude = region.center.lat;
                    address.longitude = region.center.lng;
                    return {
                      address: address,
                      placesKey: prevState.placesKey + 1
                    };
                  }
                  return null;
                }, _this.onAddressChanged);
              }
            }).catch(function (err) {
              return void 0;
            });
          }
        }).catch(function (err) {});
      }, 200);
    };
    _this.apiUrl = `${_AuthClient.AuthClient.instance.url.replace('3011/auth/', '3012/')}/backend/googleapis`;
    _this.state = {
      placesKey: 1,
      inputValue: props.address != null && props.address.addressInput != null ? props.address.addressInput : '',
      gpsLock: false,
      address: props.address
    };
    return _this;
  }
  (0, _inherits2.default)(Map, _PureComponent);
  return (0, _createClass2.default)(Map, [{
    key: "zoomTo",
    value: function zoomTo(center) {
      var zoomLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
      if (this.map != null) {
        this.map.easeTo({
          zoom: zoomLevel,
          center: center
        });
      }
    }
  }, {
    key: "getAddress",
    value: function getAddress() {
      return this.state.address;
    }
  }, {
    key: "renderAddressMarker",
    value: function renderAddressMarker() {
      var showAddressInput = this.props.showAddressInput;
      var address = this.state.address;
      if (address != null && showAddressInput) {
        var mProps = {};
        return (0, _jsxRuntime.jsx)(_reactMapGl.Marker, {
          onDragEnd: this.onDragEnd,
          longitude: address.longitude,
          latitude: address.latitude,
          anchor: "bottom",
          draggable: true,
          children: (0, _jsxRuntime.jsx)("img", Object.assign({
            alt: "address",
            src: markerImage
          }, mProps))
        }, "marker_address");
      }
      return null;
    }
  }, {
    key: "renderAddressInput",
    value: function renderAddressInput() {
      var _this2 = this;
      var _this$state = this.state,
        inputValue = _this$state.inputValue,
        placesKey = _this$state.placesKey,
        address = _this$state.address,
        gpsLock = _this$state.gpsLock;
      return [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: 0,
          width: '100%',
          height: 72,
          padding: 10,
          left: 0,
          zIndex: 200,
          elevation: 200,
          backgroundColor: 'transparent'
        },
        pointerEvents: "box-none",
        children: (0, _jsxRuntime.jsx)(_GooglePlacesInput.GooglePlacesInput, {
          initialText: inputValue,
          onChange: this.onGoogleAddressSelected,
          onUpdateFirstListItem: gpsLock ? undefined : this.onFirstListItem,
          backendURL: this.apiUrl
        }, `places_${placesKey}`)
      }, "addressInput"), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: 60,
          width: '100%',
          height: 72,
          padding: 10,
          left: 0,
          zIndex: 200,
          elevation: 200,
          backgroundColor: 'transparent',
          flexDirection: 'row'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flex: 1,
            height: 72,
            paddingRight: 8
          },
          children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            disabled: gpsLock,
            clearButton: false,
            labelText: _I18n.I18n.m.getMessage('latitude'),
            value: address != null ? address.latitude.toString().includes('.') ? address.latitude.toString() : `${address.latitude.toString()}.0` : '0.0',
            onChangeText: function onChangeText(text) {
              var latitude = Number.parseFloat(text);
              if (latitude > -90 && latitude < 90) {
                _this2.setState({
                  address: address != null ? new _AddressEntity.AddressEntity(Object.assign({}, address, {
                    latitude: latitude
                  })) : new _AddressEntity.AddressEntity({
                    latitude: latitude,
                    longitude: 0
                  })
                }, function () {
                  var address = _this2.state.address;
                  if (address != null) _this2.onDragChanged({
                    center: {
                      lat: address.latitude,
                      lng: address.longitude
                    },
                    zoom: 15
                  }, true);
                });
              }
            }
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flex: 1,
            height: 72,
            paddingRight: 8
          },
          children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            disabled: gpsLock,
            clearButton: false,
            labelText: _I18n.I18n.m.getMessage('longitude'),
            value: address != null ? address.longitude.toString().includes('.') ? address.longitude.toString() : `${address.longitude.toString()}.0` : '0.0',
            onChangeText: function onChangeText(text) {
              var longitude = Number.parseFloat(text);
              if (longitude > -180 && longitude < 180) {
                _this2.setState({
                  address: address != null ? new _AddressEntity.AddressEntity(Object.assign({}, address, {
                    longitude: longitude
                  })) : new _AddressEntity.AddressEntity({
                    longitude: longitude,
                    latitude: 0
                  })
                }, function () {
                  var address = _this2.state.address;
                  if (address != null) _this2.onDragChanged({
                    center: {
                      lat: address.latitude,
                      lng: address.longitude
                    },
                    zoom: 15
                  }, true);
                });
              }
            }
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 48,
            height: 72,
            paddingRight: 0,
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: gpsLock ? 'lock-open' : 'lock',
            toolTip: gpsLock ? _I18n.I18n.m.getMessage('gpsLockRelease') : _I18n.I18n.m.getMessage('gpsLock'),
            onPress: function onPress() {
              _this2.setState(function (prevState) {
                return {
                  gpsLock: !prevState.gpsLock
                };
              });
            }
          })
        })]
      }, "geoInput")];
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        style = _this$props.style,
        zoomLevel = _this$props.zoomLevel,
        allowScaling = _this$props.allowScaling,
        showGoToCurrentLocationButton = _this$props.showGoToCurrentLocationButton,
        showAddressInput = _this$props.showAddressInput,
        address = _this$props.address;
      var rasterSource = {
        type: 'raster',
        tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png', 'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png', 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256
      };
      var rasterLayer = {
        id: 'test',
        type: 'raster',
        source: 'raster-tiles',
        minzoom: 0,
        maxzoom: 19
      };
      var layers = [rasterLayer];
      var sources = {
        'raster-tiles': rasterSource
      };
      var mStyle = {
        version: 8,
        sources: sources,
        layers: layers
      };
      var initialViewState;
      if (address != null) {
        initialViewState = {
          zoom: zoomLevel || 17,
          latitude: address.latitude,
          longitude: address.longitude
        };
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({
          position: 'relative'
        }, style),
        children: [(0, _jsxRuntime.jsx)("div", {
          style: {
            width: '100%',
            height: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_reactMapGl.default, {
            attributionControl: false,
            ref: this.setMapRef,
            maxZoom: 18,
            mapLib: _maplibreGl.default,
            mapStyle: mStyle,
            initialViewState: initialViewState,
            scrollZoom: allowScaling === true,
            touchPitch: allowScaling === true,
            boxZoom: allowScaling === true,
            doubleClickZoom: allowScaling === true,
            touchZoomRotate: allowScaling === true,
            dragRotate: allowScaling === true,
            dragPan: allowScaling === true,
            pitchWithRotate: allowScaling === true,
            onMouseDown: this.mousedown,
            onDrag: this.mousemove,
            onMouseMove: this.mousemove,
            onMouseUp: this.mouseup,
            onMoveEnd: this.onViewChange,
            children: this.renderAddressMarker()
          })
        }), showGoToCurrentLocationButton ? this.renderCurrentLocationIcon() : null, showAddressInput ? this.renderAddressInput() : null]
      });
    }
  }, {
    key: "renderCurrentLocationIcon",
    value: function renderCurrentLocationIcon() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          height: 52,
          width: 52,
          bottom: 0,
          right: 0
        },
        pointerEvents: "box-none",
        children: (0, _jsxRuntime.jsx)(_IconGroup.IconGroup, {
          horizontal: false,
          small: true,
          buttons: [{
            icon: {
              icon: 'crosshairs',
              onPress: this.goToCurrentLocation,
              toolTip: _I18n.I18n.m.getMessage('addressSetCurrentLocation')
            },
            borderBelow: false
          }]
        })
      });
    }
  }]);
}(_react.PureComponent);
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { CachedEntities } from '../../config/CachedEntities';
export function TicketTableUserCell(props) {
    const [user, setUser] = useState(props.user ? props.user : undefined);
    const getUser = async (id) => {
        const cache = CachedEntities.knownUsers.get(id);
        if (cache)
            return cache;
        return UserOrCompany.getById(id);
    };
    const loadUsers = async () => {
        if (props.userId == null || props.userId.length === 0)
            return;
        if (user == null) {
            if (Array.isArray(props.userId)) {
                const users = [];
                for (const id of props.userId) {
                    const u = await getUser(id);
                    users.push(u);
                }
                setUser(users);
            }
            else if (user == null || user['id'] !== props.userId) {
                const u = await getUser(props.userId);
                setUser(u);
            }
        }
    };
    useEffect(() => {
        loadUsers().catch((err) => console.debug(err));
    }, [props.userId]);
    if (user == null)
        return (<View style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16, width: 300 }, props.style]}>
        <MaterialText>{props.text}</MaterialText>
      </View>);
    if (Array.isArray(user)) {
        return (<View style={[
                {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    paddingHorizontal: 16,
                    width: 300,
                },
                props.style,
            ]}>
        <View style={{
                width: 36,
                height: 36,
                borderRadius: 18,
                borderStyle: 'dotted',
                borderColor: ThemeManager.style.borderColor,
                borderWidth: ThemeManager.style.borderWidth,
                justifyContent: 'center',
                marginRight: 8,
            }}>
          <MaterialText centeredBox centeredText>
            {user.length}
          </MaterialText>
        </View>

        {user.map((u) => {
                return (<Ripple key={u.id} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }} onPress={(e) => Routing.instance.openDialog('user', { userId: u.id })(e)}>
              <UserImage size={24} user={u}/>
              <View style={{ width: 8 }}/>
              <MaterialText>{`${u.getFullName()}`}</MaterialText>
            </Ripple>);
            })}
      </View>);
    }
    return (<View style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16, width: 300 }, props.style]}>
      <Ripple style={{ flexDirection: 'row', alignItems: 'center' }} onPress={(e) => Routing.instance.openDialog('user', { userId: props.userId })(e)}>
        <UserImage size={24} user={user}/>
        <View style={{ width: 8 }}/>
        <MaterialText>{`${props.text}`}</MaterialText>
      </Ripple>
    </View>);
}

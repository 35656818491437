var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoBox = InfoBox;
var _react = _interopRequireDefault(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _color = _interopRequireDefault(require("color"));
var _Icon = require("./Icon");
var _MaterialText = require("./text/MaterialText");
var _ThemeManager = require("../ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function InfoBox(props) {
  var _props$toolTip;
  var iconColor = props.color != null ? props.color : _ThemeManager.ThemeManager.style.brandPrimary;
  var textBackground = (0, _color.default)(iconColor).alpha(0.2).toString();
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: {
      borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
      backgroundColor: textBackground,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10)
    },
    children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
      toolTip: (_props$toolTip = props.toolTip) != null ? _props$toolTip : '',
      icon: "information-outline",
      color: iconColor
    }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
      centeredBox: true,
      children: props.text
    })]
  });
}
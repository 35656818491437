import { ConfigAll } from '../../config/ConfigAll';
export class SubscriptionConfig {
    static get billwerkPortalUrl() {
        if (ConfigAll.getEnvironment() === 'production') {
            return 'https://selfservice.billwerk.com/portal/signup.html';
        }
        return 'https://selfservice.sandbox.billwerk.com/portal/signup.html';
    }
    static getBillwerkPlanVariantId(method) {
        if (ConfigAll.getEnvironment() === 'production') {
            return method === 'yearly' ? '5ee8c15f6237070d18ac1cf5' : '5ee8c10e5b98961af098a269';
        }
        return '5ef42cfd2de5ef30b045f17f';
    }
}

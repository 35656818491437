"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteFolder = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var FolderDeleted_1 = require("../../events/folder/FolderDeleted");
var FolderEntity_1 = require("../../query/entities/FolderEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var DeleteFolder = function (_ClientProjectCommand) {
  function DeleteFolder(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteFolder);
    _this = _callSuper(this, DeleteFolder, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'DeleteFolder';
    _this.allAffectedFolders = new Map();
    return _this;
  }
  (0, _inherits2.default)(DeleteFolder, _ClientProjectCommand);
  return (0, _createClass2.default)(DeleteFolder, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        this.currentFolder = yield CommandReadModels_1.CommandReadModels.folder.getById(this.entityId, undefined, userId);
        this.allAffectedFolders.set(this.currentFolder.id, this.currentFolder);
        var allDirs = yield this.listAllSubDirs(this.currentFolder.id);
        yield _superPropGet(DeleteFolder, "checkProjectStatusAndGeneralAccess", this, 3)([this.currentFolder.projectId, userId]);
        var permissionPromises = [];
        allDirs.forEach(function (dir) {
          permissionPromises.push(FolderEntity_1.FolderEntity.hasFolderDeleteRights(dir, userId));
        });
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.currentFolder.projectId, userId, 'commandEditFolders')) {
          yield Promise.all(permissionPromises);
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "listAllSubDirs",
    value: function () {
      var _listAllSubDirs = (0, _asyncToGenerator2.default)(function* (root) {
        var _this2 = this;
        var result = [root];
        var queue = [root];
        while (queue.length > 0) {
          var current = queue.pop();
          var currentLevelSubDir = yield CommandReadModels_1.CommandReadModels.instance.folder.get({
            filter: `subFolderFromId eq '${current}' and deleted ne true`
          });
          var reducedIds = currentLevelSubDir.map(function (item) {
            return item.id;
          });
          result = result.concat(reducedIds);
          queue = queue.concat(reducedIds);
          currentLevelSubDir.forEach(function (dir) {
            _this2.allAffectedFolders.set(dir.id, dir);
          });
        }
        return result;
      });
      function listAllSubDirs(_x) {
        return _listAllSubDirs.apply(this, arguments);
      }
      return listAllSubDirs;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this3 = this;
        var hasChanged = this.currentFolder.deleted == null || this.currentFolder.deleted === false;
        if (!hasChanged) return null;
        var events = [];
        var allFolderIdsToDelete = yield this.listAllSubDirs(this.currentFolder.id);
        allFolderIdsToDelete.forEach(function (folder) {
          events.push(new FolderDeleted_1.FolderDeleted(folder, {
            deleted: true
          }, _this3.currentFolder.projectId));
        });
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x2) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.DeleteFolder = DeleteFolder;
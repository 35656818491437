import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { WidgetItemContainer } from 'materialTheme/src/theme/components/widgets/WidgetItemContainer';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeUserSettings } from 'upmesh-auth-core/src/client/commands/user/ChangeUserSettings';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserSettingsEntity } from 'upmesh-auth-core/src/client/query/entities/UserSettingsEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { ChartItemWidget } from '../project/board/ChartItemWidget';
import { FocusTicketItem } from '../project/board/FocusTicketItem';
import { LogsItem } from '../project/board/LogsItem';
import { NotesWidget } from '../project/board/NotesWidget';
import { AbsenceWidget } from './board/AbsenceWidget';
import { ActivitiesOptions } from './board/ActivitiesOptions';
import { ChartItemOptions } from './board/ChartItemOptions';
import { FocusTicketsOptions } from './board/FocusTicketsOptions';
import { GanttChartWidget } from './board/GanttChartWidget';
import { GanttChartWidgetOptions } from './board/GanttChartWidgetOptions';
import { IframeWidget } from './board/IframeWidget';
import { IframeWidgetOptions } from './board/IframeWidgetOptions';
import { NotesItemOptions } from './board/NotesItemOptions';
import { ProjectWidget } from './board/ProjectWidget';
import { ProjectWidgetOptions } from './board/ProjectWidgetOptions';
import { CurrentTimeTrackingWidget } from './CurrentTimeTrackingWidget';
import { DashboardWidgetPreview } from './DashboardWidgetPreview';
import { GlobalBar } from './GlobalBar';
import { OpenAbsenceRequestWidget } from './OpenAbsenceRequestsWidget';
import { PageView } from './PageView';
export default function DashboardView(props) {
    const sDisplay = !(props.size.windowWidth > ThemeManager.style.breakpointM);
    const [items, setItems] = useState();
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const updateDashboard = (e) => {
        if (e && e.entities && e.entities.size > 0) {
            let settings = null;
            try {
                e.entities.forEach((a) => {
                    if (a.entity) {
                        settings = a.entity;
                    }
                });
                if (settings && settings['appSettings']) {
                    if (JSON.stringify(items) !== settings['appSettings']['globalDashboard']) {
                        const it = JSON.parse(settings['appSettings']['globalDashboard']);
                        setItems(it);
                    }
                }
            }
            catch (err) {
                console.warn('cant load Global Dashboard Items', err);
            }
        }
    };
    useEffect(() => {
        const key = uuid.v4();
        UpmeshClient.eventDispatcher.attach({
            attachKey: key,
            readModelName: 'UserSettings',
            callback: updateDashboard,
        });
        if (CurrentUser.settings != null &&
            CurrentUser.settings.appSettings != null &&
            CurrentUser.settings.appSettings['globalDashboard']) {
            try {
                const items = JSON.parse(CurrentUser.settings.appSettings['globalDashboard']);
                setItems(items);
            }
            catch (err) {
                console.warn('cant load Global Dashboard Items', err);
            }
        }
        setSettingsLoaded(true);
        return () => UpmeshClient.eventDispatcher.detach('UserSettings', key);
    }, []);
    const defaultItems = [
        {
            widgetId: '11',
            settings: { projects: [], showMap: true, view: 'list', title: I18n.m.getMessage('projects') },
            id: '0',
            width: 12,
            height: 7,
            top: 0,
            left: 0,
        },
        {
            widgetId: '8',
            settings: {},
            id: '7',
            width: 6,
            height: 7,
            top: 7,
            left: 0,
        },
        {
            widgetId: '3',
            settings: {},
            id: '1',
            width: 6,
            height: 7,
            top: 7,
            left: 6,
        },
        {
            widgetId: '4',
            settings: {},
            id: '2',
            width: 6,
            height: 6,
            top: 14,
            left: 0,
        },
        {
            widgetId: '9',
            settings: {},
            id: '6',
            width: 6,
            height: 6,
            top: 14,
            left: 6,
        },
        {
            widgetId: '5',
            settings: {},
            id: '3',
            width: 4,
            height: 6,
            top: 20,
            left: 0,
        },
        {
            widgetId: '6',
            settings: {},
            id: '4',
            width: 4,
            height: 6,
            top: 20,
            left: 4,
        },
        {
            widgetId: '7',
            settings: {},
            id: '5',
            width: 4,
            height: 6,
            top: 20,
            left: 8,
        },
    ];
    const templates = [
        {
            id: '3',
            previewImage: DashboardWidgetPreview.fokusTickets,
            multiple: true,
            widgetName: I18n.m.getMessage('dashboardFocusTicketsItem'),
            component: (props) => <FocusTicketItem {...props}/>,
            optionWindow: (props) => <FocusTicketsOptions {...props}/>,
            defaultSize: { width: 6, height: 7 },
            minWidth: 4,
            minHeight: 7,
        },
        {
            id: '4',
            previewImage: DashboardWidgetPreview.activites,
            multiple: true,
            widgetName: I18n.m.getMessage('activities'),
            component: (props) => <LogsItem {...props}/>,
            optionWindow: (props) => <ActivitiesOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 4,
            minHeight: 6,
            maxHeight: 8,
        },
        {
            id: '5',
            multiple: false,
            previewImage: DashboardWidgetPreview.absences,
            widgetName: I18n.m.getMessage('absences'),
            component: (props) => <AbsenceWidget {...props}/>,
            defaultSize: { width: 4, height: 6 },
            minWidth: 3,
            minHeight: 2,
        },
        {
            id: '6',
            multiple: false,
            previewImage: DashboardWidgetPreview.timetrackings,
            widgetName: I18n.m.getMessage('timetrackingsRunningHeadline'),
            component: (props) => <CurrentTimeTrackingWidget {...props}/>,
            defaultSize: { width: 4, height: 6 },
            minWidth: 3,
            minHeight: 2,
        },
        {
            id: '7',
            multiple: false,
            previewImage: DashboardWidgetPreview.openAbsences,
            widgetName: I18n.m.getMessage('absenceOpenRequests'),
            component: (props) => <OpenAbsenceRequestWidget {...props}/>,
            defaultSize: { width: 4, height: 6 },
            minWidth: 3,
            minHeight: 2,
        },
        {
            id: '8',
            multiple: true,
            previewImage: DashboardWidgetPreview.overview,
            widgetName: I18n.m.getMessage('dashboardChartItemTicketOverview'),
            component: (props) => <ChartItemWidget {...props}/>,
            optionWindow: (props) => <ChartItemOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 3,
            minHeight: 6,
        },
        {
            id: '9',
            previewImage: DashboardWidgetPreview.notes,
            multiple: true,
            widgetName: I18n.m.getMessage('dashboardNotes'),
            component: (props) => <NotesWidget {...props}/>,
            optionWindow: (props) => <NotesItemOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 3,
            minHeight: 6,
        },
        {
            id: '10',
            previewImage: DashboardWidgetPreview.iFrame,
            multiple: true,
            widgetName: I18n.m.getMessage('website'),
            component: (props) => <IframeWidget {...props}/>,
            optionWindow: (props) => <IframeWidgetOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
        {
            id: '11',
            previewImage: DashboardWidgetPreview.projects,
            multiple: true,
            widgetName: I18n.m.getMessage('projects'),
            component: (props) => <ProjectWidget {...props}/>,
            optionWindow: (props) => <ProjectWidgetOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
        {
            id: '12',
            previewImage: DashboardWidgetPreview.gantt,
            multiple: true,
            widgetName: I18n.m.getMessage('dashboardGanttChart'),
            component: (props) => <GanttChartWidget {...props}/>,
            optionWindow: (props) => <GanttChartWidgetOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
    ];
    const saveChanges = (e) => {
        try {
            if (CurrentUser.settings != null) {
                const appSettings = CurrentUser.settings.appSettings != null ? CurrentUser.settings.appSettings : {};
                const newSettings = new UserSettingsEntity({
                    ...CurrentUser.settings,
                    appSettings: { ...appSettings, globalDashboard: JSON.stringify(e) },
                });
                const c = new ChangeUserSettings(newSettings);
                c.execute(AuthClient.instance.commandStore).catch((err) => {
                    console.warn('cant save app settings global dashboard', err);
                });
            }
        }
        catch (e) {
            console.warn('cant save app settings global dashboard', e);
        }
        setItems(e);
    };
    const renderContent = () => {
        if (!settingsLoaded)
            return <Spinner />;
        return (<View style={{ width: props.width, height: props.height }}>
        <WidgetItemContainer templates={templates} savedItems={items} defaultItems={defaultItems} height={props.height} onChanged={saveChanges}/>
      </View>);
    };
    return (<View style={{
            overflow: 'hidden',
            position: 'absolute',
            width: props.width,
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
        }}>
      {!sDisplay ? <GlobalBar user={CurrentUser.entity} size={props.size} site="dashboard"/> : undefined}
      {sDisplay ? (<PageView showAccountIcon showMenu={false} scrollable={false} style={{
                width: props.width,
                overflow: 'hidden',
                position: 'absolute',
                right: 0,
                left: 0,
                top: 0,
                bottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
                alignItems: 'center',
            }} headerProps={{
                backgroundColor: 'transparent',
                withBorder: false,
                title: '',
            }}>
          {renderContent()}
        </PageView>) : (renderContent())}
    </View>);
}

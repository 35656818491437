import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { UploadTypes } from 'materialTheme/src/theme/components/upload/UploadTypes';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
export class FileThumb169 extends PureComponent {
    constructor(props) {
        super(props);
        this.createdToolTip = (toolTip) => {
            this.setState({ toolTip });
        };
        this.onPressImage = (file) => (_e) => {
            const { onPress } = this.props;
            if (onPress != null) {
                onPress(file);
            }
        };
        this.state = { toolTip: '' };
    }
    render() {
        const { width, fileId, selected } = this.props;
        const height = Math.round((width / 16) * 9);
        const imageSize = {
            width,
            height,
        };
        return (<View style={{
                ...imageSize,
                borderRadius: ThemeManager.style.borderRadius,
                borderWidth: ThemeManager.style.borderWidth,
                borderColor: selected ? ThemeManager.style.brandPrimary : ThemeManager.style.borderColor,
                backgroundColor: '#ffffff',
                overflow: 'hidden',
            }} key={`folder${fileId}`}>
        {this.renderThumb(imageSize)}
        {this.renderTitle()}
      </View>);
    }
    renderTitle() {
        const { title, selected, onPressIcon, width } = this.props;
        if (title == null) {
            return <View />;
        }
        return (<View style={{
                height: 36,
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: selected ? ThemeManager.style.brandPrimary : ThemeManager.style.white54,
                justifyContent: 'space-between',
                flexDirection: 'row',
                paddingLeft: 8,
                paddingRight: 4,
                width,
            }} key={`textBox${title}${selected}`}>
        <MaterialText type={MaterialTextTypes.Subtitle1} numberOfLines={1} fixedWidth={width - ThemeManager.style.getScreenRelativePixelSize(48)} color={selected === true ? ThemeManager.style.white87 : ThemeManager.style.black87} onToolTipCreated={this.createdToolTip} centeredBox>
          {title}
        </MaterialText>
        <Icon toolTip="" icon="dots-vertical" onPress={onPressIcon} color={selected === true ? '#ffffff' : ThemeManager.style.defaultIconColor} outerSize={36}/>
      </View>);
    }
    renderThumb(imageSize) {
        const { file, onPress, onLongPress, fileId } = this.props;
        if (file) {
            const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${file.id}?lm=${file.lastModifiedAt}`;
            const localFile = file.mimeType === 'folder'
                ? undefined
                : OfflineDataDownloader.isMediaSynced(file.projectId, file.id, file.getFileId());
            let panoramaImage = false;
            if (file.mimeType.startsWith('image') && file.metaData != null) {
                try {
                    file.metaData = JSON.parse(file.metaData);
                }
                catch (e) {
                    console.debug('cant parse item.metaData');
                }
            }
            if (file.mimeType.startsWith('image') &&
                file.metaData != null &&
                !Number.isNaN(file.metaData['width']) &&
                !Number.isNaN(file.metaData['height']) &&
                file.metaData['width'] / file.metaData['height'] === 2) {
                panoramaImage = true;
            }
            const thumb = file.mimeType === 'folder' ? (<Ripple style={{ ...imageSize, flex: 1, alignItems: 'center', alignContent: 'center', alignSelf: 'center' }} onPress={this.onPressImage(file)} key={`folder${fileId}`} onLongPress={onLongPress}>
            <Icon icon="folder-outline" toolTip="" outerSize={imageSize.height} iconSize={imageSize.height != null ? imageSize.height / 2 : undefined}/>
          </Ripple>) : (UploadTypes.getImageForFileName({ targetUrl, name: file.orgFilename, preview: localFile }, onPress, onLongPress, {
                id: file.id,
                metaData: file.imageMetadata,
            }, panoramaImage, imageSize.width, imageSize.height));
            return thumb;
        }
        return (<Ripple style={{ ...imageSize, flex: 1, alignItems: 'center', alignContent: 'center', alignSelf: 'center' }} onPress={() => onPress} onLongPress={onLongPress} key={`fileLoading${fileId}`}>
        <Icon icon="file-outline" toolTip="" outerSize={imageSize.height} iconSize={imageSize.height != null ? imageSize.height / 2 : undefined}/>
      </Ripple>);
    }
}

import React from 'react';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { Image, View } from 'react-native';
import { I18n } from '../../i18n/I18n';
const notFoundImage = require('../../assets/img/no_blueprint.png');
const emptyPlanListView = ({ width, searchWords, }) => {
    let headerText = I18n.m.getMessage('plansNotFound');
    let bodyText = I18n.m.getMessage('plansNotFoundDescription');
    if (searchWords.length === 0) {
        headerText = I18n.m.getMessage('planUploadDescription');
        bodyText = I18n.m.getMessage('plansUploadHint');
    }
    return (<View style={{ alignItems: 'center', alignContent: 'center' }}>
      <Image style={{ width, height: 300, resizeMode: 'contain', marginTop: 16 }} resizeMode="contain" source={notFoundImage}/>
      <View style={{ width, marginTop: 16 }}>
        <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2} strong>
          {headerText}
        </MaterialText>
        <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
          {bodyText}
        </MaterialText>
      </View>
    </View>);
};
export default emptyPlanListView;

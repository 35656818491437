var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Draggable = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _DragHandler = require("./DragHandler");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Draggable = exports.Draggable = function (_PureComponent) {
  function Draggable(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Draggable);
    _this = _callSuper(this, Draggable, [props]);
    _this.cancelDrag = function (_e) {
      _DragHandler.DragHandler.cancelDrag();
    };
    _this.getPosition = (0, _asyncToGenerator2.default)(function* () {
      return new Promise(function (resolve) {
        if (_this.v != null) {
          _this.v.measure(function (_x, _y, width, height, pageX, pageY) {
            resolve({
              width: width,
              height: height,
              x: pageX,
              y: pageY
            });
          });
        } else {
          resolve({
            x: 0,
            y: 0,
            width: 0,
            height: 0
          });
        }
      });
    });
    _this.setView = function (v) {
      _this.v = v;
    };
    _this.startDrag = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (_e) {
        var getPosition = yield _this.getPosition();
        _this.startDragWithPosition(getPosition);
      });
      return function (_x2) {
        return _ref2.apply(this, arguments);
      };
    }();
    _this.startDragEvent = function (drag) {
      if (_this.props.dragId != null && drag.id === _this.props.dragId) {
        if (drag.startPosition != null) {
          _this.startDragWithPosition(drag.startPosition);
        } else {
          _this.startDrag().catch(function (err) {
            return void 0;
          });
        }
      }
    };
    _this.startDragWithPosition = function (startPosition) {
      if (_this.props.children != null) {
        var child = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'relative',
            width: startPosition.width
          },
          children: _this.props.children
        });
        _DragHandler.DragHandler.startDrag(new _DragHandler.DragElement(startPosition, child, _this.props.dropData, _this.props.onDropped, _this.props.onDropNotInside, _this.props.opacity != null ? _this.props.opacity : 0.5, _this.props.onDragShouldStart, _this.props.onStartDrag));
      }
    };
    return _this;
  }
  (0, _inherits2.default)(Draggable, _PureComponent);
  return (0, _createClass2.default)(Draggable, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.dragId != null) {
        _DragHandler.DragHandler.startDragEvent.attach(this.startDragEvent);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.props.dragId != null) {
        _DragHandler.DragHandler.startDragEvent.detach(this.startDragEvent);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var webStyle = Object.assign({}, _ThemeManager.ThemeManager.noSelectionWebStyle());
      if (this.props.dragId != null) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          collapsable: false,
          ref: this.setView,
          style: webStyle,
          children: this.props.children
        });
      }
      var touchableWithoutFeedbackProps;
      var onPressOut = this.cancelDrag;
      if (this.props.startsOnLongPress === true) {
        touchableWithoutFeedbackProps = {
          onPressOut: onPressOut,
          onLongPress: this.startDrag
        };
      } else {
        touchableWithoutFeedbackProps = {
          onPressOut: onPressOut,
          onPressIn: this.startDrag
        };
      }
      var cssProps = _reactNativeWeb.Platform.OS === 'web' ? Object.assign({
        cursor: 'move'
      }, webStyle) : null;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.TouchableWithoutFeedback, Object.assign({}, touchableWithoutFeedbackProps, {
        style: cssProps,
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          collapsable: false,
          ref: this.setView,
          style: Object.assign({}, cssProps),
          children: this.props.children
        })
      }));
    }
  }]);
}(_react.PureComponent);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataGetOptions = void 0;
var ODataGetOptions = (0, _createClass2.default)(function ODataGetOptions() {
  (0, _classCallCheck2.default)(this, ODataGetOptions);
});
exports.ODataGetOptions = ODataGetOptions;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _YearButton = _interopRequireDefault(require("./YearButton"));
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var YearSelection = exports.default = function (_React$Component) {
  function YearSelection(props) {
    var _this;
    (0, _classCallCheck2.default)(this, YearSelection);
    _this = _callSuper(this, YearSelection, [props]);
    _this.selectYear = function (year) {
      _this.props.updateDate({
        newYear: year
      });
      _this.props.setSelection('daySelection');
    };
    _this.getSelection = function () {
      var rows = [];
      for (var i = 0; i < 51; i += 3) {
        var row = [];
        for (var j = 0; j < 3; j += 1) {
          row.push((0, _jsxRuntime.jsx)(_YearButton.default, {
            year: i + j + 2000,
            selectYear: _this.selectYear,
            selected: i + j + 2000 === _this.props.viewDate.getFullYear(),
            marginLeft: j > 0 ? 9 : 0
          }, i + j));
        }
        rows.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row'
          },
          children: row
        }, `${i}V`));
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        children: rows
      });
    };
    return _this;
  }
  (0, _inherits2.default)(YearSelection, _React$Component);
  return (0, _createClass2.default)(YearSelection, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginLeft: 24,
          width: 324,
          height: 322,
          justifyContent: 'center'
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
          showsVerticalScrollIndicator: false,
          children: this.getSelection()
        })
      });
    }
  }]);
}(_react.default.Component);
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../CurrentProject';
import { DashBoardItem } from './DashBoardItem';
export class TicketsItem extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.pressCreateTicket = async (e) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                Routing.instance.openDialog('ticket', { projectId, id: 'new' })(e);
            }
        };
        this.loadDashData = (tickets) => {
            this.setState({ counts: tickets.filter((t) => !t.archived && !t.deleted).length });
        };
        this.state = {
            counts: CurrentProject.instance.getCurrentTickets().filter((t) => !t.archived && !t.deleted).length,
        };
    }
    componentDidMount() {
        this.mounted = true;
        CurrentProject.ticketsChanged.attach(this.loadDashData);
    }
    componentWillUnmount() {
        this.mounted = false;
        CurrentProject.ticketsChanged.detach(this.loadDashData);
    }
    render() {
        const { counts } = this.state;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        return (<Ripple style={{ width: '100%', paddingBottom: 16 }} onPress={Routing.instance.goToButton(`/projects/${projectId}/tickets`)}>
        <View style={{ width: '100%' }}>
          <DashBoardItem title={I18n.m.getMessage('menuTickets')} icon="map-marker-check-outline" iconColor="#FF5252" counts={counts}/>
        </View>
      </Ripple>);
    }
}

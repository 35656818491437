import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, Linking, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import VCardGenerator from '../../utils/VCardGenerator';
import { Download } from '../../repo/file/Download';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { QRCodeView } from '../QRCodeView';
export class UserDetailsPU extends PureComponent {
    constructor(props) {
        super(props);
        this.renderSpace = (<View style={{ height: 8 }}/>);
        this.createQrCode = async (_e) => {
            console.log('pressed create qr code');
            const { user, qrCodeView } = this.state;
            this.setState({ qrCodeView: !qrCodeView });
            if (user != null) {
                const generator = await VCardGenerator.fromUserEntityWithLogo(user);
                Download.downloadDataAsString('vcard.vcf', 'text/vcard', generator.getAsVcard()).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.renderAddress = (company) => {
            return (<View key="phone" style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                }}>
        <View style={{
                    flex: 1,
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
            {I18n.m.getMessage('address')}
          </MaterialText>
          <MaterialText>{`${company.street} ${company.streetNr}`}</MaterialText>
          <MaterialText>{`${company.zip} ${company.city}`}</MaterialText>
          <MaterialText>{company.country}</MaterialText>
        </View>
        <Icon onPress={this.openUrl(`https://www.google.de/maps/dir//'${company.street} ${company.streetNr} ${company.zip} ${company.city} ${company.country}'`)} icon="directions" color="#FFFFFF" toolTip={I18n.m.getMessage('address')} backgroundColor={ThemeManager.style.brandPrimary}/>
      </View>);
        };
        this.renderPhone = (phone) => {
            if (phone == null || phone.length === 0) {
                return null;
            }
            return (<View key="phone" style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                }}>
        <View style={{
                    flex: 1,
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
            {I18n.m.getMessage('addressPhone')}
          </MaterialText>
          <MaterialText>{phone}</MaterialText>
        </View>
        <Icon onPress={this.openUrl(`tel:${phone}`)} icon="phone" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('addressCall')} backgroundColor="#FFFFFF" borderStyle="solid"/>
      </View>);
        };
        this.renderMail = (mails) => {
            if (mails == null || mails.length === 0) {
                return null;
            }
            return (<View key="mail" style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                }}>
        <View style={{
                    flex: 1,
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
            {I18n.m.getMessage('email')}
          </MaterialText>
          <MaterialText>{mails.join(', ')}</MaterialText>
        </View>
        <Icon onPress={this.openUrl(`mailto:${mails[0]}`)} icon="email-outline" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('email')} backgroundColor="#FFFFFF" borderStyle="solid"/>
      </View>);
        };
        this.openUrl = (url) => (_e) => {
            Linking.openURL(url).catch((err) => console.debug(err));
        };
        this.state = {
            isLoading: false,
            init: false,
            user: null,
            qrCodeView: false,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        if (Keyboard != null)
            Keyboard.dismiss();
    }
    render() {
        const { closeFunction } = this.props;
        const { init, user, qrCodeView } = this.state;
        let content = <Spinner />;
        if (init && user != null) {
            if (qrCodeView) {
                content = (<View style={{
                        flexDirection: 'row',
                        padding: ThemeManager.style.getScreenRelativePixelSize(16),
                        width: '100%',
                        justifyContent: 'center',
                    }} pointerEvents="box-none">
            <QRCodeView user={user}/>
          </View>);
            }
            else {
                content = (<View style={{
                        flexDirection: 'column',
                        padding: ThemeManager.style.getScreenRelativePixelSize(16),
                        width: '100%',
                    }} pointerEvents="box-none">
            <View style={{
                        borderRadius: ThemeManager.style.getScreenRelativePixelSize(64),
                        width: ThemeManager.style.getScreenRelativePixelSize(128),
                        alignSelf: 'center',
                    }}>
              <UserImage size={128} user={user}/>
            </View>
            <View style={{
                        width: '100%',
                        paddingTop: ThemeManager.style.getScreenRelativePixelSize(16),
                    }}>
              <MaterialText type={MaterialTextTypes.H6}>{`${user.firstname} ${user.lastname}`}</MaterialText>
              {this.renderSpace}
              {this.renderPhone(user.phone)}
              {this.renderSpace}
              {this.renderMail(user.emails)}
            </View>
          </View>);
            }
        }
        else if (init && user == null) {
            content = (<View style={{
                    flexDirection: 'column',
                    padding: ThemeManager.style.getScreenRelativePixelSize(16),
                    width: '100%',
                    minHeight: 64,
                }} pointerEvents="box-none">
          <MaterialText>{I18n.m.getMessage('userNotFound')}</MaterialText>
        </View>);
        }
        return (<View style={{ width: '100%', position: 'relative' }}>
        <View style={{ position: 'absolute', top: 4, left: 4 }}>
          {qrCodeView ? (<Icon key="backToDetails" icon="account-outline" toolTip={I18n.m.getMessage('userDetailsBackToUser')} onPress={() => {
                    this.setState({ qrCodeView: false });
                }}/>) : (<Icon key="showQrCode" icon="qrcode" toolTip={I18n.m.getMessage('userDetailsQrCodeHelp')} onPress={() => {
                    this.setState({ qrCodeView: true });
                }}/>)}
        </View>
        <View style={{ position: 'absolute', top: 4, right: 4 }}>
          <Icon icon="close" toolTip={I18n.m.getMessage('close')} onPress={closeFunction != null ? closeFunction : Routing.instance.closeDialog(true)}/>
        </View>
        {content}
      </View>);
    }
    async init() {
        await DateLocale.waitFor(() => UpmeshClient.instance.ready);
        let user = null;
        try {
            user = await AuthClient.instance.modals.user.getById(this.props.data.userId);
        }
        catch (e) {
            console.debug('cant get user', e);
        }
        const companyUsers = await UpmeshClient.instance.modals.companyMember.get({
            filter: `userId eq '${this.props.data.userId}' and deleted ne true`,
        });
        if (user != null && companyUsers.length > 0) {
            user.phone = companyUsers[0].phone;
        }
        this.setState({ user, init: true });
    }
}
UserDetailsPU.defaultProps = {
    data: null,
};
UserDetailsPU.openMemberDetails = (data) => (_e) => {
    DialogUp.instance?.open({
        content: <UserDetailsPU data={data} closeFunction={DialogUp.instance?.close}/>,
    });
};

import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { StringList } from 'materialTheme/src/theme/components/forminput/StringList';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { CustomFieldListOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { DefaultErrorHandler } from '../../../DefaultErrorHandler';
import { I18n } from '../../../../i18n/I18n';
import { hasLists, openCopyListsDialog } from './CopyListsDialog';
import { TicketLayoutDefaultOptions } from './TicketLayoutDefaultOptions';
export function TicketLayoutMultiselectListFieldOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const [formError, setFormError] = useState(new Map());
    const [importCounter, setImportCounter] = useState(0);
    const submitForm = (_e) => {
        const errors = new Map();
        if (currentField.label == null || currentField.label.length === 0)
            errors.set('label', I18n.m.getMessage('required'));
        if (!currentField.customField?.options.list || currentField.customField.options.list.length === 0)
            errors.set('options', I18n.m.getMessage('required'));
        setFormError(errors);
        if (errors.size > 0)
            return;
        props.onSave(currentField);
        DialogBetween.instance?.close();
    };
    const icon = TicketLayoutsEntity.getIconName(props.field);
    return (<>
      <DialogTitle iconRight={{ icon, color: ThemeManager.style.brandSuccess, toolTip: '' }}>
        {props.newField
            ? I18n.m.getMessage('ticketLayoutOptionsTileNew')
            : I18n.m.getMessage('ticketLayoutOptionsTileEdit', { title: currentField.label })}
      </DialogTitle>
      <DialogContent>
        <TicketLayoutDefaultOptions approverCanVisible={props.approverCanVisible} assigneeCanEditVisible={props.assigneeCanEditVisible} field={currentField} onChange={(f) => {
            setCurrentField({ ...currentField, ...f });
        }} onError={(err) => {
            setFormError(err);
        }} onSuccess={() => setFormError(new Map())}>
          <StringList title={I18n.m.getMessage('ticketLayoutOptionsListItems')} initValue={currentField.customField?.options.list} key={`importCounter_${importCounter}`} error={formError.has('options')} helperText={formError.get('options')} optionIcon="import" optionTooltip={I18n.m.getMessage('importList')} options={hasLists()
            ? openCopyListsDialog({
                onSelect: (l) => {
                    const customField = currentField.customField
                        ? currentField.customField
                        : { type: 'List', options: new CustomFieldListOptions() };
                    if (customField.options == null)
                        customField.options = new CustomFieldListOptions();
                    if (customField.options.list == null)
                        customField.options.list = [];
                    customField.options.list = [...customField.options.list, ...l];
                    setCurrentField({ ...currentField, customField });
                    setImportCounter(importCounter + 1);
                },
            })
            : undefined} onChange={(e) => {
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'MultiselectList', options: new CustomFieldListOptions() };
            if (customField.options == null) {
                customField.options = new CustomFieldListOptions();
            }
            const newFormErrors = new Map(formError);
            if (e.length > 0)
                newFormErrors.delete('options');
            else if (customField.options.list && customField.options.list.length > 0)
                newFormErrors.set('options', I18n.m.getMessage('required'));
            setFormError(newFormErrors);
            if (props.field.customField?.options.children && props.field.customField?.options.children?.length > 0) {
                const allOnSelectedRules = props.field.customField.options.children.flatMap((child) => child.onSelectedRule);
                const allIncluded = allOnSelectedRules.every((rule) => e.includes(rule));
                if (allIncluded) {
                    customField.options.list = e;
                    setCurrentField({ ...currentField, customField });
                }
                else {
                    const err = new Error(I18n.m.getMessage('projectSettingsTicketLayoutRuleDeleteAndUpdateError'));
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                    DialogBetween.instance?.close();
                }
            }
            else {
                customField.options.list = e;
                setCurrentField({ ...currentField, customField });
            }
        }}/>
        </TicketLayoutDefaultOptions>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => DialogBetween.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton disabled={formError.size > 0 || currentField.label == null || currentField.label.length === 0} onPress={submitForm} title={props.newField ? I18n.m.getMessage('create') : I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}

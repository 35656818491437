export class DashboardWidgetPreview {
}
DashboardWidgetPreview.activites = require('../../assets/img/widgets/Aktivitaeten.png');
DashboardWidgetPreview.contact = require('../../assets/img/widgets/Kontakt.png');
DashboardWidgetPreview.notes = require('../../assets/img/widgets/Notizen.png');
DashboardWidgetPreview.images = require('../../assets/img/widgets/Bilder.png');
DashboardWidgetPreview.fokusTickets = require('../../assets/img/widgets/Fokustickets.png');
DashboardWidgetPreview.gantt = require('../../assets/img/widgets/Gantt.png');
DashboardWidgetPreview.iFrame = require('../../assets/img/widgets/iFrame.png');
DashboardWidgetPreview.projectInfo = require('../../assets/img/widgets/Projektinfos.png');
DashboardWidgetPreview.projectContact = require('../../assets/img/widgets/Projektkontakt.png');
DashboardWidgetPreview.stats = require('../../assets/img/widgets/Statistik.png');
DashboardWidgetPreview.overview = require('../../assets/img/widgets/Uebersicht.png');
DashboardWidgetPreview.weather = require('../../assets/img/widgets/Wetter.png');
DashboardWidgetPreview.absences = require('../../assets/img/widgets/Abwesenheiten.png');
DashboardWidgetPreview.openAbsences = require('../../assets/img/widgets/OffeneAntraege.png');
DashboardWidgetPreview.timetrackings = require('../../assets/img/widgets/Zeiterfassungen.png');
DashboardWidgetPreview.projects = require('../../assets/img/widgets/Projektfavoriten.png');

import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ChangePlanTags } from 'upmesh-core/src/client/commands/plans/ChangePlanTags';
import { CreateMap } from 'upmesh-core/src/client/commands/plans/CreateMap';
import { CreatePlan } from 'upmesh-core/src/client/commands/plans/CreatePlan';
import { DeletePlan } from 'upmesh-core/src/client/commands/plans/DeletePlan';
import { PlanEntity } from 'upmesh-core/src/client/query/entities/PlanEntity';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FabWithCamera } from '../FabWithCamera';
import { NoRights } from '../NoRights';
import { CurrentProject } from '../project/CurrentProject';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { PageView } from '../root/PageView';
import EmptyPlanListView from './EmptyPlanListView';
import PlanReadyForReleaseNotice from './PlanReadyForReleaseNotice';
import { PlansFilterDialog } from './PlansFilterDialog';
import { PlanTagFilterIcon } from './PlanTagFilterIcon';
import { PlanThumb169 } from './PlanThumb169';
const notFoundImage = require('../../assets/img/no_blueprint.png');
export class PlansView extends PureComponent {
    constructor(props) {
        super(props);
        this.counterTO = 0;
        this.mounted = false;
        this.targetWidth = 100;
        this.filter = new PlanFilter();
        this.toggleTableViewModeEnabled = () => {
            this.setState((prevState) => {
                SimpleStorage.set('planListPageTableViewModeEnabled', (!prevState.tableViewModeEnabled).toString());
                return { tableViewModeEnabled: !prevState.tableViewModeEnabled };
            });
        };
        this.renderThumbnails = (planThumbs) => {
            const { size } = this.props;
            const numColumns = this.createNumColumns();
            return (<View>
        <FlatList key={`planlist_${numColumns}`} keyExtractor={this.getItemKey} style={{
                    width: size.contentWidth,
                    height: size.contentHeight - ThemeManager.style.headerHeight,
                    margin: 8,
                }} numColumns={numColumns} data={planThumbs} renderItem={this.renderThumbRow} ListFooterComponent={<View />} ListFooterComponentStyle={{ height: 200 }} onEndReachedThreshold={0.5}/>
      </View>);
        };
        this.renderTable = (visiblePlans, tableMaxHeight) => (<Card style={{
                marginHorizontal: 8,
                marginTop: 16,
                height: tableMaxHeight + 16,
            }}>
      <Table tableName="PlansView" actionItemsLength={2} actions={(item) => {
                const actions = [
                    { onAction: this.deletePlan, toolTip: I18n.m.getMessage('deletePlan'), icon: 'delete-outline' },
                ];
                if (item.activePlanId !== 'MAP') {
                    actions.unshift({
                        onAction: this.downloadPlan,
                        toolTip: I18n.m.getMessage('downloadPlan'),
                        icon: 'download-outline',
                    });
                }
                return actions;
            }} maxHeight={tableMaxHeight} emptyTableImage={notFoundImage} emptyTableText={I18n.m.getMessage('planUploadDescription')} emptyTableHint={I18n.m.getMessage('plansUploadHint')} data={visiblePlans} sortBy="createdAt" sortDirection="desc" columns={[
                {
                    title: '',
                    hiddenTitleForSettings: I18n.m.getMessage('thumb'),
                    keyInData: 'title',
                    style: { width: 100 },
                    onCellPress: this.gotoPlanDetails,
                    sortable: false,
                    cellRenderer: this.renderPlanThumbTableCell,
                },
                {
                    title: I18n.m.getMessage('planTitle'),
                    keyInData: 'title',
                    style: { width: 200 },
                    dataType: 'string',
                    onCellPress: this.gotoPlanDetails,
                },
                {
                    title: I18n.m.getMessage('plansCreatedDate'),
                    keyInData: 'createdAt',
                    dataType: 'Date',
                    style: { width: 160 },
                    cellStyle: { justifyContent: 'flex-end' },
                    onCellPress: this.gotoPlanDetails,
                },
                {
                    title: I18n.m.getMessage('plansActiveVersionDate'),
                    keyInData: 'activatedOn',
                    dataType: 'Date',
                    style: { width: 160 },
                    cellStyle: { justifyContent: 'flex-end' },
                    onCellPress: this.gotoPlanDetails,
                },
                {
                    title: I18n.m.getMessage('plansNumberOfVersions'),
                    keyInData: 'numberOfVersions',
                    dataType: 'number',
                    style: { width: 130 },
                    onCellPress: this.gotoPlanDetails,
                },
                {
                    title: I18n.m.getMessage('tags'),
                    keyInData: 'tags',
                    dataType: 'string',
                    style: { width: 130 },
                    cellRenderer: this.renderTags,
                },
            ]}/>
    </Card>);
        this.downloadPlan = (item) => () => {
            UpmeshClient.instance.modals.planVersion
                .getById(item.activePlanId)
                .then((planVersion) => {
                const ex = planVersion.orgFilename.substr(planVersion.orgFilename.lastIndexOf('.'));
                CurrentProject.instance.downloadPlanNow(item, planVersion, ex).catch((error) => {
                    DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                });
            })
                .catch((error) => {
                DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
            });
        };
        this.renderPlanThumbTableCell = (item, columnData, _index, _sLayout) => {
            if (item == null)
                return <View />;
            const { style } = columnData;
            return (<View style={[{ justifyContent: 'flex-start' }, style]} key={`ViewAroundThumb${item.id}}`}>
        <PlanThumb169 plan={item} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} projectId={item.projectId} fileId={item.activePlanId} width={90} onPress={this.gotoPlanDetails} onPressData={item} noBorder/>
      </View>);
        };
        this.renderTags = (item, columnData, _index) => {
            if (item == null)
                return <View />;
            const { style } = columnData;
            let chips = [];
            if (item.tags && item.tags.length > 0) {
                chips = item.tags.map((e) => {
                    return {
                        onPressChip: (e) => {
                            console.log('onPressChip', e);
                        },
                        title: e.tagName,
                        groupName: e.groupName,
                        groupId: e.groupName,
                        backgroundColor: e.color,
                        textColor: '#ffffff',
                    };
                });
            }
            return (<View style={[{ justifyContent: 'center', overflow: 'hidden', alignItems: 'flex-start' }, style]} key={`ViewAroundTags${item.id}}`}>
        <View style={{
                    zIndex: 2,
                    width: 5000,
                    overflow: 'hidden',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
          <Ripple toolTip={I18n.m.getMessage('editTags')} onPress={this.editTags(item)} style={{
                    width: 36,
                    height: 36,
                    borderRadius: 18,
                    borderStyle: 'dotted',
                    borderColor: ThemeManager.style.borderColor,
                    borderWidth: ThemeManager.style.borderWidth,
                    justifyContent: 'center',
                    marginRight: 8,
                }}>
            <MaterialText centeredBox centeredText>
              {chips.length}
            </MaterialText>
          </Ripple>
          {chips.map((c) => (<Chip {...c} key={c.title} onPressChip={() => {
                        const letT = this.filter.t != null && this.filter.t.length > 0 ? [...this.filter.t] : [];
                        const isIn = letT.findIndex((a) => a === c.title);
                        if (isIn > -1)
                            letT.splice(isIn, 1);
                        else if (isIn === -1)
                            letT.push(c.title);
                        const currentFilter = { ...this.filter, t: letT };
                        Routing.instance.changeQueryParameter({
                            fp: JSON.stringify(currentFilter),
                        });
                        this.setFilter(currentFilter);
                    }}/>))}
        </View>
      </View>);
        };
        this.renderPlanReleaseNotice = () => {
            const { plansTempNumber } = this.state;
            if (plansTempNumber == null || plansTempNumber === 0)
                return null;
            const tp = new PlanEntity();
            tp.id = '0';
            return (<PlanReadyForReleaseNotice onPress={() => {
                    this.pressTempPlan(tp).catch((err) => console.error(err));
                }}/>);
        };
        this.renderFab = () => {
            const buttons = [
                {
                    icon: 'plus',
                    onPress: (_e) => {
                        if (Fab.instance != null)
                            Fab.instance.closeButtons();
                        this.pressAddPlan(_e).catch((err) => console.debug(err));
                    },
                    text: I18n.m.getMessage('tooltipUploadPlan'),
                },
            ];
            if (CurrentProject.instance.getCurrentProject()?.projectSubscription === 'enterprise' &&
                CompanyUserInfo.companySettings != null &&
                CompanyUserInfo.companySettings.hasModule('maps')) {
                buttons.push({
                    icon: 'plus',
                    onPress: (_e) => {
                        if (Fab.instance != null)
                            Fab.instance.closeButtons();
                        this.pressCreateMap(_e).catch((err) => console.debug(err));
                    },
                    text: I18n.m.getMessage('tooltipCreateMap'),
                });
            }
            return (<FabWithCamera size={this.props.size} showCamera={false} additionalActionButtons={buttons} projectId={this.props.projectId}/>);
        };
        this.changeView = (index, _button) => {
            this.setState({ tableViewModeEnabled: index === 1 }, () => {
                SimpleStorage.set('planListPageTableViewModeEnabled', (index === 1).toString());
            });
        };
        this.editTags = (plan) => (e) => {
            const projectTags = [];
            const project = CurrentProject.instance.getCurrentProject();
            if (project == null)
                return;
            const projectTagGroups = [];
            for (const key in project.tagGroups) {
                projectTagGroups.push({
                    backgroundColor: project.tagGroups[key].color,
                    groupName: project.tagGroups[key].groupName,
                    id: project.tagGroups[key].groupName,
                });
            }
            project.tags?.forEach((t) => {
                projectTags.push({
                    id: t.tagName,
                    groupId: t.groupName,
                    title: t.tagName,
                    backgroundColor: t.color,
                    secondTextLine: t.groupName,
                    thumbnail: <View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: t.color }}/>,
                });
            });
            const planTags = [];
            if (plan != null && plan.tags != null && plan.tags.length > 0) {
                plan.tags.forEach((t) => {
                    planTags.push({
                        id: t.tagName,
                        title: t.tagName,
                        backgroundColor: t.color,
                        groupId: t.groupName,
                        secondTextLine: t.groupName,
                        thumbnail: <View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: t.color }}/>,
                    });
                });
            }
            const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
            const listItems = [];
            for (let i = 0; i < projectTags.length; i += 1) {
                const aChip = projectTags[i];
                if (aChip.title != null) {
                    listItems.push({
                        selected: planTags != null && planTags.length > 0 ? planTags.findIndex((a) => a.title === aChip.title) > -1 : false,
                        secondTextLine: aChip.secondTextLine,
                        thirdTextLine: aChip.thirdTextLine,
                        thumbnail: aChip.thumbnail ? { thumbnail: aChip.thumbnail, width: 24, rounded: true } : undefined,
                        title: aChip.title,
                        groupId: aChip.groupId,
                        id: aChip.id ? aChip.id : aChip.title,
                    });
                }
            }
            DialogUp.instance?.open({
                openPosition,
                content: (<ChipDialogForm key="TagsChipDialogForm" title={I18n.m.getMessage('tags')} sortByGroup items={listItems} chipGroups={projectTagGroups} onCancel={DialogUp.instance.close} multiselect onSave={(selectedTags) => {
                        const tags = [];
                        if (selectedTags != null) {
                            selectedTags.forEach((c) => {
                                if (c.selected) {
                                    const tag = projectTags.find((a) => a.title === c.title);
                                    if (tag != null) {
                                        tags.push({ tagName: tag.title, color: tag.backgroundColor, groupName: tag.groupId });
                                    }
                                }
                            });
                        }
                        DialogUp.instance?.close(() => {
                            const c = new ChangePlanTags({
                                tags,
                            }, plan.id);
                            c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                        });
                    }} canAddNewChips={false}/>),
                contentPadding: false,
                fullscreenResponsive: true,
                showCloseButton: false,
                showCloseIcon: false,
            });
        };
        this.openFilter = () => {
            PlansFilterDialog.open(this.plans, this.setFilter, this.filter);
        };
        this.setFilter = (filter) => {
            this.filter = filter;
            this.updateVisiblePlans().catch((err) => console.debug(err));
        };
        this.onLayoutPlanReleaseNotice = (e) => {
            if (e?.nativeEvent?.layout?.height > 0) {
                this.setState({ releasePlanHeight: e.nativeEvent.layout.height });
            }
            else {
                this.setState({ releasePlanHeight: 0 });
            }
        };
        this.getItemKey = (item, _index) => `plan${item.id}`;
        this.gotoPlanDetails = (plan) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                Routing.instance.goTo(`/projects/${projectId}/plans/${plan.id}`);
            }
        };
        this.handlePlanChange = (plans) => {
            this.plans = [...plans];
            this.updateVisiblePlans().catch((err) => console.debug(err));
        };
        this.onSearch = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.setState({ searchWords: text }, () => {
                this.searchTimer = window.setTimeout(this.searchNow(text), 250);
            });
        };
        this.onTempPlansChanged = (_e) => {
            const { projectId } = this.props;
            UpmeshClient.instance.modals.planFiles
                .count(`projectId eq '${projectId}' and uploaded eq true and processed ne true`)
                .then((count) => {
                this.setState({ plansTempNumber: count });
            })
                .catch((err) => console.error(err));
        };
        this.pressCreateMap = async (e) => {
            const { error } = this.state;
            if (error != null && error.length > 0) {
                Routing.instance.alert.post({ text: I18n.m.getMessage(error) });
                return;
            }
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId == null) {
                return;
            }
            const catched = await DefaultErrorHandler.getProjectErrors(projectId);
            if (catched) {
                return;
            }
            const c = new CreateMap({
                activatedBy: '',
                activatedOn: new Date(),
                projectId,
                title: '',
                address: new AddressEntity(),
            });
            c.canI()
                .then(() => Routing.instance.openDialog('addMap', {})(e))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.pressAddPlan = async (e) => {
            const { error } = this.state;
            if (error != null && error.length > 0) {
                Routing.instance.alert.post({ text: I18n.m.getMessage(error) });
                return;
            }
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId == null) {
                return;
            }
            const catched = await DefaultErrorHandler.getProjectErrors(projectId);
            if (catched) {
                return;
            }
            const c = new CreatePlan({ activatedBy: '', activatedOn: new Date(), activePlanId: '', projectId, title: '' });
            c.canI()
                .then(() => Routing.instance.openDialog('uploadPlan', {})(e))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.pressTempPlan = async (e) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                Routing.instance.openDialog('tempPlans', { projectId })(e);
            }
        };
        this.deletePlan = (item) => () => {
            RightsManager.hasWriteRight(item.projectId, CurrentUser.userId, 'commandDiscardPlanFile')
                .then((hasRight) => {
                if (!hasRight)
                    throw { message: 'Keine Berechtigung', messageCode: 'forbiddenCommand' };
                return UpmeshClient.instance.modals.ticket.get({ filter: `planId eq '${item.id}'`, top: 1 });
            })
                .then((tickets) => {
                let text = I18n.m.getMessage('planDeleteQuestionTextWithoutTickets');
                if (tickets.length > 0)
                    text = I18n.m.getMessage('planDeleteQuestionTextWithTickets');
                Routing.instance.alert.post({
                    text,
                    title: I18n.m.getMessage('planDeleteQuestionTitle', { plantitle: item.title }),
                    buttons: [
                        <ContainedButton key="cancel" onPress={Alert.instance?.close} title={I18n.m.getMessage('cancel')}/>,
                        <ContainedButton key="del" onPress={this.deletePlanNow(item)} title={I18n.m.getMessage('delete')} backgroundColor={ThemeManager.style.brandDanger}/>,
                    ],
                });
            })
                .catch((e) => {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            });
        };
        this.deletePlanNow = (item) => () => {
            Alert.instance?.close(() => {
                try {
                    const d = new DeletePlan({}, item.id);
                    d.execute()
                        .then(() => {
                        const index = this.plans.findIndex((p) => p.id === item.id);
                        if (index > -1) {
                            this.plans.splice(index, 1);
                            this.updateVisiblePlans().catch((err) => console.debug(err));
                        }
                    })
                        .catch((err) => {
                        DefaultErrorHandler.showDefaultErrorAlert(err, I18n.m, false);
                    });
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, false);
                }
            });
        };
        this.renderThumbRow = ({ item }) => {
            let versionAlert = false;
            if (item.versions != null && item.versions.length > 0) {
                item.versions.sort((a, b) => {
                    return b.version - a.version;
                });
                if (item.versions[0].id !== item.activePlanId) {
                    versionAlert = true;
                }
            }
            return (<View key={`PlanThumb${item.id}`} style={{
                    padding: ThemeManager.style.getScreenRelativePixelSize(8),
                    width: this.targetWidth,
                }}>
        <PlanThumb169 plan={item} projectId={item.projectId} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} fileId={item.activePlanId} width={this.targetWidth - 2 * ThemeManager.style.getScreenRelativePixelSize(8)} onPress={this.gotoPlanDetails} onPressData={item} title={item.title} deleteFunction={this.deletePlan(item)} downloadFunction={item.activePlanId !== 'MAP' ? this.downloadPlan(item) : undefined} bottomRightIcon={versionAlert
                    ? {
                        icon: 'alert-circle-outline',
                        toolTip: '',
                        color: ThemeManager.style.brandDanger,
                        onPress: this.openNotLatestVersionDialog(item),
                    }
                    : undefined}/>
      </View>);
        };
        this.openNotLatestVersionDialog = (plan) => (_e) => {
            Dialog.instance?.open({
                content: I18n.m.getMessage('planCurrentVersionNotActiveOne'),
                buttons: [
                    <ContainedButton title={I18n.m.getMessage('ok')} backgroundColor="#ffffff" textColor={ThemeManager.style.brandPrimary} onPress={Dialog.instance?.close} key="okay"/>,
                    <ContainedButton title={I18n.m.getMessage('planToTheVersions')} backgroundColor="#ffffff" textColor={ThemeManager.style.brandPrimary} onPress={this.gotoPlanVersions(plan)} key="goToVersions"/>,
                ],
            });
        };
        this.gotoPlanVersions = (plan) => () => {
            Dialog.instance?.close(() => {
                const projectId = CurrentProject.instance.getCurrentProjectId();
                if (plan != null && plan.id != null && projectId != null) {
                    Routing.instance.goTo(`/projects/${projectId}/plans/${plan.id}/versions`);
                }
            });
        };
        this.searchNow = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNow(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            this.setState({ searchWords: text }, () => {
                this.updateVisiblePlans().catch((err) => console.debug(err));
            });
            Routing.instance.changeQueryParameter({ q: text });
        };
        try {
            this.filter = props.fp != null ? JSON.parse(props.fp) : new PlanFilter();
        }
        catch (e) {
            this.filter = new PlanFilter();
            console.debug('cant parse ticketfilter');
        }
        this.state = {
            init: false,
            error: '',
            searchWords: props.q != null ? props.q : '',
            visiblePlans: CurrentProject.instance.getCurrentPlans(),
            plansTempNumber: 0,
            tableViewModeEnabled: false,
            releasePlanHeight: 0,
        };
    }
    componentDidMount() {
        this.mounted = true;
        CurrentProject.plansChanged.attach(this.handlePlanChange);
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'PlanFiles',
            callback: this.onTempPlansChanged,
            attachKey: 'PlansView',
        });
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
        UpmeshClient.eventDispatcher.detach('PlanFiles', 'PlansView');
        CurrentProject.plansChanged.detach(this.handlePlanChange);
        if (this.counterTO != null) {
            clearTimeout(this.counterTO);
        }
    }
    render() {
        const { error, init, tableViewModeEnabled, searchWords, visiblePlans, releasePlanHeight } = this.state;
        const { size } = this.props;
        const secondView = null;
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        if (!init) {
            return (<Page>
          <Spinner />
        </Page>);
        }
        if (error != null && error.length > 0) {
            return (<PageView headerProps={{ title: I18n.m.getMessage('menuProjectPlans') }}>
          <NoRights error={error}/>
        </PageView>);
        }
        const maxTableHeight = size.contentHeight -
            ThemeManager.style.getScreenRelativePixelSize(48) -
            ThemeManager.style.headerHeight -
            sViewHeight -
            releasePlanHeight;
        let view = <EmptyPlanListView width={size.contentWidth} searchWords={searchWords}/>;
        if (visiblePlans != null && visiblePlans.length > 0) {
            view = tableViewModeEnabled
                ? this.renderTable(visiblePlans, maxTableHeight)
                : this.renderThumbnails(visiblePlans);
        }
        const isFilterActive = PlanFilter.isSet(this.filter);
        const tagIcon = (<PlanTagFilterIcon currentFilter={this.filter} setFilter={(f) => {
                Routing.instance.changeQueryParameter({
                    fp: JSON.stringify(f),
                });
                this.setFilter(f);
            }} plans={this.plans}/>);
        let filterIcon = (<Icon key={`planFilter_${isFilterActive}`} icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={this.openFilter} color={isFilterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
        if (isFilterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            filterIcon = (<View key={`filterButtonBigOuter${isFilterActive}`} style={{ marginLeft: 8 }}>
          <ContainedButton key={`planFilter_big_${isFilterActive}`} title={I18n.m.getMessage('filterChange')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={this.openFilter}/>
        </View>);
        }
        return (<PageView headerProps={{
                title: '',
                searchBarProps: {
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('planSiteSearchPlans'),
                    searchOnChange: this.onSearch,
                    tooltip: I18n.m.getMessage('searchPlans'),
                },
                rightButtons: [
                    filterIcon,
                    tagIcon,
                    <View key="toggleButton">
              {ResizeEvent.current.contentWidth <= ThemeManager.style.breakpointM ? (<Icon key={`showCanbanOrListButton${tableViewModeEnabled}`} icon={tableViewModeEnabled ? 'view-grid' : 'format-list-bulleted-square'} toolTip={I18n.m.getMessage(tableViewModeEnabled ? 'planDetailsViewChangeToTiles' : 'planViewChangeToListView')} onPress={this.toggleTableViewModeEnabled}/>) : (<View style={{ width: 144, marginLeft: 12 }} key="viewButton">
                  <SegmentedButton buttons={[{ icon: { icon: 'view-grid' } }, { icon: { icon: 'format-list-bulleted-square' } }]} onPress={this.changeView} singleSelectSelected={tableViewModeEnabled ? 1 : 0} density={2}/>
                </View>)}
            </View>,
                ],
            }} scrollable={false} style={{ maxWidth: secondView != null ? 320 : null, flexGrow: 1 }}>
        <View onLayout={this.onLayoutPlanReleaseNotice} style={{ backgroundColor: 'transparent' }}>
          {this.renderPlanReleaseNotice()}
        </View>
        {this.renderFab()}
        {view}
      </PageView>);
    }
    createNumColumns() {
        const { size } = this.props;
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const width = size.contentWidth - ThemeManager.style.contentPaddingValue;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = width / numColumns;
        this.targetWidth = targetWidth;
        return numColumns;
    }
    async init() {
        if (CurrentUser.entity == null) {
            this.setState({
                error: 'forbidden',
                init: true,
            });
            return;
        }
        const { projectId } = this.props;
        const userId = CurrentUser.entity.id;
        const [canRead, planListPageTableViewModeEnabled] = await Promise.all([
            RightsManager.hasReadRight(projectId, userId, 'plans'),
            SimpleStorage.get('planListPageTableViewModeEnabled'),
        ]);
        if (!this.mounted)
            return;
        if (!canRead) {
            this.setState({ error: 'forbidden', init: true });
            return;
        }
        this.plans = [...CurrentProject.instance.getCurrentPlans()];
        for (const plan of this.plans) {
            const versions = await UpmeshClient.instance.modals.planVersion.get({
                filter: `planId eq '${plan.id}' and deleted ne true`,
            });
            plan.numberOfVersions = versions.length > 0 ? versions.length : 1;
            plan.versions = versions;
        }
        this.setState({ init: true, tableViewModeEnabled: planListPageTableViewModeEnabled === 'true' }, () => {
            this.updateVisiblePlans().catch((err) => console.debug(err));
        });
        this.onTempPlansChanged();
    }
    async updateVisiblePlans() {
        const { searchWords } = this.state;
        let visiblePlans = [...this.plans];
        if (searchWords != null && searchWords.length > 0) {
            visiblePlans = await PlanFilter.filterPlansByText(searchWords, visiblePlans);
        }
        if (this.filter != null && PlanFilter.isSet(this.filter)) {
            visiblePlans = await PlanFilter.filterPlans(visiblePlans, this.filter);
        }
        this.setState({ visiblePlans: [...visiblePlans] });
    }
}
PlansView.defaultProps = {
    title: 'upmesh',
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortQuickChips = void 0;
var sortQuickChips = exports.sortQuickChips = function sortQuickChips(a, b) {
  if (a.time == null) return 1;
  if (b.time == null) return -1;
  if (a.time.hour > b.time.hour) return 1;
  if (a.time.hour === b.time.hour) {
    if (a.time.minute > b.time.minute) return 1;
    if (a.time.minute < b.time.minute) return -1;
  }
  return 0;
};
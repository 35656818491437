"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoveProjectImage = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var UserUploadedProjectImage_1 = require("../../events/project/UserUploadedProjectImage");
var ProjectEntity_1 = require("../../query/entities/ProjectEntity");
var RemoveProjectImage = function (_CommandRoot_1$Comman) {
  function RemoveProjectImage(data, projectId, token) {
    var _this;
    (0, _classCallCheck2.default)(this, RemoveProjectImage);
    _this = _callSuper(this, RemoveProjectImage, [data, projectId, token]);
    _this.commandName = 'RemoveProjectImage';
    _this.aggregate = 'project';
    _this.data = data;
    return _this;
  }
  (0, _inherits2.default)(RemoveProjectImage, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(RemoveProjectImage, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var userId = tokenData._id;
        yield ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(this.entityId, userId);
        if (userId === 'all') {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.entityId, userId, 'commandChangeProject')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = {
          projectImageFileId: ''
        };
        return new UserUploadedProjectImage_1.UserUploadedProjectImage(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.RemoveProjectImage = RemoveProjectImage;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SimpleStorage = void 0;
var ts_events_1 = require("ts-events");
var SimpleStorage = function () {
  function SimpleStorage() {
    (0, _classCallCheck2.default)(this, SimpleStorage);
  }
  return (0, _createClass2.default)(SimpleStorage, null, [{
    key: "init",
    value: function init(storage) {
      if (SimpleStorage.storage != null) {} else SimpleStorage.storage = storage;
    }
  }, {
    key: "set",
    value: function set(key, value) {
      var s = SimpleStorage.storage.set(key, value);
      SimpleStorage.onChange.post(key);
      return s;
    }
  }, {
    key: "get",
    value: function get(key) {
      return SimpleStorage.storage.get(key);
    }
  }, {
    key: "remove",
    value: function remove(key) {
      var s = SimpleStorage.storage.remove(key);
      SimpleStorage.onChange.post(key);
      return s;
    }
  }, {
    key: "getAllKeys",
    value: function getAllKeys() {
      return SimpleStorage.storage.getAllKeys();
    }
  }, {
    key: "clear",
    value: function clear() {
      return SimpleStorage.storage.clear();
    }
  }]);
}();
exports.SimpleStorage = SimpleStorage;
SimpleStorage.isInit = false;
SimpleStorage.onChange = new ts_events_1.AsyncEvent();
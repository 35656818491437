import { ReadModels } from 'cqrs-core/src/core/query/ReadModels';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { LogBookEntity } from 'upmesh-core/src/client/query/entities/LogBookEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { openAbsenceCreationChangeDialog } from '../absences/AbsenceCreationChangeDialog';
import { CompanyLogo } from '../companies/CompanyLogo';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ProjectImage } from '../project/ProjectImage';
import { TicketNavigation } from '../tickets/TicketNavigation';
export class LogBookItem extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = true;
        this.openLnk = async (e) => {
            const { logbookItem, deep, closeDialogBefore } = this.props;
            const { planVersionModel, planModel } = this.state;
            if (logbookItem.projectId != null && logbookItem.projectId.length > 0) {
                const catched = await DefaultErrorHandler.getProjectErrors(logbookItem.projectId);
                if (catched) {
                    await DefaultErrorHandler.getProjectErrors(logbookItem.projectId, true);
                    return;
                }
            }
            try {
                if ((logbookItem.entityType === 'Ticket' || logbookItem.entityType === 'TicketComments') &&
                    logbookItem.entityId != null) {
                    let ticketId = logbookItem.entityId;
                    if (logbookItem.entityType === 'TicketComments') {
                        try {
                            const ticketComment = await UpmeshClient.instance.modals.ticketComments.getById(logbookItem.entityId);
                            ticketId = ticketComment.ticketId;
                        }
                        catch (err) {
                            err.description = `item: ${JSON.stringify(logbookItem)}, details: ${err.description}`;
                            console.debug('COMMENT! cant get ticketComment for logbookItem error details:', err);
                            ErrorReporter.sendReport({
                                subject: `COMMENT! cant get ticketComment for logbookItem`,
                                data: err,
                                type: 'warn',
                            });
                            Routing.instance.alert.post({
                                text: `${I18n.m.getMessage('ticketsDetailsGetCommentError')} ${DefaultErrorHandler.getDefaultErrorMessages(err)}`,
                            });
                        }
                    }
                    TicketNavigation.setTickets([]);
                    if (closeDialogBefore) {
                        Dialog.instance?.close(() => {
                            Routing.instance.openDialog('ticket', { id: ticketId, sp: true })(e);
                        });
                    }
                    else {
                        Routing.instance.openDialog('ticket', { id: ticketId, sp: true })(e);
                    }
                }
                else if (logbookItem.entityType === 'Plan' && logbookItem.entityId != null) {
                    const plan = await planModel.getById(logbookItem.entityId);
                    if (!plan.deleted)
                        this.goTo(`/projects/${logbookItem.projectId}/plans/${logbookItem.entityId}`);
                }
                else if (logbookItem.entityType === 'Absence') {
                    const absence = await UpmeshClient.instance.modals.absence.getById(logbookItem.entityId);
                    openAbsenceCreationChangeDialog({ absence })(e);
                }
                else if (logbookItem.entityType === 'Folder') {
                    this.goTo(`/projects/${logbookItem.projectId}/files/${logbookItem.entityId}`);
                }
                else if (logbookItem.entityType === 'StoredFile') {
                    if (logbookItem.data['forEntity'] === 'Ticket' &&
                        logbookItem.data['forEntityId'] != null &&
                        logbookItem.data['forEntityId'].length > 0) {
                        TicketNavigation.setTickets([]);
                        if (closeDialogBefore) {
                            Dialog.instance?.close(() => {
                                Routing.instance.openDialog('ticket', { id: logbookItem.data['forEntityId'], sp: true })(e);
                            });
                        }
                        else {
                            Routing.instance.openDialog('ticket', { id: logbookItem.data['forEntityId'], sp: true })(e);
                        }
                    }
                    else if (!logbookItem.data.folder) {
                        this.goTo(`/projects/${logbookItem.projectId}/files`);
                    }
                    else {
                        this.goTo(`/projects/${logbookItem.projectId}/files/${logbookItem.data.folder}`);
                    }
                }
                else if (logbookItem.entityType === 'PlanVersion' && logbookItem.entityId != null) {
                    const planVersion = await planVersionModel.getById(logbookItem.entityId);
                    const { planId } = planVersion;
                    this.goTo(`/projects/${logbookItem.projectId}/plans/${planId}/versions/${logbookItem.entityId}`);
                }
                else if (logbookItem.entityType === 'ProjectUser') {
                    this.goTo(`/projects/${logbookItem.projectId}/team`);
                }
                else if (deep === 'multiproject') {
                    this.goTo(`/projects/${logbookItem.projectId}/project`);
                }
            }
            catch (err) {
                console.debug('cant open link for logbookItem', logbookItem, err);
            }
        };
        this.state = {
            withLink: props.withLink === true,
            leftImage: null,
            userModel: props.userModel == null ? AuthClient.instance.modals.user : props.userModel,
            planModel: props.planModel == null ? UpmeshClient.instance.modals.plan : props.planModel,
            planVersionModel: props.planVersionModel == null ? UpmeshClient.instance.modals.planVersion : props.planVersionModel,
            project: null,
        };
    }
    static get defaultProps() {
        return {
            padding: ThemeManager.style.getScreenRelativePixelSize(16),
            paddingBottom: 0,
            withUserImage: true,
            centered: false,
            withLink: true,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    renderTicketText() {
        const { logbookItem, centered, textColor } = this.props;
        const date = I18n.m.dateCurrent.localeDateWithTimeString(logbookItem.createdAt);
        return (<View style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', overflow: 'visible' }}>
        <View accessibilityLabel="logbookDateForTicketActivities" style={{
                flexDirection: 'row',
                alignSelf: 'stretch',
                justifyContent: 'space-between',
            }}>
          <MaterialText type={MaterialTextTypes.Caption} centeredText={centered} fixedWidth="100%" color={textColor}>
            {date}
          </MaterialText>
        </View>
        {this.renderCoreText()}
      </View>);
    }
    renderCoreText() {
        const { text } = this.state;
        const { centered, textColor } = this.props;
        return (<View accessibilityLabel="logbookActivityMessage" style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch' }}>
        <MaterialText type={MaterialTextTypes.Body2} centeredText={centered} fixedWidth="100%" color={textColor}>
          {text}
        </MaterialText>
      </View>);
    }
    renderIcons() {
        const { logbookItem, textColor, deep } = this.props;
        const { project } = this.state;
        const date = I18n.m.dateCurrent.localeDateWithTimeString(logbookItem.createdAt);
        const renderedProject = deep !== 'multiproject' ? null : (<View style={{ flex: 1, minWidth: '40%', maxWidth: '60%', flexDirection: 'row', marginRight: 5 }}>
          {project != null ? (<ProjectImage size={24} project={project}/>) : (<Icon toolTip="" icon="domain" iconSize={16} key="projectIcon"/>)}
          <View accessibilityLabel="logbookProjectTitle" style={{
                flex: 1,
                paddingLeft: 8,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'center',
            }}>
            <MaterialText key="projectTitle" type={MaterialTextTypes.Caption} centeredText={false} centeredBox color={textColor} ellipsizeMode="tail" numberOfLines={1}>
              {logbookItem.projectTitle}
            </MaterialText>
          </View>
        </View>);
        return (<View style={{
                padding: 0,
                paddingTop: 4,
                width: '100%',
                flexDirection: 'row',
                alignSelf: 'stretch',
                overflow: 'visible',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}>
        {renderedProject}
        <Icon toolTip="" icon="clock-outline" iconSize={16}/>
        <View accessibilityLabel="logbookDate">
          <MaterialText type={MaterialTextTypes.Caption} centeredText={false} centeredBox color={textColor}>
            {date}
          </MaterialText>
        </View>
      </View>);
    }
    renderContent() {
        const { leftImage, withLink, toolTip } = this.state;
        const { padding, withUserImage, hideIcon, centered, textColor, logbookItem } = this.props;
        const entityType = logbookItem.entityType != null
            ? `${I18n.m.getMessage(`logBookEntity${logbookItem.entityType}`)}: `
            : '';
        const entityTitle = logbookItem.entityTitle != null ? (<View accessibilityLabel="logbookActivityHeader" style={{
                padding: 0,
                width: '100%',
            }}>
          <MaterialText type={MaterialTextTypes.Body2} centeredText={centered} strong centeredBox={false} fixedWidth="100%" color={textColor}>
            {logbookItem.entityTitle}
          </MaterialText>
        </View>) : null;
        const iconSize = 24;
        let icon = null;
        if (!hideIcon) {
            icon = <Icon icon="domain" toolTip={entityType} iconSize={iconSize}/>;
            if (logbookItem.entityType === 'Ticket' || logbookItem.entityType === 'TicketComments') {
                icon = <Icon icon="map-marker-check-outline" toolTip={entityType} iconSize={iconSize}/>;
            }
            else if (logbookItem.entityType === 'Plan' || logbookItem.entityType === 'PlanVersion') {
                icon = <Icon icon="floor-plan" toolTip={entityType} iconSize={iconSize}/>;
            }
            else if (logbookItem.entityType === 'ProjectUser' || logbookItem.entityType === 'ProjectRole') {
                icon = <Icon icon="account-multiple-outline" toolTip={entityType} iconIconMoon={false} iconSize={iconSize}/>;
            }
            else if (logbookItem.entityType === 'StoredFile') {
                icon = <Icon icon="text-box-multiple-outline" toolTip={entityType} iconIconMoon={false} iconSize={iconSize}/>;
            }
            else if (logbookItem.entityType === 'Absence') {
                icon = <Icon icon="calendar-arrow-right" toolTip={entityType} iconIconMoon={false} iconSize={iconSize}/>;
            }
            icon = (<View style={{
                    padding: 0,
                    overflow: 'visible',
                    alignSelf: 'center',
                }}>
          {icon}
        </View>);
        }
        return (<Ripple onPress={withLink ? this.openLnk : undefined} disabled={!withLink} toolTip={toolTip} style={{
                padding,
                paddingRight: hideIcon ? 0 : padding,
                width: '100%',
                flexDirection: 'row',
                alignSelf: 'stretch',
                overflow: 'visible',
            }}>
        {withUserImage ? (<View style={{ width: 64, alignSelf: 'center', overflow: 'visible' }}>{leftImage}</View>) : null}
        <View style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', overflow: 'visible' }}>
          {entityTitle}
          {this.renderCoreText()}
          {this.renderIcons()}
        </View>
        {icon}
      </Ripple>);
    }
    render() {
        const { text } = this.state;
        const { padding, paddingBottom, deep, flat } = this.props;
        if (text == null) {
            return null;
        }
        if (deep === 'ticket') {
            return (<View style={{
                    padding,
                    paddingBottom,
                    width: '100%',
                    flexDirection: 'row',
                    alignSelf: 'stretch',
                    overflow: 'visible',
                }}>
          {this.renderTicketText()}
        </View>);
        }
        if (deep === 'project' || flat) {
            return (<View style={{
                    paddingBottom,
                    padding: 0,
                    width: '100%',
                    flexDirection: 'row',
                    alignSelf: 'stretch',
                    overflow: 'visible',
                }}>
          <View style={{
                    padding: 0,
                    width: '100%',
                    flexDirection: 'row',
                    alignSelf: 'stretch',
                    overflow: 'visible',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: ThemeManager.style.borderColor,
                    borderBottomWidth: ThemeManager.style.borderWidth,
                }}>
            {this.renderContent()}
          </View>
        </View>);
        }
        return (<View style={{
                paddingBottom: 0,
                maxWidth: ThemeManager.style.breakpointM,
                width: '100%',
            }}>
        <Card>{this.renderContent()}</Card>
      </View>);
    }
    async init() {
        let text = '';
        const { logbookItem, withUserImage, deep } = this.props;
        const { userModel } = this.state;
        text = await LogBookEntity.createText(logbookItem, I18n.m, CurrentUser.entity != null ? CurrentUser.entity.locale : 'de', UpmeshClient.instance.modals.plan, UpmeshClient.instance.modals.planVersion, UpmeshClient.instance.modals.project, CurrentUser.userId);
        try {
            let leftImage = null;
            if (withUserImage && logbookItem.creator != null) {
                if (!logbookItem.creator.startsWith('company')) {
                    if (logbookItem.creator != null && logbookItem.creator.length > 10) {
                        try {
                            this.creator = await userModel.getById(logbookItem.creator);
                        }
                        catch (e) {
                            e.description = `item: ${JSON.stringify(logbookItem)}, details: ${e.description}`;
                            console.debug('cant get user in logbookItem - createText error details', e);
                        }
                    }
                    leftImage = this.creator != null ? <UserImage user={this.creator} size={48}/> : null;
                }
                else {
                    const companies = ReadModels.get('company');
                    const companyId = logbookItem.creator.slice(7);
                    const company = await companies.getById(companyId);
                    leftImage = <CompanyLogo company={company} round={false} size={48}/>;
                }
            }
            const withLink = this.props.withLink === true;
            let project = null;
            if (deep === 'multiproject') {
                if (logbookItem.projectId != null && logbookItem.projectId.length > 0) {
                    try {
                        project = await UpmeshClient.instance.modals.project.getById(logbookItem.projectId);
                    }
                    catch (e) {
                        console.debug('cant get project', e);
                    }
                }
            }
            if (this.mounted) {
                this.setState({
                    text,
                    leftImage,
                    withLink,
                    project,
                });
            }
        }
        catch (e) {
            e.description = `item: ${JSON.stringify(logbookItem)}, details: ${e.description}`;
            console.debug('Cant create logBook Text for logbookItem - createText error details', e);
            ErrorReporter.sendReport({
                subject: `cant create logBook Text for logbookItem: ${e.name} - ${e.message}`,
                data: e,
                type: 'warn',
            });
        }
    }
    goTo(link) {
        const { closeDialogBefore } = this.props;
        if (closeDialogBefore) {
            Dialog.instance?.close(() => {
                Routing.instance.goTo(link);
            });
        }
        else {
            Routing.instance.goTo(link);
        }
    }
}

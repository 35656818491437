"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MoveFolder = void 0;
var ServerEnvirement_1 = require("cqrs-core/build/src/server/webserver/ServerEnvirement");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var FolderMoved_1 = require("../../events/folder/FolderMoved");
var FolderEntity_1 = require("../../query/entities/FolderEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeFolder_1 = require("./ChangeFolder");
var MoveFolder = function (_ClientProjectCommand) {
  function MoveFolder(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, MoveFolder);
    _this = _callSuper(this, MoveFolder, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'MoveFolder';
    return _this;
  }
  (0, _inherits2.default)(MoveFolder, _ClientProjectCommand);
  return (0, _createClass2.default)(MoveFolder, [{
    key: "getCurrentFolder",
    value: function () {
      var _getCurrentFolder = (0, _asyncToGenerator2.default)(function* () {
        if (this._currentFolder == null) {
          var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
          this._currentFolder = yield CommandReadModels_1.CommandReadModels.folder.getById(this.entityId, undefined, userId);
        }
        return this._currentFolder;
      });
      function getCurrentFolder() {
        return _getCurrentFolder.apply(this, arguments);
      }
      return getCurrentFolder;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var currentFolder = yield this.getCurrentFolder();
        yield _superPropGet(MoveFolder, "checkProjectStatusAndGeneralAccess", this, 3)([currentFolder.projectId, userId]);
        if (yield RightsManager_1.RightsManager.hasWriteRight(currentFolder.projectId, userId, 'commandEditFolders')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var currentFolder = yield this.getCurrentFolder();
        var hasChanged = currentFolder.subFolderFromId !== this.data.subFolderFromId;
        if (!hasChanged) return null;
        var e = [new FolderMoved_1.FolderMoved(this.entityId, {
          subFolderFromId: this.data.subFolderFromId
        }, currentFolder.projectId)];
        if (this.data.subFolderFromId != null) {
          var target = yield CommandReadModels_1.CommandReadModels.folder.getById(this.data.subFolderFromId);
          if (target.access != null) {
            var changeFolder = new ChangeFolder_1.ChangeFolder({
              access: (0, _toConsumableArray2.default)(target.access)
            }, this.entityId, this.token);
            yield changeFolder.execute(ServerEnvirement_1.ServerEnvirement.writeStore);
          }
        }
        return e;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var currentFolder = yield this.getCurrentFolder();
        if (key == null || key === 'subFolderFromId') {
          var maxLength = FolderEntity_1.FolderEntity.maxPathLength;
          var subFolderFromId = currentFolder.subFolderFromId;
          if (subFolderFromId != null && subFolderFromId.length > 0) {
            var getSubFolders = yield FolderEntity_1.FolderEntity.getSubFolders(currentFolder);
            if (subFolderFromId === this.entityId || getSubFolders.findIndex(function (a) {
              return a.id === subFolderFromId;
            }) > -1) {
              throw {
                message: 'Ordner kann nicht in sich selbst verschoben werden',
                messageCode: 'badRequest'
              };
            }
            var parentLength = yield FolderEntity_1.FolderEntity.getPathLength(subFolderFromId);
            if (parentLength != null && parentLength.pathLength > 0) maxLength -= parentLength.pathLength;
          }
          if (currentFolder.title.length > maxLength) {
            throw new Error('toLong');
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.MoveFolder = MoveFolder;
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { InviteUser } from 'upmesh-core/src/client/commands/user/InviteUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
export class ProjectAddTeamPU extends PureComponent {
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('projectAddTeamTitle');
        this.submit = () => {
            this.setState({
                isLoading: true,
            }, () => {
                this.submitNow().catch((err) => console.debug(err));
            });
        };
        this.onResize = () => {
            if (this.resizeTimeOut) {
                clearTimeout(this.resizeTimeOut);
            }
            this.resizeTimeOut = window.setTimeout(() => {
                this.forceUpdate();
            }, 250);
        };
        this.onChangeRole = (value) => {
            this.setState({ selectedRoleIndex: value.index });
        };
        this.onChangeGroup = (value) => {
            this.setState({ selectedGroupIndex: value.index });
        };
        this.state = {
            isLoading: false,
            projectAddTeamMemberInput: null,
            emailError: '',
            emailTextFieldActive: false,
            init: false,
            roleList: [],
            selectedRoleIndex: 0,
            groupList: [],
            usergroups: [],
            selectedGroupIndex: 0,
            eMailAutoCompleteList: [],
        };
    }
    async init() {
        await DateLocale.waitFor(() => CurrentProject.instance.getCurrentProject() != null);
        const project = CurrentProject.instance.getCurrentProject();
        const eMailAutoCompleteList = [];
        if (project != null) {
            const filter = project.projectSubscription == null || project.projectSubscription === 'basic'
                ? `(projectId eq '${project.id}' or projectId eq '0') and basicRole eq true and deleted ne true`
                : `deleted ne true and (projectId eq '${project.id}' or projectId eq '0')`;
            const projectRoles = await UpmeshClient.instance.modals.projectRoles.get({ filter });
            const roleList = [];
            for (const role of projectRoles) {
                const desc = I18n.m.getMessage(`roleDescription${role.roleName}`);
                roleList.push({
                    title: I18n.m.getMessage(role.roleName),
                    id: role.id,
                    description: desc === `roleDescription${role.roleName}` ? '' : desc,
                });
            }
            roleList.sort((a, b) => (a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1));
            const groupList = [];
            let usergroups = [];
            if (project.userGroups != null && project.userGroups.length > 0) {
                groupList.push({ title: I18n.m.getMessage('noCompany') });
                project.userGroups.forEach((group) => {
                    groupList.push({ title: group });
                });
                usergroups = project.userGroups;
            }
            const users = await AuthClient.instance.modals.user.get();
            users.forEach((u) => {
                if (u.emails != null)
                    eMailAutoCompleteList.push(...u.emails);
            });
            this.setState({
                groupList,
                usergroups,
                init: true,
                roleList,
                eMailAutoCompleteList,
            }, () => {
                setTimeout(() => {
                    try {
                        this.state.projectAddTeamMemberInput?.focus();
                    }
                    catch (e) {
                        console.debug('cant set autofocus', e);
                    }
                }, 300);
            });
        }
    }
    componentWillUnmount() {
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    renderUserGroups() {
        const { selectedGroupIndex, groupList } = this.state;
        if (groupList.length > 0) {
            return (<FormInputPicker labelText={I18n.m.getMessage('company')} onChange={this.onChangeGroup} selectedIndex={selectedGroupIndex} list={groupList}/>);
        }
        return null;
    }
    render() {
        const { init, selectedRoleIndex, roleList, isLoading, eMailAutoCompleteList } = this.state;
        if (init) {
            const roleDescription = roleList[selectedRoleIndex].description;
            return [
                <DialogContent key="content">
          <DialogTitle inContent>{this.title}</DialogTitle>
          <MaterialText type={MaterialTextTypes.Body1} color={ThemeManager.style.black54}>
            {I18n.m.getMessage('teamAddPeopleText')}
          </MaterialText>

          <FormInputFilled ref={(projectAddTeamMemberInput) => this.setState({ projectAddTeamMemberInput })} labelText={I18n.m.getMessage('teamAddPeoplePlaceholder')} name="email" autoCompleteWeb="email" disabled={this.state.isLoading} returnKeyType="send" keyboardType="email-address" onSubmitEditing={this.submit} error={this.state.emailError.length > 0} helperText={this.state.emailError} autoCompleteList={eMailAutoCompleteList} disableScrollTo/>
          
          
          <FormInputPicker labelText={I18n.m.getMessage('role')} onChange={this.onChangeRole} selectedIndex={selectedRoleIndex} list={roleList}/>
          <MaterialText type={MaterialTextTypes.Body2}>{roleDescription}</MaterialText>
        </DialogContent>,
                <DialogActions key="actions" onBottom>
          <ContainedButton disabled={isLoading} title={I18n.m.getMessage('abort')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={Routing.instance.closeDialog(true)}/>
          <ContainedButton disabled={isLoading} onPress={this.submit} title={I18n.m.getMessage('teamInvite')} backgroundColor={ThemeManager.style.brandPrimary}/>
        </DialogActions>,
            ];
        }
        return (<View>
        <DialogTitle>{this.title}</DialogTitle>
        <Spinner />
        <View style={{ height: 16 }}/>
      </View>);
    }
    async submitNow() {
        const { projectAddTeamMemberInput, selectedRoleIndex, roleList, groupList, selectedGroupIndex } = this.state;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId == null || projectAddTeamMemberInput == null) {
            return;
        }
        const eMail = projectAddTeamMemberInput.getValue();
        const userGroup = selectedGroupIndex > 0 ? groupList[selectedGroupIndex].title : undefined;
        const command = new InviteUser({
            projectId,
            userGroup,
            email: eMail,
            roleId: roleList[selectedRoleIndex].id,
        });
        try {
            await command.execute();
            Routing.instance.closeDialog(true)(null);
        }
        catch (e) {
            if (e.messageCode != null) {
                Routing.instance.alert.post({ text: I18n.m.getMessage(e.messageCode) });
            }
            else {
                ErrorReporter.sendReport({ subject: 'Add new member error', data: e, type: 'error' });
            }
            this.setState({ isLoading: false });
        }
    }
}
ProjectAddTeamPU.defaultProps = {
    data: null,
};

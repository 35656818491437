import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { AbsenceStatusChip } from '../absences/AbsenceStatusChip';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ReadmodelHistory } from '../root/ReadmodelHistory';
import { CombinedExtraPayTrackingDialogsControl } from './CombinedExtraPayTrackingDialogsControl';
import { TimeTrackingStatusChip } from './TimeTrackingStatusChip';
export function TimeTrackingDialog(props) {
    const [absence, setAbsence] = useState();
    const [extraPay, setExtraPay] = useState();
    const loadData = async () => {
        if (props.entry.type === 'TimeTrackingEntity' && props.entry.orgEntry['absenceId']) {
            const t = await UpmeshClient.instance.modals.absence.getById(props.entry.orgEntry['absenceId']);
            setAbsence(t);
        }
        if (props.entry.type === 'ExtraPayTrackingEntity') {
            const ent = props.entry.orgEntry;
            const e = await UpmeshClient.instance.modals.extraPay.getById(ent.extraPayId);
            setExtraPay(e);
        }
    };
    useEffect(() => {
        loadData().catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    }, [props.entry]);
    const renderItem = (label, value, icon, strong, center = true) => {
        return (<View style={{ width: '100%', paddingVertical: 8 }}>
        <View style={{
                width: '100%',
                padding: 4,
                backgroundColor: '#fafafa',
                borderRadius: ThemeManager.style.borderRadius,
                justifyContent: center ? 'center' : 'flex-start',
                alignItems: center ? 'center' : 'flex-start',
            }}>
          {icon != null && icon.length > 0 && <Icon icon={icon} toolTip={label}/>}
          <MaterialText centeredBox={center} type={MaterialTextTypes.Caption}>
            {label}
          </MaterialText>
          <MaterialText centeredBox={center} strong={strong}>
            {value}
          </MaterialText>
        </View>
        
      </View>);
    };
    const renderHeader = (forDay, status, extraText) => {
        const { entry } = props;
        let thirdTextLine = entry.taskName != null && entry.taskName.length > 0 ? entry.taskName : '';
        if (entry.costCenterName != null && entry.costCenterName.length > 0) {
            if (thirdTextLine.length > 0)
                thirdTextLine += ' • ';
            thirdTextLine += entry.costCenterName;
        }
        if (extraText != null)
            thirdTextLine += ` • ${extraText}`;
        return (<View style={{ width: '100%', paddingBottom: 16 }}>
        <View style={{ width: '100%', flexDirection: 'row' }}>
          <View style={{ width: 72 }}>{entry.user != null && <UserImage size={56} user={entry.user}/>}</View>
          <View style={{ flex: 1 }}>
            {entry.memberName != null && entry.memberName.length > 0 && (<MaterialText strong>{entry.memberName}</MaterialText>)}
            <MaterialText>{forDay}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{thirdTextLine}</MaterialText>
          </View>
          {status}
        </View>
      </View>);
    };
    const renderTimeTrackingEntry = () => {
        const { entry } = props;
        return (<View style={{ width: '100%' }}>
        {renderHeader(entry.starts
                ? `${I18n.m.dateCurrent.localeDateFormat(new Date(entry.starts), 'dddd')}, ${I18n.m.dateCurrent.localeDateString(new Date(entry.starts), true)}`
                : '?', entry.orgEntry.status != null ? (<TimeTrackingStatusChip timeTracking={entry.orgEntry}/>) : undefined)}

        <View style={{
                flexDirection: 'row',
                width: '100%',
                backgroundColor: '#fafafa',
                borderRadius: ThemeManager.style.borderRadius,
                flexWrap: 'wrap',
                paddingHorizontal: '3%',
            }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('starts'), entry.starts != null ? I18n.m.dateCurrent.getLocalTimeString(new Date(entry.starts)) : '', 'arrow-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('ends'), entry.ends != null ? I18n.m.dateCurrent.getLocalTimeString(new Date(entry.ends)) : '', 'arrow-collapse-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('pause'), entry.pause != null
                ? `${entry.pause.hours.toString(10).padStart(2, '0')}:${entry.pause.minutes
                    .toString(10)
                    .padStart(2, '0')}`
                : '0:00', 'coffee-outline', true)}
            </View>
          </View>
        </View>

        {entry.note != null && entry.note.length > 0 && renderItem(I18n.m.getMessage('note'), entry.note)}
        <View style={{ width: '100%', paddingTop: 16 }}>
          <ReadmodelHistory windowWidth={props.dialogState?.windowWidth} readmodel="timeTracking" id={entry.id} ignoresProperties={['projectTitle']}/>
        </View>
      </View>);
    };
    const renderExtraPayEntry = () => {
        if (extraPay == null)
            return <Spinner />;
        const ent = props.entry.orgEntry;
        let content = <View />;
        let label = '';
        if (ent.extraField != null && extraPay.fieldType === 'number') {
            label = I18n.m.getMessage('extraPayFieldTypeNumberIn', {
                unit: extraPay.field?.unit,
            });
            content = (<View style={{
                    flexDirection: 'row',
                    width: '100%',
                    backgroundColor: '#fafafa',
                    flexWrap: 'wrap',
                    paddingHorizontal: '10%',
                    borderRadius: ThemeManager.style.borderRadius,
                }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            {renderItem(I18n.m.getMessage('extraPayFieldTypeNumber'), ent.extraField?.toString(), 'google-circles-communities', true)}
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('extraPayFieldTypeNumberUnit'), extraPay.field && extraPay.field.unit ? extraPay.field.unit : '-', 'label-outline', true)}
            </View>
          </View>
        </View>);
        }
        if (ent.extraField != null && extraPay.fieldType === 'duration') {
            const f = ent.extraField;
            label = I18n.m.getMessage('absenceRange');
            const from = `${f.from.hours.toString(10).padStart(2, '0')}:${f.from.minutes.toString(10).padStart(2, '0')}`;
            const to = `${f.to.hours.toString(10).padStart(2, '0')}:${f.to.minutes.toString(10).padStart(2, '0')}`;
            const dateRange = new Date(new Date(1970, 0, 1, f.to.hours, f.to.minutes, 0, 0).getTime() -
                new Date(1970, 0, 1, f.from.hours, f.from.minutes, 0, 0).getTime());
            const h = Math.floor(dateRange.getTime() / 60 / 60 / 1000);
            const m = dateRange.getTime() / 60 / 1000 - h * 60;
            const range = `${h.toString(10).padStart(2, '0')}:${m.toString(10).padStart(2, '0')}`;
            content = (<View style={{
                    flexDirection: 'row',
                    width: '100%',
                    backgroundColor: '#fafafa',
                    borderRadius: ThemeManager.style.borderRadius,
                    flexWrap: 'wrap',
                    paddingHorizontal: '10%',
                }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('starts'), from, 'arrow-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('ends'), to, 'arrow-collapse-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('range'), range, 'calendar-range-outline', true)}
            </View>
          </View>
        </View>);
        }
        return (<View style={{ width: '100%' }}>
        {renderHeader(`${I18n.m.dateCurrent.localeDateFormat(ent.day, 'dddd')}, ${I18n.m.dateCurrent.localeDateString(ent.day, true)}`, undefined, label)}
        {content}

        {ent.notes != null && ent.notes.length > 0 && renderItem(I18n.m.getMessage('note'), ent.notes)}

        <View style={{ width: '100%', paddingTop: 16 }}>
          <ReadmodelHistory windowWidth={props.dialogState?.windowWidth} readmodel="extraPayTracking" id={ent.id} ignoresProperties={['projectTitle']}/>
        </View>
      </View>);
    };
    const renderAbsence = () => {
        const { entry } = props;
        if (absence == null)
            return <Spinner />;
        const starts = new Date(absence.starts);
        const ends = new Date(absence.ends);
        const dateRange = new Date(ends.getTime() - starts.getTime());
        const days = Math.round(dateRange.getTime() / 60 / 60 / 1000 / 24);
        const from = `${I18n.m.dateCurrent.localeDateFormat(new Date(absence.starts), 'dddd')}, ${I18n.m.dateCurrent.localeDateString(starts, true)}`;
        const to = `${I18n.m.dateCurrent.localeDateFormat(new Date(absence.ends), 'dddd')}, ${I18n.m.dateCurrent.localeDateString(ends, true)}`;
        let range = '';
        if (days > 0) {
            range = `${days.toString(10)} ${I18n.m.getMessage('days')}`;
        }
        else {
            const h = Math.floor(dateRange.getTime() / 60 / 60 / 1000);
            const m = dateRange.getTime() / 60 / 1000 - h * 60;
            range = `${h.toString(10).padStart(2, '0')}:${m.toString(10).padStart(2, '0')}`;
        }
        return (<View style={{ width: '100%' }}>
        {renderHeader(`${I18n.m.dateCurrent.localeDateFormat(entry.orgEntry.createdAt, 'dddd')}, ${I18n.m.dateCurrent.localeDateString(entry.orgEntry.createdAt, true)}`, absence.state != null ? <AbsenceStatusChip absence={absence}/> : undefined)}

        <View style={{
                flexDirection: 'row',
                width: '100%',
                backgroundColor: '#fafafa',
                borderRadius: ThemeManager.style.borderRadius,
                flexWrap: 'wrap',
                paddingHorizontal: '3%',
            }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('starts'), from, 'arrow-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('ends'), to, 'arrow-collapse-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('range'), range, 'calendar-range-outline', true)}
            </View>
          </View>
        </View>

        {absence.note != null && absence.note.length > 0 && renderItem(I18n.m.getMessage('absenceNote'), absence.note)}

        <View style={{ width: '100%', paddingTop: 16 }}>
          <ReadmodelHistory windowWidth={props.dialogState?.windowWidth} readmodel="absence" id={absence.id} ignoresProperties={['projectTitle']}/>
        </View>
      </View>);
    };
    let title = props.entry.type === 'TimeTrackingEntity' && !props.entry.orgEntry['absenceId']
        ? I18n.m.getMessage('timeTracking')
        : props.entry.type === 'TimeTrackingEntity' && props.entry.orgEntry['absenceId']
            ? I18n.m.getMessage('absence')
            : I18n.m.getMessage('extraPay');
    if (props.entry.type === 'ExtraPayTrackingEntity' && extraPay != null) {
        title += ` (${extraPay.getName()})`;
    }
    return (<>
      <DialogTitle iconRight={{ toolTip: I18n.m.getMessage('close'), icon: 'close', onPress: () => Dialog.instance?.close() }} additionalIcons={[
            {
                icon: 'pencil-outline',
                toolTip: I18n.m.getMessage('edit'),
                onPress: CombinedExtraPayTrackingDialogsControl.editEntry(props.entry),
            },
        ]}>
        {title}
      </DialogTitle>
      <DialogContent>
        {props.entry.type === 'TimeTrackingEntity' && !props.entry.orgEntry['absenceId']
            ? renderTimeTrackingEntry()
            : props.entry.type === 'TimeTrackingEntity' && props.entry.orgEntry['absenceId']
                ? renderAbsence()
                : renderExtraPayEntry()}
      </DialogContent>
    </>);
}
export const openTimeTrackingDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        content: <TimeTrackingDialog {...props}/>,
        fullscreenResponsive: true,
        width: 768,
        contentPadding: false,
        openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: true,
        showCloseIcon: false,
    });
};

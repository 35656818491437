"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeFolder = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var Config_1 = require("../../../Config");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var FolderChanged_1 = require("../../events/folder/FolderChanged");
var FolderEntity_1 = require("../../query/entities/FolderEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeFolder = function (_ClientProjectCommand) {
  function ChangeFolder(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeFolder);
    _this = _callSuper(this, ChangeFolder, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeFolder';
    return _this;
  }
  (0, _inherits2.default)(ChangeFolder, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeFolder, [{
    key: "getCurrentFolder",
    value: function () {
      var _getCurrentFolder = (0, _asyncToGenerator2.default)(function* () {
        if (this._currentFolder == null) {
          var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
          this._currentFolder = yield CommandReadModels_1.CommandReadModels.folder.getById(this.entityId, undefined, userId);
        }
        return this._currentFolder;
      });
      function getCurrentFolder() {
        return _getCurrentFolder.apply(this, arguments);
      }
      return getCurrentFolder;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var currentFolder = yield this.getCurrentFolder();
        yield _superPropGet(ChangeFolder, "checkProjectStatusAndGeneralAccess", this, 3)([currentFolder.projectId, userId]);
        if (yield RightsManager_1.RightsManager.hasWriteRight(currentFolder.projectId, userId, 'commandEditFolders')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var currentFolder = yield this.getCurrentFolder();
        var andSub = currentFolder.subFolderFromId != null ? ` and subFolderFromId eq '${currentFolder.subFolderFromId}'` : ` and subFolderFromId eq null`;
        var getFolder = yield CommandReadModels_1.CommandReadModels.folder.get({
          filter: `projectId eq '${currentFolder.projectId}' and deleted ne true and id ne '${this.entityId}' and title eq '${this.data.title}'${andSub}`
        });
        if (getFolder.length > 0) {
          throw {
            message: 'Folder with name already exists in project',
            messageCode: 'folderWithNameAlreadyExists',
            messageData: {
              title: this.data.title
            }
          };
        }
        var titleChanged = this.data.title != null && currentFolder.title !== this.data.title;
        var descriptionChanged = this.data.description != null && currentFolder.description !== this.data.description;
        var tagsChanged = this.data.tags != null && JSON.stringify(this.data.tags) !== JSON.stringify(currentFolder.tags);
        var accessAllChanged = this.data.accessAll != null && this.data.accessAll !== currentFolder.accessAll;
        if (this.data.accessAll) this.data.access = [];
        var accessChanged = this.data.access != null && JSON.stringify(this.data.access) !== JSON.stringify(currentFolder.access);
        var hasChanged = this.data.changeSubFolderRights === 'overwrite' || accessChanged || accessAllChanged || tagsChanged || descriptionChanged || titleChanged;
        if (!hasChanged) return [];
        var data = {};
        if (titleChanged) data.title = this.data.title;
        if (descriptionChanged) data.description = this.data.description;
        if (tagsChanged) data.tags = this.data.tags;
        if (accessAllChanged) data.accessAll = this.data.accessAll;
        if (accessChanged) {
          data.access = this.data.access;
          data.oldAccess = currentFolder.access ? (0, _toConsumableArray2.default)(currentFolder.access) : [];
        }
        var events = [new FolderChanged_1.FolderChanged(this.entityId, data, currentFolder.projectId)];
        if (accessChanged || this.data.changeSubFolderRights) {
          var access = this.data.access != null ? this.data.access : currentFolder.access;
          var subFolderAccess = yield this.checkSubFolderAccess(access, !!this.data.accessAll, currentFolder.id);
          if (subFolderAccess.length > 0) events.unshift.apply(events, (0, _toConsumableArray2.default)(subFolderAccess));
        }
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "checkSubFolderAccess",
    value: function () {
      var _checkSubFolderAccess = (0, _asyncToGenerator2.default)(function* (mainAccess, mainAllAccess, mainId) {
        var _this2 = this;
        var events = [];
        var hasSubFolders = yield CommandReadModels_1.CommandReadModels.folder.get({
          filter: `subFolderFromId eq '${mainId}' and deleted ne true`
        });
        if (hasSubFolders.length > 0) {
          var _loop = function* _loop() {
            var acc = sf.access == null ? [] : (0, _toConsumableArray2.default)(sf.access);
            var mustChange = !(!!sf.accessAll === mainAllAccess);
            if (_this2.data.changeSubFolderRights != null && _this2.data.changeSubFolderRights === 'overwrite') {
              mustChange = JSON.stringify(mainAccess) !== JSON.stringify(acc);
              if (mustChange) acc = (0, _toConsumableArray2.default)(mainAccess);
            } else {
              sf.access.forEach(function (a) {
                var isIn = mainAccess.find(function (as) {
                  return as.userOrRoleId === a.userOrRoleId;
                });
                if (!isIn) {
                  var i = acc.findIndex(function (as) {
                    return as.userOrRoleId === a.userOrRoleId;
                  });
                  acc.splice(i, 1);
                  mustChange = true;
                }
              });
              if (_this2.data.changeSubFolderRights != null && _this2.data.changeSubFolderRights === 'add') {
                mainAccess.forEach(function (a) {
                  var isNew = _this2._currentFolder.access.findIndex(function (as) {
                    return as.userOrRoleId === a.userOrRoleId;
                  }) === -1;
                  if (isNew) {
                    var isIn = acc.find(function (as) {
                      return as.userOrRoleId === a.userOrRoleId;
                    });
                    if (!isIn) {
                      acc.push(a);
                      mustChange = true;
                    }
                  }
                });
              }
            }
            if (mustChange) events.push(new FolderChanged_1.FolderChanged(sf.id, {
              access: acc,
              accessAll: mainAllAccess,
              oldAccess: sf.access ? (0, _toConsumableArray2.default)(sf.access) : []
            }, sf.projectId));
            var subs = yield _this2.checkSubFolderAccess(acc, mainAllAccess, sf.id);
            if (subs.length > 0) events.push.apply(events, (0, _toConsumableArray2.default)(subs));
          };
          for (var sf of hasSubFolders) {
            yield* _loop();
          }
        }
        return events;
      });
      function checkSubFolderAccess(_x, _x2, _x3) {
        return _checkSubFolderAccess.apply(this, arguments);
      }
      return checkSubFolderAccess;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var currentFolder = yield this.getCurrentFolder();
        if (key == null || key === 'title') {
          if (this.data.title != null && this.data.title.length <= 0) {
            throw new Error('toShort');
          }
          var maxLength = FolderEntity_1.FolderEntity.maxPathLength;
          var subFolderFromId = currentFolder.subFolderFromId;
          if (subFolderFromId != null && subFolderFromId.length > 0) {
            var parentLength = yield FolderEntity_1.FolderEntity.getPathLength(subFolderFromId);
            if (parentLength != null && parentLength.pathLength > 0) maxLength -= parentLength.pathLength;
          }
          if (this.data.title != null && this.data.title.length > maxLength) {
            throw new Error('toLong');
          }
        }
        if (key == null || key === 'title') {
          if (this.data.title != null && this.data.title.length > Config_1.ConfigHandler.config.string_Medium) {
            throw {
              key: 'title',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        if (key == null || key === 'description') {
          if (this.data.description != null && this.data.description.length > Config_1.ConfigHandler.config.string_Medium) {
            throw {
              key: 'description',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x4) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeFolder = ChangeFolder;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeTicketCustomField = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var Config_1 = require("../../../Config");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var TicketCustomFieldChanged_1 = require("../../events/tickets/TicketCustomFieldChanged");
var TicketCustomFieldRemoved_1 = require("../../events/tickets/TicketCustomFieldRemoved");
var CustomField_1 = require("../../query/entities/simple/CustomField");
var TicketLayoutsEntity_1 = require("../../query/entities/simple/TicketLayoutsEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeTicketCustomField = function (_ClientProjectCommand) {
  function ChangeTicketCustomField(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeTicketCustomField);
    _this = _callSuper(this, ChangeTicketCustomField, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeTicketCustomField';
    return _this;
  }
  (0, _inherits2.default)(ChangeTicketCustomField, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeTicketCustomField, [{
    key: "getTicket",
    value: function () {
      var _getTicket = (0, _asyncToGenerator2.default)(function* () {
        if (this._ticket == null) this._ticket = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        return this._ticket;
      });
      function getTicket() {
        return _getTicket.apply(this, arguments);
      }
      return getTicket;
    }()
  }, {
    key: "getProject",
    value: function () {
      var _getProject = (0, _asyncToGenerator2.default)(function* () {
        if (this._project == null) {
          var t = yield this.getTicket();
          this._project = yield CommandReadModels_1.CommandReadModels.project.getById(t.projectId);
        }
        return this._project;
      });
      function getProject() {
        return _getProject.apply(this, arguments);
      }
      return getProject;
    }()
  }, {
    key: "findFieldById",
    value: function findFieldById(fields, id) {
      for (var field of fields) {
        if (field != null && field.customField) {
          var options = field.customField.options;
          if (!options.children) {
            options.children = [];
          }
          if (options.children && options.children.length > 0) {
            for (var child of options.children) {
              if (child.fields && Array.isArray(child.fields)) {
                for (var childField of child.fields) {
                  if (childField.id === id) {
                    return childField;
                  }
                }
              }
            }
          }
        }
      }
      return undefined;
    }
  }, {
    key: "removeChildFields",
    value: function removeChildFields(rm, listAndMultiListOptions, events) {
      var _this2 = this;
      if (!listAndMultiListOptions.children) {
        listAndMultiListOptions.children = [];
      }
      if (listAndMultiListOptions.children.length > 0) {
        var _loop = function _loop(child) {
          if (child.fields && Array.isArray(child.fields)) {
            var fieldsToRemove = Array.isArray(_this2.data.value) ? !_this2.data.value.some(function (value) {
              return child.onSelectedRule.includes(value);
            }) : !child.onSelectedRule.includes(_this2.data.value);
            if (fieldsToRemove) {
              for (var childField of child.fields) {
                events.push(new TicketCustomFieldRemoved_1.TicketCustomFieldRemoved(rm.id, {
                  fieldId: childField.id,
                  name: childField.label
                }, rm.projectId));
              }
            }
          }
        };
        for (var child of listAndMultiListOptions.children) {
          _loop(child);
        }
      }
    }
  }, {
    key: "getFieldInfo",
    value: function () {
      var _getFieldInfo = (0, _asyncToGenerator2.default)(function* () {
        var _this3 = this;
        if (this._field == null) {
          var project = yield this.getProject();
          var ticket = yield this.getTicket();
          var layout = ChangeTicketCustomField.getTicketLayout(ticket, project);
          var found = layout.fields.find(function (a) {
            return a.id === _this3.data.fieldId;
          });
          if (!found) {
            found = this.findFieldById(layout.fields, this.data.fieldId);
          }
          if (!found) throw {
            message: 'Custom Field not found',
            messageCode: 'badRequest'
          };
          this._field = found;
        }
        return this._field;
      });
      function getFieldInfo() {
        return _getFieldInfo.apply(this, arguments);
      }
      return getFieldInfo;
    }()
  }, {
    key: "getCurrentField",
    value: function () {
      var _getCurrentField = (0, _asyncToGenerator2.default)(function* () {
        var _this4 = this;
        if (this._currentField == null) {
          var _ticket$fields;
          var ticket = yield this.getTicket();
          this._currentField = (_ticket$fields = ticket.fields) == null ? void 0 : _ticket$fields.find(function (a) {
            return a.id === _this4.data.fieldId;
          });
        }
        return this._currentField;
      });
      function getCurrentField() {
        return _getCurrentField.apply(this, arguments);
      }
      return getCurrentField;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var ticket = yield this.getTicket();
        yield _superPropGet(ChangeTicketCustomField, "checkProjectStatusAndGeneralAccess", this, 3)([ticket.projectId, userId]);
        var fieldInfo = yield this.getFieldInfo();
        if (fieldInfo.systemField) throw {
          message: 'Custom Field not found',
          messageCode: 'badRequest'
        };
        if (ticket.creator === userId) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'canChangeAllTickets')) {
          return true;
        }
        if (fieldInfo.assigneeCanEdit && ticket.assignedToUserId === userId) return true;
        if (fieldInfo.approverCanEdit && ticket.approverUserId === userId) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var rm = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        var hasChanged = false;
        var f = yield this.getFieldInfo();
        if (!f.customField) throw {
          message: 'Custom Field not found',
          messageCode: 'badRequest'
        };
        var c;
        var listAndMultiListOptions;
        if (f.customField.type === 'number') {
          var o = new CustomField_1.CustomFieldNumberOptions(f.customField.options);
          c = o.getDefaulValues(f, this.data.value);
          o.validate(this.data.value);
        } else if (f.customField.type === 'string') {
          var _o = new CustomField_1.CustomFieldStringOptions(f.customField.options);
          c = _o.getDefaulValues(f, this.data.value);
          _o.validate(this.data.value);
        } else if (f.customField.type === 'person') {
          var _o2 = new CustomField_1.CustomFieldPersonOptions(f.customField.options);
          c = _o2.getDefaulValues(f, this.data.value);
          c.formattedValue = yield _o2.getFormattedValue(c.value);
          _o2.validate(this.data.value);
        } else if (f.customField.type === 'multiperson') {
          var _o3 = new CustomField_1.CustomFieldMultiPesronOptions(f.customField.options);
          c = _o3.getDefaulValues(f, this.data.value);
          c.formattedValue = yield _o3.getFormattedValue(c.value);
          _o3.validate(this.data.value);
        } else if (f.customField.type === 'Date') {
          var _o4 = new CustomField_1.CustomFieldDateOptions(f.customField.options);
          c = _o4.getDefaulValues(f, this.data.value);
          _o4.validate(this.data.value);
        } else if (f.customField.type === 'DateRange') {
          var _o5 = new CustomField_1.CustomFieldDateRangeOptions(f.customField.options);
          c = _o5.getDefaulValues(f, this.data.value);
          _o5.validate(this.data.value);
        } else if (f.customField.type === 'List') {
          var _o6 = new CustomField_1.CustomFieldListOptions(f.customField.options);
          listAndMultiListOptions = f.customField.options;
          c = _o6.getDefaulValues(f, this.data.value);
          _o6.validate(this.data.value);
        } else if (f.customField.type === 'MultiselectList') {
          listAndMultiListOptions = f.customField.options;
          var _o7 = new CustomField_1.CustomFieldMultiselectListOptions(f.customField.options);
          c = _o7.getDefaulValues(f, this.data.value);
          _o7.validate(this.data.value);
        } else if (f.customField.type === 'CheckboxList') {
          var _o8 = new CustomField_1.CustomFieldCheckboxListOptions(f.customField.options);
          c = _o8.getDefaulValues(f, this.data.value);
          _o8.validate(this.data.value);
        } else if (f.customField.type === 'progress') {
          var _o9 = new CustomField_1.CustomFieldProgressOptions(f.customField.options);
          c = _o9.getDefaulValues(f, this.data.value);
          _o9.validate(this.data.value);
        } else {
          throw {
            message: `Custom Field '${f.customField.type}' not supported`,
            messageCode: 'badRequest'
          };
        }
        var current = yield this.getCurrentField();
        if (!current) hasChanged = true;else {
          hasChanged = current.value !== this.data.value;
        }
        if (!hasChanged) return [];
        var events = [];
        if (f.customField.type === 'MultiselectList' || f.customField.type === 'List') {
          this.removeChildFields(rm, listAndMultiListOptions, events);
          if (Array.isArray(this.data.value) && this.data.value.length === 0 || this.data.value === '') {
            this.removeChildFields(rm, listAndMultiListOptions, events);
          }
        }
        events.push(new TicketCustomFieldChanged_1.TicketCustomFieldChanged(this.entityId, {
          fieldId: this.data.fieldId,
          type: c.type,
          name: c.name,
          formattedValue: c.formattedValue,
          value: this.data.value
        }, rm.projectId));
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'fieldId') {
          if (this.data.fieldId.length <= 0) {
            throw {
              key: 'fieldId',
              messageCode: 'badRequest',
              message: 'FieldId darf nicht leer sein'
            };
          }
          yield this.getFieldInfo();
        }
        if (key == null || key === 'value') {
          if (this.data.value && JSON.stringify(this.data.value).length > Config_1.ConfigHandler.config.string_Huge) {
            throw {
              key: 'value',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "getTicketLayout",
    value: function getTicketLayout(ticket, project) {
      var typeString = '';
      if (typeof ticket === 'string') {
        typeString = ticket;
      } else if (ticket.type) {
        typeString = ticket.type;
      } else {
        return TicketLayoutsEntity_1.TicketLayoutsEntity.defaultTicketLayout();
      }
      var ticketLayout = TicketLayoutsEntity_1.TicketLayoutsEntity.defaultTicketLayout();
      if (typeString) {
        var _project$ticketLayout;
        (_project$ticketLayout = project.ticketLayouts) == null || _project$ticketLayout.forEach(function (l) {
          if (l.onTypes != null && l.onTypes.findIndex(function (a) {
            return a === typeString;
          }) > -1) {
            ticketLayout = l;
          }
        });
      }
      return ticketLayout;
    }
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeTicketCustomField = ChangeTicketCustomField;
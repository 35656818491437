var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChoiceChips = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _Chip = require("./Chip");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ChoiceChips = exports.ChoiceChips = function (_PureComponent) {
  function ChoiceChips(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ChoiceChips);
    _this = _callSuper(this, ChoiceChips, [props]);
    _this.onPressChip = function (index) {
      return function (_e) {
        var selected = _this.state.selected;
        var chip = _this.props.chips[index];
        _this.setState({
          selected: index === selected ? -1 : selected
        }, function () {
          _this.forceUpdate();
          if (_this.props.onPressChip != null) {
            _this.props.onPressChip(index, chip);
          }
        });
      };
    };
    _this.state = {
      chips: props.chips,
      selected: props.selected
    };
    return _this;
  }
  (0, _inherits2.default)(ChoiceChips, _PureComponent);
  return (0, _createClass2.default)(ChoiceChips, [{
    key: "render",
    value: function render() {
      var wrap = this.props.wrap;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row',
          flexWrap: wrap ? 'wrap' : undefined
        },
        children: this.renderChips()
      });
    }
  }, {
    key: "renderChips",
    value: function renderChips() {
      var _this$props = this.props,
        filterIcon = _this$props.filterIcon,
        outlined = _this$props.outlined,
        textColor = _this$props.textColor,
        textColorSelected = _this$props.textColorSelected,
        iconColor = _this$props.iconColor,
        borderProps = _this$props.borderProps,
        borderPropsChecked = _this$props.borderPropsChecked,
        hoverColor = _this$props.hoverColor,
        disableAll = _this$props.disableAll;
      var _this$state = this.state,
        chips = _this$state.chips,
        selected = _this$state.selected;
      var backgroundColor = this.props.backgroundColor != null ? this.props.backgroundColor : _ThemeManager.ThemeManager.style.chipDefaultBg;
      var backgroundColorChecked = this.props.backgroundColorChecked != null ? this.props.backgroundColorChecked : (0, _color.default)(backgroundColor).darken(0.2).toString();
      var renderedChips = [];
      if (chips != null && chips.length > 0) {
        for (var i = 0; i < chips.length; i += 1) {
          var c = chips[i];
          var bgColor = c.backgroundColor != null ? c.backgroundColor : backgroundColor;
          var bgColorChecked = c.backgroundColor != null ? (0, _color.default)(c.backgroundColor).darken(0.1).toString() : backgroundColorChecked;
          var tColor = c.textColor != null ? c.textColor : textColor;
          renderedChips.push((0, _jsxRuntime.jsx)(_Chip.Chip, {
            title: c.title,
            onPressChip: this.onPressChip(i),
            chipDisabled: c.disabled === true || disableAll,
            hoverColor: hoverColor,
            borderProps: i === selected ? borderPropsChecked : borderProps,
            removeIconColor: iconColor,
            textColor: i === selected ? textColorSelected : tColor,
            backgroundColor: i === selected ? bgColorChecked : bgColor,
            outlined: outlined,
            thumbnail: i === selected && filterIcon ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
              icon: "check",
              toolTip: ""
            }) : undefined
          }, `ChoiceChips.Chip${i}_checked?${i === selected ? 1 : 0}`));
        }
      }
      return renderedChips;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        chips: [],
        selected: -1,
        disableAll: false,
        filterIcon: false,
        wrap: true
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      return {
        chips: nextProps.chips,
        selected: nextProps.selected != null ? nextProps.selected : prevState.selected
      };
    }
  }]);
}(_react.PureComponent);
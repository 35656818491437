"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PDFOverlay = void 0;
var pdf_lib_1 = require("pdf-lib");
var TicketEntity_1 = require("../client/query/entities/TicketEntity");
var PDFOverlay = function () {
  function PDFOverlay(fileAsBuffer, planVersion) {
    (0, _classCallCheck2.default)(this, PDFOverlay);
    this.filesLoaded = false;
    this.imageToPdfScale = 2.833333;
    this.fileAsBuffer = fileAsBuffer;
    this.planVersion = planVersion;
    var scales = this.calcMaxImageMarkerScale(this.planVersion.pageWidth);
    this.MIN_MARKER_SCALE = scales.MIN_MARKER_SCALE;
    this.MAX_MARKER_SCALE = scales.MAX_MARKER_SCALE;
  }
  return (0, _createClass2.default)(PDFOverlay, [{
    key: "loadFiles",
    value: function () {
      var _loadFiles = (0, _asyncToGenerator2.default)(function* () {
        if (this.planVersion.orgFilename.endsWith('.pdf')) {
          this.basePdfDoc = yield pdf_lib_1.PDFDocument.load(this.fileAsBuffer);
        } else {
          this.basePdfDoc = yield pdf_lib_1.PDFDocument.create();
          var page = this.basePdfDoc.addPage([this.planVersion.pageWidth, this.planVersion.pageHeight]);
          var image = yield this.basePdfDoc.embedJpg(this.fileAsBuffer);
          page.drawImage(image, {
            x: 0,
            y: 0,
            height: this.planVersion.pageHeight,
            width: this.planVersion.pageWidth
          });
        }
        this.existingPages = yield this.basePdfDoc.getPages();
        this.filesLoaded = true;
      });
      function loadFiles() {
        return _loadFiles.apply(this, arguments);
      }
      return loadFiles;
    }()
  }, {
    key: "calcMaxImageMarkerScale",
    value: function calcMaxImageMarkerScale(imageWidth) {
      var result = {
        MAX_MARKER_SCALE: PDFOverlay.DEFAULT_MARKER_PERCENT * imageWidth / PDFOverlay.MARKER_DIMENSIONS.width,
        MIN_MARKER_SCALE: PDFOverlay.DEFAULT_MIN_MARKER_PERCENT * imageWidth / PDFOverlay.MARKER_DIMENSIONS.width
      };
      return result;
    }
  }, {
    key: "addTickets",
    value: function () {
      var _addTickets = (0, _asyncToGenerator2.default)(function* (tickets) {
        if (!this.filesLoaded) yield this.loadFiles();
        var firstPage = this.existingPages[0];
        if (firstPage != null) {
          var pointsArray = [];
          var font = yield this.basePdfDoc.embedFont(pdf_lib_1.StandardFonts.Helvetica);
          var rotation = firstPage.getRotation();
          if (rotation.angle !== 0) {
            firstPage.setRotation({
              angle: 0,
              type: pdf_lib_1.RotationTypes.Degrees
            });
          }
          for (var ticket of tickets) {
            if (ticket.planPositionX != null && ticket.planPositionY != null) {
              var result = void 0;
              if (this.planVersion.orgFilename.endsWith('.pdf')) {
                result = this.planVersion.getPointInPageFromRealpointInCM({
                  x: ticket.planPositionX,
                  y: ticket.planPositionY
                });
              } else {
                result = {
                  x: ticket.planPositionX * this.imageToPdfScale,
                  y: ticket.planPositionY * this.imageToPdfScale
                };
              }
              pointsArray.push(result);
            }
          }
          var calcedScale = this.getCorrectScaleForMarker(pointsArray);
          for (var _ticket of tickets) {
            if (_ticket.planPositionX != null && _ticket.planPositionY != null) {
              var markerColor = void 0;
              if (_ticket.ticketStatus === TicketEntity_1.TicketStatus.closed) markerColor = (0, pdf_lib_1.rgb)(0, 115 / 255, 255 / 255);else if (_ticket.ticketStatus === TicketEntity_1.TicketStatus.checked) markerColor = (0, pdf_lib_1.rgb)(0, 205 / 255, 158 / 255);else if (_ticket.ticketStatus === TicketEntity_1.TicketStatus.open) markerColor = (0, pdf_lib_1.rgb)(245 / 255, 124 / 255, 0);else markerColor = (0, pdf_lib_1.rgb)(255 / 255, 213 / 255, 0);
              var _result = void 0;
              if (this.planVersion.orgFilename.endsWith('.pdf')) {
                _result = this.planVersion.getPointInPageFromRealpointInCM({
                  x: _ticket.planPositionX,
                  y: _ticket.planPositionY
                });
              } else {
                _result = {
                  x: _ticket.planPositionX * this.imageToPdfScale,
                  y: _ticket.planPositionY * this.imageToPdfScale
                };
              }
              if (rotation.angle !== 0) {
                var size = firstPage.getSize();
                if (rotation.angle === 90) {
                  _result = {
                    x: size.width - _result.y,
                    y: _result.x
                  };
                  _result.x -= PDFOverlay.MARKER_DIMENSIONS.height * calcedScale;
                  _result.y -= PDFOverlay.MARKER_DIMENSIONS.width * calcedScale / 2;
                } else if (rotation.angle === 270) {
                  _result = {
                    x: _result.y,
                    y: size.height - _result.x
                  };
                  _result.x += PDFOverlay.MARKER_DIMENSIONS.height * calcedScale;
                  _result.y += PDFOverlay.MARKER_DIMENSIONS.width * calcedScale / 2;
                } else if (rotation.angle === 180) {
                  _result = {
                    x: size.width - _result.x,
                    y: size.height - _result.y
                  };
                  _result.x += PDFOverlay.MARKER_DIMENSIONS.height * calcedScale;
                  _result.y -= PDFOverlay.MARKER_DIMENSIONS.width * calcedScale / 2;
                }
              } else {
                _result.x -= PDFOverlay.MARKER_DIMENSIONS.width * calcedScale / 2;
                _result.y += PDFOverlay.MARKER_DIMENSIONS.height * calcedScale;
              }
              firstPage.moveTo(_result.x, _result.y);
              for (var path of PDFOverlay.SVG_PATHS) {
                firstPage.drawSvgPath(path, {
                  color: markerColor,
                  scale: calcedScale,
                  rotate: rotation
                });
              }
              var ticketText = `# ${_ticket.ticketNumber}`;
              var textSize = Math.floor(40 * calcedScale);
              var textWidth = font.widthOfTextAtSize(ticketText, textSize);
              if (rotation.angle === 90) {
                firstPage.moveRight(PDFOverlay.MARKER_DIMENSIONS.height * calcedScale * 0.5);
                firstPage.moveUp(PDFOverlay.MARKER_DIMENSIONS.width * calcedScale * 0.5 - textWidth * 0.5);
              } else if (rotation.angle === 270) {
                firstPage.moveLeft(PDFOverlay.MARKER_DIMENSIONS.height * calcedScale * 0.5);
                firstPage.moveDown(PDFOverlay.MARKER_DIMENSIONS.width * calcedScale * 0.5 - textWidth * 0.5);
              } else if (rotation.angle === 180) {
                firstPage.moveUp(PDFOverlay.MARKER_DIMENSIONS.height * calcedScale * 0.5);
                firstPage.moveLeft(PDFOverlay.MARKER_DIMENSIONS.width * calcedScale * 0.5 - textWidth * 0.5);
              } else {
                firstPage.moveDown(PDFOverlay.MARKER_DIMENSIONS.height * calcedScale * 0.5);
                firstPage.moveRight(PDFOverlay.MARKER_DIMENSIONS.width * calcedScale * 0.5 - textWidth * 0.5);
              }
              firstPage.drawText(ticketText, {
                size: textSize,
                rotate: rotation
              });
            }
          }
          if (rotation.angle !== 0) {
            firstPage.setRotation(rotation);
          }
        }
      });
      function addTickets(_x) {
        return _addTickets.apply(this, arguments);
      }
      return addTickets;
    }()
  }, {
    key: "doIntersect",
    value: function doIntersect(first, second) {
      return first.x + first.width > second.x && first.y + first.height > second.y && second.x + second.width > first.x && second.y + second.height > first.y;
    }
  }, {
    key: "checkAllMarker",
    value: function checkAllMarker(points) {
      var didIntersect = false;
      for (var i = 0; i < points.length - 1 && !didIntersect; i += 1) {
        var current = points[i];
        for (var j = i + 1; j < points.length && !didIntersect; j += 1) {
          var compare = points[j];
          if (this.doIntersect(current, compare)) {
            didIntersect = true;
          }
        }
      }
      return didIntersect;
    }
  }, {
    key: "getCorrectScaleForMarker",
    value: function getCorrectScaleForMarker(points) {
      var _this = this;
      var workingArray = [];
      points.forEach(function (point) {
        workingArray.push({
          x: point.x,
          y: point.y,
          width: PDFOverlay.MARKER_DIMENSIONS.width * _this.MAX_MARKER_SCALE,
          height: PDFOverlay.MARKER_DIMENSIONS.height * _this.MAX_MARKER_SCALE
        });
      });
      var lowerScale = this.MIN_MARKER_SCALE;
      var upperScale = this.MAX_MARKER_SCALE;
      var currentScale = this.MAX_MARKER_SCALE;
      var result = this.checkAllMarker(workingArray);
      var counter = 0;
      var maxLoopCounter = 10;
      while (currentScale > this.MIN_MARKER_SCALE && counter < maxLoopCounter) {
        if (result) {
          var nextScale = (currentScale + lowerScale) / 2;
          upperScale = currentScale;
          currentScale = nextScale;
        } else {
          var _nextScale = (currentScale + upperScale) / 2;
          lowerScale = currentScale;
          currentScale = _nextScale;
        }
        for (var marker of workingArray) {
          marker.width = PDFOverlay.MARKER_DIMENSIONS.width * currentScale;
          marker.height = PDFOverlay.MARKER_DIMENSIONS.width * currentScale;
        }
        result = this.checkAllMarker(workingArray);
        counter += 1;
      }
      if (result) {
        return currentScale;
      }
      return lowerScale;
    }
  }, {
    key: "finalize",
    value: function () {
      var _finalize = (0, _asyncToGenerator2.default)(function* () {
        if (!this.filesLoaded) yield this.loadFiles();
        return yield this.basePdfDoc.save();
      });
      function finalize() {
        return _finalize.apply(this, arguments);
      }
      return finalize;
    }()
  }, {
    key: "finalizeToBase64",
    value: function () {
      var _finalizeToBase = (0, _asyncToGenerator2.default)(function* () {
        if (!this.filesLoaded) yield this.loadFiles();
        return yield this.basePdfDoc.saveAsBase64();
      });
      function finalizeToBase64() {
        return _finalizeToBase.apply(this, arguments);
      }
      return finalizeToBase64;
    }()
  }]);
}();
exports.PDFOverlay = PDFOverlay;
PDFOverlay.SVG_PATHS = ['M 0 37.5 A 37.5 37.5 0 0 1 75 37.5 A 37.5 37.5 0 0 1 0 37.5 Z', 'M 37.5 0 H 112.5 V 75 H 37.5 Z', 'M 75 37.5 A 37.5 37.5 0 0 1 150 37.5 A 37.5 37.5 0 0 1 75 37.5 Z', 'M 60 75 L 90 75 L 75 100 Z'];
PDFOverlay.MARKER_DIMENSIONS = {
  width: 150,
  height: 100
};
PDFOverlay.DEFAULT_MARKER_PERCENT = 0.08;
PDFOverlay.DEFAULT_MIN_MARKER_PERCENT = 0.02;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Menu = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _MenuAnimation = require("./animations/MenuAnimation");
var _CheckboxListItem = require("./CheckboxListItem");
var _FormInputOutlined = require("./forminput/FormInputOutlined");
var _Icon = require("./Icon");
var _ListItem = require("./ListItem");
var _Overlay = require("./Overlay");
var _KeyboardEvents = require("./utils/KeyboardEvents");
var _Measurement = require("./utils/Measurement");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MENU_OPENING = 'opening';
var MENU_OPENED = 'opened';
var MENU_CLOSING = 'closing';
var MENU_CLOSED = 'closed';
var Menu = exports.Menu = function (_PureComponent) {
  function Menu(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Menu);
    _this = _callSuper(this, Menu, [props]);
    _this.menuAnimation = new _MenuAnimation.MenuAnimation();
    _this.menuHeight = 24;
    _this.checkboxOnPress = function (i) {
      return function (_e) {
        var items = _this.state.items;
        var elements = items;
        var numberOfCheckedElements = _this.state.numberOfCheckedElements;
        if (numberOfCheckedElements != null) {
          if (elements[i.index] != null) {
            if (elements[i.index].checked === true) {
              if (elements[i.index].checked === true) {
                numberOfCheckedElements -= 1;
              } else {
                numberOfCheckedElements += 1;
              }
              elements[i.index].checked = !(elements[i.index].checked === true);
            } else {
              elements[i.index].checked = true;
              numberOfCheckedElements += 1;
            }
          }
        }
        _this.setState({
          numberOfCheckedElements: numberOfCheckedElements,
          items: elements
        }, function () {
          return _this.forceUpdate();
        });
        if (_this.state.onPressCheckbox != null) {
          _this.state.onPressCheckbox(i, elements[i]['checked'] !== true);
        }
      };
    };
    _this.close = function () {
      if (Menu.instance != null) {
        _this.closeNow();
      }
    };
    _this.closeNow = function () {
      _this.stopKeyboardEvents();
      var onClosed = _this.props.onClosed;
      var onClose = _this.state.onClose;
      if (_this.menuState !== MENU_CLOSED && _this.menuState !== MENU_CLOSING) {
        _this.setmenuState(0, onClosed);
        if (onClose != null) {
          onClose();
        }
      }
    };
    _this.onPressSelectAll = function () {
      var _this$state = _this.state,
        items = _this$state.items,
        numberOfCheckedElements = _this$state.numberOfCheckedElements,
        checkboxSelectAll = _this$state.checkboxSelectAll;
      if (checkboxSelectAll && numberOfCheckedElements != null) {
        if (numberOfCheckedElements < items.length) {
          for (var i = 0; i < items.length; i += 1) {
            items[i].checked = true;
          }
          _this.setState({
            items: items,
            numberOfCheckedElements: items.length
          }, function () {});
        } else {
          for (var _i = 0; _i < items.length; _i += 1) {
            items[_i].checked = false;
          }
          _this.setState({
            items: items,
            numberOfCheckedElements: 0
          });
        }
      }
    };
    _this.onSubmit = function (selectedElement) {
      if (selectedElement > 0 && selectedElement < _this.state.items.length - 1) {
        var selected = _this.state.items[selectedElement];
        if (selected.onPress != null) {
          if (selected.onPressData) {
            selected.onPress(selected.onPressData, selectedElement);
          } else {
            selected.onPress(undefined, selectedElement);
          }
        }
        _this.closeNow();
      }
    };
    _this.setScrollView = function (r) {
      _this.scrollView = r;
    };
    _this.setOuterView = function (r) {
      _this.outerView = r;
    };
    _this.startKeyboardEvents = function () {
      _this.stopKeyboardEvents();
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        var _Menu$instance;
        var items = _this.state.items;
        var selectedElement = _this.state.selectedElement;
        if (selectedElement == null) {
          selectedElement = -1;
        }
        switch (e.keyCode) {
          case 13:
            e.preventDefault();
            _this.onSubmit(selectedElement);
            break;
          case 27:
            e.preventDefault();
            (_Menu$instance = Menu.instance) == null || _Menu$instance.close();
            break;
          case 38:
            e.preventDefault();
            if (selectedElement != null && selectedElement > 0) {
              selectedElement -= 1;
            } else {
              selectedElement = items.length - 1;
            }
            break;
          case 40:
            e.preventDefault();
            if (selectedElement != null && selectedElement < items.length - 1) {
              selectedElement += 1;
            } else {
              selectedElement = 0;
            }
            break;
        }
        _this.setState({
          selectedElement: selectedElement
        });
        if (selectedElement != null && _this.scrollView != null) {
          _this.scrollView.scrollTo({
            y: selectedElement,
            animated: false
          });
        }
      }, 'Menu');
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('Menu');
    };
    _this.onOverlayPress = function () {
      _this.closeNow();
    };
    _this.state = Menu.defaultState;
    return _this;
  }
  (0, _inherits2.default)(Menu, _PureComponent);
  return (0, _createClass2.default)(Menu, [{
    key: "menuState",
    get: function get() {
      return this.state.menuState;
    }
  }, {
    key: "pointerEvents",
    get: function get() {
      if (this.state.overlayPointerEvents != null) {
        return this.state.overlayPointerEvents;
      }
      if (this.props.overlayPointerEvents != null) {
        return this.props.overlayPointerEvents;
      }
      return this.state.menuState === MENU_OPENED ? 'auto' : 'box-none';
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      Menu._instance = this;
      var open = this.props.open;
      if (open === true) this.openNow();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState, _snapshot) {
      if (prevProps.open !== this.props.open) {
        if (this.props.open === true) {
          this.openNow();
        } else {
          this.closeNow();
        }
      }
    }
  }, {
    key: "open",
    value: function open(state) {
      var _this2 = this;
      var newState = Object.assign(Menu.defaultState, state);
      var x = 0;
      for (var i = 0; i < newState.items.length; i += 1) {
        if (newState.items[i].checked === true) {
          x += 1;
        }
      }
      newState.numberOfCheckedElements = x;
      this.setState(newState, function () {
        _this2.openNow();
      });
    }
  }, {
    key: "openNow",
    value: function openNow() {
      var _this3 = this;
      this.startKeyboardEvents();
      var onOpened = this.props.onOpened;
      var onOpen = this.state.onOpen;
      if (onOpen != null) {
        onOpen();
      }
      var height = this.menuHeight;
      this.setmenuState(height, onOpened);
      requestAnimationFrame(function () {
        _this3.setMenuPosition().catch(function (err) {
          return void 0;
        });
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state2 = this.state,
        menuState = _this$state2.menuState,
        showOverlay = _this$state2.showOverlay,
        closeOnTouchOutside = _this$state2.closeOnTouchOutside,
        checkboxSelectAll = _this$state2.checkboxSelectAll,
        menuPosition = _this$state2.menuPosition,
        menuType = _this$state2.menuType;
      if (menuState === MENU_CLOSED) {
        return null;
      }
      var animationDuration = this.props.animationDuration;
      var _this$props$size = this.props.size,
        windowWidth = _this$props$size.windowWidth,
        windowHeight = _this$props$size.windowHeight;
      this.menuHeight = menuType === 'main' ? 56 : 16;
      if (checkboxSelectAll === true) {
        this.menuHeight += _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48);
      }
      var renderedMenuElements = this.renderMenuElements();
      var hidden = menuState === MENU_CLOSED ? _ThemeManager.ThemeManager.style.dialogHidden : {};
      var overlayPointerEvents = this.pointerEvents;
      var dimensions = {
        width: windowWidth,
        height: windowHeight
      };
      var elevation = {
        zIndex: this.state.elevation,
        elevation: _reactNativeWeb.Platform.OS === 'android' ? this.state.elevation : undefined
      };
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: [_ThemeManager.ThemeManager.style.dialogContainer, hidden, dimensions, elevation],
        pointerEvents: overlayPointerEvents,
        children: [(0, _jsxRuntime.jsx)(_Overlay.Overlay, {
          width: windowWidth,
          height: windowHeight,
          pointerEvents: overlayPointerEvents,
          showOverlay: showOverlay,
          onPress: closeOnTouchOutside != null ? this.onOverlayPress : undefined,
          backgroundColor: "transparent",
          animationDuration: animationDuration,
          elevation: this.state.elevation != null ? this.state.elevation - 1 : 0
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          collapsable: false,
          ref: this.setOuterView,
          style: Object.assign({
            position: 'absolute'
          }, menuPosition, {
            minWidth: 112,
            overflow: 'visible'
          }, _ThemeManager.ThemeManager.style.elevation8, {
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            opacity: menuPosition.left === 0 ? 0.0001 : 1
          }, elevation),
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
            style: _reactNativeWeb.StyleSheet.flatten([{
              width: menuPosition.width,
              height: menuPosition.height,
              paddingTop: 8,
              paddingBottom: 8
            }, this.menuAnimation != null ? this.menuAnimation.animations : undefined]),
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
              ref: this.setScrollView,
              scrollEnabled: typeof menuPosition.height !== 'string' && this.menuHeight > menuPosition.height,
              keyboardDismissMode: "none",
              keyboardShouldPersistTaps: "always",
              children: renderedMenuElements
            })
          })
        })]
      });
    }
  }, {
    key: "renderCheckboxSelectAll",
    value: function renderCheckboxSelectAll() {
      var _this$state3 = this.state,
        checkboxSelectAll = _this$state3.checkboxSelectAll,
        checkboxSelectAllTitle = _this$state3.checkboxSelectAllTitle,
        numberOfCheckedElements = _this$state3.numberOfCheckedElements,
        items = _this$state3.items;
      if (checkboxSelectAll !== true) {
        return null;
      }
      var color = _ThemeManager.ThemeManager.style.black42;
      var selectAllIcon = 'checkbox-blank-circle-outline';
      if (numberOfCheckedElements != null && numberOfCheckedElements === items.length) {
        selectAllIcon = 'plus-circle';
        color = _ThemeManager.ThemeManager.style.brandSuccess;
      } else if (numberOfCheckedElements != null && numberOfCheckedElements > 0) {
        selectAllIcon = 'plus-circle';
      }
      var thumbnail = {
        thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: selectAllIcon,
          toolTip: "",
          color: color
        }),
        width: 24
      };
      this.menuHeight += _ListItem.ListItem.getHeight({
        thumbnail: thumbnail,
        onPress: this.onPressSelectAll,
        title: 'checkboxSelectAllTitle'
      });
      return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
        title: checkboxSelectAllTitle,
        thumbnail: {
          thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: selectAllIcon,
            toolTip: "",
            color: color
          }),
          width: 24
        },
        onPress: this.onPressSelectAll,
        backgroundColor: "rgba(0,0,0,0.12)"
      }, "selectAll");
    }
  }, {
    key: "renderFormInput",
    value: function renderFormInput() {
      var _this$state4 = this.state,
        formInput = _this$state4.formInput,
        placeholder = _this$state4.placeholder,
        maxLetters = _this$state4.maxLetters,
        keyboardType = _this$state4.keyboardType,
        onSubmitEditing = _this$state4.onSubmitEditing,
        onFocus = _this$state4.onFocus,
        onChange = _this$state4.onChange,
        onBlur = _this$state4.onBlur,
        errorFormInput = _this$state4.errorFormInput,
        disabledFormInput = _this$state4.disabledFormInput,
        helperText = _this$state4.helperText,
        formInputValue = _this$state4.formInputValue;
      if (formInput !== true) {
        return null;
      }
      var fiHeight = _reactNativeWeb.Platform.OS.toString() === 'android' ? 65 : 77;
      this.menuHeight += _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(65 + 14);
      if (helperText != null || maxLetters != null) {
        this.menuHeight += 10;
        fiHeight += 10;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8),
          marginRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8),
          height: fiHeight,
          overflow: 'visible'
        },
        children: (0, _jsxRuntime.jsx)(_FormInputOutlined.FormInputOutlined, {
          disableScrollTo: true,
          labelText: placeholder,
          maxLength: maxLetters,
          keyboardType: keyboardType,
          onChange: onChange,
          onFocus: onFocus,
          onBlur: onBlur,
          onSubmitEditing: onSubmitEditing,
          numberOfLines: 1,
          helperText: helperText,
          disabled: disabledFormInput,
          error: errorFormInput,
          value: formInputValue,
          clearButton: true
        })
      }, "FI");
    }
  }, {
    key: "setmenuState",
    value: function setmenuState(toValue) {
      var _this4 = this;
      var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      var menuState = toValue > 0 ? MENU_OPENING : MENU_CLOSING;
      var toValue2 = toValue > 0 ? 1 : 0;
      if (this.menuAnimation != null) {
        this.setState({
          menuState: menuState
        }, function () {
          _this4.menuAnimation.toValue(toValue2, function () {
            menuState = menuState === MENU_CLOSING ? MENU_CLOSED : MENU_OPENED;
            _this4.setState({
              menuState: menuState
            });
            callback();
          });
        });
      } else {
        menuState = menuState === MENU_CLOSING ? MENU_CLOSED : MENU_OPENED;
        this.setState({
          menuState: menuState
        });
        callback();
      }
    }
  }, {
    key: "getNewHeight",
    value: function getNewHeight(client) {
      var newHeight = this.menuHeight;
      var windowHeight = _ResizeEvent.ResizeEvent.current.windowHeight;
      var top = client.y + client.height;
      if (top + newHeight > windowHeight - 16) {
        top = windowHeight - newHeight - 16;
      }
      if (top < 16 + _ThemeManager.ThemeManager.style.statusBarTopPadding) {
        top = 16 + _ThemeManager.ThemeManager.style.statusBarTopPadding;
        if (newHeight > windowHeight - 32 - _ThemeManager.ThemeManager.style.statusBarTopPadding) {
          newHeight = windowHeight - 32 - _ThemeManager.ThemeManager.style.statusBarTopPadding;
        }
      }
      return {
        top: top,
        height: newHeight
      };
    }
  }, {
    key: "getNewWidth",
    value: function getNewWidth(client) {
      var windowWidth = _ResizeEvent.ResizeEvent.current.windowWidth;
      var newWidth = client.width;
      var left = client.x;
      newWidth = Math.max(112, newWidth);
      left = client.x + newWidth > windowWidth - 32 ? windowWidth - newWidth - 16 : client.x;
      if (left < 16) {
        left = 16;
        if (newWidth > windowWidth - 32) {
          newWidth = windowWidth - 32;
        }
      }
      return {
        left: left,
        width: newWidth
      };
    }
  }, {
    key: "renderMenuElements",
    value: function renderMenuElements() {
      var _this5 = this;
      var _this$state5 = this.state,
        items = _this$state5.items,
        checkbox = _this$state5.checkbox,
        selectedElement = _this$state5.selectedElement;
      var textColor = this.state.textColor != null && this.state.textColor.length > 0 ? this.state.textColor : _ThemeManager.ThemeManager.style.primaryTextColor;
      var disabledTextColor = this.state.disabledTextColor != null && this.state.disabledTextColor.length > 0 ? this.state.disabledTextColor : _ThemeManager.ThemeManager.style.secondaryTextColor;
      var selectedTextColor = this.state.selectedTextColor != null && this.state.selectedTextColor.length > 0 ? this.state.selectedTextColor : _ThemeManager.ThemeManager.style.primaryTextColor;
      var renderedMenuElements = [];
      renderedMenuElements.push(this.renderCheckboxSelectAll());
      renderedMenuElements.push(this.renderFormInput());
      var _loop = function _loop(i) {
        var element = items[i];
        var onElementPress = function onElementPress(_data) {
          return function (e) {
            return element.onPress(e, i);
          };
        };
        _this5.menuHeight += _ListItem.ListItem.getHeight(Object.assign({}, items[i], {
          title: items[i].text,
          onPress: undefined
        }));
        if (element.subtitle != null) {
          _this5.menuHeight += 24;
        }
        if (checkbox === true) {
          renderedMenuElements.push((0, _jsxRuntime.jsx)(_CheckboxListItem.CheckboxListItem, {
            title: element.text,
            onChange: _this5.checkboxOnPress(i),
            onPressText: element.disabled !== true ? onElementPress(element.onPressData) : undefined,
            value: element.checked,
            roundedCheckbox: true,
            listItemProps: {
              secondTextLine: element.subtitle,
              textColor: element.disabled === true ? disabledTextColor : element.selected != null ? selectedTextColor : textColor
            }
          }, `cbItem_${i}:${element.checked}`));
        } else {
          renderedMenuElements.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
            title: element.text,
            secondTextLine: element.subtitle,
            onPress: element.disabled !== true ? onElementPress(element.onPressData) : undefined,
            textColor: element.disabled === true ? disabledTextColor : element.selected != null ? selectedTextColor : textColor,
            selected: selectedElement != null ? selectedElement === i : false,
            thumbnail: element.thumbnail
          }, `lbItem${i}`));
        }
        if (element.cutLine === true) {
          _this5.menuHeight += 17;
          renderedMenuElements.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              marginTop: 8,
              marginBottom: 8,
              marginRight: _reactNativeWeb.Platform.OS.toString() === 'web' ? 8 : 0,
              borderColor: _ThemeManager.ThemeManager.style.borderColor,
              borderBottomWidth: 1
            }
          }, i + 0.5));
        }
      };
      for (var i = 0; i < items.length; i += 1) {
        _loop(i);
      }
      return renderedMenuElements;
    }
  }, {
    key: "setMenuPosition",
    value: function () {
      var _setMenuPosition = (0, _asyncToGenerator2.default)(function* () {
        if (this.outerView != null) {
          var size = yield _Measurement.Measurement.measure(this.outerView);
          var client = this.state.client;
          if (client == null) {
            client = {
              x: 0,
              y: 0,
              height: 24,
              width: 112
            };
          }
          var newHeight = this.getNewHeight({
            y: client.y,
            height: client.height
          });
          var newWidth = this.getNewWidth({
            x: client.x,
            width: Math.max(client.width, size.width)
          });
          this.setState({
            menuPosition: Object.assign({}, newWidth, newHeight)
          });
        }
      });
      function setMenuPosition() {
        return _setMenuPosition.apply(this, arguments);
      }
      return setMenuPosition;
    }()
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        animationDuration: 150,
        haveOverlay: true,
        onOpened: function onOpened() {},
        onClosed: function onClosed() {},
        open: false,
        size: _ResizeEvent.ResizeEvent.current
      };
    }
  }, {
    key: "defaultState",
    get: function get() {
      return {
        items: [{
          text: 'Hallo',
          onPress: function onPress() {
            return void 0;
          },
          cutLine: true
        }],
        closeFunction: function closeFunction() {
          var _Menu$instance2;
          return (_Menu$instance2 = Menu.instance) == null ? void 0 : _Menu$instance2.close;
        },
        menuState: MENU_CLOSED,
        showOverlay: true,
        formInput: false,
        closeOnTouchOutside: true,
        elevation: 8,
        checkbox: false,
        checkboxSelectAllTitle: 'Alles auswählen',
        checkboxSelectAll: false,
        numberOfCheckedElements: 0,
        selectedElement: -1,
        menuPosition: {
          left: 0,
          top: 0,
          width: 280,
          height: 'auto'
        },
        client: {
          x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
          y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
          width: 0,
          height: 0
        },
        disabledFormInput: undefined,
        disabledTextColor: undefined,
        errorFormInput: undefined,
        formInputValue: undefined,
        helperText: undefined,
        keyboardType: undefined,
        maxLetters: undefined,
        onBlur: undefined,
        onChange: undefined,
        onClose: undefined,
        onFocus: undefined,
        onOpen: undefined,
        onPressCheckbox: undefined,
        onSubmitEditing: undefined,
        selectedTextColor: undefined,
        placeholder: undefined,
        textColor: undefined,
        menuType: undefined
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (Menu._instance != null) {
        return Menu._instance;
      }
      return null;
    }
  }]);
}(_react.PureComponent);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateBookmark = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var uuid = require("uuid");
var BookmarkCreated_1 = require("../../events/storedfiles/BookmarkCreated");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var CreateBookmark = function (_ClientProjectCommand) {
  function CreateBookmark(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateBookmark);
    _this = _callSuper(this, CreateBookmark, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'CreateBookmark';
    return _this;
  }
  (0, _inherits2.default)(CreateBookmark, _ClientProjectCommand);
  return (0, _createClass2.default)(CreateBookmark, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        this.userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var storedFiles = ReadModels_1.ReadModels.get('StoredFile');
        var associatedFile = yield storedFiles.getById(this.data.forEntityId);
        var project = ReadModels_1.ReadModels.get('Project');
        this.project = yield project.getById(associatedFile.projectId);
        var bookmarkRM = ReadModels_1.ReadModels.get('Bookmark');
        var foundBookmarks = yield bookmarkRM.get({
          filter: `forEntityId eq '${this.data.forEntityId}' and userId eq '${this.userId}' and deleted ne true`
        });
        if (foundBookmarks.length > 0) {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return [new BookmarkCreated_1.BookmarkCreated(this.entityId, {
          forEntityId: this.data.forEntityId,
          bookmarks: (0, _toConsumableArray2.default)(this.data.bookmarks),
          userId: this.userId,
          projectId: this.project.id
        })];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'forEntityId') {
          if (this.data.forEntityId == null || this.data.forEntityId.length === 0) {
            throw {
              message: 'EntityId wird benötigt',
              messageCode: 'required',
              key: 'name'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CreateBookmark = CreateBookmark;
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { UpdateProjectMessage, } from 'upmesh-core/src/client/commands/project/UpdateProjectMessage';
import { I18n } from '../../../i18n/I18n';
export class UpdateProjectMessageDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    render() {
        const { project, id, value, original } = this.props;
        const currentData = { key: id, value };
        const dataOptions = new Map();
        dataOptions.set('value', {
            formType: 'string',
            cols: 1,
            props: { labelText: '' },
        });
        return (<Form key="changeCompanyUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('ticketStateChangeTitle', { originalState: original }),
            }} disableSaveOnNothingChanged stickyActions={false} command={new UpdateProjectMessage(currentData, project.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[
                <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
export const STATE_CHIPS = [
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Baden-Württemberg',
        id: 'BW',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Bayern',
        id: 'BY',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Berlin',
        id: 'BE',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Brandenburg',
        id: 'BB',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Bremen',
        id: 'HB',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Hamburg',
        id: 'HH',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Hessen',
        id: 'HE',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Mecklenburg-Vorpommern',
        id: 'MV',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Niedersachsen',
        id: 'NI',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Nordrhein-Westfalen',
        id: 'NW',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Rheinland Pfalz',
        id: 'RP',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Saarland',
        id: 'SL',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Sachsen',
        id: 'SN',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Sachsen-Anhalt',
        id: 'ST',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Schleswig Holstein',
        id: 'SH',
    },
    {
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#ffffff',
        title: 'Thüringen',
        id: 'TH',
    },
];

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeUserData = void 0;
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var UserNamesChanged_1 = require("../../events/user/UserNamesChanged");
var UserPhoneChanged_1 = require("../../events/user/UserPhoneChanged");
var UserSetCompany_1 = require("../../events/user/UserSetCompany");
var ClientUserCommands_1 = require("./root/ClientUserCommands");
var ChangeUserData = function (_ClientUserCommands_) {
  function ChangeUserData() {
    var _this;
    (0, _classCallCheck2.default)(this, ChangeUserData);
    _this = _callSuper(this, ChangeUserData, arguments);
    _this.commandName = 'ChangeUserData';
    _this.aggregate = 'user';
    return _this;
  }
  (0, _inherits2.default)(ChangeUserData, _ClientUserCommands_);
  return (0, _createClass2.default)(ChangeUserData, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        if (this.data.userId == null) {
          errors.push({
            key: 'userId',
            messageCode: 'registerErrorRequireUserId',
            message: 'User Id wird benötigt'
          });
          throw errors;
        }
        if (this.entityId !== this.data.userId) {
          errors.push({
            key: 'userId',
            messageCode: 'registerErrorRequireUserId',
            message: 'User Id wird benötigt'
          });
          throw errors;
        }
        this.user = yield CommandReadModels_1.CommandReadModels.user.getById(this.data.userId);
        if (key == null || key === 'firstname') {
          if (this.data.firstname.length <= 0) {
            errors.push({
              key: 'firstname',
              messageCode: 'registerErrorRequireFirstName',
              message: 'Vorname wird benötigt'
            });
          }
        }
        if (key == null || key === 'lastname') {
          if (this.data.lastname.length <= 0) {
            errors.push({
              key: 'lastname',
              messageCode: 'registerErrorRequireLastName',
              message: 'Nachname wird benötigt'
            });
          }
        }
        if (key == null || key === 'phone') {
          if (this.data.phone.length >= 40) {
            errors.push({
              key: 'phone',
              messageCode: 'stringToLong',
              message: 'Die Telefonnummer ist zu lang'
            });
          }
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var events = [];
        var userId = this.user.id;
        if (this.user.phone !== this.data.phone) {
          events.push(new UserPhoneChanged_1.UserPhoneChanged(userId, {
            phone: this.data.phone,
            userId: userId
          }));
        }
        if (this.user.firstname !== this.data.firstname || this.user.lastname !== this.data.lastname) {
          events.push(new UserNamesChanged_1.UserNamesChanged(userId, {
            firstname: this.data.firstname,
            lastname: this.data.lastname,
            userId: userId
          }));
        }
        if (this.data.company != null && this.user.company !== this.data.company || this.data.functionInCompany != null && this.user.functionInCompany !== this.data.functionInCompany) {
          events.push(new UserSetCompany_1.UserSetCompany(userId, {
            company: this.data.company != null ? this.data.company : '',
            functionInCompany: this.data.functionInCompany != null ? this.data.functionInCompany : '',
            userId: userId
          }));
        }
        return events.length === 0 ? null : events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientUserCommands_1.ClientUserCommands);
exports.ChangeUserData = ChangeUserData;
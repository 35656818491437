import { TextButton } from 'materialTheme/src/theme/components/button/TextButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeRegion } from 'upmesh-core/src/client/commands/companies/regions/ChangeRegion';
import { I18n } from '../../i18n/I18n';
export class ChangeRegionCompanyDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    render() {
        const { region } = this.props;
        const currentData = { ...region };
        const dataOptions = new Map();
        dataOptions.set('region', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('companyExcelRegionNameHeader') },
        });
        dataOptions.set('country', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('companyExcelRegionCountryHeader') },
        });
        return (<Form key="changeCompanyUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyChangeRegionDialogTitle'),
            }} stickyActions stickyHeader command={new ChangeRegion(currentData, region.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
    }
}

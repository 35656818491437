import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { MoveFolder } from 'upmesh-core/src/client/commands/folder/MoveFolder';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FolderSelector } from '../selectors/FolderSelector';
class FolderEntityWithFlatTitle extends FolderEntity {
}
export class MoveFolderDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.closeAlert = (_e) => {
            Alert.instance?.close();
        };
        this.moveTo = (folderId) => (_e) => {
            const { folder, folders } = this.props;
            const target = folders.find((e) => e.id === folderId);
            if (target == null)
                return;
            Routing.instance.alert.post({
                text: I18n.m.getMessage('moveFolderToQuestion', { folderName: folder.title, targetFolderName: target.title }),
                buttons: [
                    <ContainedButton key="no" onPress={this.closeAlert} title={I18n.m.getMessage('no')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="yes" onPress={this.moveToNow(target)} title={I18n.m.getMessage('yes')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                ],
            });
        };
        this.moveToNow = (target) => (e) => {
            this.closeAlert(e);
            LoadingEvents.instance.startLoading();
            const { folder } = this.props;
            const mv = new MoveFolder({ subFolderFromId: target.id && target.id.length > 0 ? target.id : null }, folder.id);
            mv.execute()
                .then(() => {
                LoadingEvents.instance.stopLoading();
                Dialog.instance?.close(e);
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
                LoadingEvents.instance.stopLoading();
            });
        };
        this.state = {
            targetFolder: [],
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.getPossibleTargets()
            .then((folder) => {
            if (this.mounted) {
                this.setState({ targetFolder: [...folder], noTargetsFound: folder.length === 0 });
            }
        })
            .catch((err) => {
            this.setState({ noTargetsFound: true });
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    }
    getProjectFolderFlatTitle(folder, folders) {
        const f = folder;
        let t = folder.title;
        if (folder.subFolderFromId != null) {
            const getMain = folders.find((e) => e.id === folder.subFolderFromId);
            if (getMain != null)
                t = `${this.getProjectFolderFlatTitle(getMain, folders).flatTitle} › ${t}`;
        }
        f.flatTitle = t;
        return f;
    }
    async getPossibleTargets() {
        const { folder } = this.props;
        const possibleFolders = [];
        const f = await UpmeshClient.instance.modals.folder.get({
            filter: `projectId eq '${folder.projectId}' and deleted ne true`,
            orderby: 'title ASC',
        });
        const getSubFolders = await FolderEntity.getSubFolders(folder);
        for (const c of f) {
            if (c.id !== folder.id && c.id !== folder.subFolderFromId && getSubFolders.findIndex((a) => a.id === c.id) === -1)
                possibleFolders.push(await this.getProjectFolderFlatTitle(c, f));
        }
        if (folder.subFolderFromId != null) {
            possibleFolders.unshift(new FolderEntityWithFlatTitle({ id: '', title: I18n.m.getMessage('filesFolderPublic') }));
        }
        return possibleFolders;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        const { folder, folders, projectId } = this.props;
        return [
            <DialogTitle key="title">{`${I18n.m.getMessage('moveFolderTo', { folderName: folder.title })}`}</DialogTitle>,
            <DialogContent key="content" scrollDisabled padding={0} dialogHasTitle>
        <FolderSelector folders={folders} excludedFolders={[folder.id, 'private']} projectId={projectId} closeFunction={() => Dialog.instance?.close()} onSelect={this.moveTo}/>
      </DialogContent>,
        ];
    }
}
MoveFolderDialog.open = (f, folders, projectId) => {
    if (!UpmeshClient.instance.serverConnected()) {
        Routing.instance.alert.post({
            text: I18n.m.getMessage('commandOfflineNotPossible'),
        });
        return;
    }
    RightsManager.hasWriteRight(f.projectId, CurrentUser.userId, 'commandEditFolders')
        .then((can) => {
        if (can) {
            Dialog.instance?.open({
                content: <MoveFolderDialog folder={f} folders={folders} projectId={projectId}/>,
                contentPadding: false,
                scrollable: false,
                fullscreenResponsive: true,
                showCloseIcon: true,
            });
        }
        else {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('forbiddenCommand'),
            });
        }
    })
        .catch((err) => {
        DefaultErrorHandler.showDefaultErrorAlert(err);
    });
};

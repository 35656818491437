import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { BrightSkyMapper } from 'materialTheme/src/theme/components/weather/mapper/BrightSkyMapper';
import { WeatherScreen } from 'materialTheme/src/theme/components/weather/WeatherScreen';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../CurrentProject';
export class WeatherItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            project: props.project != null ? this.props.project : CurrentProject.instance.getCurrentProject(),
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
        this.attachKey = UpmeshClient.eventDispatcher.attach({
            readModelName: 'ProjectAddress',
            callback: (_e) => {
                this.init().catch((err) => console.debug(err));
            },
        });
    }
    componentWillUnmount() {
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.detach('ProjectAddress', this.attachKey);
        }
    }
    async init() {
        const { project } = this.state;
        if (project == null) {
            return;
        }
        const type = 'constructionSite';
        const address = await UpmeshClient.instance.modals.projectAddress.get({
            filter: `projectId eq '${project.id}' and type eq '${type}'`,
        });
        if (address.length > 0) {
            this.setState({ isLoading: false, address: address[0] });
        }
        else {
            this.setState({ isLoading: false });
        }
    }
    render() {
        const { style } = this.props;
        const { address, project } = this.state;
        if (project == null)
            return null;
        const { isLoading } = this.state;
        if (address == null ||
            address.address == null ||
            address.address.longitude === 0 ||
            address.address.addressInput == null ||
            address.address.addressInput.length === 0) {
            return (<View style={ThemeManager.style.contentPadding}>
          <MaterialText type={MaterialTextTypes.H5}>{I18n.m.getMessage('weather')}</MaterialText>
          <MaterialText>{I18n.m.getMessage('projectAddressForWeather')}</MaterialText>
        </View>);
        }
        return (<View style={{
                ...style,
                height: 'auto',
                flexDirection: 'row',
                flexWrap: 'wrap',
                backgroundColor: 'transparent',
                width: '100%',
                minHeight: 100,
                margin: 0,
                flex: 4,
                flexGrow: 4,
                minWidth: Math.min(ThemeManager.style.getScreenRelativePixelSize(360), ResizeEvent.current.windowWidth - 32),
            }}>
        {isLoading ? (<Spinner />) : (<WeatherScreen city={address.address.city} lat={address.address.latitude} long={address.address.longitude} mapper={BrightSkyMapper.instance} showGraph={Platform.OS === 'web'} style={{ width: '100%', overflow: 'hidden', margin: 0 }}/>)}
      </View>);
    }
}

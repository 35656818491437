"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteAbsence = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var AbsenceDeleted_1 = require("../../../events/company/absence/AbsenceDeleted");
var DeleteAbsence = function (_CommandRoot_1$Comman) {
  function DeleteAbsence(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteAbsence);
    _this = _callSuper(this, DeleteAbsence, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'DeleteAbsence';
    _this.isPayroll = false;
    return _this;
  }
  (0, _inherits2.default)(DeleteAbsence, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteAbsence, [{
    key: "getAbsence",
    value: function () {
      var _getAbsence = (0, _asyncToGenerator2.default)(function* () {
        if (this._absence == null) this._absence = yield CommandReadModels_1.CommandReadModels.absence.getById(this.entityId);
        return this._absence;
      });
      function getAbsence() {
        return _getAbsence.apply(this, arguments);
      }
      return getAbsence;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var a = yield this.getAbsence();
        if (a.state === 'open' && (userId === a.createdBy || userId === a.userId)) return true;
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${a.companyId}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].payroll) {
          this.isPayroll = true;
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "hasChanged",
    value: function hasChanged(a) {
      return a.deleted !== true;
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var a = yield this.getAbsence();
        var hasChanged = this.hasChanged(a);
        if (!hasChanged) return [];
        return new AbsenceDeleted_1.AbsenceDeleted(this.entityId, a.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteAbsence = DeleteAbsence;
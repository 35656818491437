"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MoveStoredFileToNewPlan = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var StoredFileDeleted_1 = require("../../events/storedfiles/StoredFileDeleted");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var MoveStoredFileToNewPlan = function (_ClientProjectCommand) {
  function MoveStoredFileToNewPlan(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, MoveStoredFileToNewPlan);
    _this = _callSuper(this, MoveStoredFileToNewPlan, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'MoveStoredFileToNewPlan';
    return _this;
  }
  (0, _inherits2.default)(MoveStoredFileToNewPlan, _ClientProjectCommand);
  return (0, _createClass2.default)(MoveStoredFileToNewPlan, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var getFile = yield this.getFile();
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var canUploadPlan = yield RightsManager_1.RightsManager.hasWriteRight(getFile.projectId, userId, 'commandCreatePlan');
        if (!canUploadPlan) {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        if (getFile.deleted) {
          throw {
            message: 'Bereits gelöscht',
            messageCode: 'badRequest'
          };
        }
        if (getFile.forEntityId != null && getFile.forEntityId.length > 0) {
          var oneDay = 1000 * 60 * 60 * 24;
          if (getFile.userId === userId && getFile.createdAt.getTime() + oneDay > new Date().getTime()) {
            return true;
          }
          throw {
            message: 'Die Datei kann nicht gelöscht werden, da es einem Ticket zugeordnet ist',
            messageCode: 'cantDeleteFileHasAssigendTickets'
          };
        }
        if ((userId === 'all' || getFile.userId === userId) && (getFile.forEntity == null || getFile.forEntity === '')) {
          return true;
        }
        if (getFile.folder === 'public' && (getFile.forEntity == null || getFile.forEntity === '')) {
          var project = yield CommandReadModels_1.CommandReadModels.project.getById(getFile.projectId);
          if (userId === project.creator) return true;
          if (yield RightsManager_1.RightsManager.hasWriteRight(getFile.projectId, userId, 'commandRemoveStoredFile')) {
            return true;
          }
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(getFile.projectId, userId, 'commandEditFolders')) {
          return true;
        }
        if (getFile.folder != null && getFile.folder.length > 0 && (getFile.forEntity == null || getFile.forEntity === '')) {
          var _project = yield CommandReadModels_1.CommandReadModels.project.getById(getFile.projectId);
          if (userId === _project.creator) return true;
          if (getFile.folder === 'public') {
            if (yield RightsManager_1.RightsManager.hasWriteRight(getFile.projectId, userId, 'commandRemoveStoredFile')) {
              return true;
            }
          } else {
            var getFolder = yield CommandReadModels_1.CommandReadModels.folder.getById(getFile.folder);
            var userAccess = getFolder.access.find(function (e) {
              return e.userOrRoleId === userId;
            });
            if (userAccess != null && userAccess.delete) return true;
            var myRoleInProject = yield CommandReadModels_1.CommandReadModels.projectUser.get({
              filter: `projectId eq '${_project.id}' and status eq 'accepted' and userId eq '${userId}'`
            });
            if (myRoleInProject.length > 0) {
              var roleAccess = getFolder.access.find(function (e) {
                return e.userOrRoleId === myRoleInProject[0].roleId;
              });
              if (roleAccess != null && roleAccess.delete) return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var getFile = yield this.getFile();
        return new StoredFileDeleted_1.StoredFileDeleted(this.entityId, getFile.projectId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "getFile",
    value: function () {
      var _getFile = (0, _asyncToGenerator2.default)(function* () {
        if (this.file == null) {
          this.file = yield CommandReadModels_1.CommandReadModels.storedFile.getById(this.entityId);
        }
        return this.file;
      });
      function getFile() {
        return _getFile.apply(this, arguments);
      }
      return getFile;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.MoveStoredFileToNewPlan = MoveStoredFileToNewPlan;
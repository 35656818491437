"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PdfPage = void 0;
var PdfPage = (0, _createClass2.default)(function PdfPage(page, pageWidth, pageHeight, fileIdWithExtension) {
  (0, _classCallCheck2.default)(this, PdfPage);
  this.processed = false;
  this.page = page;
  this.pageWidth = pageWidth;
  this.pageHeight = pageHeight;
  this.fileIdWithExtension = fileIdWithExtension;
});
exports.PdfPage = PdfPage;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrollEvents = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _tsEvents = require("ts-events");
var ScrollEvents = exports.ScrollEvents = function () {
  function ScrollEvents() {
    (0, _classCallCheck2.default)(this, ScrollEvents);
    this.scrollEvent = new _tsEvents.AsyncEvent();
  }
  return (0, _createClass2.default)(ScrollEvents, [{
    key: "scrollPostion",
    get: function get() {
      return this._scrollPostion;
    },
    set: function set(value) {
      this._scrollPostion = value;
      this.scrollEvent.post(value);
    }
  }]);
}();
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataLokiDBRepo = exports.LokiDBIndexOptions = void 0;
var ts_events_1 = require("ts-events");
var ApplicationError_1 = require("../../entities/ApplicationError");
var StdApplicationError_1 = require("../../entities/StdApplicationError");
var MongoDBoDataFilterParser_1 = require("../../odata/parser/mongodb/MongoDBoDataFilterParser");
var OData_1 = require("../../odata/root/OData");
var ODataRepo_1 = require("../../odata/root/ODataRepo");
var LokiDBIndexOptions = function () {
  function LokiDBIndexOptions() {
    (0, _classCallCheck2.default)(this, LokiDBIndexOptions);
    this.asyncListeners = false;
    this.disableMeta = true;
    this.autoupdate = false;
  }
  return (0, _createClass2.default)(LokiDBIndexOptions, null, [{
    key: "fromOdataIndexes",
    value: function fromOdataIndexes(indexes) {
      var options = new LokiDBIndexOptions();
      if (indexes != null) {
        var unique = [];
        var indices = [];
        indexes.forEach(function (index, key) {
          if (index.index) {
            indices.push(key);
          }
          if (index.unique) {
            unique.push(key);
          }
        });
        if (indices.length > 0 || unique.length > 0) {
          if (indices.length > 0) {
            options.indices = indices;
          }
          if (unique.length > 0) {
            options.unique = unique;
          }
          options['autoupdate'] = false;
        }
      }
      return options;
    }
  }]);
}();
exports.LokiDBIndexOptions = LokiDBIndexOptions;
var ODataLokiDBRepo = function (_ODataRepo_1$ODataRep) {
  function ODataLokiDBRepo(exampleEntity, lokiDb) {
    var _this;
    (0, _classCallCheck2.default)(this, ODataLokiDBRepo);
    _this = _callSuper(this, ODataLokiDBRepo, [exampleEntity]);
    _this.changeEvent = new ts_events_1.AsyncEvent();
    _this.dbType = 'Loki';
    _this.updateCounter = 0;
    _this.doPatch = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (entity) {
        _this.updateCounter += 1;
        if (_this.updateCounter % 25 === 0) yield new Promise(function (re) {
            setTimeout(function () {
              re();
            }, 1);
          });
        if (entity['$loki'] != null) {
          try {
            var ent = _this.collection.get(entity['$loki']);
            if (ent == null) entity['$loki'] = undefined;
          } catch (e) {
            entity['$loki'] = undefined;
          }
        }
        if (entity['$loki'] == null) {
          var getId = yield _this.doGetById(entity.id);
          entity['$loki'] = getId['$loki'];
        }
        if (entity['$loki'] == null) {
          throw StdApplicationError_1.StdApplicationError.notFound(`${_this._exampleEntity.entityName}notFound`, `${_this._exampleEntity.entityName}notFound`, {
            id: entity.id
          });
        }
        try {
          return _this.collection.update(entity);
        } catch (e) {
          throw new ApplicationError_1.ApplicationError(`failed to save ${entity.entityName}`, 'dbError', ApplicationError_1.ApplicationErrorStatusCode.InternalServerError, e);
        }
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.lokiDB = lokiDb;
    _this.indexes = LokiDBIndexOptions.fromOdataIndexes(exampleEntity.dBOptions);
    _this._exampleEntity = exampleEntity;
    _this.collection = _this.lokiDB.getCollection(_this._exampleEntity.entityName);
    if (_this.collection == null) {
      _this.collection = _this.lokiDB.addCollection(_this._exampleEntity.entityName, _this.indexes);
    }
    return _this;
  }
  (0, _inherits2.default)(ODataLokiDBRepo, _ODataRepo_1$ODataRep);
  return (0, _createClass2.default)(ODataLokiDBRepo, [{
    key: "create",
    value: function create(c) {
      return new c();
    }
  }, {
    key: "doDelete",
    value: function () {
      var _doDelete = (0, _asyncToGenerator2.default)(function* (entity) {
        var ent = entity['$loki'] == null ? yield this.doGetById(entity.id) : entity;
        return this.collection.remove(ent) != null;
      });
      function doDelete(_x2) {
        return _doDelete.apply(this, arguments);
      }
      return doDelete;
    }()
  }, {
    key: "doDeleteFilter",
    value: function () {
      var _doDeleteFilter = (0, _asyncToGenerator2.default)(function* (filter) {
        if (filter == null || filter.length === 0) {
          var _p = this.collection.chain().remove();
          return _p != null;
        }
        var query = this.filterToQuery(filter);
        var p = this.collection.chain().find(query).remove();
        return p != null;
      });
      function doDeleteFilter(_x3) {
        return _doDeleteFilter.apply(this, arguments);
      }
      return doDeleteFilter;
    }()
  }, {
    key: "doDistinct",
    value: function () {
      var _doDistinct = (0, _asyncToGenerator2.default)(function* (select, filter) {
        var options = new OData_1.ODataGetOptions();
        options.filter = filter;
        var p = yield this.get(options);
        var distinct = [];
        if (p != null && p.length > 0) {
          for (var i = 0; i < p.length; i += 1) {
            if (distinct.indexOf(p[i][select]) === -1) {
              distinct.push(p[i][select]);
            }
          }
        }
        return distinct;
      });
      function doDistinct(_x4, _x5) {
        return _doDistinct.apply(this, arguments);
      }
      return doDistinct;
    }()
  }, {
    key: "filterToQuery",
    value: function filterToQuery(filter) {
      var query;
      if (filter != null && filter.length > 0) {
        try {
          query = MongoDBoDataFilterParser_1.MongoDBoDataFilterParser.parse(filter, false);
        } catch (e) {
          throw this.returnSyntaxError(JSON.stringify({
            filter: filter,
            error: e.message
          }));
        }
        for (var k in query) {
          if (typeof query[k] === 'object' && query[k] != null) {
            var _toString = query[k].toString();
            if (_toString.substr(0, 1) === '/' && _toString.substr(_toString.length - 3, 1) === '/') {
              _toString = _toString.replace('/gi', '');
              _toString = _toString.replace('/', '');
              var reg = new RegExp(_toString, 'i');
              query[k] = {
                $regex: reg
              };
            }
          }
        }
      }
      return query;
    }
  }, {
    key: "doGet",
    value: function () {
      var _doGet = (0, _asyncToGenerator2.default)(function* () {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new OData_1.ODataGetOptions();
        var query = this.filterToQuery(options.filter);
        var mongoOptions = {};
        var projection = {};
        if (options.select != null) {
          mongoOptions.fields = ['id'];
          if (typeof options.select === 'string') {
            var s = options.select;
            mongoOptions.fields = mongoOptions.fields.concat(s.replace(/\s/g, '').trim().split(','));
          } else {
            mongoOptions.fields = mongoOptions.fields.concat(options.select);
          }
          for (var i = 0; i < mongoOptions.fields.length; i += 1) {
            projection[mongoOptions.fields[i]] = 1;
          }
        }
        var sort = [];
        if (options.orderby != null) {
          var orderby = options.orderby.trim().split(',');
          for (var _i = 0; _i < orderby.length; _i += 1) {
            var direction = false;
            var keys = orderby[_i].trim().split(' ');
            if (keys.length > 1) {
              if (keys[1].toUpperCase() === 'DESC') {
                direction = true;
              }
            }
            sort.push([keys[0], direction]);
          }
        }
        mongoOptions.sort = function (a, b) {
          var _sortF = function sortF(a, b) {
            var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
            var v = 0;
            if (sort != null && sort.length > index) {
              var k = sort[index][0];
              var _direction = sort[index][1];
              var propA = a[k] != null && typeof a[k] === 'string' ? a[k].toUpperCase() : a[k];
              var propB = b[k] != null && typeof b[k] === 'string' ? b[k].toUpperCase() : b[k];
              if (typeof propB === 'string' && typeof propA === 'string') {
                v = propA.localeCompare(propB);
              } else if (propA > propB) v = 1;else if (propA < propB) v = -1;
              if (v === 0 && sort.length > index + 1) {
                return _sortF(a, b, index + 1);
              }
              if (_direction) v *= -1;
            }
            return v;
          };
          return _sortF(a, b, 0);
        };
        if (options.top != null) {
          mongoOptions.limit = options.top;
        }
        if (options.skip != null) {
          mongoOptions.skip = options.skip;
        }
        var p = sort.length > 0 ? this.collection.chain().find(query).sort(mongoOptions.sort).offset(mongoOptions.skip).limit(mongoOptions.limit).data() : this.collection.chain().find(query).offset(mongoOptions.skip).limit(mongoOptions.limit).data();
        if (options.select != null) {
          var selection = Array.isArray(options.select) ? options.select : [options.select];
          var select = [];
          for (var _i2 = 0; _i2 < p.length; _i2 += 1) {
            var obj = {};
            for (var k in p[_i2]) {
              if (selection.indexOf(k) >= 0 || k === 'id') {
                obj[k] = p[_i2][k];
              }
            }
            select.push(obj);
          }
          return select;
        }
        return p;
      });
      function doGet() {
        return _doGet.apply(this, arguments);
      }
      return doGet;
    }()
  }, {
    key: "doGetById",
    value: function () {
      var _doGetById = (0, _asyncToGenerator2.default)(function* (id, _select) {
        var ent = this.collection.by('id', id);
        if (ent != null) return ent;
        throw StdApplicationError_1.StdApplicationError.notFound(`${this._exampleEntity.entityName}notFound`, `${this._exampleEntity.entityName}notFound`, {
          id: id
        });
      });
      function doGetById(_x6, _x7) {
        return _doGetById.apply(this, arguments);
      }
      return doGetById;
    }()
  }, {
    key: "doPost",
    value: function () {
      var _doPost = (0, _asyncToGenerator2.default)(function* (entity) {
        this.updateCounter += 1;
        try {
          if (this.updateCounter % 50 === 0) yield new Promise(function (re) {
              setTimeout(function () {
                re();
              }, 1);
            });
          if (entity['$loki'] != null) {
            try {
              var ent = this.collection.get(entity['$loki']);
              if (ent == null) entity['$loki'] = undefined;
            } catch (e) {
              entity['$loki'] = undefined;
            }
          }
          return this.collection.insert(entity);
        } catch (e) {
          if (e.toString() === 'Error: Document is already in collection, please use update()' || e.toString().startsWith('Error: Duplicate key for property') || e.toString().startsWith('Duplicate key for property')) {
            throw new ApplicationError_1.ApplicationError('already exists', 'conflict', ApplicationError_1.ApplicationErrorStatusCode.ConflictError);
          }
          if (e != null) {}
          throw e;
        }
      });
      function doPost(_x8) {
        return _doPost.apply(this, arguments);
      }
      return doPost;
    }()
  }, {
    key: "doPostOrPatchMany",
    value: function () {
      var _doPostOrPatchMany = (0, _asyncToGenerator2.default)(function* (entities) {
        var ids = [];
        var input = [];
        var patch = [];
        var start = Date.now();
        entities.forEach(function (e) {
          ids.push(e.id);
          if (e['$loki'] == null) {
            input.push(e);
          } else {
            patch.push(e);
          }
        });
        try {
          if (input.length > 5000) {
            for (var i = 0; i < input.length; i += 5000) {
              var ins = input.slice(i, Math.min(i + 5000, input.length));
              this.collection.insert((0, _toConsumableArray2.default)(ins));
              yield new Promise(function (r) {
                setTimeout(function () {
                  r();
                }, 16);
              });
            }
          } else {
            this.collection.insert([].concat(input));
          }
          if (patch.length > 0) {
            for (var _i3 = 0; _i3 < patch.length; _i3 += 1) {
              yield this.doPatch(patch[_i3]);
            }
          }
          return ids;
        } catch (e) {
          if (e.toString() === 'Error: Document is already in collection, please use update()' || e.toString().startsWith('Error: Duplicate key for property') || e.toString().includes('Duplicate key for property') || e.toString().includes('violates the unique')) {
            throw new ApplicationError_1.ApplicationError('already exists', 'conflict', ApplicationError_1.ApplicationErrorStatusCode.ConflictError);
          }
          if (e != null) {}
          throw e;
        }
      });
      function doPostOrPatchMany(_x9) {
        return _doPostOrPatchMany.apply(this, arguments);
      }
      return doPostOrPatchMany;
    }()
  }, {
    key: "doCount",
    value: function () {
      var _doCount = (0, _asyncToGenerator2.default)(function* (filter) {
        var query = this.filterToQuery(filter);
        return this.collection.count(query);
      });
      function doCount(_x10) {
        return _doCount.apply(this, arguments);
      }
      return doCount;
    }()
  }, {
    key: "doPatchManyWithFilter",
    value: function () {
      var _doPatchManyWithFilter = (0, _asyncToGenerator2.default)(function* (body, filter) {});
      function doPatchManyWithFilter(_x11, _x12) {
        return _doPatchManyWithFilter.apply(this, arguments);
      }
      return doPatchManyWithFilter;
    }()
  }]);
}(ODataRepo_1.ODataRepo);
exports.ODataLokiDBRepo = ODataLokiDBRepo;
import { DropArea } from 'materialTheme/src/theme/components/dragndrop/DropArea';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useMemo, useRef } from 'react';
import { FlatList, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import * as uuid from 'uuid';
import { compareDates, getItemKey, getOrderedWeekDays, isS } from './calendarUtil';
export default function WeekCalendar(props) {
    const { currentDate, tickets, iosContentInset, onDrop, renderTicketCard, renderTicketName, width, height, noDateWidth, setNewDate, getTicketsForDate, } = props;
    const weekdayNamesShort = useRef(getOrderedWeekDays());
    const rows = useMemo(() => {
        const noDateWidth2 = isS() ? 0 : noDateWidth;
        const { startWithMonday } = CurrentUser.settings;
        const rows = [];
        const dayWidth = (width - 33 - noDateWidth2) / 7;
        const dayHeight = height - 96;
        const day = currentDate.getDay();
        const date = currentDate.getDate();
        const weekStart = date - day + (currentDate.getDay() === 0 ? -7 : 0) + (startWithMonday ? 1 : 0);
        const weekStartDate = new Date(currentDate.getTime());
        weekStartDate.setDate(weekStart);
        const today = new Date();
        for (let i = 0; i < 7; i += 1) {
            const col = [];
            const dayDate = new Date(weekStartDate.getTime());
            dayDate.setDate(weekStartDate.getDate() + i);
            col.push(<Ripple key={uuid.v1()} style={{
                    alignSelf: 'center',
                    alignItems: 'center',
                    height: 48,
                    width: dayWidth,
                    borderColor: '#ccc',
                    borderRightWidth: i === 6 ? 0.5 : 0,
                    borderLeftWidth: i === 0 ? 0 : 0.5,
                    backgroundColor: ThemeManager.style.appBgColor,
                }} onPress={() => setNewDate(dayDate, 'day')}>
          <MaterialText centeredBox textAlign="center">
            {weekdayNamesShort.current[i].slice(0, 2)}
          </MaterialText>
          <MaterialText textAlign="center" centeredBox additionalTextStyle={{
                    textDecorationLine: compareDates(today, dayDate) ? 'underline' : 'none',
                }}>
            {dayDate.getDate()}
          </MaterialText>
        </Ripple>);
            const filteredTickets = getTicketsForDate(dayDate);
            col.push(<View style={{ backgroundColor: ThemeManager.style.brandPrimary, height: dayHeight - 48 }} key={uuid.v1()}>
          <DropArea dropFilter={() => true} onDrop={onDrop(new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), 0, 0, 0, 0))} zIndex={1}>
            <View style={{ backgroundColor: ThemeManager.style.appBgColor, height: dayHeight - 48 }}>
              <View style={{
                    width: dayWidth,
                    height: dayHeight - 48,
                    borderColor: '#ccc',
                    borderTopWidth: 0.5,
                    borderRightWidth: i === 6 ? 0.5 : 0,
                    borderLeftWidth: i === 0 ? 0 : 0.5,
                    backgroundColor: compareDates(today, dayDate) ? 'rgba(0, 115, 255, 0.05)' : '#f8f8f8',
                }}>
                <FlatList style={{ height }} key="ticketList" keyExtractor={getItemKey(true)} renderItem={width < 800 ? renderTicketName : renderTicketCard} numColumns={1} data={filteredTickets} contentContainerStyle={{ padding: 2, width: '100%', alignSelf: 'center' }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>
              </View>
            </View>
          </DropArea>
        </View>);
            rows.push(<View key={`row_${i}`} style={{ flexDirection: 'column' }}>
          {col}
        </View>);
        }
        return rows;
    }, [
        currentDate,
        tickets,
        iosContentInset,
        onDrop,
        renderTicketCard,
        renderTicketName,
        width,
        height,
        noDateWidth,
        setNewDate,
    ]);
    return (<View style={{
            flexDirection: 'row',
            backgroundColor: ThemeManager.style.appBgColor,
        }}>
      {rows}
    </View>);
}

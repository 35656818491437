"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateFolder = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var FolderCreated_1 = require("../../events/folder/FolderCreated");
var FolderEntity_1 = require("../../query/entities/FolderEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var CreateFolder = function (_ClientProjectCommand) {
  function CreateFolder(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateFolder);
    _this = _callSuper(this, CreateFolder, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'CreateFolder';
    return _this;
  }
  (0, _inherits2.default)(CreateFolder, _ClientProjectCommand);
  return (0, _createClass2.default)(CreateFolder, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield _superPropGet(CreateFolder, "checkProjectStatusAndGeneralAccess", this, 3)([this.data.projectId, userId]);
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.data.projectId, userId, 'commandEditFolders')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var andSub = this.data.subFolderFromId != null ? ` and subFolderFromId eq '${this.data.subFolderFromId}'` : ` and subFolderFromId eq null`;
        var getFolder = yield CommandReadModels_1.CommandReadModels.folder.get({
          filter: `projectId eq '${this.data.projectId}' and deleted ne true and title eq '${this.data.title}'${andSub}`
        });
        if (getFolder.length > 0) {
          throw {
            message: 'Folder with name already exists in project',
            messageCode: 'folderWithNameAlreadyExists',
            messageData: {
              title: this.data.title
            }
          };
        }
        var data = {
          accessAll: this.data.accessAll,
          access: this.data.accessAll ? [] : this.data.access,
          description: this.data.description,
          tags: this.data.tags,
          projectId: this.data.projectId,
          subFolderFromId: this.data.subFolderFromId,
          title: this.data.title
        };
        return new FolderCreated_1.FolderCreated(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'title') {
          if (this.data.title.length <= 0) {
            throw {
              key: 'title',
              messageCode: 'folderNameRequired',
              message: 'Titel wird benötigt'
            };
          }
          var maxLength = FolderEntity_1.FolderEntity.maxPathLength;
          if (this.data.subFolderFromId != null && this.data.subFolderFromId.length > 0) {
            var parentLength = yield FolderEntity_1.FolderEntity.getPathLength(this.data.subFolderFromId);
            if (parentLength != null && parentLength.pathLength > 0) maxLength -= parentLength.pathLength;
          }
          if (this.data.title.length > maxLength) {
            throw new Error('toLong');
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CreateFolder = CreateFolder;
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { I18n } from '../../i18n/I18n';
export function AbsenceStatusChip(props) {
    if (props.absence.state === 'open') {
        return (<Chip backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF" thumbnail={<Icon icon="circle-outline" toolTip="" color="#FFFFFF"/>} title={I18n.m.getMessage('absenceStateOpen')}/>);
    }
    if (props.absence.state === 'rejected') {
        return (<Chip backgroundColor={ThemeManager.style.brandDanger} textColor="#FFFFFF" thumbnail={<Icon icon="close" toolTip="" color="#FFFFFF"/>} title={I18n.m.getMessage('absenceStateRejected')}/>);
    }
    if (props.absence.state === 'approved') {
        return (<Chip backgroundColor={ThemeManager.style.brandSuccess} textColor="#FFFFFF" thumbnail={<Icon icon="check" toolTip="" color="#FFFFFF"/>} title={I18n.m.getMessage('absenceStateApproved')}/>);
    }
    return null;
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataRepo = exports.ChangeEventResult = void 0;
var ApplicationError_1 = require("../../entities/ApplicationError");
var OdataEntity_1 = require("../../entities/root/OdataEntity");
var ErrorReporter_1 = require("../../reporting/ErrorReporter");
var ChangeEventResult = (0, _createClass2.default)(function ChangeEventResult(event, options) {
  (0, _classCallCheck2.default)(this, ChangeEventResult);
  this.event = event;
  this.options = options;
});
exports.ChangeEventResult = ChangeEventResult;
var ODataRepo = function () {
  function ODataRepo(_exampleEntity, _db) {
    (0, _classCallCheck2.default)(this, ODataRepo);
  }
  return (0, _createClass2.default)(ODataRepo, [{
    key: "addEventListener",
    value: function addEventListener(_event, handler) {
      this.changeEvent.attach(handler);
    }
  }, {
    key: "count",
    value: function () {
      var _count = (0, _asyncToGenerator2.default)(function* (filter) {
        var _this = this;
        var g = function () {
          var _ref = (0, _asyncToGenerator2.default)(function* () {
            if (filter != null) filter = _this.encodeFilter(filter);
            var result = yield _this.doCount(filter);
            _this.changeEvent.post(new ChangeEventResult('onCounted'));
            return result;
          });
          return function g() {
            return _ref.apply(this, arguments);
          };
        }();
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} Count ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'count',
            'db.query.text': `${filter}`
          }
        }, g) : g();
      });
      function count(_x) {
        return _count.apply(this, arguments);
      }
      return count;
    }()
  }, {
    key: "delete",
    value: function () {
      var _delete2 = (0, _asyncToGenerator2.default)(function* (entityId) {
        var _this2 = this;
        var g = function () {
          var _ref2 = (0, _asyncToGenerator2.default)(function* () {
            var orgE = yield _this2.getById(entityId);
            var result = yield _this2.doDelete(orgE);
            _this2.changeEvent.post(new ChangeEventResult('onDeleted', orgE));
            return result;
          });
          return function g() {
            return _ref2.apply(this, arguments);
          };
        }();
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} Delete ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'delete',
            'db.query.text': `${entityId}`
          }
        }, g) : g();
      });
      function _delete(_x2) {
        return _delete2.apply(this, arguments);
      }
      return _delete;
    }()
  }, {
    key: "deleteFilter",
    value: function () {
      var _deleteFilter = (0, _asyncToGenerator2.default)(function* (filter) {
        var _this3 = this;
        var g = function () {
          var _ref3 = (0, _asyncToGenerator2.default)(function* () {
            if (filter != null) filter = _this3.encodeFilter(filter);
            var result = yield _this3.doDeleteFilter(filter);
            _this3.changeEvent.post(new ChangeEventResult('onDeletedMany', result));
            return result;
          });
          return function g() {
            return _ref3.apply(this, arguments);
          };
        }();
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} DeleteFilter ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'deleteFilter',
            'db.query.text': `${filter}`
          }
        }, g) : g();
      });
      function deleteFilter(_x3) {
        return _deleteFilter.apply(this, arguments);
      }
      return deleteFilter;
    }()
  }, {
    key: "distinct",
    value: function () {
      var _distinct = (0, _asyncToGenerator2.default)(function* (select, _filter) {
        var _this4 = this;
        var g = function () {
          var _ref4 = (0, _asyncToGenerator2.default)(function* () {
            if (_filter != null) _filter = _this4.encodeFilter(_filter);
            var result = yield _this4.doDistinct(select);
            _this4.changeEvent.post(new ChangeEventResult('onDistinct'));
            return result;
          });
          return function g() {
            return _ref4.apply(this, arguments);
          };
        }();
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} DeleteFilter ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'deleteFilter',
            'db.query.text': `${select.toString}`
          }
        }, g) : g();
      });
      function distinct(_x4, _x5) {
        return _distinct.apply(this, arguments);
      }
      return distinct;
    }()
  }, {
    key: "get",
    value: function () {
      var _get = (0, _asyncToGenerator2.default)(function* (options) {
        var _this5 = this;
        if (options != null && options.filter != null) options.filter = this.encodeFilter(options.filter);
        var result = ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} Get ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'getById',
            'db.query.text': `${options == null ? void 0 : options.filter}`
          }
        }, (0, _asyncToGenerator2.default)(function* () {
          return _this5.doGet(options);
        })) : yield this.doGet(options);
        this.changeEvent.post(new ChangeEventResult('onGot'));
        var typedResult = [];
        for (var e of result) {
          typedResult.push(this._exampleEntity.createOne(e));
        }
        return typedResult;
      });
      function get(_x6) {
        return _get.apply(this, arguments);
      }
      return get;
    }()
  }, {
    key: "encodeFilter",
    value: function encodeFilter(filter) {
      return filter.replace(/'([^']+)'/g, function ($1) {
        return encodeURIComponent($1).replace(/%20/g, ' ');
      });
    }
  }, {
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id, select) {
        var _this6 = this;
        var result = ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} GetById ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'getById',
            'db.query.text': `${id} ${select ? select.toString() : ''}`
          }
        }, (0, _asyncToGenerator2.default)(function* () {
          return _this6.doGetById(id, select);
        })) : yield this.doGetById(id, select);
        if (result == null) {
          throw new ApplicationError_1.ApplicationError('not found', `${this._exampleEntity.entityName}notFound`, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError, {
            id: id
          });
        }
        this.changeEvent.post(new ChangeEventResult('onGot'));
        return this._exampleEntity.createOne(result);
      });
      function getById(_x7, _x8) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }, {
    key: "patch",
    value: function () {
      var _patch = (0, _asyncToGenerator2.default)(function* (entity) {
        var _this7 = this;
        var g = function () {
          var _ref7 = (0, _asyncToGenerator2.default)(function* () {
            var get = yield _this7.getById(entity.id, undefined);
            for (var k in entity) {
              entity[k] = OdataEntity_1.OdataEntity.dateReviver(k, entity[k]);
            }
            var e = _this7._exampleEntity.createOne(get);
            e.fill(entity);
            yield e.validate();
            var result = _this7._exampleEntity.createOne(yield _this7.doPatch(entity));
            _this7.changeEvent.post(new ChangeEventResult('onPatched', entity));
            return result;
          });
          return function g() {
            return _ref7.apply(this, arguments);
          };
        }();
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} Patch ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'patch',
            'db.query.text': `${entity.id}`
          }
        }, g) : g();
      });
      function patch(_x9) {
        return _patch.apply(this, arguments);
      }
      return patch;
    }()
  }, {
    key: "post",
    value: function () {
      var _post = (0, _asyncToGenerator2.default)(function* (entity) {
        var _this8 = this;
        var g = function () {
          var _ref8 = (0, _asyncToGenerator2.default)(function* () {
            try {
              var exists = yield _this8.getById(entity.id, undefined);
              if (exists != null) {
                throw new ApplicationError_1.ApplicationError('already exists', 'conflict', ApplicationError_1.ApplicationErrorStatusCode.ConflictError);
              }
            } catch (e) {
              if (e instanceof ApplicationError_1.ApplicationError) {
                if (e.statusCode !== ApplicationError_1.ApplicationErrorStatusCode.NotFoundError) {
                  throw e;
                }
              }
            }
            for (var k in entity) {
              entity[k] = OdataEntity_1.OdataEntity.dateReviver(k, entity[k]);
            }
            var typedEntity = _this8._exampleEntity.createOne(entity);
            var valid = yield _this8.validateEntity(typedEntity);
            if (valid.error) {
              throw _this8.returnValidationError(valid.errorMessage);
            }
            var result = yield _this8.doPost(typedEntity);
            _this8.changeEvent.post(new ChangeEventResult('onPosted', typedEntity));
            return _this8._exampleEntity.createOne(result);
          });
          return function g() {
            return _ref8.apply(this, arguments);
          };
        }();
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} Post ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'post',
            'db.query.text': `${entity.id}`
          }
        }, g) : g();
      });
      function post(_x10) {
        return _post.apply(this, arguments);
      }
      return post;
    }()
  }, {
    key: "postOrPatchMany",
    value: function () {
      var _postOrPatchMany = (0, _asyncToGenerator2.default)(function* (entities) {
        var _this9 = this;
        var g = function () {
          var _ref9 = (0, _asyncToGenerator2.default)(function* () {
            var ent = [];
            for (var i = 0; i < entities.length; i += 1) {
              var entity = entities[i];
              for (var k in entity) {
                entity[k] = OdataEntity_1.OdataEntity.dateReviver(k, entity[k]);
              }
              var typedEntity = _this9._exampleEntity.createOne(entity);
              var valid = yield _this9.validateEntity(typedEntity);
              if (valid.error) {
                throw _this9.returnValidationError(valid.errorMessage);
              }
              ent.push(typedEntity);
            }
            var result = [];
            try {
              result = ent.length > 0 ? yield _this9.doPostOrPatchMany(ent) : [];
            } catch (e) {
              if (e.toString().includes('conflict')) {
                var toPost = [];
                for (var _i = 0; _i < ent.length; _i += 1) {
                  result.push(ent[_i].id);
                  try {
                    yield _this9.patch(ent[_i]);
                  } catch (err) {
                    toPost.push(ent[_i]);
                  }
                }
                if (toPost.length > 0) yield _this9.doPostOrPatchMany(toPost);
              } else {
                throw e;
              }
            }
            ent.forEach(function (typedEntity) {
              _this9.changeEvent.post(new ChangeEventResult('onPosted', typedEntity));
            });
            return result;
          });
          return function g() {
            return _ref9.apply(this, arguments);
          };
        }();
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} PostOrPatchMany ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'postOrPatchMany',
            'db.query.text': `${entities.map(function (e) {
              return e.id;
            }).join(', ')}`
          }
        }, g) : g();
      });
      function postOrPatchMany(_x11) {
        return _postOrPatchMany.apply(this, arguments);
      }
      return postOrPatchMany;
    }()
  }, {
    key: "patchManyWithFilter",
    value: function () {
      var _patchManyWithFilter = (0, _asyncToGenerator2.default)(function* (body, filter) {
        var _this10 = this;
        return ErrorReporter_1.ErrorReporter.Reporter && typeof ErrorReporter_1.ErrorReporter.Reporter['startSpan'] === 'function' ? yield ErrorReporter_1.ErrorReporter.Reporter['startSpan']({
          name: `${this.dbType.toLowerCase()} PatchManyWithFilter ${this._exampleEntity.entityName}`,
          op: 'db.query',
          attributes: {
            'db.system': this.dbType.toLowerCase(),
            'db.collection.name': this._exampleEntity.entityName,
            'db.operation.name': 'patchManyWithFilter',
            'db.query.text': `${filter}; ${JSON.stringify(body)}`
          }
        }, (0, _asyncToGenerator2.default)(function* () {
          return _this10.doPatchManyWithFilter(body, filter);
        })) : this.doPatchManyWithFilter(body, filter);
      });
      function patchManyWithFilter(_x12, _x13) {
        return _patchManyWithFilter.apply(this, arguments);
      }
      return patchManyWithFilter;
    }()
  }, {
    key: "removeEventListener",
    value: function removeEventListener() {}
  }, {
    key: "returnSyntaxError",
    value: function returnSyntaxError(errorMessage) {
      return new ApplicationError_1.ApplicationError(`Syntax Error: ${errorMessage}`, 'dbError', ApplicationError_1.ApplicationErrorStatusCode.BadRequestError);
    }
  }, {
    key: "returnValidationError",
    value: function returnValidationError(errorMessage) {
      return new ApplicationError_1.ApplicationError(`Validation Error: ${errorMessage}`, 'dbError', ApplicationError_1.ApplicationErrorStatusCode.BadRequestError);
    }
  }, {
    key: "validateEntity",
    value: function () {
      var _validateEntity = (0, _asyncToGenerator2.default)(function* (entity) {
        try {
          yield entity.validate();
          return {
            error: false,
            errorMessage: ''
          };
        } catch (e) {
          if (e != null) return {
            error: true,
            errorMessage: typeof e === 'string' ? e : JSON.stringify(e)
          };
          return {
            error: true,
            errorMessage: 'unknown error'
          };
        }
      });
      function validateEntity(_x14) {
        return _validateEntity.apply(this, arguments);
      }
      return validateEntity;
    }()
  }]);
}();
exports.ODataRepo = ODataRepo;
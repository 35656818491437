import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateProjectRole } from 'upmesh-core/src/client/commands/roles/CreateProjectRole';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
export class CreateRoleDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    static open(project) {
        Dialog.instance?.open({
            closeOnTouchOutside: false,
            content: <CreateRoleDialog project={project}/>,
            contentPadding: false,
        });
    }
    render() {
        const { project } = this.props;
        const currentData = {
            description: undefined,
            projectId: project.id,
            roleName: '',
            basedOnRoleId: '1',
        };
        const dataOptions = new Map();
        dataOptions.set('roleName', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('rolesAddDialogRolenameLabel'),
                key: 'roleName',
            },
        });
        dataOptions.set('basedOnRoleId', {
            formType: 'Picker',
            cols: 1,
            mapValue: (value, fromFormInput) => {
                if (fromFormInput)
                    return value.value.data;
                let index = 0;
                if (value === '3')
                    index = 1;
                else if (value === '2')
                    index = 2;
                return { index, value };
            },
            props: {
                labelText: I18n.m.getMessage('rolesAddDialogBaseroleLabel'),
                key: 'basedOnRoleId',
                list: [
                    { title: I18n.m.getMessage('projectManager'), data: '1' },
                    { title: I18n.m.getMessage('collaborator'), data: '3' },
                    { title: I18n.m.getMessage('subcontractor'), data: '2' },
                ],
            },
        });
        return (<Form key="addProjectRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('rolesAddDialogTitle'),
            }} stickyActions={false} command={new CreateProjectRole(currentData, uuid.v1())} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('rolesAddDialogActionButton')} additionalButtons={[
                <ContainedButton key="cancel" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

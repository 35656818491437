import querystring from 'querystring';
import fetch from 'cross-fetch';
import { Url } from 'cqrs-shared/src/uri/Url';
export class WeatherWrapper {
    constructor(data) {
        this.data = data;
    }
    static async getGroupsForDate(date, lon, lat, start, end) {
        const dayResult = await WeatherWrapper.getWeatherForDate(date, lat, lon);
        if (dayResult == null || start > dayResult.weather.length || end > dayResult.weather.length) {
            return null;
        }
        const groups = [];
        let lastGroup = {
            tempLow: dayResult.weather[start].temperature || 0,
            tempHigh: dayResult.weather[start].temperature || 0,
            condition: dayResult.weather[start].condition || 'dry',
            startTime: start,
            endTime: start + 1,
        };
        for (let i = start + 1; i < end; i += 1) {
            const current = dayResult.weather[i];
            if (current.condition === lastGroup.condition) {
                if (current.temperature != null) {
                    if (lastGroup.tempLow > current.temperature) {
                        lastGroup.tempLow = current.temperature;
                    }
                    if (lastGroup.tempHigh < current.temperature) {
                        lastGroup.tempHigh = current.temperature;
                    }
                }
                lastGroup.endTime = i;
            }
            else {
                groups.push(lastGroup);
                lastGroup = {
                    tempLow: current.temperature || 0,
                    tempHigh: current.temperature || 0,
                    condition: current.condition || 'dry',
                    startTime: i,
                    endTime: i + 1,
                };
            }
        }
        groups.push(lastGroup);
        return groups;
    }
    static async getWeatherForDate(date, lat, lon) {
        const query = {
            date: date.toISOString(),
            lon,
            lat,
        };
        return WeatherWrapper.callApi(query);
    }
    static async callApi(callData) {
        let params;
        if (callData.last_date) {
            params = {
                date: callData.date,
                last_date: callData.last_date,
                lat: callData.lat.toString(),
                lon: callData.lon.toString(),
            };
        }
        else {
            params = {
                date: callData.date,
                lat: callData.lat.toString(),
                lon: callData.lon.toString(),
            };
        }
        const url = Url.getURLfromString(`https://api.brightsky.dev/weather?${querystring.stringify(params)}`);
        const urlString = url.toString();
        const cached = WeatherWrapper.cachedData.get(urlString);
        if (cached != null) {
            return cached;
        }
        try {
            const result = await fetch(url.toString());
            if (result.status === 200) {
                const resultData = await result.json();
                WeatherWrapper.cachedData.set(urlString, resultData);
                return resultData;
            }
        }
        catch (e) {
            console.debug('error in getting weather data:', e);
        }
        return null;
    }
    static brightSkyToWeatherJournal(condition) {
        switch (condition) {
            case 'dry':
                return 'clear';
            case 'fog':
                return 'fog';
            case 'rain':
                return 'rain';
            case 'sleet':
                return 'sleet';
            case 'snow':
                return 'snow';
            case 'hail':
                return 'hail';
            case 'thunderstorm':
                return 'thunderstorm';
            default:
                return 'clear';
        }
    }
}
WeatherWrapper.cachedData = new Map();

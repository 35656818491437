"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanySettingsEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var I18n_1 = require("../../../i18n/I18n");
var SubscriptionEntity_1 = require("./SubscriptionEntity");
var CompanySettingsEntity = function (_ReadModelEntity_1$Re) {
  function CompanySettingsEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, CompanySettingsEntity);
    _this = _callSuper(this, CompanySettingsEntity, [obj]);
    _this.entityName = 'CompanySettings';
    _this.lists = {};
    _this.messagesOverride = {};
    _this.projectStateNames = [];
    _this.modules = [];
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(CompanySettingsEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(CompanySettingsEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new CompanySettingsEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('ticketTypes', new OdataEntity_1.OdataIndex());
      baseMap.set('crafts', new OdataEntity_1.OdataIndex());
      baseMap.set('messagesOverride', new OdataEntity_1.OdataIndex());
      baseMap.set('fileTypes', new OdataEntity_1.OdataIndex());
      baseMap.set('tagGroups', new OdataEntity_1.OdataIndex());
      baseMap.set('tags', new OdataEntity_1.OdataIndex());
      baseMap.set('defaultWorkingTimeModelId', new OdataEntity_1.OdataIndex());
      baseMap.set('defaultGroupId', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketLayouts', new OdataEntity_1.OdataIndex());
      baseMap.set('reportTemplates', new OdataEntity_1.OdataIndex());
      baseMap.set('modules', new OdataEntity_1.OdataIndex());
      baseMap.set('lists', new OdataEntity_1.OdataIndex());
      baseMap.set('managerLicenses', new OdataEntity_1.OdataIndex());
      baseMap.set('collaboratorLicenses', new OdataEntity_1.OdataIndex());
      baseMap.set('timeTrackingLicenses', new OdataEntity_1.OdataIndex());
      baseMap.set('billingDate', new OdataEntity_1.OdataIndex());
      baseMap.set('accountManager', new OdataEntity_1.OdataIndex());
      baseMap.set('projectStateNames', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "hasModule",
    value: function hasModule(module) {
      return this.modules.findIndex(function (m) {
        return m === module;
      }) > -1;
    }
  }], [{
    key: "getTaskTypeById",
    value: function () {
      var _getTaskTypeById = (0, _asyncToGenerator2.default)(function* (id) {
        var l = yield CompanySettingsEntity.getTaskTypes();
        var item = l.find(function (s) {
          return s.id === id;
        });
        if (item != null) return item;
        throw StdApplicationError_1.StdApplicationError.notFound();
      });
      function getTaskTypeById(_x) {
        return _getTaskTypeById.apply(this, arguments);
      }
      return getTaskTypeById;
    }()
  }, {
    key: "getTaskTypes",
    value: function () {
      var _getTaskTypes = (0, _asyncToGenerator2.default)(function* () {
        var values = {
          lng: CurrentUser_1.CurrentUser.entity != null ? CurrentUser_1.CurrentUser.entity.locale : 'de'
        };
        return [{
          title: I18n_1.I18n.m.getMessage('taskTypeWork', values),
          id: 'work',
          productive: true
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeHoliday', values),
          id: 'holiday',
          productive: false
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeOvertimeReduction', values),
          id: 'overtimeReduction',
          productive: false
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeShortTimeWork', values),
          id: 'shortTimeWork',
          productive: false
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeWinterFailure', values),
          id: 'winterFailure',
          productive: false
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeWinterFailureViaSubstituteFund', values),
          id: 'winterFailureViaSubstituteFund',
          productive: false
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeSick', values),
          id: 'sick',
          productive: false
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeEducation', values),
          id: 'education',
          productive: false
        }, {
          title: I18n_1.I18n.m.getMessage('taskTypeOther', values),
          id: 'other',
          productive: false
        }];
      });
      function getTaskTypes() {
        return _getTaskTypes.apply(this, arguments);
      }
      return getTaskTypes;
    }()
  }, {
    key: "hasModule",
    value: function () {
      var _hasModule = (0, _asyncToGenerator2.default)(function* (module, companyId) {
        var companySettings = yield ReadModels_1.ReadModels.get('CompanySettings').getById(companyId);
        return companySettings.hasModule(module);
      });
      function hasModule(_x2, _x3) {
        return _hasModule.apply(this, arguments);
      }
      return hasModule;
    }()
  }, {
    key: "updateLicenses",
    value: function () {
      var _updateLicenses = (0, _asyncToGenerator2.default)(function* (ent, eventServerApi) {
        var till = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Date();
        var events = yield eventServerApi.get({
          filter: `(eventName eq 'SubscriptionAdded' or eventName eq 'SubscriptionChanged' or eventName eq 'SubscriptionOfCompanyExpired' or eventName eq 'SubscriptionExpired') and lastModifiedAt le ${till.toISOString()} and aggregateId eq '${ent.id}'`,
          orderby: 'lastModifiedAt ASC'
        });
        var sub = [];
        var _loop = function* _loop(e) {
          var exists = sub.findIndex(function (a) {
            return a.id === e.entityId;
          });
          if (exists > -1) {
            var _s = sub[exists];
            var ev = e;
            if (ev.data.licenceType != null) _s.licenceType = ev.data.licenceType;
            if (ev.data.licenses != null) _s.licenses = ev.data.licenses;
            if (ev.data.expireDate != null) _s.expireDate = ev.data.expireDate;
            sub[exists] = _s;
          } else {
            var _s2 = new SubscriptionEntity_1.SubscriptionEntity(e.data);
            _s2.id = e.entityId;
            sub.push(_s2);
          }
        };
        for (var e of events) {
          yield* _loop(e);
        }
        var timeTrackingLicenses = 0;
        var collaboratorLicenses = 0;
        var managerLicenses = 0;
        for (var s of sub) {
          if (!s.expired && s.subscription === 'enterprise' && (s.expireDate == null || new Date(s.expireDate).getTime() > new Date().getTime()) && s.licenses != null && s.licenses > 0) {
            if (s.licenceType === 'timeTracking') {
              timeTrackingLicenses += s.licenses;
            } else if (s.licenceType === 'collaborator') {
              collaboratorLicenses += s.licenses;
            } else {
              managerLicenses += s.licenses;
            }
          }
        }
        ent.managerLicenses = managerLicenses;
        ent.collaboratorLicenses = collaboratorLicenses;
        ent.timeTrackingLicenses = timeTrackingLicenses;
        return ent;
      });
      function updateLicenses(_x4, _x5) {
        return _updateLicenses.apply(this, arguments);
      }
      return updateLicenses;
    }()
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.CompanySettingsEntity = CompanySettingsEntity;
import { View, FlatList } from 'react-native';
import React, { useMemo } from 'react';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { DropArea } from 'materialTheme/src/theme/components/dragndrop/DropArea';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { getItemKey } from './calendarUtil';
import { I18n } from '../../../i18n/I18n';
function NoDateTickets(props) {
    const { iosContentInset, tickets, height, noDateWidth, onDrop, renderTicketName, setNoDateWidth } = props;
    const filteredTickets = useMemo(() => tickets.filter((ticket) => {
        if (ticket.completionOn != null)
            return false;
        if (ticket.fields != null && ticket.fields.length > 0) {
            const datefields = ticket.fields.filter((f) => (f.type === 'Date' || f.type === 'DateRange') && f.value != null);
            if (datefields.length > 0)
                return false;
        }
        return true;
    }), [tickets]);
    return (<View style={{ backgroundColor: ThemeManager.style.brandPrimary, height: height - 144 }}>
      <DropArea dropFilter={() => true} onDrop={onDrop()} zIndex={1}>
        <View style={{ backgroundColor: ThemeManager.style.appBgColor }}>
          <View style={{
            width: noDateWidth - 8,
            height: height - 114,
            marginLeft: 8,
            borderLeftWidth: 0.5,
            borderColor: '#ccc',
        }}>
            <Ripple style={{
            width: noDateWidth - 8,
            height: 40,
            alignItems: 'center',
            flexDirection: 'row',
        }} onPress={() => setNoDateWidth(40)}>
              <Icon icon="menu-open" rotation={180} toolTip={I18n.m.getMessage('hide')}/>
              <MaterialText textAlign="left" fixedWidth="100%" centeredBox>
                {I18n.m.getMessage('ticketsWithoutCompletionOn')}
              </MaterialText>
            </Ripple>
            <View style={{
            flex: 1,
        }}>
              {filteredTickets.length > 0 && (<FlatList style={{ height: height - 180 }} key="ticketList" keyExtractor={getItemKey()} renderItem={renderTicketName} numColumns={1} data={filteredTickets} contentContainerStyle={{ padding: 2, width: '100%', alignSelf: 'center' }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>)}
            </View>
          </View>
        </View>
      </DropArea>
    </View>);
}
export default NoDateTickets;

import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ProOnlyDialogContent } from 'materialTheme/src/theme/components/ProOnlyDialogContent';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ProjectEntity } from 'upmesh-core/src/client/query/entities/ProjectEntity';
import { I18n } from '../../../i18n/I18n';
import { OfflineDataDownloader } from '../../../repo/file/OfflineDataDownloader';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { OfflineProjectSettingsPU } from '../../root/OfflineProjectSettingsPU';
import { CompanyProjectStatePicker } from '../CompanyProjectStatePicker';
import { CurrentProject } from '../CurrentProject';
import { ProjectImage } from '../ProjectImage';
import { ProjectTemplateStatus } from '../ProjectTemplateStatus';
import { UpgradeButton } from '../UpgradeButton';
const bytes = require('bytes');
const howToDownloadAProjectImage = require('../../../assets/img/howToDownloadAProject.png');
export class ProjectItem extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.handleChange = (project) => {
            this.setState({ project });
        };
        this.updateOfflineProject = (e) => {
            if (e.settings.projectId === this.state.project?.id)
                this.setState({ offlineProject: { ...e } });
        };
        this.openHowToDownloadDialog = () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                contentPadding: false,
                content: (<ProOnlyDialogContent headline={I18n.m.getMessage('projectDownloadHowToHeadline')} description={I18n.m.getMessage('projectDownloadHowToDescription')} buttonText={I18n.m.getMessage('projectDownloadProFunctionButton')} buttonFunction={Dialog.instance?.close} imageSource={howToDownloadAProjectImage} imagePosition="below"/>),
            });
        };
        this.openDownloadDialog = (project) => OfflineProjectSettingsPU.open(project.id);
        this.goToProjectSettings = (_e) => {
            const { project } = this.state;
            Routing.instance.goTo(`/projects/${project.id}/projectsettings`);
        };
        this.state = {
            isLoading: false,
            created: false,
            offlineProject: OfflineDataDownloader.getProject(props.project?.id),
            project: CurrentProject.instance.getCurrentProject(),
        };
    }
    async init() {
        const userSubscription = await RightsManager.hasVersion(CurrentUser.userId);
        this.setState({ userSubscription });
    }
    componentDidMount() {
        this.mounted = true;
        CurrentProject.projectChanged.attach(this.handleChange);
        OfflineDataDownloader.progress.attach(this.updateOfflineProject);
        this.init().catch((err) => console.warn(err));
    }
    componentWillUnmount() {
        this.mounted = false;
        OfflineDataDownloader.progress.detach(this.updateOfflineProject);
        CurrentProject.projectChanged.detach(this.handleChange);
    }
    renderProjectSize(project) {
        const storageUsageInBytes = project.storageUsageInBytes != null ? bytes(project.storageUsageInBytes) : '0';
        const maxSize = ProjectEntity.getMaxSize(project.projectSubscription);
        const percent = project.storageUsageInBytes != null ? Math.round((project.storageUsageInBytes / maxSize) * 100) : 0;
        const barColor = percent > 75 ? ThemeManager.style.brandDanger : ThemeManager.style.brandPrimary;
        return (<View style={{ marginTop: 16, width: '100%' }}>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectSize')}</MaterialText>
        <View style={{
                width: '100%',
                maxWidth: 200,
                height: 7,
                backgroundColor: '#EBEBEB',
                borderRadius: 10,
                margin: 2,
            }}>
          <View style={{
                height: 7,
                borderRadius: 10,
                width: `${percent}%`,
                backgroundColor: barColor,
            }}/>
        </View>
        <MaterialText type={MaterialTextTypes.Caption}>
          {storageUsageInBytes} / {bytes(maxSize)}
        </MaterialText>
      </View>);
    }
    render() {
        const { styles } = this.props;
        const { project } = this.state;
        if (project == null) {
            return null;
        }
        return (<ScrollView style={[
                styles,
                {
                    width: '100%',
                    height: '100%',
                },
            ]} contentContainerStyle={{ padding: 16 }} nestedScrollEnabled>
        {this.renderProjectTitle(project)}

        <View style={{ width: '100%' }}>
          <ProjectTemplateStatus project={project}/>
          {this.renderProjectSubscription(project)}
          {this.renderProjectType(project)}
          {this.renderProjectPeriod(project)}
          {this.renderProjectBuildingPeriod(project)}
          {this.renderProjectStateChip(project)}
          {this.renderProjectDescription(project)}
          {this.renderOfflineAccessibility(project)}
          {this.renderProjectSize(project)}
        </View>
      </ScrollView>);
    }
    renderProjectBuildingPeriod(project) {
        if (project.constructionStart == null && project.constructionEnd == null) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectBuildingPeriod')}</MaterialText>
        </View>
        <MaterialText>
          {project.constructionStart == null ? '' : I18n.m.dateCurrent.localeDateString(project.constructionStart)}

          {project.constructionStart != null && project.constructionEnd != null ? ' ' : ''}

          {project.constructionEnd == null
                ? ''
                : `${I18n.m.getMessage('till')} ${I18n.m.dateCurrent.localeDateString(project.constructionEnd)}`}
        </MaterialText>
      </View>);
    }
    renderOfflineAccessibility(project) {
        let textRight = '';
        let icon = {
            icon: 'cloud-outline',
            toolTip: I18n.m.getMessage('projectDownloadOfflineUnavailable'),
            onPress: this.openHowToDownloadDialog,
        };
        if (Platform.OS !== 'web') {
            const { offlineProject } = this.state;
            textRight = '';
            icon = {
                icon: 'cloud-outline',
                toolTip: I18n.m.getMessage('projectDownloadOfflineUnavailable'),
                onPress: this.openDownloadDialog(project),
            };
            if (offlineProject != null) {
                if (offlineProject.status !== 'removed') {
                    if (offlineProject.status === 'isSyncing' && offlineProject.percentSynced != null) {
                        textRight = `${offlineProject.percentSynced}%`;
                        icon = {
                            icon: 'cloud-sync-outline',
                            toolTip: I18n.m.getMessage('projectDownloadOfflineAvailableSyncing'),
                            onPress: this.openDownloadDialog(project),
                        };
                    }
                    else {
                        textRight = '';
                        icon = {
                            icon: 'cloud-check-outline',
                            toolTip: I18n.m.getMessage('projectDownloadOfflineAvailable'),
                            onPress: this.openDownloadDialog(project),
                        };
                    }
                }
            }
        }
        return (<Ripple onPress={icon.onPress} style={{ flexDirection: 'row', height: 36, alignItems: 'center', marginTop: 16 }}>
        <MaterialText centeredBox>{I18n.m.getMessage('projectDownload')}</MaterialText>
        <Icon {...icon}/>
        <MaterialText type={MaterialTextTypes.Caption} centeredBox>
          {textRight}
        </MaterialText>
      </Ripple>);
    }
    renderProjectStateChip(project) {
        if (CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.projectStateNames.length > 0) {
            return (<View style={{ marginTop: 16 }}>
          <CompanyProjectStatePicker changeDirectly projectId={project.id}/>
        </View>);
        }
        return null;
    }
    renderProjectDescription(project) {
        if (project.description == null || project.description.length === 0) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectDescription')}</MaterialText>
        </View>
        <MaterialText recognizeUrl>{project.description}</MaterialText>
      </View>);
    }
    renderProjectPeriod(project) {
        if (project.projectStart == null && project.projectEnd == null) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectPeriod')}</MaterialText>
        </View>
        <MaterialText>
          {project.projectStart == null ? '' : I18n.m.dateCurrent.localeDateString(project.projectStart)}

          {project.projectStart != null && project.projectEnd != null ? ' ' : ''}

          {project.projectEnd == null
                ? ''
                : `${I18n.m.getMessage('till')} ${I18n.m.dateCurrent.localeDateString(project.projectEnd)}`}
        </MaterialText>
      </View>);
    }
    renderProjectTitle(project) {
        return (<View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
            }}>
        <View style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                paddingRight: 32,
            }}>
          <ProjectImage project={project} size={40} lightBox/>
          <View style={{ minHeight: 34, marginLeft: 8, paddingRight: 48, maxWidth: '100%' }}>
            <MaterialText type={MaterialTextTypes.H5} color={ThemeManager.style.black87} fixedWidth="100%">
              {project.title}
            </MaterialText>
          </View>
        </View>
        <View style={{ position: 'absolute', alignSelf: 'stretch', top: 0, right: 0 }}>
          <Icon toolTip={I18n.m.getMessage('tooltipEditProjectDetails')} onPress={this.goToProjectSettings} icon="cog-outline" color={ThemeManager.style.defaultIconColor}/>
        </View>
      </View>);
    }
    renderProjectType(project) {
        if (project.projectType == null || project.projectType.length === 0) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectType')}</MaterialText>
        </View>
        <MaterialText>{project.projectType}</MaterialText>
      </View>);
    }
    renderProjectSubscription(project) {
        let subscription = 'basic';
        if (project.projectSubscription != null && project.projectSubscription.length > 0) {
            subscription = project.projectSubscription;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectSubscription')}</MaterialText>
        </View>
        <MaterialText>{I18n.m.getMessage(`${subscription}Project`)}</MaterialText>
        {this.getUpgradeButton()}
      </View>);
    }
    getUpgradeButton() {
        const { project, userSubscription } = this.state;
        if (project == null || userSubscription == null)
            return null;
        return <UpgradeButton project={project} userSubscription={userSubscription}/>;
    }
}

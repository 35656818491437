var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebImageLoader = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _tsEvents = require("ts-events");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _a;
var WebImageLoader = exports.WebImageLoader = function () {
  function WebImageLoader() {
    (0, _classCallCheck2.default)(this, WebImageLoader);
  }
  return (0, _createClass2.default)(WebImageLoader, null, [{
    key: "next",
    value: function next() {
      _a.loadNextWebImageWithHeader().catch(function (err) {});
    }
  }, {
    key: "loadNextWebImageWithHeader",
    value: function () {
      var _loadNextWebImageWithHeader = (0, _asyncToGenerator2.default)(function* () {
        var uri = '';
        try {
          if (_a.loadImages.size > 0 && _a.loadingImages.size <= _a.maxParallel) {
            var k = _a.loadImages.keys().next().value;
            var props = _a.loadImages.get(k);
            _a.loadImages.delete(k);
            if (props == null) throw new Error('no thing found');
            _a.loadingImages.add(props.uri);
            uri = props.uri;
            var headers = {};
            if ((props.auth == null || props.auth) && _CurrentUser.CurrentUser.token != null && _CurrentUser.CurrentUser.token.length > 0) headers['authorization'] = `Bearer ${_CurrentUser.CurrentUser.token}`;
            var res = yield fetch(props.uri, {
              method: 'GET',
              headers: headers
            });
            var content = yield res.blob();
            var reader = new FileReader();
            yield new Promise(function (resolve, reject) {
              reader.onload = function (r) {
                var t = r.currentTarget;
                if (t != null && t.result != null && (t.result.toString().substr(0, 10) === 'data:image' || t.result.toString().substr(0, 29) === 'data:application/octet-stream')) {
                  _a.webcache[props['uri']] = t.result.toString();
                  _a.loadingImages.delete(props.uri);
                  _a.loadedImage.post({
                    uri: props.uri,
                    result: t.result.toString()
                  });
                  _a.next();
                  resolve();
                } else {
                  _a.loadedImage.post({
                    uri: props.uri,
                    error: new Error('no result')
                  });
                  _a.loadingImages.delete(props.uri);
                  reject(new Error('no result'));
                }
              };
              reader.onerror = function (e) {
                if (e['statusCode'] === '401') _AuthClient.AuthClient.instance.validateToken('image error').catch(function (err) {
                  return void 0;
                });
                _a.loadedImage.post({
                  uri: props.uri,
                  error: e
                });
                _a.loadingImages.delete(props.uri);
                reject(e);
              };
              reader.readAsDataURL(content);
            });
          }
        } catch (e) {
          _a.loadingImages.delete(uri);
          _a.loadedImage.post({
            uri: uri,
            error: e
          });
          throw e;
        }
      });
      function loadNextWebImageWithHeader() {
        return _loadNextWebImageWithHeader.apply(this, arguments);
      }
      return loadNextWebImageWithHeader;
    }()
  }]);
}();
_a = WebImageLoader;
WebImageLoader.webcache = {};
WebImageLoader.loadingImages = new Set();
WebImageLoader.loadImages = new Map();
WebImageLoader.loadedImage = new _tsEvents.AsyncEvent();
WebImageLoader.maxParallel = 5;
WebImageLoader.loadWebImageWithHeader = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (sources, props, connection, onLoadError, controller) {
    var source = sources.source;
    if (source.uri.startsWith('data:')) {
      return {
        webSource: source.uri,
        notReachable: false,
        notFound: false
      };
    }
    if (_a.webcache[source['uri']] != null) {
      return {
        webSource: _a.webcache[source['uri']],
        notReachable: false,
        notFound: false
      };
    }
    if (!_a.loadImages.has(source.uri) && !_a.loadingImages.has(source.uri)) {
      if (controller != null && controller.signal.aborted) {
        _a.loadingImages.delete(source.uri);
        return Promise.reject(new Error('AbortError'));
      }
      if (props.localSource == null && props.onlyOnConnectedServer && !connection.connectedToServer) {
        _a.loadingImages.delete(source.uri);
        onLoadError(new Error('no connection'));
        throw new Error('no connection');
      }
      _a.loadImages.set(source.uri, {
        uri: source.uri,
        auth: props.auth
      });
    }
    return new Promise(function (r) {
      var _res = function res(_id) {
        if (_a.webcache[source['uri']] != null) {
          r({
            webSource: _a.webcache[source['uri']],
            notReachable: false,
            notFound: false
          });
          _a.loadedImage.detach(_res);
        }
      };
      _a.loadedImage.attach(_res);
      _a.next();
    });
  });
  return function (_x, _x2, _x3, _x4, _x5) {
    return _ref.apply(this, arguments);
  };
}();
import _ from 'lodash';
import { AddressCardForm } from 'materialTheme/src/theme/components/addressCard/AddressCardForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import React, { useEffect, useState } from 'react';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { ChangeProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/ChangeProjectAddress';
import { CreateProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/CreateProjectAddress';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ConfigAll } from '../../../config/ConfigAll';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
export function ProjectAddressItemOptions(props) {
    const [address, setAddress] = useState();
    const [loading, setLoading] = useState(true);
    const loadAddress = async () => {
        const addressId = props.initValues ? props.initValues.addressId : undefined;
        if (addressId != null) {
            const a = await UpmeshClient.instance.modals.projectAddress.getById(addressId);
            setAddress(a);
        }
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        loadAddress().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    }, []);
    const onCancel = () => {
        Dialog.instance?.close();
    };
    const saveAddress = async (newAddress) => {
        const { projectId } = props;
        if (address != null && !_.isEqual(newAddress, address.address)) {
            const c = new ChangeProjectAddress({ address: newAddress }, address.id);
            await c.execute();
            return c.entityId;
        }
        if (address == null && projectId != null) {
            const c = new CreateProjectAddress({
                type: 'contact',
                address: newAddress,
                projectId,
            });
            await c.execute();
            return c.entityId;
        }
        return null;
    };
    const onSave = (address) => {
        setLoading(true);
        saveAddress(address)
            .then((id) => {
            setLoading(false);
            if (id)
                props.onSaved({ addressId: id, type: 'contact' });
            else
                Dialog.instance?.close();
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    return (<AddressCardForm address={address != null && address.address != null
            ? new AddressEntity(address.address)
            : new AddressEntity({ longitude: 10, latitude: 50 })} backendURL={`${ConfigAll.b2cURL}/backend/googleapis`} key={address != null ? address.id : 'new'} onCancel={onCancel} showContactFunction title={props.initValues?.addressId != null
            ? I18n.m.getMessage('projectContactWidgetChangeTitle')
            : I18n.m.getMessage('projectContactWidgetAddTitle')} change subTitle={props.subTitle} loading={loading} onSave={onSave}/>);
}

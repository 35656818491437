"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteRegion = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var HolidayRegionsChanged_1 = require("../../../events/company/holiday/HolidayRegionsChanged");
var RegionDeleted_1 = require("../../../events/company/region/RegionDeleted");
var UserChangedRegion_1 = require("../../../events/company/region/UserChangedRegion");
var DeleteRegion = function (_CommandRoot_1$Comman) {
  function DeleteRegion(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteRegion);
    _this = _callSuper(this, DeleteRegion, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'DeleteRegion';
    return _this;
  }
  (0, _inherits2.default)(DeleteRegion, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteRegion, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        this.region = yield CommandReadModels_1.CommandReadModels.instance.region.getById(this.entityId);
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        this.company = yield companies.getById(this.region.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, this.company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var companyMembers = ReadModels_1.ReadModels.get('CompanyMember');
        var companyUsers = yield companyMembers.get({
          filter: `companyId eq '${this.company.id}' and deleted ne true and regionId eq '${this.region.id}'`
        });
        var events = [];
        for (var user of companyUsers) {
          events.push(new UserChangedRegion_1.UserChangedRegion(user.id, this.company.id, {
            regionId: ''
          }));
        }
        events.push(new RegionDeleted_1.RegionDeleted(this.entityId, Object.assign({}, this.data), this.region.companyId));
        var holidays = yield CommandReadModels_1.CommandReadModels.instance.holiday.get({
          filter: `companyId eq '${this.company.id}' and deleted ne true`
        });
        holidays.forEach(function (holiday) {
          if (holiday.regionIds.findIndex(function (e) {
            return e === _this2.entityId;
          })) {
            var newRegionIds = holiday.regionIds.filter(function (e) {
              return e === _this2.entityId;
            });
            events.push(new HolidayRegionsChanged_1.HolidayRegionsChanged(holiday.id, {
              regionIds: newRegionIds
            }, _this2.company.id));
          }
        });
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteRegion = DeleteRegion;
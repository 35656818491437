"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Visitor = void 0;
var odata_v4_literal_1 = require("odata-v4-literal");
var Visitor = function () {
  function Visitor() {
    (0, _classCallCheck2.default)(this, Visitor);
    this.query = {};
    this.sort = {};
    this.projection = {};
    this.includes = [];
    var ast;
    Object.defineProperty(this, 'ast', {
      get: function get() {
        return ast;
      },
      set: function set(v) {
        ast = v;
      },
      enumerable: false
    });
  }
  return (0, _createClass2.default)(Visitor, [{
    key: "Visit",
    value: function Visit(node, context) {
      this.ast = this.ast || node;
      context = context || {};
      if (node) {
        var visitor = this[`Visit${node.type}`];
        if (visitor) visitor.call(this, node, context);
      }
      return this;
    }
  }, {
    key: "VisitODataUri",
    value: function VisitODataUri(node, context) {
      this.Visit(node.value.resource, context);
      this.Visit(node.value.query, context);
    }
  }, {
    key: "VisitEntitySetName",
    value: function VisitEntitySetName(node, _context) {
      this.collection = node.value.name;
    }
  }, {
    key: "VisitExpand",
    value: function VisitExpand(node, _context) {
      var _this = this;
      var innerContexts = {};
      node.value.items.forEach(function (item) {
        var expandPath = item.value.path.raw;
        var innerVisitor = _this.includes.filter(function (v) {
          return v.navigationProperty === expandPath;
        })[0];
        if (!innerVisitor) {
          innerVisitor = new Visitor();
          innerContexts[expandPath] = {
            query: {},
            sort: {},
            projection: {},
            options: {}
          };
          _this.includes.push(innerVisitor);
        }
        var innerContext = innerContexts[expandPath] || {};
        innerVisitor.Visit(item, innerContext);
        innerVisitor.query = innerContext.query || innerVisitor.query || {};
        innerVisitor.sort = innerContext.sort || innerVisitor.sort;
        innerVisitor.projection = innerContext.projection || innerVisitor.projection;
      });
    }
  }, {
    key: "VisitExpandItem",
    value: function VisitExpandItem(node, context) {
      var _this2 = this;
      this.Visit(node.value.path, context);
      if (node.value.options != null) node.value.options.forEach(function (item) {
        return _this2.Visit(item, context);
      });
    }
  }, {
    key: "VisitExpandPath",
    value: function VisitExpandPath(node, _context) {
      this.navigationProperty = node.raw;
    }
  }, {
    key: "VisitQueryOptions",
    value: function VisitQueryOptions(node, context) {
      var _this3 = this;
      context.options = {};
      node.value.options.forEach(function (option) {
        return _this3.Visit(option, context);
      });
      this.query = context.query || {};
      delete context.query;
      this.sort = context.sort;
      delete context.sort;
    }
  }, {
    key: "VisitInlineCount",
    value: function VisitInlineCount(node, _context) {
      this.inlinecount = odata_v4_literal_1.Literal.convert(node.value.value, node.value.raw);
    }
  }, {
    key: "VisitFilter",
    value: function VisitFilter(node, context) {
      context.query = {};
      this.Visit(node.value, context);
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitOrderBy",
    value: function VisitOrderBy(node, context) {
      var _this4 = this;
      context.sort = {};
      node.value.items.forEach(function (item) {
        return _this4.Visit(item, context);
      });
    }
  }, {
    key: "VisitSkip",
    value: function VisitSkip(node, _context) {
      this.skip = +node.value.raw;
    }
  }, {
    key: "VisitTop",
    value: function VisitTop(node, _context) {
      this.limit = +node.value.raw;
    }
  }, {
    key: "VisitOrderByItem",
    value: function VisitOrderByItem(node, context) {
      this.Visit(node.value.expr, context);
      if (context.identifier) context.sort[context.identifier] = node.value.direction;
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitSelect",
    value: function VisitSelect(node, context) {
      var _this5 = this;
      context.projection = {};
      node.value.items.forEach(function (item) {
        return _this5.Visit(item, context);
      });
      this.projection = context.projection;
      delete context.projection;
    }
  }, {
    key: "VisitSelectItem",
    value: function VisitSelectItem(node, context) {
      context.projection[node.raw.replace(/\//g, '.')] = 1;
    }
  }, {
    key: "VisitAndExpression",
    value: function VisitAndExpression(node, context) {
      var query = context.query;
      var leftQuery = {};
      context.query = leftQuery;
      this.Visit(node.value.left, context);
      var rightQuery = {};
      context.query = rightQuery;
      this.Visit(node.value.right, context);
      if (Object.keys(leftQuery).length > 0 && Object.keys(rightQuery).length > 0) {
        query.$and = [leftQuery, rightQuery];
      }
      context.query = query;
    }
  }, {
    key: "VisitOrExpression",
    value: function VisitOrExpression(node, context) {
      var query = context.query;
      var leftQuery = {};
      context.query = leftQuery;
      this.Visit(node.value.left, context);
      var rightQuery = {};
      context.query = rightQuery;
      this.Visit(node.value.right, context);
      if (Object.keys(leftQuery).length > 0 && Object.keys(rightQuery).length > 0) {
        query.$or = [leftQuery, rightQuery];
      }
      context.query = query;
    }
  }, {
    key: "VisitBoolParenExpression",
    value: function VisitBoolParenExpression(node, context) {
      this.Visit(node.value, context);
    }
  }, {
    key: "VisitCommonExpression",
    value: function VisitCommonExpression(node, context) {
      this.Visit(node.value, context);
    }
  }, {
    key: "VisitFirstMemberExpression",
    value: function VisitFirstMemberExpression(node, context) {
      this.Visit(node.value, context);
    }
  }, {
    key: "VisitMemberExpression",
    value: function VisitMemberExpression(node, context) {
      this.Visit(node.value, context);
    }
  }, {
    key: "VisitPropertyPathExpression",
    value: function VisitPropertyPathExpression(node, context) {
      if (node.value.current && node.value.next) {
        this.Visit(node.value.current, context);
        if (context.identifier) context.identifier += '.';
        this.Visit(node.value.next, context);
      } else this.Visit(node.value, context);
    }
  }, {
    key: "VisitSingleNavigationExpression",
    value: function VisitSingleNavigationExpression(node, context) {
      if (node.value.current && node.value.next) {
        this.Visit(node.value.current, context);
        this.Visit(node.value.next, context);
      } else this.Visit(node.value, context);
    }
  }, {
    key: "VisitODataIdentifier",
    value: function VisitODataIdentifier(node, context) {
      context.identifier = (context.identifier || '') + node.value.name;
    }
  }, {
    key: "VisitNotExpression",
    value: function VisitNotExpression(node, context) {
      this.Visit(node.value, context);
      if (context.query) {
        for (var prop in context.query) {
          context.query[prop] = {
            $not: context.query[prop]
          };
        }
      }
    }
  }, {
    key: "VisitEqualsExpression",
    value: function VisitEqualsExpression(node, context) {
      this.Visit(node.value.left, context);
      this.Visit(node.value.right, context);
      if (context.identifier) {
        if (context.literal == null) {
          context.query['$or'] = [(0, _defineProperty2.default)({}, context.identifier, {
            $exists: false
          }), (0, _defineProperty2.default)({}, context.identifier, null)];
        } else context.query[context.identifier] = context.literal;
      }
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitNotEqualsExpression",
    value: function VisitNotEqualsExpression(node, context) {
      this.Visit(node.value.left, context);
      this.Visit(node.value.right, context);
      if (context.identifier) context.query[context.identifier] = {
        $ne: context.literal
      };
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitInExpression",
    value: function VisitInExpression(node, context) {
      this.Visit(node.value.left, context);
      this.Visit(node.value.right, context);
      if (context.identifier) context.query[context.identifier] = {
        $in: context.literal
      };
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitLesserThanExpression",
    value: function VisitLesserThanExpression(node, context) {
      this.Visit(node.value.left, context);
      this.Visit(node.value.right, context);
      if (context.identifier) context.query[context.identifier] = {
        $lt: context.literal
      };
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitLesserOrEqualsExpression",
    value: function VisitLesserOrEqualsExpression(node, context) {
      this.Visit(node.value.left, context);
      this.Visit(node.value.right, context);
      if (context.identifier) context.query[context.identifier] = {
        $lte: context.literal
      };
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitGreaterThanExpression",
    value: function VisitGreaterThanExpression(node, context) {
      this.Visit(node.value.left, context);
      this.Visit(node.value.right, context);
      if (context.identifier) context.query[context.identifier] = {
        $gt: context.literal
      };
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitGreaterOrEqualsExpression",
    value: function VisitGreaterOrEqualsExpression(node, context) {
      this.Visit(node.value.left, context);
      this.Visit(node.value.right, context);
      if (context.identifier) context.query[context.identifier] = {
        $gte: context.literal
      };
      delete context.identifier;
      delete context.literal;
    }
  }, {
    key: "VisitArrayOrObject",
    value: function VisitArrayOrObject(node, context) {
      context.literal = JSON.parse(node.raw);
    }
  }, {
    key: "VisitLiteral",
    value: function VisitLiteral(node, context) {
      context.literal = odata_v4_literal_1.Literal.convert(node.value, node.raw);
    }
  }, {
    key: "VisitMethodCallExpression",
    value: function VisitMethodCallExpression(node, context) {
      var _this6 = this;
      var method = node.value.method;
      (node.value.parameters || []).forEach(function (p) {
        return _this6.Visit(p, context);
      });
      if (context.identifier) {
        var flags = Visitor.regExWithFlag ? 'gi' : undefined;
        switch (method) {
          case 'contains':
            context.query[context.identifier] = {
              $regex: new RegExp(context.literal, flags)
            };
            break;
          case 'endswith':
            context.query[context.identifier] = {
              $regex: new RegExp(`${context.literal}$`, flags)
            };
            break;
          case 'startswith':
            context.query[context.identifier] = {
              $regex: new RegExp(`^${context.literal}`, flags)
            };
            break;
          default:
            throw new Error('Method call not implemented.');
        }
        delete context.identifier;
      }
    }
  }]);
}();
exports.Visitor = Visitor;
Visitor.regExWithFlag = true;
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CopyProject } from 'upmesh-core/src/client/commands/project/CopyProject';
import { I18n } from '../../../i18n/I18n';
export class CopyProjectDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close(() => {
                requestAnimationFrame(() => {
                    Toast.instance?.open({
                        title: I18n.m.getMessage('projectCopyProjectSuccessToast'),
                    });
                });
            });
        };
    }
    static openDialog(project) {
        Dialog.instance?.open({
            scrollable: true,
            showCloseIcon: true,
            contentPadding: false,
            content: <CopyProjectDialog projectId={project.id}/>,
        });
    }
    render() {
        const { projectId } = this.props;
        const currentData = {
            projectId,
            newProjectName: '',
            copyMember: true,
            copyPlans: true,
            copyTickets: true,
            copyFolderAndFiles: true,
            copyJournalSettings: true,
        };
        const dataOptions = new Map();
        dataOptions.set('newProjectName', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectCopyProjectDialogProjectTitle'),
                key: 'newProjectName',
            },
        });
        dataOptions.set('text', {
            formType: 'text',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectCopyProjectDialogProjectCopyText'),
                key: 'projectText',
            },
        });
        dataOptions.set('copyMember', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectCopyProjectDialogProjectCopyMember'),
                key: 'copyMember',
                dense: true,
            },
        });
        dataOptions.set('copyPlans', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectCopyProjectDialogProjectCopyPlans'),
                key: 'copyPlans',
                dense: true,
            },
        });
        dataOptions.set('copyTickets', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectCopyProjectDialogProjectCopyTickets'),
                key: 'copyTickets',
                dense: true,
            },
        });
        dataOptions.set('copyFolderAndFiles', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectCopyProjectDialogProjectFolderAndFiles'),
                key: 'copyFolderAndFiles',
                dense: true,
            },
        });
        dataOptions.set('copyJournalSettings', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectCopyProjectDialogProjectJournalSettings'),
                key: 'copyJournalSettings',
                dense: true,
            },
        });
        return (<Form key="CopyProjectForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('projectCopyProject'),
            }} stickyActions={false} command={new CopyProject(currentData)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('projectCopyProjectDialogButton')} saveButtonTextColor="#FFFFFF" saveButtonColor={ThemeManager.style.brandPrimary} additionalButtons={[
                <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

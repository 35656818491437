"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.I18n = void 0;
var I18n = (0, _createClass2.default)(function I18n() {
  (0, _classCallCheck2.default)(this, I18n);
});
exports.I18n = I18n;
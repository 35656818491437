import { Checkbox } from 'materialTheme/src/theme/components/Checkbox';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import useComponentSize from 'materialTheme/src/theme/hooks/UseComponentSize';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { ChangeProjectNotificationSettings, } from 'upmesh-core/src/client/commands/settings/ChangeProjectNotificationSettings';
import { ProjectFilter } from 'upmesh-core/src/client/query/filter/ProjectFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function ProjectNotifications(props) {
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [searchBarText, setSearchBarText] = useState('');
    const [cardSize, onCardSize] = useComponentSize();
    const loadProjects = () => {
        UpmeshClient.instance.modals.project
            .get({
            filter: `deleted ne true`,
        })
            .then((resultProjects) => {
            setProjects(resultProjects);
            if (searchBarText.length === 0) {
                setFilteredProjects(resultProjects);
            }
        })
            .catch((err) => {
            console.debug('could not load projects for notifications', err);
        });
    };
    const filterProjects = (searchText) => {
        setSearchBarText(searchText);
        setFilteredProjects(ProjectFilter.filterProjectsByText(searchText, projects));
    };
    const changeAutoAddNew = (e) => {
        const data = {
            autoAddNewProjects: e,
            excludedProjectIds: [],
        };
        if (e) {
            projects.forEach((current) => {
                if (!props.settings.notifyEnabled(current.id)) {
                    data.excludedProjectIds.push(current.id);
                }
            });
        }
        else {
            projects.forEach((current) => {
                if (props.settings.notifyEnabled(current.id)) {
                    data.excludedProjectIds.push(current.id);
                }
            });
        }
        const cChangeSettings = new ChangeProjectNotificationSettings(data);
        cChangeSettings.execute().catch((err) => {
            console.error('could not change project notification settings', err);
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const projectKeyExtractor = (project) => {
        return project.id;
    };
    const changeSettingsForProject = (e, project) => {
        const data = {
            autoAddNewProjects: props.settings.autoAddNewProjects,
            excludedProjectIds: [],
        };
        if ((props.settings.autoAddNewProjects && e) || (!props.settings.autoAddNewProjects && !e)) {
            props.settings.excludedProjectIds.forEach((current) => {
                if (current !== project.id) {
                    data.excludedProjectIds.push(current);
                }
            });
        }
        else {
            data.excludedProjectIds = [...props.settings.excludedProjectIds, project.id];
        }
        const cChangeSettings = new ChangeProjectNotificationSettings(data);
        cChangeSettings.execute().catch((err) => {
            console.error('could not change project notification settings', err);
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    useEffect(() => {
        loadProjects();
        const projectAttachKey = UpmeshClient.eventDispatcher.attach({
            readModelName: 'Project',
            callback: loadProjects,
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('Project', projectAttachKey);
        };
    }, []);
    const renderProjectRow = ({ item }) => {
        return (<View style={{ flexDirection: 'row', paddingBottom: ThemeManager.style.contentPaddingValue }}>
        <Checkbox key={`notifications_${item.id}_${props.settings.notifyEnabled(item.id)}`} value={props.settings.notifyEnabled(item.id)} onChange={(e) => changeSettingsForProject(e, item)}/>
        <MaterialText centeredBox>{item.title}</MaterialText>
      </View>);
    };
    return (<View>
      <View style={{ paddingBottom: 0 }}>
        <MaterialText strong>{I18n.m.getMessage('projects')}</MaterialText>
      </View>
      <View style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingBottom: ThemeManager.style.contentPaddingValue,
        }}>
        <MaterialText centeredBox>{I18n.m.getMessage('notificationsSettingsAutoAddNew')}</MaterialText>
        <Switch key={`autoAddNewProjects_${props.settings.autoAddNewProjects}`} value={props.settings['autoAddNewProjects']} onPress={changeAutoAddNew}/>
      </View>
      <View style={{
            width: '100%',
            paddingBottom: ThemeManager.style.contentPaddingValue,
        }} onLayout={onCardSize}>
        <SearchBar maxWidth={Math.max(200, cardSize.width)} searchBarValue={searchBarText} searchOnChange={filterProjects}/>
      </View>
      <FlatList style={{ maxHeight: 300 }} key="projectNotifications" keyExtractor={projectKeyExtractor} data={filteredProjects} renderItem={renderProjectRow}/>
    </View>);
}

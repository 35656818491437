import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
export class Redirect extends PureComponent {
    componentDidMount() {
        window.setTimeout(() => {
            Routing.instance.goTo(this.props.to, this.props.replace);
        }, 300);
    }
    render() {
        return <View />;
    }
}
Redirect.defaultProps = {
    searchObject: {},
    replace: false,
};

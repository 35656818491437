"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdateCompanyMessage = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CompanyMessageUpdated_1 = require("../../events/company/CompanyMessageUpdated");
var UpdateCompanyMessage = function (_CommandRoot_1$Comman) {
  function UpdateCompanyMessage(privatedata, companyId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, UpdateCompanyMessage);
    _this = _callSuper(this, UpdateCompanyMessage, [privatedata, companyId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'UpdateCompanyMessage';
    return _this;
  }
  (0, _inherits2.default)(UpdateCompanyMessage, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(UpdateCompanyMessage, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        if (userId.startsWith('company')) {
          var companyId = userId.substr(7);
          if (companyId === this.entityId) return true;
        }
        var companyMembers = ReadModels_1.ReadModels.get('CompanyMember');
        var companyMember = yield companyMembers.get({
          filter: `userId eq '${userId}' and deleted ne true and companyId eq '${this.entityId}'`
        });
        if (companyMember.length > 0) this.companyMember = companyMember[0];
        if (this.companyMember == null || this.companyMember.companyId !== this.entityId) {
          throw {
            message: 'badRequest',
            messageCode: 'forbidden'
          };
        }
        var companies = ReadModels_1.ReadModels.get('Company');
        this.company = yield companies.getById(this.entityId);
        var companiesSettings = ReadModels_1.ReadModels.get('CompanySettings');
        this.companySettings = yield companiesSettings.getById(this.entityId);
        if (!(yield RightsManager_1.RightsManager.isCompanyAdmin(userId, this.entityId))) throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "clone",
    value: function clone(newData, entityId, token) {
      return new UpdateCompanyMessage(newData, entityId, token);
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        if (this.companySettings.messagesOverride != null && this.companySettings.messagesOverride[this.data.key] === this.data.value) return [];
        return new CompanyMessageUpdated_1.CompanyMessageUpdated(this.entityId, {
          key: this.data.key,
          value: this.data.value
        }, this.entityId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        if (key == null || key === 'key') {
          if (this.data.key == null || this.data.key.length === 0) {
            errors.push({
              name: 'key',
              key: 'key',
              message: 'Schlüssel wird benötigt',
              messageCode: 'required'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.UpdateCompanyMessage = UpdateCompanyMessage;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteGroup = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var GroupDeleted_1 = require("../../events/company/group/GroupDeleted");
var UserAddedToGroup_1 = require("../../events/company/group/UserAddedToGroup");
var DeleteGroup = function (_CommandRoot_1$Comman) {
  function DeleteGroup(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteGroup);
    _this = _callSuper(this, DeleteGroup, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'DeleteGroup';
    return _this;
  }
  (0, _inherits2.default)(DeleteGroup, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteGroup, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        this.group = yield CommandReadModels_1.CommandReadModels.instance.group.getById(this.entityId);
        var companySettings = ReadModels_1.ReadModels.get('CompanySettings');
        var cSettings = yield companySettings.getById(this.group.companyId);
        if (this.group.id === cSettings.defaultGroupId) {
          throw {
            message: 'Standard Gruppe kann nicht gelöscht werden',
            messageCode: 'companyGroupNoDeleteDefault'
          };
        }
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        this.company = yield companies.getById(this.group.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, this.company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var companyMembers = ReadModels_1.ReadModels.get('CompanyMember');
        var companyMember = yield companyMembers.get({
          filter: `companyId eq '${this.company.id}' and deleted ne true`
        });
        var companyUsers = companyMember.filter(function (user) {
          return user.groupId === _this2.group.id;
        }) || [];
        var companySettings = yield CommandReadModels_1.CommandReadModels.instance.companySettings.getById(this.company.id);
        var events = [];
        for (var user of companyUsers) {
          events.push(new UserAddedToGroup_1.UserAddedToGroup(user.id, this.company.id, {
            groupId: companySettings.defaultGroupId
          }));
        }
        events.push(new GroupDeleted_1.GroupDeleted(this.entityId, Object.assign({}, this.data), this.group.companyId));
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteGroup = DeleteGroup;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserImage = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _CacheableImage = require("../../../theme/components/images/CacheableImage");
var _ThemeManager = require("../../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var UserImage = exports.UserImage = function (_PureComponent) {
  function UserImage(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, UserImage);
    _this = _callSuper(this, UserImage, [props, context]);
    _this.onImageLoadError = function (_e) {
      _this.setState({
        loadError: true
      });
    };
    _this.onImageNotFound = function () {
      _this.setState({
        loadError: true
      });
    };
    _this.url = props.backendUrl != null ? props.backendUrl : _AuthClient.AuthClient.instance.url;
    var user = _this.props.user;
    var userImageLink;
    if (user != null) {
      userImageLink = user.userImageFileId != null && user.userImageFileId.length > 0 ? `${_this.url}/user/${user.userId}/image/${user.userImageFileId}` : undefined;
    }
    _this.state = {
      userImageLink: userImageLink,
      loadError: false
    };
    return _this;
  }
  (0, _inherits2.default)(UserImage, _PureComponent);
  return (0, _createClass2.default)(UserImage, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        size = _this$props.size,
        badge = _this$props.badge,
        badgeColor = _this$props.badgeColor;
      var badgeSize = 16;
      if (badge == null || badge.length === 0) return this.renderImage();
      var extraTopPadding = _reactNativeWeb.Platform.OS !== 'web' ? {
        paddingTop: '10%'
      } : {};
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: size,
          height: size,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'visible'
        },
        children: [this.renderImage(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            top: -2,
            right: -2,
            width: badgeSize,
            height: badgeSize,
            backgroundColor: badgeColor != null ? badgeColor : _ThemeManager.ThemeManager.style.brandPrimary,
            borderRadius: badgeSize / 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'visible'
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Text, {
            style: Object.assign({
              width: '100%',
              color: '#FFFFFF',
              textAlign: 'center',
              fontSize: badgeSize - 2,
              lineHeight: badgeSize - 2,
              fontFamily: 'roboto'
            }, extraTopPadding),
            allowFontScaling: true,
            adjustsFontSizeToFit: true,
            numberOfLines: 1,
            children: badge
          })
        })]
      });
    }
  }, {
    key: "renderImage",
    value: function renderImage() {
      var _this$props2 = this.props,
        size = _this$props2.size,
        user = _this$props2.user,
        lightBox = _this$props2.lightBox;
      var _this$state = this.state,
        userImageLink = _this$state.userImageLink,
        loadError = _this$state.loadError;
      if (!loadError && userImageLink != null && userImageLink.length > 0) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: size,
            height: size,
            backgroundColor: user != null ? user.colorCode : _ThemeManager.ThemeManager.style.brandPrimary,
            borderRadius: size / 2,
            justifyContent: 'center',
            alignItems: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_CacheableImage.CacheableImage, {
            onlyOnConnectedServer: false,
            width: size,
            height: size,
            style: {
              borderRadius: size / 2
            },
            lightBox: lightBox,
            source: {
              uri: userImageLink
            },
            onError: this.onImageLoadError,
            resizeMode: "cover",
            onNotFound: this.onImageNotFound
          }, `${user != null ? user.userImageFileId : 'empty'}_${size}`)
        });
      }
      var initialien = '?';
      if (user != null && user.firstname != null && user.firstname.length >= 1) {
        initialien = user.firstname.substr(0, 1);
      }
      if (user != null && user.lastname != null && user.lastname.length >= 1) {
        initialien += user.lastname.substr(0, 1);
      }
      var extraTopPadding = _reactNativeWeb.Platform.OS !== 'web' ? {
        paddingTop: '10%'
      } : {};
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: size,
          height: size,
          backgroundColor: user != null && user.colorCode != null ? user.colorCode : _ThemeManager.ThemeManager.style.brandPrimary,
          borderRadius: size / 2,
          justifyContent: 'center',
          alignItems: 'center'
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Text, {
          style: Object.assign({
            width: '100%',
            color: '#FFFFFF',
            textAlign: 'center',
            fontSize: size / 2,
            lineHeight: size / 2,
            fontFamily: 'roboto'
          }, extraTopPadding),
          allowFontScaling: true,
          adjustsFontSizeToFit: true,
          numberOfLines: 1,
          children: initialien
        })
      });
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, _prevState) {
      if (nextProps.user != null) {
        var url = nextProps.backendUrl != null ? nextProps.backendUrl : _AuthClient.AuthClient.instance.url;
        var userImageLink = nextProps.user.userImageFileId != null && nextProps.user.userImageFileId.length > 0 ? `${url}/user/${nextProps.user.userId}/image/${nextProps.user.userImageFileId}` : undefined;
        return {
          userImageLink: userImageLink,
          loadError: false
        };
      }
      return null;
    }
  }]);
}(_react.PureComponent);
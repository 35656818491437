import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { I18n } from '../../../i18n/I18n';
export function IframeWidgetOptions(props) {
    const isValidURL = (text) => {
        try {
            return Boolean(new URL(text));
        }
        catch (e) {
            return false;
        }
    };
    const [title, setTitle] = useState(props.initValues && props.initValues.title ? props.initValues.title : '');
    const [url, setUrl] = useState(props.initValues && props.initValues.url ? props.initValues.url : '');
    const [validUrl, setValidUrl] = useState(props.initValues && props.initValues.url ? isValidURL(props.initValues.url) : false);
    const save = () => {
        props.onSaved({ title, url });
    };
    return (<>
      <DialogTitle>
        {props.type === 'edit'
            ? I18n.m.getMessage('widgetOptionDialogEditTitle')
            : I18n.m.getMessage('widgetOptionDialogAddTitle')}
      </DialogTitle>
      <DialogContent>
        <FormInputFilled initValue={title} labelText={I18n.m.getMessage('widgetIframeTitle')} onChange={(t) => setTitle(t)}/>
        <FormInputFilled initValue={url} labelText={I18n.m.getMessage('widgetIframeUrl')} onChange={(t) => {
            setUrl(t);
            setValidUrl(isValidURL(t));
        }} helperText={url.length > 0 && !validUrl ? I18n.m.getMessage('widgetIframeInvalidUrl') : undefined} error={url.length > 0 && !validUrl}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="#ffffff" onPress={Dialog.instance?.close}/>
        <ContainedButton title={I18n.m.getMessage('save')} onPress={save} disabled={!validUrl}/>
      </DialogActions>
    </>);
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileHandler = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _StdApplicationError = require("odatarepos/build/src/entities/StdApplicationError");
var _ODataFileHandler2 = require("odatarepos/build/src/odata/root/ODataFileHandler");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FileHandler = exports.FileHandler = function (_ODataFileHandler) {
  function FileHandler(url) {
    var _this;
    (0, _classCallCheck2.default)(this, FileHandler);
    _this = _callSuper(this, FileHandler);
    _this.url = url;
    return _this;
  }
  (0, _inherits2.default)(FileHandler, _ODataFileHandler);
  return (0, _createClass2.default)(FileHandler, [{
    key: "getFullPathForId",
    value: function getFullPathForId(id, subfolder) {
      if (subfolder != null) {
        return `${this.url}/${id}?sub=${subfolder}`;
      }
      return `${this.url}/${id}`;
    }
  }, {
    key: "getFileById",
    value: function () {
      var _getFileById = (0, _asyncToGenerator2.default)(function* (id, subfolder) {
        var fullPath = this.getFullPathForId(id, subfolder);
        return new _ODataFileHandler2.ODataFile(fullPath, id);
      });
      function getFileById(_x, _x2) {
        return _getFileById.apply(this, arguments);
      }
      return getFileById;
    }()
  }, {
    key: "deleteFileById",
    value: function () {
      var _deleteFileById = (0, _asyncToGenerator2.default)(function* (_id, _subfolder) {
        return false;
      });
      function deleteFileById(_x3, _x4) {
        return _deleteFileById.apply(this, arguments);
      }
      return deleteFileById;
    }()
  }, {
    key: "deleteFolder",
    value: function () {
      var _deleteFolder = (0, _asyncToGenerator2.default)(function* (_subfolder) {
        return false;
      });
      function deleteFolder(_x5) {
        return _deleteFolder.apply(this, arguments);
      }
      return deleteFolder;
    }()
  }, {
    key: "saveFileById",
    value: function () {
      var _saveFileById = (0, _asyncToGenerator2.default)(function* (file, id, subfolder) {
        var fullPath = this.getFullPathForId(id, subfolder);
        var f = file;
        var data;
        data = yield new Promise(function (resolve, reject) {
          try {
            var reader = new FileReader();
            var senddata = {
              name: f.name,
              date: f.lastModifiedDate,
              size: f.size,
              type: f.type,
              fileData: null
            };
            reader.onload = function () {
              var _ref = (0, _asyncToGenerator2.default)(function* (theFileData) {
                var t = theFileData.currentTarget;
                senddata.fileData = t.result;
                data = new FormData();
                data.append('myFile', f, f.name);
                resolve(data);
              });
              return function (_x9) {
                return _ref.apply(this, arguments);
              };
            }();
            reader.readAsDataURL(f);
          } catch (e) {
            reject(e);
          }
        });
        var headers = {};
        headers['Accept'] = 'application/json';
        var result = yield fetch(fullPath, {
          headers: headers,
          method: 'POST',
          body: data
        });
        if (result == null) {
          throw _StdApplicationError.StdApplicationError.badRequest('no result');
        }
        if (result.status === 200) {
          return new _ODataFileHandler2.ODataFile(fullPath, id);
        }
        throw _StdApplicationError.StdApplicationError.badRequest();
      });
      function saveFileById(_x6, _x7, _x8) {
        return _saveFileById.apply(this, arguments);
      }
      return saveFileById;
    }()
  }]);
}(_ODataFileHandler2.ODataFileHandler);
import React, { PureComponent } from 'react';
import { FlatList } from 'react-native';
import { TicketCard } from './TicketCard';
export class TicketList extends PureComponent {
    constructor(props) {
        super(props);
        this.getItemKey = (item, _index) => `ticket_card_${item.id}`;
        this.renderRow = ({ item }) => {
            const { planId, activeMultiselect, onMultiSelect, selectedIDs, showProjects } = this.props;
            return (<TicketCard ticket={item} key={`tCard${item.id}`} bullseye={planId != null && planId !== 'all'} activeMultiselect={activeMultiselect} onMultiSelect={onMultiSelect} status selected={selectedIDs.has(item.id)} showProjects={showProjects}/>);
        };
        this.state = {
            selectedIDs: props.selectedIDs,
        };
    }
    render() {
        const { iosContentInset, height, tickets } = this.props;
        return (<FlatList style={{ height }} key="ticketList" keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={1} data={tickets} contentContainerStyle={{ padding: 8, paddingBottom: 60, width: '100%', maxWidth: 768, alignSelf: 'center' }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _Spinner = require("../Spinner");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
var wrapperLoader = function wrapperLoader(_ref) {
  var text = _ref.text;
  var message = text != null && text.length > 0 ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
    color: _ThemeManager.ThemeManager.style.loadingTextColor,
    centeredText: true,
    centeredBox: true,
    children: text
  }) : null;
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    collapsable: false,
    style: [_ThemeManager.ThemeManager.style.absoluteStyle, {
      elevation: 100,
      zIndex: 100,
      backgroundColor: 'transparent',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    }],
    children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      collapsable: false,
      style: [_ThemeManager.ThemeManager.style.absoluteStyle, {
        backgroundColor: _ThemeManager.ThemeManager.style.loadingOverlayColor,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center'
      }],
      children: [(0, _jsxRuntime.jsx)(_Spinner.Spinner, {}), message]
    })
  });
};
var _default = exports.default = wrapperLoader;
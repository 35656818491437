import Color from 'color';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddTimeTrackDialog } from './AddTimeTrackDialog';
import { CombinedExtraPayTrackingDialogsControl } from './CombinedExtraPayTrackingDialogsControl';
export class TimeTrackingListItem extends PureComponent {
    constructor(props) {
        super(props);
        this.updateTime = () => {
            const { userEntries } = this.props;
            userEntries.memberEntries.forEach((t) => {
                if (t.type === 'TimeTrackingEntity') {
                    const timeTracking = t.orgEntry;
                    if (timeTracking.ends == null) {
                        const duration = TimeTrackingEntity.getRunningTime(timeTracking);
                        const currentTime = TimeTrackingEntity.msToTime(duration, true, true);
                        this.setState({ currentTime, currentMs: duration == null ? 0 : duration });
                    }
                }
            });
        };
        this.toggleDetails = () => {
            this.setState((prevstate) => ({ details: !prevstate.details }), () => {
                this.props.onToggleDetails(this.state.details);
            });
        };
        this.renderDetail = (t) => {
            const { isCollidating, currentMs } = this.state;
            const colliding = isCollidating.find((c) => c.id === t.id);
            const duration = t.type === 'TimeTrackingEntity' ? t.sumInMS / 1000 / 60 : 64;
            const line1_1 = t.taskName != null && t.taskName.length > 0 ? t.taskName : t.extraPayTitle != null ? t.extraPayTitle : '_';
            const line2_1 = t.projectTitle != null && t.projectTitle.length > 0 ? t.projectTitle : t.costCenterName;
            const line1_2 = t.type === 'TimeTrackingEntity'
                ? TimeTrackingEntity.msToTime(t.sumInMS === 0 && currentMs > 0 ? currentMs : t.sumInMS, false, true)
                : t.extraFieldValue != null
                    ? t.extraFieldValue
                    : ' ';
            const line2_2 = t.type === 'TimeTrackingEntity'
                ? `${I18n.m.date().getLocalTimeString(t.starts)} - ${I18n.m.date().getLocalTimeString(t.ends)}`
                : ' ';
            const icon = t.type === 'TimeTrackingEntity' ? 'clock-outline' : 'database-plus-outline';
            const iconColor = colliding != null && colliding.collidingEntries.length > 0
                ? ThemeManager.style.brandDanger
                : ThemeManager.style.defaultIconColor;
            const iconOnPress = colliding != null && colliding.collidingEntries.length > 0
                ? () => CombinedExtraPayTrackingDialogsControl.showColliding(t.orgEntry, colliding.collidingEntries)
                : undefined;
            const iconToolTip = colliding != null && colliding.collidingEntries.length > 0
                ? I18n.m.getMessage('timeTrackingChangeShowCollision')
                : '';
            return this.renderDetailView({
                icon,
                iconColor,
                iconToolTip,
                line2_2,
                line1_1,
                line2_1,
                line1_2,
                iconOnPress,
                duration,
                key: `track_${t.id}`,
                t,
            });
        };
        this.openAddDialog = (id) => (_e) => {
            const { currentDate, userEntries } = this.props;
            Dialog.instance?.open({
                content: (<AddTimeTrackDialog selectedDate={currentDate} memberIds={[id]} start={userEntries.targetStart} end={userEntries.targetEnd} pause={userEntries.targetPause}/>),
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        };
        this.openAddTimeTrackDialog = () => {
            const { member } = this.state;
            if (member != null) {
                Dialog.instance?.open({
                    content: (<AddTimeTrackDialog title={I18n.m.getMessage('timeTrackingTaskOrProject')} hideTimes selectedDate={new Date()} memberIds={[member.id]}/>),
                    fullscreenResponsive: true,
                    contentPadding: false,
                    scrollable: false,
                });
            }
        };
        this.state = {
            currentMs: 0,
            isCollidating: [],
            details: props.opened === true,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
        const { userEntries } = this.props;
        userEntries.memberEntries.forEach((t) => {
            if (t.type === 'TimeTrackingEntity' && t.ends == null) {
                this.updateTime();
                if (this.interval != null)
                    clearInterval(this.interval);
                this.interval = setInterval(this.updateTime, 1000);
            }
        });
    }
    componentWillUnmount() {
        if (this.interval != null)
            clearInterval(this.interval);
    }
    async init() {
        const { userEntries } = this.props;
        const isCollidating = [];
        for (const t of userEntries.memberEntries) {
            if (t.type === 'TimeTrackingEntity') {
                const a = await TimeTrackingEntity.isColliding(t.orgEntry);
                if (a.length > 0) {
                    isCollidating.push({ id: t.id, collidingEntries: a });
                }
            }
        }
        let user;
        const member = CompanyUserInfo.companyMember.find((m) => m.id === userEntries.memberId);
        if (member != null && member.userId != null) {
            try {
                user =
                    CachedEntities.knownUsers != null && CachedEntities.knownUsers.has(member.userId)
                        ? CachedEntities.knownUsers.get(member.userId)
                        : await AuthClient.instance.modals.user.getById(member.userId);
            }
            catch (err) {
                console.debug('cant get user', err);
            }
        }
        this.setState({ isCollidating, user, member });
    }
    renderDetails() {
        const { details } = this.state;
        if (!details)
            return null;
        const { userEntries } = this.props;
        const content = [];
        userEntries.memberEntries.forEach((next, index) => {
            if (index && next.type === 'TimeTrackingEntity') {
                const last = userEntries.memberEntries[index - 1];
                if (last.type === 'TimeTrackingEntity' && last.starts != null && next.ends != null) {
                    const pause = (last.starts.getTime() - next.ends.getTime()) / 1000 / 60;
                    if (pause > 1) {
                        const line2_2 = `${I18n.m.date().getLocalTimeString(next.ends)} - ${I18n.m
                            .date()
                            .getLocalTimeString(last.starts)}`;
                        content.push(this.renderDetailView({
                            icon: 'pause-circle-outline',
                            duration: pause,
                            line2_1: I18n.m.getMessage('pause'),
                            line2_2,
                            iconColor: ThemeManager.style.defaultIconColor,
                            iconToolTip: '',
                            backgroundColor: '#F2F2F2',
                            key: `pause_${next.id}`,
                        }));
                    }
                }
            }
            content.push(this.renderDetail(next));
        });
        return <View style={{ width: '100%', padding: 8 }}>{content}</View>;
    }
    renderRecordingButtons() {
        const { currentMs } = this.state;
        if (currentMs === 0)
            return null;
        const { userEntries } = this.props;
        const activeTrack = userEntries.memberEntries.find((t) => t.type === 'TimeTrackingEntity' && t.sumInMS === 0);
        if (activeTrack == null)
            return null;
        return this.renderQuickChips(activeTrack);
    }
    renderDetailView(data) {
        const { line1_1, backgroundColor, iconOnPress, icon, line1_2, line2_1, line2_2, iconColor, iconToolTip, key, t, } = data;
        return (<View key={key} style={{
                width: '100%',
                padding: 0,
                ...ThemeManager.style.borderStyle,
                borderWidth: 0,
                borderTopWidth: 1,
                paddingVertical: 8,
                height: 48,
                justifyContent: 'center',
                backgroundColor,
            }}>
        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{
                width: 40,
                height: 40,
                ...ThemeManager.style.borderStyle,
                borderRadius: 40,
                backgroundColor: '#F2F2F2',
                borderColor: '#F2F2F2',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Icon icon={icon} toolTip={iconToolTip} color={iconColor} onPress={iconOnPress}/>
          </View>
          <View style={{ flex: 1, paddingLeft: 8, justifyContent: 'center' }}>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              {line1_1 == null ? null : (<View style={{ flex: 1 }}>
                  <MaterialText numberOfLines={1} fixedWidth="100%">
                    {line1_1}
                  </MaterialText>
                </View>)}
              {line1_2 == null ? null : (<View style={{ width: 100 }}>
                  <MaterialText numberOfLines={1} fixedWidth="100%" strong textAlign="right">
                    {line1_2}
                  </MaterialText>
                </View>)}
            </View>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ flex: 1 }}>
                <MaterialText numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2}>
                  {line2_1}
                </MaterialText>
              </View>

              <View style={{ width: 160 }}>
                <MaterialText numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2} textAlign="right">
                  {line2_2}
                </MaterialText>
              </View>
            </View>
          </View>
          <View style={{ width: 48, alignItems: 'flex-end' }}>
            <Icon disabled={t == null} icon="dots-vertical" toolTip="" onPress={t != null ? CombinedExtraPayTrackingDialogsControl.openMenu(t) : undefined}/>
          </View>
        </View>
      </View>);
    }
    render() {
        const { userEntries } = this.props;
        const { isCollidating, user, member, currentMs } = this.state;
        if (userEntries.memberEntries.length === 0) {
            if (member == null || member.role === 'inactive')
                return null;
            const memberName = member != null ? `${member?.firstName} ${member?.lastName}` : '';
            return (<Card style={{ width: '100%' }} outerPadding={8}>
          <View style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'visible',
                    padding: 8,
                }}>
            {user != null ? (<UserImage size={36} user={user} lightBox={false}/>) : member != null ? (<UserImage size={36} lightBox={false} user={new UserEntity({ firstname: member.firstName, lastname: member.lastName })}/>) : (<View style={{
                        width: 40,
                        height: 40,
                        ...ThemeManager.style.borderStyle,
                        borderRadius: 20,
                        backgroundColor: '#F2F2F2',
                        borderColor: '#F2F2F2',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                <MaterialText centeredText centeredBox type={MaterialTextTypes.H5}>
                  0
                </MaterialText>
              </View>)}
            <View style={{ flex: 1, paddingLeft: 8 }}>
              <MaterialText numberOfLines={1} fixedWidth="100%">
                {user ? user.getFullName() : memberName}
              </MaterialText>
              <MaterialText color={ThemeManager.style.brandDanger} numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2}>
                {I18n.m.getMessage('noTimeTrackingRecordsForDay')}
              </MaterialText>
            </View>
            <View style={{ width: 48 }}>
              <Icon icon="plus" toolTip={I18n.m.getMessage('timeTrackingCaptureTime')} onPress={this.openAddDialog(userEntries.memberId)}/>
            </View>
          </View>
        </Card>);
        }
        const { holidayTimeInMS, targetTimeInMS } = userEntries;
        const workingTargetTime = Math.max(targetTimeInMS - holidayTimeInMS, 0);
        const targetTime = targetTimeInMS != null ? userEntries.totalTime + currentMs - workingTargetTime : null;
        const badgeColor = isCollidating.length > 0 ? ThemeManager.style.brandDanger : ThemeManager.style.brandPrimary;
        return (<Card style={{ width: '100%' }} outerPadding={8}>
        <View style={{ width: '100%' }}>
          <Ripple style={{ width: '100%', borderRadius: 6 }} onPress={this.toggleDetails} hoverColor={isCollidating.length > 0
                ? Color(ThemeManager.style.brandDanger).alpha(0.25).toString()
                : ThemeManager.style.getDefaultHoverColor(ThemeManager.style.brandPrimary)} backgroundColor={isCollidating.length > 0 ? Color(ThemeManager.style.brandDanger).alpha(0.2).toString() : '#FFFFFF'}>
            <View style={{
                width: '100%',
                padding: 8,
            }}>
              <View style={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                overflow: 'visible',
            }}>
                {user != null ? (<UserImage size={36} user={user} lightBox={false} badgeColor={badgeColor} badge={userEntries.memberEntries.length > 1 ? userEntries.memberEntries.length.toString(10) : undefined}/>) : member != null ? (<UserImage size={36} badgeColor={badgeColor} badge={userEntries.memberEntries.length > 1 ? userEntries.memberEntries.length.toString(10) : undefined} lightBox={false} user={new UserEntity({ firstname: member.firstName, lastname: member.lastName })}/>) : (<View style={{
                    width: 40,
                    height: 40,
                    ...ThemeManager.style.borderStyle,
                    borderRadius: 20,
                    backgroundColor: '#F2F2F2',
                    borderColor: '#F2F2F2',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <MaterialText centeredText centeredBox type={MaterialTextTypes.H5}>
                      {userEntries.memberEntries.length}
                    </MaterialText>
                  </View>)}
                <View style={{ flex: 1, paddingLeft: 8 }}>
                  <MaterialText numberOfLines={1} fixedWidth="100%">
                    {userEntries.memberEntries[0].memberName}
                  </MaterialText>
                  <MaterialText numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2}>
                    {userEntries.tasks.length > 0 ? userEntries.tasks.join(', ') : '-'} (
                    {userEntries.projectTitles.length > 0 ? userEntries.projectTitles.join(', ') : '-'})
                  </MaterialText>
                </View>
                <View style={{ width: 85, paddingLeft: 8, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <MaterialText centeredBox fixedWidth="100%" numberOfLines={1} strong textAlign="right">
                    {userEntries.totalTime + currentMs > 0
                ? TimeTrackingEntity.msToTime(userEntries.totalTime + currentMs, currentMs > 0, true)
                : ' '}
                  </MaterialText>

                  <MaterialText fixedWidth="100%" numberOfLines={1} type={MaterialTextTypes.Body2} textAlign="right" color={targetTime != null && targetTime < 0
                ? ThemeManager.style.brandDanger
                : ThemeManager.style.brandSuccess}>
                    {targetTime != null && targetTime !== 0
                ? `${targetTime >= 0 ? '+' : '-'} ${TimeTrackingEntity.msToTime(Math.abs(targetTime), false, true)}`
                : ' '}
                  </MaterialText>
                </View>
              </View>
            </View>
          </Ripple>
          {this.renderRecordingButtons()}
          {this.renderDetails()}
        </View>
      </Card>);
    }
    renderQuickChips(t) {
        const { isCollidating } = this.state;
        if (t.type !== 'TimeTrackingEntity')
            return null;
        const timeTracking = t.orgEntry;
        if (timeTracking.status !== 'Running')
            return null;
        return (<View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                paddingTop: 4,
                paddingBottom: 8,
                backgroundColor: isCollidating.length > 0 ? Color(ThemeManager.style.brandDanger).alpha(0.2).toString() : '#FFFFFF',
            }}>
        <Icon key="switchIcon" toolTip={I18n.m.getMessage('timeTrackingTaskOrProject')} icon="swap-horizontal" backgroundColor={ThemeManager.style.brandWarning} color="#FFFFFF" onPress={this.openAddTimeTrackDialog}/>
        <View key="swicthSpacer" style={{ width: 32 }}/>
        <Icon toolTip={I18n.m.getMessage('timeTrackingStopTimer')} onPress={CombinedExtraPayTrackingDialogsControl.stopTiming(t)} icon="stop" backgroundColor={ThemeManager.style.brandDanger} color="#FFFFFF"/>
      </View>);
    }
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddProjectTicketLayout = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ProjectTicketLayoutAdded_1 = require("../../../events/project/ticketlayout/ProjectTicketLayoutAdded");
var CustomField_1 = require("../../../query/entities/simple/CustomField");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var AddProjectTicketLayout = function (_ClientProjectCommand) {
  function AddProjectTicketLayout(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddProjectTicketLayout);
    _this = _callSuper(this, AddProjectTicketLayout, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.waitForResult = false;
    _this.commandName = 'AddProjectTicketLayout';
    return _this;
  }
  (0, _inherits2.default)(AddProjectTicketLayout, _ClientProjectCommand);
  return (0, _createClass2.default)(AddProjectTicketLayout, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield _superPropGet(AddProjectTicketLayout, "checkProjectStatusAndGeneralAccess", this, 3)([this.data.projectId, userId]);
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.data.projectId);
        if (project.projectSubscription == null || project.projectSubscription === 'basic') {
          throw {
            message: 'Min. Pro-Projekt benötigt',
            messageCode: 'notInBasicProject'
          };
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.data.projectId, userId, 'commandChangeProjectTicketLayouts')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var d = {
          fields: this.data.fields != null ? this.data.fields : [],
          onTypes: this.data.onTypes != null ? this.data.onTypes : [],
          title: this.data.title
        };
        return new ProjectTicketLayoutAdded_1.ProjectTicketLayoutAdded(this.entityId, d, this.data.projectId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var _project$ticketLayout,
          _this2 = this;
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.data.projectId);
        if ((_project$ticketLayout = project.ticketLayouts) != null && _project$ticketLayout.find(function (a) {
          return a.title === _this2.data.title;
        })) {
          throw {
            message: 'Das Ticketlayout existiert bereits im Projekt',
            messageCode: 'projectTicketLayoutAlreadyExists'
          };
        }
        if (this.data.title.length <= 0) {
          throw {
            key: 'title',
            messageCode: 'required',
            message: 'Name wird benötigt'
          };
          if (this.data.title.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'value',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        yield AddProjectTicketLayout.validateCustoms(this.data.fields);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "validateCustoms",
    value: function () {
      var _validateCustoms = (0, _asyncToGenerator2.default)(function* (fields) {
        if (fields == null || fields.length === 0) return;
        fields.forEach(function (f) {
          if (f.customField) {
            if (f.label == null || f.label.length <= 0) {
              throw {
                key: 'label',
                messageCode: 'required',
                message: 'Name wird benötigt'
              };
            }
            var duplicateField = fields == null ? void 0 : fields.find(function (a) {
              var _a$customField;
              return (a.label === f.label || CustomField_1.LayoutFields.getIdentifier(a) === CustomField_1.LayoutFields.getIdentifier(f)) && a.id !== f.id && ((_a$customField = a.customField) == null ? void 0 : _a$customField.type) !== 'divider';
            });
            if (duplicateField) {
              var _duplicateField$custo;
              throw {
                message: 'Doppelte Bezeichnungen eines Feldes sind nicht möglich: {{label}}',
                messageCode: 'ticketLayoutDuplicateFieldName',
                messageData: {
                  label: ((_duplicateField$custo = duplicateField.customField) == null ? void 0 : _duplicateField$custo.type) === 'divider' ? f.label : CustomField_1.LayoutFields.getIdentifier(duplicateField)
                }
              };
            }
            if (f.customField.type === 'number') {
              var o = new CustomField_1.CustomFieldNumberOptions(f.customField.options);
              if (!(o.delimiter === 1 || o.delimiter === 0)) throw {
                key: 'delimiter',
                messageCode: 'badRequest',
                message: 'delimiter darf nicht leer sein'
              };
              if (!(o.dezimals >= 0 && o.dezimals <= 4)) throw {
                key: 'dezimals',
                messageCode: 'badRequest',
                message: 'dezimals darf nicht leer sein'
              };
              if (o.min != null && o.max != null && o.min > o.max) throw {
                key: 'max',
                messageCode: 'maxSmallerThanMin',
                message: 'Maximum darf nicht kleiner sein als Minimum'
              };
            } else if (f.customField.type === 'string') {} else if (f.customField.type === 'Date') {} else if (f.customField.type === 'DateRange') {} else if (f.customField.type === 'List') {
              var _o$list;
              var _o = f.customField.options;
              if (!((_o$list = _o.list) != null && _o$list.length) || _o.list.length <= 0) throw {
                key: 'label',
                messageCode: 'required',
                message: 'Optionen werden benötigt'
              };
            } else if (f.customField.type === 'MultiselectList') {
              var _o2$list;
              var _o2 = f.customField.options;
              if (!((_o2$list = _o2.list) != null && _o2$list.length) || _o2.list.length <= 0) throw {
                key: 'label',
                messageCode: 'required',
                message: 'Optionen werden benötigt'
              };
            } else if (f.customField.type === 'CheckboxList') {} else if (f.customField.type === 'person') {} else if (f.customField.type === 'multiperson') {} else if (f.customField.type === 'divider') {} else if (f.customField.type === 'progress') {} else {
              throw {
                message: `Custom Field "${f.customField.type}" not supported`,
                messageCode: 'badRequest'
              };
            }
          }
        });
      });
      function validateCustoms(_x2) {
        return _validateCustoms.apply(this, arguments);
      }
      return validateCustoms;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.AddProjectTicketLayout = AddProjectTicketLayout;
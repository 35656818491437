var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LokiReactNativeAsyncStorageAdapter = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _lokijs = require("../../../../../upmesh-client/node_modules/lokijs");
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LokiReactNativeAsyncStorageAdapter = exports.LokiReactNativeAsyncStorageAdapter = function (_LokiLocalStorageAdap) {
  function LokiReactNativeAsyncStorageAdapter() {
    (0, _classCallCheck2.default)(this, LokiReactNativeAsyncStorageAdapter);
    return _callSuper(this, LokiReactNativeAsyncStorageAdapter);
  }
  (0, _inherits2.default)(LokiReactNativeAsyncStorageAdapter, _LokiLocalStorageAdap);
  return (0, _createClass2.default)(LokiReactNativeAsyncStorageAdapter, [{
    key: "loadDatabase",
    value: function loadDatabase(dbname, callback) {
      var result = _SimpleStorage.SimpleStorage.get(dbname);
      if (result == null) {
        _SimpleStorage.SimpleStorage.set(dbname, '');
        callback('');
      }
      return callback(result);
    }
  }, {
    key: "saveDatabase",
    value: function saveDatabase(dbname, dbstring, callback) {
      _SimpleStorage.SimpleStorage.set(dbname, dbstring.toString());
      callback(null);
    }
  }, {
    key: "deleteDatabase",
    value: function deleteDatabase(dbname, callback) {
      _SimpleStorage.SimpleStorage.getAllKeys().then(function (list) {
        if (list != null) {
          list.forEach(function (str) {
            if (str.startsWith(dbname)) {
              _SimpleStorage.SimpleStorage.remove(str);
            }
          });
        }
        return new Promise(function (resolve) {
          resolve();
        });
      }).then(function () {
        callback(null);
      }).catch(function (e) {
        callback(e);
      });
    }
  }]);
}(_lokijs.LokiLocalStorageAdapter);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KiConnectorV2 = void 0;
var ServerEnvirement_1 = require("cqrs-core/build/src/server/webserver/ServerEnvirement");
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var uuid = require("uuid");
var CreateTicket_1 = require("../../../client/commands/tickets/CreateTicket");
var Config_1 = require("../../../Config");
var KiConnectorV2 = function () {
  function KiConnectorV2() {
    (0, _classCallCheck2.default)(this, KiConnectorV2);
  }
  return (0, _createClass2.default)(KiConnectorV2, [{
    key: "getId",
    value: function getId() {
      return this.id;
    }
  }, {
    key: "getTimestamp",
    value: function getTimestamp() {
      return this.query.timestamp;
    }
  }, {
    key: "getQueryText",
    value: function getQueryText() {
      return this.query.input;
    }
  }, {
    key: "getResponse",
    value: function getResponse() {
      return this.response;
    }
  }, {
    key: "removeInstructEndStrings",
    value: function removeInstructEndStrings(resultAsString) {
      var llama3InstructEndString = '<|eot_id|>';
      if (resultAsString.endsWith(llama3InstructEndString)) {
        return resultAsString.substring(0, resultAsString.length - llama3InstructEndString.length);
      }
      return resultAsString;
    }
  }, {
    key: "executePrompt",
    value: function () {
      var _executePrompt = (0, _asyncToGenerator2.default)(function* () {
        var queryData = KiConnectorV2.getDefaultLavaData();
        queryData.messages.push({
          role: 'system',
          content: this.basePrompt
        });
        queryData.messages.push({
          role: 'user',
          content: this.query.input
        });
        var aiUrl = Config_1.ConfigHandler.config.aiUrl;
        try {
          var start = new Date();
          var stringBody = JSON.stringify(queryData);
          var result = yield fetch(`${aiUrl}/v1/chat/completions`, {
            method: 'POST',
            body: stringBody
          });
          var rawText = yield result.text();
          var parsedResult = JSON.parse(rawText);
          var withoutInstruct = this.removeInstructEndStrings(parsedResult.choices[0].message.content);
          var parsedAction = JSON.parse(withoutInstruct);
          var end = new Date();
          this.response = {
            id: this.id,
            sanitizedRequest: {
              input: this.query.input,
              timestamp: this.query.timestamp,
              userId: this.query.userId,
              additionalData: this.query.additionalData
            },
            result: parsedAction
          };
          return this.response;
        } catch (err) {}
        throw new ApplicationError_1.ApplicationError('Fehler bei Anfrage', 'errorOnKiRequest', ApplicationError_1.ApplicationErrorStatusCode.InternalServerError);
      });
      function executePrompt() {
        return _executePrompt.apply(this, arguments);
      }
      return executePrompt;
    }()
  }, {
    key: "executeResponse",
    value: function () {
      var _executeResponse = (0, _asyncToGenerator2.default)(function* (userToken) {
        if (this.response == null) {
          return;
        }
        switch (this.response.result.action) {
          case 'CreateTicket':
            yield this.createTicket(userToken);
            break;
          default:
        }
      });
      function executeResponse(_x) {
        return _executeResponse.apply(this, arguments);
      }
      return executeResponse;
    }()
  }, {
    key: "getAction",
    value: function getAction() {
      return this.response.result.action;
    }
  }, {
    key: "getTicketDataFromResponse",
    value: function getTicketDataFromResponse() {
      var _this$query$additiona;
      var ticketData = Object.assign({}, this.response.result.actionData, {
        projectId: (_this$query$additiona = this.query.additionalData.projectId) != null ? _this$query$additiona : ''
      });
      Object.keys(ticketData).forEach(function (key) {
        if (ticketData[key] == null || ticketData[key] === 'null') {
          delete ticketData[key];
        }
      });
      if (this.response.result.actionData.completionOnString != null) {
        ticketData.completionOn = new Date(this.response.result.actionData.completionOnString);
      }
      return ticketData;
    }
  }, {
    key: "createTicket",
    value: function () {
      var _createTicket = (0, _asyncToGenerator2.default)(function* (userToken) {
        var ticketData = this.getTicketDataFromResponse();
        var createTicketCommand = new CreateTicket_1.CreateTicket(ticketData, uuid.v1(), userToken);
        yield createTicketCommand.execute(ServerEnvirement_1.ServerEnvirement.writeStore);
      });
      function createTicket(_x2) {
        return _createTicket.apply(this, arguments);
      }
      return createTicket;
    }()
  }], [{
    key: "getDefaultLavaData",
    value: function getDefaultLavaData() {
      return {
        stream: false,
        n_predict: 400,
        temperature: 0.7,
        stop: ['</s>', 'Llama:', 'User:'],
        repeat_last_n: 256,
        repeat_penalty: 1.18,
        top_k: 40,
        top_p: 0.5,
        tfs_z: 1,
        typical_p: 1,
        presence_penalty: 0,
        frequency_penalty: 0,
        mirostat: 0,
        mirostat_tau: 5,
        mirostat_eta: 0.1,
        grammar: '',
        n_probs: 0,
        image_data: [],
        cache_prompt: true,
        slot_id: 0,
        messages: []
      };
    }
  }, {
    key: "fromQuery",
    value: function fromQuery(query, basePrompt) {
      var connector = new KiConnectorV2();
      connector.query = query;
      connector.basePrompt = basePrompt;
      connector.id = uuid.v4();
      return connector;
    }
  }, {
    key: "fromResponse",
    value: function fromResponse(response) {
      var connector = new KiConnectorV2();
      connector.query = response.sanitizedRequest;
      connector.response = response;
      return connector;
    }
  }]);
}();
exports.KiConnectorV2 = KiConnectorV2;
KiConnectorV2.KI_LOG_KEY = 'KI - LOG';
KiConnectorV2.BOT_NAME = 'meshy';
import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ConfigAll } from '../../config/ConfigAll';
export class CompanyLogo extends PureComponent {
    render() {
        const { company, size, round } = this.props;
        if (company == null)
            return null;
        let content;
        let borderRadius = round ? size / 2 : 0;
        if (company.logo == null || company.logo.length === 0) {
            borderRadius = size / 2;
            content = (<View style={{
                    width: size,
                    height: size,
                    overflow: 'hidden',
                    backgroundColor: ThemeManager.style.brandSecondary,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
          <MaterialText type={size === 64 ? MaterialTextTypes.H6 : MaterialTextTypes.H3} color="#FFFFFF" centeredBox centeredText>
            {company.company.slice(0, Math.min(3, company.company.length))}
          </MaterialText>
        </View>);
        }
        else {
            const uri = `${ConfigAll.b2cURL}/company/${company.id}/logo/${company.logo}`;
            content = (<CacheableImage resizeMode="contain" key={company.logo} lightBox width={size} height={size} source={{ uri }}/>);
        }
        return (<View style={{
                width: size,
                height: size,
                borderRadius,
                overflow: 'hidden',
                backgroundColor: '#FFFFFF',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
        {content}
      </View>);
    }
}

import { Overlay } from 'materialTheme/src/theme/components/Overlay';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Animated, PanResponder, View } from 'react-native';
import { AsyncEvent } from 'ts-events';
import { CurrentProject } from '../project/CurrentProject';
import { ProjectImage } from '../project/ProjectImage';
import { MenuButton } from './MenuButton';
import { ProjectMenu } from './ProjectMenu';
export class SidePanelHandler {
    static post(e) {
        SidePanelHandler.currentState = e;
        SidePanelHandler.stateEvent.post(e);
    }
}
SidePanelHandler.currentState = 'unmounted';
SidePanelHandler.stateEvent = new AsyncEvent();
export class SidePanel extends PureComponent {
    constructor(props) {
        super(props);
        this.drawerWidth = 256;
        this.onResize = () => {
            this.close();
            this.forceUpdate();
        };
        this.close = () => {
            if (this.state.panelState !== this.createPanelState('close')) {
                SidePanelHandler.post(this.createPanelState('close'));
            }
        };
        this.open = () => {
            if (this.state.panelState !== 'open') {
                SidePanelHandler.post(this.createPanelState('open'));
            }
        };
        this.handleEvent = (e) => {
            this.setState({ panelState: e });
        };
        this.aniWith = new Animated.Value(0);
        this.animate = new Animated.Value(0);
        this.lastWidth = 0;
        this.state = {
            panelState: 'unmounted',
            currentProjectId: '',
        };
        this.createPanResponder();
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentProjectId !== prevState.currentProjectId) {
            return {
                ...prevState,
                currentProjectId: nextProps.currentProjectId,
            };
        }
        return null;
    }
    componentDidMount() {
        SidePanelHandler.stateEvent.attach(this.handleEvent);
        ResizeEvent.onResize.attach(this.onResize);
        if (this.state.panelState === 'unmounted') {
            SidePanelHandler.post(this.createPanelState());
        }
    }
    static getClosestNumberInArray(array, num) {
        let minDiff = 1000;
        let ans;
        for (const i in array) {
            const m = Math.abs(num - array[i]);
            if (m < minDiff) {
                minDiff = m;
                ans = array[i];
            }
        }
        return ans;
    }
    createPanelState(changeNow) {
        if ((this.state.panelState === 'open' && changeNow == null) || changeNow === 'open') {
            return 'open';
        }
        if (ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM) {
            return 'bar';
        }
        return 'closed';
    }
    componentWillUnmount() {
        ResizeEvent.onResize.detach(this.onResize);
        SidePanelHandler.stateEvent.detach(this.handleEvent);
        SidePanelHandler.post('unmounted');
    }
    createWidth() {
        const { panelState } = this.state;
        if (panelState === 'open') {
            return ThemeManager.style.getScreenRelativePixelSize(this.drawerWidth);
        }
        if (panelState === 'bar') {
            return ThemeManager.style.getScreenRelativePixelSize(56);
        }
        return 0;
    }
    startAni(toWidth) {
        Animated.timing(this.aniWith, {
            duration: 195,
            toValue: toWidth,
            useNativeDriver: false,
        }).start();
    }
    componentDidUpdate(_prevProps, _prevState, _prevContext) {
        const width = this.createWidth();
        if (width !== this.lastWidth) {
            this.lastWidth = width;
            this.startAni(width);
        }
    }
    createPanResponder() {
        this._panResponder = PanResponder.create({
            onStartShouldSetPanResponder: (_evt, _gestureState) => false,
            onStartShouldSetPanResponderCapture: (_evt, _gestureState) => {
                this.startMoveX = _gestureState.dx;
                return false;
            },
            onMoveShouldSetPanResponder: (_evt, _gestureState) => {
                const diff = Math.abs(_gestureState.dx - this.startMoveX);
                if (diff > ThemeManager.style.getScreenRelativePixelSize(10)) {
                    return true;
                }
                return false;
            },
            onMoveShouldSetPanResponderCapture: (_evt, _gestureState) => false,
            onPanResponderGrant: (_evt, _gestureState) => {
                this.startMove = Date.now();
                this.startMoveX = _gestureState.dx;
                return false;
            },
            onPanResponderMove: (_evt, _gestureState) => {
                if (this.startMove != null) {
                    const direction = _gestureState.dx < 0 ? 1 : -1;
                    const newWidth = direction + this.lastWidth + _gestureState.dx;
                    this.aniWith.setValue(Math.max(1, Math.min(newWidth, this.drawerWidth)));
                }
                return false;
            },
            onPanResponderTerminationRequest: (_evt, _gestureState) => true,
            onPanResponderRelease: (_evt, _gestureState) => {
                if (this.startMove != null) {
                    const direction = _gestureState.dx < 0 ? 1 : -1;
                    const newWidth = direction + this.lastWidth + _gestureState.dx;
                    const closest = SidePanel.getClosestNumberInArray([0, this.drawerWidth], newWidth);
                    if (closest === 0) {
                        this.startAni(0);
                        SidePanelHandler.post(this.createPanelState('close'));
                    }
                    else {
                        this.startAni(this.drawerWidth);
                        SidePanelHandler.post(this.createPanelState('open'));
                    }
                }
                return false;
            },
            onPanResponderTerminate: (_evt, _gestureState) => {
            },
            onShouldBlockNativeResponder: (_evt, _gestureState) => true,
        });
    }
    render() {
        const { size } = this.props;
        const sView = size.windowWidth <= ThemeManager.style.breakpointM;
        if (sView)
            return <ProjectMenu closerDrawer={this.close} currentProjectId={this.props.currentProjectId} size={size} sView/>;
        const overlay = this.state.panelState === 'open' ? (<Overlay elevation={0} onPress={this.close} showOverlay={this.state.panelState === 'open'}/>) : null;
        const mainWidth = this.state.panelState === 'open'
            ? ResizeEvent.current.windowWidth
            :
                this.aniWith;
        const panView = this.createPanelState() === 'bar' ? null : (<Animated.View {...this._panResponder.panHandlers} key="drawerResponder" pointerEvents="auto" style={{
                position: 'absolute',
                top: 0,
                overflow: 'hidden',
                left: ResizeEvent.current.contentLeft,
                height: ResizeEvent.current.contentHeight,
                width: this.state.panelState === 'closed'
                    ? ThemeManager.style.contentPaddingValue
                    : ResizeEvent.current.windowWidth,
                zIndex: 10000,
            }}/>);
        const project = CurrentProject.instance.getCurrentProject();
        return (<View pointerEvents="box-none" style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: ResizeEvent.current.windowHeight,
                width: ResizeEvent.current.windowWidth,
            }}>
        {panView}
        <Animated.View style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: ResizeEvent.current.windowHeight,
                width: mainWidth,
                zIndex: 10001,
                borderStyle: 'solid',
                borderWidth: 0,
                borderRightWidth: 1,
                borderColor: ThemeManager.style.menuRightBorderColor,
            }}>
          {overlay}
          <Animated.View style={{
                width: this.aniWith,
                position: 'absolute',
                overflow: 'hidden',
                top: 0,
                left: 0,
                height: ResizeEvent.current.windowHeight,
                backgroundColor: ThemeManager.style.menuBgColor,
                borderRightColor: ThemeManager.style.borderColor,
                borderRightWidth: ThemeManager.style.borderWidth,
            }}>
            <View style={{
                position: 'relative',
                top: 0,
                height: ThemeManager.style.getScreenRelativePixelSize(48),
                alignItems: 'flex-start',
                justifyContent: 'center',
                borderStyle: 'solid',
                borderWidth: 0,
                borderBottomWidth: 1,
                borderColor: ThemeManager.style.menuBorderColor,
                alignSelf: 'stretch',
                right: 0,
                left: 0,
            }}>
              <View style={{
                position: 'absolute',
                top: 0,
                height: ThemeManager.style.getScreenRelativePixelSize(48),
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                right: ThemeManager.style.getScreenRelativePixelSize(6),
            }}>
                {this.state.panelState === 'open' && project != null ? (<ProjectImage project={project} size={ThemeManager.style.getScreenRelativePixelSize(40)}/>) : null}
              </View>
              <View style={{
                position: 'absolute',
                top: 0,
                height: ThemeManager.style.getScreenRelativePixelSize(48),
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'row',
                left: ThemeManager.style.getScreenRelativePixelSize(4),
            }}>
                <MenuButton size={ThemeManager.style.getScreenRelativePixelSize(48)} isOpen={this.state.panelState === 'open'} closeFunction={this.close} openFunction={this.open}/>
              </View>
            </View>

            <ProjectMenu closerDrawer={this.close} currentProjectId={this.props.currentProjectId} size={size}/>
          </Animated.View>
        </Animated.View>
      </View>);
    }
}

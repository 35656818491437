"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RightsManager = void 0;
var RightsManager = function () {
  function RightsManager() {
    (0, _classCallCheck2.default)(this, RightsManager);
  }
  return (0, _createClass2.default)(RightsManager, null, [{
    key: "isCompanyAdmin",
    value: function () {
      var _isCompanyAdmin = (0, _asyncToGenerator2.default)(function* (userId, companyId) {
        return RightsManager.instance.isCompanyAdmin(userId, companyId);
      });
      function isCompanyAdmin(_x, _x2) {
        return _isCompanyAdmin.apply(this, arguments);
      }
      return isCompanyAdmin;
    }()
  }, {
    key: "hasWriteRight",
    value: function () {
      var _hasWriteRight = (0, _asyncToGenerator2.default)(function* (projectId, userId, right) {
        return RightsManager.instance.hasWriteRight(projectId, userId, right);
      });
      function hasWriteRight(_x3, _x4, _x5) {
        return _hasWriteRight.apply(this, arguments);
      }
      return hasWriteRight;
    }()
  }, {
    key: "hasVersion",
    value: function () {
      var _hasVersion = (0, _asyncToGenerator2.default)(function* (userId) {
        return RightsManager.instance.hasVersion(userId);
      });
      function hasVersion(_x6) {
        return _hasVersion.apply(this, arguments);
      }
      return hasVersion;
    }()
  }, {
    key: "hasReadRight",
    value: function () {
      var _hasReadRight = (0, _asyncToGenerator2.default)(function* (projectId, userId, right) {
        return RightsManager.instance.hasReadRight(projectId, userId, right);
      });
      function hasReadRight(_x7, _x8, _x9) {
        return _hasReadRight.apply(this, arguments);
      }
      return hasReadRight;
    }()
  }, {
    key: "getRoleForUserInProject",
    value: function () {
      var _getRoleForUserInProject = (0, _asyncToGenerator2.default)(function* (userId, projectId) {
        return RightsManager.instance.getRoleForUserInProject(userId, projectId);
      });
      function getRoleForUserInProject(_x10, _x11) {
        return _getRoleForUserInProject.apply(this, arguments);
      }
      return getRoleForUserInProject;
    }()
  }]);
}();
exports.RightsManager = RightsManager;
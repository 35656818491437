"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpmeshPartnerEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var UpmeshPartnerEntity = function (_ReadModelEntity_1$Re) {
  function UpmeshPartnerEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, UpmeshPartnerEntity);
    _this = _callSuper(this, UpmeshPartnerEntity, [obj]);
    _this.entityName = 'UpmeshPartner';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(UpmeshPartnerEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(UpmeshPartnerEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new UpmeshPartnerEntity(obj);
    }
  }, {
    key: "hasRight",
    value: function hasRight(right, userId) {
      if (this.users == null) return false;
      var u = this.users.findIndex(function (u) {
        return u.userId === userId;
      });
      if (u === -1) return false;
      var user = this.users[u];
      if (user[right] == null) return false;
      return !!user[right];
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('users', new OdataEntity_1.OdataIndex(true));
      baseMap.set('company', new OdataEntity_1.OdataIndex(true));
      baseMap.set('contactName', new OdataEntity_1.OdataIndex(true));
      baseMap.set('description', new OdataEntity_1.OdataIndex());
      baseMap.set('street', new OdataEntity_1.OdataIndex());
      baseMap.set('streetNr', new OdataEntity_1.OdataIndex());
      baseMap.set('zip', new OdataEntity_1.OdataIndex());
      baseMap.set('city', new OdataEntity_1.OdataIndex());
      baseMap.set('country', new OdataEntity_1.OdataIndex());
      baseMap.set('phone', new OdataEntity_1.OdataIndex());
      baseMap.set('email', new OdataEntity_1.OdataIndex());
      baseMap.set('supportPhone', new OdataEntity_1.OdataIndex());
      baseMap.set('supportEmail', new OdataEntity_1.OdataIndex());
      baseMap.set('locked', new OdataEntity_1.OdataIndex(true));
      baseMap.set('lockedReason', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.UpmeshPartnerEntity = UpmeshPartnerEntity;
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { I18n } from '../../../i18n/I18n';
import { PageView } from '../../root/PageView';
import { ProjectCraftSettings } from './ProjectCraftsSettings';
import { ProjectFileTypeSettings } from './ProjectFileTypeSettings';
import { ProjectProfileView } from './ProjectProfileView';
import { ProjectSettingsMenu } from './ProjectSettingsMenu';
import { ProjectTagSettings } from './ProjectTagsSettings';
import { ProjectTicketLayoutSettings } from './ProjectTicketLayoutSettings';
import { ProjectTicketstatusView } from './ProjectTicketstatusView';
import { ProjectTicketTypeSettings } from './ProjectTicketTypeSettings';
export class ProjectSettingsView extends PureComponent {
    constructor(props) {
        super(props);
        this.breakViewWidth = 756;
        this.renderLayouts = () => {
            const { project, size } = this.props;
            return <ProjectTicketLayoutSettings project={project} size={size}/>;
        };
        this.renderCrafts = () => {
            const { project, size } = this.props;
            return <ProjectCraftSettings project={project} size={size}/>;
        };
        this.renderFileTypes = () => {
            const { project, size } = this.props;
            return <ProjectFileTypeSettings project={project} size={size}/>;
        };
        this.renderTicketStatus = () => {
            const { project, size } = this.props;
            return <ProjectTicketstatusView project={project} size={size}/>;
        };
        this.renderProfile = () => {
            const { canEdit, canSeeSettings } = this.state;
            const { size } = this.props;
            return (<ProjectProfileView size={size} key={`profile_${canSeeSettings}`} canSeeSettings={canSeeSettings} project={this.props.project} canEdit={canEdit}/>);
        };
        this.renderContent = () => {
            const activeTab = this.props.pathvars.planId;
            const { size, project } = this.props;
            const { canSeeSettings } = this.state;
            if (!canSeeSettings) {
                return (<View style={{
                        height: size.contentHeight - ThemeManager.style.headerHeight,
                        width: size.contentWidth,
                        alignContent: 'center',
                    }}>
          {this.renderTabContent()}
        </View>);
            }
            if (size.contentWidth < this.breakViewWidth) {
                if (activeTab == null) {
                    return (<View style={{ width: '100%' }}>
            <ProjectSettingsMenu size={size} onPress={(key) => Routing.instance.goTo(`/projects/${project.id}/projectsettings/${key}`)} selected={activeTab} sMode={size.contentWidth < this.breakViewWidth}/>
          </View>);
                }
                return (<View style={{
                        height: size.contentHeight - ThemeManager.style.headerHeight,
                        width: size.contentWidth,
                        alignContent: 'center',
                    }}>
          {this.renderTabContent()}
        </View>);
            }
            return (<View style={{
                    flexDirection: 'row',
                    width: size.contentWidth,
                    height: size.contentHeight - ThemeManager.style.headerHeight,
                }}>
        <View style={{
                    height: size.contentHeight - ThemeManager.style.headerHeight,
                    width: 312,
                    alignContent: 'center',
                }}>
          <ProjectSettingsMenu size={size} onPress={(key) => Routing.instance.goTo(`/projects/${project.id}/projectsettings/${key}`, Platform.OS === 'ios' && size.contentWidth >= this.breakViewWidth)} selected={activeTab} sMode={size.contentWidth < this.breakViewWidth}/>
        </View>
        <View style={{
                    height: size.contentHeight - ThemeManager.style.headerHeight,
                    width: size.contentWidth - 312,
                    alignContent: 'center',
                }}>
          {this.renderTabContent()}
        </View>
      </View>);
        };
        this.renderTabContent = () => {
            const { activeTab, canSeeSettings } = this.state;
            let tabContent = this.renderProfile();
            if (canSeeSettings && activeTab != null) {
                if (activeTab === 'types')
                    tabContent = this.renderTicketTypes();
                else if (activeTab === 'ticketLayouts')
                    tabContent = this.renderLayouts();
                else if (activeTab === 'crafts')
                    tabContent = this.renderCrafts();
                else if (activeTab === 'tags')
                    tabContent = this.renderTags();
                else if (activeTab === 'categories')
                    tabContent = this.renderFileTypes();
                else if (activeTab === 'status')
                    tabContent = this.renderTicketStatus();
            }
            return (<View key={`tabContent${activeTab}`} style={{ width: '100%', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
        {tabContent}
      </View>);
        };
        this.renderTags = () => {
            const { size, project } = this.props;
            return <ProjectTagSettings size={size} project={project}/>;
        };
        this.renderTicketTypes = () => {
            const { size, project } = this.props;
            return <ProjectTicketTypeSettings project={project} size={size}/>;
        };
        this.state = {
            activeTab: this.props.pathvars.planId,
            canEdit: false,
            canSeeSettings: false,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init ProjectSettingsView', err));
    }
    async init() {
        const { project } = this.props;
        const canSeeSettings = await RightsManager.hasReadRight(project.id, CurrentUser.userId, 'projectSettings');
        const canEdit = await RightsManager.hasWriteRight(project.id, CurrentUser.userId, 'commandChangeProject');
        this.setState({ canEdit, canSeeSettings });
    }
    render() {
        const { size, project } = this.props;
        const { canSeeSettings, activeTab } = this.state;
        const leftButtons = canSeeSettings && size.contentWidth < this.breakViewWidth && activeTab != null
            ? [
                <Icon key="back" toolTip="" icon="arrow-left" onPress={Routing.instance.goToButton(`/projects/${project.id}/projectsettings`, true)}/>,
            ]
            : undefined;
        return (<PageView headerProps={{
                title: I18n.m.getMessage('projectSettings'),
                leftButtons,
            }} scrollable={false}>
        {this.renderContent()}
      </PageView>);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.pathvars.planId !== this.props.pathvars.planId) {
            const activeTab = this.props.pathvars.planId;
            if (this.state.activeTab !== activeTab) {
                this.setState({ activeTab });
            }
        }
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropArea = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _DropAreaHandler = require("./DropAreaHandler");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DropArea = exports.DropArea = function (_PureComponent) {
  function DropArea(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DropArea);
    _this = _callSuper(this, DropArea, [props]);
    _this.getPosition = (0, _asyncToGenerator2.default)(function* () {
      return new Promise(function (resolve) {
        _this.v.measure(function (_x, _y, width, height, pageX, pageY) {
          resolve({
            width: width,
            height: height,
            x: pageX,
            y: pageY
          });
        });
      });
    });
    _this.onAction = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (data) {
        if (_this.props.onDrop != null) {
          _this.props.onDrop(data).catch(function (err) {
            return void 0;
          });
        }
      });
      return function (_x2) {
        return _ref2.apply(this, arguments);
      };
    }();
    _this.onFilter = function (data) {
      if (_this.props.dropFilter != null) {
        return _this.props.dropFilter(data);
      }
      return false;
    };
    _this.setView = function (v) {
      _this.v = v;
    };
    _this.toggleHighlight = function (active, data) {
      var highlight = active;
      if (active) {
        highlight = _this.onFilter(data);
      }
      if (_this.props.toggleHighlight != null) {
        return _this.props.toggleHighlight(highlight);
      }
      var opacity = highlight ? 0.9 : 1;
      return _this.setState({
        opacity: opacity
      });
    };
    _this.state = {
      opacity: 1
    };
    return _this;
  }
  (0, _inherits2.default)(DropArea, _PureComponent);
  return (0, _createClass2.default)(DropArea, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var zIndex = this.props.zIndex != null ? this.props.zIndex : 0;
      this.areaId = _DropAreaHandler.DropAreaHandler.registerDropArea(zIndex, this.getPosition, this.onAction, this.onFilter, this.toggleHighlight, this.props.exclusive === true);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.areaId != null) {
        _DropAreaHandler.DropAreaHandler.removeDropArea(this.areaId);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          opacity: this.state.opacity,
          position: 'relative',
          padding: 0,
          margin: 0
        },
        collapsable: false,
        ref: this.setView,
        pointerEvents: "box-none",
        children: this.props.children
      });
    }
  }]);
}(_react.PureComponent);
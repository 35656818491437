import * as Sentry from '@sentry/browser';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import './assets/materialTheme.web.css';
import './assets/tui-editor.web.css';
import './assets/upmesh.web.css';
import { Bau2cloudApp } from './Bau2cloudApp';
import { ConfigAll } from './config/ConfigAll';
console.log('web start');
ConfigAll.init()
    .catch((err) => console.debug('cant init', err))
    .finally(() => {
    if (ConfigAll.getEnvironment() === 'development' && ConfigAll.getHost().length > 0) {
        ErrorReporter.setENVIRONMENTExtra(ConfigAll.getHost());
    }
    ErrorReporter.ENVIRONMENT = ConfigAll.getEnvironment();
    if (ConfigAll.getEnvironment() !== 'development') {
        Sentry.init({
            dsn: 'https://b1b7dd1e52d848728968e55b476ec864@s.upmesh.de/2',
            tracesSampleRate: ConfigAll.getEnvironment() === 'testing' ? 0.1 : 0.01,
            profilesSampleRate: ConfigAll.getEnvironment() === 'testing' ? 0.1 : 0.01,
            environment: ErrorReporter.ENVIRONMENT,
            release: ConfigAll.getVersion(),
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
                Sentry.captureConsoleIntegration({ levels: ['error', 'warn', 'info', 'fatal', 'assert'] }),
            ],
            replaysSessionSampleRate: 0.01,
            replaysOnErrorSampleRate: 1.0,
        });
        ErrorReporter.Reporter = Sentry;
    }
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<Bau2cloudApp />);
});

import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { PageView } from 'materialTheme/src/views/root/PageView';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { SubscriptionConfig } from './SubscriptionConfig';
export class SubscriptionOrder extends PureComponent {
    constructor(props) {
        super(props);
        this.billwerkUrl = SubscriptionConfig.billwerkPortalUrl;
        this.state = {
            url: undefined,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        await WaitFor.instance.waitFor(() => CurrentUser.entity != null);
        const { planVersionId } = this.props.pathvars;
        const u = CurrentUser.entity;
        if (planVersionId === '0') {
            const headers = {};
            headers['Content-Type'] = `application/json`;
            headers['Authorization'] = `Bearer ${CurrentUser.token}`;
            const result = await fetch(`${UpmeshClient.instance.url}/subscription/billwerk/selfservice`, {
                headers,
                method: 'GET',
            });
            console.debug('got selfservice url', result);
            if (result.status === 200) {
                const url = await result.text();
                this.setState({ url });
            }
            else {
                console.debug('cant get result', result);
            }
            return;
        }
        const ual = await AuthClient.instance.modals.userAdress.get({
            filter: `userId eq '${CurrentUser.userId}'`,
        });
        const ua = ual.length > 0 ? ual[0] : undefined;
        let userString = `tag=${u.userId}&fname=${u.firstname}&lname=${u.lastname}`;
        if (u.company != null && u.company.length > 0) {
            userString += `&cname=${u.company}`;
        }
        if (ua != null && ua.street != null) {
            userString += `&street=${ua.street}`;
        }
        if (ua != null && ua.streetnumber != null) {
            userString += `&hnum=${ua.streetnumber}`;
        }
        if (ua != null && ua.zip != null) {
            userString += `&pcode=${ua.zip}`;
        }
        if (ua != null && ua.city != null) {
            userString += `&city=${ua.city}`;
        }
        const userMails = await AuthClient.instance.modals.userMails.get({
            filter: `userId eq '${u.id}' and validated eq true`,
        });
        if (userMails.length > 0) {
            let { email } = userMails[0];
            for (const e of userMails) {
                if (e.notifications) {
                    email = e.email;
                    break;
                }
            }
            userString += `&email=${email}`;
        }
        this.setState({
            url: `${this.billwerkUrl}#/${planVersionId}?${userString}`,
        });
    }
    render() {
        const { url } = this.state;
        return (<PageView showAccountIcon headerProps={{
                leftButtons: [
                    <Icon icon="home-outline" color={ThemeManager.style.headerButtonColor} key="homeButton" onPress={Routing.instance.goToButton('/')} toolTip={I18n.m.getMessage('goToHome')}/>,
                ],
                rightButtons: [],
                title: I18n.m.getMessage('subscriptionOrderTitle'),
            }} scrollable={false} style={{
                backgroundColor: ThemeManager.style.appBgColor,
            }}>
        {url != null && url.length > 0 ? (<iframe title="order" style={{ width: '100%', height: ResizeEvent.current.contentHeight - ThemeManager.style.headerHeight }} src={url}/>) : (<View style={ThemeManager.style.contentPadding}>
            <Spinner />
          </View>)}
      </PageView>);
    }
}

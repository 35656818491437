import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeRoleName } from 'upmesh-core/src/client/commands/roles/ChangeRoleName';
import { I18n } from '../../i18n/I18n';
export class ChangeRoleDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    static open(project, role) {
        Dialog.instance?.open({
            closeOnTouchOutside: false,
            content: <ChangeRoleDialog project={project} role={role}/>,
            contentPadding: false,
        });
    }
    render() {
        const { role } = this.props;
        const currentData = {
            roleName: role.roleName,
        };
        const dataOptions = new Map();
        dataOptions.set('roleName', {
            formType: 'string',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('rolesChangeDialogRolenameLabel'),
                key: 'roleName',
            },
        });
        return (<Form key="addProjectRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('rolesChangeDialogTitle'),
            }} stickyActions={false} command={new ChangeRoleName(currentData, role.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('rolesChangeDialogActionButton')} additionalButtons={[
                <ContainedButton key="cancel" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectReadRights = void 0;
var ProjectReadRights = (0, _createClass2.default)(function ProjectReadRights() {
  (0, _classCallCheck2.default)(this, ProjectReadRights);
  this.plans = false;
  this.teams = false;
  this.allTickets = false;
  this.logs = false;
  this.projectSettings = false;
});
exports.ProjectReadRights = ProjectReadRights;
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyTag } from 'upmesh-core/src/client/commands/companies/tags/AddCompanyTag';
import { ChangeCompanyTag } from 'upmesh-core/src/client/commands/companies/tags/ChangeCompanyTag';
import { AddProjectTag } from 'upmesh-core/src/client/commands/project/tags/AddProjectTag';
import { ChangeProjectTag } from 'upmesh-core/src/client/commands/project/tags/ChangeProjectTag';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ColorComponent } from '../project/ColorComponent';
import { ALL_TAG_GROUP_COLORS } from './AllTagGroupColors';
export function AddOrChangeTagDialog(props) {
    const [tagName, setTagName] = useState(props.tag?.tagName ?? '');
    const [groupName, setGroupName] = useState(props.tag?.groupName ?? '');
    const [color, setColor] = useState(props.tag?.color);
    const [isLoading, setIsLoading] = useState(false);
    const [pickerIndex, setPickerIndex] = useState(0);
    const [dialogWidth, setDialogWidth] = useState(0);
    const [availableColors, setAvailableColors] = useState(new Set(ALL_TAG_GROUP_COLORS));
    const [availableTags, setAvailableTags] = useState(new Map());
    const [availableGroups, setAvailableGroups] = useState([]);
    useEffect(() => {
        const allGroups = new Map();
        const allTags = new Map();
        const modifiedColors = new Set(availableColors);
        props.settings.tags?.forEach((tag) => {
            allGroups.set(tag.groupName, tag);
            allTags.set(tag.tagName, tag);
            modifiedColors.delete(tag.color);
        });
        setAvailableGroups(Array.from(allGroups.values()).sort((a, b) => a.groupName.localeCompare(b.groupName)));
        setAvailableTags(allTags);
        setAvailableColors(modifiedColors);
    }, [props.settings.tags]);
    const renderErrorText = () => {
        const errorTexts = [];
        if (props.tag != null) {
            if (tagName !== props.tag.tagName && availableTags.has(tagName)) {
                errorTexts.push('tagsAddTagTagAlreadyExists');
            }
        }
        else {
            if (availableTags.has(tagName)) {
                errorTexts.push('tagsAddTagTagAlreadyExists');
            }
            if (availableColors.size === 0 && availableGroups.findIndex((elem) => elem.groupName === groupName) === -1) {
                errorTexts.push('tagsAddGroupTooMany');
            }
        }
        return errorTexts.map((elem) => (<MaterialText key={`error_${elem}`} type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
        {I18n.m.getMessage(elem)}
      </MaterialText>));
    };
    const groupNameTextInput = (input) => {
        const tmpTag = availableGroups.find((elem) => elem.groupName === input);
        if (tmpTag != null) {
            setGroupName(tmpTag.groupName);
            setColor(tmpTag.color);
        }
        else {
            setGroupName(input ?? '');
        }
    };
    const renderAddNew = () => {
        const numberOfShownColors = Math.floor((dialogWidth - 32) / 40);
        let colors = Array.from(availableColors).splice(0, numberOfShownColors);
        const tmpTag = availableGroups.find((elem) => elem.groupName === groupName);
        if (tmpTag != null) {
            colors = [tmpTag.color];
        }
        return [
            <FormInputFilled key="input_new_group" initValue={groupName} onSubmitEditing={groupNameTextInput} labelText={I18n.m.getMessage('tagGroup')} autoCompleteList={availableGroups.map((x) => x.groupName)} onPressAutoComplete={groupNameTextInput} onChange={groupNameTextInput} disableScrollTo helperText={I18n.m.getMessage('tagsAddGroupHelperText')}/>,
            <ColorComponent key="input_new_color" initColor={color} propColors={colors} onChange={setColor} title={I18n.m.getMessage('tagsTaggroupColor')}/>,
        ];
    };
    const changePicker = (index) => {
        const group = availableGroups[index];
        setPickerIndex(index);
        if (group != null) {
            setGroupName(group.groupName);
            setColor(group.color);
        }
    };
    const renderChange = () => {
        const listItems = [];
        availableGroups.forEach((tag) => {
            listItems.push({
                title: tag.groupName,
                data: { groupName: tag.groupName, groupColor: tag.color },
                thumbnail: {
                    width: 24,
                    thumbnail: (<View style={{
                            height: 16,
                            width: 16,
                            borderRadius: 8,
                            backgroundColor: tag.color,
                        }}/>),
                },
            });
        });
        return [
            <FormInputPicker key="group_picker" list={listItems} selectedIndex={pickerIndex} labelText={I18n.m.getMessage('tagGroup')} onChangePicker={changePicker}/>,
            <ColorComponent key="input_new_color" initColor={color} propColors={[color ?? '']} title={I18n.m.getMessage('tagsTaggroupColor')}/>,
        ];
    };
    const submitNowProject = async () => {
        if (props.tag != null) {
            const oldValue = props.tag;
            const newValue = { groupName, tagName, color: color ?? props.tag.color };
            if (JSON.stringify(oldValue) === JSON.stringify(newValue))
                return;
            const command = new ChangeProjectTag({ oldValue: props.tag, newValue: { groupName, tagName, color: color ?? props.tag.color } }, props.settings.id);
            await command.execute();
        }
        else {
            const command = new AddProjectTag({
                tag: { tagName, color: color ?? '', groupName },
            }, props.settings.id);
            await command.execute();
        }
    };
    const submitNow = async () => {
        if (props.tag != null) {
            const oldValue = props.tag;
            const newValue = { groupName, tagName, color: color ?? props.tag.color };
            if (JSON.stringify(oldValue) === JSON.stringify(newValue))
                return;
            const command = new ChangeCompanyTag({ oldValue: props.tag, newValue: { groupName, tagName, color: color ?? props.tag.color } }, props.settings.id);
            await command.execute(ClientStore.commandStore);
        }
        else {
            const command = new AddCompanyTag({
                tag: { tagName, color: color ?? '', groupName },
            }, props.settings.id);
            await command.execute(ClientStore.commandStore);
        }
    };
    const submit = (_e) => {
        setIsLoading(true);
        const correctFunction = props.settings.entityName === 'CompanySettings' ? submitNow : submitNowProject;
        correctFunction()
            .then(() => {
            Dialog.instance?.close();
        })
            .catch((e) => {
            DefaultErrorHandler.showDefaultErrorAlert(e);
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    const onChangeLayout = (e) => {
        const { width } = e.nativeEvent.layout;
        setDialogWidth(width);
    };
    return (<>
      <DialogTitle key="tag_dialog_header">
        {props.tag != null ? I18n.m.getMessage('tagsEditTagTitle') : I18n.m.getMessage('tagsAddTagTitle')}
      </DialogTitle>
      <DialogContent key="tag_dialog_content">
        <FormInputFilled value={tagName} onChange={setTagName} labelText={I18n.m.getMessage('tag')}/>
        <View onLayout={onChangeLayout}>{props.tag != null ? renderChange() : renderAddNew()}</View>
        {renderErrorText()}
      </DialogContent>
      <DialogActions key="tag_dialog_actions">
        <ContainedButton disabled={isLoading} title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} onPress={Dialog.instance?.close} backgroundColor="transparent"/>
        <ContainedButton disabled={isLoading || tagName.length === 0} title={props.tag != null ? I18n.m.getMessage('save') : I18n.m.getMessage('add')} onPress={submit}/>
      </DialogActions>
    </>);
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangePlanDetails = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var Config_1 = require("../../../Config");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var PlanChanged_1 = require("../../events/plans/PlanChanged");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangePlanDetails = function (_ClientProjectCommand) {
  function ChangePlanDetails(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangePlanDetails);
    _this = _callSuper(this, ChangePlanDetails, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangePlanDetails';
    return _this;
  }
  (0, _inherits2.default)(ChangePlanDetails, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangePlanDetails, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var entity = yield CommandReadModels_1.CommandReadModels.plan.getById(this.entityId);
        yield _superPropGet(ChangePlanDetails, "checkProjectStatusAndGeneralAccess", this, 3)([entity.projectId, userId]);
        if (yield RightsManager_1.RightsManager.hasWriteRight(entity.projectId, userId, 'commandChangePlanDetails')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var getPlan = yield CommandReadModels_1.CommandReadModels.plan.getById(this.entityId);
        return new PlanChanged_1.PlanChanged(this.entityId, Object.assign({}, this.data, {
          tagIds: []
        }), getPlan.projectId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'title') {
          if (this.data.title.length <= 0) {
            throw {
              key: 'title',
              messageCode: 'required',
              message: 'wird benötigt'
            };
          }
          if (this.data.title.length > 100) {
            throw {
              key: 'title',
              messageCode: 'stringToLong',
              message: 'zu lang'
            };
          }
        }
        if (key == null || key === 'notes') {
          if (this.data.notes != null && this.data.notes.length > Config_1.ConfigHandler.config.string_Large) {
            throw {
              key: 'notes',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangePlanDetails = ChangePlanDetails;
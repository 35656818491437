"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateLocale = void 0;
var moment = require("moment");
require("moment/locale/de");
require("moment/locale/nl");
require("moment/locale/pl");
var DateLocale = function () {
  function DateLocale(locale) {
    var hourFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '24';
    (0, _classCallCheck2.default)(this, DateLocale);
    this.locale = 'de';
    this.hourFormatLT = 'HH:mm';
    this.hourFormatLTS = 'HH:mm:ss';
    this.today = 'Today';
    this.yesterday = 'Yesterday';
    this.locale = locale;
    if (hourFormat === '12') {
      this.hourFormatLT = 'hh:mm A';
      this.hourFormatLTS = 'hh:mm:ss A';
    }
    if (this.locale === 'de') {
      this.today = 'Heute';
      this.yesterday = 'Gestern';
    } else if (this.locale === 'nl') {
      this.today = 'Vandaag';
      this.yesterday = 'Gisteren';
    }
    try {
      moment.locale(this.locale);
    } catch (e) {
      moment.locale('en');
    }
  }
  return (0, _createClass2.default)(DateLocale, [{
    key: "setLocale",
    value: function setLocale() {
      try {
        moment.locale(this.locale);
      } catch (e) {
        moment.locale('en');
      }
    }
  }, {
    key: "firstDayOfWeek",
    value: function firstDayOfWeek() {
      this.setLocale();
      return moment.localeData().firstDayOfWeek();
    }
  }, {
    key: "firstDayOfYear",
    value: function firstDayOfYear() {
      this.setLocale();
      return moment.localeData().firstDayOfYear();
    }
  }, {
    key: "getLocalTimeString",
    value: function getLocalTimeString() {
      var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
      this.setLocale();
      return moment(date).format(this.hourFormatLT);
    }
  }, {
    key: "isValidDate",
    value: function isValidDate(d) {
      return d instanceof Date && !Number.isNaN(d.getTime());
    }
  }, {
    key: "localeDateFromString",
    value: function localeDateFromString(date) {
      try {
        this.setLocale();
        var d = moment(date, 'l').toDate();
        if (this.isValidDate(d)) {
          return d;
        }
      } catch (_unused) {
        throw new Error('wrong Date Format');
      }
      throw new Error('wrong Date Format');
    }
  }, {
    key: "localeDateFromStringWithTime",
    value: function localeDateFromStringWithTime(date) {
      try {
        this.setLocale();
        return moment(date, `L ${this.hourFormatLT}`).toDate();
      } catch (_unused2) {
        try {
          return moment(date, `L ,${this.hourFormatLT}`).toDate();
        } catch (_unused3) {
          throw new Error('wrong Date Format');
        }
      }
    }
  }, {
    key: "exifDateFromStringToDate",
    value: function exifDateFromStringToDate(date) {
      try {
        this.setLocale();
        return moment(date, `YYYY:MM:DD ${this.hourFormatLTS}`).toDate();
      } catch (_unused4) {
        try {
          return moment(date, `YYYY:MM:DD ${this.hourFormatLT}`).toDate();
        } catch (_unused5) {
          throw new Error('wrong Date Format');
        }
      }
    }
  }, {
    key: "localeDateString",
    value: function localeDateString(date) {
      var monthName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var todayAsText = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var yesterdayAsText = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      try {
        if (todayAsText && this.localeDateString(date) === this.localeDateString(new Date())) return this.today;
        if (yesterdayAsText && this.localeDateString(date) === this.localeDateString(new Date(new Date().setDate(new Date().getDate() - 1)))) return this.yesterday;
        this.setLocale();
        return moment(date).format(monthName ? 'LL' : 'L');
      } catch (_unused6) {
        return '-';
      }
    }
  }, {
    key: "localeDateWithTimeString",
    value: function localeDateWithTimeString(date) {
      var longTerm = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var withSeconds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      try {
        this.setLocale();
        if (withSeconds) return moment(date).format(longTerm ? 'LLLL' : `L ${this.hourFormatLTS}`);
        return moment(date).format(longTerm ? 'LLLL' : `L ${this.hourFormatLT}`);
      } catch (_unused7) {
        return '-';
      }
    }
  }, {
    key: "localeDateFormat",
    value: function localeDateFormat(date, format) {
      try {
        this.setLocale();
        return moment(date).format(format);
      } catch (_unused8) {
        return '-';
      }
    }
  }, {
    key: "monthsNames",
    value: function monthsNames() {
      this.setLocale();
      return moment.localeData().months();
    }
  }, {
    key: "monthsNamesShort",
    value: function monthsNamesShort() {
      this.setLocale();
      return moment.localeData().monthsShort();
    }
  }, {
    key: "week",
    value: function week(m) {
      this.setLocale();
      return moment.localeData().week(m);
    }
  }, {
    key: "weekdayNamesShort",
    value: function weekdayNamesShort() {
      this.setLocale();
      return moment.localeData().weekdaysShort();
    }
  }, {
    key: "weekdays",
    value: function weekdays() {
      this.setLocale();
      return moment.localeData().weekdays();
    }
  }], [{
    key: "waitFor",
    value: function () {
      var _waitFor = (0, _asyncToGenerator2.default)(function* (causa) {
        var intervall = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 33;
        var timeOut = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 60000;
        var starTime = Date.now();
        var returner = true;
        while (!causa()) {
          yield new Promise(function (resolve) {
            setTimeout(function () {
              resolve();
            }, intervall);
          });
          if (Date.now() - starTime > timeOut) {
            returner = false;
            break;
          }
        }
        return returner;
      });
      function waitFor(_x) {
        return _waitFor.apply(this, arguments);
      }
      return waitFor;
    }()
  }]);
}();
exports.DateLocale = DateLocale;
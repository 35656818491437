"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeBookmark = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var BookmarkChanged_1 = require("../../events/storedfiles/BookmarkChanged");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeBookmark = function (_ClientProjectCommand) {
  function ChangeBookmark(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeBookmark);
    _this = _callSuper(this, ChangeBookmark, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeBookmark';
    return _this;
  }
  (0, _inherits2.default)(ChangeBookmark, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeBookmark, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        this.bookmark = yield CommandReadModels_1.CommandReadModels.instance.bookmark.getById(this.entityId);
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var file = yield CommandReadModels_1.CommandReadModels.instance.storedFile.getById(this.bookmark.forEntityId);
        this.project = yield CommandReadModels_1.CommandReadModels.instance.project.getById(file.projectId);
        if (userId === 'all') return true;
        if (userId === this.bookmark.userId && !this.bookmark.deleted) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new BookmarkChanged_1.BookmarkChanged(this.entityId, {
          bookmarks: (0, _toConsumableArray2.default)(this.data.bookmarks)
        }, this.project.id);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'bookmarks') {
          if (this.data.bookmarks == null) {
            throw {
              message: 'Lesezeichen werden benötigt',
              messageCode: 'required',
              key: 'name'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeBookmark = ChangeBookmark;
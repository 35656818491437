import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
export function TimeTrackingCalenderDay(props) {
    const fontSize = props.sMode
        ? ThemeManager.style.getScreenRelativePixelSize(12)
        : ThemeManager.style.getScreenRelativePixelSize(14);
    return (<Ripple onPress={props.onPress} key={`renderedDay_${props.date}`} toolTip={props.holidayTooltip} style={{
            flex: 1,
            position: 'relative',
            flexDirection: props.sMode ? 'column' : 'row',
            borderLeftColor: ThemeManager.style.borderColor,
            borderLeftWidth: ThemeManager.style.borderWidth,
            alignItems: 'center',
            alignContent: 'center',
            backgroundColor: props.isSelected ? 'rgba(0, 115, 255, 0.1)' : '#FFFFFF',
        }}>
      <View style={{
            top: 0,
            left: 0,
            position: 'absolute',
        }}>
        <MaterialText type={MaterialTextTypes.Caption} color={props.holidayTooltip.length > 0 ? ThemeManager.style.brandDanger : ThemeManager.style.black87} centeredText centeredBox>
          {props.withMonth || props.date.getDate() === 1
            ? `${props.date.getDate()}.${props.date.getMonth() + 1}`
            : props.date.getDate()}
        </MaterialText>
      </View>
      {!props.diffTime && props.timeTotalString === '00:00' ? null : (<View collapsable={false} style={{
                top: props.sMode ? 12 : 0,
                position: 'absolute',
                right: 4,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto',
            }}>
          <MaterialText fontSize={fontSize} fixedWidth="100%" textAlign="right" centeredText={false} type={MaterialTextTypes.Body2}>
            {props.timeTotalString}
          </MaterialText>
          <MaterialText fontSize={fontSize} fixedWidth="100%" textAlign="right" color={props.diffTime < 0 ? ThemeManager.style.brandDanger : ThemeManager.style.brandSuccess} centeredText={false} type={MaterialTextTypes.Body2}>
            {`${props.diffTime > 0 ? '+ ' : ''}${props.diffTimeString}`}
          </MaterialText>
        </View>)}
    </Ripple>);
}

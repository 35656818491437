import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Table } from 'materialTheme/src/theme/components/Table';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { I18n } from '../../i18n/I18n';
export function CompanyReportTemplatesNotesDialogJournalsTab(props) {
    const fieldsDaily = [
        { id: 'project.title', category: 1, type: 'string', description: I18n.m.getMessage('projectName') },
        { id: 'project.client', category: 1, type: 'string', description: I18n.m.getMessage('projectContactLabel') },
        { id: 'report.owner', category: 2, type: 'string', description: I18n.m.getMessage('projectOwner') },
        { id: 'report.forDay', category: 2, type: 'number', description: I18n.m.getMessage('date') },
        { id: 'report.projectCompanyName', category: 2, type: 'string', description: I18n.m.getMessage('company') },
        { id: 'report.attendance[].companyName', category: 3, type: 'string', description: I18n.m.getMessage('company') },
        {
            id: 'report.attendance[].workerCount',
            category: 3,
            type: 'number',
            description: I18n.m.getMessage('journalAttendanceEmployeeCount'),
        },
        {
            id: 'report.attendance[].timeStart',
            category: 3,
            type: 'Date | string',
            description: I18n.m.getMessage('journalAttendanceArrival'),
        },
        {
            id: 'report.attendance[].timeEnd',
            category: 3,
            type: 'Date | string',
            description: I18n.m.getMessage('journalAttendanceDeparture'),
        },
        {
            id: 'report.attendance[].specifics',
            category: 3,
            type: 'string',
            description: I18n.m.getMessage('journalSpecifics'),
        },
        { id: 'report.employee[].name', category: 4, type: 'string', description: I18n.m.getMessage('name') },
        {
            id: 'report.employee[].workerCount',
            category: 4,
            type: 'number',
            description: I18n.m.getMessage('journalEmployeesEmployees'),
        },
        { id: 'report.employee[].task', category: 4, type: 'string', description: I18n.m.getMessage('task') },
        {
            id: 'report.employee[].timeStart',
            category: 4,
            type: 'Date | string',
            description: I18n.m.getMessage('journalAttendanceArrival'),
        },
        {
            id: 'report.employee[].timeEnd',
            category: 4,
            type: 'Date | string',
            description: I18n.m.getMessage('journalAttendanceDeparture'),
        },
        {
            id: 'report.employee[].totalTimeInMinutes',
            category: 4,
            type: 'number',
            description: I18n.m.getMessage('totalNumberOfHours'),
        },
        {
            id: 'report.specialOccurrences',
            category: 2,
            type: 'string',
            description: I18n.m.getMessage('journalSpecialOccurrences'),
        },
        { id: 'report.workDone', category: 2, type: 'string', description: I18n.m.getMessage('journalWorkDone') },
        {
            id: 'report.weather[].time',
            category: 5,
            type: 'Date | string',
            description: I18n.m.getMessage('journalWeatherTime'),
        },
        {
            id: 'report.weather[].temperatureLow',
            category: 5,
            type: 'number',
            description: I18n.m.getMessage('journalWeatherTemperatureLow'),
        },
        {
            id: 'report.weather[].temperatureHigh',
            category: 5,
            type: 'number',
            description: I18n.m.getMessage('journalWeatherTemperatureHigh'),
        },
        {
            id: 'report.weather[].weatherCondition',
            category: 5,
            type: 'string',
            description: I18n.m.getMessage('journalWeatherConditions'),
        },
        {
            id: 'report.weather[].specifics',
            category: 5,
            type: 'string',
            description: I18n.m.getMessage('journalSpecifics'),
        },
        {
            id: 'report.additionalFields[].title',
            category: 6,
            type: 'string',
            description: I18n.m.getMessage('name'),
        },
        {
            id: 'report.additionalFields[].content',
            category: 6,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsAdditionalFieldsContent'),
        },
        {
            id: 'report.images[].fileName',
            category: 7,
            type: 'string',
            description: I18n.m.getMessage('journalSettingsFilename'),
        },
        {
            id: 'report.images[].userName',
            category: 7,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsCreatorName'),
        },
        {
            id: 'report.images[].date',
            category: 7,
            type: 'Date | string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsUploadDate'),
        },
        {
            id: 'report.images[].image',
            category: 7,
            type: '{ width: number; height: number; data: string | ArrayBuffer }',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsImageFile'),
        },
        {
            id: 'company.name',
            category: 8,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsCompanyName'),
        },
        {
            id: 'company.contactName',
            category: 8,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsCompanyName'),
        },
        { id: 'company.street', category: 8, type: 'string', description: I18n.m.getMessage('accountChangeAddressStreet') },
        {
            id: 'company.streetNr',
            category: 8,
            type: 'string',
            description: I18n.m.getMessage('accountChangeAddressStreetN'),
        },
        { id: 'company.zip', category: 8, type: 'string', description: I18n.m.getMessage('accountChangeAddressZip') },
        { id: 'company.city', category: 8, type: 'string', description: I18n.m.getMessage('accountChangeAddressCity') },
        {
            id: 'company.country',
            category: 8,
            type: 'string',
            description: I18n.m.getMessage('accountChangeAddressCountry'),
        },
        { id: 'company.phone', category: 8, type: 'string', description: I18n.m.getMessage('phone') },
        { id: 'company.email', category: 8, type: 'string', description: I18n.m.getMessage('archiveTeamEmail') },
    ];
    const journalCategories = [
        I18n.m.getMessage('reportTemplatesNotesDialogTableCategoriesGeneral'),
        I18n.m.getMessage('project'),
        I18n.m.getMessage('reportTypedaily'),
        I18n.m.getMessage('journalEmployee'),
        I18n.m.getMessage('journalAttendances'),
        I18n.m.getMessage('weather'),
        I18n.m.getMessage('journalAdditionalFields'),
        I18n.m.getMessage('journalImageText'),
        I18n.m.getMessage('company'),
        I18n.m.getMessage('customFields'),
    ];
    const [searchWords, setSearchWords] = React.useState('');
    const getJournalFields = (searchText) => {
        let sortedFields = fieldsDaily;
        if (searchText.length > 0) {
            const text = searchText.toLowerCase();
            const sortedCategories = journalCategories.filter((c) => c.toLowerCase().includes(text));
            sortedFields = sortedFields.filter((f) => sortedCategories.indexOf(journalCategories[f.category]) > -1 ||
                f.id.toLowerCase().includes(text) ||
                f.description.toLowerCase().includes(text) ||
                f.type.toLowerCase().includes(text));
        }
        return sortedFields;
    };
    const columns = [
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogTableCategories'),
            keyInData: 'category',
            style: { width: 250 },
            dataType: 'string',
            cellRenderer: (item, column, _index) => {
                return (<View key={`fields${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <MaterialText>{journalCategories[item.category]}</MaterialText>
          </View>);
            },
        },
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogDescription'),
            keyInData: 'description',
            style: { width: 400 },
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogType'),
            keyInData: 'type',
            style: { width: 100 },
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogVariable'),
            keyInData: 'id',
            style: { width: 300 },
            dataType: 'string',
        },
    ];
    const contentHeight = (ResizeEvent.current.contentHeight >= ThemeManager.style.breakpointM
        ? ResizeEvent.current.contentHeight - 180
        : 0.9 * ResizeEvent.current.contentHeight) - 180;
    const contentWidth = Math.min(props.maxDialogWith, ResizeEvent.current.contentWidth);
    return (<View style={{
            paddingHorizontal: 16,
            height: contentHeight,
            width: contentWidth,
        }}>
      <View style={{
            paddingVertical: 8,
        }}>
        <SearchBar searchOnChange={setSearchWords} searchBarValue={searchWords}/>
      </View>
      <Table data={getJournalFields(searchWords)} columns={columns} maxHeight={contentHeight - 52} actionItemsLength={0} tableName="CustomTicketReportsVarsTable" onRowPress={(item) => {
            const clipboardText = item.id;
            try {
                navigator.clipboard
                    .writeText(clipboardText)
                    .then(() => {
                    Toast.instance?.open({
                        title: I18n.m.getMessage('reportTemplatesNotesDialogCopiedToClipboard', { var: clipboardText }),
                        buttonTitle: I18n.m.getMessage('close'),
                        animationDuration: 150000,
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (e) {
                console.debug(`cant copy to clipboard: ${e}`);
            }
        }} sortBy="category"/>
    </View>);
}

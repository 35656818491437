"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectRoles = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var ProjectRolesEntity_1 = require("./entities/ProjectRolesEntity");
var ProjectRoles = function (_ReadModelClientOffli) {
  function ProjectRoles() {
    var _this;
    (0, _classCallCheck2.default)(this, ProjectRoles);
    _this = _callSuper(this, ProjectRoles, arguments);
    _this.exampleEntity = new ProjectRolesEntity_1.ProjectRolesEntity();
    _this.onEvents = [{
      eventName: 'ProjectRoleCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ProjectRoleChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ProjectRoleRightChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ProjectRoleNameChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ProjectRoleDeleted',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(ProjectRoles, _ReadModelClientOffli);
  return (0, _createClass2.default)(ProjectRoles, [{
    key: "onProjectRoleRightChanged",
    value: function () {
      var _onProjectRoleRightChanged = (0, _asyncToGenerator2.default)(function* (event, entity) {
        if ({}.hasOwnProperty.call(entity.readRights, event.data.right)) entity.readRights[event.data.right] = event.data.value;else if ({}.hasOwnProperty.call(entity.writeRights, event.data.right)) entity.writeRights[event.data.right] = event.data.value;
        entity.lastModifiedAt = event.createdAt;
        return entity;
      });
      function onProjectRoleRightChanged(_x, _x2) {
        return _onProjectRoleRightChanged.apply(this, arguments);
      }
      return onProjectRoleRightChanged;
    }()
  }, {
    key: "onProjectRoleDeleted",
    value: function () {
      var _onProjectRoleDeleted = (0, _asyncToGenerator2.default)(function* (event, entity) {
        entity.deleted = true;
        entity.lastModifiedAt = event.createdAt;
        return entity;
      });
      function onProjectRoleDeleted(_x3, _x4) {
        return _onProjectRoleDeleted.apply(this, arguments);
      }
      return onProjectRoleDeleted;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.ProjectRoles = ProjectRoles;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidateEmail = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var uuid = require("uuid");
var UserSetValidationTokenForEmail_1 = require("../../events/guest/UserSetValidationTokenForEmail");
var ValidateEmail = function (_CommandRoot_1$Comman) {
  function ValidateEmail(data) {
    var _this;
    (0, _classCallCheck2.default)(this, ValidateEmail);
    _this = _callSuper(this, ValidateEmail, [data, uuid.v1(), '']);
    _this.commandName = 'ValidateEmail';
    _this.aggregate = 'guests';
    return _this;
  }
  (0, _inherits2.default)(ValidateEmail, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ValidateEmail, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'token') {
          if (this.data.token.length <= 0) {
            throw {
              key: 'token',
              messageCode: 'passwordErrorRequireToken',
              message: 'Code wird benötigt'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new UserSetValidationTokenForEmail_1.UserSetValidationTokenForEmail({
          validationToken: this.data.token
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ValidateEmail = ValidateEmail;
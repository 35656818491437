import { Url } from 'cqrs-shared/src/uri/Url';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { DeleteAccount } from 'upmesh-core/src/client/commands/user/DeleteAccount';
import { I18n } from '../../i18n/I18n';
export class DeleteAccountCard extends PureComponent {
    constructor() {
        super(...arguments);
        this.deleteAccountQuestion = (_e) => {
            Dialog.instance?.open({
                content: this.renderFormDialog(),
                closeOnTouchOutside: false,
            });
        };
        this.abort = (_e) => {
            Dialog.instance?.close();
        };
        this.logout = () => {
            AuthClient.instance.logOut(true, Url.getURLfromString('/login#del')).catch((err) => console.debug(err));
        };
        this.onSaved = (_result) => {
            Dialog.instance?.close(() => {
                requestAnimationFrame(() => {
                    Routing.instance.alert.post({
                        title: I18n.m.getMessage('deleteAccountDialogSuccessTitle'),
                        text: I18n.m.getMessage('deleteAccountDialogSuccessText'),
                        buttons: [
                            <ContainedButton key="okButton" title={I18n.m.getMessage('close')} onPress={this.logout} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                        ],
                    });
                });
            });
        };
    }
    render() {
        return (<ContainedButton onPress={this.deleteAccountQuestion} backgroundColor="transparent" textColor={ThemeManager.style.black54} title={I18n.m.getMessage('deleteAccount')}/>);
    }
    renderFormDialog() {
        const currentData = {
            confirmed: false,
        };
        const dataOptions = new Map();
        dataOptions.set('confirmed', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('deleteAccountDialogConfirmaionText'),
                key: 'checkboxConfirm',
            },
        });
        return (<Form key="DeleteAccountForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('deleteAccountDialogTitle'),
                formText: I18n.m.getMessage('deleteAccountDialogText'),
            }} stickyActions={false} command={new DeleteAccount(currentData)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('deleteAccount')} saveButtonTextColor="#FFFFFF" saveButtonColor={ThemeManager.style.brandDanger} additionalButtons={[
                <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={this.abort} backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>,
            ]}/>);
    }
}

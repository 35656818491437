"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HolidayEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var HolidayEntity = function (_ReadModelEntity_1$Re) {
  function HolidayEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, HolidayEntity);
    _this = _callSuper(this, HolidayEntity, [obj]);
    _this.entityName = 'Holiday';
    _this.deleted = false;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(HolidayEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(HolidayEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new HolidayEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('name', new OdataEntity_1.OdataIndex(true));
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('regionIds', new OdataEntity_1.OdataIndex());
      baseMap.set('wageType', new OdataEntity_1.OdataIndex(true));
      baseMap.set('date', new OdataEntity_1.OdataIndex(true));
      baseMap.set('type', new OdataEntity_1.OdataIndex(true));
      baseMap.set('amount', new OdataEntity_1.OdataIndex());
      baseMap.set('annual', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }], [{
    key: "getHolidayForDayAndUser",
    value: function () {
      var _getHolidayForDayAndUser = (0, _asyncToGenerator2.default)(function* (companyMember, date) {
        var queryDate = HolidayEntity.getNormalizedDate(date);
        var queryDateAnnual = HolidayEntity.getNormalizedDate(date, true);
        try {
          var filterStringCompanyAndUser = `companyId eq '${companyMember.companyId}'`;
          var filterStringAnnual = `( date ge ${new Date(queryDateAnnual.getTime() - 1).toISOString()} and date le ${new Date(queryDateAnnual.getTime() + 1).toISOString()} )`;
          var filterString = `( date ge ${new Date(queryDate.getTime() - 1).toISOString()} and date le ${new Date(queryDate.getTime() + 1).toISOString()} )`;
          var finalFilterString = `deleted ne true and ${filterStringCompanyAndUser} and ( ${filterStringAnnual} or ${filterString} )`;
          var holiday = yield ReadModels_1.ReadModels.get('Holiday').get({
            filter: finalFilterString
          });
          return holiday.filter(function (element) {
            return companyMember.regionId != null && element.regionIds.findIndex(function (e) {
              return e === companyMember.regionId;
            }) > -1;
          });
        } catch (e) {
          return [];
        }
        return [];
      });
      function getHolidayForDayAndUser(_x, _x2) {
        return _getHolidayForDayAndUser.apply(this, arguments);
      }
      return getHolidayForDayAndUser;
    }()
  }, {
    key: "getNormalizedDate",
    value: function getNormalizedDate(date) {
      var annual = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var tmp = new Date(0);
      var workingDate = new Date(date);
      if (!annual) {
        tmp.setFullYear(workingDate.getFullYear());
      }
      tmp.setMonth(workingDate.getMonth());
      tmp.setDate(workingDate.getDate());
      return tmp;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.HolidayEntity = HolidayEntity;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sizePropInVert = exports.sizeProp = exports.scrollProp = exports.positionPropInvert = exports.positionProp = exports.SCROLL_CHANGE_REQUESTED = exports.SCROLL_CHANGE_OBSERVED = exports.DIRECTION_VERTICAL = exports.DIRECTION_HORIZONTAL = exports.ALIGN_START = exports.ALIGN_END = exports.ALIGN_CENTER = exports.ALIGN_AUTO = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var ALIGN_AUTO = exports.ALIGN_AUTO = 'auto';
var ALIGN_START = exports.ALIGN_START = 'start';
var ALIGN_CENTER = exports.ALIGN_CENTER = 'center';
var ALIGN_END = exports.ALIGN_END = 'end';
var DIRECTION_VERTICAL = exports.DIRECTION_VERTICAL = 'vertical';
var DIRECTION_HORIZONTAL = exports.DIRECTION_HORIZONTAL = 'horizontal';
var SCROLL_CHANGE_OBSERVED = exports.SCROLL_CHANGE_OBSERVED = 'observed';
var SCROLL_CHANGE_REQUESTED = exports.SCROLL_CHANGE_REQUESTED = 'requested';
var scrollProp = exports.scrollProp = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, DIRECTION_VERTICAL, 'scrollTop'), DIRECTION_HORIZONTAL, 'scrollLeft');
var sizeProp = exports.sizeProp = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, DIRECTION_VERTICAL, 'height'), DIRECTION_HORIZONTAL, 'width');
var sizePropInVert = exports.sizePropInVert = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, DIRECTION_VERTICAL, 'width'), DIRECTION_HORIZONTAL, 'height');
var positionProp = exports.positionProp = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, DIRECTION_VERTICAL, 'top'), DIRECTION_HORIZONTAL, 'left');
var positionPropInvert = exports.positionPropInvert = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, DIRECTION_VERTICAL, 'left'), DIRECTION_HORIZONTAL, 'top');
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardHeader = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _Avatar = require("./Avatar");
var _CacheableImage = require("./images/CacheableImage");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var CardHeader = exports.CardHeader = function (_PureComponent) {
  function CardHeader(props) {
    var _this;
    (0, _classCallCheck2.default)(this, CardHeader);
    _this = _callSuper(this, CardHeader, [props]);
    _this.onLayout = function (_e) {};
    _this.state = {};
    return _this;
  }
  (0, _inherits2.default)(CardHeader, _PureComponent);
  return (0, _createClass2.default)(CardHeader, [{
    key: "getImage",
    value: function getImage() {
      var _this$props = this.props,
        imageSource = _this$props.imageSource,
        imagePosition = _this$props.imagePosition,
        imageHeightForTopPosition = _this$props.imageHeightForTopPosition;
      if (imageSource == null) {
        return null;
      }
      var style = {
        width: 80,
        height: 80,
        marginTop: 16,
        marginRight: 16
      };
      if (imagePosition === 'top') {
        style = {
          width: '100%',
          height: imageHeightForTopPosition
        };
      }
      return (0, _jsxRuntime.jsx)(_CacheableImage.CacheableImage, {
        source: imageSource,
        style: Object.assign({}, style)
      });
    }
  }, {
    key: "getSubtitle",
    value: function getSubtitle() {
      var _this$props2 = this.props,
        subtitle = _this$props2.subtitle,
        avatarSource = _this$props2.avatarSource,
        subtitleLines = _this$props2.subtitleLines;
      var subtitleColor = this.props.subtitleColor != null && this.props.subtitleColor.length > 0 ? this.props.subtitleColor : _ThemeManager.ThemeManager.style.black54;
      if (subtitle == null || subtitle.length === 0) {
        return null;
      }
      var style = {};
      var textType = _MaterialText.MaterialTextTypes.Body1;
      if (avatarSource == null) {
        style = {
          marginTop: 12,
          marginBottom: 16,
          marginLeft: 16,
          marginRight: 16
        };
        textType = _MaterialText.MaterialTextTypes.H6;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: Object.assign({}, style),
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: textType,
          numberOfLines: subtitleLines,
          color: subtitleColor,
          children: subtitle
        })
      });
    }
  }, {
    key: "getTitle",
    value: function getTitle() {
      var _this$props3 = this.props,
        title = _this$props3.title,
        avatarSource = _this$props3.avatarSource,
        subtitle = _this$props3.subtitle,
        topRightButton = _this$props3.topRightButton;
      var titleColor = this.props.titleColor != null && this.props.titleColor.length > 0 ? this.props.titleColor : _ThemeManager.ThemeManager.style.black87;
      if (title == null || title.length === 0) {
        return null;
      }
      var style = {
        height: 34
      };
      if (avatarSource == null) {
        style = {
          marginTop: 24,
          marginBottom: subtitle != null && subtitle.length > 0 ? 0 : 16,
          marginLeft: 16,
          marginRight: topRightButton != null ? 56 : 16,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          height: 34
        };
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: Object.assign({}, style),
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H5,
          color: titleColor,
          children: title
        })
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props4 = this.props,
        avatarSource = _this$props4.avatarSource,
        imagePosition = _this$props4.imagePosition,
        imageSource = _this$props4.imageSource;
      if (avatarSource != null) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            height: 72,
            paddingLeft: 16,
            width: '100%'
          },
          children: [(0, _jsxRuntime.jsx)(_Avatar.Avatar, {
            source: avatarSource,
            style: {
              marginTop: 16
            }
          }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              height: 56,
              width: '100%',
              paddingLeft: 32
            },
            children: [this.getTitle(), this.getSubtitle()]
          }), this.getTopRightIcon()]
        });
      }
      if (imagePosition === 'right' && imageSource != null) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          },
          onLayout: this.onLayout,
          children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            children: [this.getTitle(), this.getTopRightIcon(), this.getSubtitle()]
          }), this.getImage()]
        });
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'relative',
          width: '100%'
        },
        children: [this.getImage(), this.getTitle(), this.getTopRightIcon(), this.getSubtitle()]
      });
    }
  }, {
    key: "getTopRightIcon",
    value: function getTopRightIcon() {
      var topRightButton = this.props.topRightButton;
      if (topRightButton != null) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            alignSelf: 'stretch',
            top: 24,
            right: 16
          },
          children: topRightButton
        });
      }
      return null;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        imagePosition: 'right',
        imageHeightForTopPosition: 200
      };
    }
  }]);
}(_react.PureComponent);
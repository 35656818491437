import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Animated, View } from 'react-native';
export class MenuButton extends PureComponent {
    constructor(props) {
        super(props);
        this.changeOpen = (open) => {
            this.setState({ open }, () => {
                this.animateTo(this.state.open ? 1 : 0);
            });
        };
        this.animateTo = (value) => {
            const { size } = this.state;
            const diff = size / 48;
            if (value === 1) {
                Animated.sequence([
                    Animated.parallel([
                        Animated.timing(this.animatedStroke1Top, {
                            toValue: 9 * diff,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                        Animated.timing(this.animatedStroke3Top, {
                            toValue: 9 * diff,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                        Animated.timing(this.animatedStroke2Opacity, {
                            toValue: 0,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                    ]),
                    Animated.parallel([
                        Animated.timing(this.animatedStroke1Degree, {
                            toValue: 1,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                        Animated.timing(this.animatedStroke3Degree, {
                            toValue: 1,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                    ]),
                ]).start();
            }
            else {
                Animated.sequence([
                    Animated.parallel([
                        Animated.timing(this.animatedStroke1Degree, {
                            toValue: 0,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                        Animated.timing(this.animatedStroke3Degree, {
                            toValue: 0,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                    ]),
                    Animated.parallel([
                        Animated.timing(this.animatedStroke1Top, {
                            toValue: 2 * diff,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                        Animated.timing(this.animatedStroke3Top, {
                            toValue: 14 * diff,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                        Animated.timing(this.animatedStroke2Opacity, {
                            toValue: 1,
                            duration: 200,
                            useNativeDriver: false,
                        }),
                    ]),
                ]).start();
            }
        };
        this.animatedStroke1Top = new Animated.Value(2);
        this.animatedStroke1Degree = new Animated.Value(0);
        this.animatedStroke2Opacity = new Animated.Value(1);
        this.animatedStroke3Degree = new Animated.Value(0);
        this.animatedStroke3Top = new Animated.Value(20);
        this.toggleMenu = () => {
            if (this.state.open) {
                this.props.closeFunction();
            }
            else {
                this.props.openFunction();
            }
        };
        this.animatedStroke1 = {
            top: this.animatedStroke1Top,
            transform: [
                {
                    rotate: this.animatedStroke1Degree.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0deg', '45deg'],
                    }),
                },
            ],
        };
        this.animatedStroke2 = {
            opacity: this.animatedStroke2Opacity,
        };
        this.animatedStroke3 = {
            top: this.animatedStroke3Top,
            transform: [
                {
                    rotate: this.animatedStroke3Degree.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0deg', '-45deg'],
                    }),
                },
            ],
        };
        this.state = {
            open: this.props.isOpen,
            size: 48,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isOpen !== prevState.open || nextProps.size !== prevState.size) {
            return { ...prevState, open: nextProps.isOpen, size: nextProps.size };
        }
        return null;
    }
    componentDidMount() {
        const { size } = this.state;
        const diff = size / 48;
        this.animatedStroke1Top.setValue(2 * diff);
        this.animatedStroke3Top.setValue(14 * diff);
    }
    componentDidUpdate() {
        this.changeOpen(this.state.open);
    }
    render() {
        const { size } = this.state;
        const diff = size / 48;
        return (<View style={{
                position: 'relative',
                padding: 0,
                height: size,
                width: size,
            }}>
        <View style={{
                position: 'absolute',
                padding: 0,
                height: size - 24 * diff,
                width: size - 24 * diff,
                top: 12 * diff,
                left: 12 * diff,
            }}>
          <Ripple onPress={this.toggleMenu} style={{
                position: 'absolute',
                padding: 0,
                height: 24 * diff,
                width: 24 * diff,
            }}>
            <View style={{
                position: 'absolute',
                padding: 0,
                top: 3,
                left: 3,
                height: 18 * diff,
                width: 18 * diff,
            }}>
              <Animated.View style={[
                {
                    position: 'absolute',
                    padding: 0,
                    height: 2,
                    width: 18 * diff,
                    backgroundColor: ThemeManager.style.menuButtonColor,
                    top: 2 * diff,
                },
                this.animatedStroke1,
            ]}/>
              <Animated.View style={[
                {
                    position: 'absolute',
                    padding: 0,
                    height: 2,
                    width: 18 * diff,
                    backgroundColor: ThemeManager.style.menuButtonColor,
                    top: 8 * diff,
                },
                this.animatedStroke2,
            ]}/>
              <Animated.View style={[
                {
                    position: 'absolute',
                    padding: 0,
                    height: 2,
                    width: 18 * diff,
                    backgroundColor: ThemeManager.style.menuButtonColor,
                    top: 14 * diff,
                },
                this.animatedStroke3,
            ]}/>
            </View>
          </Ripple>
        </View>
      </View>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutoCompleteEvents = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _tsEvents = require("ts-events");
var AutoCompleteEvents = exports.AutoCompleteEvents = (0, _createClass2.default)(function AutoCompleteEvents() {
  (0, _classCallCheck2.default)(this, AutoCompleteEvents);
});
AutoCompleteEvents.closeEvent = new _tsEvents.AsyncEvent();
AutoCompleteEvents.openEvent = new _tsEvents.AsyncEvent();
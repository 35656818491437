"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientDB = void 0;
var ClientDB = (0, _createClass2.default)(function ClientDB(localDb) {
  (0, _classCallCheck2.default)(this, ClientDB);
  this.initialized = false;
  this.localDb = localDb;
});
exports.ClientDB = ClientDB;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoredFileFilter = void 0;
var Filter_1 = require("./Filter");
var StoredFileFilter = function () {
  function StoredFileFilter() {
    (0, _classCallCheck2.default)(this, StoredFileFilter);
  }
  return (0, _createClass2.default)(StoredFileFilter, null, [{
    key: "isSet",
    value: function isSet(filter) {
      return filter != null && (filter.u != null && filter.u.length > 0 || filter.fe != null && filter.fe.length > 0 || filter.date != null && filter.date > 0 || filter.dateTo != null && filter.dateTo > 0);
    }
  }, {
    key: "filterFiles",
    value: function filterFiles(files, filter) {
      if (filter != null && StoredFileFilter.isSet(filter)) {
        var filtered = (0, _toConsumableArray2.default)(files);
        if (filter.u != null && filter.u.length > 0) filtered = StoredFileFilter.filterFilesByCreators(files, filter.u);
        if (filter.fe != null && filter.fe.length > 0) filtered = StoredFileFilter.filterFilesByFileextensionString(files, filter.fe);
        if (filter.date != null || filter.dateTo != null) filtered = StoredFileFilter.filterFilesByInterval(filtered, filter.date, filter.dateTo);
        return filtered;
      }
      return files;
    }
  }, {
    key: "filterFilesByInterval",
    value: function filterFilesByInterval(files, from, to) {
      var start = from != null ? from : null;
      var end = to != null ? to : null;
      return files.filter(function (t) {
        var createdAt = new Date(t.createdAt).getTime();
        return (start == null || createdAt > start) && (end == null || createdAt < end);
      });
    }
  }, {
    key: "filterFilesByCreators",
    value: function filterFilesByCreators(files, userIds) {
      if (userIds != null && userIds.length > 0) {
        var filterByCreatorId = function filterByCreatorId(element, _index, _array) {
          for (var i = 0; i < userIds.length; i += 1) {
            if (userIds[i] != null && userIds[i].length > 0) {
              if (userIds[i] === element.createdBy) {
                return true;
              }
            }
          }
          return false;
        };
        return files.filter(filterByCreatorId);
      }
      return [];
    }
  }, {
    key: "filterFilesByFileextensionString",
    value: function filterFilesByFileextensionString(files, filter) {
      function filterByFe(element, _index, _array) {
        if (filter != null && filter.length > 0) {
          for (var i = 0; i < filter.length; i += 1) {
            if (filter[i].toLowerCase() === 'null' && (element.orgFilename == null || element.orgFilename.length === 0)) {
              return true;
            }
            if (filter[i] != null && filter[i].length > 0) {
              if (element.orgFilename != null && element.orgFilename.length > 0) {
                if (element.orgFilename.toLowerCase().endsWith(filter[i].toLowerCase())) {
                  return true;
                }
              }
            }
          }
        } else if (element.orgFilename == null || element.orgFilename.length === 0) {
          return true;
        }
        return false;
      }
      return files.filter(filterByFe);
    }
  }, {
    key: "filterFilesByText",
    value: function filterFilesByText(text, files) {
      var words = text.trim().split(' ');
      if (files == null || files.length === 0) {
        return files;
      }
      function filterByTitle(element, _index, _array) {
        if (element.title == null) return false;
        var matches = 0;
        for (var i = 0; i < words.length; i += 1) {
          var w = words[i].trim();
          if (w.length > 0) {
            var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
            var matchA = element.title.match(regexp);
            if (matchA != null && matchA.length > 0) {
              matches += 1;
            }
          } else {
            matches += 1;
          }
        }
        return matches === words.length;
      }
      function filterByUsername(element, _index, _array) {
        if (element.uploadedByUsername == null) return false;
        var matches = 0;
        for (var i = 0; i < words.length; i += 1) {
          var w = words[i].trim();
          if (w.length > 0) {
            var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
            var matchA = element.uploadedByUsername.match(regexp);
            if (matchA != null && matchA.length > 0) {
              matches += 1;
            }
          } else {
            matches += 1;
          }
        }
        return matches === words.length;
      }
      function filterByOrgname(element, _index, _array) {
        if (element.orgFilename == null) return false;
        if (element.orgFilename.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var tmpSearch = String(element.orgFilename);
              var matchN = tmpSearch.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      }
      function filterByComment(element, _index, _array) {
        if (element.comment == null) return false;
        if (element.comment != null && element.comment.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.comment.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      }
      if (text != null && text.length > 0) {
        var filteredByTitle = files.filter(filterByTitle);
        var filteredByUsername = files.filter(filterByUsername);
        var filteredByOrgname = files.filter(filterByOrgname);
        var filteredByComment = files.filter(filterByComment);
        var result = [].concat((0, _toConsumableArray2.default)(filteredByUsername), (0, _toConsumableArray2.default)(filteredByTitle), (0, _toConsumableArray2.default)(filteredByOrgname), (0, _toConsumableArray2.default)(filteredByComment));
        var counts = [];
        result.forEach(function (item) {
          if (counts[item.id] === undefined) {
            counts[item.id] = 0;
          }
          counts[item.id] += 1;
          return counts;
        });
        var unique = result.filter(function (x, i, l) {
          return l.indexOf(x) === i;
        });
        var sorted = unique.sort(function (v1, v2) {
          return counts[v2.id] - counts[v1.id];
        });
        return sorted;
      }
      return files;
    }
  }]);
}();
exports.StoredFileFilter = StoredFileFilter;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServerEnvirement = void 0;
var ServerEnvirement = (0, _createClass2.default)(function ServerEnvirement() {
  (0, _classCallCheck2.default)(this, ServerEnvirement);
});
exports.ServerEnvirement = ServerEnvirement;
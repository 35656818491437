"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JournalFilter = exports.JournalEntityWithVersions = void 0;
var JournalEntity_1 = require("../entities/JournalEntity");
var Filter_1 = require("./Filter");
var JournalEntityWithVersions = function (_JournalEntity_1$Jour) {
  function JournalEntityWithVersions() {
    (0, _classCallCheck2.default)(this, JournalEntityWithVersions);
    return _callSuper(this, JournalEntityWithVersions, arguments);
  }
  (0, _inherits2.default)(JournalEntityWithVersions, _JournalEntity_1$Jour);
  return (0, _createClass2.default)(JournalEntityWithVersions);
}(JournalEntity_1.JournalEntity);
exports.JournalEntityWithVersions = JournalEntityWithVersions;
var JournalFilter = function () {
  function JournalFilter() {
    (0, _classCallCheck2.default)(this, JournalFilter);
  }
  return (0, _createClass2.default)(JournalFilter, null, [{
    key: "isSet",
    value: function isSet(filter) {
      return filter != null && (filter.u != null && filter.u.length > 0 || filter.t != null && filter.t.length > 0 || filter.is != null || filter.ie != null);
    }
  }, {
    key: "filterJournalsByInterval",
    value: function () {
      var _filterJournalsByInterval = (0, _asyncToGenerator2.default)(function* (journals, from, to) {
        var dateFrom = from != null ? new Date(from) : null;
        var dateTo = to != null ? new Date(to) : null;
        var start = dateFrom == null ? null : new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0, 0);
        var end = dateTo == null ? null : new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59, 59);
        return journals.filter(function (t) {
          t.createdAt = new Date(t.createdAt);
          return (start == null || t.createdAt > start) && (end == null || t.createdAt < end);
        });
      });
      function filterJournalsByInterval(_x, _x2, _x3) {
        return _filterJournalsByInterval.apply(this, arguments);
      }
      return filterJournalsByInterval;
    }()
  }, {
    key: "filterJournalsByCreators",
    value: function () {
      var _filterJournalsByCreators = (0, _asyncToGenerator2.default)(function* (journals, userIds) {
        if (userIds != null && userIds.length > 0) {
          var filterByCreatorId = function filterByCreatorId(element, _index, _array) {
            for (var i = 0; i < userIds.length; i += 1) {
              if (userIds[i] != null && userIds[i].length > 0) {
                if (userIds[i] === element.createdBy) {
                  return true;
                }
              }
            }
            return false;
          };
          return journals.filter(filterByCreatorId);
        }
        return [];
      });
      function filterJournalsByCreators(_x4, _x5) {
        return _filterJournalsByCreators.apply(this, arguments);
      }
      return filterJournalsByCreators;
    }()
  }, {
    key: "filterJournalsByText",
    value: function () {
      var _filterJournalsByText = (0, _asyncToGenerator2.default)(function* (text, journals) {
        var words = text.trim().split(' ');
        if (journals == null || journals.length === 0) {
          return journals;
        }
        function filterBySpecialOccurances(element, _index, _array) {
          if (element.specialOccurrences != null && element.specialOccurrences.length > 0) {
            for (var i = 0; i < words.length; i += 1) {
              if (words[i] != null && words[i].length > 0) {
                var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[i]), 'i');
                var matchN = element.specialOccurrences.match(regexp);
                if (matchN != null && matchN.length > 0) {
                  return true;
                }
              }
            }
          }
          return false;
        }
        function filterByWorkDone(element, _index, _array) {
          if (element.workDone != null && element.workDone.length > 0) {
            for (var i = 0; i < words.length; i += 1) {
              if (words[i] != null && words[i].length > 0) {
                var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[i]), 'i');
                var matchN = element.workDone.match(regexp);
                if (matchN != null && matchN.length > 0) {
                  return true;
                }
              }
            }
          }
          return false;
        }
        function filterByEmployees(element, _index, _array) {
          if (element.employee != null && element.employee.length > 0) {
            for (var i = 0; i < words.length; i += 1) {
              for (var j = 0; j < element.employee.length; j += 1) {
                if (words[i] != null && words[i].length > 0) {
                  var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[i]), 'i');
                  var name = element.employee[j].employee.groupName;
                  var matchN = name != null ? name.match(regexp) : null;
                  if (matchN != null && matchN.length > 0) return true;
                }
              }
            }
          }
          return false;
        }
        function filterByAttendanceName(element, _index, _array) {
          if (element.attendance != null && element.attendance.length > 0) {
            for (var i = 0; i < words.length; i += 1) {
              for (var j = 0; j < element.attendance.length; j += 1) {
                if (words[i] != null && words[i].length > 0) {
                  var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[i]), 'i');
                  var name = element.attendance[j].companyName;
                  var matchN = name != null ? name.match(regexp) : null;
                  if (matchN != null && matchN.length > 0) return true;
                }
              }
            }
          }
          return false;
        }
        function filterByAttendanceSpecifics(element, _index, _array) {
          if (element.attendance != null && element.attendance.length > 0) {
            for (var i = 0; i < words.length; i += 1) {
              for (var j = 0; j < element.attendance.length; j += 1) {
                if (words[i] != null && words[i].length > 0) {
                  var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[i]), 'i');
                  var specifics = element.attendance[j].specifics;
                  var matchN = specifics != null ? specifics.match(regexp) : null;
                  if (matchN != null && matchN.length > 0) return true;
                }
              }
            }
          }
          return false;
        }
        if (text != null && text.length > 0) {
          var filteredByWorkDone = journals.filter(filterByWorkDone);
          var filteredBySpecialOccurances = journals.filter(filterBySpecialOccurances);
          var filteredByEmployees = journals.filter(filterByEmployees);
          var filteredByAttendanceNames = journals.filter(filterByAttendanceName);
          var filteredByAttendanceSpecifics = journals.filter(filterByAttendanceSpecifics);
          var result = [].concat((0, _toConsumableArray2.default)(filteredByWorkDone), (0, _toConsumableArray2.default)(filteredBySpecialOccurances), (0, _toConsumableArray2.default)(filteredByEmployees), (0, _toConsumableArray2.default)(filteredByAttendanceNames), (0, _toConsumableArray2.default)(filteredByAttendanceSpecifics));
          var counts = [];
          result.forEach(function (item) {
            if (counts[item.id] === undefined) {
              counts[item.id] = 0;
            }
            counts[item.id] += 1;
            return counts;
          });
          var unique = result.filter(function (x, i, l) {
            return l.indexOf(x) === i;
          });
          var sorted = unique.sort(function (v1, v2) {
            return counts[v2.id] - counts[v1.id];
          });
          return sorted;
        }
        return journals;
      });
      function filterJournalsByText(_x6, _x7) {
        return _filterJournalsByText.apply(this, arguments);
      }
      return filterJournalsByText;
    }()
  }]);
}();
exports.JournalFilter = JournalFilter;
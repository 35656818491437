var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VoiceInputIcon = VoiceInputIcon;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactSpeechRecognition = _interopRequireWildcard(require("react-speech-recognition"));
var _Icon = require("../Icon");
var _ThemeManager = require("../../ThemeManager");
var _I18n = require("../../i18n/I18n");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function VoiceInputIcon(props) {
  var _props$iconSize2;
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    isListening = _useState2[0],
    setIsListening = _useState2[1];
  var _useState3 = (0, _react.useState)(''),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    recognizedText = _useState4[0],
    setRecognizedText = _useState4[1];
  var _useSpeechRecognition = (0, _reactSpeechRecognition.useSpeechRecognition)(),
    transcript = _useSpeechRecognition.transcript,
    listening = _useSpeechRecognition.listening,
    browserSupportsSpeechRecognition = _useSpeechRecognition.browserSupportsSpeechRecognition,
    resetTranscript = _useSpeechRecognition.resetTranscript;
  (0, _react.useEffect)(function () {
    if (props.onRecognizedText != null) {
      props.onRecognizedText(recognizedText);
    }
  }, [recognizedText]);
  var iconHandler = function iconHandler() {
    if (listening) {
      setRecognizedText(transcript);
      _reactSpeechRecognition.default.stopListening();
    } else {
      resetTranscript();
      _reactSpeechRecognition.default.startListening();
    }
    setIsListening(!isListening);
  };
  if (browserSupportsSpeechRecognition) {
    var _props$iconSize;
    return (0, _jsxRuntime.jsx)(_Icon.Icon, {
      onPress: iconHandler,
      icon: isListening ? 'record-circle-outline' : 'microphone-outline',
      toolTip: _I18n.I18n.m.getMessage('voiceControl'),
      color: isListening ? _ThemeManager.ThemeManager.style.brandDanger : _ThemeManager.ThemeManager.style.brandPrimary,
      iconSize: (_props$iconSize = props.iconSize) != null ? _props$iconSize : 24
    });
  }
  return (0, _jsxRuntime.jsx)(_Icon.Icon, {
    onPress: function onPress() {
      return void 0;
    },
    disabled: true,
    icon: isListening ? 'record-circle-outline' : 'microphone-outline',
    toolTip: _I18n.I18n.m.getMessage('voiceErrorNotSupportedBrowser'),
    iconSize: (_props$iconSize2 = props.iconSize) != null ? _props$iconSize2 : 24
  });
}
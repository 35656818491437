import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { SwitchListItem } from 'materialTheme/src/theme/components/SwitchListItem';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { I18n } from '../../i18n/I18n';
export function ReportTemplateDialog(props) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [asPdf, setAsPdf] = useState(false);
    useEffect(() => {
        const d = SimpleStorage.get('ticketTemplateInfo');
        if (d) {
            const a = JSON.parse(d);
            if (a['asPdf']) {
                setAsPdf(true);
            }
            if (a['index']) {
                const i = Number.parseInt(a['index'], 10);
                if (i > 0 && i < props.templates.length) {
                    setCurrentIndex(i);
                }
            }
        }
    }, []);
    useEffect(() => {
        const s = { index: currentIndex };
        if (asPdf)
            s['asPdf'] = 1;
        SimpleStorage.set('ticketTemplateInfo', JSON.stringify(s));
    }, [asPdf, currentIndex]);
    const list = [];
    props.templates.forEach((t) => list.push(t.title));
    const submitForm = (_e) => {
        Dialog.instance?.close();
        props.onSave(props.templates[currentIndex], asPdf);
    };
    return (<>
      <DialogTitle>{I18n.m.getMessage('reportTemplatesDialogTitle')}</DialogTitle>
      <DialogContent>
        <FormInputPicker list={list} selectedIndex={currentIndex} onChange={(v) => setCurrentIndex(v.index)}/>
        <SwitchListItem key={`sw_${asPdf.toString()}`} initValue={asPdf} title={I18n.m.getMessage('reportTemplatesDialogSaveAsPdf')} onChange={(v) => {
            setAsPdf(v);
        }}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => Dialog.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton onPress={submitForm} title={I18n.m.getMessage('reportCreateButton')}/>
      </DialogActions>
    </>);
}
export const openReportTemplateDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <ReportTemplateDialog {...props}/>,
    });
};

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataFile = exports.ODataFileHandler = void 0;
var uuid = require("uuid");
var path = require("path");
var ODataFileHandler = (0, _createClass2.default)(function ODataFileHandler() {
  (0, _classCallCheck2.default)(this, ODataFileHandler);
});
exports.ODataFileHandler = ODataFileHandler;
var ODataFile = function () {
  function ODataFile(fullPath, fileId) {
    (0, _classCallCheck2.default)(this, ODataFile);
    this.fullPath = fullPath;
    this.fileId = ODataFile.getCorrectedFileId(fullPath, fileId);
  }
  return (0, _createClass2.default)(ODataFile, null, [{
    key: "getCorrectedFileId",
    value: function getCorrectedFileId(fullPath) {
      var fileId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
      var ext = path.extname(fullPath).toLowerCase();
      var extFileId = path.extname(fileId).toLowerCase();
      return ext !== extFileId ? `${fileId}${ext}` : fileId;
    }
  }]);
}();
exports.ODataFile = ODataFile;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoggerReactNative = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _LokiDataBase = require("odatarepos/build/src/db/loki/LokiDataBase");
var _LokiODataDB = require("odatarepos/build/src/db/loki/LokiODataDB");
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _OdataEntity2 = require("odatarepos/build/src/entities/root/OdataEntity");
var _FileHandler = require("./FileHandler");
var _ClientDBadapterAsyncStorage = require("./odataDB/loki/ClientDBadapterAsyncStorage");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LogEntry = function (_OdataEntity) {
  function LogEntry(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, LogEntry);
    _this = _callSuper(this, LogEntry, [obj]);
    _this.entityName = 'LogEntry';
    if (obj != null) {
      _this.fill(obj);
    }
    return _this;
  }
  (0, _inherits2.default)(LogEntry, _OdataEntity);
  return (0, _createClass2.default)(LogEntry, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        return true;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = _OdataEntity2.OdataEntity.createBaseMap();
      baseMap.set('message', new _OdataEntity2.OdataIndex(true));
      baseMap.set('level', new _OdataEntity2.OdataIndex(true));
      return baseMap;
    }
  }, {
    key: "createOne",
    value: function createOne(obj) {
      return new LogEntry(obj);
    }
  }]);
}(_OdataEntity2.OdataEntity);
var LoggerReactNative = exports.LoggerReactNative = function () {
  function LoggerReactNative() {
    (0, _classCallCheck2.default)(this, LoggerReactNative);
  }
  return (0, _createClass2.default)(LoggerReactNative, null, [{
    key: "deepStringify",
    value: function deepStringify(c, orgConsole) {
      var deep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      try {
        if (deep > 3) return 'deep > 3';
        if (typeof c === 'string') return c;
        if (Array.isArray(c)) {
          var items = [];
          if (c.length > 100) return '[Array > 100]';
          c.forEach(function (m, i) {
            items.push(`${i} : ${LoggerReactNative.deepStringify(m, orgConsole, deep + 1)}`);
          });
          return `[${items.join(',')}]`;
        }
        if (typeof c === 'number') return c.toString(10);
        if (typeof c === 'object') {
          if (c['toString'] && typeof c['toString'] === 'function') {
            var st = c.toString();
            if (st !== '[object Object]') return st;
          }
          var _items = [];
          var t = Object.assign({}, c);
          for (var k in t) {
            if (_items.length > 100) {
              break;
            }
            if (t[k]) _items.push(`${k} : ${LoggerReactNative.deepStringify(t[k], orgConsole, deep + 1)}`);
          }
          return `{ ${_items.join(',')} }`;
        }
        return '';
      } catch (err) {
        orgConsole('CANT STRINGIFY', err);
        return '';
      }
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* () {
        var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        if (LoggerReactNative.initialized) return;
        LoggerReactNative.prefix = prefix;
        if (LoggerReactNative.transporter === 'loki') {
          var keys = yield _SimpleStorage.SimpleStorage.getAllKeys();
          var today = new Date();
          var expireDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14);
          for (var k of keys) {
            if (k.startsWith(`logs_${prefix}`)) {
              var getDate = k.substring(`logs_${prefix}`.length + 1);
              var d = getDate.split('-');
              if (d.length >= 3) {
                try {
                  var currentDate = new Date(Number.parseInt(d[0], 10), Number.parseInt(d[1], 10) - 1, Number.parseInt(d[2], 10));
                  if (currentDate instanceof Date) {
                    if (currentDate.getTime() < expireDate.getTime()) {
                      _SimpleStorage.SimpleStorage.remove(k);
                    }
                  } else {
                    _SimpleStorage.SimpleStorage.remove(k);
                  }
                } catch (_e) {
                  _SimpleStorage.SimpleStorage.remove(k);
                }
              } else {
                _SimpleStorage.SimpleStorage.remove(k);
              }
            }
          }
          LoggerReactNative.db = new _LokiODataDB.LokiODataDB();
          var adapter = yield _ClientDBadapterAsyncStorage.ClientDBadapterAsyncStorage.getAdapter(`logs_${prefix}`);
          var dbOptions = new _LokiDataBase.LokiDBOptions(new _FileHandler.FileHandler(`./files`), `logs_${prefix}.${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}-.json`, adapter);
          var initDB = yield LoggerReactNative.db.initDB(dbOptions);
          LoggerReactNative.rep = LoggerReactNative.db.getRepos(new LogEntry());
          if (initDB) {
            console.log = function () {};
            console.info = function () {};
            console.warn = function () {};
            console.error = function () {};
            console.debug = function () {};
          }
        } else if (LoggerReactNative.transporter === 'console') {
          console.log = function () {};
          console.info = function () {};
          console.warn = function () {};
          console.error = function () {};
          console.debug = function () {};
        }
        LoggerReactNative.initialized = true;
      });
      function init() {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }]);
}();
LoggerReactNative.initialized = false;
LoggerReactNative.transporter = 'loki';
LoggerReactNative.prefix = '';
LoggerReactNative.consoleFormat = function (orgConsole) {
  var messages = [];
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key2 = 1; _key2 < _len; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }
  args.forEach(function (m) {
    messages.push(LoggerReactNative.deepStringify(m, orgConsole));
  });
  return messages.join('; ');
};
LoggerReactNative.consoleNeDb = function (org, level) {
  return function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
      args[_key3] = arguments[_key3];
    }
    try {
      var message = LoggerReactNative.consoleFormat.apply(LoggerReactNative, [org].concat(args));
      var l = new LogEntry({
        message: message,
        level: level,
        date: new Date()
      });
      LoggerReactNative.rep.post(l).catch(function () {
        org('cant save entry', l);
        org.apply(void 0, args);
      });
    } catch (e) {
      org.apply(void 0, args);
    }
  };
};
LoggerReactNative.console = function (org, _level) {
  return function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key4 = 0; _key4 < _len3; _key4++) {
      args[_key4] = arguments[_key4];
    }
    org.apply(void 0, [`${new Date().toISOString()}:\n`].concat(args));
  };
};
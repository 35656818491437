import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import NumberPicker from 'materialTheme/src/theme/components/NumberPicker';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
export class ExtraPayFieldComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { currentField: props.field };
    }
    blur() { }
    clear() { }
    focus() { }
    getId() {
        return '';
    }
    setValue(value) {
        if (value != null)
            this.setState({ currentField: value });
    }
    getValue() {
        return this.state.currentField;
    }
    render() {
        const { currentField } = this.state;
        const { onChange } = this.props;
        if (onChange == null)
            return null;
        return (<View style={{ width: '100%' }}>
        <View style={{ width: '100%', flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <FormInputFilled onChange={(val) => {
                this.setState({
                    currentField: { ...currentField, unit: val },
                }, () => onChange({ ...currentField, unit: val }));
            }} initValue={currentField.unit} labelText={I18n.m.getMessage('extraPayFieldTypeNumberUnit')}/>
          </View>
          <View style={{ flex: 1, paddingLeft: 4 }}>
            <FormInputFilled onChange={(val) => {
                const min = val.length === 0 ? undefined : Number.parseFloat(val.replace(/,/g, '.'));
                this.setState({
                    currentField: { ...currentField, min },
                }, () => onChange({ ...currentField, min }));
            }} initValue={currentField.min?.toString(10)} labelText={I18n.m.getMessage('extraPayFieldTypeNumberMin')} keyboardType="numeric"/>
          </View>
          <View style={{ flex: 1, paddingLeft: 4 }}>
            <FormInputFilled onChange={(val) => {
                const max = val.length === 0 ? undefined : Number.parseFloat(val.replace(/,/g, '.'));
                this.setState({
                    currentField: { ...currentField, max },
                }, () => onChange({ ...currentField, max }));
            }} initValue={currentField.max?.toString(10)} labelText={I18n.m.getMessage('extraPayFieldTypeNumberMax')} keyboardType="numeric"/>
          </View>
        </View>
        <View style={{ width: '100%' }}>
          <NumberPicker formatTime={false} title={I18n.m.getMessage('ticketLayoutOptionsNumberDezimals')} min={0} max={4} initValue={currentField.dezimals ? currentField.dezimals : 0} onChange={(dezimals) => {
                this.setState({
                    currentField: { ...currentField, dezimals: dezimals },
                }, () => onChange({ ...currentField, dezimals: dezimals }));
            }}/>
          <FormInputPicker labelText={I18n.m.getMessage('ticketLayoutOptionsNumberDelimiter')} selectedIndex={currentField.delimiter ? currentField.delimiter : 0} list={['1.000,00', '1,000.00']} onChange={(v) => {
                this.setState({
                    currentField: { ...currentField, delimiter: v.index ? 1 : 0 },
                }, () => onChange({ ...currentField, delimiter: v.index ? 1 : 0 }));
            }}/>
        </View>
      </View>);
    }
}

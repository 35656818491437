"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserMails = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var UserMailsEntity_1 = require("./entities/UserMailsEntity");
var UserMails = function (_ReadModelClientOffli) {
  function UserMails() {
    var _this;
    (0, _classCallCheck2.default)(this, UserMails);
    _this = _callSuper(this, UserMails, arguments);
    _this.exampleEntity = new UserMailsEntity_1.UserMailsEntity();
    _this.authNeeded = true;
    _this.onEvents = [{
      eventName: 'UserSignedUp',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserAddedEmail',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserRemovedEmail',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(UserMails, _ReadModelClientOffli);
  return (0, _createClass2.default)(UserMails, [{
    key: "onUserAddedEmail",
    value: function () {
      var _onUserAddedEmail = (0, _asyncToGenerator2.default)(function* (ev, ent) {
        var ent2 = yield _superPropGet(UserMails, "defaultEventHandler", this, 3)([ev, ent]);
        ent2.deleted = false;
        return ent2;
      });
      function onUserAddedEmail(_x, _x2) {
        return _onUserAddedEmail.apply(this, arguments);
      }
      return onUserAddedEmail;
    }()
  }, {
    key: "onUserRemovedEmail",
    value: function () {
      var _onUserRemovedEmail = (0, _asyncToGenerator2.default)(function* (ev, ent) {
        var ent2 = yield _superPropGet(UserMails, "defaultEventHandler", this, 3)([ev, ent]);
        ent2.deleted = true;
        return ent2;
      });
      function onUserRemovedEmail(_x3, _x4) {
        return _onUserRemovedEmail.apply(this, arguments);
      }
      return onUserRemovedEmail;
    }()
  }, {
    key: "onUserSignedUp",
    value: function () {
      var _onUserSignedUp = (0, _asyncToGenerator2.default)(function* (ev, ent) {
        var ent2 = yield _superPropGet(UserMails, "defaultEventHandler", this, 3)([ev, ent]);
        ent2.deleted = false;
        return ent2;
      });
      function onUserSignedUp(_x5, _x6) {
        return _onUserSignedUp.apply(this, arguments);
      }
      return onUserSignedUp;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.UserMails = UserMails;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NetworkStatus = void 0;
var NetworkStatus = (0, _createClass2.default)(function NetworkStatus() {
  (0, _classCallCheck2.default)(this, NetworkStatus);
});
exports.NetworkStatus = NetworkStatus;
NetworkStatus.online = true;
import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { CustomFieldMultiPesronOptions } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { chipList } from '../../project/settings/ticketlayouts/TicketLayoutMultiPersonFieldOptions';
export function MultiPersonChip({ ticket, personField, labelStyle, errorResult }) {
    const o = new CustomFieldMultiPesronOptions(personField?.customField?.options);
    const [selectedChips, setSelectedChips] = useState(o.multiperson && o.multiperson.length > 0 ? chipList().filter((chip) => o.multiperson?.includes(chip.id)) : []);
    useEffect(() => {
        if (ticket && ticket?.fields) {
            ticket?.fields.forEach((field) => {
                if (field.id === personField.id && field.value) {
                    const filteredArr = chipList().filter((item) => field.value.includes(item.id));
                    setSelectedChips(filteredArr);
                }
            });
        }
    }, [ticket.fields]);
    return (<>
      <View style={labelStyle} key="MultiPersonLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {personField.label}
        </MaterialText>
      </View>
      <ChipGroup chips={selectedChips} bordered={false} availableChips={chipList()} onChanged={(items) => {
            setSelectedChips(items);
            if (ticket != null) {
                try {
                    const ids = [];
                    items.forEach((item) => {
                        if (item.id)
                            ids.push(item.id);
                    });
                    const c = new ChangeTicketCustomField({ fieldId: personField.id, value: ids }, ticket.id);
                    c.execute().catch((err) => errorResult(err));
                }
                catch (e) {
                    errorResult(e);
                }
            }
        }} key={`added_${true}_${selectedChips.toString()}`}/>
    </>);
}

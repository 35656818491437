import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { I18n } from '../../i18n/I18n';
export function TimeTrackingStatusChip(props) {
    if (props.timeTracking.status === 'Running') {
        return (<Chip title={I18n.m.getMessage('timeTrackingFilterStatusRunning')} backgroundColor={ThemeManager.style.brandWarning} textColor="#ffffff"/>);
    }
    if (props.timeTracking.status === 'Recorded') {
        return (<Chip title={I18n.m.getMessage('timeTrackingFilterStatusRecorded')} backgroundColor={ThemeManager.style.brandPrimary} textColor="#ffffff"/>);
    }
    if (props.timeTracking.status === 'Locked') {
        return (<Chip title={I18n.m.getMessage('timeTrackingFilterStatusLocked')} backgroundColor={ThemeManager.style.black54} textColor="#ffffff"/>);
    }
    return (<Chip title={I18n.m.getMessage('timeTrackingFilterStatusExported')} backgroundColor={ThemeManager.style.brandSecondary} textColor="#ffffff"/>);
    return null;
}

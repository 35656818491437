var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = Button;
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _Spinner = require("../Spinner");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function Button(props) {
  var title = props.title == null ? '' : props.title;
  var textColor = props.textColor == null ? '#FFFFFF' : props.textColor;
  var backgroundColor = props.backgroundColor == null ? _ThemeManager.ThemeManager.style.brandPrimary : props.backgroundColor;
  var rippleProps = props.rippleProps == null ? Object.assign({}, _Ripple.rippleDefaultProps) : Object.assign({}, _Ripple.rippleDefaultProps, props.rippleProps);
  var disabled = !!props.disabled;
  var full = !!props.full;
  if (disabled) {
    textColor = _ThemeManager.ThemeManager.style.disabledColor;
  }
  var renderWithoutIcon = function renderWithoutIcon() {
    if (title != null && title.length > 0) {
      return (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        type: _MaterialText.MaterialTextTypes.Button,
        color: disabled ? _ThemeManager.ThemeManager.style.disabledColor : textColor,
        centeredText: true,
        numberOfLines: 1,
        centeredBox: true,
        children: title.toUpperCase()
      });
    }
    return null;
  };
  var renderWithIcon = function renderWithIcon(iconProps) {
    var iconPosition = props.iconPosition;
    if (iconProps.color == null) {
      iconProps.color = disabled ? _ThemeManager.ThemeManager.style.disabledColor : textColor;
    }
    var margin = null;
    if (title != null && title.length > 0) {
      margin = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8)
        }
      });
    }
    if (iconPosition == null || iconPosition === 'left') {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row',
          alignSelf: 'center',
          justifyContent: 'center',
          alignContent: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, iconProps)), margin, renderWithoutIcon()]
      });
    }
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        alignContent: 'center'
      },
      children: [renderWithoutIcon(), margin, (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, iconProps))]
    });
  };
  var renderContent = function renderContent() {
    var icon = props.icon,
      loading = props.loading;
    var content = renderWithoutIcon();
    if (icon != null) {
      icon.toolTip = icon.toolTip != null ? icon.toolTip : '';
      content = renderWithIcon(icon);
    }
    if (loading) {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'relative',
          overflow: 'visible',
          height: '100%',
          justifyContent: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            opacity: 0
          },
          children: content
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            alignSelf: 'center',
            overflow: 'visible'
          },
          children: (0, _jsxRuntime.jsx)(_Spinner.Spinner, {
            small: true
          })
        })]
      });
    }
    return content;
  };
  var render = function render() {
    var raised = props.raised,
      radius = props.radius,
      accessibilityLabel = props.accessibilityLabel,
      icon = props.icon;
    var borderRadius = radius == null ? _ThemeManager.ThemeManager.style.borderRadius : radius;
    if (radius == null && (title == null || title.length === 0)) {
      borderRadius = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(18);
    }
    var tID = accessibilityLabel != null ? accessibilityLabel : title == null || title.length === 0 ? icon != null ? icon.icon : 'BUTTON' : title;
    var ripplePropsAdded = rippleProps != null ? Object.assign({}, rippleProps) : Object.assign({}, _Ripple.rippleDefaultProps);
    if (ripplePropsAdded != null) {
      ripplePropsAdded.style = {
        borderRadius: borderRadius,
        backgroundColor: disabled === true && backgroundColor !== 'transparent' ? 'rgba(0,0,0,0.12)' : backgroundColor,
        height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36),
        width: 'auto',
        alignSelf: full ? 'stretch' : 'flex-start',
        justifyContent: 'center',
        flex: undefined,
        alignContent: 'center'
      };
      if (title != null && title.length > 0) {
        ripplePropsAdded.style = Object.assign({}, ripplePropsAdded.style, {
          paddingLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(icon != null ? 12 : 16),
          paddingRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16)
        });
      }
      if (ripplePropsAdded.style.backgroundColor === textColor) {
        ripplePropsAdded.style.backgroundColor = (0, _color.default)(ripplePropsAdded.style.backgroundColor).lighten(0.25).toString();
      }
      ripplePropsAdded.onPress = props.onPress;
    }
    var bgc = {
      borderRadius: borderRadius,
      backgroundColor: !disabled ? backgroundColor : 'transparent',
      alignSelf: full ? 'stretch' : 'baseline',
      borderStyle: props.borderStyle,
      borderColor: props.outlineColor,
      borderWidth: props.borderStyle != null ? _ThemeManager.ThemeManager.style.borderWidth : 0
    };
    if (raised) {
      bgc = Object.assign({}, bgc, _ThemeManager.ThemeManager.style.elevation4);
    }
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: bgc,
      children: (0, _jsxRuntime.jsx)(_Ripple.Ripple, Object.assign({}, ripplePropsAdded, {
        testID: tID,
        accessibilityLabel: tID,
        hoverColor: _ThemeManager.ThemeManager.style.getDefaultHoverColor(textColor),
        rippleColor: _ThemeManager.ThemeManager.style.getDefaultRippleColor(textColor),
        disabled: disabled,
        children: renderContent()
      }))
    });
  };
  return render();
}
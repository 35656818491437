import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ChangeTicketSubTicketFrom } from 'upmesh-core/src/client/commands/tickets/ChangeTicketSubTicketFrom';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CurrentProject } from '../../project/CurrentProject';
export function UpTicket(props) {
    const [upTicket, setUpTicket] = useState();
    const [chipList, setChipList] = useState([]);
    const loadTicket = async () => {
        try {
            if (props.ticket.subTicketFrom != null && props.ticket.subTicketFrom.length > 0) {
                const ticket = await UpmeshClient.instance.modals.ticket.getById(props.ticket.subTicketFrom);
                setUpTicket(ticket);
                const chips = [
                    {
                        title: `#${ticket.ticketNumber ? ticket.ticketNumber : '?'} ${ticket.title}`,
                        onPressChipData: ticket.id,
                    },
                ];
                if (props.canEditSubTickets) {
                    const tickets = CurrentProject.instance.getCurrentTickets();
                    tickets.forEach((t) => {
                        chips.push({ title: `#${t.ticketNumber ? t.ticketNumber : '?'} ${t.title}`, onPressChipData: t.id });
                    });
                }
                setChipList(chips);
            }
        }
        catch (e) {
            console.debug(e);
        }
    };
    useEffect(() => {
        loadTicket().catch((err) => console.debug(err));
    }, [props.ticket.subTicketFrom]);
    if (upTicket == null)
        return null;
    return (<View style={{ width: '100%', ...props.labelStyle }}>
      <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('upTicket')}</MaterialText>
      <OpenableChip onPressMainChip={Routing.instance.openDialog('ticket', { id: upTicket.id })} selected={0} chipDisabled={!props.canEditSubTickets} showDeleteIcon={props.canEditSubTickets} chipsList={chipList} onPressChip={(e) => {
            try {
                const c = new ChangeTicketSubTicketFrom({ ticketId: e }, props.ticket.id);
                c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
            catch (e) {
                console.debug(e);
            }
        }}/>
    </View>);
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WeatherHourlyData = exports.WeatherHourly = void 0;
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _Weather2 = require("./Weather");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var WeatherHourly = exports.WeatherHourly = function () {
  function WeatherHourly() {
    (0, _classCallCheck2.default)(this, WeatherHourly);
  }
  return (0, _createClass2.default)(WeatherHourly, [{
    key: "fill",
    value: function fill(data) {
      this.summary = data.summary;
      this.icon = data.icon.replace('-', '');
      this.data = data.data;
    }
  }]);
}();
var WeatherHourlyData = exports.WeatherHourlyData = function (_Weather) {
  function WeatherHourlyData() {
    (0, _classCallCheck2.default)(this, WeatherHourlyData);
    return _callSuper(this, WeatherHourlyData, arguments);
  }
  (0, _inherits2.default)(WeatherHourlyData, _Weather);
  return (0, _createClass2.default)(WeatherHourlyData, [{
    key: "fill",
    value: function fill(data) {
      this.temperature = data.temperature;
      this.apparentTemperature = data.apparentTemperature;
    }
  }]);
}(_Weather2.Weather);
import { Url } from 'cqrs-shared/src/uri/Url';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { UploadButton } from 'materialTheme/src/theme/components/upload/UploadButton';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useRef, useState } from 'react';
import { Linking } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeCompanyReportTemplate } from 'upmesh-core/src/client/commands/companies/reporttemplates/ChangeCompanyReportTemplate';
import { ReportTemplatesEntity } from 'upmesh-core/src/client/query/entities/simple/ReportTemplatesEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function AddOrChangeReportTemplate(props) {
    const uploadId = useRef(uuid.v1());
    const [formError, setFormError] = useState(new Map());
    const [current, setCurrent] = useState(props.reportTemplate
        ? new ReportTemplatesEntity(props.reportTemplate)
        : new ReportTemplatesEntity({ reportType: 'ticket' }));
    const [startUpload, setStartUpload] = useState(false);
    const possibleTypesRaw = ['ticket', 'daily'];
    const possibleTypes = possibleTypesRaw.map((type) => {
        return {
            title: I18n.m.getMessage(`reportType${type}`),
            data: type,
        };
    });
    const onUploadStatus = (countedUploads, withErrorOrRemoved) => {
        if (countedUploads === 0) {
            setStartUpload(false);
            if (!withErrorOrRemoved)
                Dialog.instance?.close();
        }
    };
    const saveForm = async () => {
        if (props.companySettings != null) {
            if (props.reportTemplate == null) {
                if (!startUpload) {
                    setStartUpload(true);
                }
            }
            else {
                const c = new ChangeCompanyReportTemplate({ title: current.title, reportType: current.reportType, companyId: props.companySettings.id }, current.id);
                await c.execute(ClientStore.commandStore);
                Dialog.instance?.close();
            }
        }
    };
    const submitForm = (_e) => {
        if (current.title == null || current.title.length === 0) {
            const s = new Map();
            s.set('title', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        setFormError(new Map());
        saveForm().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    const downloadExamples = (_e) => {
        const link = 'https://upmesh.de/upmesh_template_examples.zip';
        Linking.openURL(link).catch((err) => console.debug(err));
    };
    return (<>
      <DialogTitle>
        {props.reportTemplate
            ? I18n.m.getMessage('reportTemplatesTitleChange', { title: props.reportTemplate.title })
            : I18n.m.getMessage('reportTemplatesTitleCreate')}
      </DialogTitle>
      <DialogContent>
        <FormInputFilled helperText={formError.has('title') ? formError.get('title') : ''} error={formError.has('title')} labelText="Name" initValue={current.title} onChange={(v) => {
            setCurrent(new ReportTemplatesEntity({ ...current, title: v }));
        }}/>
        <FormInputPicker labelText={I18n.m.getMessage('companyReportTemplateType')} selectedIndex={possibleTypesRaw.findIndex((tmp) => tmp === current.reportType)} list={possibleTypes} onChange={(change) => {
            const { data } = change.value;
            setCurrent(new ReportTemplatesEntity({ ...current, reportType: data }));
        }}/>
        {props.reportTemplate == null && (<UploadButton uploadText={I18n.m.getMessage('selectFile')} uploadIcon="cloud-upload-outline" id={uploadId.current} startUploadImmediately={false} startNow={startUpload} onUploading={onUploadStatus} multiple={false} data={{ title: current.title, reportType: current.reportType }} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/companysettings/${props.companySettings.id}/reporttemplate`)}/>)}
        {props.reportTemplate == null && (<ContainedButton full key="examples" title={I18n.m.getMessage('reportTemplatesDownloadExamples')} onPress={downloadExamples} textColor={ThemeManager.style.brandPrimary} backgroundColor="#FFFFFF"/>)}
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => Dialog.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton onPress={submitForm} title={props.reportTemplate ? I18n.m.getMessage('save') : I18n.m.getMessage('create')}/>
      </DialogActions>
    </>);
}
export const openAddOrChangeReportTemplate = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <AddOrChangeReportTemplate {...props}/>,
    });
};

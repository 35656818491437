import React, { useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import color from 'color';
import { I18n } from '../../i18n/I18n';
export function FolderStructure(props) {
    const [openedFolder, setOpenedFolder] = React.useState([]);
    const [openedFolderForSearch, setOpenedFolderForSearch] = React.useState([]);
    const [init, setInit] = React.useState(false);
    const [folders, setFolders] = React.useState(props.folders);
    const getToOpenFolder = (id) => {
        const toOpen = [];
        const ind = folders.findIndex((e) => e.id === id);
        if (ind > 0) {
            if (!toOpen.includes('public'))
                toOpen.push('public');
            const f = folders[ind];
            if (f != null && f.subFolderFromId != null && f.subFolderFromId.length > 0) {
                if (!toOpen.includes(f.subFolderFromId))
                    toOpen.push(f.subFolderFromId);
                toOpen.push(...getToOpenFolder(f.subFolderFromId));
            }
        }
        return toOpen;
    };
    useEffect(() => {
        const { projectId, folder } = props;
        let openFolder = openedFolder;
        try {
            const s = SimpleStorage.get(`openFolders_${projectId}`);
            if (s != null)
                openFolder = JSON.parse(s);
        }
        catch (e) {
            console.debug('cant get open folders', e);
        }
        if (folder != null && folder !== 'private' && folder !== 'public' && folder !== 'all' && folder.length > 0) {
            const toOpen = getToOpenFolder(folder);
            toOpen.forEach((f) => {
                if (!openFolder.includes(f))
                    openFolder.push(f);
            });
        }
        setInit(true);
        setOpenedFolder([...openFolder]);
    }, []);
    useEffect(() => {
        const { excludedFolders } = props;
        let { folders } = props;
        if (excludedFolders && excludedFolders.length > 0)
            folders = folders.filter((f) => !excludedFolders.includes(f.id));
        setFolders(folders);
    }, [props.folders]);
    useEffect(() => {
        const { searchString } = props;
        const openFolders = [];
        if (searchString && searchString.length > 0) {
            const foundFolders = folders.filter((f) => f.title.toLowerCase().includes(searchString.toLowerCase()));
            for (let i = 0; i < foundFolders.length; i += 1) {
                let folder = foundFolders[i];
                while (folder != null && folder.subFolderFromId != null) {
                    openFolders.push(folder.subFolderFromId);
                    folder =
                        folder && folder.subFolderFromId
                            ?
                                folders.find((f) => folder && folder.subFolderFromId && f.id === folder.subFolderFromId)
                            : undefined;
                }
            }
            if (openFolders.length > 0 || foundFolders.length > 0)
                openFolders.push('public');
        }
        setOpenedFolderForSearch(openFolders);
    }, [props.searchString]);
    const toggleFolder = (id) => (_e) => {
        const index = openedFolder.findIndex((e) => e === id);
        if (index > -1)
            openedFolder.splice(index, 1);
        else
            openedFolder.push(id);
        const { projectId } = props;
        SimpleStorage.set(`openFolders_${projectId}`, JSON.stringify(openedFolder));
        setOpenedFolder([...openedFolder]);
    };
    const renderFolderItem = (folderName, folderId, icon, openable = false, folderEntity, ebene = 0) => {
        const { folder, menuIcon, onPressFolder, borderRadiusRight, searchString, onPressMenuItem } = props;
        const isOpen = openedFolder.includes(folderId) || openedFolderForSearch.includes(folderId);
        let bgColor = props.bgColor || ThemeManager.style.appBgColor;
        if (searchString && folderName.toLowerCase().includes(searchString))
            bgColor = color(ThemeManager.style.brandPrimary).alpha(0.2).toString();
        return (<View key={`folder_${folderId}`} style={{
                position: 'relative',
                width: '100%',
                height: 40,
                flexDirection: 'row',
                backgroundColor: folder === folderId ? ThemeManager.style.brandPrimary : bgColor,
                justifyContent: 'space-between',
                borderRadius: 0,
                borderBottomRightRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: borderRadiusRight ? 6 : 0,
                borderTopLeftRadius: borderRadiusRight ? 6 : 0,
            }}>
        <Ripple style={{
                position: 'absolute',
                flexDirection: 'row',
                width: '100%',
                flex: 1,
                height: 40,
            }} onPress={(e) => {
                if (onPressFolder)
                    onPressFolder(folderId)(e);
            }} hoverColor={folder === folderId ? ThemeManager.style.brandPrimary : '#dddddf'}/>
        <View collapsable={false} pointerEvents="box-none" style={{
                position: 'absolute',
                flexDirection: 'row',
                width: '100%',
                flex: 1,
                height: 40,
                paddingLeft: ebene * 8,
            }}>
          {openable ? (<View style={{ width: 40 }}>
              <Icon icon={isOpen ? 'menu-down' : 'menu-right'} toolTip="" onPress={toggleFolder(folderId)} outerSize={40} iconSize={24} color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}/>
            </View>) : (<View style={{ width: 40 }}/>)}
          <View pointerEvents="none" style={{ flexDirection: 'row', flex: 1, height: 40 }}>
            <Icon toolTip={folderName} icon={icon} color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}/>
            <View pointerEvents="none" style={{ flexDirection: 'row', flex: 1, height: 40 }}>
              <MaterialText fixedWidth="100%" numberOfLines={1} centeredBox color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}>
                {folderName}
              </MaterialText>
            </View>
          </View>
          {(folderEntity != null || folderId === 'public') && menuIcon ? (<View style={{ height: 40, justifyContent: 'center', marginRight: 2 }}>
              <Icon icon={menuIcon} toolTip="" outerSize={36} disabled={!onPressMenuItem} onPress={(e) => {
                    if (onPressMenuItem)
                        onPressMenuItem(folderId)(e);
                }} color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}/>
            </View>) : (<View style={{ width: 36 }}/>)}
        </View>
      </View>);
    };
    const renderFolder = (mainId = 'public', ebene = 1) => {
        const items = [];
        folders.forEach((e) => {
            const subFolderFromId = e.subFolderFromId != null ? e.subFolderFromId : 'public';
            if ((mainId == null && subFolderFromId == null) || (mainId != null && subFolderFromId === mainId)) {
                const isOpen = openedFolder.includes(e.id) || openedFolderForSearch.includes(e.id);
                const { excludedFolders } = props;
                if (excludedFolders == null || !excludedFolders.includes(e.id)) {
                    items.push(renderFolderItem(e.title, e.id, 'folder', true, e, ebene));
                    if (isOpen)
                        items.push(...renderFolder(e.id, ebene + 1));
                }
            }
        });
        return items;
    };
    const renderUserFolders = () => {
        const publicIsOpen = openedFolder.includes('public') || openedFolderForSearch.includes('public');
        if (!publicIsOpen || folders == null || folders.length === 0)
            return null;
        return renderFolder();
    };
    return !init ? (<Spinner />) : (<ScrollView style={{ width: props.width, height: '100%' }} contentContainerStyle={{ paddingBottom: 72 }}>
      {props.excludedFolders == null || !props.excludedFolders.includes('public') ? (renderFolderItem(I18n.m.getMessage('filesFolderPublic'), 'public', 'folder-home', true, undefined, 0)) : (<View />)}
      {renderUserFolders()}
      {props.excludedFolders == null || !props.excludedFolders.includes('private') ? (renderFolderItem(I18n.m.getMessage('filesFolderPrivate'), 'private', 'folder-lock', false)) : (<View />)}
      {props.excludedFolders == null || !props.excludedFolders.includes('tickets') ? (renderFolderItem(I18n.m.getMessage('tickets'), 'tickets', 'folder-marker', false)) : (<View />)}
      {props.excludedFolders == null || !props.excludedFolders.includes('reports') ? (renderFolderItem(I18n.m.getMessage('menuProjectDailyReports'), 'reports', 'calendar', false)) : (<View />)}
    </ScrollView>);
}

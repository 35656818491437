"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddProjectTag = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ProjectTagAdded_1 = require("../../../events/project/tags/ProjectTagAdded");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var AddProjectTag = function (_ClientProjectCommand) {
  function AddProjectTag(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddProjectTag);
    _this = _callSuper(this, AddProjectTag, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'AddProjectTag';
    return _this;
  }
  (0, _inherits2.default)(AddProjectTag, _ClientProjectCommand);
  return (0, _createClass2.default)(AddProjectTag, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield _superPropGet(AddProjectTag, "checkProjectStatusAndGeneralAccess", this, 3)([this.entityId, userId]);
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        if (project.projectSubscription == null || project.projectSubscription === 'basic') {
          throw {
            message: 'Min. Pro-Projekt benötigt',
            messageCode: 'notInBasicProject'
          };
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.entityId, userId, 'commandChangeProjectTags')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ProjectTagAdded_1.ProjectTagAdded(this.entityId, Object.assign({}, this.data));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _project$tags,
          _this2 = this;
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        if (this.data.tag.tagName == null || this.data.tag.tagName.length <= 0) {
          throw {
            key: 'tag.tagName',
            messageCode: 'tagsErrorNameRequired',
            message: 'Tag Name benötigt'
          };
        }
        if (this.data.tag.groupName == null || this.data.tag.groupName.length <= 0) {
          throw {
            key: 'tag.groupName',
            messageCode: 'tagsErrorGroupRequired',
            message: 'Taggruppe wird benötigt'
          };
        }
        if (this.data.tag.color == null || this.data.tag.color.length <= 0) {
          throw {
            key: 'tag.color',
            messageCode: 'tagsErrorColorRequired',
            message: 'Gruppenfarbe wird benötigt'
          };
        }
        if (project.tags != null && ((_project$tags = project.tags) == null ? void 0 : _project$tags.findIndex(function (t) {
          return _this2.data.tag.tagName === t.tagName;
        })) > -1) {
          throw {
            message: 'Das Tag existiert bereits im Projekt',
            messageCode: 'projectTagAlreadyExists'
          };
        }
        if (project.tags != null && project.tags.findIndex(function (t) {
          return _this2.data.tag.color === t.color && _this2.data.tag.groupName !== t.groupName;
        }) > -1) {
          throw {
            message: 'Die Farbe wird schon von einer anderen Gruppe verwendet',
            messageCode: 'companyTagColorAlreadyUsed'
          };
        }
        if (key == null || key === 'tag') {
          if (this.data.tag.tagName.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'tag',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
          if (this.data.tag.groupName.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'tag',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
          if (this.data.tag.color.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'tag',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.AddProjectTag = AddProjectTag;
import React from 'react';
import { Card } from 'materialTheme/src/theme/components/Card';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { Linking, View } from 'react-native';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { I18n } from '../../i18n/I18n';
const styles = {
    card: {
        justifyContent: 'center',
        alignContent: 'center',
        maxWidth: 560,
        height: 'auto',
        padding: ThemeManager.style.contentPaddingValue,
        alignSelf: 'center',
    },
    buttonWrapper: {
        height: 'auto',
        alignSelf: 'center',
        paddingTop: ThemeManager.style.contentPaddingValue,
    },
};
const noCompanyConnectionCard = () => {
    const openUrl = (url) => () => {
        Linking.openURL(url).catch((err) => console.debug(err));
        Menu.instance?.close();
    };
    return (<Card style={styles.card}>
      <MaterialText>{I18n.m.getMessage('companyHasNoCompanyText')}</MaterialText>
      <View style={styles.buttonWrapper}>
        <ContainedButton onPress={openUrl('tel:00495451593360')} title={`${I18n.m.getMessage('phone')}: +49 5451 5933 60`} backgroundColor={ThemeManager.style.brandSecondary}/>
      </View>
    </Card>);
};
export default noCompanyConnectionCard;

import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Picker } from 'materialTheme/src/theme/components/Picker';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../i18n/I18n';
function VersionsDialog(props) {
    const [index, setIndex] = useState(0);
    const [appText, setAppText] = useState([]);
    useEffect(() => { }, []);
    const onChangePicker = (newIndex) => {
        setIndex(newIndex);
    };
    useEffect(() => {
        if (props.versionList != null && props.versionList.length > 0 && props.versions != null) {
            const version = props.versionList[index];
            const result = props.versions.find((item) => item.version.name === version);
            const featureTexts = [];
            if (result != null) {
                for (const feature of result.features) {
                    featureTexts.push(<View key={`feature_${feature}`} style={{ flexDirection: 'row', width: '100%', paddingTop: 4 }}>
              <View style={{ width: 24 }}>
                <MaterialText strong>•</MaterialText>
              </View>
              <View style={{ flex: 1, flexGrow: 1 }}>
                <MaterialText>{feature}</MaterialText>
              </View>
            </View>);
                }
            }
            setAppText(featureTexts);
        }
    }, [index]);
    return (<View>
      {props.versionList != null && props.versionList.length > 0 ? (<Picker list={props.versionList} selectedIndex={index} onChangePicker={onChangePicker}/>) : null}
      <MaterialText strong>{I18n.m.getMessage('whatsNewFeatures')}</MaterialText>
      {appText}
    </View>);
}
export function VersionsCard(props) {
    const [releaseNotes, setReleaseNotes] = useState([]);
    const [versions, setVersions] = useState([]);
    useEffect(() => {
        (async function () {
            const url = `${UpmeshClient.instance.url}/releasenotes/all`;
            const headers = {};
            headers['Accept'] = 'application/json';
            headers['accept-language'] = I18n.m.currentLanguage;
            const result = await fetch(url, { headers, method: 'GET' });
            const body = await result.json();
            setReleaseNotes(body);
            const versions = [];
            for (const version of body) {
                versions.push(version.version.name);
            }
            setVersions(versions);
        })().catch((err) => console.debug(err));
    }, []);
    const openReleaseDialog = () => {
        if (versions != null && versions.length > 0) {
            RouterControl.instance.openUnRoutedDialog({
                content: <VersionsDialog versions={releaseNotes} versionList={versions}/>,
                title: `${I18n.m.getMessage('versionNumber')}: ${props.appVersion}`,
                showCloseIcon: true,
                fullscreenResponsive: true,
            });
        }
    };
    return (<View>
      <View style={{
            marginLeft: 8 + ThemeManager.style.contentPaddingValue,
            marginTop: 8,
        }}>
        <MaterialText>{I18n.m.getMessage('versionNumber')}</MaterialText>
      </View>
      <Card>
        <View style={{
            width: '100%',
            padding: ThemeManager.style.contentPaddingValue,
            ...ThemeManager.style.bottomBorder,
        }}>
          <MaterialText type={MaterialTextTypes.Overline} color="#aaaaaa">
            {props.appVersion}
          </MaterialText>
        </View>
        <View style={{ padding: 8, paddingLeft: 0 }}>
          <ContainedButton disabled={versions == null || versions.length === 0} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={openReleaseDialog} title={I18n.m.getMessage('whatsNewShow')}/>
        </View>
      </Card>
    </View>);
}

import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { ProOnlyDialogContent } from 'materialTheme/src/theme/components/ProOnlyDialogContent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RemoveProjectUser } from 'upmesh-core/src/client/commands/user/RemoveProjectUser';
import { ProjectEntity } from 'upmesh-core/src/client/query/entities/ProjectEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CommandReadModels } from 'upmesh-core/src/server/webserver/commands/CommandReadModels';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { I18n } from '../i18n/I18n';
const maxSizeReachedImage = require('../assets/img/maxProjectSizeReached.png');
export class DefaultErrorHandler {
    static getDefaultErrorMessages(e, i18n = I18n.m, reportError = false) {
        let errorMessage = typeof e === 'string' ? e : JSON.stringify(e);
        if (errorMessage === 'tokenError' || (e['messageCode'] != null && e['messageCode'] === 'tokenError')) {
            AuthClient.instance.validateToken('on Token Error').catch((err) => console.debug(err));
        }
        if (Array.isArray(e)) {
            errorMessage = '';
            e.forEach((err) => {
                errorMessage += DefaultErrorHandler.getDefaultErrorMessages(err);
            });
            return errorMessage;
        }
        if (typeof e['0'] === 'object') {
            errorMessage = '';
            for (const err in e) {
                if (err !== 'message')
                    errorMessage += `${DefaultErrorHandler.getDefaultErrorMessages(e[err], i18n, reportError)}\n`;
            }
            return errorMessage;
        }
        if (e['messageCode'] != null && i18n != null) {
            errorMessage = i18n.getMessage(e['messageCode'], e['messageData']);
            if ((e['messageCode'] === 'badRequest' || e['messageCode'] === 'internalServer') && e['message'] != null) {
                errorMessage += `: ${e['message']}`;
            }
        }
        else if (e['message'] != null) {
            errorMessage = e['message'];
        }
        else if (Array.isArray(e)) {
            errorMessage = '';
            e.forEach((err) => (errorMessage += `${DefaultErrorHandler.getDefaultErrorMessages(err, i18n)}\n`));
        }
        if (reportError) {
            ErrorReporter.sendReport({
                data: e,
                type: 'error',
                subject: errorMessage,
            });
        }
        return errorMessage;
    }
    static showDefaultErrorAlert(e, i18n = I18n.m, reportError = false) {
        const text = DefaultErrorHandler.getDefaultErrorMessages(e, i18n, reportError);
        Routing.instance.alert.post({ text });
    }
    static async getProjectErrors(projectId, showAlert = true, callbackBeforeAlert, i18n = I18n.m) {
        if (projectId == null || projectId.length === 0)
            return true;
        const buttons = [
            <ContainedButton key="okButton" title={I18n.m.getMessage('close')} onPress={Alert.instance?.close} full={false} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
        ];
        try {
            await ProjectEntity.checkProjectStatusAndGeneralAccess(projectId, CurrentUser.userId);
            return false;
        }
        catch (e) {
            let errorMessage = typeof e === 'string' ? e : JSON.stringify(e);
            if (typeof e !== 'string') {
                if (e['messageCode'] != null) {
                    if (e['messageCode'] === 'projectLocked') {
                        const project = await UpmeshClient.instance.modals.project.getById(projectId);
                        if (CurrentUser.userId === project.creator) {
                            errorMessage = i18n.getMessage('projectLockedOwner');
                        }
                        else {
                            const owner = await UserOrCompany.getById(project.creator);
                            errorMessage = i18n.getMessage('projectLocked', { ownerName: owner.getFullName() });
                            buttons.unshift(<ContainedButton key="leaveButton" title={I18n.m.getMessage('teamLeaveProject')} onPress={DefaultErrorHandler.leaveProject(projectId)} full={false} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>);
                        }
                    }
                    else if (e['messageCode'] === 'projectMaximumSizeReached') {
                        showAlert = false;
                        RouterControl.instance.openUnRoutedDialog({
                            scrollable: true,
                            contentPadding: false,
                            showCloseIcon: true,
                            content: (<ProOnlyDialogContent headline={i18n.getMessage('maxSizeReached')} description={i18n.getMessage('maxSizeReachedText')} buttonText={i18n.getMessage('maxSizeReachedUpgradeButton')} buttonFunction={() => {
                                    Dialog.instance?.close();
                                    Routing.instance.goTo('/subscriptionPlanView');
                                }} imageSource={maxSizeReachedImage}/>),
                        });
                    }
                    else if (e['messageCode'] === 'projectErrorProOrEnterpriseNeededForRole') {
                        const project = await UpmeshClient.instance.modals.project.getById(projectId);
                        let ownerName = '';
                        if (project.creator.startsWith('company')) {
                            const companyId = project.creator.slice(7);
                            const company = await UpmeshClient.instance.modals.company.getById(companyId);
                            ownerName = company.company;
                        }
                        else {
                            const owner = await UserOrCompany.getById(project.creator);
                            ownerName = owner.getFullName();
                        }
                        errorMessage = i18n.getMessage('projectErrorProOrEnterpriseNeededForRole', { ownerName });
                    }
                    else {
                        errorMessage = i18n.getMessage(e['messageCode']);
                    }
                }
                else if (e['message'] != null) {
                    errorMessage = e['message'];
                }
            }
            if (callbackBeforeAlert != null) {
                callbackBeforeAlert(e);
            }
            if (errorMessage === 'forbidden' || (e['messageCode'] != null && e['messageCode'] === 'forbidden')) {
                console.error('got main project site and remove project');
                if (typeof CommandReadModels.project['reSync'] === 'function') {
                    CommandReadModels.project['reSync']({ filter: `id eq '${projectId}'`, deleteBefore: true });
                }
            }
            if (errorMessage === 'tokenError' || (e['messageCode'] != null && e['messageCode'] === 'tokenError')) {
                await AuthClient.instance.validateToken('on Token Error');
            }
            if (showAlert) {
                requestAnimationFrame(() => {
                    Routing.instance.alert.post({ text: errorMessage, buttons });
                });
            }
            return true;
        }
    }
}
DefaultErrorHandler.leaveProject = (projectId) => (_e) => {
    Alert.instance?.close(() => {
        console.debug('Leave Project', projectId);
        UpmeshClient.instance.modals.projectUser
            .get({
            filter: `projectId eq '${projectId}' and userId eq '${CurrentUser.userId}'`,
        })
            .then((pu) => {
            if (pu.length === 1) {
                LoadingEvents.instance.startLoading();
                const r = new RemoveProjectUser({ force: true }, pu[0].id);
                return r.execute();
            }
            throw new Error(`user not in project ${projectId}, ${pu.length}`);
        })
            .then(() => {
            LoadingEvents.instance.stopLoading();
            if (AuthClient.instance != null)
                AuthClient.instance.startSync().catch((err) => console.debug(err));
            Routing.instance.goTo('/');
        })
            .catch((err) => {
            console.debug('cant leave Project', err);
            LoadingEvents.instance.stopLoading();
        });
    });
};

export const ALL_TAG_GROUP_COLORS = [
    '#007618',
    '#9b5b00',
    '#dee8a1',
    '#28261d',
    '#542400',
    '#ffdb16',
    '#ff133c',
    '#9cf97d',
    '#0050a0',
    '#54f8f9',
    '#00926a',
    '#ff4a65',
    '#181a69',
    '#ffcc78',
    '#4afbde',
    '#ff7a55',
    '#d1e5f3',
    '#2eff63',
    '#4fac00',
    '#619dff',
    '#7039e6',
    '#014e79',
    '#ba002b',
    '#6a005f',
    '#c1f179',
    '#00c6a8',
    '#97d3ff',
    '#abea00',
    '#01a0ac',
    '#fdb5ff',
    '#ff9b9c',
    '#5a5400',
    '#d40054',
    '#c70011',
    '#7c2e00',
    '#e070ff',
    '#224500',
    '#710037',
    '#00d685',
    '#97c800',
];

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Privacy = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../upmesh-client/node_modules/react-native-web");
var _MaterialText = require("../theme/components/text/MaterialText");
var _jsxRuntime = require("../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Privacy = exports.Privacy = function (_PureComponent) {
  function Privacy() {
    (0, _classCallCheck2.default)(this, Privacy);
    return _callSuper(this, Privacy, arguments);
  }
  (0, _inherits2.default)(Privacy, _PureComponent);
  return (0, _createClass2.default)(Privacy, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Datenschutzerkl\xE4rung"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Wir freuen uns über Ihren Besuch auf unserer Internetseite www.upmesh.de und Ihr Interesse an unserem Unternehmen. Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen. Personenbezogene Daten sind Informationen über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Darunter fallen z.B. der bürgerliche Name, die Anschrift, die Telefonnummer und das Geburtsdatum, aber auch alle anderen Daten, die auf eine bestimmbare Person bezogen werden können.

Da personenbezogene Daten besonderen gesetzlichen Schutz genießen, werden sie bei uns nur im soweit dies für die Bereitstellung unserer Internetseite und die Erbringung unserer Leistung erforderlich ist erhoben. Nachfolgend stellen wir dar, welche personenbezogenen Informationen wir während Ihres Besuches auf unserer Internetseite erfassen und wie wir diese nutzen.

Unsere Datenschutzpraxis steht im Einklang mit den gesetzlichen Regelungen, insbesondere denen des Bundesdatenschutzgesetzes (BDSG), des Telemediengesetzes (TMG) und der Datenschutzgrundverordnung der EU (DSGVO). Wir werden Ihre personenbezogenen Daten ausschließlich erheben, verarbeiten und speichern, soweit dies zur funktionsfähigen Bereitstellung dieser Internetseite und unserer Inhalte und Leistungen erforderlich ist, sowie zur Bearbeitung von Anfragen und ggf. zur Abwicklung von Bestellungen / Verträgen, dies jeweils aber nur, soweit hierfür ein berechtigtes Interesse i.S.d. Art. 6 Abs. 1 S.1 lit. f DSGVO oder ein sonstiger Erlaubnistatbestand besteht. Nur wenn Sie zuvor Ihre Einwilligung gesondert erteilt haben, erfolgt eine Nutzung Ihrer Daten auch für weitergehende, in der Einwilligung genau bestimmte Zwecke, z.B. für die Übersendung von werblichen Informationen per Newsletter.`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Verantwortlicher i.S.d. Art. 4 Abs. 7 DSGVO"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Verantwortlicher im Sinne der DSGVO und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:

upmesh UG (haftungsbeschränkt) 
Ignatz-Wiemeler-Str. 22
49477 Ibbenbüren 
E-Mail: moin@upmesh.de
Telefon: (0 54 51) 59 33-60
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Bereitstellung der Website und Erstellung von Logfiles"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen des Computersystems des aufrufenden Rechners. Folgende Daten werden hierbei erhoben:

Umfang der Verarbeitung der Daten
Informationen über den Browsertyp und die verwendete Version
Das Betriebssystem des Abrufgerätes
Die IP-Adresse des Abrufgerätes
Datum und Uhrzeit des Zugriffs
Websites und Ressorucen (Bilder, Dateien, weitere Seiteninhalte), die auf unserer Internetseite aufgerufen wurden.
Websites, von denen das System des Nutzers auf unsere Internetseite gelangte (Referrer-Tracking)
 

Diese Daten werden in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit personenbezogenen Daten eines konkreten Nutzers findet nicht statt, so dass eine Identifizierung einzelner Seitenbesucher nicht erfolgt.

Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Art. 6 Abs.1 lit.f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse besteht darin, die Erreichung des nachfolgend geschilderten Zwecks zu gewährleisten.
Zweck der Datenverarbeitung
Das Logging erfolgt zur Erhaltung der Kompatibilität unsere Internetseite für möglichst alle Besucher und zur Missbrauchsbekämpfung und Störungsbeseitigung. Hierfür ist es notwendig, die technischen Daten des abrufenden Rechners zu loggen, um so frühestmöglich auf Darstellungsfehler, Angriffe auf unsere IT-Systeme und/oder Fehler der Funktionalität unserer Internetseite reagieren zu können. Zudem dienen uns die Daten zur Optimierung der Website und zur generellen Sicherstellung der Sicherheit unserer informationstechnischen Systeme.
Dauer der Speicherung
Die Löschung der vorgenannten technischen Daten erfolgt, sobald sie nicht mehr benötigt werden, um die Kompatibilität der Internetseite für alle Besucher zu gewährleisten, spätestens aber 3 Monate nach Abruf unserer Internetseite.
Widerspruchs- und Beseitigungsmöglichkeit
Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Besondere Funktionen unserer Internetseite"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Unsere Internetseite bietet Ihnen verschiedene Funktionen, bei deren Nutzung Ihre personenbezogenen Daten von uns erhoben, verarbeitet und gespeichert werden. Nachfolgend erklären wir, was mit Ihren Daten geschieht:

Kontaktformular(e):
Umfang der Verarbeitung personenbezogener Daten
Die von Ihnen in unsere Kontaktformulare eingegebenen Daten.
Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Art. 6 Abs. 1 lit.a DSGVO (stillschweigende Einwilligung)
Zweck der Datenverarbeitung
Die über unser Kontaktformular bzw. über unsere Kontaktformulare aufgenommenen Daten werden wir nur für die Bearbeitung der konkreten Kontaktanfrage verwenden, die durch das Kontaktformular eingeht.
Dauer der Speicherung
Nach Bearbeitung Ihrer Anfrage werden die erhobenen Daten unverzüglich gelöscht, soweit keine gesetzlichen Aufbewahrungsfristen bestehen.
Widerspruchs- und Beseitigungsmöglichkeit
Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.
Login-Bereich:
Umfang der Verarbeitung personenbezogener Daten
Die von Ihnen bei uns eingegebenen Registrierungs- und Login-Daten.
Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Art. 6 Abs.1 lit.a DSGVO (stillschweigende Einwilligung)
Zweck der Datenverarbeitung
Sie haben auf unserer Internetseite die Möglichkeit einen separaten Login-Bereich zu nutzen. Falls Sie Ihr Passwort oder Ihren Benutzernamen für diesen Bereich vergessen haben sollten, besteht die Möglichkeit sich diese Daten nach vorheriger Eingabe Ihrer Kontaktdaten (E-Mail-Adresse) erneut zusenden zu lassen. Die im Rahmen der Nutzung des Login-Bereiches anfallenden Nutzungsdaten werden von uns nur zur Missbrauchsbekämpfung und Störungsbeseitigung bzw. zur Aufrechterhaltung der Funktionalität erhoben, gespeichert und verarbeitet. Eine Verwendung zu anderen Zwecken oder eine Datenweitergabe an Dritte erfolgt nicht.
Dauer der Speicherung
Ihre Bewertung wird zeitlich unbefristet gespeichert und veröffentlicht. Wir behalten uns die Löschung ohne Angaben von Gründen und ohne vorherige oder nachträgliche Information vor.
Die Daten, die im Rahmen der Funktion ‘Benutzernamen bzw. Passwort vergessen’ erhoben werden, werden nur für die erneute Zusendung von vergessenen Zugangsdaten genutzt.
Widerspruchs- und Beseitigungsmöglichkeit
Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.
Formular zur Newsletter-Anmeldung:
Umfang der Verarbeitung personenbezogener Daten
Die von Ihnen bei der Newsletter-Anmeldung eingegebenen Daten.
Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Art. 6 Abs.1 lit. a DSGVO (stillschweigende Einwilligung)
Zweck der Datenverarbeitung
Die in der Anmeldemaske unseres Newsletters aufgenommenen Daten werden von uns ausschließlich für den Versand unseres Newsletters verwendet, in dem wir über alle unsere Leistungen und unsere Neuigkeiten informieren. Wir werden Ihnen nach der Anmeldung eine Bestätigungs-E-Mail senden, in der ein Link enthalten ist, den Sie anklicken müssen, um die Anmeldung zu unserem Newsletter abzuschließen (Double-Opt-In).
Dauer der Speicherung
Unser Newsletter kann jederzeit durch Anklicken des Abmelde-Links, der auch in jedem Newsletter enthalten ist, abbestellt werden. Ihre Daten werden unverzüglich nach der Abmeldung von uns gelöscht. Ebenso werden Ihre Daten im Falle einer nicht abgeschlossenen Anmeldung unverzüglich von uns gelöscht. Wir behalten uns die Löschung ohne Angaben von Gründen und ohne vorherige oder nachträgliche Information vor.
Widerspruchs- und Beseitigungsmöglichkeit
Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.
Terminbuchungsformular:
Umfang der Verarbeitung personenbezogener Daten
Die von Ihnen bei einer Terminbuchung eingegebenen Daten.
Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Art. 6 Abs.1 lit.b DSGVO (Durchführung vorvertraglicher Maßnahmen)
Zweck der Datenverarbeitung
Die über unser Terminbuchungsformular aufgenommenen Daten werden wir nur für die Bearbeitung von Terminanfragen, die durch das Terminbuchungsformular eingehen, verwenden.
Dauer der Speicherung
Ihre Terminbuchung wird bei uns unverzüglich nach Ablauf von 12 Monaten, nachdem der Termin angesetzt war, gelöscht, soweit keine gesetzlichen Aufbewahrungspflichten bestehen. Wir behalten uns die Löschung ohne Angaben von Gründen und ohne vorherige oder nachträgliche Information vor.
Widerspruchs- und Beseitigungsmöglichkeit
Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Statistische Auswertung der Besuche dieser Internetseite - Webtracker"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Wir erheben, verarbeiten und speichern bei dem Aufruf dieser Internetseite oder einzelner Dateien der Internetseite folgende Daten: IP-Adresse, Webseite, von der aus die Datei abgerufen wurde, Name der Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und Meldung über den Erfolg des Abrufs (sog. Web-Log). Diese Zugriffsdaten verwenden wir ausschließlich in nicht personalisierter Form für die stetige Verbesserung unseres Internetangebots und zu statistischen Zwecken.

Wir setzen zur Auswertung der Besuche dieser Internetseite zudem noch folgende Webtracker ein:

Google-Analytics
Umfang der Verarbeitung personenbezogener Daten
Auf unserer Seite verwenden wir den Webtracking-Service des Unternehmens Google LLC, 1600 Amphitheatre Park in 94043 Mountain View, USA (nachfolgend: Google-Analytics). Google-Analytics nutzt im Rahmen des Webtrackings Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung unserer Website und Ihres Surfverhaltens ermöglichen (sogenanntes Tracken). Wir führen diese Analyse auf Basis des Tracking-Services von Google-Analytics durch, um unser Internetangebot ständig zu optimieren und besser verfügbar zu machen. Im Rahmen der Benutzung unserer Webseite werden dabei Daten, wie insbesondere Ihre IP-Adresse und Ihre Nutzeraktivitäten an Server des Unternehmens Google LLC übertragen und außerhalb der Europäischen Union, z.B. in den USA verarbeitet und gespeichert.
Die EU-Kommission hat festgestellt, dass in den USA ein angemessenes Datenschutzniveau bestehen kann, wenn sich das datenverarbeitende Unternehmen dem US-EU-Privacy-Shield Abkommen unterworfen hat und der Datenexport in die USA auf diesem Weg zulässig ausgestaltet wurde. Durch die Aktivierung der IP-Anonymisierung innerhalb des Google-Analytics Tracking-Codes dieser Internetseite, wird Ihre IP-Adresse von Google-Analytics vor der Übertragung anonymisiert. Diese Website nutzt einen Google-Analytics-Tracking-Code, der um den Operator gat._anonymizeIp(); erweitert wurde, um nur eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu ermöglichen.
Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Art. 6 Abs.1 lit.a DSGVO (Einwilligung), entweder im Rahmen der Registrierung bei Google (Eröffnung eines Google-Accounts und Akzeptanz der dort implementierten Datenschutzhinweise) oder, falls Sie sich nicht bei Google registriert haben, durch explizite Einwilligung beim Öffnen unserer Seite.
Zweck der Datenverarbeitung
Im unserem Auftrag wird Google diese Informationen nutzen, um Ihren Besuch auf dieser Internetseite auszuwerten, Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Die im Rahmen von Google-Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten der Google LLC zusammengeführt.
Dauer der Speicherung
Google wird die für die Bereitstellung des Webtracking relevanten Daten solange speichern, wie es notwendig ist, um den gebuchten Webservice zu erfüllen. Die Datenerhebung und Speicherung erfolgt anonymisiert. Soweit doch Personenbezug bestehen sollte, werden die Daten unverzüglich gelöscht, soweit diese keinen gesetzlichen Aufbewahrungspflichten unterfallen. In jedem Fall erfolgt die Löschung nach Ablauf der Aufbewahrungspflicht.
Widerspruchs- und Beseitigungsmöglichkeit
Sie können die Erfassung und Weiterleitung der personenbezogenen Daten an Google (insb. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren, einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com) oder die „Do Not Track“ Einstellung Ihres Browsers aktivieren. Sie können darüber hinaus die Erfassung der durch den Google-Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link (https://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser-Plug-In herunterladen und installieren. Die Sicherheits- und Datenschutzgrundsätze von Google finden Sie unter https://policies.google.com/privacy?hl=de.
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Einbindung externer Webservices und Verarbeitung von Daten au\xDFerhalb der EU"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Auf unserer Internetseite verwenden wir aktive Java-Script-Inhalte von externen Anbietern, sog. Webservices. Durch Aufruf unserer Internetseite erhalten diese externen Anbieter ggf. personenbezogene Informationen über Ihren Besuch auf unserer Internetseite. Hierbei ist ggf. eine Verarbeitung von Daten außerhalb der EU möglich. Sie können dies verhindern, indem Sie einen Java-Script-Blocker wie z.B. das Browser-Plug-In ‘NoScript’ installieren (www.noscript.net) oder Java-Script in Ihrem Browser deaktivieren. Hierdurch kann es zu Funktionseinschränkungen auf Internetseiten kommen, die Sie besuchen.

Wir verwenden folgende externe Webservices:

Einsatz der WebProspector Technologie
Auf dieser Webseite werden mit Technologien der WebProspector GmbH (www.webprospector.de) Daten zu Marketingzwecken und zur Erkennung von Unternehmens-Interessenten gesammelt und gespeichert. Die WebProspector Technologie führt auf Basis dieser Daten eine Adressen-Ermittlung durch, jedoch nur in den Fällen, in welchen Sie sicherstellen kann, dass es sich um ein Unternehmen und nicht um eine einzelne Person als Besucher handelt. Die WebProspector Technologie kann ggf. weitere Merkmale, wie z.B. Bewegungsdaten, zu dem Besuch übermittelt bekommen und setzt in einigen Fällen Cookies ein. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internetbrowsers des Seitenbesuchers gespeichert werden. Die Cookies ermöglichen die Wiedererkennung des Internetbrowsers. Der Datenerhebung und -speicherung durch WebProspector für diese Internetseite kann jederzeit mit Wirkung für die Zukunft unter widersprochen werden. Klicken Sie hierzu bitte auf: https://exclusion.unified-tracking.com/exclusion?cid=TRACKINGID.

website-check.de
Auf unserer Seite wird ein Webservice des Unternehmens Website-Check GmbH, Beethovenstraße 24 in 66111 Saarbrücken, DE (nachfolgend: website-check.de) nachgeladen. Wir nutzen diese Daten, um die volle Funktionalität unserer Website zu gewährleisten. In diesem Zusammenhang wird Ihr Browser ggf. personenbezogene Daten an website-check.de übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO (berechtigtes Interesse). Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite. Die Löschung der Daten erfolgt, sobald der Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung von website-check.de: https://www.website-check.de/datenschutzerklaerung/. Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch website-check.de verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com).

Doubleclick
Auf unserer Webseite wird ein Webservice des Unternehmens Google LLC, 1600 Amphitheatre Parkway in 94043 Mountain View, USA (nachfolgend: Doubleclick) nachgeladen. Wir nutzen diese Daten, um die volle Funktionalität unserer Webseite zu gewährleisten. In diesem Zusammenhang wird Ihr Browser ggf. personenbezogene Daten an Doubleclick übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite. Doubleclick hat sich im Rahmen des EU-US-Privacy-Shield-Abkommens selbst zertifiziert (vgl. https://www.privacyshield.gov/list). Die Löschung der Daten erfolgt, sobald der Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung von Doubleclick: https://www.google.com/intl/de/policies/privacy/. Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch Doubleclick verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com).

Google
Auf unserer Webseite wird ein Webservice des Unternehmens Google LLC, 1600 Amphitheatre Parkway in 94043 Mountain View, USA (nachfolgend: Google) nachgeladen. Wir nutzen diese Daten, um die volle Funktionalität unserer Webseite zu gewährleisten. In diesem Zusammenhang wird Ihr Browser ggf. personenbezogene Daten an Google übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite. Google hat sich im Rahmen des EU-US-Privacy-Shield-Abkommens selbst zertifiziert (vgl. https://www.privacyshield.gov/list). Die Löschung der Daten erfolgt, sobald der Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung von Google: https://www.google.com/intl/de/policies/privacy/. Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch Google verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com).

Google-APIs
Auf unserer Webseite wird ein Webservice des Unternehmens Google LLC, 1600 Amphitheatre Parkway in 94043 Mountain View, USA (nachfolgend: Google-Apis) nachgeladen. Wir nutzen diese Daten, um die volle Funktionalität unserer Webseite zu gewährleisten. In diesem Zusammenhang wird Ihr Browser ggf. personenbezogene Daten an Google-Apis übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite. Google-Apis hat sich im Rahmen des EU-US-Privacy-Shield-Abkommens selbst zertifiziert (vgl. https://www.privacyshield.gov/list). Die Löschung der Daten erfolgt, sobald der Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung von Google-Apis: https://www.google.com/intl/de/policies/privacy/. Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch Google-Apis verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com).

gstatic
Auf unserer Webseite wird ein Webservice des Unternehmens Google LLC, 1600 Amphitheatre Parkway in 94043 Mountain View, USA (nachfolgend: gstatic) nachgeladen. Wir nutzen diese Daten, um die volle Funktionalität unserer Webseite zu gewährleisten. In diesem Zusammenhang wird Ihr Browser ggf. personenbezogene Daten an gstatic übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite. gstatic hat sich im Rahmen des EU-US-Privacy-Shield-Abkommens selbst zertifiziert (vgl. https://www.privacyshield.gov/list). Die Löschung der Daten erfolgt, sobald der Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung von gstatic: https://www.google.com/intl/de/policies/privacy/. Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch gstatic verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in Ihrem Browser installieren (diesen finden Sie z.B. unter www.noscript.net oder www.ghostery.com).

Analyse durch wiredminds
Unsere Webseite nutzt die Zählpixeltechnologie der wiredminds GmbH (www.wiredminds.de) zur Analyse des Besucherverhaltens. Dabei werden ggf. Daten erhoben, verarbeitet und gespeichert, aus denen unter einem Pseudonym Nutzungsprofile erstellt werden. Wo möglich und sinnvoll werden diese Nutzungsprofile vollständig anonymisiert. Hierzu können Cookies zum Einsatz kommen. Cookies sind kleine Textdateien, die im Internet-Browser des Besuchers gespeichert werden und zur Wiedererkennung des Internet-Browsers dienen. Die erhobenen Daten, die auch personenbezogene Daten beinhalten können, werden an wiredminds übermittelt oder direkt von wiredminds erhoben. wiredminds darf Informationen, die durch Besuche auf den Webseiten hinterlassen werden, nutzen um anonymisierte Nutzungsprofile zu erstellen. Die dabei gewonnenen Daten werden ohne die gesondert erteilte Zustimmung des Betroffenen nicht benutzt, um den Besucher dieser Webseite persönlich zu identifizieren und sie werden nicht mit personenbezogenen Daten über den Träger des Pseudonyms zusammengeführt. Soweit IP-Adressen erfasst werden, erfolgt deren sofortige Anonymisierung durch Löschen des letzten Nummernblocks. 
Vom Tracking ausschließen.

Einsatz von Social-Media-Plugins
Wir setzen derzeit folgende Social-Media-Plug-ins ein: [Facebook, Google+, Twitter, Xing, T3N, LinkedIn, Flattr]. Wir nutzen dabei die sog. Zwei-Klick-Lösung. Das heißt, wenn Sie unsere Seite besuchen, werden zunächst grundsätzlich keine personenbezogenen Daten an die Anbieter der Plug-ins weitergegeben. Den Anbieter des Plug-ins erkennen Sie über die Markierung auf dem Kasten über seinen Anfangsbuchstaben oder das Logo. Wir eröffnen Ihnen die Möglichkeit, über den Button direkt mit dem Anbieter des Plug-ins zu kommunizieren. Nur wenn Sie auf das markierte Feld klicken und es dadurch aktivieren, erhält der Plug-in-Anbieter die Information, dass Sie die entsprechende Website unseres Online-Angebots aufgerufen haben. Zudem werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Im Fall von Facebook und Xing wird nach Angaben der jeweiligen Anbieter in Deutschland die IP-Adresse sofort nach Erhebung anonymisiert. Durch die Aktivierung des Plug-ins werden also personenbezogene Daten von Ihnen an den jeweiligen Plug-in-Anbieter übermittelt und dort (bei US-amerikanischen Anbietern in den USA) gespeichert. Da der Plug-in-Anbieter die Datenerhebung insbesondere über Cookies vornimmt, empfehlen wir Ihnen, vor dem Klick auf den ausgegrauten Kasten über die Sicherheitseinstellungen Ihres Browsers alle Cookies zu löschen.
Wir haben weder Einfluss auf die erhobenen Daten und Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der Datenerhebung, die Zwecke der Verarbeitung, die Speicherfristen bekannt. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.
Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung dessen an den jeweiligen Plug-in-Anbieter wenden müssen. Über die Plug-ins bietet wir Ihnen die Möglichkeit, mit den sozialen Netzwerken und anderen Nutzern zu interagieren, so dass wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten können. Rechtsgrundlage für die Nutzung der Plug-ins ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem Plug-in-Anbieter eingeloggt sind, werden Ihre bei uns erhobenen Daten direkt Ihrem beim Plug-in-Anbieter bestehenden Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z.B. die Seite verlinken, speichert der Plug-in-Anbieter auch diese Information in Ihrem Nutzerkonto und teilt sie Ihren Kontakten öffentlich mit. Wir empfehlen Ihnen, sich nach Nutzung eines sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem Plug-in-Anbieter vermeiden können.
Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den im Folgenden mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre.
Adressen der jeweiligen Plug-in-Anbieter und URL mit deren Datenschutzhinweisen:
Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, USA; http://www.facebook.com/policy.php; weitere Informationen zur Datenerhebung: http://www.facebook.com/help/186325668085084, http://www.facebook.com/about/privacy/your-info-on-other#applications sowie http://www.facebook.com/about/privacy/your-info#everyoneinfo. Facebook hat sich dem EU-US-Privacy-Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043, USA; https://www.google.com/policies/privacy/partners/?hl=de. Google hat sich dem EU-US-Privacy-Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
Twitter, Inc., 1355 Market St, Suite 900, San Francisco, California 94103, USA; https://twitter.com/privacy. Twitter hat sich dem EU-US-Privacy-Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
Xing AG, Gänsemarkt 43, 20354 Hamburg, DE; http://www.xing.com/privacy.
T3N, yeebase media GmbH, Kriegerstr. 40, 30161 Hannover, Deutschland; https://t3n.de/store/page/datenschutz.
LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California 94043, USA; http://www.linkedin.com/legal/privacy-policy. LinkedIn hat sich dem EU-US-Privacy-Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.
Flattr Network Ltd. mit Sitz in 2nd Floor, White bear yard 114A, Clerkenwell Road, London, Middlesex, England, EC1R 5DF, Großbritannien; https://flattr. com/privacy.]
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Unterrichtung \xFCber die Nutzung von Cookies"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Umfang der Verarbeitung personenbezogener Daten
Auf verschiedenen Seiten verwenden wir Cookies, um die Nutzung bestimmter Funktionen unserer Website zu ermöglichen. Bei den sog. ‘Cookies’ handelt es sich um kleine Textdateien, die Ihr Browser auf Ihrem Rechner ablegen kann. Diese Textdateien enthalten eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen unserer Website ermöglichen Der Vorgang des Ablegens einer Cookie-Datei wird auch ‘ein Cookie setzen’ genannt.
Rechtsgrundlage für die Verarbeitung personenbezogener Daten
Art. 6 Abs.1 lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse besteht in der Erhaltung der vollen Funktionsfähigkeit unserer Internetseite, die Steigerung der Bedienbarkeit sowie die Ermöglichung einer individuelleren Kundenansprache. Eine Identifizierung einzelner Seitenbesucher ist uns mit Hilfe der Cookie-Technologie nur möglich, wenn der Seitenbesucher uns zuvor auf Basis einer gesonderten Einwilligung entsprechende personenbezogene Daten überlassen hat.
Zweck der Datenverarbeitung
Die Cookies werden von unserer Website gesetzt, um die volle Funktionsfähigkeit unserer Internetseite zu erhalten und die Bedienbarkeit zu verbessern. Zudem ermöglicht uns die Cookie-Technologie, einzelne Besucher durch Pseudonyme, z.B. eine individuelle, beliebige ID, wiederzuerkennen, so dass es uns möglich ist, individuellere Leistungen anzubieten.
Dauer der Speicherung
Die Speicherung unserer Cookies erfolgt bis zur Löschung in Ihrem Browser oder, wenn es sich um einen Session-Cookie handelt, bis die Session abgelaufen ist.
Widerspruchs- und Beseitigungsmöglichkeit
Sie können Ihren Browser selbst nach Ihren Wünschen so einstellen, dass Sie das Setzen von Cookies generell verhindern, nur darüber informiert werden, von Fall zu Fall über die Annahme von Cookies entscheiden: oder die Annahme von Cookies grundsätzlich akzeptieren. Cookies können zu unterschiedlichen Zwecken eingesetzt werden, z.B. um zu erkennen, dass Ihr PC schon einmal eine Verbindung zu unserem Webangebot hatte (dauerhafte Cookies) oder um zuletzt angesehene Angebote zu speichern (Sitzungs-Cookies). Wir setzen Cookies ein, um Ihnen einen gesteigerten Benutzerkomfort zu bieten. Um unsere Komfortfunktionen zu nutzen, empfehlen wir Ihnen, die Annahme von Cookies für unser Webangebot zu erlauben.
Die Widerspruchs- und Beseitigungsmöglichkeiten richten sich im Übrigen nach den nachfolgend in dieser Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen Widerspruchsrecht und Löschungsanspruch.
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Plugins und eingebettete Funktionen sowie Inhalte"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als “Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als “Inhalte”).

Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als “Web Beacons” bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die “Pixel-Tags” können Informationen, wie der Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als auch mit solchen Informationen aus anderen Quellen verbunden werden.

Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.

Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Daten, die den Standort des Endgeräts eines Endnutzers angeben), Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit, Vertragliche Leistungen und Service, Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO), Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
Eingesetzte Dienste und Diensteanbieter:

Google Fonts: Wir binden die Schriftarten (“Google Fonts”) des Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website: https://fonts.google.com/; Datenschutzerklärung: https://policies.google.com/privacy.
Google Maps: Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google ein. Zu den verarbeiteten Daten können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website: https://cloud.google.com/maps-platform; Datenschutzerklärung: https://policies.google.com/privacy; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin: https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die Darstellung von Werbeeinblendungen: https://adssettings.google.com/authenticated.
OpenStreetMap: Wir binden die Landkarten des Dienstes “OpenStreetMap” ein, die auf Grundlage der Open Data Commons Open Database Lizenz (ODbL) durch die OpenStreetMap Foundation (OSMF) angeboten werden. Die Daten der Nutzer werden durch OpenStreetMap ausschließlich zu Zwecken der Darstellung der Kartenfunktionen und zur Zwischenspeicherung der gewählten Einstellungen verwendet. Zu diesen Daten können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen) erhoben werden. Dienstanbieter: OpenStreetMap Foundation (OSMF); Website: https://www.openstreetmap.de; Datenschutzerklärung: https://wiki.openstreetmap.org/wiki/Privacy_Policy.
YouTube-Videos: Videoinhalte; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website: https://www.youtube.com; Datenschutzerklärung: https://policies.google.com/privacy; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin: https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die Darstellung von Werbeeinblendungen: https://adssettings.google.com/authenticated.
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "L\xF6schung von Daten"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).

Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.

Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Datensicherheit und Datenschutz, Kommunikation per E-Mail"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Ihre personenbezogenen Daten werden durch technische und organisatorische Maßnahmen so bei der Erhebung, Speicherung und Verarbeitung geschützt, dass sie für Dritte nicht zugänglich sind. Bei einer unverschlüsselten Kommunikation per E-Mail kann die vollständige Datensicherheit auf dem Übertragungsweg zu unseren IT-Systemen von uns nicht gewährleistet werden, sodass wir bei Informationen mit hohem Geheimhaltungsbedürfnis eine verschlüsselte Kommunikation oder den Postweg empfehlen.
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Widerruf von Einwilligungen - Datenausk\xFCnfte und \xC4nderungsw\xFCnsche \u2013 L\xF6schung & Sperrung von Daten"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Sie haben einmal pro Jahr ein Recht auf unentgeltliche Auskunft über Ihre gespeicherten Daten sowie jederzeit ein Recht auf Berichtigung, Sperrung oder Löschung Ihrer Daten. Ihre Daten werden von uns auf erstes Anfordern gelöscht, falls dem nicht gesetzliche Regelungen entgegenstehen. Sie können eine uns erteilte Erlaubnis, Ihre persönlichen Daten zu nutzen, also jederzeit widerrufen. Auskunfts-, Löschungs- und Berichtigungswünsche zu Ihren Daten und gerne auch Anregungen können Sie jederzeit an folgende Adresse senden:

upmesh UG (haftungsbeschränkt) 
Ignatz-Wiemeler-Str. 22
49477 Ibbenbüren 
E-Mail: moin@upmesh.de
Telefon: (0 54 51) 59 33-60
`
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 16
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: "Beschwerderecht bei der Aufsichtsbeh\xF6rde gem. Art. 77 I DSGVO"
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: `Sofern Sie den Verdacht haben, dass auf unserer Seite Ihre Daten rechtswidrig verarbeitet werden, können Sie selbstverständlich jederzeit eine gerichtliche Klärung der Problematik herbeiführen. Unabhängig davon steht Ihnen die Möglichkeit zur Verfügung, sich an eine Aufsichtsbehörde zu wenden. Das Beschwerderecht steht Ihnen in dem EU-Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes und/oder des Orts des vermeintlichen Verstoßes zu, d.h. Sie können die Aufsichtsbehörde, an die Sie sich wenden, an den oben genannten Orten wählen. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet Sie dann über den Stand und die Ergebnisse Ihrer Eingabe, einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.

 

Erstellt durch:

© IT-Recht-Kanzlei DURY – www.dury.de

© Website-Check GmbH – www.website-check.de
`
        })]
      });
    }
  }]);
}(_react.PureComponent);
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
export class TicketWithPlanDownloaderBase {
    constructor(tickets, planVersion, plan) {
        this.tickets = tickets;
        this.planVersion = planVersion;
        this.plan = plan;
    }
    getFileName() {
        return `${this.plan.title}.pdf`;
    }
    getUrl() {
        return `${UpmeshClient.instance.url}/planversion/${this.planVersion.id}/pdf`;
    }
    async getToken() {
        const headers = {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
            authorization: `Bearer ${CurrentUser.token}`,
        };
        const token = await fetch(`${this.getUrl()}`, {
            headers,
            method: 'POST',
        });
        const json = await token.json();
        return json.token;
    }
    async open() { }
}

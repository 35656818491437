var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropAreaHandler = exports.AreaPositionInfo = exports.Area = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var uuid = _interopRequireWildcard(require("uuid"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var AreaPositionInfo = exports.AreaPositionInfo = (0, _createClass2.default)(function AreaPositionInfo() {
  (0, _classCallCheck2.default)(this, AreaPositionInfo);
});
var Area = exports.Area = (0, _createClass2.default)(function Area(zIndex, areaPostion, action, filter, toggleHighlight, exclusive) {
  (0, _classCallCheck2.default)(this, Area);
  this.zIndex = zIndex;
  this.areaPostion = areaPostion;
  this.action = action;
  this.filter = filter;
  this.toggleHighlight = toggleHighlight;
  this.exclusive = exclusive;
  this.id = uuid.v1();
});
var DropAreaHandler = exports.DropAreaHandler = function () {
  function DropAreaHandler() {
    (0, _classCallCheck2.default)(this, DropAreaHandler);
  }
  return (0, _createClass2.default)(DropAreaHandler, null, [{
    key: "createPositions",
    value: function () {
      var _createPositions = (0, _asyncToGenerator2.default)(function* () {
        for (var i = 0; i < DropAreaHandler.areas.length; i += 1) {
          yield DropAreaHandler.createPosition(i);
        }
      });
      function createPositions() {
        return _createPositions.apply(this, arguments);
      }
      return createPositions;
    }()
  }, {
    key: "hitTest",
    value: function hitTest(elementPos) {
      var areas = (0, _toConsumableArray2.default)(DropAreaHandler.areas);
      areas.sort(function (a, b) {
        if (a.zIndex < b.zIndex) {
          return 1;
        }
        return -1;
      });
      try {
        for (var i = 0; i < areas.length; i += 1) {
          var pos = areas[i].areaPostionSync;
          if (elementPos.x + elementPos.width / 2 > pos.x && elementPos.x + elementPos.width / 2 < pos.x + pos.width && elementPos.y + elementPos.height / 2 > pos.y && elementPos.y + elementPos.height / 2 < pos.y + pos.height) {
            return areas[i];
          }
          if (areas[i].exclusive) {
            return undefined;
          }
        }
      } catch (e) {
        return undefined;
      }
      return undefined;
    }
  }, {
    key: "registerDropArea",
    value: function registerDropArea(zIndex, areaPostion, action, filter, toggleHighlight, exclusive) {
      var area = new Area(zIndex, areaPostion, action, filter, toggleHighlight, exclusive);
      var index = DropAreaHandler.areas.push(area);
      DropAreaHandler.createPosition(index).catch(function (err) {
        return void 0;
      });
      return area.id;
    }
  }, {
    key: "removeDropArea",
    value: function removeDropArea(id) {
      for (var i = 0; i < DropAreaHandler.areas.length; i += 1) {
        var thumb = DropAreaHandler.areas[i];
        if (thumb.id === id) {
          DropAreaHandler.areas.splice(i, 1);
          break;
        }
      }
    }
  }, {
    key: "createPosition",
    value: function () {
      var _createPosition = (0, _asyncToGenerator2.default)(function* (i) {
        try {
          if (DropAreaHandler.areas.length > i && DropAreaHandler.areas[i] != null) {
            DropAreaHandler.areas[i].areaPostionSync = yield DropAreaHandler.areas[i].areaPostion();
          }
        } catch (e) {}
      });
      function createPosition(_x) {
        return _createPosition.apply(this, arguments);
      }
      return createPosition;
    }()
  }]);
}();
DropAreaHandler.areas = [];
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidateEmailView = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _LeftImageOrTopLogoResponsive = require("../../components/login/components/LeftImageOrTopLogoResponsive");
var _ValidateEmailComp = require("../../components/login/components/ValidateEmailComp");
var _ThemeManager = require("../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var containerStyle = {
  width: '100%',
  maxWidth: 512,
  alignSelf: 'center'
};
var ValidateEmailView = exports.ValidateEmailView = function (_PureComponent) {
  function ValidateEmailView() {
    (0, _classCallCheck2.default)(this, ValidateEmailView);
    return _callSuper(this, ValidateEmailView, arguments);
  }
  (0, _inherits2.default)(ValidateEmailView, _PureComponent);
  return (0, _createClass2.default)(ValidateEmailView, [{
    key: "render",
    value: function render() {
      var size = this.props.size;
      return (0, _jsxRuntime.jsx)(_LeftImageOrTopLogoResponsive.LeftImageOrTopLogoResponsive, {
        size: size,
        children: this.renderForm()
      });
    }
  }, {
    key: "renderForm",
    value: function renderForm() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: [_ThemeManager.ThemeManager.style.contentStyle, containerStyle],
        children: (0, _jsxRuntime.jsx)(_ValidateEmailComp.ValidateEmailComp, Object.assign({}, this.props))
      });
    }
  }]);
}(_react.PureComponent);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddUserToGroup = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var UserAddedToGroup_1 = require("../../events/company/group/UserAddedToGroup");
var AddUserToGroup = function (_CommandRoot_1$Comman) {
  function AddUserToGroup(data, companyId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddUserToGroup);
    _this = _callSuper(this, AddUserToGroup, [data, companyId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'AddUserToGroup';
    return _this;
  }
  (0, _inherits2.default)(AddUserToGroup, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(AddUserToGroup, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        this.group = yield CommandReadModels_1.CommandReadModels.instance.group.getById(this.entityId);
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.group.companyId);
        var companyMembers = ReadModels_1.ReadModels.get('CompanyMember');
        var get = yield companyMembers.get({
          filter: `companyId eq '${company.id}' and delete ne true`
        });
        var addedUser = get.find(function (u) {
          return u.id === _this2.data.memberId;
        });
        if (addedUser == null) {
          throw {
            message: 'Nutzer ist nicht vorhanden',
            messageCode: 'companyGroupUserNotInCompany'
          };
        }
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'memberId') {
          if (this.data.memberId == null || this.data.memberId.length === 0) {
            throw {
              message: 'memberId wird benötigt',
              messageCode: 'required'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new UserAddedToGroup_1.UserAddedToGroup(this.data.memberId, this.group.companyId, {
          groupId: this.group.id
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.AddUserToGroup = AddUserToGroup;
import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { TimeTrackingCalenderDay } from './TimeTrackingCalenderDay';
export class TimeTrackingCalendar extends PureComponent {
    constructor(props) {
        super(props);
        this.monthNames = I18n.m.dateCurrent.monthsNames();
        this.selectedEmployers = [];
        this.selectedWorkTime = 0;
        this.diffWorkTime = 0;
        this.weekNumberWidth = 25;
        this.breakpoint = 800;
        this.weekdayNamesShort = I18n.m.dateCurrent.weekdayNamesShort();
        this.updateRunning = () => {
            const { selected } = this.state;
            const today = new Date();
            const date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0);
            const isSelected = (selected.startDate == null && selected.endDate == null) ||
                (selected.startDate != null &&
                    selected.endDate != null &&
                    date.getTime() >= selected.startDate.getTime() &&
                    date.getTime() <= selected.endDate.getTime()) ||
                (selected.startDate != null && selected.endDate == null && selected.startDate.getTime() <= date.getTime()) ||
                (selected.endDate != null && selected.startDate == null && selected.endDate.getTime() >= date.getTime());
            if (isSelected) {
                const { entriesInDates } = this.props;
                const index = entriesInDates.findIndex((e) => e.date.getTime() === date.getTime());
                if (index > -1) {
                    const employee = entriesInDates[index];
                    let currentTime = 0;
                    employee.entries.forEach((e) => {
                        e.memberEntries.forEach((f) => {
                            if (f.currentInMS)
                                currentTime += f.currentInMS;
                        });
                    });
                    if (currentTime > 0)
                        this.forceUpdate();
                }
            }
        };
        this.init = async () => {
            const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                filter: `userId eq '${CurrentUser.userId}' and deleted ne true`,
                top: 1,
            });
            let company = null;
            let holidays = [];
            let regions = [];
            if (companyMember.length > 0)
                company =
                    CompanyUserInfo.company?.id === companyMember[0].companyId
                        ? CompanyUserInfo.company
                        : await UpmeshClient.instance.modals.company.getById(companyMember[0].companyId);
            if (company) {
                holidays = await UpmeshClient.instance.modals.holiday.get({
                    filter: `deleted ne true and companyId eq '${company.id}'`,
                });
                regions = await UpmeshClient.instance.modals.region.get({
                    filter: `deleted ne true and companyId eq '${company.id}'`,
                });
            }
            this.setState({ holidays, regions });
        };
        this.getEntriesOfTheDay = (date) => (entry) => {
            const startDate = new Date(entry.day);
            return (startDate.getFullYear() === date.getFullYear() &&
                startDate.getMonth() === date.getMonth() &&
                startDate.getDate() === date.getDate());
        };
        this.onChangeSelected = (selected) => {
            const { onChangeSelected } = this.props;
            if (onChangeSelected != null)
                onChangeSelected(selected);
        };
        this.onPressNextLastSelection = (direction) => {
            const { selected } = this.state;
            if (selected.startDate == null || selected.endDate == null)
                return;
            if (selected.type === '?') {
                let newEnd = selected.endDate;
                let newStart = selected.startDate;
                const monthDiff = TimeTrackingCalendar.monthDiff(selected.startDate, selected.endDate) + 1;
                const dayDiff = TimeTrackingCalendar.dateDiffInDays(selected.startDate, selected.endDate);
                if (direction === 'next') {
                    newStart = new Date(selected.endDate.getFullYear(), selected.endDate.getMonth(), selected.endDate.getDate() + 1, 0, 0, 0, 0);
                    if (monthDiff > 1) {
                        newEnd = new Date(newStart.getFullYear(), newStart.getMonth() + (monthDiff - 1), newStart.getDate(), 0, 0, 0, -1);
                    }
                    else if (dayDiff > 0) {
                        newEnd = new Date(newStart.getFullYear(), newStart.getMonth(), newStart.getDate() + dayDiff + 1, 0, 0, 0, -1);
                    }
                    else {
                        newEnd = new Date(newStart.getFullYear(), newStart.getMonth(), newStart.getDate() + 1, 0, 0, 0, -1);
                    }
                }
                else {
                    newEnd = new Date(selected.startDate.getFullYear(), selected.startDate.getMonth(), selected.startDate.getDate(), 0, 0, 0, -1);
                    if (monthDiff > 1) {
                        newStart = new Date(newEnd.getFullYear(), newEnd.getMonth() - (monthDiff - 1), newEnd.getDate() + 1, 0, 0, 0, 0);
                    }
                    else if (dayDiff > 0) {
                        newStart = new Date(newEnd.getFullYear(), newEnd.getMonth(), newEnd.getDate() - dayDiff, 0, 0, 0, 0);
                    }
                    else {
                        newStart = new Date(newEnd.getFullYear(), newEnd.getMonth(), newEnd.getDate() - 1, 0, 0, 0, 0);
                    }
                }
                this.setState({
                    selected: { startDate: newStart, endDate: newEnd, type: selected.type },
                }, () => {
                    this.onChangeSelected({ startDate: newStart, endDate: newEnd });
                });
                return;
            }
            if (selected.type === 'month') {
                let newStart = selected.startDate;
                let newEnd = selected.endDate;
                if (direction === 'next') {
                    newStart = new Date(selected.startDate.getFullYear(), selected.startDate.getMonth() + 1, selected.startDate.getDate());
                }
                else {
                    newStart = new Date(selected.startDate.getFullYear(), selected.startDate.getMonth() - 1, selected.startDate.getDate());
                }
                newEnd = new Date(new Date(newStart.getFullYear(), newStart.getMonth() + 1, newStart.getDate()).getTime() - 1);
                this.setState({
                    selected: { startDate: newStart, endDate: newEnd, type: selected.type },
                }, () => {
                    this.onChangeSelected({ startDate: newStart, endDate: newEnd });
                });
            }
            else if (selected.type === 'week') {
                const newStart = new Date(selected.startDate.getFullYear(), selected.startDate.getMonth(), direction === 'next' ? selected.startDate.getDate() + 7 : selected.startDate.getDate() - 7, 0, 0, 0, 0);
                this.selectDate(selected.type, newStart);
            }
            else if (selected.type === 'date') {
                this.selectDate(selected.type, new Date(selected.startDate.getTime() + (direction === 'next' ? 24 * 60 * 60 * 1000 : -24 * 60 * 60 * 1000)));
            }
        };
        this.onResized = (e) => {
            this.setState({ currentWidth: e.nativeEvent.layout.width });
        };
        this.calenderVisibility = (show) => (_e) => {
            SimpleStorage.set('timeTrackingCalenderVisibility', show ? '1' : '0');
            this.setState({ hideCalender: !show });
        };
        this.getFirstDisplayedDay = () => {
            const { startsWithMonday, selected } = this.state;
            const { startDate } = selected;
            if ((startsWithMonday && startDate.getDay() === 1) || (!startsWithMonday && startDate.getDay() === 0)) {
                return startDate;
            }
            if (startsWithMonday && startDate.getDay() === 0) {
                return new Date(startDate.getTime() - 6 * 24 * 60 * 60 * 1000);
            }
            return new Date(startDate.getTime() - (startDate.getDay() - (startsWithMonday ? 1 : 0)) * 24 * 60 * 60 * 1000);
        };
        this.selectDate = (type, startDate) => {
            const { selected, startsWithMonday } = this.state;
            this.selectedEmployers = [];
            this.selectedWorkTime = 0;
            let newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
            let endDate = this.getEndDate(type, startDate);
            const { sMode } = this;
            const sameTimePeriod = selected.startDate != null &&
                selected.startDate.getTime() === newStartDate.getTime() &&
                selected.endDate != null &&
                selected.endDate.getTime() === endDate.getTime();
            if (selected.startDate != null && (type === 'month' || (!sMode && sameTimePeriod))) {
                newStartDate = new Date(selected.startDate.getFullYear(), selected.startDate.getMonth(), 1, 0, 0, 0, 0);
                endDate = this.getEndDate('month', newStartDate);
            }
            else if (selected.startDate != null && sMode && sameTimePeriod) {
                let dayCoefficient = selected.startDate.getDay();
                if (startsWithMonday) {
                    if (selected.startDate.getDay() === 0)
                        dayCoefficient = 6;
                    else
                        dayCoefficient -= 1;
                }
                newStartDate = new Date(selected.startDate.getTime() - dayCoefficient * 24 * 60 * 60 * 1000);
                endDate = this.getEndDate('week', newStartDate);
            }
            if (!sameTimePeriod && type === 'date' && TimeTrackingCalendar.beforeDayPeriod == null) {
                TimeTrackingCalendar.beforeDayPeriod = selected;
            }
            else if (sameTimePeriod && type === 'date' && TimeTrackingCalendar.beforeDayPeriod != null) {
                newStartDate = TimeTrackingCalendar.beforeDayPeriod.startDate;
                endDate = TimeTrackingCalendar.beforeDayPeriod.endDate;
                TimeTrackingCalendar.beforeDayPeriod = undefined;
            }
            else if (!sameTimePeriod &&
                type === 'week' &&
                selected.type !== 'date' &&
                TimeTrackingCalendar.beforeWeekPeriod == null) {
                TimeTrackingCalendar.beforeWeekPeriod = selected;
            }
            else if (sameTimePeriod && type === 'week' && TimeTrackingCalendar.beforeWeekPeriod != null) {
                newStartDate = TimeTrackingCalendar.beforeWeekPeriod.startDate;
                endDate = TimeTrackingCalendar.beforeWeekPeriod.endDate;
                TimeTrackingCalendar.beforeDayPeriod = undefined;
            }
            this.setState({ selected: { startDate: newStartDate, endDate, type } }, () => {
                const s = { startDate: newStartDate, endDate };
                this.onChangeSelected(s);
            });
        };
        this.state = {
            currentWidth: 0,
            hideCalender: false,
            showWeekends: true,
            startsWithMonday: CurrentUser.settings.startWithMonday,
            selected: TimeTrackingCalendar.getStartSelected(props.currentFilter),
            currentFilter: props.currentFilter,
            holidays: [],
            regions: [],
            scrollViewContentHeight: 0,
            scrollViewHeight: 0,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let state = {};
        if (JSON.stringify(nextProps.currentFilter) !== JSON.stringify(prevState.currentFilter)) {
            state = {
                currentFilter: nextProps.currentFilter,
                selected: TimeTrackingCalendar.getStartSelected(nextProps.currentFilter),
            };
        }
        return state;
    }
    static getStartSelected(currentFilter) {
        const today = new Date();
        const startDate = currentFilter.date != null
            ? new Date(currentFilter.date)
            : new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0);
        const endDate = currentFilter.dateTo != null
            ? new Date(currentFilter.dateTo)
            : new Date(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0, 0);
        const type = TimeTrackingCalendar.getSelectedType(startDate, endDate, true);
        return { startDate, endDate, type };
    }
    static getKW(date) {
        const doDat = TimeTrackingCalendar.getNextThursday(date);
        const year = doDat.getFullYear();
        const doKW1 = TimeTrackingCalendar.getNextThursday(new Date(year, 0, 4));
        return Math.floor(1.5 + (doDat.getTime() - doKW1.getTime()) / 86400000 / 7);
    }
    static getNextThursday(date) {
        const thursday = new Date();
        thursday.setTime(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);
        return thursday;
    }
    componentDidMount() {
        const show = SimpleStorage.get('timeTrackingCalenderVisibility');
        this.setState({ hideCalender: show === '0' });
        this.init().catch((err) => console.debug(err));
        if (this.intervall)
            clearInterval(this.intervall);
        this.intervall = setInterval(this.updateRunning, 30000);
    }
    componentWillUnmount() {
        if (this.intervall)
            clearInterval(this.intervall);
    }
    static monthDiff(dateFrom, dateTo) {
        return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
    }
    static dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
    get sMode() {
        const { currentWidth } = this.state;
        return currentWidth <= this.breakpoint || ResizeEvent.current.contentHeight < 768;
    }
    render() {
        const { hideCalender, currentWidth } = this.state;
        this.selectedWorkTime = 0;
        this.diffWorkTime = 0;
        this.selectedEmployers = [];
        return (<View onLayout={this.onResized} style={{
                width: '100%',
                paddingVertical: 8,
                paddingHorizontal: 16,
            }}>
        {currentWidth === 0 ? null : (<View style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: ThemeManager.style.borderRadius,
                }}>
            <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    borderBottomColor: ThemeManager.style.borderColor,
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    height: 48,
                    alignItems: 'center',
                    alignContent: 'center',
                    position: 'relative',
                    width: '100%',
                }}>
              {this.renderChangeChip()}
              <View style={{ position: 'absolute', right: 8, top: 6 }}>
                {hideCalender ? (<Icon icon="chevron-up" toolTip="" onPress={this.calenderVisibility(true)}/>) : (<Icon icon="chevron-down" toolTip="" onPress={this.calenderVisibility(false)}/>)}
              </View>
            </View>

            {this.renderCalendar()}
            {this.renderSumView()}
          </View>)}
      </View>);
    }
    renderSumView() {
        return (<View style={{ flexDirection: 'row', justifyContent: 'center', height: 40, alignItems: 'center' }}>
        <Icon toolTip="" icon="sigma"/>
        <MaterialText centeredText centeredBox>
          {this.selectedWorkTime > 0 ? TimeTrackingEntity.msToTime(this.selectedWorkTime, false, true) : '00:00'}
        </MaterialText>
        <Icon toolTip="" icon="plus-minus"/>
        <MaterialText color={this.diffWorkTime >= 0 ? ThemeManager.style.brandSuccess : ThemeManager.style.brandDanger} centeredText centeredBox>
          {this.diffWorkTime
                ? `${this.diffWorkTime > 0 ? '+ ' : ''}${TimeTrackingEntity.msToTime(this.diffWorkTime, false, true)}`
                : '00:00'}
        </MaterialText>
        <Icon toolTip="" icon="account-outline"/>
        <MaterialText centeredText centeredBox>
          {this.selectedEmployers.length > 0 ? this.selectedEmployers.length : '-'}
        </MaterialText>
      </View>);
    }
    renderChangeChip() {
        const { selected } = this.state;
        const { onPressFilter } = this.props;
        let selectedText = '';
        if (selected.type === 'month' && selected.startDate != null) {
            selectedText = this.monthNames[selected.startDate.getMonth()];
            selectedText += ` ${selected.startDate.getFullYear()}`;
        }
        else if (selected.type === 'week' && selected.startDate != null) {
            selectedText = `${I18n.m.getMessage('calendarWeekShort')} ${TimeTrackingCalendar.getKW(selected.startDate)}`;
            selectedText += ` ${selected.startDate.getFullYear()}`;
        }
        else if (selected.type === 'date' && selected.startDate != null) {
            selectedText = I18n.m.dateCurrent.localeDateString(selected.startDate);
        }
        else if (selected.startDate != null && selected.endDate != null) {
            selectedText = `${I18n.m.dateCurrent.localeDateString(selected.startDate)} - ${I18n.m.dateCurrent.localeDateString(selected.endDate)}`;
        }
        else if (selected.startDate != null) {
            selectedText = `> ${I18n.m.dateCurrent.localeDateString(selected.startDate)}`;
        }
        else if (selected.endDate != null) {
            selectedText = `< ${I18n.m.dateCurrent.localeDateString(selected.endDate)}`;
        }
        return (<View>
        <Chip title={selectedText} thumbnail={<Icon icon="chevron-left" toolTip="" onPress={() => this.onPressNextLastSelection('last')}/>} removeIconSize={24} removeIcon="chevron-right" removeIconTooltip="" button onPressButton={() => this.onPressNextLastSelection('next')} onPressText={onPressFilter}/>
      </View>);
    }
    renderDay(date, withMonth) {
        const { selected, holidays, regions } = this.state;
        let targetWorkingTime = 0;
        const employees = [];
        const holiToday = holidays.filter((h) => {
            const holiDate = new Date(h.date);
            const isDate = holiDate.getDate() === date.getDate() && holiDate.getMonth() === date.getMonth();
            return (holiDate.getFullYear() === date.getFullYear() && isDate) || (h.annual && isDate);
        });
        let holidayTooltip = '';
        if (holiToday.length > 0) {
            holiToday.forEach((h, index) => {
                const regionList = regions.filter((r) => {
                    return h.regionIds.indexOf(r.id) > -1;
                });
                let regionString = '';
                regionList.forEach((there, index) => {
                    regionString = regionString + (index !== 0 ? ', ' : '') + there.region;
                });
                holidayTooltip = `${holidayTooltip}${index === 0 ? '' : '; '}${h.name} ${I18n.m.getMessage('regions')}: ${regionString}`;
            });
        }
        const isSelected = (selected.startDate == null && selected.endDate == null) ||
            (selected.startDate != null &&
                selected.endDate != null &&
                date.getTime() >= selected.startDate.getTime() &&
                date.getTime() <= selected.endDate.getTime()) ||
            (selected.startDate != null && selected.endDate == null && selected.startDate.getTime() <= date.getTime()) ||
            (selected.endDate != null && selected.startDate == null && selected.endDate.getTime() >= date.getTime());
        let dateEntry;
        if (isSelected) {
            const { entriesInDates } = this.props;
            const index = entriesInDates.findIndex((e) => e.date.getTime() === date.getTime());
            if (index > -1) {
                dateEntry = entriesInDates[index];
                entriesInDates[index].entries.forEach((e) => {
                    employees.push(e.memberId);
                    targetWorkingTime += Math.max(e.targetTimeInMS - e.holidayTimeInMS, 0);
                });
            }
        }
        const { sMode } = this;
        let timeTotalString = '';
        let diffTimeString = '';
        let diffTime = 0;
        let currentTime = 0;
        const today = new Date();
        if (dateEntry != null) {
            if (dateEntry.entries.length > 1)
                timeTotalString += `(${dateEntry.entries.length})${sMode ? '\n' : ' '}`;
            if (isSelected) {
                this.selectedWorkTime += dateEntry.totalTime;
                dateEntry.entries.forEach((employee) => {
                    if (this.selectedEmployers.indexOf(employee.memberId) === -1) {
                        this.selectedEmployers.push(employee.memberId);
                    }
                    if (today.getFullYear() === date.getFullYear() &&
                        today.getMonth() === date.getMonth() &&
                        today.getDay() === date.getDay()) {
                        employee.memberEntries.forEach((f) => {
                            if (f.currentInMS)
                                currentTime += f.currentInMS;
                        });
                    }
                });
                timeTotalString += `${TimeTrackingEntity.msToTime(dateEntry.totalTime + currentTime, false, true)}`;
                diffTime = dateEntry.totalTime + currentTime - targetWorkingTime;
                diffTimeString = `${TimeTrackingEntity.msToTime(diffTime, false, true)}`;
            }
        }
        this.diffWorkTime += diffTime;
        return {
            date,
            day: (<TimeTrackingCalenderDay key={`renderDay_${date}`} entries={dateEntry?.entries} diffTime={diffTime} onPress={() => this.selectDate('date', date)} isSelected={isSelected} diffTimeString={diffTimeString} holidayTooltip={holidayTooltip} timeTotalString={timeTotalString} sMode={sMode} withMonth={withMonth} date={date}/>),
            workingTime: dateEntry != null ? dateEntry.totalTime + currentTime : 0,
            targetWorkingTime,
            employees,
        };
    }
    renderDayNames(sMode) {
        const { showWeekends, startsWithMonday, scrollViewHeight, scrollViewContentHeight } = this.state;
        const { showKW } = CurrentUser.settings;
        const renderedWeekNames = [];
        const weekdays = [];
        if (startsWithMonday || (!startsWithMonday && !showWeekends)) {
            weekdays.push(this.weekdayNamesShort[1].slice(0, 2));
            weekdays.push(this.weekdayNamesShort[2].slice(0, 2));
            weekdays.push(this.weekdayNamesShort[3].slice(0, 2));
            weekdays.push(this.weekdayNamesShort[4].slice(0, 2));
            weekdays.push(this.weekdayNamesShort[5].slice(0, 2));
            if (showWeekends) {
                weekdays.push(this.weekdayNamesShort[6].slice(0, 2));
                weekdays.push(this.weekdayNamesShort[0].slice(0, 2));
            }
        }
        else {
            weekdays.push(...this.weekdayNamesShort);
        }
        weekdays.push(I18n.m.getMessage('timeTrackingSum'));
        for (let i = 0; i < weekdays.length; i += 1) {
            const style = sMode && weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? { width: 64 } : { flex: 1 };
            renderedWeekNames.push(<View key={`daynames_${i}_${weekdays[i]}`} style={{
                    ...style,
                    alignItems: weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? 'flex-end' : 'flex-start',
                    justifyContent: 'center',
                    borderLeftColor: ThemeManager.style.borderColor,
                    borderLeftWidth: ThemeManager.style.borderWidth,
                    backgroundColor: weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? '#f6f6f8' : '#ffffff',
                    paddingRight: weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? 6 : 0,
                }}>
          {weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? (<View>
              <Icon iconSize={24} outerSize={24} radius={0} icon="sigma" toolTip={I18n.m.getMessage('timeTrackingSum')}/>
            </View>) : (<View>
              <MaterialText>{weekdays[i]}</MaterialText>
            </View>)}
        </View>);
        }
        return (<View style={{
                width: '100%',
                flexDirection: 'row',
                height: this.weekNumberWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                borderBottomWidth: ThemeManager.style.borderWidth,
                paddingRight: scrollViewContentHeight > scrollViewHeight ? scrollbarWidth() : 0,
                backgroundColor: '#f6f6f8',
            }}>
        {showKW && <View style={{ backgroundColor: '#f6f6f8', width: this.weekNumberWidth }}/>}
        <View style={{
                flex: 1,
                flexDirection: 'row',
                height: this.weekNumberWidth,
            }}>
          {renderedWeekNames}
        </View>
      </View>);
    }
    renderWeeks(maximum = 100) {
        const { selected } = this.state;
        const weeks = [];
        let firstDayNextWeek = this.getFirstDisplayedDay();
        for (let i = 0; i < maximum; i += 1) {
            weeks.push(this.renderWeek(firstDayNextWeek, i === 0));
            firstDayNextWeek = new Date(firstDayNextWeek.getFullYear(), firstDayNextWeek.getMonth(), firstDayNextWeek.getDate() + 7, 0, 0, 0, 0);
            if (firstDayNextWeek.getTime() > selected.endDate.getTime()) {
                i = maximum;
            }
        }
        return <View style={{ width: '100%' }}>{weeks}</View>;
    }
    renderWeek(firstDay, firstLine) {
        const { showWeekends } = this.state;
        const { showKW } = CurrentUser.settings;
        const days = [];
        for (let i = 0; i < (showWeekends ? 7 : 5); i += 1) {
            days.push(this.renderDay(new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + i, 12, 0, 0, 0), firstLine && i === 0));
        }
        const renderedDays = [];
        let weeklyWorkingTime = 0;
        let weeklyTargetTime = 0;
        const weeklyEmployees = [];
        days.forEach((day) => {
            renderedDays.push(day.day);
            weeklyWorkingTime += day.workingTime;
            weeklyTargetTime += day.targetWorkingTime;
            day.employees.forEach((employer) => {
                if (weeklyEmployees.indexOf(employer) === -1) {
                    weeklyEmployees.push(employer);
                }
            });
        });
        const monday = new Date(firstDay.getTime());
        if (firstDay.getDay() === 0)
            monday.setDate(monday.getDate() + 1);
        const weekNumber = TimeTrackingCalendar.getKW(monday);
        const { sMode } = this;
        const fontSize = sMode
            ? ThemeManager.style.getScreenRelativePixelSize(12)
            : ThemeManager.style.getScreenRelativePixelSize(14);
        const sumStyle = sMode ? { width: 64 } : { flex: 1 };
        const diffTime = weeklyWorkingTime - weeklyTargetTime;
        return (<View key={`renderedWeek_${weekNumber}_${firstDay}`} style={{
                width: '100%',
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                flexDirection: 'row',
                height: sMode ? 96 : 40,
                overflow: 'hidden',
            }}>
        {showKW && (<Ripple onPress={() => this.selectDate('week', firstDay)} style={{
                    backgroundColor: '#f6f6f8',
                    width: this.weekNumberWidth,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'auto',
                }}>
            <View style={{ height: '100%', justifyContent: 'center' }}>
              <MaterialText type={MaterialTextTypes.Caption} centeredText centeredBox>
                {weekNumber}
              </MaterialText>
            </View>
          </Ripple>)}
        <View style={{
                flex: 1,
                flexDirection: 'row',
                overflow: 'hidden',
            }}>
          {renderedDays}
          <Ripple onPress={() => this.selectDate('week', firstDay)} key={`renderedWeekSum_${weekNumber}`} style={{
                ...sumStyle,
                flexDirection: 'column',
                borderLeftColor: ThemeManager.style.borderColor,
                borderLeftWidth: ThemeManager.style.borderWidth,
                justifyContent: 'center',
                backgroundColor: '#f6f6f8',
                paddingRight: 6,
                paddingTop: sMode ? 12 : 0,
            }}>
            <MaterialText fontSize={fontSize} fixedWidth="100%" textAlign="right" type={MaterialTextTypes.Body2}>{`${weeklyEmployees.length > 1 ? `(${weeklyEmployees.length})` : ''}${sMode ? '\n' : ''}${TimeTrackingEntity.msToTime(weeklyWorkingTime, false, true)}`}</MaterialText>

            <MaterialText fixedWidth="100%" fontSize={fontSize} textAlign="right" color={diffTime < 0 ? ThemeManager.style.brandDanger : ThemeManager.style.brandSuccess} type={MaterialTextTypes.Body2}>
              {`${diffTime > 0 ? '+ ' : ''}${TimeTrackingEntity.msToTime(diffTime, false, true)}`}
            </MaterialText>
          </Ripple>
        </View>
      </View>);
    }
    getEndDate(type, startDate) {
        if (type === 'date') {
            return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1, 0, 0, 0, -1);
        }
        if (type === 'week') {
            return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7, 0, 0, 0, -1);
        }
        return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 0, 0, 0, -1);
    }
    renderCalendar() {
        const { hideCalender } = this.state;
        const content = this.renderWeeks();
        if (hideCalender)
            return null;
        return (<View style={{ width: '100%', height: 'auto' }}>
        {this.renderDayNames(this.sMode)}
        <ScrollView onLayout={(e) => this.setState({ scrollViewHeight: e.nativeEvent.layout.height })} onContentSizeChange={(_width, height) => this.setState({ scrollViewContentHeight: height })} style={{ maxHeight: Math.max(100, ResizeEvent.current.windowHeight / 2 - 200) }}>
          {content}
        </ScrollView>
      </View>);
    }
}
TimeTrackingCalendar.getSelectedType = (startDate, endDate, startsWithMonday) => {
    if (startDate == null || endDate == null)
        return '?';
    const startDateWOTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    const endDateWOTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1, 0, 0, 0, -1);
    const endDateWOTime2 = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0);
    if (startDateWOTime.getTime() === endDateWOTime2.getTime()) {
        return 'date';
    }
    console.log('isWeek?', new Date(startDateWOTime.getFullYear(), startDateWOTime.getMonth(), startDate.getDate() + 7, 0, 0, 0, -1).getTime() === endDateWOTime.getTime());
    if (new Date(startDateWOTime.getFullYear(), startDateWOTime.getMonth(), startDate.getDate() + 7, 0, 0, 0, -1).getTime() === endDateWOTime.getTime() &&
        startDateWOTime.getDay() === (startsWithMonday ? 1 : 0)) {
        return 'week';
    }
    if (startDateWOTime.getDate() === 1 &&
        endDateWOTime.getTime() ===
            new Date(startDateWOTime.getFullYear(), startDateWOTime.getMonth() + 1, 1, 0, 0, 0, -1).getTime()) {
        return 'month';
    }
    return '?';
};

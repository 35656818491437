"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommandReadModels = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CommandReadModels = function () {
  function CommandReadModels() {
    (0, _classCallCheck2.default)(this, CommandReadModels);
  }
  return (0, _createClass2.default)(CommandReadModels, null, [{
    key: "userLogins",
    get: function get() {
      return ReadModels_1.ReadModels.get('UserLogin');
    }
  }, {
    key: "userMails",
    get: function get() {
      return ReadModels_1.ReadModels.get('UserMails');
    }
  }, {
    key: "user",
    get: function get() {
      return ReadModels_1.ReadModels.get('User');
    }
  }, {
    key: "userSettings",
    get: function get() {
      return ReadModels_1.ReadModels.get('UserSettings');
    }
  }]);
}();
exports.CommandReadModels = CommandReadModels;
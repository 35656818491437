"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddProjectToCostUnit = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ProjectCostUnitAdded_1 = require("../../../events/company/costcenter/ProjectCostUnitAdded");
var ProjectCostUnitRemoved_1 = require("../../../events/company/costcenter/ProjectCostUnitRemoved");
var AddProjectToCostUnit = function (_CommandRoot_1$Comman) {
  function AddProjectToCostUnit(data, costCenterId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddProjectToCostUnit);
    _this = _callSuper(this, AddProjectToCostUnit, [data, costCenterId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'AddProjectToCostUnit';
    return _this;
  }
  (0, _inherits2.default)(AddProjectToCostUnit, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(AddProjectToCostUnit, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        this.costCenter = yield CommandReadModels_1.CommandReadModels.costCenter.getById(this.entityId);
        if (this.costCenter.costType !== 'costUnit') throw {
          message: 'Nur Kostenträgern können Projekte zugeordnet werden',
          messageCode: 'companyCostCenterCantAddProjectToCostCenterOnlyCostUnit'
        };
        this.project = yield CommandReadModels_1.CommandReadModels.companyProject.getById(this.data.projectId);
        if (userId === 'all') return true;
        if (userId.startsWith('company') && userId.slice(7) === this.costCenter.companyId) return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.costCenter.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this$costCenter$proj,
          _this2 = this;
        var ind = (_this$costCenter$proj = this.costCenter.projectIds) == null ? void 0 : _this$costCenter$proj.findIndex(function (p) {
          return p === _this2.data.projectId;
        });
        if (ind != null && ind > -1) return [];
        var e = [];
        var projectAlreadInOther = yield CommandReadModels_1.CommandReadModels.costCenter.get({
          filter: `companyId eq '${this.costCenter.companyId}' and projectIds eq '${this.data.projectId}'`
        });
        if (projectAlreadInOther.length > 0) {
          projectAlreadInOther.forEach(function (a) {
            e.push(new ProjectCostUnitRemoved_1.ProjectCostUnitRemoved(a.id, {
              projectId: _this2.data.projectId
            }, _this2.costCenter.companyId));
          });
        }
        e.push(new ProjectCostUnitAdded_1.ProjectCostUnitAdded(this.entityId, {
          projectId: this.data.projectId
        }, this.costCenter.companyId));
        return e;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.AddProjectToCostUnit = AddProjectToCostUnit;
import React from 'react';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
export function FolderSelectorListItem(props) {
    const getIcon = () => {
        const { id } = props;
        if (id === 'public')
            return 'folder-home';
        if (id === 'private')
            return 'folder-lock';
        return 'folder';
    };
    return (<ListItem key={props.id} title={props.title} secondTextLine={props.title !== props.flatTitle ? props.flatTitle : ''} onPress={props.onSelect(props.id)} thumbnail={{ width: 40, thumbnail: <Icon toolTip="" icon={getIcon()} backgroundColor="rgba(0,0,0,0.12)"/> }}/>);
}

import ConnectionContext from 'materialTheme/src/connectionContext';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ModalBottomSheet } from 'materialTheme/src/theme/components/ModalBottomSheet';
import { UploadButton } from 'materialTheme/src/theme/components/upload/UploadButton';
import React, { useContext, useEffect, useState } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { FileStorageSelector } from './FileStorageSelector';
export function ProjectUploadButton(props) {
    const [projectUploaded, setProjectUploaded] = useState([]);
    const [externUploads, setExternUploads] = useState(0);
    const connection = useContext(ConnectionContext);
    useEffect(() => {
        if (props.onUploading != null)
            props.onUploading(0 + externUploads);
    }, [props.startNow, externUploads]);
    const onNonProjectUploading = (countedUploads) => {
        setExternUploads(countedUploads);
    };
    const addServerSideFileToProjectUploaded = (file) => {
        projectUploaded.push({
            name: file.orgFilename,
            targetUrl: `${UpmeshClient.instance.url}/storedfile/file/${file.getFileId()}`,
        });
        if (props.onUploading != null)
            props.onUploading(projectUploaded.length + 1);
        setProjectUploaded([...projectUploaded]);
    };
    const onGotFileFromSelector = (file) => {
        const asyncNow = async () => {
            const requestBody = {
                storedFileId: file.id,
                uploadURL: props.endpointUrl.href,
                data: props.data,
            };
            const headers = {};
            headers['Content-Type'] = 'application/json';
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            const identifier = uuid.v4();
            if (props.selfManageUploads) {
                setProjectUploaded(projectUploaded.concat([{ id: identifier, name: file.orgFilename, targetUrl: '' }]));
            }
            if (props.beforeUpload != null) {
                await props.beforeUpload();
            }
            fetch(`${UpmeshClient.instance.url}/storedfile/attachment`, {
                method: 'POST',
                headers,
                body: JSON.stringify(requestBody),
            })
                .then((fetchResult) => {
                if (fetchResult.status === 200) {
                    addServerSideFileToProjectUploaded(file);
                }
                else {
                    fetchResult
                        .json()
                        .then((err) => {
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                    })
                        .catch((err) => {
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                    });
                }
            })
                .catch((e) => {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            })
                .finally(() => {
                DialogBetween.instance?.close();
                if (props.selfManageUploads) {
                    setProjectUploaded(projectUploaded.filter((item) => item.id === identifier));
                }
            });
        };
        asyncNow().catch((err) => console.error(err));
    };
    const openFileStorage = (e) => {
        ModalBottomSheet.instance?.close();
        const asyncNow = async () => {
            if (!connection.connectedToServer) {
                DefaultErrorHandler.showDefaultErrorAlert({
                    name: 'Offline nicht verfügbar',
                    message: 'Offline nicht verfügbar',
                    messageCode: 'offlineNotAvailable',
                }, I18n.m, false);
                return;
            }
            const folders = await UpmeshClient.instance.modals.folder.get({
                filter: `projectId eq '${CurrentProject.instance.getCurrentProjectId()}' and deleted ne true`,
                orderby: 'title ASC',
            });
            FileStorageSelector.openSelector(e)({
                onSelect: onGotFileFromSelector,
                folders,
                shouldUseBetween: true,
                filter: props.filter,
            });
        };
        asyncNow().catch((err) => console.error(err));
    };
    const additionalItems = [
        {
            title: I18n.m.getMessage('projectUploadButtonProjectFiles'),
            thumbnail: {
                thumbnail: <Icon toolTip={I18n.m.getMessage('projectUploadButtonHelperText')} icon="paperclip"/>,
                width: 24,
            },
            onPress: openFileStorage,
        },
    ];
    return (<UploadButton uploadIcon={props.uploadIcon} uploadText={props.uploadText} uploadDropDialogText={props.uploadDropDialogText} disableUpload={props.disableUpload} id={props.id} filter={props.filter} multiple={props.multiple} data={props.data} endpointUrl={props.endpointUrl} uploaded={projectUploaded.concat(props.uploaded ?? [])} onUploading={onNonProjectUploading} deleteFunction={props.deleteFunction} iconPosition={props.iconPosition} showUploadOptionIfDisabled={props.showUploadOptionIfDisabled} showDeleteIcon={props.showDeleteIcon} startUploadImmediately={props.startUploadImmediately} startNow={props.startNow} accessibilityLabel={props.accessibilityLabel} additionalOptions={additionalItems} maxImageSize={props.maxImageSize}/>);
}

import { Url } from 'cqrs-shared/src/uri/Url';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { SaveJournalSettings } from 'upmesh-core/src/client/commands/project/journal/SaveJournalSettings';
import { JournalSettingsEntity } from 'upmesh-core/src/client/query/entities/JournalSettingsEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { JournalSettingsAdditionalFields } from './JournalSettingsAdditionalFields';
import { ProjectUploadButton } from '../file/ProjectUploadButton';
export class JournalSettingsDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.close = (e) => {
            Dialog.instance?.close(e);
        };
        this.onChangeTemplatePicker = (data) => {
            this.setState({ selectedIndex: { index: data.index, data: data.value.data } });
        };
        this.deleteUpload = async (_e) => {
            this.setState((prevState) => {
                const journalSettings = new JournalSettingsEntity({ ...prevState.journalSettings, logo: undefined });
                return { fileDelete: true, journalSettings };
            });
            return true;
        };
        this.onUploadStatus = (countedUploads) => {
            this.setState({ currentUploads: countedUploads }, this.checkSaveStatus);
        };
        this.checkSaveStatus = () => {
            const { currentUploads, startUploads } = this.state;
            if (startUploads && currentUploads === 0) {
                Dialog.instance?.close();
            }
        };
        this.saveNow = () => {
            this.saveTitleNow()
                .then(() => {
                const { currentUploads } = this.state;
                if (currentUploads > 0) {
                    this.setState({ startUploads: true, fileDelete: false, newSettings: false }, this.checkSaveStatus);
                }
                else {
                    this.props.onSaved();
                    Dialog.instance?.close();
                }
            })
                .catch((err) => {
                this.setState({ saveIsLoading: false, startUploads: false, fileDelete: false }, () => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            });
        };
        this.saveTitleNow = async () => {
            const { projectId } = this.props;
            const { journalSettings, currentTitle, fileDelete, newSettings, currentJournalCompany, currentOwnEmployeeHeader, currentExternalEmployeeHeader, currentWeatherHeader, currentWorkDoneHeader, currentSpecialOccurrencesHeader, additionalFields, currentBuilderContent, currentBuilderHeader, selectedIndex, } = this.state;
            if (journalSettings != null &&
                (newSettings ||
                    currentTitle !== journalSettings?.filename ||
                    fileDelete ||
                    currentJournalCompany !== journalSettings.journalCompany)) {
                await new Promise((resolve, reject) => {
                    const to = setTimeout(() => reject(new Error('timeout')), 10000);
                    UpmeshClient.eventDispatcher.attach({
                        attachKey: 'saveJournalSettings',
                        entityId: journalSettings.id,
                        readModelName: 'JournalSettings',
                        callback: (_e) => {
                            UpmeshClient.eventDispatcher.detach('JournalSettings', 'saveJournalSettings');
                            clearTimeout(to);
                            resolve();
                        },
                    });
                    const s = new SaveJournalSettings({
                        logo: journalSettings.logo == null ? undefined : journalSettings.logo,
                        filename: currentTitle,
                        journalCompany: currentJournalCompany === CompanyUserInfo.company?.company ? '' : currentJournalCompany,
                        projectId,
                        ownEmployeeHeader: currentOwnEmployeeHeader,
                        externalEmployeeHeader: currentExternalEmployeeHeader,
                        weatherHeader: currentWeatherHeader,
                        workDoneHeader: currentWorkDoneHeader,
                        specialOccurrencesHeader: currentSpecialOccurrencesHeader,
                        additionalFields,
                        builderContent: currentBuilderContent,
                        builderHeader: currentBuilderHeader,
                        selectedTemplateId: selectedIndex.data,
                    }, journalSettings.id);
                    s.execute().catch((err) => console.debug(err));
                });
            }
        };
        this.save = () => {
            this.setState({ saveIsLoading: true, startUploads: true }, this.saveNow);
        };
        this.state = {
            currentTitle: '',
            currentUploads: 0,
            startUploads: false,
            saveIsLoading: false,
            fileDelete: false,
            newSettings: false,
            currentOwnEmployeeHeader: '',
            currentExternalEmployeeHeader: '',
            currentWeatherHeader: '',
            currentWorkDoneHeader: '',
            currentSpecialOccurrencesHeader: '',
            additionalFields: [],
            currentBuilderHeader: '',
            currentBuilderContent: '',
            availableTemplates: [],
            selectedIndex: { index: 0, data: '' },
        };
    }
    isCustom() {
        const { selectedIndex } = this.state;
        return selectedIndex.data.length > 0;
    }
    componentDidMount() {
        const { projectId } = this.props;
        const ownerId = CompanyUserInfo.company != null ? CompanyUserInfo.company.id : CurrentUser.userId;
        const templates = CompanyUserInfo.companySettings?.reportTemplates?.filter((a) => a.reportType === 'daily') ?? [];
        templates.sort((a, b) => a.title.localeCompare(b.title));
        this.setState({ availableTemplates: templates });
        UpmeshClient.instance.modals.journalSettings
            .get({
            filter: `projectId eq '${projectId}' and owner/id eq '${ownerId}'`,
        })
            .then((js) => {
            const filename = `${I18n.m.getMessage('journal')}_{project}_{forDay}`;
            if (js.length > 0) {
                const journalSettings = new JournalSettingsEntity(js[0]);
                const findIndex = templates.findIndex((a) => a.id === journalSettings.selectedTemplateId);
                this.setState({
                    selectedIndex: findIndex >= 0
                        ? { index: findIndex + 1, data: journalSettings.selectedTemplateId }
                        : { index: 0, data: '' },
                    journalSettings,
                    currentTitle: journalSettings.filename != null ? journalSettings.filename : filename,
                    currentJournalCompany: journalSettings.journalCompany != null && journalSettings.journalCompany.length > 0
                        ? journalSettings.journalCompany
                        : CompanyUserInfo.company?.company,
                    currentBuilderHeader: journalSettings.builderHeader != null && journalSettings.builderHeader.length > 0
                        ? journalSettings.builderHeader
                        : I18n.m.getMessage('builder'),
                    currentBuilderContent: journalSettings.builderContent != null ? journalSettings.builderContent : '{builder}',
                    currentExternalEmployeeHeader: journalSettings.externalEmployeeHeader != null
                        ? journalSettings.externalEmployeeHeader
                        : I18n.m.getMessage('journalAttendances'),
                    currentWeatherHeader: journalSettings.weatherHeader != null
                        ? journalSettings.weatherHeader
                        : I18n.m.getMessage('journalWeather'),
                    currentWorkDoneHeader: journalSettings.workDoneHeader != null
                        ? journalSettings.workDoneHeader
                        : I18n.m.getMessage('journalWorkDone'),
                    currentSpecialOccurrencesHeader: journalSettings.specialOccurrencesHeader != null
                        ? journalSettings.specialOccurrencesHeader
                        : I18n.m.getMessage('journalSpecialOccurrences'),
                    additionalFields: journalSettings.additionalFields != null ? journalSettings.additionalFields : [],
                });
            }
            else {
                this.setState({
                    journalSettings: new JournalSettingsEntity({ filename }),
                    currentTitle: filename,
                    newSettings: true,
                    currentJournalCompany: CompanyUserInfo.company?.company,
                    currentOwnEmployeeHeader: I18n.m.getMessage('journalEmployee'),
                    currentExternalEmployeeHeader: I18n.m.getMessage('journalAttendances'),
                    currentWeatherHeader: I18n.m.getMessage('journalWeather'),
                    currentWorkDoneHeader: I18n.m.getMessage('journalWorkDone'),
                    currentSpecialOccurrencesHeader: I18n.m.getMessage('journalSpecialOccurrences'),
                    currentBuilderHeader: I18n.m.getMessage('builder'),
                    currentBuilderContent: '{builder}',
                }, () => {
                    this.saveTitleNow().catch((err) => console.debug('Cant save settings', err));
                });
            }
        })
            .catch((err) => console.debug('cant load settings', err));
    }
    getTemplateList() {
        const { availableTemplates } = this.state;
        const list = [];
        list.push({ title: I18n.m.getMessage('journalSettingsTemplateDefault'), data: '' });
        availableTemplates.sort();
        for (const template of availableTemplates) {
            list.push({ title: template.title, data: template.id });
        }
        return list;
    }
    renderLayoutHeadLineOptions() {
        const { currentOwnEmployeeHeader, currentExternalEmployeeHeader, currentWeatherHeader, currentWorkDoneHeader, currentSpecialOccurrencesHeader, currentBuilderHeader, } = this.state;
        return (<>
        <View style={{
                height: 0,
                width: '100%',
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                marginBottom: ThemeManager.style.contentPaddingValue,
                marginTop: ThemeManager.style.contentPaddingValue,
            }}/>
        <MaterialText type={MaterialTextTypes.Body1} strong>
          {I18n.m.getMessage('journalSettingsHeadlineText')}
        </MaterialText>
        <MaterialText type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('journalSettingsHeadlineTextHelper')}
        </MaterialText>
        <FormInputFilled value={currentBuilderHeader} onChangeText={(currentBuilderHeader) => this.setState({ currentBuilderHeader })} labelText={I18n.m.getMessage('journalSettingsBuilderHeader')}/>
        <FormInputFilled value={currentOwnEmployeeHeader} onChangeText={(currentOwnEmployeeHeader) => this.setState({ currentOwnEmployeeHeader })} labelText={I18n.m.getMessage('journalEmployee')}/>
        <FormInputFilled value={currentExternalEmployeeHeader} onChangeText={(currentExternalEmployeeHeader) => this.setState({ currentExternalEmployeeHeader })} labelText={I18n.m.getMessage('journalAttendances')}/>
        <FormInputFilled value={currentWeatherHeader} onChangeText={(currentWeatherHeader) => this.setState({ currentWeatherHeader })} labelText={I18n.m.getMessage('journalWeather')}/>
        <FormInputFilled value={currentWorkDoneHeader} onChangeText={(currentWorkDoneHeader) => this.setState({ currentWorkDoneHeader })} labelText={I18n.m.getMessage('journalWorkDone')}/>
        <FormInputFilled value={currentSpecialOccurrencesHeader} onChangeText={(currentSpecialOccurrencesHeader) => this.setState({ currentSpecialOccurrencesHeader })} labelText={I18n.m.getMessage('journalSpecialOccurrences')}/>
      </>);
    }
    renderCustomLogo() {
        const { projectId } = this.props;
        const { journalSettings, startUploads } = this.state;
        if (journalSettings == null) {
            return <Spinner />;
        }
        return (<>
        <View style={{
                height: 0,
                width: '100%',
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                marginBottom: ThemeManager.style.contentPaddingValue,
                marginTop: ThemeManager.style.contentPaddingValue,
            }}/>
        <MaterialText type={MaterialTextTypes.Body1} strong>
          {I18n.m.getMessage('journalSettingsLogo')}
        </MaterialText>
        <View style={{ height: 8 }}/>
        <ProjectUploadButton id={`journalSettings_${projectId}_${journalSettings.id}`} uploadIcon="cloud-upload-outline" multiple={false} uploaded={journalSettings.logo != null
                ? [
                    {
                        id: journalSettings.logo.fileId,
                        targetUrl: `${UpmeshClient.instance.url}/journalsettings/${journalSettings.id}/logo`,
                        name: journalSettings.logo.name,
                    },
                ]
                : undefined} deleteFunction={this.deleteUpload} startUploadImmediately={false} startNow={startUploads} onUploading={this.onUploadStatus} showDeleteIcon filter={['images']} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/journalsettings/${journalSettings.id}/logo`)} uploadText={I18n.m.getMessage('journalSettingsLogoButton')}/>
      </>);
    }
    renderContent() {
        const { journalSettings, currentTitle, currentJournalCompany, additionalFields, currentBuilderContent, selectedIndex, } = this.state;
        if (journalSettings == null) {
            return <Spinner />;
        }
        return (<View>
        <MaterialText type={MaterialTextTypes.Body1} strong>
          {I18n.m.getMessage('journalSettingsTemplateHeader')}
        </MaterialText>
        <FormInputPicker labelText={I18n.m.getMessage('journalSettingsTemplateLabel')} list={this.getTemplateList()} onChange={this.onChangeTemplatePicker} selectedIndex={selectedIndex.index}/>
        <MaterialText type={MaterialTextTypes.Body1} strong>
          Layout
        </MaterialText>
        <FormInputFilled value={currentTitle} onChangeText={(currentTitle) => this.setState({ currentTitle })} labelText={I18n.m.getMessage('journalSettingsFilename')} helperText={I18n.m.getMessage('journalSettingsFilenameHelp')}/>
        <FormInputFilled value={currentJournalCompany} onChangeText={(currentJournalCompany) => this.setState({ currentJournalCompany })} labelText={I18n.m.getMessage('journalSettingsCompanyName')} disabled={this.isCustom()}/>
        <FormInputFilled value={currentBuilderContent} onChangeText={(currentBuilderContent) => this.setState({ currentBuilderContent })} labelText={I18n.m.getMessage('journalSettingsBuilderContent')} helperText={I18n.m.getMessage('journalSettingsBuilderContentHelp')} disabled={this.isCustom()}/>
        {this.isCustom() ? null : this.renderLayoutHeadLineOptions()}
        <JournalSettingsAdditionalFields onChanged={(additionalFields) => this.setState({ additionalFields })} additionalFields={additionalFields}/>
        {this.isCustom() ? null : this.renderCustomLogo()}
      </View>);
    }
    hasChangedArray(a, b) {
        if (a == null && b == null)
            return false;
        if ((a != null && b == null) || (a == null && b != null))
            return true;
        if (a != null && b != null) {
            if (a.length !== b.length)
                return true;
            let changes = false;
            let index = 0;
            do {
                if (a[index] !== b[index])
                    changes = true;
                index += 1;
            } while (!changes && index < a.length);
            return changes;
        }
        return false;
    }
    render() {
        const { saveIsLoading, journalSettings, currentTitle, currentUploads, fileDelete, currentJournalCompany, currentOwnEmployeeHeader, currentExternalEmployeeHeader, currentWeatherHeader, currentWorkDoneHeader, currentSpecialOccurrencesHeader, additionalFields, currentBuilderHeader, currentBuilderContent, selectedIndex, } = this.state;
        const changedFields = journalSettings
            ? this.hasChangedArray(additionalFields, journalSettings.additionalFields)
            : false;
        const hasChanged = journalSettings?.filename !== currentTitle ||
            currentUploads > 0 ||
            fileDelete ||
            (journalSettings.journalCompany === '' && currentJournalCompany !== CompanyUserInfo.company?.company) ||
            (journalSettings.journalCompany !== '' && currentJournalCompany !== journalSettings.journalCompany) ||
            journalSettings.ownEmployeeHeader !== currentOwnEmployeeHeader ||
            journalSettings.externalEmployeeHeader !== currentExternalEmployeeHeader ||
            journalSettings.weatherHeader !== currentWeatherHeader ||
            journalSettings.workDoneHeader !== currentWorkDoneHeader ||
            changedFields ||
            journalSettings.specialOccurrencesHeader !== currentSpecialOccurrencesHeader ||
            journalSettings.builderHeader !== currentBuilderHeader ||
            journalSettings.builderContent !== currentBuilderContent ||
            journalSettings.selectedTemplateId !== selectedIndex.data;
        return [
            <DialogTitle key="journalSettingsTitle" iconRight={{ icon: 'close', onPress: () => Dialog.instance?.close(), toolTip: I18n.m.getMessage('close') }}>
        {I18n.m.getMessage('journalSettings')}
      </DialogTitle>,
            <DialogContent key="JournalSettingsContent" dialogHasTitle>
        {this.renderContent()}
      </DialogContent>,
            <DialogActions key="JournalSettingsActions">
        <ContainedButton disabled={saveIsLoading} key="abort" title={I18n.m.getMessage('cancel')} onPress={this.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton onPress={this.save} disabled={!hasChanged} loading={saveIsLoading} key="save" title={I18n.m.getMessage('save')}/>
      </DialogActions>,
        ];
    }
}

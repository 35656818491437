var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilterChips = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _Chip = require("./Chip");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FilterChips = exports.FilterChips = function (_PureComponent) {
  function FilterChips(props) {
    var _this;
    (0, _classCallCheck2.default)(this, FilterChips);
    _this = _callSuper(this, FilterChips, [props]);
    _this.onPressChip = function (index) {
      return function (_e) {
        var chip = _this.props.chips[index];
        if (chip.checked == null) {
          chip.checked = false;
        }
        chip.checked = !chip.checked;
        var chips = _this.state.chips;
        chips[index] = chip;
        _this.setState({
          chips: chips
        }, function () {
          _this.forceUpdate();
          if (_this.props.onPressChip != null) {
            _this.props.onPressChip(index, chip);
          }
        });
      };
    };
    _this.state = {
      chips: props.chips
    };
    return _this;
  }
  (0, _inherits2.default)(FilterChips, _PureComponent);
  return (0, _createClass2.default)(FilterChips, [{
    key: "render",
    value: function render() {
      var centeredChips = this.props.centeredChips;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: centeredChips ? 'center' : 'flex-start'
        },
        children: this.renderChips()
      });
    }
  }, {
    key: "renderChips",
    value: function renderChips() {
      var _this$props = this.props,
        filterIcon = _this$props.filterIcon,
        outlined = _this$props.outlined,
        textColor = _this$props.textColor,
        iconColor = _this$props.iconColor,
        borderProps = _this$props.borderProps,
        hoverColor = _this$props.hoverColor,
        disableAll = _this$props.disableAll;
      var chips = this.state.chips;
      var backgroundColor = this.props.backgroundColor != null ? this.props.backgroundColor : _ThemeManager.ThemeManager.style.chipDefaultBg;
      var backgroundColorChecked = this.props.backgroundColorChecked != null ? this.props.backgroundColorChecked : (0, _color.default)(backgroundColor).darken(0.2).toString();
      var renderedChips = [];
      if (chips != null && chips.length > 0) {
        for (var i = 0; i < chips.length; i += 1) {
          var c = chips[i];
          var bgColor = c.backgroundColor != null ? c.backgroundColor : backgroundColor;
          var bgColorChecked = c.backgroundColor != null ? (0, _color.default)(c.backgroundColor).darken(0.1).toString() : backgroundColorChecked;
          var tColor = c.textColor != null ? c.textColor : textColor;
          var chipOutlined = c.outlined != null ? c.outlined : outlined;
          renderedChips.push((0, _jsxRuntime.jsx)(_Chip.Chip, {
            title: c.title,
            onPressChip: this.onPressChip(i),
            chipDisabled: c.disabled === true || disableAll,
            hoverColor: hoverColor,
            borderProps: borderProps,
            removeIconColor: iconColor,
            textColor: tColor,
            backgroundColor: c.checked === true ? bgColorChecked : bgColor,
            outlined: chipOutlined,
            thumbnail: c.checked === true ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
              icon: filterIcon != null ? filterIcon : 'check',
              color: tColor,
              toolTip: ""
            }) : undefined
          }, `FilterChips.Chip${i}_checked?${c.checked === true ? 1 : 0}`));
        }
      }
      return renderedChips;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        chips: [],
        filterIcon: 'check',
        disableAll: false
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      return Object.assign({}, prevState, {
        chips: nextProps.chips
      });
    }
  }]);
}(_react.PureComponent);
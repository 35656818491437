"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkerRemoteDB = void 0;
var RemoteODataDB_1 = require("odatarepos/build/src/db/remote/RemoteODataDB");
var WorkerRemoteDB = function (_RemoteODataDB_1$Remo) {
  function WorkerRemoteDB() {
    (0, _classCallCheck2.default)(this, WorkerRemoteDB);
    return _callSuper(this, WorkerRemoteDB);
  }
  (0, _inherits2.default)(WorkerRemoteDB, _RemoteODataDB_1$Remo);
  return (0, _createClass2.default)(WorkerRemoteDB, [{
    key: "fetchData",
    value: function () {
      var _fetchData = (0, _asyncToGenerator2.default)(function* (path) {
        var init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
          method: 'GET'
        };
        return {};
      });
      function fetchData(_x) {
        return _fetchData.apply(this, arguments);
      }
      return fetchData;
    }()
  }]);
}(RemoteODataDB_1.RemoteODataDB);
exports.WorkerRemoteDB = WorkerRemoteDB;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestValidationMail = void 0;
var UserRequestedValidationMail_1 = require("../../events/user/UserRequestedValidationMail");
var ClientUserCommands_1 = require("./root/ClientUserCommands");
var RequestValidationMail = function (_ClientUserCommands_) {
  function RequestValidationMail(data, entityId) {
    var _this;
    (0, _classCallCheck2.default)(this, RequestValidationMail);
    _this = _callSuper(this, RequestValidationMail, [data, entityId]);
    _this.commandName = 'RequestValidationMail';
    _this.aggregate = 'user';
    _this.waitForResult = false;
    return _this;
  }
  (0, _inherits2.default)(RequestValidationMail, _ClientUserCommands_);
  return (0, _createClass2.default)(RequestValidationMail, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new UserRequestedValidationMail_1.UserRequestedValidationMail(this.entityId, {
          userId: this.data.userId
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientUserCommands_1.ClientUserCommands);
exports.RequestValidationMail = RequestValidationMail;
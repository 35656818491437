var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountView = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _UserMailsList = require("../../components/account/mails/UserMailsList");
var _ProfileSettings = require("../../components/account/profile/ProfileSettings");
var _ErrorBoundary = _interopRequireDefault(require("../../reporting/ErrorBoundary"));
var _Card = require("../../theme/components/Card");
var _MaterialText = require("../../theme/components/text/MaterialText");
var _I18n = require("../../theme/i18n/I18n");
var _ThemeManager = require("../../theme/ThemeManager");
var _BackButton = _interopRequireDefault(require("../components/BackButton"));
var _PageView = require("../root/PageView");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var AccountView = exports.AccountView = function (_PureComponent) {
  function AccountView() {
    (0, _classCallCheck2.default)(this, AccountView);
    return _callSuper(this, AccountView, arguments);
  }
  (0, _inherits2.default)(AccountView, _PureComponent);
  return (0, _createClass2.default)(AccountView, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_PageView.PageView, {
        showAccountIcon: true,
        headerProps: {
          leftButtons: [(0, _jsxRuntime.jsx)(_BackButton.default, {}, "backButtonAccountView")],
          rightButtons: [],
          title: _I18n.I18n.m.getMessage('account')
        },
        scrollable: true,
        style: {
          backgroundColor: _ThemeManager.ThemeManager.style.appBgColor
        },
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            maxWidth: 640,
            paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue,
            paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              marginLeft: 8 + _ThemeManager.ThemeManager.style.contentPaddingValue
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: _I18n.I18n.m.getMessage('accountPersonalData')
            })
          }), (0, _jsxRuntime.jsx)(_Card.Card, {
            children: (0, _jsxRuntime.jsx)(_ErrorBoundary.default, {
              componentName: "ProfileSettings",
              children: (0, _jsxRuntime.jsx)(_ProfileSettings.ProfileSettings, {})
            })
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              marginLeft: 8 + _ThemeManager.ThemeManager.style.contentPaddingValue,
              marginTop: 8
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: _I18n.I18n.m.getMessage('accountEmails')
            })
          }), (0, _jsxRuntime.jsx)(_Card.Card, {
            children: (0, _jsxRuntime.jsx)(_ErrorBoundary.default, {
              componentName: "UserMailsList",
              children: (0, _jsxRuntime.jsx)(_UserMailsList.UserMailsList, {})
            })
          }), this.props.children]
        })
      });
    }
  }]);
}(_react.PureComponent);
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToolTipWrapper = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ToolTipWrapper = exports.ToolTipWrapper = function (_PureComponent) {
  function ToolTipWrapper(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ToolTipWrapper);
    _this = _callSuper(this, ToolTipWrapper, [props]);
    _this.toolTipPadding = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8);
    _this.onLayout = function (e) {
      if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
        _this.setState({
          layout: e.nativeEvent.layout
        });
      }
    };
    _this.state = {};
    return _this;
  }
  (0, _inherits2.default)(ToolTipWrapper, _PureComponent);
  return (0, _createClass2.default)(ToolTipWrapper, [{
    key: "render",
    value: function render() {
      var _this$props$t = this.props.t,
        text = _this$props$t.text,
        id = _this$props$t.id,
        elementPosition = _this$props$t.elementPosition;
      var layout = this.state.layout;
      var leftCenter = layout != null ? layout.width / 2 : 0;
      var toolTipHeight = layout != null ? layout.height : 32;
      var left = elementPosition.x + elementPosition.width / 2 - leftCenter;
      var top = elementPosition.y + elementPosition.height + this.toolTipPadding;
      var w = layout != null ? layout.width : elementPosition.width;
      var maxLeft = _ResizeEvent.ResizeEvent.current.windowWidth - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8) - w;
      var maxTop = _ResizeEvent.ResizeEvent.current.windowHeight - toolTipHeight - this.toolTipPadding;
      var maxWidth = _ResizeEvent.ResizeEvent.current.windowWidth - 2 * _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8);
      if (left < 0) {
        left = 0;
      } else if (left > maxLeft) {
        left = maxLeft;
      }
      if (top < 0) {
        top = 0;
      } else if (top > maxTop) {
        var newTop = elementPosition.y - toolTipHeight - 4;
        top = newTop > 0 ? newTop : 0;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        pointerEvents: "none",
        style: [_ThemeManager.ThemeManager.style.tooltipOuter, {
          maxWidth: maxWidth,
          left: left,
          top: top,
          width: 'auto',
          opacity: layout == null ? 0 : 1
        }],
        onLayout: this.onLayout,
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Text, {
          style: [_ThemeManager.ThemeManager.style.tooltipText, {
            position: 'relative',
            textAlign: 'center',
            alignSelf: 'center'
          }],
          numberOfLines: w === maxWidth ? undefined : 1,
          children: text
        })
      }, id);
    }
  }]);
}(_react.PureComponent);
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Calendar = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _I18n = require("../i18n/I18n");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _DateInput = require("./forminput/DateInput");
var _Icon = require("./Icon");
var _MaterialText = require("./text/MaterialText");
var _Ripple = require("./utils/Ripple");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Calendar = exports.Calendar = function (_PureComponent) {
  function Calendar(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Calendar);
    _this = _callSuper(this, Calendar, [props]);
    _this.onChangeView = function () {
      var onChangeView = _this.props.onChangeView;
      var numberOfShownWeeks = _this.state.numberOfShownWeeks;
      if (onChangeView && numberOfShownWeeks != null) {
        var fromDate = _this.getFirstDayVisible();
        var toDate = new Date(new Date(fromDate).setDate(fromDate.getDate() + numberOfShownWeeks * 7 - 1));
        onChangeView(fromDate, toDate);
      }
    };
    _this.getCircleSize = function (viewWidth) {
      var selectedDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _this.state.selectedDate;
      var numberOfShownWeeks = Math.max(1, Math.floor(viewWidth / (8 * 48)));
      var circleSize = Math.min(48, viewWidth / (8 * numberOfShownWeeks));
      var newSelected = selectedDate;
      if (_this.state != null) {
        var _this$state = _this.state,
          currentKW = _this$state.currentKW,
          currentYear = _this$state.currentYear;
        var firstDayVisible = _this.getFirstDayVisible(currentKW, currentYear);
        if (newSelected < firstDayVisible || newSelected > new Date(firstDayVisible.getDate() + 7 * numberOfShownWeeks)) {
          newSelected = firstDayVisible;
        }
      }
      return {
        circleSize: circleSize,
        numberOfShownWeeks: numberOfShownWeeks,
        selectedDate: newSelected
      };
    };
    _this.calculateCircleSize = function (e) {
      var viewWidth = e != null ? e.nativeEvent.layout.width : _ResizeEvent.ResizeEvent.current.contentWidth;
      _this.setState(_this.getCircleSize(viewWidth));
    };
    _this.createChipsList = function (currentYear) {
      var chipsList = [];
      var kwAmount = new Date(currentYear, 0, 1).getDay() === 4 || new Date(currentYear, 11, 31).getDay() === 4 ? 53 : 52;
      for (var i = 0; i < kwAmount; i += 1) {
        chipsList.push({
          title: `${_I18n.I18n.m.getMessage('dateWeekNumberShort')} ${i + 1}`,
          onPressChipData: i + 1
        });
      }
      return chipsList;
    };
    _this.chipsList = _this.createChipsList(_this.props.selectedDate.getFullYear());
    _this.getColor = function (date) {
      var _this$props = _this.props,
        markedTextColor = _this$props.markedTextColor,
        markedColor = _this$props.markedColor,
        defaultTextColor = _this$props.defaultTextColor,
        todaysTextColor = _this$props.todaysTextColor,
        defaultColor = _this$props.defaultColor,
        draftTextColor = _this$props.draftTextColor,
        draftColor = _this$props.draftColor,
        draftedDates = _this$props.draftedDates,
        markedDates = _this$props.markedDates,
        disabledColor = _this$props.disabledColor,
        disabledTextColor = _this$props.disabledTextColor;
      if (todaysTextColor) {
        if (markedDates && markedDates.findIndex(function (marked) {
          return _this.isTheSameDate(marked, date);
        }) > -1 && markedColor && markedTextColor) {
          return {
            color: markedColor,
            textColor: markedTextColor
          };
        }
        if (draftedDates && draftedDates.findIndex(function (draft) {
          return _this.isTheSameDate(draft, date);
        }) > -1 && draftColor && draftTextColor) {
          return {
            color: draftColor,
            textColor: draftTextColor
          };
        }
        if (date > new Date() && disabledColor && disabledTextColor) {
          return {
            color: disabledColor,
            textColor: disabledTextColor
          };
        }
        if (defaultColor && defaultTextColor) {
          return {
            color: defaultColor,
            textColor: defaultTextColor
          };
        }
      }
      return {
        color: _ThemeManager.ThemeManager.style.appBgColorDarken,
        textColor: _ThemeManager.ThemeManager.style.black87
      };
    };
    _this.getFirstDayOfFirstKW = function (currentYear) {
      var startWithMonday = _this.props.startWithMonday;
      var firstDate = new Date(currentYear, 0, 1);
      var startDayMathCorrection = startWithMonday ? 1 : 0;
      if (firstDate.getDay() === 0) {
        firstDate.setDate(firstDate.getDate() + startDayMathCorrection);
      } else if (firstDate.getDay() === 1) {
        firstDate.setDate(firstDate.getDate() + (1 - startDayMathCorrection));
      } else if (firstDate.getDay() === 2) {
        firstDate.setDate(firstDate.getDate() - (1 + startDayMathCorrection));
      } else if (firstDate.getDay() === 3) {
        firstDate.setDate(firstDate.getDate() - (2 + startDayMathCorrection));
      } else if (firstDate.getDay() === 4) {
        firstDate.setDate(firstDate.getDate() - (3 + startDayMathCorrection));
      } else if (firstDate.getDay() === 5) {
        firstDate.setDate(firstDate.getDate() + 2 + startDayMathCorrection);
      } else if (firstDate.getDay() === 6) {
        firstDate.setDate(firstDate.getDate() + 1 + startDayMathCorrection);
      }
      return firstDate;
    };
    _this.firstDayOfFirstKW = _this.getFirstDayOfFirstKW(_this.props.selectedDate.getFullYear());
    _this.getFirstDayVisible = function () {
      var kw = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this.state.currentKW;
      var year = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _this.state.currentYear;
      var startWithMonday = _this.props.startWithMonday;
      var firstDayVisible = _this.getFirstDayOfFirstKW(year);
      firstDayVisible = new Date(firstDayVisible.setDate(firstDayVisible.getDate() + 7 * (kw - 1)));
      if (startWithMonday && firstDayVisible.getDay() === 0 || !startWithMonday && firstDayVisible.getDay() === 6) {
        firstDayVisible.setDate(firstDayVisible.getDate() + 1);
      }
      return firstDayVisible;
    };
    _this.isTheSameDate = function (date1, date2) {
      return new Date(date1).getFullYear() === new Date(date2).getFullYear() && new Date(date1).getMonth() === new Date(date2).getMonth() && new Date(date1).getDate() === new Date(date2).getDate();
    };
    _this.onPressLeftRightKW = function (week) {
      return function () {
        var _this$state2 = _this.state,
          currentKW = _this$state2.currentKW,
          currentYear = _this$state2.currentYear,
          selectedDate = _this$state2.selectedDate;
        var newKW = currentKW + week;
        var newYear = currentYear;
        var newSelected = new Date(selectedDate.getTime());
        newSelected.setDate(newSelected.getDate() - 7 * (currentKW - newKW));
        if (currentKW <= 1 && week === -1) {
          _this.firstDayOfFirstKW = _this.getFirstDayOfFirstKW(currentYear - 1);
          _this.chipsList = _this.createChipsList(currentYear - 1);
          newKW = _this.chipsList.length;
          newYear = currentYear - 1;
        } else if (currentKW === _this.chipsList.length && week === 1) {
          _this.firstDayOfFirstKW = _this.getFirstDayOfFirstKW(currentYear + 1);
          _this.chipsList = _this.createChipsList(currentYear + 1);
          newKW = 1;
          newYear = currentYear + 1;
        }
        var onPressDate = _this.props.onPressDate;
        if (onPressDate) onPressDate(newSelected);else _this.setState({
          currentKW: newKW,
          currentYear: newYear,
          selectedDate: newSelected
        });
      };
    };
    _this.beSureSelectedDateIsShown = function () {
      var _this$state3 = _this.state,
        selectedDate = _this$state3.selectedDate,
        currentKW = _this$state3.currentKW,
        currentYear = _this$state3.currentYear,
        numberOfShownWeeks = _this$state3.numberOfShownWeeks;
      if (numberOfShownWeeks == null) return;
      var firstDayVisible = _this.getFirstDayVisible(currentKW, currentYear);
      var firstDayInvisible = new Date(firstDayVisible.getFullYear(), firstDayVisible.getMonth(), firstDayVisible.getDate());
      firstDayInvisible = new Date(firstDayInvisible.setDate(firstDayInvisible.getDate() + 7 * numberOfShownWeeks));
      if (selectedDate < firstDayVisible || selectedDate >= firstDayInvisible) {
        if (currentKW !== Calendar.getKW(selectedDate) || currentYear !== selectedDate.getFullYear()) {
          var _newKW = Calendar.getKW(selectedDate) - Math.round((numberOfShownWeeks - 1) / 2);
          _this.setState({
            currentKW: _newKW,
            currentYear: selectedDate.getFullYear()
          });
        }
      }
    };
    _this.onSelectDateDatePicker = function (newDate) {
      if (newDate) {
        var onPressDate = _this.props.onPressDate;
        if (onPressDate) onPressDate(new Date(newDate.getTime()));else _this.setState({
          currentKW: Calendar.getKW(newDate),
          currentYear: newDate.getFullYear(),
          selectedDate: newDate
        });
      }
    };
    _this.onPressDate = function (newDate) {
      return function () {
        if (newDate) {
          var onPressDate = _this.props.onPressDate;
          if (onPressDate) onPressDate(newDate);else _this.setState({
            selectedDate: newDate
          });
        }
      };
    };
    _this.renderDay = function (date) {
      var last = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var _this$props2 = _this.props,
        selectedColor = _this$props2.selectedColor,
        disableEverything = _this$props2.disableEverything;
      var _this$state4 = _this.state,
        currentKW = _this$state4.currentKW,
        circleSize = _this$state4.circleSize,
        selectedDate = _this$state4.selectedDate;
      if (circleSize == null) return null;
      var weekday = _I18n.I18n.m.dateCurrent.weekdayNamesShort()[date.getDay()].toUpperCase();
      var colors = _this.getColor(date);
      var today = _this.isTheSameDate(date, new Date());
      var isSelected = selectedDate && _this.isTheSameDate(date, selectedDate);
      var weekDayColor = _ThemeManager.ThemeManager.style.black87;
      if (isSelected) {
        weekDayColor = _ThemeManager.ThemeManager.style.brandPrimary;
      } else if (date.getDay() === 0) {
        weekDayColor = _ThemeManager.ThemeManager.style.brandDanger;
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flex: 3,
          flexGrow: 1,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        },
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          centeredText: true,
          centeredBox: true,
          strong: today,
          color: weekDayColor,
          children: weekday.slice(0, 2)
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            marginTop: 8,
            borderRightWidth: last ? 0 : _ThemeManager.ThemeManager.style.borderWidth,
            borderRightColor: _ThemeManager.ThemeManager.style.borderColor,
            width: '100%',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
            style: {
              height: circleSize,
              width: circleSize,
              borderRadius: circleSize / 2,
              backgroundColor: colors.color,
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              borderWidth: isSelected ? 3 : 0,
              borderColor: selectedColor
            },
            onPress: _this.onPressDate(date),
            disabled: date > new Date() || disableEverything,
            children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              centeredText: true,
              centeredBox: true,
              type: _MaterialText.MaterialTextTypes.H6,
              color: colors.textColor,
              strong: today,
              children: date.getDate().toString()
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                height: today ? 2 : 0,
                backgroundColor: colors.textColor,
                width: circleSize * 0.5,
                borderRadius: 1
              }
            })]
          })
        })]
      }, `dateEntry${date.toDateString()}${currentKW}`);
    };
    _this.renderHeader = function () {
      var _this$state5 = _this.state,
        currentKW = _this$state5.currentKW,
        currentYear = _this$state5.currentYear,
        numberOfShownWeeks = _this$state5.numberOfShownWeeks,
        selectedDate = _this$state5.selectedDate;
      var _this$props3 = _this.props,
        datePickerLabel = _this$props3.datePickerLabel,
        disableEverything = _this$props3.disableEverything,
        coloredDates = _this$props3.coloredDates;
      var kwAmountLastYear = new Date(currentYear - 1, 0, 1).getDay() === 4 || new Date(currentYear - 1, 11, 31).getDay() === 4 ? 53 : 52;
      if (numberOfShownWeeks == null) return null;
      var showNextLastKW = numberOfShownWeeks > 1;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 16,
          alignContent: 'center'
        },
        children: [(0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
          style: {
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            padding: 8,
            paddingTop: 0,
            height: '100%'
          },
          onPress: _this.onPressLeftRightKW(-numberOfShownWeeks),
          disabled: disableEverything,
          children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "chevron-left",
            toolTip: ""
          }), showNextLastKW ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            centeredBox: true,
            centeredText: true,
            children: `${_I18n.I18n.m.getMessage('dateWeekNumberShort')} ${currentKW > 1 ? currentKW - 1 : kwAmountLastYear}`
          }) : undefined]
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          children: [(0, _jsxRuntime.jsx)(_DateInput.DateInput, {
            selectedDate: selectedDate,
            hasDeleteIcon: false,
            onChange: _this.onSelectDateDatePicker,
            labelText: datePickerLabel,
            disabled: disableEverything,
            coloredDates: coloredDates
          }), _this.renderKwTitle()]
        }), (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
          style: {
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            padding: 8,
            paddingTop: 0,
            height: '100%'
          },
          onPress: _this.onPressLeftRightKW(numberOfShownWeeks),
          disabled: disableEverything,
          children: [showNextLastKW ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            centeredBox: true,
            centeredText: true,
            children: `${_I18n.I18n.m.getMessage('dateWeekNumberShort')} ${currentKW + numberOfShownWeeks < _this.chipsList.length ? currentKW + numberOfShownWeeks : currentKW + numberOfShownWeeks - _this.chipsList.length}`
          }) : undefined, (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "chevron-right",
            toolTip: ""
          })]
        })]
      });
    };
    _this.renderWeek = function (currentKW) {
      var _this$state6 = _this.state,
        numberOfShownWeeks = _this$state6.numberOfShownWeeks,
        currentYear = _this$state6.currentYear;
      var startDay = _this.getFirstDayVisible(currentKW, currentYear);
      var renderedDays = [];
      if (numberOfShownWeeks != null) {
        for (var i = 0; i < numberOfShownWeeks * 7; i += 1) {
          var d = _this.renderDay(new Date(new Date(startDay).setDate(startDay.getDate() + i)), i === numberOfShownWeeks * 7 - 1);
          if (d != null) renderedDays.push(d);
        }
      }
      return renderedDays;
    };
    var newKW = Calendar.getKW(props.selectedDate);
    _this.state = {
      currentKW: newKW,
      currentYear: props.selectedDate.getFullYear(),
      selectedDate: props.selectedDate
    };
    return _this;
  }
  (0, _inherits2.default)(Calendar, _PureComponent);
  return (0, _createClass2.default)(Calendar, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.onChangeView();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, prevState) {
      var _this$state7 = this.state,
        currentKW = _this$state7.currentKW,
        currentYear = _this$state7.currentYear,
        numberOfShownWeeks = _this$state7.numberOfShownWeeks;
      if (prevState.selectedDate !== this.state.selectedDate) this.beSureSelectedDateIsShown();
      if (prevState.currentKW !== currentKW || prevState.currentYear !== currentYear || prevState.numberOfShownWeeks !== numberOfShownWeeks) {
        this.onChangeView();
      }
    }
  }, {
    key: "renderKwTitle",
    value: function renderKwTitle() {
      var showKwTitle = this.props.showKwTitle;
      if (!showKwTitle) return null;
      var _this$state8 = this.state,
        numberOfShownWeeks = _this$state8.numberOfShownWeeks,
        currentKW = _this$state8.currentKW;
      var weekString = `${_I18n.I18n.m.getMessage('dateWeekNumberShort')} ${currentKW}`;
      if (numberOfShownWeeks != null && numberOfShownWeeks > 1) {
        weekString = `${weekString} - ${_I18n.I18n.m.getMessage('dateWeekNumberShort')}${currentKW + numberOfShownWeeks - 1}`;
      }
      return (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        centeredBox: true,
        centeredText: true,
        children: weekString
      });
    }
  }, {
    key: "render",
    value: function render() {
      var currentKW = this.state.currentKW;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        onLayout: this.calculateCircleSize,
        style: {
          width: '100%'
        },
        children: [this.renderHeader(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row'
          },
          children: this.renderWeek(currentKW)
        })]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        startWithMonday: _CurrentUser.CurrentUser.settings.startWithMonday,
        defaultColor: _ThemeManager.ThemeManager.style.chipDefaultBg,
        defaultTextColor: _ThemeManager.ThemeManager.style.black87,
        disabledColor: _ThemeManager.ThemeManager.style.black38,
        disabledTextColor: _ThemeManager.ThemeManager.style.black87,
        selectedColor: _ThemeManager.ThemeManager.style.brandPrimary,
        markedColor: _ThemeManager.ThemeManager.style.brandSecondary,
        markedTextColor: '#FFFFFF',
        selectedDate: new Date(),
        markedDates: [],
        draftColor: _ThemeManager.ThemeManager.style.brandWarning,
        draftTextColor: '#FFFFFF',
        draftedDates: [],
        todaysTextColor: _ThemeManager.ThemeManager.style.black87,
        disableEverything: false
      };
    }
  }, {
    key: "getKW",
    value: function getKW(date) {
      var doDat = Calendar.getNextThursday(date);
      var kwjahr = doDat.getFullYear();
      var doKW1 = Calendar.getNextThursday(new Date(kwjahr, 0, 4));
      return Math.floor(1.5 + (doDat.getTime() - doKW1.getTime()) / 86400000 / 7);
    }
  }, {
    key: "getNextThursday",
    value: function getNextThursday(date) {
      var thursday = new Date();
      thursday.setTime(date.getTime() + (3 - (date.getDay() + 6) % 7) * 86400000);
      return thursday;
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.selectedDate.toISOString() !== prevState.selectedDate.toISOString()) {
        return {
          selectedDate: new Date(nextProps.selectedDate.getTime())
        };
      }
      return null;
    }
  }]);
}(_react.PureComponent);
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageView = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _UserImageSyncButton = require("../../components/account/profile/UserImageSyncButton");
var _FadeInAnimation = require("../../theme/components/animations/FadeInAnimation");
var _ContainedButton = require("../../theme/components/button/ContainedButton");
var _Header = require("../../theme/components/header/Header");
var _ScrollContainer = require("../../theme/components/scroll/ScrollContainer");
var _ResizeEvent = require("../../theme/ResizeEvent");
var _ThemeManager = require("../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var PageView = exports.PageView = function (_PureComponent) {
  function PageView(props) {
    var _this;
    (0, _classCallCheck2.default)(this, PageView);
    _this = _callSuper(this, PageView, [props]);
    _this.scrollPosition = 0;
    _this.setRef = function (r) {
      _this.scrollContainer = r;
    };
    _this.handleScroll = function (e) {
      _this.scrollPosition = e.nativeEvent.contentOffset.y;
    };
    _this.onLayout = function (e) {
      if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {}
      if (_this.onResizeTimeOut) {
        clearTimeout(_this.onResizeTimeOut);
      }
      _this.onResizeTimeOut = window.setTimeout(_this.onResizeNow, 50);
    };
    _this.onResize = function (_e) {
      _this.onLayout();
      if (_this.props.onLayout != null) {
        _this.props.onLayout(_e);
      }
    };
    _this.onResizeNow = function () {
      var showMenu = _this.props.showMenu && _reactNativeWeb.Dimensions.get('window').width <= _ThemeManager.ThemeManager.style.breakpointM;
      _this.setState({
        showMenu: showMenu
      }, function () {
        _this.forceUpdate();
      });
    };
    _this.openDrawer = function () {};
    _this._animations = new _FadeInAnimation.FadeInAnimation();
    _this.state = {
      showMenu: props.showMenu && _reactNativeWeb.Dimensions.get('window').width <= _ThemeManager.ThemeManager.style.breakpointM
    };
    return _this;
  }
  (0, _inherits2.default)(PageView, _PureComponent);
  return (0, _createClass2.default)(PageView, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ResizeEvent.ResizeEvent.onResize.attach(this.onResize);
      this._animations.toValue(1, function () {});
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _ResizeEvent.ResizeEvent.onResize.detach(this.onResize);
      clearTimeout(this.onResizeTimeOut);
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        scrollable = _this$props.scrollable,
        headerProps = _this$props.headerProps,
        _this$props$style = _this$props.style,
        style = _this$props$style === void 0 ? {} : _this$props$style,
        showAccountIcon = _this$props.showAccountIcon;
      var textInputRefs = [];
      if (this.props.textInputRefs != null && this.props.textInputRefs.length > 0) {
        for (var i = 0; i < this.props.textInputRefs.length; i += 1) {
          textInputRefs.push(this.props.textInputRefs[i]);
        }
      }
      var headerBG = headerProps != null && headerProps.backgroundColor != null && headerProps.backgroundColor.length > 0 ? headerProps.backgroundColor : _ThemeManager.ThemeManager.style.headerBG;
      var leftButtons = [];
      if (headerProps != null && headerProps.leftButtons != null && headerProps.leftButtons.length > 0) {
        leftButtons.push.apply(leftButtons, (0, _toConsumableArray2.default)(headerProps.leftButtons));
      }
      var rightButtons = [];
      if (headerProps != null && headerProps.rightButtons != null && headerProps.rightButtons.length > 0) {
        rightButtons.push.apply(rightButtons, (0, _toConsumableArray2.default)(headerProps.rightButtons));
      }
      if (this.props.secondaryHeader !== true) {
        if (this.state.showMenu) {
          if (leftButtons == null || leftButtons.length === 0) {
            leftButtons.push((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              icon: {
                icon: 'menu'
              },
              onPress: this.openDrawer,
              textColor: headerProps != null && headerProps.textColor != null ? headerProps.textColor : _ThemeManager.ThemeManager.style.headerButtonColor,
              backgroundColor: "transparent"
            }, "drawerMenuButton"));
          }
        }
        if (this.state.showMenu && showAccountIcon === 'auto' || showAccountIcon === true) {
          rightButtons.push((0, _jsxRuntime.jsx)(_UserImageSyncButton.UserImageSyncButton, {}, "userImage"));
        }
      } else {
        if (showAccountIcon === true || showAccountIcon === 'auto') {
          rightButtons.push((0, _jsxRuntime.jsx)(_UserImageSyncButton.UserImageSyncButton, {}, "userImage"));
        }
        if (headerProps == null || headerProps.backgroundColor == null) {
          headerBG = _ThemeManager.ThemeManager.style.headerBGDarken;
        }
      }
      var keyboardShouldPersistTaps = _reactNativeWeb.Platform.OS === 'android' ? {
        keyboardShouldPersistTaps: 'always'
      } : {};
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        onLayout: this.onLayout,
        style: [{
          position: 'absolute',
          top: _ResizeEvent.ResizeEvent.current.contentTop,
          left: _ResizeEvent.ResizeEvent.current.contentLeft,
          right: _ResizeEvent.ResizeEvent.current.contentRight,
          bottom: _ResizeEvent.ResizeEvent.current.contentBottom,
          flexDirection: 'column'
        }, {
          paddingTop: 0
        }, style],
        children: [headerProps != null ? (0, _jsxRuntime.jsx)(_Header.Header, Object.assign({
          withElevation: false,
          withBorder: true
        }, headerProps, {
          leftButtons: leftButtons,
          rightButtons: rightButtons,
          backgroundColor: headerBG
        })) : null, scrollable === true ? (0, _jsxRuntime.jsx)(_ScrollContainer.ScrollContainer, Object.assign({
          onLayout: this.onLayout,
          style: {
            width: _ResizeEvent.ResizeEvent.current.contentWidth,
            height: _ResizeEvent.ResizeEvent.current.contentHeight
          },
          onScroll: this.handleScroll,
          ref: this.setRef,
          scrollEnabled: scrollable
        }, keyboardShouldPersistTaps, {
          contentContainerStyle: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignSelf: 'stretch'
          },
          children: this.props.children
        })) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          onLayout: this.onLayout,
          children: this.props.children
        })]
      });
    }
  }, {
    key: "scrollTo",
    value: function scrollTo(props) {
      this.scrollContainer.scrollTo(props);
    }
  }, {
    key: "scrollToPageY",
    value: function scrollToPageY(y) {
      var animated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var newY = this.scrollPosition + y - _ThemeManager.ThemeManager.style.contentPaddingValue;
      if (_ThemeManager.ThemeManager.style.headerHeight > 0) {
        newY -= _ThemeManager.ThemeManager.style.headerHeight;
      }
      if (this.scrollContainer != null && newY != null && newY > 0) {
        this.scrollContainer.scrollTo({
          animated: animated,
          y: newY,
          x: 0
        });
      }
    }
  }]);
}(_react.PureComponent);
PageView.defaultProps = {
  showMenu: true,
  showAccountIcon: 'auto',
  headerTextColor: _ThemeManager.ThemeManager.style.black87
};
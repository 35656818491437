var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DBleadermanager = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var DBleadermanager = exports.DBleadermanager = (0, _createClass2.default)(function DBleadermanager() {
  (0, _classCallCheck2.default)(this, DBleadermanager);
});
DBleadermanager.isLeader = false;
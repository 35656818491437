"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MarkLogBookAsRead = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../server/webserver/commands/CommandReadModels");
var LogBookRead_1 = require("../events/logbook/LogBookRead");
var MarkLogBookAsRead = function (_CommandRoot_1$Comman) {
  function MarkLogBookAsRead(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, MarkLogBookAsRead);
    _this = _callSuper(this, MarkLogBookAsRead, [data, entityId, token]);
    _this.commandName = 'MarkLogBookAsRead';
    _this.aggregate = 'upmeshUser';
    _this.data = data;
    return _this;
  }
  (0, _inherits2.default)(MarkLogBookAsRead, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(MarkLogBookAsRead, [{
    key: "getLogBook",
    value: function () {
      var _getLogBook = (0, _asyncToGenerator2.default)(function* () {
        if (this._logBook) return this._logBook;
        this._logBook = yield CommandReadModels_1.CommandReadModels.logBook.getById(this.entityId);
        return this._logBook;
      });
      function getLogBook() {
        return _getLogBook.apply(this, arguments);
      }
      return getLogBook;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var userId = tokenData._id;
        if (userId === 'all') return true;
        var l = yield this.getLogBook();
        var i = l.userIds.findIndex(function (a) {
          return a.userId === userId;
        });
        if (i > -1) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var userId = tokenData._id;
        var l = yield this.getLogBook();
        var hasRead = l.readByUserIds && l.readByUserIds.findIndex(function (a) {
          return a.userId === userId;
        }) > -1;
        if (hasRead) return null;
        return new LogBookRead_1.LogBookRead(this.entityId, {}, userId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.MarkLogBookAsRead = MarkLogBookAsRead;
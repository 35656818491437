var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DayButton = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DayButton = exports.DayButton = function (_PureComponent) {
  function DayButton(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DayButton);
    _this = _callSuper(this, DayButton, [props]);
    _this.registerPress = function () {
      if (_this.props.onPress === undefined) return;
      _this.props.onPress(parseInt(_this.props.text, 10));
    };
    return _this;
  }
  (0, _inherits2.default)(DayButton, _PureComponent);
  return (0, _createClass2.default)(DayButton, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        highlightColor = _this$props.highlightColor,
        disabled = _this$props.disabled,
        inRange = _this$props.inRange,
        marginRight = _this$props.marginRight,
        marginLeft = _this$props.marginLeft;
      var width = 40;
      if (Number.isInteger(marginRight)) width += marginRight;
      if (Number.isInteger(marginLeft)) width += marginLeft;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          height: 38,
          width: width,
          position: inRange === 'start' || inRange === 'end' ? 'relative' : undefined,
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: !Number.isInteger(marginRight) ? undefined : marginRight,
          paddingLeft: !Number.isInteger(marginLeft) ? undefined : marginLeft,
          marginRight: !Number.isInteger(marginRight) ? marginRight : 0,
          marginLeft: !Number.isInteger(marginLeft) ? marginLeft : 0,
          backgroundColor: inRange === true ? (0, _color.default)(_ThemeManager.ThemeManager.style.brandPrimary).alpha(0.25).toString() : 'transparent'
        },
        children: [inRange === 'start' || inRange === 'end' ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            width: width / 2,
            left: inRange === 'end' ? 0 : width / 2,
            height: 38,
            backgroundColor: (0, _color.default)(_ThemeManager.ThemeManager.style.brandPrimary).alpha(0.25).toString()
          }
        }) : null, (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          disabled: this.props.onPress === undefined || disabled,
          onPress: this.registerPress,
          accessibilityLabel: "calendarDate",
          style: {
            height: 36,
            width: 36,
            borderRadius: 18,
            backgroundColor: this.props.clicked && !highlightColor ? _ThemeManager.ThemeManager.style.brandPrimary : highlightColor,
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: this.props.clicked && highlightColor ? 2 : 0,
            borderColor: _ThemeManager.ThemeManager.style.brandPrimary
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            textAlign: "center",
            fixedWidth: 36,
            color: disabled ? '#999' : this.props.color == null ? this.props.clicked || highlightColor != null ? _ThemeManager.ThemeManager.style.brandLight : _ThemeManager.ThemeManager.style.brandDark : this.props.color,
            type: _MaterialText.MaterialTextTypes.Body1,
            additionalTextStyle: {
              textDecorationLine: this.props.current ? 'underline' : undefined
            },
            children: this.props.text
          })
        })]
      });
    }
  }]);
}(_react.PureComponent);
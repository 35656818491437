"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientProjectCommands = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var ClientStore_1 = require("../../ClientStore");
var ProjectEntity_1 = require("../../query/entities/ProjectEntity");
var ClientProjectCommands = function (_CommandRoot_1$Comman) {
  function ClientProjectCommands(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ClientProjectCommands);
    _this = _callSuper(this, ClientProjectCommands, [data, entityId, token]);
    _this.waitForResult = true;
    _this.data = data;
    return _this;
  }
  (0, _inherits2.default)(ClientProjectCommands, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ClientProjectCommands, [{
    key: "checkProjectStatusAndGeneralAccess",
    value: function () {
      var _checkProjectStatusAndGeneralAccess = (0, _asyncToGenerator2.default)(function* (projectId, userId) {
        var igonoreArchive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        return ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(projectId, userId, igonoreArchive);
      });
      function checkProjectStatusAndGeneralAccess(_x, _x2) {
        return _checkProjectStatusAndGeneralAccess.apply(this, arguments);
      }
      return checkProjectStatusAndGeneralAccess;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* () {
        var onStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ClientStore_1.ClientStore.commandStore;
        try {
          return yield _superPropGet(ClientProjectCommands, "execute", this, 3)([onStore]);
        } catch (e) {
          if (e['statusCode'] === '401') yield AuthClient_1.AuthClient.instance.validateToken('Command 401 error');
          throw e;
        }
      });
      function execute() {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ClientProjectCommands = ClientProjectCommands;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientStore = void 0;
var ClientStore = function () {
  function ClientStore() {
    (0, _classCallCheck2.default)(this, ClientStore);
  }
  return (0, _createClass2.default)(ClientStore, null, [{
    key: "commandStore",
    get: function get() {
      return ClientStore._commandStore;
    },
    set: function set(store) {
      ClientStore._commandStore = store;
    }
  }]);
}();
exports.ClientStore = ClientStore;
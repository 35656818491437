import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { CachedEntities } from '../../config/CachedEntities';
import { ProjectImage } from '../project/ProjectImage';
export function TicketTableProjectCell(props) {
    const [project, setProject] = useState();
    const loadProject = async (id) => {
        const cache = CachedEntities.knownProjects.get(id);
        if (cache)
            return cache;
        return UpmeshClient.instance.modals.project.getById(id);
    };
    useEffect(() => {
        let mounted = true;
        loadProject(props.ticket.projectId)
            .then((p) => {
            if (mounted)
                setProject(p);
        })
            .catch((err) => console.debug(err));
        return () => {
            mounted = false;
        };
    }, [props.ticket]);
    if (project == null)
        return (<View style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16, width: 300 }, props.style]}/>);
    const openProject = (e) => {
        if (project != null && project.id.length > 0)
            Routing.instance.goToButton(`/projects/${project.id}/${StoredStartPages.getStoredProjectStartPage()}`)(e);
    };
    return (<View style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16, width: 300 }, props.style]}>
      <Ripple onPress={openProject} style={{ flexDirection: 'row', alignItems: 'center' }}>
        <ProjectImage size={24} project={project}/>
        <View style={{ width: 8 }}/>
        <MaterialText>{`${project.title}`}</MaterialText>
      </Ripple>
    </View>);
}

import ConnectionContext from 'materialTheme/src/connectionContext';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MapMarker } from 'materialTheme/src/theme/components/zoom/MapMarker';
import { ZoomTileHelper } from 'materialTheme/src/theme/components/zoom/ZoomTileHelper';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Linking, Pressable, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ChangeTicketPlanAffiliation, } from 'upmesh-core/src/client/commands/tickets/ChangeTicketPlanAffiliation';
import { TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { OfflineDataDownloader } from '../../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { PlanZoomTileMapper } from '../../plans/PlanZoomTileMapper';
export class PlanAffiliationThumbs extends PureComponent {
    constructor(props) {
        super(props);
        this.changePlanPosition = (e) => {
            const { ticket } = this.props;
            const asyncNow = async () => {
                const error = await DefaultErrorHandler.getProjectErrors(ticket.projectId);
                if (error) {
                    return;
                }
                Routing.instance.openDialog('ticket', {
                    id: ticket.id,
                    changePlan: true,
                }, false)(e);
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.checkCanChangeAffiliationAccessRights = async () => {
            const { ticket, planVersion } = this.props;
            const info = planVersion != null
                ? await ZoomTileHelper.getTileInfo(`${UpmeshClient.instance.url}/plan/tiles/${ticket.projectId}/planVersion/${planVersion.id}`)
                : undefined;
            if (ticket !== undefined) {
                const cChange = new ChangeTicketPlanAffiliation({}, ticket.id, CurrentUser.token);
                cChange
                    .canI()
                    .then((canI) => {
                    this.setState({ canChangeAffiliation: canI === true, info, loaded: true });
                })
                    .catch((e) => {
                    console.debug('no rights to change plan or plan postion', e);
                });
            }
        };
        this.onRef = (r) => {
            const { ticket, planVersion } = this.props;
            if (r != null &&
                ticket?.planId != null &&
                planVersion == null &&
                ticket.planPositionX != null &&
                ticket.planPositionY != null) {
                r.zoomTo({ x: ticket.planPositionX, y: ticket.planPositionY }, 15);
            }
        };
        this.renderPlanThumb = (zoomLevel) => {
            const { ticket, planVersion, plan } = this.props;
            const { info, loaded } = this.state;
            let planThumb = (<View>
        <MaterialText>{I18n.m.getMessage('noPlanAffiliation')}</MaterialText>
      </View>);
            if (plan != null &&
                ticket != null &&
                ticket.planId != null &&
                ticket.planPositionX != null &&
                ticket.planPositionY != null &&
                plan.activePlanId != null &&
                plan.activePlanId.length > 0) {
                const point = planVersion == null
                    ? { x: ticket.planPositionX, y: ticket.planPositionY }
                    : planVersion.getPointInPageFromRealpointInCM({ x: ticket.planPositionX, y: ticket.planPositionY });
                const markers = [];
                let zoom = 16;
                markers.push(new MapMarker(ticket.id, TicketStatusColor.getMarkerForStatus(ticket.ticketStatus, ticket.completionOn), point, undefined, this.gotoActivePlanVersion({
                    planId: ticket.planId,
                    x: ticket.planPositionX,
                    y: ticket.planPositionY,
                    zoom: zoomLevel != null ? zoomLevel : zoom,
                })));
                let initialPlanPosition;
                if (planVersion != null) {
                    zoom = 3;
                    if (info != null) {
                        zoom = info.maxLevel - Math.ceil(info.maxLevel / 2);
                        if (zoom < 1)
                            zoom = 1;
                    }
                    initialPlanPosition = {
                        center: { point: { ...markers[0].position } },
                        zoom: zoomLevel != null ? zoomLevel : zoom,
                    };
                }
                planThumb = (<Pressable style={{
                        width: 256,
                        height: 144,
                        borderStyle: 'solid',
                        borderWidth: ThemeManager.style.borderWidth,
                        borderColor: ThemeManager.style.borderColor,
                    }} onPress={this.gotoActivePlanVersion({
                        planId: ticket.planId,
                        x: ticket.planPositionX,
                        y: ticket.planPositionY,
                        zoom: zoomLevel != null ? zoomLevel : zoom,
                    })}>
          {loaded ? (<PlanZoomTileMapper plan={plan} initialPlanPosition={initialPlanPosition} ref={this.onRef} type={planVersion == null ? 'map' : 'plan'} planVersion={planVersion} zoomAble={false} key={`plan_${planVersion == null ? `${ticket.planId}_${ticket.address}` : planVersion.id}`} marker={markers} offlineData={planVersion == null
                            ? undefined
                            : OfflineDataDownloader.isPlanVersionSynced(planVersion.projectId, planVersion.id)} url={planVersion == null
                            ? 'map'
                            : `${UpmeshClient.instance.url}/plan/tiles/${ticket.projectId}/planVersion/${planVersion.id}`}/>) : null}
        </Pressable>);
            }
            return planThumb;
        };
        this.openUrl = (url) => (_e) => {
            Linking.openURL(url).catch((err) => console.debug(err));
        };
        this.gotoActivePlanVersion = (plan) => (_e) => {
            const { ticket } = this.props;
            Routing.instance.goTo(`/projects/${ticket.projectId}/tickets/${plan.planId}/?zTo=${plan.x}x${plan.y}x${plan.zoom}x${ticket.id}`);
        };
        this.state = {
            loaded: false,
            canChangeAffiliation: false,
        };
    }
    componentDidMount() {
        this.checkCanChangeAffiliationAccessRights().catch((err) => console.warn('cant check rights', err));
    }
    render() {
        const { labelStyle, plan, ticket } = this.props;
        const { canChangeAffiliation } = this.state;
        const { connectedToServer } = this.context;
        let localSynced = false;
        if (plan != null && plan.activePlanId != null && plan.activePlanId !== 'MAP') {
            localSynced = OfflineDataDownloader.isPlanVersionSynced(plan.projectId, plan.activePlanId) != null;
        }
        const label = plan != null && plan.activePlanId === 'MAP'
            ? I18n.m.getMessage('ticketsDetailsMap')
            : I18n.m.getMessage('ticketsDetailsPlan');
        return (<View style={{ width: '100%', position: 'relative', minHeight: 64 }}>
        <View style={labelStyle}>
          <MaterialText type={MaterialTextTypes.Caption}>{label}</MaterialText>
        </View>
        {plan != null &&
                ticket != null &&
                ticket.planId != null &&
                ticket.planPositionX != null &&
                plan?.activePlanId !== 'MAP' &&
                ticket.planPositionY != null ? (<View style={{ paddingBottom: 8 }}>{this.renderPlanThumb(0)}</View>) : undefined}
        {this.renderPlanThumb()}
        {plan ? (<View accessibilityLabel="planTitle" style={{ paddingTop: 8 }}>
            <MaterialText>{plan.title}</MaterialText>
          </View>) : undefined}
        {this.renderAddress()}
        <View style={{
                position: 'absolute',
                top: 4,
                right: 0,
            }}>
          {!canChangeAffiliation ? undefined : (<Icon key="editPlan" icon="pencil-outline" onPress={this.changePlanPosition} toolTip={I18n.m.getMessage('changeTicketPlanPosition')} disabled={!canChangeAffiliation || (!connectedToServer && !localSynced)}/>)}
        </View>
      </View>);
    }
    renderAddress() {
        const { ticket, plan, planVersion } = this.props;
        if (plan?.activePlanId !== 'MAP' || planVersion != null || ticket.address == null || ticket.address.length === 0)
            return null;
        return (<View style={{
                flexDirection: 'row',
                width: '100%',
                marginBottom: 16,
                marginTop: 8,
                paddingRight: 8,
                justifyContent: 'space-between',
            }}>
        <View style={{ flex: 1, alignSelf: 'center' }}>
          <MaterialText onPress={this.openUrl(`https://www.google.de/maps/dir//'${ticket.address}'/@${ticket.planPositionY},${ticket.planPositionX},18z`)}>
            {ticket.address}
            <View style={{ width: 6 }}/>
          </MaterialText>
        </View>
        {ticket.planPositionX != null && ticket.planPositionY != null ? (<Icon onPress={this.openUrl(`https://www.google.de/maps/dir//'${ticket.address}'/@${ticket.planPositionY},${ticket.planPositionX},18z`)} icon="directions" color="#FFFFFF" toolTip={I18n.m.getMessage('address')} backgroundColor={ThemeManager.style.brandPrimary}/>) : null}
      </View>);
    }
}
PlanAffiliationThumbs.contextType = ConnectionContext;

var _a;
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import * as mime from 'mime';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { DeviceManager } from 'materialTheme/src/securestore/DeviceManager';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export class AddStoredFileAndUpload {
}
_a = AddStoredFileAndUpload;
AddStoredFileAndUpload.onGotFile = async (f, projectId, ticketId, fId) => {
    const catched = await DefaultErrorHandler.getProjectErrors(projectId);
    if (catched)
        return null;
    if (f['folder'] != null && f['filepath'] == null)
        return null;
    let orgFilename = f['name'] != null ? f['name'] : f['filename'] != null ? f['filename'] : f['fileName'];
    if (orgFilename == null) {
        orgFilename = f.path.split('/').pop();
    }
    const ext = orgFilename.split('.').pop();
    if (ext == null || ext.length === 0) {
        return Routing.instance.alert.post({ text: I18n.m.getMessage('fileError') });
    }
    const fileId = fId != null ? fId : `${uuid.v1()}.${ext}`;
    const mimeType = mime.getType(fileId);
    const data = {
        fileId,
        projectId,
        fileType: 'any',
        mimeType: mimeType != null ? mimeType : '',
        deviceId: DeviceManager.deviceId,
        forEntity: ticketId != null ? 'Ticket' : undefined,
        forEntityId: ticketId != null ? ticketId : undefined,
    };
    const type = ticketId != null ? `ticket_${ticketId}` : `storedfile_${projectId}`;
    UpmeshClient.eventDispatcher.detach('StoredFile', fileId);
    try {
        const tempFile = await Uploads.addUpload(`${UpmeshClient.instance.url}/storedfile/file/`, f.webFile != null ? f.webFile : f, type, data);
        console.debug('add upload', tempFile.id);
    }
    catch (e) {
        DefaultErrorHandler.showDefaultErrorAlert(e);
    }
    return null;
};

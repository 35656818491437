import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { Config } from '../../config/Config';
import { I18n } from '../../i18n/I18n';
export class PlanUploadedThumbs extends PureComponent {
    render() {
        const { imageWidth, imageHeight, fileId, maxWidth, margin, title, secondPlanId, rotation } = this.props;
        const headers = {};
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const bigSource = {
            headers,
            uri: `${Config.b2cURL}/plan/thumb/temp/${fileId}/`,
        };
        const bigSource2 = {
            headers,
            uri: `${Config.b2cURL}/plan/thumb/plan/${secondPlanId}/`,
        };
        const width = maxWidth != null && maxWidth < imageWidth ? maxWidth : imageWidth;
        const height = imageHeight;
        const optionalView = [];
        if (title != null) {
            optionalView.push(<View key="title" style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: ThemeManager.style.overlayColor,
                    minHeight: 48,
                    justifyContent: 'center',
                    padding: 8,
                }}>
          <MaterialText color="#FFFFFF" type={MaterialTextTypes.Subtitle2}>
            {title}
          </MaterialText>
        </View>);
        }
        const transform = rotation != null ? [{ rotate: `${rotation}deg` }] : undefined;
        if (secondPlanId != null && secondPlanId.length > 0) {
            const newWidth = Math.round(width / 2 - 16);
            const newHeight = Math.round(height - 16);
            const newSize = Math.min(newWidth, newHeight);
            return (<View style={{
                    height,
                    margin: margin != null && margin ? 16 : 0,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
          <View style={{ justifyContent: 'center', alignContent: 'center' }}>
            <MaterialText centeredBox centeredText type={MaterialTextTypes.H6}>
              {I18n.m.getMessage('planUploadExisting')}
            </MaterialText>
            <View style={{
                    height: newHeight,
                    width: newWidth,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
              <CacheableImage lightBox width={newWidth} height={newHeight} source={bigSource2} resizeMode="contain"/>
            </View>
          </View>
          <View style={{
                    justifyContent: 'center',
                    alignContent: 'center',
                }}>
            <MaterialText centeredBox centeredText type={MaterialTextTypes.H6} color={ThemeManager.style.brandDanger}>
              {I18n.m.getMessage('planUploadNew')}
            </MaterialText>
            <View style={{
                    height: newHeight,
                    width: newWidth,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
              <CacheableImage lightBox style={{
                    transform,
                }} width={newSize} height={newSize} source={bigSource} resizeMode="contain"/>
            </View>
          </View>
          {optionalView}
        </View>);
        }
        const isRotated = rotation === 90 || rotation === 270;
        return (<View style={{
                height,
                width,
                margin: margin != null && margin ? 16 : 0,
                padding: 0,
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignContent: 'center',
                alignItems: 'center',
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: ThemeManager.style.borderColor,
                borderRadius: ThemeManager.style.borderRadius,
                overflow: 'hidden',
            }}>
        <CacheableImage showLoading lightBox style={{
                transform,
                overflow: 'hidden',
            }} width={isRotated ? height : width} height={isRotated ? width : height} source={bigSource} resizeMode="contain"/>
        {optionalView}
      </View>);
    }
}
PlanUploadedThumbs.defaultProps = {
    rotation: 0,
    secondPlanId: null,
    title: null,
    margin: true,
};

import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
export class NotFoundView extends PureComponent {
    render() {
        const site = this.props.location;
        const mes = site != null && site.length > 0 ? I18n.m.getMessage('notFoundSite', { site }) : I18n.m.getMessage('notFound');
        return (<View>
        <View style={{ width: '100%', paddingTop: 48, alignItems: 'center', justifyContent: 'center' }}>
          <MaterialText centeredBox centeredText type={MaterialTextTypes.H5}>
            {mes}
          </MaterialText>
          <View style={{ height: 16 }}/>
          <ContainedButton full title={I18n.m.getMessage('toStartSite')} onPress={Routing.instance.goToButton('/')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        </View>
      </View>);
    }
}
NotFoundView.defaultProps = {
    title: 'upmesh',
};

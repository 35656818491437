"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoteDeleted = void 0;
var EventRootOdata_1 = require("cqrs-core/build/src/core/events/EventRootOdata");
var NoteDeleted = function (_EventRootOdata_1$Eve) {
  function NoteDeleted(entityId, userId) {
    var _this;
    (0, _classCallCheck2.default)(this, NoteDeleted);
    _this = _callSuper(this, NoteDeleted, [{
      entityId: entityId,
      eventName: 'NoteDeleted',
      aggregate: 'user',
      aggregateId: userId
    }]);
    _this.eventName = 'NoteDeleted';
    return _this;
  }
  (0, _inherits2.default)(NoteDeleted, _EventRootOdata_1$Eve);
  return (0, _createClass2.default)(NoteDeleted);
}(EventRootOdata_1.EventRootOdata);
exports.NoteDeleted = NoteDeleted;
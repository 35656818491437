export class StoredFileDownloaderOptions {
    constructor(target, view = false, localFile, storedfile, editable = false) {
        this.view = false;
        this.editable = editable;
        this.storedfile = storedfile;
        this.target = target;
        this.view = view;
        this.localFile = localFile;
    }
}

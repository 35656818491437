import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ChangeProjectUserPhone } from 'upmesh-core/src/client/commands/user/ChangeProjectUserPhone';
import { ChangeUserRole } from 'upmesh-core/src/client/commands/user/ChangeUserRole';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
export class ProjectChangeRolePU extends PureComponent {
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('projectChangeRoleTitle');
        this.submit = () => {
            this.setState({
                isLoading: true,
            }, () => {
                this.submitNow().catch((err) => console.debug(err));
            });
        };
        this.onResize = () => {
            if (this.resizeTimeOut) {
                clearTimeout(this.resizeTimeOut);
            }
            this.resizeTimeOut = window.setTimeout(() => {
                this.forceUpdate();
            }, 250);
        };
        this.onChangeRole = (value) => {
            this.setState({ selectedRoleIndex: value.index });
        };
        this.onChangeGroup = (value) => {
            this.setState({ selectedGroupIndex: value.index });
        };
        this.state = {
            usergroups: [],
            isLoading: false,
            selectedProjectUser: null,
            selectedUser: null,
            emailError: '',
            emailTextFieldActive: false,
            init: false,
            roles: [],
            currentRoleIndex: 0,
            selectedRoleIndex: 0,
            groupList: [],
            roleList: [],
            selectedGroupIndex: 0,
            currentGroupIndex: 0,
            phone: '',
            initialPhone: '',
            initialRoleIndex: 0,
        };
    }
    async init() {
        try {
            await DateLocale.waitFor(() => CurrentProject.instance.getCurrentProjectId() != null);
            const project = CurrentProject.instance.getCurrentProject();
            if (project != null) {
                const filter = project.projectSubscription == null || project.projectSubscription === 'basic'
                    ? `(projectId eq '${project.id}' or projectId eq '0') and basicRole eq true and deleted ne true`
                    : `deleted ne true and (projectId eq '${project.id}' or projectId eq '0')`;
                const getRoles = await UpmeshClient.instance.modals.projectRoles.get({ filter });
                const selectedProjectUser = await UpmeshClient.instance.modals.projectUser.getById(this.props.data.id);
                const selectedUser = await AuthClient.instance.modals.user.getById(selectedProjectUser.userId);
                const roleList = [];
                const companies = await UpmeshClient.instance.modals.company.get({
                    filter: `users/userId eq '${selectedUser.userId}'`,
                });
                for (const role of getRoles) {
                    let title = I18n.m.getMessage(role.roleName);
                    if (role.roleName === 'subcontractor' &&
                        project != null &&
                        companies.length > 0 &&
                        project.creator.startsWith('company') &&
                        `company${companies[0].id}` === project.creator) {
                        title = I18n.m.getMessage('subcontractorInOwnCompany');
                    }
                    roleList.push({ title, id: role.id });
                }
                let selectedRoleIndex = 0;
                for (let i = 0; i < roleList.length; i += 1) {
                    if (roleList[i].id === selectedProjectUser.roleId) {
                        selectedRoleIndex = i;
                        break;
                    }
                }
                const currentRoleIndex = selectedRoleIndex;
                let selectedGroupIndex = 0;
                const groupList = [];
                if (project.userGroups != null && project.userGroups.length > 0) {
                    selectedGroupIndex = project.userGroups.findIndex((g) => g === selectedProjectUser.userGroup) + 1;
                    groupList.push({ title: I18n.m.getMessage('noCompany') });
                    project.userGroups.forEach((group) => {
                        groupList.push({ title: group });
                    });
                }
                const currentGroupIndex = selectedGroupIndex;
                const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                    filter: `userId eq '${selectedProjectUser.userId}'`,
                });
                let correctPhone = selectedProjectUser.phone;
                if (correctPhone == null && companyMember.length > 0) {
                    correctPhone = companyMember[0].phone;
                }
                if (correctPhone == null) {
                    correctPhone = selectedUser.phone;
                }
                this.setState({
                    groupList,
                    selectedUser,
                    selectedRoleIndex,
                    currentRoleIndex,
                    currentGroupIndex,
                    selectedGroupIndex,
                    selectedProjectUser,
                    init: true,
                    roles: getRoles,
                    roleList,
                    usergroups: project.userGroups != null ? project.userGroups : [],
                    phone: correctPhone ?? '',
                    initialPhone: correctPhone ?? '',
                    initialRoleIndex: selectedRoleIndex,
                });
            }
        }
        catch (e) {
            console.debug('cant init', e);
            Routing.instance.closeDialog(true)(null);
        }
    }
    componentWillUnmount() {
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    renderUserGroups() {
        const { selectedGroupIndex, groupList } = this.state;
        if (groupList.length > 0) {
            return (<FormInputPicker labelText={I18n.m.getMessage('company')} key="companyInput" onChange={this.onChangeGroup} selectedIndex={selectedGroupIndex} list={groupList}/>);
        }
        return null;
    }
    render() {
        const { phone, init, selectedRoleIndex, currentRoleIndex, roles, isLoading, selectedUser, roleList } = this.state;
        if (init) {
            if (selectedUser != null) {
                const currentRole = roleList[currentRoleIndex].title;
                const message = CurrentUser.userId === selectedUser.userId ? 'projectChangeRoleTitleMe' : 'projectChangeRoleTitleFrom';
                this.title = I18n.m.getMessage(message, {
                    currentRole,
                    userName: `${selectedUser.firstname} ${selectedUser.lastname}`,
                });
            }
            else {
                this.title = I18n.m.getMessage('projectChangeRoleTitle');
            }
            const roleDescription = roles[selectedRoleIndex].description != null
                ? roles[selectedRoleIndex].description
                : I18n.m.getMessage(`roleDescription${roles[selectedRoleIndex].roleName}`);
            const desc = roleDescription === `roleDescription${roles[selectedRoleIndex].roleName}` ? '' : roleDescription;
            return [
                <DialogContent key="content">
          <DialogTitle inContent>{this.title}</DialogTitle>
          
          
          <FormInputPicker labelText={I18n.m.getMessage('role')} onChange={this.onChangeRole} selectedIndex={selectedRoleIndex} list={roleList}/>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Body2}>
            {desc}
          </MaterialText>
          <FormInputFilled value={phone} disabled={isLoading} labelText={I18n.m.getMessage('phone')} onChange={(e) => this.setState({ phone: e })} helperText={I18n.m.getMessage('projectChangePhoneHelperText')}/>
        </DialogContent>,
                <DialogActions key="actions" onBottom>
          <ContainedButton disabled={isLoading} title={I18n.m.getMessage('abort')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={Routing.instance.closeDialog(true)}/>
          <ContainedButton disabled={isLoading} loading={isLoading} onPress={this.submit} title={I18n.m.getMessage('projectChangeRoleTitle')} backgroundColor={ThemeManager.style.brandPrimary}/>
        </DialogActions>,
            ];
        }
        return (<View style={{ alignItems: 'stretch' }}>
        <DialogTitle>{this.title}</DialogTitle>
        <Spinner />
        <View style={{ height: 16 }}/>
      </View>);
    }
    async submitNow() {
        const { initialRoleIndex, phone, initialPhone, selectedRoleIndex, roleList, selectedProjectUser, groupList, selectedGroupIndex, } = this.state;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId == null || selectedProjectUser == null) {
            return;
        }
        const userGroup = selectedGroupIndex > 0 ? groupList[selectedGroupIndex].title : '';
        if (initialRoleIndex !== selectedRoleIndex) {
            const command = new ChangeUserRole({
                userGroup,
                roleId: roleList[selectedRoleIndex].id,
            }, selectedProjectUser.id);
            try {
                await command.execute();
            }
            catch (e) {
                if (e.messageCode != null) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage(e.messageCode) });
                }
                else {
                    ErrorReporter.sendReport({ subject: 'error', data: e, type: 'warn' });
                }
                this.setState({ isLoading: false });
            }
        }
        if (phone !== initialPhone) {
            const changePhoneCommand = new ChangeProjectUserPhone({ phone }, selectedProjectUser.id);
            try {
                await changePhoneCommand.execute();
            }
            catch (e) {
                if (e.messageCode != null) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage(e.messageCode) });
                }
                else {
                    ErrorReporter.sendReport({ subject: 'error', data: e, type: 'warn' });
                }
            }
        }
        Routing.instance.closeDialog(true)(null);
    }
}
ProjectChangeRolePU.defaultProps = {
    data: null,
};

import color from 'color';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { PanResponder, Platform, View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
export function GanttChartDataEntry(props) {
    const [moveStart, setMoveStart] = useState();
    const [lastMove, setLastMove] = useState();
    const [leftPosition, setLeftPosition] = useState(0);
    const [leftPositionMod, setLeftPositionMod] = useState(0);
    const [width, setWidth] = useState(0);
    const [widthMod, setWidthMod] = useState(0);
    const [textWidth, setTextWidth] = useState(0);
    const [startDate, setStartDate] = useState(props.data.start);
    const [endDate, setEndDate] = useState(props.data.end);
    const moveS = Platform.OS === 'web' ? { cursor: 'ew-resize' } : {};
    const calculateNewDate = (date, y) => {
        const { widthPerDay } = props;
        const moveDays = Math.round(y / widthPerDay);
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 3, 0, 0, 0);
        newDate.setTime(date.getTime() + moveDays * 24 * 60 * 60 * 1000);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    };
    const daysSinceStart = (start, until) => {
        const s = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 3, 0, 0, 0);
        const u = new Date(until.getFullYear(), until.getMonth(), until.getDate(), 3, 0, 0, 0);
        const dd = Math.round((u.getTime() - s.getTime()) / (24 * 60 * 60 * 1000));
        return dd;
    };
    const panResponderStart = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderMove: (_e, gestureState) => {
            if (moveStart != null) {
                const lm = { x: gestureState.moveX - moveStart.x, y: gestureState.moveY - moveStart.y };
                setLastMove(lm);
                setLeftPositionMod(lm.x);
                setWidthMod(-lm.x);
            }
            else {
                setMoveStart({ x: gestureState.moveX, y: gestureState.moveY });
            }
        },
        onPanResponderRelease: (_e, _gestureState) => {
            if (moveStart != null && lastMove != null) {
                const { data, firstDay, widthPerDay } = props;
                const newStart = calculateNewDate(startDate, leftPositionMod);
                setLeftPosition(daysSinceStart(firstDay, newStart) * widthPerDay);
                setWidth(daysSinceStart(newStart, endDate) * widthPerDay + widthPerDay);
                setWidthMod(0);
                setLeftPositionMod(0);
                setLastMove(undefined);
                setMoveStart(undefined);
                setStartDate(newStart);
                if (data.onChangeDate)
                    data.onChangeDate(data.id, newStart, endDate);
            }
        },
    });
    const panResponderEnd = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderMove: (_e, gestureState) => {
            if (moveStart != null) {
                const lm = { x: gestureState.moveX - moveStart.x, y: gestureState.moveY - moveStart.y };
                setLastMove(lm);
                setWidthMod(lm.x);
            }
            else {
                setMoveStart({ x: gestureState.moveX, y: gestureState.moveY });
            }
        },
        onPanResponderRelease: (_e, _gestureState) => {
            if (moveStart != null && lastMove != null) {
                const { data, widthPerDay } = props;
                let newLast = calculateNewDate(endDate, widthMod);
                if (newLast.getTime() < startDate.getTime())
                    newLast = startDate;
                setWidth(daysSinceStart(startDate, newLast) * widthPerDay + widthPerDay);
                setWidthMod(0);
                setLastMove(undefined);
                setMoveStart(undefined);
                setEndDate(newLast);
                if (data.onChangeDate)
                    data.onChangeDate(data.id, startDate, newLast);
            }
        },
    });
    useEffect(() => {
        const { firstDay, widthPerDay } = props;
        setLeftPosition(daysSinceStart(firstDay, startDate) * widthPerDay);
        setWidth(daysSinceStart(startDate, endDate) * widthPerDay + widthPerDay);
    }, []);
    const renderTooltip = () => {
        if (widthMod !== 0 || leftPositionMod !== 0) {
            const left = leftPositionMod !== 0 ? leftPosition + leftPositionMod : leftPosition + width + widthMod;
            const date = leftPositionMod !== 0 ? calculateNewDate(startDate, leftPositionMod) : calculateNewDate(endDate, widthMod);
            return (<View style={{ ...ThemeManager.style.tooltipOuter, top: -40, left: left - 50, width: 110 }}>
          <View style={{ ...ThemeManager.style.tooltipText, width: 94 }}>
            <MaterialText numberOfLines={1} fixedWidth={94} textAlign="center" color={ThemeManager.style.white87}>
              {I18n.m.dateCurrent.localeDateString(date)}
            </MaterialText>
          </View>
        </View>);
        }
        return <View />;
    };
    const getColor = () => {
        let bgColor = props.data.color;
        try {
            color(bgColor);
        }
        catch (err) {
            bgColor = ThemeManager.style.brandPrimary;
        }
        if (bgColor === 'transparent')
            bgColor = '#BBBBBB';
        return bgColor;
    };
    const renderEntry = () => {
        const { data } = props;
        if (endDate.getTime() < startDate.getTime() || (leftPosition === 0 && width === 0))
            return <View key={data.id}/>;
        let onPress;
        if (data.onPress != null)
            onPress = data.onPress;
        const bgColor = getColor();
        return (<View key={`entry_${data.id}`} style={{ height: 32, flexDirection: 'row', overflow: 'visible' }} pointerEvents="box-none">
        {renderTooltip()}
        <View style={{
                overflow: 'hidden',
                backgroundColor: bgColor,
                flexDirection: 'row',
                width: width + widthMod,
                marginVertical: 4,
                height: 24,
                borderBottomLeftRadius: 12,
                borderTopLeftRadius: 12,
                borderBottomRightRadius: 12,
                borderTopRightRadius: 12,
                paddingHorizontal: data.onChangeDate ? 0 : 8,
                position: 'relative',
                left: leftPosition + leftPositionMod,
            }} key={data.id}>
          {data.onChangeDate && Platform.OS !== 'ios' ? (<View style={{ height: '100%', backgroundColor: color(bgColor).darken(0.1).toString(), ...moveS }} {...panResponderStart.panHandlers}>
              <Icon icon="drag-vertical" toolTip="" outerSize={24} color="#ffffff"/>
            </View>) : (<View />)}
          <Ripple onPress={() => onPress(data.id)} style={{ flex: 1 }}/>
          {data.onChangeDate && Platform.OS !== 'ios' ? (<View style={{ height: '100%', backgroundColor: color(bgColor).darken(0.1).toString(), ...moveS }} {...panResponderEnd.panHandlers}>
              <Icon icon="drag-vertical" toolTip="" outerSize={24} color="#ffffff"/>
            </View>) : (<View />)}
        </View>
        <View onLayout={(e) => {
                setTextWidth(e.nativeEvent.layout.width);
            }} style={{
                position: 'absolute',
                paddingLeft: 4,
                top: 6,
                bottom: 6,
                left: textWidth < width + widthMod - 48
                    ? leftPosition + leftPositionMod + 24
                    : leftPosition + width + leftPositionMod + widthMod,
            }}>
          <MaterialText numberOfLines={1} color={textWidth < width + widthMod - 48 ? '#ffffff' : ThemeManager.style.black87} centeredBox>
            {data.name}
          </MaterialText>
          <Ripple onPress={() => onPress(data.id)} style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                width: textWidth,
            }}/>
        </View>
      </View>);
    };
    return renderEntry();
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogBook = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../server/webserver/commands/CommandReadModels");
var LogBookEntity_1 = require("./entities/LogBookEntity");
var LogBook = function (_ReadModelClientOffli) {
  function LogBook() {
    var _this;
    (0, _classCallCheck2.default)(this, LogBook);
    _this = _callSuper(this, LogBook, arguments);
    _this.exampleEntity = new LogBookEntity_1.LogBookEntity();
    _this.shouldSynced = false;
    _this.onEvents = [{
      eventName: 'LogBookEntryCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'LogBookRead',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'LogBookRevokeRead',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(LogBook, _ReadModelClientOffli);
  return (0, _createClass2.default)(LogBook, [{
    key: "events",
    get: function get() {
      return this.onEvents;
    }
  }, {
    key: "defaultEventHandler",
    value: function () {
      var _defaultEventHandler = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield _superPropGet(LogBook, "defaultEventHandler", this, 3)([e, ent]);
        entity.id = uuid.v1();
        entity.eventName = e.eventName;
        if (e.createdBy != null) {
          entity.createdBy = e.createdBy;
        }
        entity.data = e.data;
        if (e.data.projectId != null) {
          entity.projectId = e.data.projectId;
        }
        return entity;
      });
      function defaultEventHandler(_x, _x2) {
        return _defaultEventHandler.apply(this, arguments);
      }
      return defaultEventHandler;
    }()
  }, {
    key: "onPlanChanged",
    value: function () {
      var _onPlanChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.plan.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Plan';
        return entity;
      });
      function onPlanChanged(_x3, _x4) {
        return _onPlanChanged.apply(this, arguments);
      }
      return onPlanChanged;
    }()
  }, {
    key: "onPlanCreated",
    value: function () {
      var _onPlanCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = e.data.projectId;
        entity.entityType = 'Plan';
        entity.entityId = e.entityId;
        return entity;
      });
      function onPlanCreated(_x5, _x6) {
        return _onPlanCreated.apply(this, arguments);
      }
      return onPlanCreated;
    }()
  }, {
    key: "onPlanPlanVersionCreated",
    value: function () {
      var _onPlanPlanVersionCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.plan.getById(e.data.planId)).projectId;
        entity.entityType = 'Plan';
        entity.entityId = e.data.planId;
        return entity;
      });
      function onPlanPlanVersionCreated(_x7, _x8) {
        return _onPlanPlanVersionCreated.apply(this, arguments);
      }
      return onPlanPlanVersionCreated;
    }()
  }, {
    key: "onPlanVersionActivated",
    value: function () {
      var _onPlanVersionActivated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.plan.getById(e.entityId)).projectId;
        entity.entityType = 'Plan';
        entity.entityId = e.entityId;
        return entity;
      });
      function onPlanVersionActivated(_x9, _x10) {
        return _onPlanVersionActivated.apply(this, arguments);
      }
      return onPlanVersionActivated;
    }()
  }, {
    key: "onProjectChanged",
    value: function () {
      var _onProjectChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = e.entityId;
        entity.entityType = 'Project';
        return entity;
      });
      function onProjectChanged(_x11, _x12) {
        return _onProjectChanged.apply(this, arguments);
      }
      return onProjectChanged;
    }()
  }, {
    key: "onProjectCreated",
    value: function () {
      var _onProjectCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = e.entityId;
        entity.entityType = 'Project';
        return entity;
      });
      function onProjectCreated(_x13, _x14) {
        return _onProjectCreated.apply(this, arguments);
      }
      return onProjectCreated;
    }()
  }, {
    key: "onProjectUserAccepted",
    value: function () {
      var _onProjectUserAccepted = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        var projectUser = yield CommandReadModels_1.CommandReadModels.projectUser.getById(e.entityId);
        entity.projectId = projectUser.projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'ProjectUser';
        entity.data = Object.assign({}, projectUser);
        return entity;
      });
      function onProjectUserAccepted(_x15, _x16) {
        return _onProjectUserAccepted.apply(this, arguments);
      }
      return onProjectUserAccepted;
    }()
  }, {
    key: "onProjectUserAdded",
    value: function () {
      var _onProjectUserAdded = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = e.data.projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'ProjectUser';
        return entity;
      });
      function onProjectUserAdded(_x17, _x18) {
        return _onProjectUserAdded.apply(this, arguments);
      }
      return onProjectUserAdded;
    }()
  }, {
    key: "onProjectUserRemoved",
    value: function () {
      var _onProjectUserRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        var projectUser = yield CommandReadModels_1.CommandReadModels.projectUser.getById(e.entityId);
        entity.projectId = projectUser.projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'ProjectUser';
        entity.data = Object.assign({}, projectUser);
        return entity;
      });
      function onProjectUserRemoved(_x19, _x20) {
        return _onProjectUserRemoved.apply(this, arguments);
      }
      return onProjectUserRemoved;
    }()
  }, {
    key: "onTicketAssignedChanged",
    value: function () {
      var _onTicketAssignedChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketAssignedChanged(_x21, _x22) {
        return _onTicketAssignedChanged.apply(this, arguments);
      }
      return onTicketAssignedChanged;
    }()
  }, {
    key: "onTicketApproverChanged",
    value: function () {
      var _onTicketApproverChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketApproverChanged(_x23, _x24) {
        return _onTicketApproverChanged.apply(this, arguments);
      }
      return onTicketApproverChanged;
    }()
  }, {
    key: "onTicketCommentAdded",
    value: function () {
      var _onTicketCommentAdded = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.data.ticketId)).projectId;
        entity.entityId = e.data.ticketId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketCommentAdded(_x25, _x26) {
        return _onTicketCommentAdded.apply(this, arguments);
      }
      return onTicketCommentAdded;
    }()
  }, {
    key: "onTicketCommentChanged",
    value: function () {
      var _onTicketCommentChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        var ticketComments = yield CommandReadModels_1.CommandReadModels.ticketComments.getById(e.entityId);
        entity.projectId = ticketComments.projectId;
        entity.entityId = ticketComments.ticketId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketCommentChanged(_x27, _x28) {
        return _onTicketCommentChanged.apply(this, arguments);
      }
      return onTicketCommentChanged;
    }()
  }, {
    key: "onTicketCompletionOnChanged",
    value: function () {
      var _onTicketCompletionOnChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketCompletionOnChanged(_x29, _x30) {
        return _onTicketCompletionOnChanged.apply(this, arguments);
      }
      return onTicketCompletionOnChanged;
    }()
  }, {
    key: "onTicketCreated",
    value: function () {
      var _onTicketCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketCreated(_x31, _x32) {
        return _onTicketCreated.apply(this, arguments);
      }
      return onTicketCreated;
    }()
  }, {
    key: "onTicketDescriptionChanged",
    value: function () {
      var _onTicketDescriptionChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketDescriptionChanged(_x33, _x34) {
        return _onTicketDescriptionChanged.apply(this, arguments);
      }
      return onTicketDescriptionChanged;
    }()
  }, {
    key: "onTicketNumberChanged",
    value: function () {
      var _onTicketNumberChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketNumberChanged(_x35, _x36) {
        return _onTicketNumberChanged.apply(this, arguments);
      }
      return onTicketNumberChanged;
    }()
  }, {
    key: "onTicketStatusChanged",
    value: function () {
      var _onTicketStatusChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketStatusChanged(_x37, _x38) {
        return _onTicketStatusChanged.apply(this, arguments);
      }
      return onTicketStatusChanged;
    }()
  }, {
    key: "onTicketTagsChanged",
    value: function () {
      var _onTicketTagsChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketTagsChanged(_x39, _x40) {
        return _onTicketTagsChanged.apply(this, arguments);
      }
      return onTicketTagsChanged;
    }()
  }, {
    key: "onTicketTitleChangedd",
    value: function () {
      var _onTicketTitleChangedd = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketTitleChangedd(_x41, _x42) {
        return _onTicketTitleChangedd.apply(this, arguments);
      }
      return onTicketTitleChangedd;
    }()
  }, {
    key: "onTicketTypeChanged",
    value: function () {
      var _onTicketTypeChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketTypeChanged(_x43, _x44) {
        return _onTicketTypeChanged.apply(this, arguments);
      }
      return onTicketTypeChanged;
    }()
  }, {
    key: "onTicketWatcherAdded",
    value: function () {
      var _onTicketWatcherAdded = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketWatcherAdded(_x45, _x46) {
        return _onTicketWatcherAdded.apply(this, arguments);
      }
      return onTicketWatcherAdded;
    }()
  }, {
    key: "onTicketWatcherRemoved",
    value: function () {
      var _onTicketWatcherRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.projectId = (yield CommandReadModels_1.CommandReadModels.ticket.getById(e.entityId)).projectId;
        entity.entityId = e.entityId;
        entity.entityType = 'Ticket';
        return entity;
      });
      function onTicketWatcherRemoved(_x47, _x48) {
        return _onTicketWatcherRemoved.apply(this, arguments);
      }
      return onTicketWatcherRemoved;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.LogBook = LogBook;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TicketCommentsEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var TicketCommentsEntity = function (_ReadModelEntity_1$Re) {
  function TicketCommentsEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, TicketCommentsEntity);
    _this = _callSuper(this, TicketCommentsEntity, [obj]);
    _this.commentType = 'usercomment';
    _this.entityName = 'TicketComments';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(TicketCommentsEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(TicketCommentsEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new TicketCommentsEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('_access2', new OdataEntity_1.OdataIndex(true));
      baseMap.set('ticketId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('comment', new OdataEntity_1.OdataIndex(true));
      baseMap.set('commentType', new OdataEntity_1.OdataIndex(true));
      baseMap.set('creator', new OdataEntity_1.OdataIndex());
      baseMap.set('clientCreation', new OdataEntity_1.OdataIndex(true));
      baseMap.set('attachedFileId', new OdataEntity_1.OdataIndex());
      baseMap.set('attachedFileType', new OdataEntity_1.OdataIndex());
      baseMap.set('data', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.TicketCommentsEntity = TicketCommentsEntity;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabEventHandler = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _tsEvents = require("ts-events");
var TabEventHandler = exports.TabEventHandler = (0, _createClass2.default)(function TabEventHandler() {
  (0, _classCallCheck2.default)(this, TabEventHandler);
});
TabEventHandler.changeTab = new _tsEvents.AsyncEvent();
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
class MenuListItem extends PureComponent {
    constructor() {
        super(...arguments);
        this.onPressText = () => {
            this.props.closerDrawer();
            Routing.instance.goTo(this.props.link);
        };
    }
    render() {
        const { title, icon, iconMoon, activeMainRoute, activeOnRoute, disabled, accessibilityLabel } = this.props;
        const active = activeMainRoute === activeOnRoute;
        return (<Ripple disabled={disabled} style={{
                width: ThemeManager.style.getScreenRelativePixelSize(264),
                height: ThemeManager.style.getScreenRelativePixelSize(48),
                alignSelf: 'flex-start',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
            }} hoverColor={ThemeManager.style.lisItemHoverColor} toolTip="" onPress={this.onPressText} rippleColor="rgba(255,255,255,0.8)" backgroundColor={active ? 'rgba(255,255,255,0.24)' : 'rgba(255,255,255,0)'}>
        <View style={{
                flexDirection: 'row',
                width: ThemeManager.style.getScreenRelativePixelSize(264),
                height: ThemeManager.style.getScreenRelativePixelSize(48),
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: 0,
                margin: 0,
            }}>
          <View style={{
                width: ThemeManager.style.getScreenRelativePixelSize(4),
                height: ThemeManager.style.getScreenRelativePixelSize(48),
            }}/>
          <View style={{
                width: ThemeManager.style.getScreenRelativePixelSize(48),
                padding: 0,
                paddingLeft: ThemeManager.style.getScreenRelativePixelSize(12),
                margin: 0,
            }} accessibilityLabel={accessibilityLabel}>
            <Icon toolTip="" icon={icon} color={active ? ThemeManager.style.menuButtonColorActive : ThemeManager.style.menuButtonColor} iconSize={24} outerSize={24} iconIconMoon={iconMoon === true} radius={0}/>
          </View>
          <View style={{
                width: ThemeManager.style.getScreenRelativePixelSize(200),
                paddingLeft: ThemeManager.style.getScreenRelativePixelSize(16),
            }}>
            <MaterialText type={MaterialTextTypes.Subtitle2} color={active ? ThemeManager.style.menuButtonColorActive : ThemeManager.style.menuButtonColor}>
              {title}
            </MaterialText>
          </View>
        </View>
      </Ripple>);
    }
}
export class ProjectMenu extends PureComponent {
    static getDerivedStateFromProps(nextProps, prevState) {
        let updateNeeded = false;
        const newState = { ...prevState };
        if (nextProps.currentProjectId !== prevState.currentProjectId) {
            newState.currentProjectId = nextProps.currentProjectId;
            updateNeeded = true;
        }
        if (updateNeeded) {
            return newState;
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.changeTab = (index) => {
            this.setState({ selectedTab: index }, () => {
                const tabs = this.getTabs();
                if (tabs != null && tabs.length > index) {
                    const tab = tabs[index];
                    Routing.instance.goTo(`/projects/${this.state.currentProjectId}/${tab.route}`);
                }
            });
        };
        this.routeChanged = (data) => {
            const activeMainRoute = ProjectMenu.getProjectRouteFromRoute(data.path);
            if (activeMainRoute !== this.state.activeMainRoute) {
                this.setState({ activeMainRoute, selectedTab: this.getTabFromRoute(activeMainRoute) });
            }
        };
        this.lastMainRoute = 'project';
        this.changedRoute = () => {
            const { activeMainRoute, selectedTab } = this.state;
            if (this.lastMainRoute !== activeMainRoute) {
                this.lastMainRoute = activeMainRoute;
                const tabIndex = this.getTabFromRoute(activeMainRoute);
                if (selectedTab !== tabIndex)
                    this.setState({ selectedTab: tabIndex });
            }
        };
        this.state = {
            activeMainRoute: 'project',
            currentProjectId: '',
            selectedTab: 0,
        };
        let path = '';
        if (RouterControl.instance != null && RouterControl.instance.currentUrl != null) {
            path = RouterControl.instance.currentUrl.pathname;
        }
        const activeMainRoute = ProjectMenu.getProjectRouteFromRoute(path);
        this.state = {
            activeMainRoute,
            currentProjectId: this.props.currentProjectId,
            selectedTab: this.getTabFromRoute(activeMainRoute),
        };
        this.changedRoute();
    }
    static getProjectRouteFromRoute(route) {
        const activeRoute = route.substr(0, 1) === '/' ? route.substr(1).split('?')[0] : route.split('?')[0];
        const activeRoutes = activeRoute.split('/');
        if (activeRoutes.length >= 3) {
            return activeRoutes[2];
        }
        return '';
    }
    getTabFromRoute(activeRoute) {
        const tabs = this.getTabs();
        if (tabs == null)
            return 0;
        const index = tabs.findIndex((a) => a.route === activeRoute);
        if (index >= 0)
            return index;
        return 0;
    }
    componentDidUpdate() {
        this.changedRoute();
    }
    getTabs() {
        const { size } = this.props;
        const { canReadPlans } = this.state;
        if (canReadPlans == null)
            return null;
        const hideTitle = size.windowWidth < 500;
        const tabs = [
            {
                title: I18n.m.getMessage('dashboard'),
                icon: 'view-dashboard-outline',
                hideTitle,
                route: 'project',
            },
        ];
        if (canReadPlans) {
            tabs.push({
                title: I18n.m.getMessage('menuProjectPlans'),
                icon: 'floor-plan',
                hideTitle,
                route: 'plans',
            });
        }
        tabs.push({
            title: I18n.m.getMessage('menuTickets'),
            icon: 'map-marker-check-outline',
            hideTitle,
            route: 'tickets',
        });
        tabs.push({
            title: I18n.m.getMessage('menuProjectDailyReports'),
            icon: 'calendar',
            hideTitle,
            route: 'dailyreports',
        });
        tabs.push({
            title: I18n.m.getMessage('menuProjectFiles'),
            icon: 'folder-outline',
            hideTitle,
            route: 'files',
        });
        tabs.push({
            title: I18n.m.getMessage('menuProjectInvolved'),
            icon: 'account-multiple-outline',
            hideTitle,
            route: 'team',
        });
        tabs.push({
            title: I18n.m.getMessage('accountSettings'),
            icon: 'cog-outline',
            hideTitle,
            route: 'projectsettings',
        });
        return tabs;
    }
    renderTabBar() {
        const { size } = this.props;
        const { selectedTab } = this.state;
        const tabs = this.getTabs();
        if (tabs == null)
            return null;
        return (<View key="Tabbar" style={{
                position: 'absolute',
                bottom: -ResizeEvent.current.contentBottom,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 1)',
                height: ResizeEvent.current.contentBottom + 48,
                width: ResizeEvent.current.windowWidth,
                borderWidth: 0,
                borderTopWidth: 1,
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
            }}>
        <TabBar maxTabs={4} indicatorOnTop textColor={ThemeManager.style.defaultIconColor} unfocusedTextColor="rgba(0, 0, 0, 0.5)" contentHeight={0} width={ResizeEvent.current.windowWidth} onChangeSelected={this.changeTab} selected={selectedTab} upperCase={false} underlineColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" borderHeight={4} minItemWidth={size.windowWidth < 700 ? 48 : 100} textProps={{
                fontSize: 12,
            }} tabs={tabs}/>
      </View>);
    }
    componentDidMount() {
        Routing.instance.routeChanged.attach(this.routeChanged);
        this.timeOut = setTimeout(() => {
            if (RouterControl.instance.currentUrl != null) {
                this.routeChanged({
                    url: RouterControl.instance.currentUrl,
                    path: RouterControl.instance.currentUrl.pathname,
                });
            }
        }, 1000);
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (CurrentUser.entity == null || projectId == null) {
            return;
        }
        const userId = CurrentUser.entity.id;
        const canReadPlans = await RightsManager.hasReadRight(projectId, userId, 'plans');
        this.setState({ canReadPlans }, () => {
            this.lastMainRoute = 'project';
            this.changedRoute();
        });
    }
    componentWillUnmount() {
        Routing.instance.routeChanged.detach(this.routeChanged);
        if (this.timeOut != null) {
            clearTimeout(this.timeOut);
        }
    }
    render() {
        const { sView } = this.props;
        if (sView)
            return this.renderTabBar();
        const { canReadPlans } = this.state;
        const width = ThemeManager.style.getScreenRelativePixelSize(256);
        const currentP = CurrentProject.instance.getCurrentProject();
        return (<ScrollView style={{ width, height: '100%' }} contentContainerStyle={{
                position: 'relative',
                paddingTop: 0,
                alignItems: 'flex-start',
                padding: 0,
            }}>
        <View style={{
                width,
                alignItems: 'flex-start',
                padding: 0,
                margin: 0,
                paddingLeft: 0,
            }}>
          <View style={{
                width,
                paddingLeft: 0,
                height: ThemeManager.style.headerHeight,
                justifyContent: 'center',
                borderStyle: 'solid',
                borderWidth: 0,
                borderBottomWidth: 1,
                borderColor: ThemeManager.style.menuBorderColor,
            }}>
            <MenuListItem accessibilityLabel="home" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} icon="home-outline" iconMoon={false} activeOnRoute="home" title={I18n.m.getMessage('home')} toolTip={I18n.m.getMessage('home')} link={`/${StoredStartPages.getStoredStartPage()}`} disabled={false}/>
          </View>

          <View style={{
                width,
                position: 'relative',
            }}>
            
            
            
            
            
            
            
            
            

            <MenuListItem accessibilityLabel="dashboard" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} icon="view-dashboard-outline" iconMoon={false} activeOnRoute="project" title={currentP != null ? currentP.title : I18n.m.getMessage('menuProject')} toolTip={currentP != null ? currentP.title : I18n.m.getMessage('menuProject')} link={`/projects/${this.state.currentProjectId}/project`} disabled={false}/>

            {!canReadPlans ? null : (<MenuListItem accessibilityLabel="plans-site" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} icon="floor-plan" activeOnRoute="plans" title={I18n.m.getMessage('menuProjectPlans')} toolTip={I18n.m.getMessage('menuProjectPlans')} link={`/projects/${this.state.currentProjectId}/plans`} disabled={false}/>)}

            <MenuListItem accessibilityLabel="tickets-site" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} icon="map-marker-check-outline" activeOnRoute="tickets" title={I18n.m.getMessage('menuTickets')} toolTip={I18n.m.getMessage('menuTickets')} link={`/projects/${this.state.currentProjectId}/tickets`} disabled={false}/>
            <MenuListItem accessibilityLabel="dailyreports-site" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} icon="calendar" activeOnRoute="dailyreports" title={I18n.m.getMessage('menuProjectDailyReports')} toolTip={I18n.m.getMessage('menuProjectDailyReports')} link={`/projects/${this.state.currentProjectId}/dailyreports`} disabled={false}/>
            <MenuListItem accessibilityLabel="files-site" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} icon="folder-outline" activeOnRoute="files" title={I18n.m.getMessage('menuProjectFiles')} toolTip={I18n.m.getMessage('menuProjectFiles')} link={`/projects/${this.state.currentProjectId}/files`} disabled={false}/>

            <MenuListItem accessibilityLabel="team-site" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} activeOnRoute="team" title={I18n.m.getMessage('menuProjectInvolved')} toolTip={I18n.m.getMessage('menuProjectInvolved')} link={`/projects/${this.state.currentProjectId}/team`} icon="account-multiple-outline" disabled={false}/>

            <MenuListItem accessibilityLabel="project-settings" closerDrawer={this.props.closerDrawer} activeMainRoute={this.state.activeMainRoute} activeOnRoute="projectsettings" title={I18n.m.getMessage('projectSettings')} toolTip={I18n.m.getMessage('projectSettings')} link={`/projects/${this.state.currentProjectId}/projectsettings`} icon="cog-outline" disabled={false}/>
          </View>
        </View>
      </ScrollView>);
    }
}
ProjectMenu.defaultProps = {
    onChange: null,
    activeRoute: '/',
    currentProject: '',
    drawer: false,
    headerHeight: 0,
};

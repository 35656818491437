import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { ChangePlanDetails } from 'upmesh-core/src/client/commands/plans/ChangePlanDetails';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
export class PlanEditPU extends PureComponent {
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('projectEditTitle');
        this.submit = () => {
            this.setState({
                isLoading: true,
            }, () => {
                this.submitNow().catch((err) => console.debug(err));
            });
        };
        this.onPlansChanged = () => {
            this.setState({
                plansList: CurrentProject.instance.getCurrentPlans(),
            });
        };
        this.changedTitle = (text) => {
            const { plansList, plan } = this.state;
            if (plan != null) {
                const planTitleExists = plansList.find((inPlans) => inPlans.title === text && inPlans.id !== plan.id) != null;
                const saveButtonDisabled = planTitleExists || text.length < 1;
                this.setState({ planTitleExists, saveButtonDisabled });
            }
        };
        this.onChangedForm = () => {
            this.setState({ saveButtonDisabled: false });
        };
        this.onChangedTags = (chips) => {
            this.setState({ saveButtonDisabled: false, currentTags: [...chips] });
        };
        this.state = {
            chipGroup: undefined,
            planTags: [],
            currentTags: [],
            plan: undefined,
            projectTagGroups: [],
            projectTags: [],
            isLoading: false,
            _planNameInput: undefined,
            planNameTextFieldActive: false,
            planNameError: '',
            _planTagInput: undefined,
            planTagActive: false,
            planTagError: '',
            _planNotesInput: null,
            saveButtonDisabled: true,
            planTitleExists: false,
            plansList: CurrentProject.instance.getCurrentPlans(),
        };
    }
    componentDidMount() {
        this.checkData().catch((err) => console.debug(err));
        CurrentProject.plansChanged.attach(this.onPlansChanged);
    }
    componentWillUnmount() {
        CurrentProject.plansChanged.detach(this.onPlansChanged);
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    render() {
        if (this.state.plan == null) {
            return <Spinner />;
        }
        let titleErrorText;
        if (this.state.planTitleExists) {
            titleErrorText = I18n.m.getMessage('planEditTitleExists');
        }
        else if (this.state.planNameError.length > 0) {
            titleErrorText = this.state.planNameError;
        }
        return [
            <DialogContent key="content">
        <DialogTitle inContent>{I18n.m.getMessage('plansFormTitle')}</DialogTitle>
        <FormInputFilled ref={(_planNameInput) => this.setState({ _planNameInput })} labelText={`${I18n.m.getMessage('planTitle')}*`} autoFocus={false} initValue={this.state.plan.title} name="projectName" autoCompleteWeb="projectName" disabled={this.state.isLoading} nextField={this.state._planTagInput} error={titleErrorText != null} helperText={titleErrorText} maxLength={100} onChange={this.changedTitle}/>
        <ChipGroup label="Tags" textColor="#FFFFFF" bordered={false} key={`test${this.state.projectTags.length}`} ref={(chipGroup) => this.setState({ chipGroup })} availableChips={this.state.projectTags} chips={this.state.currentTags} chipGroups={this.state.projectTagGroups} sortFormByGroup onChanged={this.onChangedTags} tooltipAddChip={I18n.m.getMessage('addTags')}/>
        <View style={{ height: 16 }}/>
        <FormInputFilled ref={(_planNotesInput) => this.setState({ _planNotesInput })} labelText={I18n.m.getMessage('plansNotes')} autoFocus={false} initValue={this.state.plan.notes} name="projectDescription" autoCompleteWeb="projectDescription" disabled={this.state.isLoading} numberOfLines={3} onChange={this.onChangedForm}/>
      </DialogContent>,
            <DialogActions key="actions" onBottom>
        <ContainedButton onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('abort')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton onPress={this.submit} title={I18n.m.getMessage('save')} textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} disabled={this.state.saveButtonDisabled}/>
      </DialogActions>,
        ];
    }
    async checkData() {
        await DateLocale.waitFor(() => UpmeshClient.instance.ready);
        let plan;
        if (this.props.data != null && this.props.data.planId != null && this.props.data.planId.length > 0) {
            plan = await UpmeshClient.instance.modals.plan.getById(this.props.data.planId);
            const close = () => {
                Routing.instance.closeDialog(true)(null);
            };
            const catched = await DefaultErrorHandler.getProjectErrors(plan.projectId, true, close);
            if (catched) {
                return;
            }
            const projectTags = [];
            const projectTagGroups = [];
            const project = await UpmeshClient.instance.modals.project.getById(plan.projectId);
            project.tags?.forEach((t) => {
                projectTags.push({
                    id: t.tagName,
                    groupId: t.groupName,
                    title: t.tagName,
                    backgroundColor: t.color,
                });
            });
            for (const key in project.tagGroups) {
                projectTagGroups.push({
                    backgroundColor: project.tagGroups[key].color,
                    groupName: project.tagGroups[key].groupName,
                    id: project.tagGroups[key].groupName,
                });
            }
            const planTags = [];
            if (plan != null && plan.tags != null && plan.tags.length > 0) {
                plan.tags.forEach((t) => {
                    planTags.push({
                        id: t.tagName,
                        title: t.tagName,
                        backgroundColor: t.color,
                        groupId: t.groupName,
                    });
                });
            }
            this.setState({ planTags, projectTagGroups, currentTags: [...planTags], projectTags, plan });
        }
        else {
            Routing.instance.closeDialog(true)(null);
        }
    }
    async submitNow() {
        const { plan } = this.state;
        if (plan == null || this.state._planNameInput == null || this.state._planNotesInput == null) {
            return;
        }
        plan.title = this.state._planNameInput.getValue();
        plan.notes = this.state._planNotesInput.getValue();
        if (plan.title == null || plan.title.length === 0) {
            this.setState({
                isLoading: false,
                planNameError: I18n.m.getMessage('plansErrorTitleRequired'),
            });
            return;
        }
        const tags = [];
        if (this.state.chipGroup != null) {
            const getChips = this.state.chipGroup.getCurrentChips();
            getChips.forEach((c) => {
                tags.push({ tagName: c.id, color: c.backgroundColor, groupName: c.groupId });
            });
        }
        const c = new ChangePlanDetails({
            tags,
            title: plan.title,
            notes: plan.notes,
        }, plan.id);
        try {
            await c.execute();
        }
        catch (e) {
            const planNameError = I18n.m.getMessage(e.messageCode);
            this.setState({
                planNameError,
                isLoading: false,
            });
            return;
        }
        this.setState({ isLoading: false }, () => {
            Routing.instance.closeDialog(true)(null);
        });
    }
}
PlanEditPU.defaultProps = {
    data: null,
};

import { Card } from 'materialTheme/src/theme/components/Card';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { ProjectImage } from '../../project/ProjectImage';
export class ProjectCard extends PureComponent {
    constructor() {
        super(...arguments);
        this.openProject = (e) => {
            const { onOpen, project } = this.props;
            DialogBetween.instance?.close(() => {
                Routing.instance.goToButton(`/projects/${project.id}/project`)(e);
                if (onOpen != null)
                    onOpen(project, e);
            });
        };
    }
    render() {
        const { project } = this.props;
        return (<View style={{ width: '100%', height: 'auto', padding: 6, overflow: 'visible' }}>
        <Card outerPadding={0} style={{ width: '100%', height: 'auto', overflow: 'visible', margin: 0 }}>
          <View pointerEvents="box-none" style={{ width: '100%', height: 'auto', overflow: 'hidden', borderRadius: 6, paddingRight: 4, margin: 0 }}>
            <Ripple onPress={this.openProject} style={{
                width: '100%',
                padding: ThemeManager.style.getScreenRelativePixelSize(4),
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                minHeight: 44,
            }}>
              <ProjectImage project={project} size={24}/>
              <View style={{ paddingLeft: 4, height: 'auto', maxWidth: '100%', alignSelf: 'center', flexGrow: 1 }}>
                <MaterialText type={MaterialTextTypes.Body1} ellipsizeMode="tail">
                  {project.title}
                </MaterialText>
              </View>
            </Ripple>
          </View>
        </Card>
      </View>);
    }
}

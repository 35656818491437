var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WeatherIconsTypes = exports.WeatherIcons = void 0;
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _react = _interopRequireWildcard(require("../../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../../upmesh-client/node_modules/react-native-web");
var _jsxRuntime = require("../../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var WeatherIconsTypes = exports.WeatherIconsTypes = (0, _createClass2.default)(function WeatherIconsTypes() {
  (0, _classCallCheck2.default)(this, WeatherIconsTypes);
  this.clearday = require("../../../../assets/weather/sunny.png");
  this.clearnight = require("../../../../assets/weather/night.png");
  this.cloudy = require("../../../../assets/weather/cloudy.png");
  this.cloudyWindy = require("../../../../assets/weather/cloudy-windy.png");
  this.fog = require("../../../../assets/weather/fog.png");
  this.hail = require("../../../../assets/weather/hail.png");
  this.lightning = require("../../../../assets/weather/lightning.png");
  this.partlycloudyday = require("../../../../assets/weather/partlycloudy.png");
  this.partlycloudynight = require("../../../../assets/weather/night-cloudy.png");
  this.pouring = require("../../../../assets/weather/pouring.png");
  this.rain = require("../../../../assets/weather/rainy.png");
  this.sleet = require("../../../../assets/weather/snowy-rainy.png");
  this.snow = require("../../../../assets/weather/snowy.png");
  this.thunderstorm = require("../../../../assets/weather/lightning-rainy.png");
  this.wind = require("../../../../assets/weather/windy.png");
});
var WeatherIcons = exports.WeatherIcons = function (_PureComponent) {
  function WeatherIcons() {
    (0, _classCallCheck2.default)(this, WeatherIcons);
    return _callSuper(this, WeatherIcons, arguments);
  }
  (0, _inherits2.default)(WeatherIcons, _PureComponent);
  return (0, _createClass2.default)(WeatherIcons, [{
    key: "removeHyphens",
    value: function removeHyphens(str) {
      return str.replace(/-/g, '');
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        width = _this$props.width,
        height = _this$props.height,
        type = _this$props.type;
      if (type == null) return null;
      var t = this.removeHyphens(type);
      if (WeatherIcons.icons[t] == null) return null;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
        style: {
          width: width,
          height: height
        },
        source: WeatherIcons.icons[t]
      });
    }
  }], [{
    key: "icons",
    get: function get() {
      return new WeatherIconsTypes();
    }
  }]);
}(_react.PureComponent);
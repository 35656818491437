import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
export function TicketHeaderTitle(props) {
    const [jsxTickets, setJsxTickets] = useState([]);
    const loadTickets = async (t) => {
        const j = [];
        if (t.subTicketFrom) {
            try {
                const ticket = await UpmeshClient.instance.modals.ticket.getById(t.subTicketFrom);
                const headerTitleText = ticket != null && ticket.ticketNumber != null && ticket.ticketNumber > 0 ? `#${ticket.ticketNumber}` : '# ?';
                j.push(<MaterialText key={`headerTitleText_${headerTitleText}_${ticket.id}`} onPress={Routing.instance.openDialog('ticket', { id: ticket.id })} type={MaterialTextTypes.Body2} centeredBox={false} centeredText={false} color={ThemeManager.style.headerTitleColor} additionalTextStyle={{ marginRight: 4 }} numberOfLines={1}>
            {headerTitleText} /
          </MaterialText>);
                if (ticket.subTicketFrom) {
                    const lt = await loadTickets(ticket);
                    j.unshift(...lt);
                }
            }
            catch (e) {
                console.debug('Ticket not found', e);
            }
        }
        return j;
    };
    useEffect(() => {
        const { ticket } = props;
        const headerTitleText = ticket != null && ticket.ticketNumber != null && ticket.ticketNumber > 0 ? `#${ticket.ticketNumber}` : '# ?';
        const j = [
            <MaterialText key={`headerTitleText_${headerTitleText}_${ticket.id}`} type={MaterialTextTypes.Body2} centeredBox={false} centeredText={false} color={ThemeManager.style.headerTitleColor} numberOfLines={1} additionalTextStyle={{ marginRight: 4 }}>
        {headerTitleText}
      </MaterialText>,
        ];
        loadTickets(props.ticket)
            .then((e) => {
            setJsxTickets([...e, ...j]);
        })
            .catch((err) => console.warn(err));
    }, [props.ticket.subTicketFrom]);
    return (<View style={{ width: '100%' }}>
      <ScrollView horizontal style={{ width: '100%', flexDirection: 'row' }}>
        <View style={{ flexDirection: 'row' }}>{jsxTickets.map((j) => j)}</View>
      </ScrollView>
      {(props.ticket.archived === true ||
            (props.ticket.foreignId != null && props.ticket.foreignId.length > 0) ||
            props.ticket.deleted === true) && (<View style={{ flexDirection: 'row' }}>
          <MaterialText type={MaterialTextTypes.Body2}>
            {props.ticket.archived === true && I18n.m.getMessage('archived')}{' '}
            {props.ticket.foreignId != null && props.ticket.foreignId.length > 0 && ` (${props.ticket.foreignId})`}
            {props.ticket.deleted === true && I18n.m.getMessage('deleted')}{' '}
          </MaterialText>
        </View>)}
    </View>);
}

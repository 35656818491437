var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialTextAsync = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _MaterialText = require("./MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var _require = require('abortcontroller-polyfill/dist/cjs-ponyfill'),
  AbortController = _require.AbortController;
var MaterialTextAsync = exports.MaterialTextAsync = function (_PureComponent) {
  function MaterialTextAsync(props) {
    var _this;
    (0, _classCallCheck2.default)(this, MaterialTextAsync);
    _this = _callSuper(this, MaterialTextAsync, [props]);
    _this.controller = new AbortController();
    _this.state = {
      content: ''
    };
    return _this;
  }
  (0, _inherits2.default)(MaterialTextAsync, _PureComponent);
  return (0, _createClass2.default)(MaterialTextAsync, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      this.init().then(function (content) {
        if (typeof content === 'string') {
          _this2.setState({
            content: content
          });
        } else {
          _this2.setState({
            content: content.text,
            onPress: content.onPress,
            color: content.color
          });
        }
      }).catch(function (e) {
        if (e.message !== 'AbortError') {}
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.controller.abort();
    }
  }, {
    key: "init",
    value: function init() {
      var _this3 = this;
      var signal = this.controller.signal;
      return new Promise(function (resolve, reject) {
        signal.addEventListener('abort', function () {
          reject(new Error('AbortError'));
        });
        if (signal.aborted) {
          reject(new Error('AbortError'));
        }
        _this3.props.asyncText().then(resolve).catch(reject);
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
        content = _this$state.content,
        color = _this$state.color,
        onPress = _this$state.onPress;
      return (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({}, this.props, {
        color: color,
        onPress: onPress,
        children: content
      }));
    }
  }]);
}(_react.PureComponent);
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { DeleteProject } from 'upmesh-core/src/client/commands/project/DeleteProject';
import { I18n } from '../../../i18n/I18n';
export class DeleteProjectDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close(() => {
                requestAnimationFrame(() => {
                    Routing.instance.goTo('/projects');
                });
            });
        };
    }
    render() {
        const { projectId, downloadAction } = this.props;
        const currentData = {
            confirmed: false,
        };
        const dataOptions = new Map();
        dataOptions.set('confirmed', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectDeleteProjectDialogConfirmaionText'),
                key: 'checkboxConfirm',
            },
        });
        return (<Form key="deleteProjectForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('projectDeleteProject'),
                formText: I18n.m.getMessage('projectDeleteProjectDialogText'),
            }} stickyActions={false} command={new DeleteProject(currentData, projectId)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('projectDeleteProjectDialogButton')} saveButtonTextColor="#FFFFFF" saveButtonColor={ThemeManager.style.brandDanger} additionalButtons={[
                <ContainedButton key="abort" title={I18n.m.getMessage('projectDeleteProjectDialogDownloadButton')} onPress={downloadAction} backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>,
            ]}/>);
    }
}

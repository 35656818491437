var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Connection = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var Connection = exports.Connection = (0, _createClass2.default)(function Connection() {
  (0, _classCallCheck2.default)(this, Connection);
});
Connection.connectedToServer = false;
Connection.offline = true;
Connection.type = 'unknown';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateTask } from 'upmesh-core/src/client/commands/companies/tasks/CreateTask';
import { CompanySettingsEntity } from 'upmesh-core/src/client/query/entities/CompanySettingsEntity';
import { I18n } from '../../i18n/I18n';
export class AddTaskToCompanyDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
        this.state = {
            init: false,
            taskTypeList: [],
            taskTypes: [],
        };
    }
    componentDidMount() {
        CompanySettingsEntity.getTaskTypes()
            .then((taskTypes) => {
            const taskTypeList = [];
            taskTypes.forEach((s) => {
                taskTypeList.push({
                    title: s.title,
                    data: s.id,
                });
            });
            this.setState({ init: true, taskTypeList, taskTypes });
        })
            .catch((err) => console.debug('cant get task types', err));
    }
    render() {
        const { company } = this.props;
        const { taskTypeList, init } = this.state;
        if (!init)
            return <Spinner />;
        const currentData = {
            mark: '',
            followUpWageType1: '',
            followUpWageType2: '',
            wageType: '',
            companyId: company.id,
            description: '',
            taskTypeId: taskTypeList[0].data,
            active: true,
        };
        const dataOptions = new Map();
        dataOptions.set('mark', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskMark') },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskDescription') },
        });
        dataOptions.set('taskTypeId', {
            formType: 'Picker',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskType'), list: taskTypeList },
            mapValue: (value, fromFormInput) => {
                if (fromFormInput)
                    return value.value.data;
                let index = 0;
                for (let i = 0; i < taskTypeList.length; i += 1) {
                    if (taskTypeList[i].data === value) {
                        index = i;
                        break;
                    }
                }
                const value2 = taskTypeList[index];
                return { index, value: value2 };
            },
        });
        dataOptions.set('wageType', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskWageType') },
        });
        dataOptions.set('followUpWageType1', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskFollowUpWageType1') },
        });
        dataOptions.set('followUpWageType2', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskFollowUpWageType2') },
        });
        dataOptions.set('active', {
            formType: 'switch',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskActive') },
        });
        return (<Form key="changeCompanyUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyAddTaskDialogTitle'),
            }} stickyActions stickyHeader command={new CreateTask(currentData)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('add')} additionalButtons={[
                <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeSection = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var TimeSection = exports.TimeSection = function (_PureComponent) {
  function TimeSection(props) {
    var _this;
    (0, _classCallCheck2.default)(this, TimeSection);
    _this = _callSuper(this, TimeSection, [props]);
    _this.buttonSize = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32);
    _this.clockSize = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(300);
    _this.panResponder = _reactNativeWeb.PanResponder.create({
      onStartShouldSetPanResponder: function onStartShouldSetPanResponder() {
        return true;
      },
      onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder() {
        return true;
      },
      onPanResponderStart: function onPanResponderStart(_, gestureState) {
        return _this.handleGesture(gestureState);
      },
      onPanResponderMove: function onPanResponderMove(_, gestureState) {
        return _this.handleGesture(gestureState);
      },
      onPanResponderEnd: function onPanResponderEnd(_, gestureState) {
        return _this.handleGesture(gestureState, true);
      }
    });
    _this.handleGesture = function (gestureState) {
      var ended = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      try {
        var _this$state = _this.state,
          globalCenter = _this$state.globalCenter,
          select = _this$state.select,
          selectedHour = _this$state.selectedHour,
          selectedMinute = _this$state.selectedMinute;
        var offsetX = -(globalCenter.x - gestureState.x0 - gestureState.dx);
        var offsetY = globalCenter.y - gestureState.y0 - gestureState.dy;
        var distance = Math.sqrt(Math.abs(offsetX ** 2 + offsetY ** 2));
        var angle;
        if (offsetY === 0 && offsetX > 0) {
          angle = 90;
        } else if (offsetY === 0) {
          angle = 270;
        } else {
          var toAdd;
          var subtract;
          if (offsetX > 0 && offsetY > 0) toAdd = 0;else if (offsetX > 0 && offsetY < 0) subtract = 180;else if (offsetX < 0 && offsetY < 0) toAdd = 180;else if (offsetX < 0 && offsetY > 0) subtract = 360;
          if (toAdd != null) angle = Math.atan(Math.abs(offsetX) / Math.abs(offsetY)) * 180 / Math.PI + toAdd;else angle = subtract - Math.atan(Math.abs(offsetX) / Math.abs(offsetY)) * 180 / Math.PI;
        }
        if (Number.isNaN(angle)) return;
        if (select === 'hour') {
          var hour = angle + 15;
          if (hour >= 360) hour -= 360;
          hour = Math.floor(hour / 30);
          if (distance < _this.clockSize / 3.5) hour += 12;
          if (typeof hour === 'number' && (selectedHour == null || selectedHour.data == null || hour !== selectedHour.data || ended)) _this.onChangeHour(hour, ended)();
        } else {
          var minute = angle + 3;
          if (minute >= 360) minute -= 360;
          minute = Math.floor(minute / 6);
          if (typeof minute === 'number' && minute !== selectedMinute) {
            _this.onChangeMinute(minute)();
          }
        }
      } catch (err) {}
    };
    _this.onChangeSelect = function (select) {
      return function (_e) {
        _this.setState({
          select: select
        });
      };
    };
    _this.renderMinutePointer = function () {
      var selectedMinute = _this.state.selectedMinute;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          width: _this.clockSize,
          height: _this.clockSize,
          justifyContent: 'center',
          alignItems: 'center',
          transform: [{
            rotate: `${selectedMinute * 6}deg`
          }]
        },
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: 2,
            height: _this.clockSize - 32,
            overflow: 'visible'
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: _this.buttonSize,
              height: _this.buttonSize,
              borderRadius: _this.buttonSize / 2,
              backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
              position: 'absolute',
              alignSelf: 'center'
            }
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: 2,
              height: '50%',
              backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary
            }
          })]
        })
      });
    };
    _this.setRef = function (ref) {
      if (ref != null) {
        _this.centerRef = ref;
        setTimeout(_this.calculateCenter, 300);
      }
    };
    _this.calculateCenter = function () {
      if (_this.centerRef != null) {
        _this.centerRef.measure(function (_x, _y, width, height, pageX, pageY) {
          return _this.setState({
            globalCenter: {
              x: pageX + width / 2,
              y: pageY + height / 2
            }
          });
        });
      }
    };
    _this.setClockSize = function () {
      var maxWidth = Math.min(372, _ResizeEvent.ResizeEvent.current.windowWidth);
      var maxHeight = Math.min(372, _ResizeEvent.ResizeEvent.current.windowHeight);
      maxHeight -= _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(80);
      maxHeight -= _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36);
      maxWidth -= 2 * 16;
      maxHeight -= 2 * 16;
      _this.clockSize = Math.min(maxHeight, maxWidth);
    };
    _this.onChangeHour = function (h) {
      var selectMinutes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      return function (_e) {
        try {
          var _this$state2 = _this.state,
            hours = _this$state2.hours,
            selectedFormat = _this$state2.selectedFormat;
          var onChangeHour = _this.props.onChangeHour;
          var timeFormat = _this.props.timeFormat != null ? _this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
          _this.setState(function (prevState) {
            return {
              selectedHour: hours[h],
              select: selectMinutes ? 'minute' : prevState.select
            };
          });
          var hour = hours[h];
          if (timeFormat === '12') {
            if (selectedFormat === 'am') {
              if (hour.data > 11) hour.data -= 12;
            } else if (selectedFormat === 'pm') {
              if (hour.data < 12) hour.data += 12;
            }
          }
          if (hour != null) onChangeHour(hour);
        } catch (err) {}
      };
    };
    _this.onChangeMinute = function (min) {
      return function (_e) {
        var onChangeMinutes = _this.props.onChangeMinutes;
        _this.setState({
          selectedMinute: min
        });
        onChangeMinutes(min);
      };
    };
    _this.setClockSize();
    if (props.selectTime === true && props.selectedDate != null) {
      _this.state = {
        lastDate: props.selectedDate,
        selectedHour: TimeSection.createSelectedHour(props.selectedDate),
        selectedMinute: props.selectedDate.getMinutes(),
        hours: TimeSection.createHoursList(),
        minutes: TimeSection.createMinutesList(props),
        select: 'hour',
        selectedFormat: props.selectedDate.getHours() > 11 ? 'pm' : 'am',
        globalCenter: {
          x: 0,
          y: 0
        }
      };
    } else {
      _this.state = {
        lastDate: props.selectedDate,
        hours: TimeSection.createHoursList(),
        minutes: TimeSection.createMinutesList(props),
        selectedHour: {
          title: '00',
          data: 0,
          index: 0
        },
        selectedMinute: 0,
        select: 'hour',
        selectedFormat: props.selectedDate.getHours() > 11 ? 'pm' : 'am',
        globalCenter: {
          x: 0,
          y: 0
        }
      };
    }
    _this.dotRef = (0, _react.createRef)();
    return _this;
  }
  (0, _inherits2.default)(TimeSection, _PureComponent);
  return (0, _createClass2.default)(TimeSection, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          padding: 16,
          paddingBottom: 0,
          flexDirection: 'column'
        },
        children: [this.renderTimeText(), this.renderClock()]
      });
    }
  }, {
    key: "renderClock",
    value: function renderClock() {
      var select = this.state.select;
      var webStyle = _reactNativeWeb.Platform.OS === 'web' ? {
        cursor: 'pointer'
      } : {};
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, Object.assign({
        style: Object.assign({
          height: this.clockSize,
          width: this.clockSize,
          borderRadius: this.clockSize / 2,
          backgroundColor: _ThemeManager.ThemeManager.style.datePickerClockDefaultBg,
          overflow: 'visible'
        }, webStyle, _ThemeManager.ThemeManager.noSelectionWebStyle())
      }, this.panResponder.panHandlers, {
        children: [select === 'minute' ? this.renderMinutePointer() : null, this.renderHours(), this.renderMinutes(), this.renderCenterDot()]
      }));
    }
  }, {
    key: "renderClockButtons",
    value: function renderClockButtons(data, rotation, selected) {
      var select = this.state.select;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          height: this.buttonSize,
          width: this.clockSize - 32,
          borderRadius: this.buttonSize / 2,
          overflow: 'visible',
          transform: [{
            rotate: `${rotation}deg`
          }],
          position: 'absolute',
          top: this.clockSize / 2 - 16,
          left: 16,
          flexDirection: 'row',
          justifyContent: 'space-between'
        },
        pointerEvents: "box-none",
        children: [selected && select === 'hour' ? this.renderPointer((this.clockSize - 32) / 2) : null, this.renderSingleClockButton(data, rotation, selected)]
      }, `ClockButton${data}rotation${rotation}`);
    }
  }, {
    key: "renderHours",
    value: function renderHours() {
      var selectedDate = this.props.selectedDate;
      var select = this.state.select;
      var timeFormat = this.props.timeFormat != null ? this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
      if (select === 'minute') {
        return null;
      }
      var selectedHour = selectedDate.getHours();
      if (timeFormat === '12') {
        if (selectedHour > 12) selectedHour -= 12;else if (selectedHour === 0) selectedHour = 12;
      }
      var hours = [];
      for (var i = 0; i < 12; i += 1) {
        var data = i === 0 && timeFormat === '12' ? 12 : i;
        hours.push(this.renderClockButtons(data, i * 30 + 90, selectedHour === data));
      }
      if (timeFormat === '24') {
        for (var _i = 12; _i < 24; _i += 1) {
          hours.push(this.renderSecondRowClockButtons(_i, _i * 30 + 90, selectedHour === _i));
        }
      }
      return hours;
    }
  }, {
    key: "renderMinutes",
    value: function renderMinutes() {
      var _this$props = this.props,
        selectTimeMinutesIntervall = _this$props.selectTimeMinutesIntervall,
        selectedDate = _this$props.selectedDate;
      var select = this.state.select;
      if (select !== 'minute') {
        return null;
      }
      var selectedMinute = selectedDate.getMinutes();
      var rotation = 360 / (60 / selectTimeMinutesIntervall);
      var minutes = [];
      var minute = 0;
      do {
        minutes.push(this.renderClockButtons(minute, rotation * (minute / selectTimeMinutesIntervall) + 90, selectedMinute === minute));
        minute += selectTimeMinutesIntervall;
      } while (minute < 60);
      return minutes;
    }
  }, {
    key: "renderPointer",
    value: function renderPointer(lenght) {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: this.buttonSize / 2 - 1,
          width: lenght,
          height: 2,
          backgroundColor: this.props.color,
          alignItems: 'flex-end'
        }
      });
    }
  }, {
    key: "renderCenterDot",
    value: function renderCenterDot() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        ref: this.setRef,
        style: {
          position: 'absolute',
          top: this.clockSize / 2 - 3,
          left: this.clockSize / 2 - 3,
          width: 6,
          height: 6,
          borderRadius: 3,
          backgroundColor: this.props.color
        }
      });
    }
  }, {
    key: "renderSecondRowClockButtons",
    value: function renderSecondRowClockButtons(data, rotation, selected) {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          height: this.buttonSize,
          width: this.clockSize - 64 - this.buttonSize,
          borderRadius: this.buttonSize / 2,
          overflow: 'visible',
          transform: [{
            rotate: `${rotation}deg`
          }],
          position: 'absolute',
          top: this.clockSize / 2 - this.buttonSize / 2,
          left: 32 + this.buttonSize / 2,
          flexDirection: 'row',
          justifyContent: 'space-between'
        },
        pointerEvents: "box-none",
        children: [selected ? this.renderPointer((this.clockSize - 64 - this.buttonSize) / 2) : null, this.renderSingleClockButton(data, rotation, selected)]
      }, `ClockButton${data}rotation${rotation}`);
    }
  }, {
    key: "renderSingleClockButton",
    value: function renderSingleClockButton() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var rotation = arguments.length > 1 ? arguments[1] : undefined;
      var selected = arguments.length > 2 ? arguments[2] : undefined;
      var _this$props2 = this.props,
        color = _this$props2.color,
        textColor = _this$props2.textColor;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: this.buttonSize,
          width: this.buttonSize,
          borderRadius: this.buttonSize / 2,
          overflow: 'visible'
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            transform: [{
              rotate: `${-rotation}deg`
            }],
            alignSelf: 'flex-start',
            height: this.buttonSize,
            width: this.buttonSize,
            borderRadius: this.buttonSize / 2,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: selected === true ? color : 'transparent'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            centeredBox: true,
            color: selected === true ? textColor : _ThemeManager.ThemeManager.style.black87,
            children: data.toString()
          })
        })
      });
    }
  }, {
    key: "renderTimeText",
    value: function renderTimeText() {
      var _this2 = this;
      var selectedDate = this.props.selectedDate;
      var _this$state3 = this.state,
        select = _this$state3.select,
        selectedFormat = _this$state3.selectedFormat;
      var timeFormat = this.props.timeFormat != null ? this.props.timeFormat : _CurrentUser.CurrentUser.settings.timeFormat;
      var hour = selectedDate.getHours();
      if (timeFormat === '12') {
        if (hour > 12) hour -= 12;else if (hour === 0) hour = 12;
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row',
          alignItems: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          onPress: this.onChangeSelect('hour'),
          style: {
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(45),
            width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(45),
            borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(45) / 2,
            alignContent: 'center',
            alignItems: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.H4,
            color: select === 'hour' ? _ThemeManager.ThemeManager.style.black87 : _ThemeManager.ThemeManager.style.black54,
            centeredBox: true,
            children: hour
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.H4,
            children: ":"
          })
        }), (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          onPress: this.onChangeSelect('minute'),
          style: {
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(45),
            width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(45),
            borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(45) / 2,
            alignContent: 'center',
            alignItems: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.H4,
            color: select === 'minute' ? _ThemeManager.ThemeManager.style.black87 : _ThemeManager.ThemeManager.style.black54,
            centeredBox: true,
            children: selectedDate.getMinutes() > 9 ? selectedDate.getMinutes() : `0${selectedDate.getMinutes()}`
          })
        }), timeFormat === '12' && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            height: '80%',
            marginLeft: 12
          },
          children: [(0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            style: {
              width: 52,
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              borderWidth: 1,
              borderRightWidth: 0,
              borderColor: '#aaa',
              justifyContent: 'center',
              backgroundColor: selectedFormat === 'am' ? (0, _color.default)(_ThemeManager.ThemeManager.style.brandPrimary).lighten(0.75).toString() : undefined
            },
            onPress: function onPress() {
              _this2.setState({
                selectedFormat: 'am'
              }, _this2.onChangeHour(selectedDate.getHours(), false));
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              fixedWidth: "100%",
              textAlign: "center",
              children: "AM"
            })
          }), (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            style: {
              width: 52,
              borderTopRightRadius: 3,
              borderBottomRightRadius: 3,
              borderWidth: 1,
              borderColor: '#aaa',
              justifyContent: 'center',
              backgroundColor: selectedFormat === 'pm' ? (0, _color.default)(_ThemeManager.ThemeManager.style.brandPrimary).lighten(0.75).toString() : undefined
            },
            onPress: function onPress() {
              _this2.setState({
                selectedFormat: 'pm'
              }, _this2.onChangeHour(selectedDate.getHours(), false));
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              fixedWidth: "100%",
              textAlign: "center",
              children: "PM"
            })
          })]
        })]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        smallScreen: false,
        color: _ThemeManager.ThemeManager.style.datePickerColor,
        textColor: _ThemeManager.ThemeManager.style.datePickerTextColor,
        selectTimeMinutesIntervall: 5,
        selectedDate: new Date(2000, 1, 1),
        onChangeMinutes: function onChangeMinutes() {},
        onChangeHour: function onChangeHour() {},
        contentSize: {
          height: _ResizeEvent.ResizeEvent.current.contentHeight,
          width: _ResizeEvent.ResizeEvent.current.contentWidth
        }
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.selectedDate != null && prevState.lastDate.getTime() !== nextProps.selectedDate.getTime()) {
        return {
          hours: TimeSection.createHoursList(),
          minutes: TimeSection.createMinutesList(nextProps),
          selectedHour: TimeSection.createSelectedHour(nextProps.selectedDate),
          selectedMinute: nextProps.selectedDate.getMinutes(),
          selectedFormat: nextProps.selectedDate.getHours() > 11 ? 'pm' : 'am',
          lastDate: nextProps.selectedDate
        };
      }
      return null;
    }
  }, {
    key: "createHoursList",
    value: function createHoursList() {
      var hours = [];
      var index = 0;
      for (var i = 0; i < 24; i += 1) {
        var hour = i.toString().length === 1 ? `0${i.toString()}` : i.toString();
        hours.push({
          index: index,
          title: hour,
          data: i
        });
        index += 1;
      }
      return hours;
    }
  }, {
    key: "createMinutesList",
    value: function createMinutesList(nextProps) {
      var selectTimeMinutesIntervall = nextProps.selectTimeMinutesIntervall;
      var minutes = [];
      var index = 0;
      for (var i = 0; i < 60; i += selectTimeMinutesIntervall) {
        var minute = i.toString().length === 1 ? `0${i.toString()}` : i.toString();
        minutes.push({
          index: index,
          title: minute,
          data: i
        });
        index += 1;
      }
      return minutes;
    }
  }, {
    key: "createSelectedHour",
    value: function createSelectedHour(selectedDate) {
      var hour = selectedDate.getHours();
      return {
        title: hour > 9 ? hour.toString() : `0${hour.toString()}`,
        data: hour,
        index: hour
      };
    }
  }]);
}(_react.PureComponent);
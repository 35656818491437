"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookmarkEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var BookmarkEntity = function (_ReadModelEntity_1$Re) {
  function BookmarkEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, BookmarkEntity);
    _this = _callSuper(this, BookmarkEntity, [obj]);
    _this.entityName = 'Bookmark';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(BookmarkEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(BookmarkEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new BookmarkEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('forEntityId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('bookmarks', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "convertToPDFTron",
    value: function convertToPDFTron() {
      var result = {};
      for (var entry of this.bookmarks) {
        result[entry.page] = entry.title;
      }
      return result;
    }
  }], [{
    key: "convertFromPDFTron",
    value: function convertFromPDFTron(pdftronBookmark) {
      var bookmarks = [];
      for (var key in pdftronBookmark) {
        bookmarks.push({
          page: Number(key),
          title: pdftronBookmark[key]
        });
      }
      return bookmarks;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.BookmarkEntity = BookmarkEntity;
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import BackButton from 'materialTheme/src/views/components/BackButton';
import { PageView } from 'materialTheme/src/views/root/PageView';
import React, { PureComponent } from 'react';
import { Linking, Text, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { I18n } from '../../i18n/I18n';
import { SubscriptionCard } from './SubscriptionCard';
import { SubscriptionConfig } from './SubscriptionConfig';
const styles = {
    priceContainer: {
        marginBottom: 16,
        flex: 1,
        minWidth: 300,
        borderWidth: 1,
        backgroundColor: '#FFF',
        borderRadius: 3,
        margin: 16,
        padding: 0,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    priceDefaultText: {
        fontSize: 32,
        fontFamily: 'Roboto',
        fontWeight: '600',
        color: '#0c0042',
        marginBottom: 15,
    },
    priceNotetText: {
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: '400',
        color: '#0c0042',
        textAlign: 'center',
        marginBottom: 15,
    },
    priceNotetTextGrey: {
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: '400',
        color: ThemeManager.style.black42,
        marginBottom: 15,
    },
    priceText: {
        fontSize: 50,
        fontFamily: 'Roboto',
        fontWeight: '700',
        color: '#0c0042',
        marginBottom: 15,
    },
    cardsContainer: {
        flex: 1,
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: 50,
        marginHorizontal: 200,
    },
};
export class SubscriptionPlanView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.setPaymentMethod = (method) => (_e) => {
            const planVariantId = SubscriptionConfig.getBillwerkPlanVariantId(method);
            Dialog.instance?.close(() => {
                Routing.instance.goTo(`/subscriptionOrder/${planVariantId}`);
            });
        };
        this.onBasicPlanPress = () => {
            Routing.instance.goTo(`/subscriptionOrder/0`);
        };
        this.onProPlanPress = () => {
            Dialog.instance?.open({
                contentPadding: false,
                content: (<View style={{ overflow: 'visible', padding: ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.H6}> {I18n.m.getMessage('paymentMethod')} </MaterialText>
          <View style={styles.priceContainer}>
            <Ripple onPress={this.setPaymentMethod('yearly')} style={{ padding: 16 }}>
              <Text style={styles.priceDefaultText}>{I18n.m.getMessage('subscriptionPaymentMethodYearly')}</Text>
              <Text style={styles.priceText}>€ 49,-</Text>
              <Text style={styles.priceNotetText}>{I18n.m.getMessage('subscriptionPaymentPerUserAndMonth')}</Text>
              <Text style={styles.priceNotetText}>
                (€ 588,- / {I18n.m.getMessage('subscriptionPaymentUserAndYear')})
              </Text>
            </Ripple>
          </View>
          <View style={styles.priceContainer}>
            <Ripple onPress={this.setPaymentMethod('monthly')} style={{ padding: 16 }}>
              <Text style={styles.priceDefaultText}>{I18n.m.getMessage('subscriptionPaymentMethodMonthly')}</Text>
              <Text style={styles.priceText}>€ 59,-</Text>
              <Text style={styles.priceNotetText}>{I18n.m.getMessage('subscriptionPaymentPerUserAndMonth')}</Text>
              <Text style={styles.priceNotetText}>
                (€ 708,- / {I18n.m.getMessage('subscriptionPaymentUserAndYear')})
              </Text>
            </Ripple>
          </View>
          <View style={{ alignSelf: 'center' }}>
            <Text style={styles.priceNotetTextGrey}>
              {I18n.m.getMessage('subscriptionPaymentAllPricesWithoutSalesTaxes')}
            </Text>
          </View>
        </View>),
            });
        };
        this.openUrl = (url) => () => {
            Linking.openURL(url).catch((err) => console.debug(err));
        };
        this.state = { currentPlan: 'Basic' };
    }
    componentDidMount() {
        RightsManager.instance
            .hasVersion(CurrentUser.userId)
            .then((version) => {
            this.setState({ currentPlan: version.charAt(0).toUpperCase() + version.slice(1) });
        })
            .catch((err) => console.debug(err));
    }
    render() {
        const { currentPlan } = this.state;
        let basicButtonText = I18n.m.getMessage('keepBasicSubscription');
        let proButtonText = I18n.m.getMessage('subscriptionButtonTextPro');
        let enterpriseButtonText = I18n.m.getMessage('subscriptionButtonTextEnt');
        if (currentPlan === 'Pro' || currentPlan === 'ProAndEnterprise') {
            basicButtonText = I18n.m.getMessage('downgrade');
            proButtonText = I18n.m.getMessage('keepProSubscription');
            enterpriseButtonText = I18n.m.getMessage('subscriptionButtonTextEnt');
        }
        else if (currentPlan === 'Enterprise' || currentPlan === 'ProAndEnterprise') {
            basicButtonText = I18n.m.getMessage('downgrade');
            proButtonText = I18n.m.getMessage('subscriptionButtonTextPro');
            enterpriseButtonText = I18n.m.getMessage('keepEnterpriseSubscription');
        }
        return (<PageView showAccountIcon headerProps={{
                leftButtons: [<BackButton key="backButtonAccountView"/>],
                rightButtons: [],
                title: I18n.m.getMessage('subscriptionViewTitle'),
            }} scrollable style={{
                backgroundColor: ThemeManager.style.appBgColor,
            }}>
        <View style={{
                ...styles.cardsContainer,
                flexDirection: ResizeEvent.current.windowWidth > 1123 ? 'row' : 'column',
            }}>
          <SubscriptionCard isCurrentSubscription={currentPlan === 'Basic'} onSubscriptionButtonPress={this.onBasicPlanPress} subscriptionType={I18n.m.getMessage('subscriptionTypeBasic')} subscriptionDescription={I18n.m.getMessage('subscriptionDescriptionBasic')} subscriptionPrice="€ 0,-" subscriptionPriceDescription={I18n.m.getMessage('subscriptionPriceDescriptionBasic')} subscriptionButtonText={basicButtonText} subscriptionPossibilitiesDescription={I18n.m.getMessage('subscriptionPossibilitiesDescriptionBasic')} buttonDisabled={currentPlan === 'Enterprise' || currentPlan === 'ProAndEnterprise'}/>
          <SubscriptionCard isCurrentSubscription={currentPlan === 'Pro' || currentPlan === 'ProAndEnterprise'} onSubscriptionButtonPress={this.onProPlanPress} subscriptionType={I18n.m.getMessage('subscriptionTypePro')} subscriptionDescription={I18n.m.getMessage('subscriptionDescriptionPro')} subscriptionPrice="€ 49,-" subscriptionPriceDescription={I18n.m.getMessage('subscriptionPriceDescriptionPro')} subscriptionButtonText={proButtonText} subscriptionPossibilitiesDescription={I18n.m.getMessage('subscriptionPossibilitiesDescriptionPro')}/>
          <SubscriptionCard isCurrentSubscription={currentPlan === 'Enterprise' || currentPlan === 'ProAndEnterprise'} onSubscriptionButtonPress={this.openUrl('https://www.upmesh.de/kontakt/')} subscriptionType={I18n.m.getMessage('subscriptionTypeEnt')} subscriptionDescription={I18n.m.getMessage('subscriptionDescriptionEnt')} subscriptionPrice={I18n.m.getMessage('subscriptionIndividual')} subscriptionPriceDescription={I18n.m.getMessage('subscriptionPriceDescriptionEnt')} subscriptionButtonText={enterpriseButtonText} subscriptionPossibilitiesDescription={I18n.m.getMessage('subscriptionPossibilitiesDescriptionEnt')}/>
        </View>
        <View style={{ alignSelf: 'center' }}>
          <Text style={styles.priceNotetText}>
            {I18n.m.getMessage('subscriptionPaymentAllPricesWithoutSalesTaxes')}
          </Text>
          <ContainedButton title={I18n.m.getMessage('showMoreSubscriptionInfo')} onPress={this.openUrl('https://www.upmesh.de/preise/')} textColor="#00cd9e" backgroundColor="transparent"/>
        </View>
      </PageView>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GoogleButton = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _Ripple = require("../../../theme/components/utils/Ripple");
var _ThemeManager = require("../../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var googleImage = require("../../../assets/googleIcon.png");
var GoogleButton = exports.GoogleButton = function (_PureComponent) {
  function GoogleButton() {
    (0, _classCallCheck2.default)(this, GoogleButton);
    return _callSuper(this, GoogleButton, arguments);
  }
  (0, _inherits2.default)(GoogleButton, _PureComponent);
  return (0, _createClass2.default)(GoogleButton, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        title = _this$props.title,
        disabled = _this$props.disabled,
        onPress = _this$props.onPress,
        width = _this$props.width,
        accessibilityLabel = _this$props.accessibilityLabel;
      var tID = accessibilityLabel != null ? accessibilityLabel : title == null || title.length === 0 ? 'BUTTON' : title;
      return (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
        disabled: disabled,
        onPress: onPress,
        backgroundColor: "#FFFFFF",
        hoverColor: "#EFEFEF",
        testID: tID,
        accessibilityLabel: tID,
        style: Object.assign({
          width: width != null ? width : '100%',
          height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48)
        }, _ThemeManager.ThemeManager.style.borderStyle, {
          position: 'relative',
          justifyContent: 'center',
          borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
          paddingLeft: width != null ? _ThemeManager.ThemeManager.style.contentPaddingValue + _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : _ThemeManager.ThemeManager.style.contentPaddingValue,
          paddingRight: _ThemeManager.ThemeManager.style.contentPaddingValue
        }),
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Button,
          centeredText: true,
          centeredBox: true,
          children: title.toLocaleUpperCase()
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
          source: googleImage,
          style: {
            width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
            position: 'absolute',
            left: _ThemeManager.ThemeManager.style.contentPaddingValue
          },
          resizeMode: "contain"
        })]
      });
    }
  }]);
}(_react.PureComponent);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeExtraPayTrackingStatus = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ExtraPayTrackingStatusChanged_1 = require("../../../events/company/extrapay/ExtraPayTrackingStatusChanged");
var ChangeExtraPayTrackingStatus = function (_CommandRoot_1$Comman) {
  function ChangeExtraPayTrackingStatus(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeExtraPayTrackingStatus);
    _this = _callSuper(this, ChangeExtraPayTrackingStatus, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeExtraPayTrackingStatus';
    return _this;
  }
  (0, _inherits2.default)(ChangeExtraPayTrackingStatus, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeExtraPayTrackingStatus, [{
    key: "getExtraPayTracking",
    value: function () {
      var _getExtraPayTracking = (0, _asyncToGenerator2.default)(function* () {
        if (this._extraPayTracking == null) this._extraPayTracking = yield CommandReadModels_1.CommandReadModels.extraPayTracking.getById(this.entityId);
        return this._extraPayTracking;
      });
      function getExtraPayTracking() {
        return _getExtraPayTracking.apply(this, arguments);
      }
      return getExtraPayTracking;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var extraPayTracking = yield this.getExtraPayTracking();
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(extraPayTracking.companyId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive') {
          if (companyMember[0].payroll || companyMember[0].canLockExtraPayFrom === 'all') return true;
          if (companyMember[0].canLockExtraPayFrom === 'group') {
            var forUser = yield ReadModels_1.ReadModels.get('CompanyMember').getById(extraPayTracking.memberId);
            if (forUser.groupId === companyMember[0].groupId) return true;
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var events = [];
        var extraPayTracking = yield this.getExtraPayTracking();
        if (this.data.to === 'Exported') {
          if (extraPayTracking.status === 'Exported') return [];
          events.push(new ExtraPayTrackingStatusChanged_1.ExtraPayTrackingStatusChanged(this.entityId, {
            status: 'Exported'
          }, extraPayTracking.companyId));
        } else if (this.data.to === 'Locked') {
          if (extraPayTracking.status === 'Locked') return [null];
          events.push(new ExtraPayTrackingStatusChanged_1.ExtraPayTrackingStatusChanged(this.entityId, {
            status: 'Locked'
          }, extraPayTracking.companyId));
        } else if (this.data.to === 'Recorded') {
          if (extraPayTracking.status === 'Recorded') return [null];
          events.push(new ExtraPayTrackingStatusChanged_1.ExtraPayTrackingStatusChanged(this.entityId, {
            status: 'Recorded'
          }, extraPayTracking.companyId));
        }
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeExtraPayTrackingStatus = ChangeExtraPayTrackingStatus;
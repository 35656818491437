import { DeviceManager } from 'materialTheme/src/securestore/DeviceManager';
import { AddPushNotification } from 'upmesh-core/src/client/commands/settings/AddPushNotification';
import { RemovePushNotification } from 'upmesh-core/src/client/commands/settings/RemovePushNotification';
import { UpdatePushNotificationToken } from 'upmesh-core/src/client/commands/settings/UpdatePushNotificationToken';
export class PushNotifications {
    static async getState(pushNotifications) {
        try {
            if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
                console.log('push not supported, showNotification', ServiceWorkerRegistration.prototype);
                return 'notSupported';
            }
            if (!PushNotifications.workerRunning) {
                console.log('push not supported, worker not running');
                return 'notSupported';
            }
            if (!('Notification' in window)) {
                console.log('push not supported, no Notification in window');
                return 'notSupported';
            }
            if (Notification.permission === 'denied') {
                console.log('push not denied');
                return 'denied';
            }
            if (!('PushManager' in window)) {
                console.log('push not supported, no PushManager in window');
                return 'notSupported';
            }
            if (!('serviceWorker' in navigator)) {
                console.log('push not supported, no serviceWorker in nviagator');
                return 'notSupported';
            }
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;
            const subscription = await serviceWorkerRegistration.pushManager.getSubscription();
            if (!subscription) {
                return false;
            }
            let alreadyIn = false;
            if (pushNotifications == null || pushNotifications.length === 0)
                return false;
            pushNotifications.forEach((p) => {
                if (p.deviceId === DeviceManager.deviceId) {
                    alreadyIn = true;
                    if (p.token['endpoint'] !== subscription['endpoint']) {
                        PushNotifications.updatePushNotificationOnServer(subscription).catch((err) => console.debug(err));
                    }
                }
            });
            return alreadyIn;
        }
        catch (err) {
            console.warn('PushNotifications: error', err);
            return 'notSupported';
        }
    }
    static async checkNotificationPermission() {
        return new Promise((resolve) => {
            Notification.requestPermission()
                .then((permission) => {
                if (Notification.permission === 'denied' || Notification.permission === 'default') {
                    console.debug('PushNotifications: no permission', permission, Notification.permission);
                    resolve(false);
                }
                else {
                    resolve(true);
                }
            })
                .catch((err) => {
                console.debug('PushNotifications: permission error', err);
                resolve(false);
            });
        });
    }
    static async setState(value) {
        if (!PushNotifications.workerRunning)
            return 'notSupported';
        if (value) {
            if (Notification.permission === 'denied' || Notification.permission === 'default') {
                const permission = await PushNotifications.checkNotificationPermission();
                if (!permission)
                    return 'notSupported';
            }
        }
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        if (value)
            return PushNotifications.addSubscription(serviceWorkerRegistration);
        return PushNotifications.removeSubscription(serviceWorkerRegistration);
    }
    static async removeSubscription(serviceWorkerRegistration) {
        try {
            const pushSubscription = await serviceWorkerRegistration.pushManager.getSubscription();
            if (!pushSubscription)
                return false;
            const remove = new RemovePushNotification({
                deviceId: DeviceManager.deviceId,
            });
            await remove.execute();
            await pushSubscription.unsubscribe();
        }
        catch (err) {
            console.debug('PushNotifications: Error thrown while unsubscribing from push messaging.', err);
        }
        return false;
    }
    static async addSubscription(serviceWorkerRegistration) {
        try {
            const subscription = await serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BLbLPAEIt3qPyhgQ_pLG573PCMbDmWqWlLXoOZdgF-n_2tVlCTbBiJzVakIWxcpz7XCfvF3e5BhY8hja7Nmerlw',
            });
            console.log('subscription', subscription);
            if (subscription == null)
                return 'notSupported';
            const command = new AddPushNotification({
                deviceId: DeviceManager.deviceId,
                token: subscription,
                type: 'web',
            });
            await command.execute();
            return true;
        }
        catch (err) {
            console.debug('cant save Push Notification Settings', err);
            return false;
        }
    }
    static async updatePushNotificationOnServer(token) {
        try {
            const c = new UpdatePushNotificationToken({
                token,
                deviceId: DeviceManager.deviceId,
            });
            await c.execute();
        }
        catch (err) {
            console.debug('PushNotifications: cant UpdatePushNotificationToken on server', err);
        }
    }
}
PushNotifications.workerRunning = false;

import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { LogBook } from '../../logbook/LogBook';
import { CurrentProject } from '../CurrentProject';
export class LogsItem extends PureComponent {
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        const { projectId } = this.props;
        if (projectId != null && CurrentProject.instance.getCurrentProjectId() !== projectId) {
            try {
                const project = await UpmeshClient.instance.modals.project.getById(projectId);
                this.setState({ project });
            }
            catch (e) {
                console.debug(e);
            }
        }
    }
    render() {
        const { styles, projectId } = this.props;
        return (<View style={[styles, { width: '100%', height: '100%' }]} onLayout={(l) => {
                if (l.nativeEvent.layout.height) {
                    this.setState({ height: l.nativeEvent.layout.height - 48 });
                }
            }}>
        <View style={{ padding: 16, paddingBottom: 0, width: '100%', height: 48 }}>
          <MaterialText type={MaterialTextTypes.H5}>{`${I18n.m.getMessage('activities')}${this.state?.project && this.state?.project.title ? ` (${this.state.project.title})` : ''}`}</MaterialText>
        </View>
        {this.state?.height && (<ScrollView style={{ height: this.state.height, width: '100%' }} nestedScrollEnabled>
            <LogBook projectId={projectId} projectLogBook={projectId != null} height={this.state.height} isPreview moreActivitiesRoute={projectId ? `/projects/${projectId}/logbook` : '/activities'} flat/>
          </ScrollView>)}
      </View>);
    }
}

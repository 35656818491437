var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddressCardForm = AddressCardForm;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AddressEntity = require("upmesh-auth-core/build/src/client/query/entities/AddressEntity");
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _ContainedButton = require("../button/ContainedButton");
var _DialogActions = require("../dialog/DialogActions");
var _DialogContent = require("../dialog/DialogContent");
var _FormInputFilled = require("../forminput/FormInputFilled");
var _Header = require("../header/Header");
var _Icon = require("../Icon");
var _Map = require("../Map");
var _GooglePlacesInput = require("./GooglePlacesInput");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var headerStyle = {
  justifyContent: 'flex-end',
  paddingTop: 16,
  paddingBottom: 16
};
var getMapHeight = function getMapHeight() {
  var mapHeightMobile = _ResizeEvent.ResizeEvent.current.contentHeight - _ThemeManager.ThemeManager.style.headerHeight;
  var mapHeightWeb = _ResizeEvent.ResizeEvent.current.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM ? _ResizeEvent.ResizeEvent.current.windowHeight - _ThemeManager.ThemeManager.style.headerHeight : _ResizeEvent.ResizeEvent.current.windowHeight * 0.89 - _ThemeManager.ThemeManager.style.headerHeight;
  return Math.floor(_reactNativeWeb.Platform.OS === 'web' ? mapHeightWeb : mapHeightMobile);
};
function AddressCardForm(props) {
  var _useState = (0, _react.useState)(props.address),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    address = _useState2[0],
    setAddress = _useState2[1];
  var _useState3 = (0, _react.useState)(getMapHeight()),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    mapHeight = _useState4[0],
    setMapHeight = _useState4[1];
  var _useState5 = (0, _react.useState)(props.address.addressInput),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    inputValue = _useState6[0],
    setInputValue = _useState6[1];
  var _useState7 = (0, _react.useState)(false),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    showMap = _useState8[0],
    setShowMap = _useState8[1];
  var _useState9 = (0, _react.useState)(0),
    _useState10 = (0, _slicedToArray2.default)(_useState9, 2),
    placesKey = _useState10[0],
    _setPlacesKey = _useState10[1];
  var abortController = (0, _react.useRef)(new AbortController());
  var map = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    return function () {
      return abortController.current.abort();
    };
  }, []);
  var onLayout = function onLayout() {
    setMapHeight(getMapHeight());
  };
  var renderMapView = function renderMapView() {
    if (!showMap) return null;
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      onLayout: onLayout,
      style: {
        overflow: 'hidden'
      },
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: mapHeight,
          width: '100%',
          overflow: 'hidden'
        },
        children: (0, _jsxRuntime.jsx)(_Map.Map, {
          ref: map,
          style: {
            width: '100%',
            height: mapHeight
          },
          address: address,
          allowScaling: true,
          onChangeAddressInput: function onChangeAddressInput(address) {
            setAddress(address);
            setInputValue(address.addressInput != null ? address.addressInput : '');
          },
          showCurrentLocation: true,
          showAddressInput: true,
          zoomLevel: 12,
          showGoToCurrentLocationButton: true
        })
      })
    });
  };
  var onGoogleAddressSelected = function onGoogleAddressSelected(newAddress) {
    setAddress(function (prev) {
      return new _AddressEntity.AddressEntity(Object.assign({}, prev, newAddress));
    });
    setInputValue(newAddress.addressInput != null ? newAddress.addressInput : '');
    if (map.current != null && newAddress != null && newAddress.latitude != null && newAddress.longitude != null) {
      map.current.zoomTo({
        lat: Number.parseFloat(newAddress.latitude),
        lng: Number.parseFloat(newAddress.longitude)
      });
    }
  };
  var onCustomAddressTextSelected = function onCustomAddressTextSelected(text) {
    setAddress(new _AddressEntity.AddressEntity(Object.assign({}, address, {
      addressInput: text
    })));
    setInputValue(text != null ? text : '');
  };
  var onChangeFormInputText = function onChangeFormInputText(propName) {
    return function (text) {
      var addressCopy = Object.assign({}, address);
      addressCopy[propName] = text;
      setAddress(new _AddressEntity.AddressEntity(addressCopy));
    };
  };
  var save = function save(_e) {
    var onSave = props.onSave;
    onSave(address);
  };
  var renderAddressAutocomplete = function renderAddressAutocomplete(onPress) {
    return (0, _jsxRuntime.jsx)(_GooglePlacesInput.GooglePlacesInput, {
      initialText: inputValue != null ? inputValue : '',
      onChange: onGoogleAddressSelected,
      onChangeText: onCustomAddressTextSelected,
      backendURL: props.backendURL,
      onPressMapMarker: onPress
    }, `places_${placesKey.toString()}`);
  };
  var switchView = function switchView() {
    setShowMap(function (prev) {
      return !prev;
    });
  };
  var renderFIView = function renderFIView() {
    var change = props.change,
      loading = props.loading,
      onCancel = props.onCancel,
      emailAC = props.emailAC,
      phoneAC = props.phoneAC,
      nameAC = props.nameAC,
      functionAC = props.functionAC;
    if (showMap) return null;
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flex: 1
          },
          children: [renderAddressAutocomplete(switchView), (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            labelText: _I18n.I18n.m.getMessage('addressContactName'),
            onChange: onChangeFormInputText('contactName'),
            initValue: address.contactName != null ? address.contactName : '',
            autoCompleteList: nameAC
          }, "FI_Name"), props.showContactFunction && (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            labelText: _I18n.I18n.m.getMessage('addressContactFunction'),
            onChange: onChangeFormInputText('contactFunction'),
            initValue: address.contactFunction != null ? address.contactFunction : '',
            autoCompleteList: functionAC
          }, "FI_Function"), (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            labelText: _I18n.I18n.m.getMessage('addressPhone'),
            initValue: address.phone != null ? address.phone : '',
            onChange: onChangeFormInputText('phone'),
            keyboardType: "phone-pad",
            autoCompleteList: phoneAC
          }, "FI_Phone"), (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
            labelText: _I18n.I18n.m.getMessage('email'),
            onChange: onChangeFormInputText('email'),
            initValue: address.email != null ? address.email : '',
            keyboardType: "email-address",
            autoCompleteList: emailAC
          }, "FI_Email")]
        })
      }), (0, _jsxRuntime.jsxs)(_DialogActions.DialogActions, {
        children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          disabled: loading,
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary,
          title: _I18n.I18n.m.getMessage('cancel'),
          onPress: onCancel
        }, "closeButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          disabled: loading,
          loading: loading,
          onPress: save,
          title: change ? _I18n.I18n.m.getMessage('save') : _I18n.I18n.m.getMessage('add'),
          backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary
        })]
      })]
    });
  };
  var change = props.change,
    title = props.title,
    subTitle = props.subTitle;
  var headerContainerStyle = showMap ? {} : headerStyle;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: headerContainerStyle,
      children: (0, _jsxRuntime.jsx)(_Header.Header, {
        title: title != null && title.length > 0 ? title : change ? _I18n.I18n.m.getMessage('addressChange') : _I18n.I18n.m.getMessage('addressAdd'),
        subTitle: subTitle,
        withBorder: false,
        leftButtons: showMap ? [(0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: _I18n.I18n.m.getMessage('back'),
          icon: "arrow-left",
          onPress: switchView
        }, "ACIHeaderBack")] : []
      })
    }), showMap ? renderMapView() : renderFIView()]
  });
}
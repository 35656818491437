import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyCraft } from 'upmesh-core/src/client/commands/companies/crafts/AddCompanyCraft';
import { I18n } from '../../i18n/I18n';
export class AddCraftToCompanyDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    render() {
        const { company } = this.props;
        const currentData = { craft: '' };
        const dataOptions = new Map();
        dataOptions.set('craft', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('craft') },
        });
        return (<Form key="changeCompanyUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyAddCraftDialogTitle'),
            }} stickyActions={false} command={new AddCompanyCraft(currentData, company.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('add')} additionalButtons={[
                <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

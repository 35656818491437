"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignUpUser = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var EmailValidator = require("email-validator");
var UserSignedUp_1 = require("../../events/guest/UserSignedUp");
var SignUpUser = function (_CommandRoot_1$Comman) {
  function SignUpUser(data) {
    var _this;
    (0, _classCallCheck2.default)(this, SignUpUser);
    _this = _callSuper(this, SignUpUser, [data, '', '']);
    _this.commandName = 'SignUpUser';
    _this.aggregate = 'guests';
    return _this;
  }
  (0, _inherits2.default)(SignUpUser, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(SignUpUser, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'email') {
          if (!EmailValidator.validate(this.data.email)) {
            throw {
              key: 'email',
              messageCode: 'emailValidationError',
              message: 'Keine gültige E-Mail Adresse'
            };
          }
        }
        if (key == null || key === 'firstname') {
          if (!this.data.firstname || this.data.firstname.length <= 0) {
            throw {
              key: 'firstname',
              messageCode: 'registerErrorRequireFirstName',
              message: 'Vorname wird benötigt'
            };
          }
        }
        if (key == null || key === 'lastname') {
          if (!this.data.lastname || this.data.lastname.length <= 0) {
            throw {
              key: 'lastname',
              messageCode: 'registerErrorRequireLastName',
              message: 'Nachname wird benötigt'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = Object.assign({}, this.data);
        var e = new UserSignedUp_1.UserSignedUp(this.entityId, data);
        return e;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.SignUpUser = SignUpUser;
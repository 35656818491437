"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.I18nClient = exports.I18nOptions = void 0;
var cross_fetch_1 = require("cross-fetch");
var i18next_1 = require("i18next");
var ts_events_1 = require("ts-events");
var DateLocale_1 = require("./i18n/DateLocale");
var I18nOptions = (0, _createClass2.default)(function I18nOptions(options) {
  (0, _classCallCheck2.default)(this, I18nOptions);
  this.backendUrl = 'https://dev.upmesh.de/api/upmesh/backend/translations';
  this.hourFormat24 = true;
  this.component = 'client';
  for (var k in options) {
    this[k] = options[k];
  }
});
exports.I18nOptions = I18nOptions;
var I18nClient = function () {
  function I18nClient(deMessages, options) {
    (0, _classCallCheck2.default)(this, I18nClient);
    this.changedLanguage = new ts_events_1.AsyncEvent();
    this._initialized = false;
    this.backendUrl = 'https://dev.upmesh.de/api/upmesh/backend/translations';
    this.i18config = undefined;
    this.resources = {
      de: {
        translation: {}
      }
    };
    this.defaultMessagesOverride = {};
    this.messagesOverride = {};
    this.options = options;
    this.backendUrl = options.backendUrl;
    this.component = options.component;
    I18nClient.translations[this.component]['de'] = deMessages;
    this.resources = {
      de: {
        translation: deMessages
      }
    };
    this.dateCurrent = new DateLocale_1.DateLocale('de', options.hourFormat24 ? '24' : '12');
    this.cache = options.cache;
  }
  return (0, _createClass2.default)(I18nClient, [{
    key: "date",
    value: function date() {
      var _lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'de';
      return this.dateCurrent;
    }
  }, {
    key: "initialized",
    get: function get() {
      return this._initialized;
    }
  }, {
    key: "changeDateLocale",
    value: function changeDateLocale(hourFormat24) {
      this.dateCurrent = new DateLocale_1.DateLocale(this.currentLanguage, hourFormat24 ? '24' : '12');
    }
  }, {
    key: "changeLanguage",
    value: function () {
      var _changeLanguage = (0, _asyncToGenerator2.default)(function* (lang) {
        var locale = this.stringToLangkey(lang);
        if (this.loadedLang === locale) return;
        this.currentLanguage = locale;
        this.dateCurrent = new DateLocale_1.DateLocale(locale, this.options.hourFormat24 ? '24' : '12');
        yield this.loadTranslations(locale);
        yield this.loadMessages(locale);
        yield this.i18n.changeLanguage(locale);
        this.changedLanguage.post(locale);
      });
      function changeLanguage(_x) {
        return _changeLanguage.apply(this, arguments);
      }
      return changeLanguage;
    }()
  }, {
    key: "getMessage",
    value: function getMessage(message) {
      var values = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        lng: this.currentLanguage
      };
      var messagesOverride = arguments.length > 2 ? arguments[2] : undefined;
      var original = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var mo = messagesOverride != null && messagesOverride[message] != null ? messagesOverride : Object.assign({}, this.defaultMessagesOverride, this.messagesOverride);
      if (mo != null && mo[message] != null && mo[message].length > 0 && !original) return mo[message];
      if (values != null && values.lng == null) values.lng = this.currentLanguage;
      var r = '?';
      try {
        if (values) {
          r = this.i18n.t(message.toString(), Object.assign({}, values, {
            interpolation: {
              escapeValue: false
            }
          }));
        } else {
          r = this.i18n.t(message.toString());
        }
      } catch (e) {
        r = message.toString();
      }
      return r;
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (lang) {
        yield this.changeLanguage(lang);
        this._initialized = true;
      });
      function init(_x2) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "stringToLangkey",
    value: function stringToLangkey(local) {
      if (local.length >= 2) {
        if (local.substring(0, 2) === 'en') return 'en';
        if (local.substring(0, 2) === 'nl') return 'nl';
        if (local.substring(0, 2) === 'pl') return 'pl';
      }
      return 'de';
    }
  }, {
    key: "getCurrentTranslation",
    value: function getCurrentTranslation(selectedLang) {
      var trans = I18nClient.translations[this.component][selectedLang];
      if (trans != null) return trans;
      return I18nClient.translations[this.component]['de'];
    }
  }, {
    key: "loadMessages",
    value: function () {
      var _loadMessages = (0, _asyncToGenerator2.default)(function* (lang) {
        var onError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var selectedLang = 'de';
        if (lang != null) {
          selectedLang = lang;
        } else if (this.currentLanguage != null) {
          selectedLang = this.currentLanguage;
        }
        if (this.loadedLang !== selectedLang) {
          if (selectedLang !== 'de') {
            this.resources[selectedLang] = {
              translation: this.getCurrentTranslation(selectedLang)
            };
          }
          this.i18config = {
            resources: this.resources,
            lng: selectedLang,
            fallbackLng: ['de'],
            returnEmptyString: false,
            debug: false
          };
          if (i18next_1.default == null) {
            var i18next1 = require('i18next');
            this.i18n = i18next1.createInstance();
          } else {
            this.i18n = i18next_1.default.createInstance();
          }
          try {
            yield this.i18n.init(this.i18config);
          } catch (e) {
            if (!onError) {
              yield this.loadMessages('de');
            }
          }
          if (selectedLang != null) this.loadedLang = selectedLang;
        }
      });
      function loadMessages(_x3) {
        return _loadMessages.apply(this, arguments);
      }
      return loadMessages;
    }()
  }, {
    key: "loadTranslations",
    value: function () {
      var _loadTranslations = (0, _asyncToGenerator2.default)(function* (langCode) {
        if (this.cache != null) {
          var cached = yield this.cache.restore(`translations_${this.component}`);
          if (cached != null) I18nClient.translations[this.component] = JSON.parse(cached);
        }
        var trans = I18nClient.translations[this.component][langCode];
        var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        if (trans == null || I18nClient.lastUpdate == null || I18nClient.lastUpdate < yesterday) {
          try {
            var response = yield (0, cross_fetch_1.default)(`${this.backendUrl}/${langCode}/${this.component}`, {
              method: 'GET',
              mode: 'cors'
            });
            if (response != null && response.status === 200) {
              var data = yield response.json();
              if (data != null) {
                I18nClient.translations[this.component][langCode] = data;
                trans = data;
                if (this.cache != null) {
                  yield this.cache.save(`translations_${this.component}`, JSON.stringify(I18nClient.translations[this.component]));
                }
                I18nClient.lastUpdate = new Date();
              }
            }
          } catch (e) {}
        }
        if (trans != null) return trans;
        return I18nClient.translations[this.component]['de'];
      });
      function loadTranslations(_x4) {
        return _loadTranslations.apply(this, arguments);
      }
      return loadTranslations;
    }()
  }]);
}();
exports.I18nClient = I18nClient;
I18nClient.languages = {
  client: ['de'],
  supportclient: ['de'],
  materialtheme: ['de']
};
I18nClient.translations = {
  client: {
    de: {
      test: 'test'
    }
  },
  supportclient: {
    de: {
      test: 'test'
    }
  },
  materialtheme: {
    de: {
      test: 'test'
    }
  }
};
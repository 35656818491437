import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { ChangeGroupName } from 'upmesh-core/src/client/commands/companies/ChangeGroupName';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { I18n } from '../../i18n/I18n';
export class ChangeGroupCompanyDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    render() {
        const { group } = this.props;
        if (group.groupName === GroupEntity.getDefaultName()) {
            group.groupName = I18n.m.getMessage('companyGroupDefaultName');
        }
        const currentData = { ...group };
        const dataOptions = new Map();
        dataOptions.set('groupName', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('name') },
        });
        return (<Form key="changeCompanyUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyChangeGroupDialogTitle'),
            }} stickyActions={false} command={new ChangeGroupName(currentData, group.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[
                <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormDialogContainer = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _DialogContainer2 = require("../dialog/DialogContainer");
var _Icon = require("../Icon");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FormDialogContainer = exports.FormDialogContainer = function (_DialogContainer) {
  function FormDialogContainer() {
    (0, _classCallCheck2.default)(this, FormDialogContainer);
    return _callSuper(this, FormDialogContainer, arguments);
  }
  (0, _inherits2.default)(FormDialogContainer, _DialogContainer);
  return (0, _createClass2.default)(FormDialogContainer, [{
    key: "render",
    value: function render() {
      var closed = this.state.closed;
      if (closed) {
        return null;
      }
      return this.renderModal(this.props.children, {
        onPress: null
      });
    }
  }, {
    key: "renderContent",
    value: function renderContent(children) {
      var closeButton = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          right: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
          top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
          backgroundColor: '#FFFFFF',
          borderRadius: _ThemeManager.ThemeManager.style.borderRadius
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "close",
          toolTip: _I18n.I18n.m.getMessage('close'),
          onPress: _Routing.Routing.instance.closeDialog(true)
        })
      });
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          height: '100%',
          position: 'relative'
        },
        children: [_react.default.cloneElement(children, Object.assign({}, this.state, this.props, children.props)), closeButton]
      });
    }
  }]);
}(_DialogContainer2.DialogContainer);
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoutingRoute = exports.Routing = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _Url = require("cqrs-shared/build/src/uri/Url");
var _queryString = _interopRequireDefault(require("query-string"));
var _tsEvents = require("ts-events");
var _ResizeEvent = require("../ResizeEvent");
var RoutingRoute = exports.RoutingRoute = (0, _createClass2.default)(function RoutingRoute() {
  (0, _classCallCheck2.default)(this, RoutingRoute);
  this.exact = true;
});
var Routing = exports.Routing = function () {
  function Routing() {
    var _this = this;
    (0, _classCallCheck2.default)(this, Routing);
    this.alert = new _tsEvents.AsyncEvent();
    this.dialogClosedEvent = new _tsEvents.AsyncEvent();
    this.dialogShouldCloseEvent = new _tsEvents.AsyncEvent();
    this.routeBack = new _tsEvents.AsyncEvent();
    this.routeBlock = new _tsEvents.AsyncEvent();
    this.routeChanged = new _tsEvents.AsyncEvent();
    this.routeShouldChanged = new _tsEvents.AsyncEvent();
    this.routeShouldChangedAttached = false;
    this.closeDialog = function () {
      var replace = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return function (_e) {
        if (replace && _this.isGoBackAvailable()) return _this.goBack();
        var search = {};
        var path = '/';
        var hash = '';
        if (_this.currentUrl != null) {
          if (_this.currentUrl.search != null) {
            search = _queryString.default.parse(_this.currentUrl.search);
          }
          if (_this.currentUrl.pathname != null) {
            path = _this.currentUrl.pathname;
          }
          if (_this.currentUrl.hash != null) {
            hash = _this.currentUrl.hash;
          }
        }
        search.d = undefined;
        search.data = undefined;
        var fullpath = `${path}?${_queryString.default.stringify(search)}${hash}`;
        return _this.postRouteShouldChange({
          replace: replace,
          path: fullpath
        });
      };
    };
    this.goBackButton = function (_e) {
      _this.routeBack.post('');
    };
    this.postRouteShouldChange = function (data) {
      if (_this.gotoTO) clearTimeout(_this.gotoTO);
      if (_this.routeShouldChangedAttached) {
        _this.routeShouldChanged.post(data);
      } else {
        _this.gotoTO = setTimeout(function () {
          _this.postRouteShouldChange(data);
        }, 100);
      }
    };
    this.goToButton = function () {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return function (_e) {
        _this.postRouteShouldChange({
          path: path,
          replace: replace
        });
      };
    };
    this.openDialog = function (dialogPath, data) {
      var replace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return function (e) {
        var search = {};
        var path = '/';
        var hash = '';
        if (_this.currentUrl != null) {
          if (_this.currentUrl.search != null) {
            search = _queryString.default.parse(_this.currentUrl.search);
          }
          if (_this.currentUrl.pathname != null) {
            path = _this.currentUrl.pathname;
          }
          if (_this.currentUrl.hash != null) {
            hash = _this.currentUrl.hash;
          }
        }
        var dPath = _Url.Url.getURLfromString(dialogPath);
        if (dPath.search != null) {
          search = Object.assign({}, search, _queryString.default.parse(dPath.search));
        }
        search.d = dPath.pathname;
        if (data != null) {
          search.data = _queryString.default.stringify(data);
        }
        var click = {
          x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
          y: _ResizeEvent.ResizeEvent.current.windowHeight / 2
        };
        if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
          click = {
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY
          };
        }
        var state = {
          click: click
        };
        var fullpath = `${path}?${_queryString.default.stringify(search)}${hash}`;
        _this.postRouteShouldChange({
          replace: replace,
          state: state,
          path: fullpath
        });
      };
    };
    if (Routing._instance != null) {
      return Routing._instance;
    }
  }
  return (0, _createClass2.default)(Routing, [{
    key: "changeQueryParameter",
    value: function changeQueryParameter(parameterValue) {
      var _this2 = this;
      var replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (this.currentUrl != null) {
        var uri = this.currentUrl;
        var s = uri.search != null && uri.search.length > 0 ? uri.search.substr(0, 1) === '?' ? uri.search.substr(1) : uri.search : '';
        var o = Object.assign({}, _queryString.default.parse(s), parameterValue);
        Object.keys(o).forEach(function (k) {
          if (typeof o[k] === 'object') {
            o[k] = _queryString.default.stringify(o[k]);
          }
        });
        uri.search = _queryString.default.stringify(o);
        this.goTo(uri, replace);
      } else {
        requestAnimationFrame(function () {
          _this2.changeQueryParameter(parameterValue, replace);
        });
      }
    }
  }, {
    key: "goBack",
    value: function goBack() {
      if (!this.isGoBackAvailable()) {
        this.goTo('/');
        return;
      }
      this.routeBack.post('');
    }
  }, {
    key: "isGoBackAvailable",
    value: function isGoBackAvailable() {
      return Routing.history.index > 0 && Routing.history.length > Routing.initialIndex;
    }
  }, {
    key: "goTo",
    value: function goTo() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.postRouteShouldChange({
        path: path,
        replace: replace
      });
    }
  }, {
    key: "routeHasChanged",
    value: function routeHasChanged(route) {
      this.currentUrl = _Url.Url.getURLfromString(route);
      this.routeChanged.post({
        path: route,
        url: this.currentUrl
      });
    }
  }], [{
    key: "instance",
    get: function get() {
      if (Routing._instance == null) {
        Routing._instance = new Routing();
      }
      return Routing._instance;
    }
  }, {
    key: "routes",
    get: function get() {
      return Routing._routes;
    },
    set: function set(routes) {
      var newRoutes = [];
      Routing._dialogs = [];
      for (var i = 0; i < routes.length; i += 1) {
        var r = routes[i];
        if (r.asDialog != null) {
          Routing._dialogs.push(r);
        } else {
          r.path = Routing.removeFirstAndLastEmptyPath(r.path.split('/')).join('/');
          newRoutes.push(r);
        }
      }
      Routing._routes = newRoutes;
    }
  }, {
    key: "getDialogForPath",
    value: function getDialogForPath(path) {
      var clearedPath = Routing.removeFirstAndLastEmptyPath(path);
      for (var i = 0; i < Routing._dialogs.length; i += 1) {
        var pathSplit = Routing._dialogs[i].path.split('/');
        if (clearedPath.length >= pathSplit.length) {
          var equal = Routing.equalRoutePath(clearedPath, pathSplit);
          if (equal != null) {
            return Object.assign({
              path: Routing._dialogs[i].path,
              extendend: Routing._dialogs[i].asDialog === 'extendedDialog',
              optionalDialogContainerProps: Routing._dialogs[i].optionalDialogContainerProps,
              component: Routing._dialogs[i].component,
              dialogType: Routing._dialogs[i].asDialog
            }, equal);
          }
        }
      }
      return undefined;
    }
  }, {
    key: "getRouteForPath",
    value: function getRouteForPath(path) {
      var clearedPath = Routing.removeFirstAndLastEmptyPath(path);
      for (var i = 0; i < Routing.routes.length; i += 1) {
        var pathSplit = Routing.routes[i].path.split('/');
        if (clearedPath.length >= pathSplit.length) {
          var equal = Routing.equalRoutePath(clearedPath, pathSplit);
          if (equal != null && (Routing.routes[i].exact === false || equal.exact)) {
            var route = Routing.routes[i];
            if (route.beforeRoute != null) {
              var beforeRoute = route.beforeRoute();
              if (beforeRoute != null) {
                return {
                  path: Routing.routes[i].path,
                  component: beforeRoute,
                  pathvars: equal.pathvars
                };
              }
            }
            return {
              path: Routing.routes[i].path,
              component: Routing.routes[i].component,
              pathvars: equal.pathvars
            };
          }
        }
      }
      return undefined;
    }
  }, {
    key: "equalRoutePath",
    value: function equalRoutePath(path, route) {
      var pathvars;
      for (var i = 0; i < path.length; i += 1) {
        if (route.length > i) {
          var match = Routing.matchPathPart(path[i], route[i]);
          if (!match.match) {
            return undefined;
          }
          if (pathvars == null) {
            pathvars = {};
          }
          if (match.type != null && match.type === 'var') {
            pathvars[route[i].substr(1)] = path[i];
          }
        } else {
          if (pathvars != null) {
            return {
              pathvars: pathvars,
              exact: false
            };
          }
          return undefined;
        }
      }
      if (pathvars != null) {
        return {
          pathvars: pathvars,
          exact: true
        };
      }
      return undefined;
    }
  }, {
    key: "matchPathPart",
    value: function matchPathPart(path, route) {
      if (route === path) {
        return {
          match: true,
          type: 'value'
        };
      }
      if (route.length > 1 && route.substr(0, 1) === ':' && path.length > 0) {
        return {
          match: true,
          type: 'var'
        };
      }
      return {
        match: false
      };
    }
  }, {
    key: "removeFirstAndLastEmptyPath",
    value: function removeFirstAndLastEmptyPath(path) {
      var pathSplit = path;
      if (pathSplit[0] === '' && pathSplit.length > 1) {
        pathSplit.shift();
      }
      if (pathSplit[pathSplit.length - 1] === '' && pathSplit.length > 1) {
        pathSplit.pop();
      }
      return pathSplit;
    }
  }]);
}();
Routing.history = [];
Routing._dialogs = [];
Routing._routes = [];
Routing.initialIndex = 0;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportSettings = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var ReportSettingsEntity_1 = require("./entities/ReportSettingsEntity");
var ReportSettings = function (_ReadModelClientOffli) {
  function ReportSettings() {
    var _this;
    (0, _classCallCheck2.default)(this, ReportSettings);
    _this = _callSuper(this, ReportSettings, arguments);
    _this.exampleEntity = new ReportSettingsEntity_1.ReportSettingsEntity();
    _this.onEvents = [{
      eventName: 'ReportSettingsSaved',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(ReportSettings, _ReadModelClientOffli);
  return (0, _createClass2.default)(ReportSettings);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.ReportSettings = ReportSettings;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponsiveColsList = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ResponsiveColsList = exports.ResponsiveColsList = function (_PureComponent) {
  function ResponsiveColsList(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ResponsiveColsList);
    _this = _callSuper(this, ResponsiveColsList, [props]);
    _this.startMove = 0;
    _this.animationRunning = false;
    _this.onPressCircle = function (pressed) {
      return function (_e) {
        var _this$state = _this.state,
          tabPositions = _this$state.tabPositions,
          viewWidth = _this$state.viewWidth,
          tabWidth = _this$state.tabWidth;
        var visibleCols = Math.max(1, Math.floor(viewWidth / tabWidth));
        var i = Math.min(pressed, tabPositions.length - visibleCols);
        var newContentPosition = tabPositions[i];
        _this.animateToPosition(newContentPosition * -1, function () {
          _this.setState({
            IsSwiping: false,
            contentPosition: newContentPosition * -1
          }, function () {
            if (_this.props.changedSelection) _this.props.changedSelection(tabPositions.indexOf(newContentPosition));
          });
        });
      };
    };
    _this.state = {
      selected: 0,
      contentPosition: 0,
      contentPositionAnimated: new _reactNativeWeb.Animated.Value(0),
      initialMoveContentPosition: 0,
      tabWidth: 0,
      viewWidth: 0,
      contentWidth: 1000,
      overlappingWidth: 0,
      tabPositions: [],
      IsSwiping: false
    };
    _this.createPanResponder();
    return _this;
  }
  (0, _inherits2.default)(ResponsiveColsList, _PureComponent);
  return (0, _createClass2.default)(ResponsiveColsList, [{
    key: "animateToPosition",
    value: function animateToPosition(position, callBack) {
      var _this2 = this;
      var duration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 375;
      if (!this.animationRunning) {
        this.animationRunning = true;
        var _this$state2 = this.state,
          contentPositionAnimated = _this$state2.contentPositionAnimated,
          contentPosition = _this$state2.contentPosition;
        if (Number.isNaN(position) || position === contentPosition && contentPositionAnimated === new _reactNativeWeb.Animated.Value(position)) {
          return;
        }
        _reactNativeWeb.Animated.timing(contentPositionAnimated, {
          duration: duration,
          useNativeDriver: _reactNativeWeb.Platform.OS !== 'web',
          toValue: position
        }).start(function () {
          _this2.animationRunning = false;
          if (callBack != null) {
            callBack();
          }
          _this2.setState({
            contentPosition: position
          });
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        width = _this$props.width,
        height = _this$props.height,
        showButtons = _this$props.showButtons;
      var _this$state3 = this.state,
        contentPositionAnimated = _this$state3.contentPositionAnimated,
        overlappingWidth = _this$state3.overlappingWidth,
        contentWidth = _this$state3.contentWidth;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: width,
          height: height,
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: 'transparent'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, Object.assign({}, this._panResponder.panHandlers, {
          style: Object.assign({
            width: width,
            height: height == null ? 'auto' : height + (showButtons ? 0 : _reactNativeWeb.Platform.OS === 'web' ? 36 : 18),
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'transparent'
          }, _ThemeManager.ThemeManager.noSelectionWebStyle()),
          children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.Animated.View, {
            pointerEvents: "auto",
            style: {
              height: height,
              position: 'absolute',
              width: contentWidth,
              top: 0,
              left: 0,
              zIndex: 500,
              flexDirection: 'row',
              transform: [{
                translateX: contentPositionAnimated
              }]
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: overlappingWidth,
                overflow: 'hidden',
                backgroundColor: 'transparent'
              }
            }, "contentElementOLeft"), this.renderContents(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: overlappingWidth,
                overflow: 'hidden',
                backgroundColor: 'transparent'
              }
            }, "contentElementORight")]
          })
        })), this.renderButtons()]
      });
    }
  }, {
    key: "renderButtons",
    value: function renderButtons() {
      var _this3 = this;
      var _this$props2 = this.props,
        showButtons = _this$props2.showButtons,
        contents = _this$props2.contents;
      var _this$state4 = this.state,
        contentPosition = _this$state4.contentPosition,
        tabPositions = _this$state4.tabPositions,
        viewWidth = _this$state4.viewWidth,
        tabWidth = _this$state4.tabWidth,
        contentWidth = _this$state4.contentWidth;
      var visibleCols = Math.max(1, Math.floor(viewWidth / tabWidth));
      var firstColVisible = tabPositions.indexOf(contentPosition * -1);
      if (!showButtons || viewWidth >= contentWidth || contents.length === 0) return null;
      var buttonSize = _reactNativeWeb.Platform.OS === 'web' ? 24 : 12;
      var icons = [];
      contents.forEach(function (_a, i) {
        var k = `icon_${i}`;
        icons.push((0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: "",
          icon: "circle",
          color: firstColVisible <= i && firstColVisible + visibleCols - 1 >= i ? _ThemeManager.ThemeManager.style.brandPrimary : _ThemeManager.ThemeManager.style.disabledColor,
          outerSize: buttonSize * 1.5,
          iconSize: buttonSize,
          onPress: _reactNativeWeb.Platform.OS !== 'web' && firstColVisible <= i && firstColVisible + visibleCols - 1 >= i ? undefined : _this3.onPressCircle(i)
        }, k));
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          bottom: 4,
          left: 0,
          right: 0,
          flexDirection: 'row',
          justifyContent: 'center'
        },
        children: icons
      });
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, _prevState, _snapshot) {
      if (this.props.currentIndex != null && _prevProps.currentIndex !== this.props.currentIndex) {
        this.onPressCircle(this.props.currentIndex)();
      }
    }
  }, {
    key: "renderContents",
    value: function renderContents() {
      var contents = this.props.contents;
      var tabWidth = this.state.tabWidth;
      var renderedContents = [];
      for (var i = 0; i < contents.length; i += 1) {
        renderedContents.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: tabWidth,
            overflow: 'hidden'
          },
          children: contents[i]
        }, `contentElement${i}`));
      }
      return renderedContents;
    }
  }, {
    key: "createPanResponder",
    value: function createPanResponder() {
      var _this4 = this;
      this._panResponder = _reactNativeWeb.PanResponder.create({
        onStartShouldSetPanResponder: function onStartShouldSetPanResponder(_evt, _gestureState) {
          return false;
        },
        onStartShouldSetPanResponderCapture: function onStartShouldSetPanResponderCapture(_evt, _gestureState) {
          _this4.startMoveX = _gestureState.dx;
          return false;
        },
        onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder(_evt, _gestureState) {
          var diff = Math.abs(_gestureState.dx - _this4.startMoveX);
          if (diff > _ThemeManager.ThemeManager.style.minSwipeValue) {
            return true;
          }
          return false;
        },
        onMoveShouldSetPanResponderCapture: function onMoveShouldSetPanResponderCapture(_evt, _gestureState) {
          return false;
        },
        onPanResponderGrant: function onPanResponderGrant(_evt, _gestureState) {
          var contentPosition = _this4.state.contentPosition;
          _this4.startMove = Date.now();
          _this4.setState({
            initialMoveContentPosition: contentPosition,
            IsSwiping: true
          });
          return false;
        },
        onPanResponderMove: function onPanResponderMove(_evt, _gestureState) {
          var initialMoveContentPosition = _this4.state.initialMoveContentPosition;
          if (_this4.startMove != null) {
            var contentPosition = _this4.getLimitContentPosition(initialMoveContentPosition + _gestureState.dx);
            _this4.setState({
              contentPosition: contentPosition,
              contentPositionAnimated: new _reactNativeWeb.Animated.Value(contentPosition)
            });
          }
          return true;
        },
        onPanResponderTerminationRequest: function onPanResponderTerminationRequest(_evt, _gestureState) {
          return true;
        },
        onPanResponderRelease: function onPanResponderRelease(_evt, _gestureState) {
          var shortSwipe = _this4.props.shortSwipe;
          if (shortSwipe === true) {
            var _this4$state = _this4.state,
              tabWidth = _this4$state.tabWidth,
              contentPosition = _this4$state.contentPosition,
              tabPositions = _this4$state.tabPositions;
            var newContentPosition = Math.abs(contentPosition);
            var halfTabWidth = tabWidth / 2;
            var swipeDuration = Date.now() - _this4.startMove;
            var hasShortSwiped = swipeDuration < 150 && Math.abs(_gestureState.dx) > _ThemeManager.ThemeManager.style.minSwipeValue && Math.abs(_gestureState.dx) < halfTabWidth;
            if (hasShortSwiped) {
              var direction = _gestureState.dx < 0 ? 1 : -1;
              newContentPosition = ResponsiveColsList.getClosestNumberInArray(tabPositions, newContentPosition + direction * tabWidth);
            }
            var snapInSections = _this4.props.snapInSections;
            if (!hasShortSwiped && snapInSections === true) {
              var closest = ResponsiveColsList.getClosestNumberInArray(tabPositions, Math.abs(contentPosition));
              if (Math.abs(newContentPosition) !== closest) {
                newContentPosition = closest;
              }
            }
            _this4.animateToPosition(newContentPosition * -1, function () {
              _this4.setState({
                IsSwiping: false,
                contentPosition: newContentPosition * -1
              }, function () {
                if (_this4.props.changedSelection) _this4.props.changedSelection(tabPositions.indexOf(newContentPosition));
              });
            });
          } else {
            _this4.setState({
              IsSwiping: false
            });
          }
          return true;
        },
        onPanResponderTerminate: function onPanResponderTerminate(_evt, _gestureState) {},
        onShouldBlockNativeResponder: function onShouldBlockNativeResponder(_evt, _gestureState) {
          return true;
        }
      });
    }
  }, {
    key: "getLimitContentPosition",
    value: function getLimitContentPosition(contentPosition) {
      return Math.min(Math.max(contentPosition, this.getMinContentPosition()), this.getMaxContentPosition());
    }
  }, {
    key: "getMaxContentPosition",
    value: function getMaxContentPosition() {
      return 0;
    }
  }, {
    key: "getMinContentPosition",
    value: function getMinContentPosition() {
      var _this$state5 = this.state,
        viewWidth = _this$state5.viewWidth,
        contentWidth = _this$state5.contentWidth;
      return (contentWidth - viewWidth) * -1;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        contents: [],
        snapInSections: true,
        width: 200,
        height: 200,
        overlappingWidth: 24,
        targetWidthPerSection: 100,
        shortSwipe: true,
        changeSelection: function changeSelection() {}
      };
    }
  }, {
    key: "calculateTabWidthAndOverlapping",
    value: function calculateTabWidthAndOverlapping(props) {
      var calculated = {
        tabWidth: props.targetWidthPerSection,
        overlappingWidth: 0
      };
      var possibleVisible = props.width / props.targetWidthPerSection;
      if (possibleVisible >= props.contents.length) {
        calculated.tabWidth = props.width / props.contents.length;
      } else if (possibleVisible <= 1) {
        calculated.tabWidth = props.width;
        if (props.overlappingWidth != null && props.overlappingWidth > 0) {
          calculated.tabWidth -= props.overlappingWidth * 2;
          calculated.overlappingWidth = props.overlappingWidth;
        }
      } else {
        var possibleVisibleFloored = Math.floor(possibleVisible);
        var targetWidthPerSection = props.width / possibleVisibleFloored;
        if (props.overlappingWidth != null && props.overlappingWidth > 0) {
          targetWidthPerSection -= props.overlappingWidth * 2 / possibleVisibleFloored;
          calculated.overlappingWidth = props.overlappingWidth;
        }
        calculated.tabWidth = targetWidthPerSection;
      }
      if (Number.isNaN(calculated.tabWidth) || !Number.isFinite(calculated.tabWidth)) calculated.tabWidth = _ResizeEvent.ResizeEvent.current.contentWidth;
      return calculated;
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(_nextProps, _prevState) {
      var newState = Object.assign({}, _prevState, ResponsiveColsList.calculateTabWidthAndOverlapping(_nextProps));
      newState.viewWidth = _nextProps.width;
      newState.contentWidth = newState.tabWidth * _nextProps.contents.length + 2 * newState.overlappingWidth;
      newState.tabPositions = ResponsiveColsList.getTabPositions(_nextProps.contents.length, newState.tabWidth);
      if (!Number.isNaN(newState.viewWidth) && !Number.isNaN(newState.contentWidth) && !Number.isNaN(newState.contentPosition) && !Number.isNaN(newState.tabWidth)) {
        var max = 0;
        while (newState.viewWidth - newState.contentWidth - newState.contentPosition > 0 && max < 10) {
          newState.contentPosition += newState.tabWidth;
          max += 1;
        }
      }
      return newState;
    }
  }, {
    key: "getClosestNumberInArray",
    value: function getClosestNumberInArray(array, num) {
      var minDiff = 1000;
      var ans;
      for (var i in array) {
        var m = Math.abs(num - array[i]);
        if (m < minDiff) {
          minDiff = m;
          ans = array[i];
        }
      }
      return ans;
    }
  }, {
    key: "getTabPositions",
    value: function getTabPositions(tabs, tabwidth) {
      var tabPositions = [];
      for (var i = 0; i < tabs; i += 1) {
        tabPositions.push(i * tabwidth);
      }
      return tabPositions;
    }
  }]);
}(_react.PureComponent);
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { sortQuickChips } from 'materialTheme/src/theme/components/datepickerv2/DatepickerTypes';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateRangeInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateRangeInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ChangeAbsence } from 'upmesh-core/src/client/commands/companies/absence/ChangeAbsence';
import { CreateAbsence } from 'upmesh-core/src/client/commands/companies/absence/CreateAbsence';
import { AbsenceEntity } from 'upmesh-core/src/client/query/entities/AbsenceEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { Collaborator } from '../companies/Collaborator';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
function absenceText(change) {
    return {
        title: !change ? I18n.m.getMessage('absenceDialogTitleAdd') : I18n.m.getMessage('absenceDialogTitleChange'),
        button: !change ? I18n.m.getMessage('add') : I18n.m.getMessage('save'),
    };
}
const createEntries = async (absence, memberIds) => {
    for (const mId of memberIds) {
        try {
            const c = new CreateAbsence({
                companyId: CompanyUserInfo.company.id,
                memberId: mId,
                starts: absence.starts,
                ends: absence.ends,
                note: absence.note,
                taskId: absence.taskId,
                costCenterId: absence.costCenterId,
                projectId: absence.projectId,
                state: absence.state,
            });
            await c.execute(AuthClient.instance?.commandStore);
        }
        catch (err) {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        }
    }
};
const changeEntry = async (org, absence) => {
    try {
        const c = new ChangeAbsence({
            starts: org.starts !== absence.starts ? absence.starts : undefined,
            ends: org.ends !== absence.ends ? absence.ends : undefined,
            note: org.note !== absence.note ? absence.note : undefined,
            taskId: org.taskId !== absence.taskId ? absence.taskId : undefined,
            costCenterId: org.costCenterId !== absence.costCenterId ? absence.costCenterId : undefined,
            projectId: org.projectId !== absence.projectId ? absence.projectId : undefined,
            state: org.state !== absence.state ? absence.state : undefined,
        }, org.id);
        await c.execute(AuthClient.instance?.commandStore);
    }
    catch (err) {
        DefaultErrorHandler.showDefaultErrorAlert(err);
    }
};
const checkEntries = async (absence, mId, newOne) => {
    try {
        const a = {
            companyId: CompanyUserInfo.company.id,
            memberId: mId,
            starts: absence.starts,
            ends: absence.ends,
            note: absence.note,
            taskId: absence.taskId,
            costCenterId: absence.costCenterId,
            projectId: absence.projectId,
            state: absence.state,
        };
        const c = newOne ? new CreateAbsence(a) : new ChangeAbsence(a, absence.id);
        await c.canI();
        await c.validate();
        return [];
    }
    catch (err) {
        return err;
    }
};
const getFormData = async (changeDialog, _absence, memberIds) => {
    const endTimeQuickSelection = [{ name: '23:59', time: { hour: 23, minute: 59 } }];
    const startTimeQuickSelection = [{ name: '0:00', time: { hour: 0, minute: 0 } }];
    if (CompanyUserInfo.me != null && CompanyUserInfo.me.workingTimeModelId != null) {
        try {
            const workingTimeModel = await UpmeshClient.instance.modals.workingTimeModel.getById(CompanyUserInfo.me.workingTimeModelId);
            workingTimeModel.sections.forEach((s) => {
                for (const k in s.workingTime) {
                    if (s.workingTime[k]['start'] && s.workingTime[k]['start']['hours']) {
                        const d = s.workingTime[k];
                        const item = {
                            name: `${d.start.hours}:${d.start.minutes > 9 ? d.start.minutes : `0${d.start.minutes}`}`,
                            time: { hour: d.start.hours, minute: d.start.minutes },
                        };
                        if (startTimeQuickSelection.findIndex((i) => i.time != null && i.time.hour === item.time.hour && i.time.minute === item.time.minute) === -1)
                            startTimeQuickSelection.push(item);
                    }
                    if (s.workingTime[k]['stop'] && s.workingTime[k]['stop']['hours']) {
                        const d = s.workingTime[k];
                        const item = {
                            name: `${d.stop.hours}:${d.stop.minutes > 9 ? d.stop.minutes : `0${d.stop.minutes}`}`,
                            time: { hour: d.stop.hours, minute: d.stop.minutes },
                        };
                        if (endTimeQuickSelection.findIndex((i) => i.time != null && i.time.hour === item.time.hour && i.time.minute === item.time.minute) === -1)
                            endTimeQuickSelection.push(item);
                    }
                }
            });
            startTimeQuickSelection.sort(sortQuickChips);
            endTimeQuickSelection.sort(sortQuickChips);
        }
        catch (e) {
            console.debug('cant get working Time Model for user', e);
        }
    }
    const tasks = await UpmeshClient.instance.modals.task.get({ filter: `deleted ne true and active ne false and productive ne true`, orderby: 'mark' }, CurrentUser.userId);
    const taskList = tasks.map((ta) => ({
        title: `${ta.mark} - ${ta.description}`,
        onPressChipData: ta.id,
    }));
    let collaborators;
    let isPayroll = !!CompanyUserInfo.me?.payroll;
    if (changeDialog && memberIds != null && memberIds.length === 1) {
        const member = await UpmeshClient.instance.modals.companyMember.getById(memberIds[0]);
        if (!isPayroll &&
            CompanyUserInfo.me?.canPermitAbsenceFor === 'group' &&
            member.id !== CompanyUserInfo.me?.id &&
            member.groupId === CompanyUserInfo.me?.groupId) {
            isPayroll = true;
        }
        let item = {
            title: `${member.firstName} ${member.lastName}`,
            id: memberIds[0],
            groupId: member.groupId,
        };
        if (member.userId != null && member.userId.length > 0) {
            const u = await AuthClient.instance.modals.user.getById(member.userId);
            item = {
                title: u.getFullName(),
                thumbnail: <UserImage user={u} size={24}/>,
                id: memberIds[0],
                groupId: member.groupId,
            };
        }
        collaborators = { selectedCollaborators: [item], collaboratorList: [item], collaboratorGroups: [] };
    }
    else {
        collaborators = await Collaborator.getCollaboratorList('lastAbsenceCollaborators', 'absenceFor', memberIds);
    }
    const costCenterList = [
        { title: I18n.m.getMessage('pleaseSelect'), onPressChipData: '' },
    ];
    const projectList = new Map();
    if (isPayroll) {
        const costCenters = await UpmeshClient.instance.modals.costCenter.get({
            filter: `companyId eq '${CompanyUserInfo.me?.companyId}' and deleted ne true and active ne false`,
            orderby: 'mark',
        });
        const promises = [];
        for (const ta of costCenters) {
            if ((ta.costType === 'costUnit' && ta.projectIds?.length === 1) || ta.costType === 'costCenter') {
                costCenterList.push({ title: `${ta.mark} - ${ta.description}`, onPressChipData: ta.id });
                if (ta.projectIds?.length === 1) {
                    try {
                        const project = await UpmeshClient.instance.modals.companyProject.getById(ta.projectIds[0]);
                        projectList.set(ta.id, [{ title: project.title, onPressChipData: project.id }]);
                    }
                    catch (e) {
                        console.debug('project not found anymore?', e);
                    }
                }
            }
            else if (ta.costType === 'costUnit' && ta.projectIds != null && ta.projectIds?.length > 1) {
                costCenterList.push({
                    title: `${ta.mark} - ${ta.description} (${ta.projectIds?.length})`,
                    onPressChipData: ta.id,
                });
                promises.push(new Promise((resolve) => {
                    UpmeshClient.instance.modals.companyProject
                        .get({ filter: `id in ${JSON.stringify(ta.projectIds)}`, orderby: 'title' })
                        .then((pr) => {
                        const projects = [];
                        pr.forEach((p) => {
                            projects.push({ title: p.title, onPressChipData: p.id });
                        });
                        resolve({ projects, costCenter: ta.id });
                    })
                        .catch((err) => console.error(err));
                }));
            }
        }
        const projects = await Promise.all(promises);
        projects.forEach((a) => {
            projectList.set(a.costCenter, a.projects);
        });
    }
    return {
        taskList,
        isPayroll,
        startTimeQuickSelection,
        endTimeQuickSelection,
        collaboratorList: collaborators.collaboratorList,
        selectedCollaborators: collaborators.selectedCollaborators,
        collaboratorGroups: collaborators.collaboratorGroups,
        costCenterList,
        projectList,
    };
};
const styles = StyleSheet.create({
    container: { width: '100%', height: '100%' },
    chipContainer: { flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingBottom: 16 },
    collaboratorContainer: { width: '100%', paddingBottom: 16 },
});
let selectedCollaboratorsTimeOut;
export function AbsenceCreationChangeDialog(props) {
    const changeDialog = props.absence != null;
    const [currentAbsence, setCurrentAbsence] = useState(props.absence ? new AbsenceEntity(props.absence) : new AbsenceEntity());
    const [memberIds, setMemberIds] = useState(props.absence ? [props.absence.memberId] : undefined);
    const [currentMemberIds, setCurrentMemberIds] = useState(props.absence ? [props.absence.memberId] : []);
    const [text, setText] = useState(absenceText(changeDialog));
    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    useEffect(() => {
        let isMounted = true;
        getFormData(changeDialog, currentAbsence, memberIds)
            .then((newFormData) => {
            if (isMounted) {
                setFormData(newFormData);
                if (props.absence == null && currentAbsence.taskId == null && newFormData.taskList.length > 0) {
                    setCurrentAbsence(new AbsenceEntity({
                        ...currentAbsence,
                        taskId: newFormData.taskList[0].onPressChipData,
                    }));
                }
                if (props.absence == null) {
                    const mIds = [];
                    newFormData.selectedCollaborators.forEach((f) => {
                        if (f.id != null)
                            mIds.push(f.id);
                    });
                    setCurrentMemberIds(mIds);
                }
            }
        })
            .catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
        return () => {
            isMounted = false;
        };
    }, [props.absence, memberIds]);
    useEffect(() => {
        if (formData == null)
            return;
        if (formData.selectedCollaborators.length > 0) {
            checkEntries(currentAbsence, formData.selectedCollaborators[0].id, props.absence == null)
                .then((e) => {
                setErrors(e);
            })
                .catch((err) => {
                setErrors(err);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
        else {
            setErrors([
                {
                    message: 'Ein Benutzer wird benötigt',
                    messageCode: 'required',
                    key: 'memberId',
                },
            ]);
        }
    }, [currentAbsence, formData?.selectedCollaborators]);
    useEffect(() => {
        setText(absenceText(props.absence != null));
    }, [props.absence]);
    useEffect(() => {
        if (changeDialog)
            return;
        if (selectedCollaboratorsTimeOut != null)
            clearTimeout(selectedCollaboratorsTimeOut);
        selectedCollaboratorsTimeOut = setTimeout(() => {
            if (formData != null) {
                SimpleStorage.set('lastAbsenceCollaborators', JSON.stringify(formData.selectedCollaborators));
            }
        }, 300);
    }, [formData?.selectedCollaborators]);
    const onSave = (e) => {
        setIsLoading(true);
        if (props.absence) {
            changeEntry(props.absence, currentAbsence)
                .then(() => {
                Dialog.instance?.close(e);
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
                setIsLoading(false);
            });
        }
        else {
            createEntries(currentAbsence, currentMemberIds)
                .then(() => {
                Dialog.instance?.close(e);
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
                setIsLoading(false);
            });
        }
    };
    let startsError;
    let endsError;
    let rangeError;
    let costCenterError;
    let taskError;
    let projectError;
    if (Array.isArray(errors)) {
        const startsErrorIndex = errors?.findIndex((e) => e.key === 'starts');
        startsError =
            startsErrorIndex >= 0 ? DefaultErrorHandler.getDefaultErrorMessages(errors[startsErrorIndex], I18n.m) : undefined;
        const endsErrorIndex = errors?.findIndex((e) => e.key === 'ends');
        endsError =
            endsErrorIndex >= 0 ? DefaultErrorHandler.getDefaultErrorMessages(errors[endsErrorIndex], I18n.m) : undefined;
        const costCenterErrorIndex = errors?.findIndex((e) => e.key === 'costCenterId');
        costCenterError =
            costCenterErrorIndex >= 0
                ? DefaultErrorHandler.getDefaultErrorMessages(errors[costCenterErrorIndex], I18n.m)
                : undefined;
        const projectErrorIndex = errors?.findIndex((e) => e.key === 'projectId');
        projectError =
            projectErrorIndex >= 0
                ? DefaultErrorHandler.getDefaultErrorMessages(errors[projectErrorIndex], I18n.m)
                : undefined;
        const taskErrorIndex = errors?.findIndex((e) => e.key === 'taskId');
        taskError =
            taskErrorIndex >= 0 ? DefaultErrorHandler.getDefaultErrorMessages(errors[taskErrorIndex], I18n.m) : undefined;
        if (startsError != null || endsError != null) {
            rangeError = startsError;
            if (endsError !== startsError)
                rangeError = endsError;
        }
    }
    return (<View style={styles.container}>
      <DialogTitle key="title">{text.title}</DialogTitle>
      <DialogContent key="content">
        {formData == null || isLoading ? (<Spinner />) : (<View>
            <View style={styles.chipContainer}>
              <Chip backgroundColor={currentAbsence.state === 'open' ? ThemeManager.style.brandPrimary : undefined} textColor={currentAbsence.state === 'open' ? '#FFFFFF' : undefined} thumbnail={<Icon icon="circle-outline" toolTip="" color={currentAbsence.state === 'open' ? '#FFFFFF' : undefined}/>} title={I18n.m.getMessage('absenceStateOpen')} onPressChip={!formData.isPayroll || currentAbsence.state === 'open'
                ? undefined
                : (_e) => {
                    if (currentAbsence.state !== 'open') {
                        const n = new AbsenceEntity({ ...currentAbsence, state: 'open' });
                        setCurrentAbsence(n);
                    }
                }}/>
              <Chip backgroundColor={currentAbsence.state === 'rejected' ? ThemeManager.style.brandDanger : undefined} textColor={currentAbsence.state === 'rejected' ? '#FFFFFF' : undefined} thumbnail={<Icon icon="close" toolTip="" color={currentAbsence.state === 'rejected' ? '#FFFFFF' : undefined}/>} title={I18n.m.getMessage('absenceStateRejected')} onPressChip={!formData.isPayroll || currentAbsence.state === 'rejected'
                ? undefined
                : (_e) => {
                    if (currentAbsence.state !== 'rejected') {
                        const n = new AbsenceEntity({ ...currentAbsence, state: 'rejected' });
                        setCurrentAbsence(n);
                    }
                }}/>
              <Chip backgroundColor={currentAbsence.state === 'approved' ? ThemeManager.style.brandSuccess : undefined} textColor={currentAbsence.state === 'approved' ? '#FFFFFF' : undefined} thumbnail={<Icon icon="check" toolTip="" color={currentAbsence.state === 'approved' ? '#FFFFFF' : undefined}/>} title={I18n.m.getMessage('absenceStateApproved')} onPressChip={!formData.isPayroll || currentAbsence.state === 'approved'
                ? undefined
                : (_e) => {
                    if (currentAbsence.state !== 'approved') {
                        const n = new AbsenceEntity({ ...currentAbsence, state: 'approved' });
                        setCurrentAbsence(n);
                    }
                }}/>
            </View>
            {formData.collaboratorList.length === 0 ? null : (<View style={styles.collaboratorContainer}>
                <ChipGroup editable={props.absence == null || formData.collaboratorList.length !== 1} key={`${JSON.stringify(formData.selectedCollaborators)}_${JSON.stringify(formData.collaboratorList)}`} chips={formData.selectedCollaborators} availableChips={formData.collaboratorList} label={I18n.m.getMessage('collaborator')} sortFormByGroup chipGroups={formData.collaboratorGroups} onChanged={(chips) => {
                    const mIds = [];
                    chips.forEach((e) => {
                        if (e.id != null)
                            mIds.push(e.id);
                    });
                    setMemberIds(mIds);
                }}/>
              </View>)}

            {!formData.isPayroll || formData.costCenterList.length <= 1 ? null : (<OpenableChip looksLikeFI disableAutoSort formInputLabel={`${I18n.m.getMessage('costCenter')} / ${I18n.m.getMessage('costUnit')}`} formInputError={costCenterError != null} formInputHelperText={costCenterError} selected={currentAbsence.costCenterId == null
                    ? 0
                    : formData.costCenterList.findIndex((a) => a.onPressChipData === currentAbsence.costCenterId)} chipsList={formData.costCenterList} onPressChip={(e) => {
                    const n = new AbsenceEntity({ ...currentAbsence, costCenterId: e });
                    const pr = formData.projectList.get(e);
                    n.projectId = pr && pr.length > 0 ? pr[0].onPressChipData : '';
                    setCurrentAbsence(n);
                }}/>)}

            {!formData.isPayroll ||
                currentAbsence.costCenterId == null ||
                currentAbsence.costCenterId.length === 0 ||
                !formData.projectList.has(currentAbsence.costCenterId) ||
                formData.projectList.get(currentAbsence.costCenterId).length <= 1 ? null : (<OpenableChip looksLikeFI disableAutoSort formInputLabel={I18n.m.getMessage('project')} formInputError={projectError != null} formInputHelperText={projectError} selected={currentAbsence.projectId == null
                    ? 0
                    : formData.projectList
                        .get(currentAbsence.costCenterId)
                        ?.findIndex((a) => a.onPressChipData === currentAbsence.projectId)} chipsList={formData.projectList.get(currentAbsence.costCenterId)} onPressChip={(e) => {
                    const n = new AbsenceEntity({ ...currentAbsence, projectId: e });
                    setCurrentAbsence(n);
                }}/>)}

            <OpenableChip looksLikeFI disableAutoSort chipDisabled={!formData.isPayroll && currentAbsence.state !== 'open'} formInputLabel={I18n.m.getMessage('absenceTask')} selected={currentAbsence.taskId == null
                ? 0
                : Math.max(formData.taskList.findIndex((a) => a.onPressChipData === currentAbsence.taskId), 0)} formInputError={taskError != null} formInputHelperText={taskError} chipsList={formData.taskList} onPressChip={(e) => {
                const n = new AbsenceEntity({ ...currentAbsence, taskId: e });
                setCurrentAbsence(n);
            }}/>

            <DateRangeInputFormFilled disabled={!formData.isPayroll && currentAbsence.state !== 'open'} selectTime clearButton={false} labelText={I18n.m.getMessage('absenceRange')} startDate={currentAbsence.starts != null && currentAbsence.ends != null
                ? { from: currentAbsence.starts, to: currentAbsence.ends }
                : undefined} timeQuickSelections={formData.startTimeQuickSelection} value={currentAbsence.starts != null && currentAbsence.ends != null
                ? { from: currentAbsence.starts, to: currentAbsence.ends }
                : undefined} helperText={rangeError} error={!!rangeError} onChange={(d) => {
                if (d == null)
                    return;
                const n = new AbsenceEntity({ ...currentAbsence, starts: d.from, ends: d.to });
                setCurrentAbsence(n);
            }}/>

            <FormInputFilled labelText={I18n.m.getMessage('absenceNote')} disabled={!formData.isPayroll && currentAbsence.state !== 'open'} numberOfLines={3} initValue={currentAbsence.note} onChangeText={(d) => {
                const n = new AbsenceEntity({ ...currentAbsence, note: d });
                setCurrentAbsence(n);
            }}/>
          </View>)}
      </DialogContent>
      <DialogActions onBottom key="actions">
        <ContainedButton disabled={isLoading} key="cancel" title={I18n.m.getMessage('cancel')} onPress={(e) => {
            Dialog.instance?.close(e);
        }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton key="save" disabled={formData == null ||
            isLoading ||
            (Array.isArray(errors) && errors.length > 0) ||
            (!Array.isArray(errors) && errors['name'] != null) ||
            !(currentAbsence.state === 'open' || formData.isPayroll)} title={text.button} onPress={onSave} backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>
      </DialogActions>
    </View>);
}
export const openAbsenceCreationChangeDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        content: <AbsenceCreationChangeDialog absence={props.absence}/>,
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition,
        openImmediately: false,
        closeOnTouchOutside: false,
    });
};

import { AsyncEvent } from 'ts-events';
export class TicketNavigation {
    static setTickets(tickets) {
        TicketNavigation.currentTickets = tickets;
        TicketNavigation.onUpdate.post();
    }
    static hasTickets() {
        return TicketNavigation.currentTickets.length > 0;
    }
    static currentIndex(id) {
        const index = TicketNavigation.currentTickets.findIndex((t) => t.id === id);
        return index;
    }
    static numberOfTickets() {
        return TicketNavigation.currentTickets.length;
    }
    static hasNext(id) {
        const index = TicketNavigation.currentTickets.findIndex((t) => t.id === id);
        if (index < TicketNavigation.currentTickets.length - 1) {
            return TicketNavigation.currentTickets[index + 1].id;
        }
        return false;
    }
    static hasPrev(id) {
        const index = TicketNavigation.currentTickets.findIndex((t) => t.id === id);
        if (index > 0) {
            return TicketNavigation.currentTickets[index - 1].id;
        }
        return false;
    }
}
TicketNavigation.onUpdate = new AsyncEvent();
TicketNavigation.currentTickets = [];

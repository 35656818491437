var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlideAnimation = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ResizeEvent = require("../../ResizeEvent");
var _Animation2 = _interopRequireDefault(require("./Animation"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SlideAnimation = exports.SlideAnimation = function (_Animation) {
  function SlideAnimation() {
    var _this;
    var toValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var slideFrom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'bottom';
    var spring = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var duration = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 195;
    (0, _classCallCheck2.default)(this, SlideAnimation);
    _this = _callSuper(this, SlideAnimation, [toValue]);
    _this.duration = 195;
    _this.animations = _this.createAnimations(slideFrom);
    _this.spring = spring;
    _this.duration = duration;
    return _this;
  }
  (0, _inherits2.default)(SlideAnimation, _Animation);
  return (0, _createClass2.default)(SlideAnimation, [{
    key: "createAnimations",
    value: function createAnimations(slideFrom) {
      var transform = [];
      if (['top', 'bottom'].includes(slideFrom)) {
        if (slideFrom === 'bottom') {
          transform.push({
            translateY: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [_ResizeEvent.ResizeEvent.current.windowHeight, 1]
            })
          });
        } else {
          transform.push({
            translateY: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [-_ResizeEvent.ResizeEvent.current.windowHeight, 1]
            })
          });
        }
      } else if (['left', 'right'].includes(slideFrom)) {
        if (slideFrom === 'right') {
          transform.push({
            translateX: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [_ResizeEvent.ResizeEvent.current.windowWidth, 1]
            })
          });
        } else {
          transform.push({
            translateX: this.animate.interpolate({
              inputRange: [0, 1],
              outputRange: [-_ResizeEvent.ResizeEvent.current.windowWidth, 1]
            })
          });
        }
      }
      return {
        transform: transform,
        opacity: 1
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      if (this.spring) {
        _reactNativeWeb.Animated.timing(this.animate, {
          toValue: _toValue,
          useNativeDriver: false
        }).start(function () {
          if (onFinished != null) {
            onFinished();
          }
        });
      } else {
        _reactNativeWeb.Animated.timing(this.animate, {
          toValue: _toValue,
          duration: this.duration,
          useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
        }).start(function () {
          if (onFinished != null) {
            onFinished();
          }
        });
      }
    }
  }]);
}(_Animation2.default);
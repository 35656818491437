import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
export function ImageItemOptions(props) {
    const [folderList, setFolderList] = useState([]);
    const [folderId, setFolderId] = useState(props.initValues && props.initValues.folderId ? props.initValues.folderId : 'public');
    useEffect(() => {
        UpmeshClient.instance.modals.folder
            .get({
            filter: `projectId eq '${props.projectId}' and deleted ne true`,
        })
            .then((p) => {
            const pr = [{ title: I18n.m.getMessage('filesFolderPublic'), data: 'public' }];
            p.forEach((project) => {
                pr.push({ title: project.title, data: project.id });
            });
            setFolderList(pr);
        })
            .catch((err) => console.debug('err', err));
    }, []);
    const save = () => {
        props.onSaved({ folderId, projectId: props.projectId });
    };
    const selectedIndex = folderList.findIndex((c) => c.data === folderId);
    return (<>
      <DialogTitle>
        {props.type === 'edit'
            ? I18n.m.getMessage('widgetOptionDialogEditTitle')
            : I18n.m.getMessage('widgetOptionDialogAddTitle')}
      </DialogTitle>
      <DialogContent>
        {folderList != null && folderList.length > 0 && (<FormInputPicker labelText={I18n.m.getMessage('folder')} list={folderList} onChange={(e) => {
                setFolderId(e.value.data);
            }} selectedIndex={selectedIndex >= 0 ? selectedIndex : 0}/>)}
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="#ffffff" onPress={Dialog.instance?.close}/>
        <ContainedButton title={I18n.m.getMessage('save')} onPress={save}/>
      </DialogActions>
    </>);
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddressEntity = void 0;
var AddressEntity = function () {
  function AddressEntity(obj) {
    (0, _classCallCheck2.default)(this, AddressEntity);
    this.latitudeDelta = 1;
    this.longitudeDelta = 1;
    if (obj != null) {
      this.fill(obj);
    }
  }
  return (0, _createClass2.default)(AddressEntity, [{
    key: "fill",
    value: function fill(obj) {
      this.building = obj['building'] != null ? obj['building'] : '';
      this.houseNumber = obj['house_number'] != null ? obj['house_number'] : '';
      if (obj['houseNumber'] != null) {
        this.houseNumber = obj['houseNumber'];
      }
      this.road = obj['road'] != null ? obj['road'] : '';
      this.suburb = obj['suburb'] != null ? obj['suburb'] : '';
      this.city = obj['city'] != null ? obj['city'] : '';
      this.town = obj['town'] != null ? obj['town'] : '';
      if (this.city == null || this.city.length === 0) {
        this.city = this.town;
      }
      this.county = obj['county'] != null ? obj['county'] : '';
      this.state = obj['state'] != null ? obj['state'] : '';
      this.postcode = obj['postcode'] != null ? obj['postcode'] : '';
      this.country = obj['country'] != null ? obj['country'] : '';
      this.countryCode = obj['country_code'] != null ? obj['country_code'] : '';
      if (obj['countryCode'] != null) {
        this.countryCode = obj['countryCode'];
      }
      if (obj['boundingbox'] != null) {
        this.latitude = (parseFloat(obj['boundingbox'][0]) + parseFloat(obj['boundingbox'][1])) / 2;
        this.longitude = (parseFloat(obj['boundingbox'][2]) + parseFloat(obj['boundingbox'][3])) / 2;
        this.latitudeDelta = parseFloat(obj['boundingbox'][1]) - parseFloat(obj['boundingbox'][0]);
        this.longitudeDelta = parseFloat(obj['boundingbox'][3]) - parseFloat(obj['boundingbox'][2]);
      }
      this.latitude = obj['lat'] != null ? parseFloat(obj['lat']) : 0;
      if (obj['latitude'] != null) {
        this.latitude = parseFloat(obj['latitude']);
      }
      this.longitude = obj['lon'] != null ? parseFloat(obj['lon']) : 0;
      if (obj['longitude'] != null) {
        this.longitude = parseFloat(obj['longitude']);
      }
      this.latitudeDelta = obj['latitudeDelta'] != null ? parseFloat(obj['latitudeDelta']) : 0.01;
      this.longitudeDelta = obj['longitudeDelta'] != null ? parseFloat(obj['longitudeDelta']) : 0.01;
      this.contactName = obj['name'] != null ? obj['name'] : '';
      if (obj['contactName'] != null) {
        this.contactName = obj['contactName'];
      }
      this.companyName = obj['companyName'] != null ? obj['companyName'] : '';
      this.phone = obj['phone'] != null ? obj['phone'] : '';
      this.email = obj['email'] != null ? obj['email'] : '';
      this.contactFunction = obj['contactFunction'] != null ? obj['contactFunction'] : '';
      this.addressInput = obj['addressInput'] != null ? obj['addressInput'] : '';
    }
  }, {
    key: "bound",
    get: function get() {
      return [this.latitude - this.latitudeDelta / 2, this.latitude + this.latitudeDelta / 2, this.longitude - this.longitudeDelta / 2, this.longitude + this.longitudeDelta / 2];
    }
  }], [{
    key: "createAddressString",
    value: function createAddressString(address) {
      var addStrArray = [];
      if (address == null) return '';
      if (address.building.length > 0) {
        addStrArray.push(address.building);
      }
      if (address.road.length > 0 && address.houseNumber.length > 0) {
        addStrArray.push(`${address.road} ${address.houseNumber}`);
      } else if (address.road.length > 0) {
        addStrArray.push(`${address.road}`);
      }
      if (address.postcode.length > 0 && address.city.length > 0) {
        addStrArray.push(`${address.postcode} ${address.city}`);
      } else if (address.postcode.length > 0) {
        addStrArray.push(`${address.postcode}`);
      } else if (address.city.length > 0) {
        addStrArray.push(`${address.city}`);
      }
      if (address.country.length > 0) {
        addStrArray.push(`${address.country}`);
      }
      return addStrArray.join(', ');
    }
  }]);
}();
exports.AddressEntity = AddressEntity;
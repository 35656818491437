"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUPPORTED_EXPORTS = void 0;
exports.SUPPORTED_EXPORTS = {
  csv: {
    titleKey: 'timeTrackingExportOptionsCSV',
    short: 'csv',
    additionalData: null
  },
  brz: {
    titleKey: 'timeTrackingExportOptionsBRZ',
    short: 'brz',
    additionalData: {
      brzCompanyNumber: ''
    }
  },
  addison: {
    titleKey: 'timeTrackingExportOptionsAddison',
    short: 'addison',
    additionalData: {
      addisonCompanyNumber: ''
    }
  },
  gdi: {
    titleKey: 'timeTrackingExportOptionsGDI',
    short: 'gdi',
    additionalData: null,
    inlcudedAbsences: []
  },
  novaline: {
    titleKey: 'timeTrackingExportOptionsNovaline',
    short: 'novaline',
    additionalData: {
      novalineMandantNr: '',
      novalineKundenNr: '',
      novalineCostUnitAsCostCenter: false
    }
  },
  datevLodas: {
    titleKey: 'timeTrackingExportOptionsDatev',
    short: 'lodas',
    additionalData: {
      datevMandantNr: '',
      datevBeraterNr: ''
    }
  },
  datev: {
    titleKey: 'timeTrackingExportOptionsLohnUndGehalt',
    short: 'datev',
    additionalData: {
      datevBeraterNr: '',
      datevMandantNr: '',
      markAsCompanyPersNumber: false
    }
  }
};
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MoveAnimated = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _BaseAnimated2 = require("./BaseAnimated");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MoveAnimated = exports.MoveAnimated = function (_BaseAnimated) {
  function MoveAnimated(props) {
    var _this;
    (0, _classCallCheck2.default)(this, MoveAnimated);
    _this = _callSuper(this, MoveAnimated, [props]);
    _this.state = Object.assign({}, _this.state, {
      from: props.from,
      to: props.to
    });
    return _this;
  }
  (0, _inherits2.default)(MoveAnimated, _BaseAnimated);
  return (0, _createClass2.default)(MoveAnimated, [{
    key: "getAnimated",
    value: function getAnimated() {
      var translateX = this.animationState.interpolate({
        inputRange: [0, 1],
        outputRange: this.props.inverted === true ? [this.state.to.x, this.state.from.x] : [this.state.from.x, this.state.to.x]
      });
      var translateY = this.animationState.interpolate({
        inputRange: [0, 1],
        outputRange: this.props.inverted === true ? [this.state.to.y, this.state.from.y] : [this.state.from.y, this.state.to.y]
      });
      return {
        transform: [{
          translateX: translateX
        }, {
          translateY: translateY
        }],
        overflow: 'visible',
        alignSelf: 'flex-start'
      };
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.to !== prevState.to || nextProps.from !== prevState.from || nextProps.animationState !== prevState.animationState) {
        return {
          from: nextProps.from,
          to: nextProps.to,
          animationState: nextProps.animationState
        };
      }
      return null;
    }
  }]);
}(_BaseAnimated2.BaseAnimated);
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapMarkers = exports.MapMarker = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var B64 = _interopRequireWildcard(require("base-64"));
var _DownloadCache = require("../../../file/download/DownloadCache");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var mapMarkers = exports.mapMarkers = function mapMarkers() {
  var c = _DownloadCache.DownloadCache.cacheDir;
  return {
    blue: `${c}/blue.png`,
    blueRed: `${c}/blueRed.png`,
    green: `${c}/green.png`,
    greenRed: `${c}/greenRed.png`,
    orange: `${c}/orange.png`,
    orangeRed: `${c}/orangeRed.png`,
    yellow: `${c}/yellow.png`,
    yellowRed: `${c}/yellowRed.png`
  };
};
var MapMarker = exports.MapMarker = function () {
  function MapMarker(id, color, position, tooltip, onClick, dragable, onDragged, fillColor) {
    (0, _classCallCheck2.default)(this, MapMarker);
    this.dragable = false;
    this.id = id;
    this.color = color;
    this.fillColor = fillColor;
    this.position = position;
    this.onClick = onClick;
    this.tooltip = tooltip;
    this.dragable = dragable === true;
    this.onDragged = onDragged;
  }
  return (0, _createClass2.default)(MapMarker, null, [{
    key: "getSvg",
    value: function getSvg() {
      var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#00AEEF';
      var fillcolor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'transparent';
      var c = '#00AEEF';
      var f = color.endsWith('Red') ? '#F44336' : fillcolor;
      if (color.startsWith('blue')) {
        c = 'rgb(0, 115, 255)';
      } else if (color.startsWith('green')) {
        c = 'rgb(0, 205, 158)';
      } else if (color.startsWith('yellow')) {
        c = 'rgb(255,213,0)';
      } else if (color.startsWith('orange')) {
        c = 'rgb(245, 124, 0)';
      } else {
        c = color;
      }
      var n = MapMarker.xml.replace('#MARKERCOLOR', c).replace('#FILLCOLOR', f);
      return n;
    }
  }, {
    key: "getB64Svg",
    value: function getB64Svg() {
      var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#00AEEF';
      var fillcolor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'transparent';
      return `data:image/svg+xml;base64,${B64.encode(MapMarker.getSvg(color, fillcolor))}`;
    }
  }]);
}();
MapMarker.xml = '<?xml version="1.0" encoding="utf-8"?>\n' + '<!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->\n' + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n' + '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"\n' + '     viewBox="0 0 365 560" enable-background="new 0 0 365 560" xml:space="preserve">\n' + '<g>\n' + '\t<path fill="#MARKERCOLOR" d="M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9\n' + '\t\tC96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8\n' + '\t\tc33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"/>\n' + '<circle id="Oval" fill="#FILLCOLOR" fill-rule="nonzero" cx="183" cy="187" r="65"></circle>\n' + '</g>\n' + '</svg>';
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddNewOrExistingUserToCompany = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var EventRootOdata_1 = require("cqrs-core/build/src/core/events/EventRootOdata");
var EmailValidator = require("email-validator");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var AddNewOrExistingUserToCompany = function (_CommandRoot_1$Comman) {
  function AddNewOrExistingUserToCompany(privatedata) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddNewOrExistingUserToCompany);
    _this = _callSuper(this, AddNewOrExistingUserToCompany, [privatedata, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'AddNewOrExistingUserToCompany';
    return _this;
  }
  (0, _inherits2.default)(AddNewOrExistingUserToCompany, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(AddNewOrExistingUserToCompany, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        if (userId.startsWith('company')) {
          var companyId = userId.substr(7);
          if (companyId === this.data.companyId) return true;
        }
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, this.data.companyId)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "clone",
    value: function clone(newData, entityId, token) {
      return new AddNewOrExistingUserToCompany(newData, entityId, token);
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new EventRootOdata_1.EventRootOdata({
          entityId: this.entityId,
          eventName: 'event',
          aggregateId: this.data.companyId,
          aggregate: 'company'
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        if (key == null || key === 'role') {
          if (this.data.role == null || this.data.role.length === 0) {
            errors.push({
              name: 'role',
              key: 'role',
              message: 'Role wird benötigt',
              messageCode: 'roleIsRequired'
            });
          }
        }
        if (key == null || key === 'email') {
          if (this.data.role === 'user' || this.data.role === 'admin' || this.data.role === 'collaborator') {
            if (this.data.role == null || this.data.role.length === 0) {
              errors.push({
                name: 'email',
                key: 'email',
                message: 'email wird benötigt',
                messageCode: 'emailIsRequired'
              });
            }
          }
          if (this.data.email != null && this.data.email.length > 0 && !EmailValidator.validate(this.data.email)) {
            errors.push({
              key: 'email',
              message: 'Keine gültige E-Mail Adresse',
              messageCode: 'emailValidationError'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.AddNewOrExistingUserToCompany = AddNewOrExistingUserToCompany;
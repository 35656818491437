var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Weather = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var Weather = exports.Weather = function () {
  function Weather() {
    (0, _classCallCheck2.default)(this, Weather);
  }
  return (0, _createClass2.default)(Weather, [{
    key: "fill",
    value: function fill(data) {
      this.time = data.time;
      this.summary = data.summary;
      this.icon = data.icon;
      this.precipIntensity = data.precipIntensity;
      this.precipProbability = data.precipProbability;
      this.precipType = data.precipType;
      this.humidity = data.humidity;
      this.pressure = data.pressure;
      this.windSpeed = data.windSpeed;
      this.windBearing = data.windBearing;
      this.cloudCover = data.cloudCover;
      this.uvIndex = data.uvIndex;
    }
  }]);
}();
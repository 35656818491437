"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlanFilter = void 0;
var Filter_1 = require("./Filter");
var PlanFilter = function () {
  function PlanFilter() {
    (0, _classCallCheck2.default)(this, PlanFilter);
  }
  return (0, _createClass2.default)(PlanFilter, null, [{
    key: "isSet",
    value: function isSet(filter) {
      return filter != null && (filter.u != null && filter.u.length > 0 || filter.t != null && filter.t.length > 0 || filter.is != null || filter.ie != null);
    }
  }, {
    key: "filterPlans",
    value: function () {
      var _filterPlans = (0, _asyncToGenerator2.default)(function* (plans, filter) {
        if (filter != null && PlanFilter.isSet(filter)) {
          var counted = 0;
          var filteredPlans = [];
          if (filter.u != null && filter.u.length > 0) {
            var filteredByUserId = yield PlanFilter.filterPlansByCreators(plans, filter.u);
            filteredPlans.push.apply(filteredPlans, (0, _toConsumableArray2.default)(filteredByUserId));
            counted += 1;
          }
          if (filter.t != null && filter.t.length > 0) {
            var filteredByTags = yield PlanFilter.filterPlansByTagStringAnd(plans, filter.t);
            filteredPlans.push.apply(filteredPlans, (0, _toConsumableArray2.default)(filteredByTags));
            counted += 1;
          }
          if (filter.is != null || filter.ie != null) {
            var filterPlansByActivity = yield PlanFilter.filterPlansByInterval(plans, filter.is, filter.ie);
            filteredPlans.push.apply(filteredPlans, (0, _toConsumableArray2.default)(filterPlansByActivity));
            counted += 1;
          }
          var counts = [];
          filteredPlans.forEach(function (item) {
            if (counts[item.id] === undefined) {
              counts[item.id] = 0;
            }
            counts[item.id] += 1;
            return counts;
          });
          var unique = filteredPlans.filter(function (x, i, l) {
            return l.indexOf(x) === i;
          });
          var filterByCounts = function filterByCounts(element, _index, _array) {
            if (counts[element.id] !== undefined && counts[element.id] === counted) {
              return true;
            }
            return false;
          };
          var filteredByCounts = unique.filter(filterByCounts);
          return filteredByCounts;
        }
        return plans;
      });
      function filterPlans(_x, _x2) {
        return _filterPlans.apply(this, arguments);
      }
      return filterPlans;
    }()
  }, {
    key: "filterPlansByInterval",
    value: function () {
      var _filterPlansByInterval = (0, _asyncToGenerator2.default)(function* (plans, from, to) {
        var dateFrom = from != null ? new Date(from) : null;
        var dateTo = to != null ? new Date(to) : null;
        var start = dateFrom == null ? null : new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0, 0);
        var end = dateTo == null ? null : new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59, 59);
        return plans.filter(function (t) {
          t.createdAt = new Date(t.createdAt);
          return (start == null || t.createdAt > start) && (end == null || t.createdAt < end);
        });
      });
      function filterPlansByInterval(_x3, _x4, _x5) {
        return _filterPlansByInterval.apply(this, arguments);
      }
      return filterPlansByInterval;
    }()
  }, {
    key: "filterPlansByCreators",
    value: function () {
      var _filterPlansByCreators = (0, _asyncToGenerator2.default)(function* (plans, userIds) {
        if (userIds != null && userIds.length > 0) {
          var filterByCreatorId = function filterByCreatorId(element, _index, _array) {
            for (var i = 0; i < userIds.length; i += 1) {
              if (userIds[i] != null && userIds[i].length > 0) {
                if (userIds[i] === element.createdBy) {
                  return true;
                }
              }
            }
            return false;
          };
          return plans.filter(filterByCreatorId);
        }
        return [];
      });
      function filterPlansByCreators(_x6, _x7) {
        return _filterPlansByCreators.apply(this, arguments);
      }
      return filterPlansByCreators;
    }()
  }, {
    key: "filterPlansByTagStringAnd",
    value: function () {
      var _filterPlansByTagStringAnd = (0, _asyncToGenerator2.default)(function* (plans, tags) {
        function filterByTags(element, _index, _array) {
          if (element.tags == null || element.tags.length === 0) {
            return false;
          }
          var c = 0;
          var _loop = function _loop(i) {
            var index = element.tags.findIndex(function (a) {
              return a.tagName === tags[i];
            });
            if (index > -1) c += 1;
          };
          for (var i = 0; i < tags.length; i += 1) {
            _loop(i);
          }
          return c === tags.length;
        }
        if (tags != null && tags.length > 0) return plans.filter(filterByTags);
        return plans;
      });
      function filterPlansByTagStringAnd(_x8, _x9) {
        return _filterPlansByTagStringAnd.apply(this, arguments);
      }
      return filterPlansByTagStringAnd;
    }()
  }, {
    key: "filterPlansByTagStringOr",
    value: function () {
      var _filterPlansByTagStringOr = (0, _asyncToGenerator2.default)(function* (plans, filter) {
        function filterByTags(element, _index, _array) {
          if (filter != null && filter.length > 0) {
            for (var i = 0; i < filter.length; i += 1) {
              if (filter[i].toLowerCase() === 'null' && (element.tags == null || element.tags.length === 0)) {
                return true;
              }
              if (filter[i] != null && filter[i].length > 0) {
                if (element.tags != null && element.tags.length > 0) {
                  for (var j = 0; j < element.tags.length; j += 1) {
                    if (filter[i].toLowerCase() === element.tags[j].tagName.toLowerCase()) {
                      return true;
                    }
                  }
                }
              }
            }
          } else if (element.tags == null || element.tags.length === 0) {
            return true;
          }
          return false;
        }
        return plans.filter(filterByTags);
      });
      function filterPlansByTagStringOr(_x10, _x11) {
        return _filterPlansByTagStringOr.apply(this, arguments);
      }
      return filterPlansByTagStringOr;
    }()
  }, {
    key: "filterPlansByText",
    value: function () {
      var _filterPlansByText = (0, _asyncToGenerator2.default)(function* (text, plans) {
        var words = text.trim().split(' ');
        if (plans == null || plans.length === 0) {
          return plans;
        }
        function filterByTitle(element, _index, _array) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchA = element.title.match(regexp);
              if (matchA != null && matchA.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        function filterByTags(element, _index, _array) {
          if (element.tags != null && element.tags.length > 0) {
            var _loop2 = function _loop2(i) {
                if (words[i] != null && words[i].length > 0) {
                  return {
                    v: element.tags.findIndex(function (t) {
                      var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[i]), 'i');
                      var matchN = t.tagName.match(regexp);
                      return matchN != null && matchN.length > 0;
                    }) > -1
                  };
                }
              },
              _ret;
            for (var i = 0; i < words.length; i += 1) {
              _ret = _loop2(i);
              if (_ret) return _ret.v;
            }
          }
          return false;
        }
        function filterByNote(element, _index, _array) {
          if (element.notes != null && element.notes.length > 0) {
            var matches = 0;
            for (var i = 0; i < words.length; i += 1) {
              var w = words[i].trim();
              if (w.length > 0) {
                var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
                var matchN = element.notes.match(regexp);
                if (matchN != null && matchN.length > 0) {
                  matches += 1;
                }
              } else {
                matches += 1;
              }
            }
            return matches === words.length;
          }
          return false;
        }
        if (text != null && text.length > 0) {
          var filteredByTitle = plans.filter(filterByTitle);
          var filteredByNotes = plans.filter(filterByNote);
          var filteredByTags = plans.filter(filterByTags);
          var result = [].concat((0, _toConsumableArray2.default)(filteredByTitle), (0, _toConsumableArray2.default)(filteredByNotes), (0, _toConsumableArray2.default)(filteredByTags));
          var counts = [];
          result.forEach(function (item) {
            if (counts[item.id] === undefined) {
              counts[item.id] = 0;
            }
            counts[item.id] += 1;
            return counts;
          });
          var unique = result.filter(function (x, i, l) {
            return l.indexOf(x) === i;
          });
          var sorted = unique.sort(function (v1, v2) {
            return counts[v2.id] - counts[v1.id];
          });
          return sorted;
        }
        return plans;
      });
      function filterPlansByText(_x12, _x13) {
        return _filterPlansByText.apply(this, arguments);
      }
      return filterPlansByText;
    }()
  }, {
    key: "filterPlansByTags",
    value: function filterPlansByTags(tags, plans) {
      function search(element, _index, _array) {
        var _loop3 = function _loop3(i) {
            if (tags[i] != null && tags[i].length > 0) {
              if (element.tags.findIndex(function (t) {
                return tags[i].toLowerCase() === t.tagName.toLowerCase();
              }) >= 0) {
                return {
                  v: true
                };
              }
            }
          },
          _ret2;
        for (var i = 0; i < tags.length; i += 1) {
          _ret2 = _loop3(i);
          if (_ret2) return _ret2.v;
        }
        return false;
      }
      return tags != null && tags.length > 0 ? plans.filter(search) : plans;
    }
  }]);
}();
exports.PlanFilter = PlanFilter;
var _a;
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CommandReadModels } from 'upmesh-core/src/server/webserver/commands/CommandReadModels';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddFilesPU } from './AddFilesPU';
import { SharedFiles } from './SharedFiles';
export class EditedFileSaver {
}
_a = EditedFileSaver;
EditedFileSaver.saveEditedImage = (storedFile, editable = false) => async (fileData, _alreadyAccepted) => {
    if (fileData != null) {
        const project = await CommandReadModels.project.getById(storedFile.projectId);
        await new Promise((resolve) => {
            const buttons = [
                <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} key="close" onPress={() => {
                        Alert.instance?.close(() => {
                            resolve();
                        });
                    }} title={I18n.m.getMessage('discard')}/>,
                <ContainedButton key="saveVersion" onPress={() => {
                        Alert.instance?.close(() => {
                            _a.saveEditedImageNow(storedFile, fileData)().catch((err) => {
                                DefaultErrorHandler.showDefaultErrorAlert(err);
                            });
                            resolve();
                        });
                    }} title={I18n.m.getMessage('save')}/>,
            ];
            let canReplace = false;
            if (AuthClient.instance.serverConnected() && (editable || project != null)) {
                if (storedFile.forEntity === 'Ticket' &&
                    storedFile.forEntityId != null &&
                    storedFile.forEntityId.length > 0) {
                    canReplace =
                        CurrentUser.userId === storedFile.createdBy &&
                            (project.filesEditableTickets === 'ever' ||
                                (project.filesEditableTickets === '24h' &&
                                    storedFile.createdAt.getTime() + 1000 * 60 * 60 * 24 > new Date().getTime()));
                    if (canReplace) {
                        buttons.push(<ContainedButton key="replace" onPress={() => {
                                Alert.instance?.close(() => {
                                    _a.saveEditedImageNow(storedFile, fileData, 'replace')().catch((err) => {
                                        DefaultErrorHandler.showDefaultErrorAlert(err);
                                    });
                                    resolve();
                                });
                            }} title={I18n.m.getMessage('replace')}/>);
                    }
                }
            }
            Routing.instance.alert.post({
                title: I18n.m.getMessage('photoEditorSaveTitle'),
                text: I18n.m.getMessage('photoEditorSaveText'),
                buttons,
            });
        });
    }
};
EditedFileSaver.saveEditedImageNow = (storedFile, fileData, target = 'version') => async (_e) => {
    Alert.instance?.close(() => {
        if (fileData == null)
            return;
        if (storedFile != null) {
            if (target === 'newFile') {
                const file = fileData['path'] != null ? { path: fileData['path'], filename: storedFile.orgFilename } : fileData;
                try {
                    SharedFiles.files = [file];
                    SharedFiles.excludeFolders = null;
                    const data = { type: 'All' };
                    data.projectId = storedFile.projectId;
                    if (storedFile.forEntityId != null) {
                        data.type = 'Tickets';
                        data.entityId = storedFile.forEntityId;
                    }
                    AddFilesPU.openDialog(data)(null);
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            }
            else {
                const targetUrl = target === 'replace'
                    ? `${UpmeshClient.instance.url}/storedfile/file/${storedFile.id}`
                    : `${UpmeshClient.instance.url}/storedfile/file/`;
                const submitData = {
                    forEntityId: storedFile.forEntityId,
                    forEntity: storedFile.forEntity,
                    folder: storedFile.folder,
                    metaData: storedFile.metaData,
                    imageMetadata: storedFile.imageMetadata,
                    mimeType: storedFile.mimeType,
                    title: storedFile.title,
                    type: storedFile.type,
                    comment: storedFile.comment,
                    fileType: storedFile.fileType,
                };
                if (submitData['versions'])
                    submitData['versions'] = undefined;
                try {
                    Uploads.addUpload(targetUrl, fileData, storedFile.fileType, {
                        ...submitData,
                        projectId: storedFile.projectId,
                    }).catch(DefaultErrorHandler.showDefaultErrorAlert);
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                }
            }
        }
    });
};

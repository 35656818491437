var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProgressBar = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ProgressBar = exports.ProgressBar = function (_PureComponent) {
  function ProgressBar(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ProgressBar);
    _this = _callSuper(this, ProgressBar, [props]);
    _this.state = {
      progressInPercent: 0
    };
    return _this;
  }
  (0, _inherits2.default)(ProgressBar, _PureComponent);
  return (0, _createClass2.default)(ProgressBar, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        color = _this$props.color,
        width = _this$props.width,
        backgroundColor = _this$props.backgroundColor;
      var progressInPercent = this.state.progressInPercent;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row',
          overflow: 'hidden',
          width: width,
          position: 'relative',
          height: 7,
          borderRadius: 10,
          backgroundColor: backgroundColor
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 7,
            borderRadius: 10,
            width: `${progressInPercent}%`,
            backgroundColor: color,
            overflow: 'hidden'
          }
        })
      });
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, _prevState) {
      return {
        progressInPercent: nextProps.progressInPercent
      };
    }
  }]);
}(_react.PureComponent);
ProgressBar.defaultProps = {
  color: _ThemeManager.ThemeManager.style.progressBarColor,
  width: '100%',
  progressInPercent: 0,
  backgroundColor: _ThemeManager.ThemeManager.style.appBgColorDarken
};
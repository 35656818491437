import { TextButton } from 'materialTheme/src/theme/components/button/TextButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeHoliday } from 'upmesh-core/src/client/commands/companies/holiday/ChangeHoliday';
import { CreateHoliday } from 'upmesh-core/src/client/commands/companies/holiday/CreateHoliday';
import { I18n } from '../../i18n/I18n';
export class AddOrChangeHolidayToCompanyDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    render() {
        const { company, holiday } = this.props;
        let currentData;
        if (holiday != null) {
            currentData = { ...holiday };
        }
        else {
            currentData = {
                companyId: company.id,
                name: '',
                regionIds: [],
                wageType: '',
                date: new Date(),
                type: 'day',
                amount: 1,
                annual: true,
            };
        }
        const dataOptions = new Map();
        dataOptions.set('name', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('companyHolidayName') },
        });
        dataOptions.set('wageType', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('companyHolidayWageType') },
        });
        dataOptions.set('date', {
            formType: 'Date',
            cols: 1,
            props: { labelText: I18n.m.getMessage('date') },
        });
        dataOptions.set('type', {
            formType: 'Picker',
            mapValue: (value, fromFormInput) => {
                if (fromFormInput)
                    return value.value.data;
                if (value === 'hours')
                    return { index: 1, value };
                if (value === 'day')
                    return { index: 0, value };
                return { index: 0, value: 'day' };
            },
            props: {
                labelText: I18n.m.getMessage('companyHolidayDayOrHours'),
                key: 'dayOrHourId',
                list: [
                    { title: I18n.m.getMessage('day'), data: 'day' },
                    { title: I18n.m.getMessage('journalEmployeesHours'), data: 'hours' },
                ],
            },
        });
        dataOptions.set('amount', {
            formType: 'number',
            cols: 1,
            props: { labelText: I18n.m.getMessage('quantity') },
        });
        dataOptions.set('annual', {
            formType: 'boolean',
            cols: 1,
            props: { labelText: I18n.m.getMessage('companyHolidayYearly') },
        });
        if (holiday != null) {
            return (<Form key="changeCompanyUserRoleForm" formHeaderProps={{
                    formTitle: I18n.m.getMessage('companyChangeHolidayDialogTitle'),
                }} stickyActions stickyHeader command={new ChangeHoliday(currentData, holiday.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
        }
        return (<Form key="changeCompanyUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyAddHolidayDialogTitle'),
            }} stickyActions stickyHeader command={new CreateHoliday(currentData)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('add')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LightboxContentPosition = exports.LightBoxHandler = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _tsEvents = require("ts-events");
var LightboxContentPosition = exports.LightboxContentPosition = (0, _createClass2.default)(function LightboxContentPosition() {
  (0, _classCallCheck2.default)(this, LightboxContentPosition);
});
var LightBoxHandler = exports.LightBoxHandler = function () {
  function LightBoxHandler() {
    (0, _classCallCheck2.default)(this, LightBoxHandler);
  }
  return (0, _createClass2.default)(LightBoxHandler, null, [{
    key: "getGroupInfo",
    value: function getGroupInfo(groupId) {
      var boxes = LightBoxHandler._lightboxes.get(groupId);
      if (boxes != null) {
        return boxes;
      }
      return null;
    }
  }, {
    key: "getNext",
    value: function getNext(groupId, lbId) {
      if (groupId === 'none') {
        return null;
      }
      var boxes = LightBoxHandler._lightboxes.get(groupId);
      if (boxes != null) {
        var openNext = false;
        for (var lb of boxes) {
          if (openNext) {
            return {
              lbId: lb.lbId,
              lb: lb.lb
            };
          }
          if (lb.lbId === lbId) {
            openNext = true;
          }
        }
      }
      return null;
    }
  }, {
    key: "getPrev",
    value: function getPrev(groupId, lbId) {
      if (groupId === 'none') {
        return null;
      }
      var boxes = LightBoxHandler._lightboxes.get(groupId);
      if (boxes != null) {
        var prev = null;
        for (var lb of boxes) {
          if (lb.lbId === lbId && prev != null) {
            return {
              lbId: prev.lbId,
              lb: prev.lb
            };
          }
          prev = lb;
        }
      }
      return null;
    }
  }, {
    key: "open",
    value: function open(groupId, lbId) {
      var boxes = LightBoxHandler._lightboxes.get(groupId);
      if (boxes != null) {
        for (var i = 0; i < boxes.length; i += 1) {
          if (boxes[i].lbId === lbId) {
            LightBoxHandler.openLightboxEvent.post(boxes[i].lb);
            return;
          }
        }
      }
    }
  }, {
    key: "openNextGroupImage",
    value: function openNextGroupImage(groupId, lbId) {
      var next = LightBoxHandler.getNext(groupId, lbId);
      if (next != null) {
        LightBoxHandler.open(groupId, next.lbId);
      }
    }
  }, {
    key: "openPrevGroupImage",
    value: function openPrevGroupImage(groupId, lbId) {
      var prev = LightBoxHandler.getPrev(groupId, lbId);
      if (prev != null) {
        LightBoxHandler.open(groupId, prev.lbId);
      }
    }
  }, {
    key: "register",
    value: function register(groupId, lbId, lb, index) {
      if (!LightBoxHandler._lightboxes.has(groupId)) {
        LightBoxHandler._lightboxes.set(groupId, []);
      }
      var boxes = LightBoxHandler._lightboxes.get(groupId);
      if (boxes != null) {
        for (var i = 0; i < boxes.length; i += 1) {
          if (boxes[i].lbId === lbId) {
            return;
          }
        }
        boxes.push({
          lbId: lbId,
          lb: lb,
          index: index
        });
        boxes.sort(function (a, b) {
          if (a.index == null && b.index == null) {
            return 0;
          }
          if (a.index == null) {
            return -1;
          }
          if (b.index == null) {
            return 1;
          }
          if (a.index > b.index) {
            return 1;
          }
          if (a.index < b.index) {
            return -1;
          }
          return 0;
        });
        LightBoxHandler._lightboxes.set(groupId, boxes);
      }
    }
  }, {
    key: "remove",
    value: function remove(groupId, lbId) {
      var boxes = LightBoxHandler._lightboxes.get(groupId);
      if (boxes != null) {
        for (var i = 0; i < boxes.length; i += 1) {
          if (boxes[i].lbId === lbId) {
            boxes.splice(i, 1);
            LightBoxHandler._lightboxes.set(groupId, boxes);
            return;
          }
        }
      }
    }
  }]);
}();
LightBoxHandler.openLightboxEvent = new _tsEvents.AsyncEvent();
LightBoxHandler._lightboxes = new Map();
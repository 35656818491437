import { UserImageSyncButton } from 'materialTheme/src/components/account/profile/UserImageSyncButton';
import { Header } from 'materialTheme/src/theme/components/header/Header';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
import { SidePanelHandler } from './SidePanel';
export class AppBar extends PureComponent {
    constructor(props) {
        super(props);
        this.onLayout = () => {
            if (!this.props.hideMenu) {
                const showMenu = ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM;
                this.setState({ showMenu });
            }
        };
        this.openDrawer = () => {
            SidePanelHandler.stateEvent.post('open');
        };
        this.state = {
            showMenu: !props.hideMenu && ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM,
        };
    }
    render() {
        let backgroundColor = ThemeManager.style.headerBG;
        let leftButtons = this.props.leftButtons != null ? this.props.leftButtons : undefined;
        const rightButtons = this.props.rightButtons != null ? this.props.rightButtons : [];
        if (this.props.secondaryHeader == null) {
            if (this.state.showMenu) {
                if (leftButtons == null) {
                    leftButtons = [];
                    leftButtons.push(<Icon toolTip={I18n.m.getMessage('openMenu')} key="drawerMenuButton" icon="menu" onPress={this.openDrawer} backgroundColor="transparent" color={this.props.textColor != null ? this.props.textColor : ThemeManager.style.headerButtonColor}/>);
                }
            }
            if (this.state.showMenu || this.props.showAccountIcon === true) {
                rightButtons.push(<UserImageSyncButton key="userImage" size={24}/>);
            }
        }
        else {
            if (this.props.showAccountIcon === true) {
                rightButtons.push(<UserImageSyncButton key="userImage" size={24}/>);
            }
            backgroundColor = ThemeManager.style.headerBGDarken;
        }
        if (this.props.backgroundColor != null) {
            backgroundColor = this.props.backgroundColor;
        }
        return (<View style={{ alignSelf: 'stretch', overflow: 'visible' }} onLayout={this.onLayout}>
        <Header additionalContent={this.props.additionalContent} textColor={this.props.textColor} leftButtons={leftButtons} searchBarProps={this.props.searchBarProps} withElevation={this.props.withElevation} withBorder={this.props.withBorder} rightButtons={rightButtons} title={this.props.title} backgroundColor={backgroundColor} additionalContentPaddingLeft={this.props.additionalContentPaddingLeft}/>
      </View>);
    }
}

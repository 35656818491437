"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeammemberFilter = void 0;
var TeammemberFilter = function () {
  function TeammemberFilter() {
    (0, _classCallCheck2.default)(this, TeammemberFilter);
    this.status = ['accepted', 'invited'];
  }
  return (0, _createClass2.default)(TeammemberFilter, null, [{
    key: "filterMembersByStatus",
    value: function filterMembersByStatus(status, user) {
      var search = function search(element, _index, _array) {
        if (element.status != null && status.findIndex(function (value) {
          return value === element.status;
        }) >= 0) {
          return true;
        }
        return false;
      };
      return status != null && status.length > 0 ? user.filter(search) : user;
    }
  }, {
    key: "filterMembersByCompany",
    value: function filterMembersByCompany(company, user) {
      var search = function search(element, _index, _array) {
        return element.company != null && company.findIndex(function (value) {
          return value === element.company;
        }) >= 0;
      };
      return company != null && company.length > 0 ? user.filter(search) : user;
    }
  }, {
    key: "filterMembersByRole",
    value: function filterMembersByRole(roles, user) {
      var search = function search(element, _index, _array) {
        return element.translatedRole != null && roles.findIndex(function (value) {
          return value === element.translatedRole;
        }) >= 0;
      };
      return roles != null && roles.length > 0 ? user.filter(search) : user;
    }
  }]);
}();
exports.TeammemberFilter = TeammemberFilter;
TeammemberFilter.filterTeammember = function (teammember, filter) {
  var roles = filter.roles,
    companies = filter.companies,
    status = filter.status;
  var filteredMembers = teammember;
  if (roles != null) filteredMembers = TeammemberFilter.filterMembersByRole(roles, filteredMembers);
  if (companies != null) filteredMembers = TeammemberFilter.filterMembersByCompany(companies, filteredMembers);
  if (status != null) filteredMembers = TeammemberFilter.filterMembersByStatus(status, filteredMembers);
  return filteredMembers;
};
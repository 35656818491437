var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WeatherDay = exports.WeatherDaily = void 0;
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _Weather2 = require("./Weather");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var WeatherDaily = exports.WeatherDaily = function () {
  function WeatherDaily() {
    (0, _classCallCheck2.default)(this, WeatherDaily);
  }
  return (0, _createClass2.default)(WeatherDaily, [{
    key: "fill",
    value: function fill(data) {
      this.summary = data.summary;
      this.icon = data.icon;
      this.data = data.data;
    }
  }]);
}();
var WeatherDay = exports.WeatherDay = function (_Weather) {
  function WeatherDay() {
    (0, _classCallCheck2.default)(this, WeatherDay);
    return _callSuper(this, WeatherDay, arguments);
  }
  (0, _inherits2.default)(WeatherDay, _Weather);
  return (0, _createClass2.default)(WeatherDay, [{
    key: "fill",
    value: function fill(data) {
      this.sunriseTime = data.sunriseTime;
      this.sunsetTime = data.sunsetTime;
      this.temperatureHigh = data.temperatureHigh;
      this.temperatureLow = data.temperatureLow;
    }
  }]);
}(_Weather2.Weather);
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeProjectOwner } from 'upmesh-core/src/client/commands/user/ChangeProjectOwner';
import { I18n } from '../../i18n/I18n';
export class OwnerChangeDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            if (AuthClient.instance != null)
                AuthClient.instance.startSync().catch((err) => console.debug(err));
            Dialog.instance?.close();
        };
    }
    render() {
        const { project, userName, projectUserId, toType } = this.props;
        const currentData = {
            confirmation: false,
            projectId: project.id,
            newOwnerProjectUserIdOrCompanyId: projectUserId,
        };
        const dataOptions = new Map();
        dataOptions.set('confirmation', {
            formType: 'boolean',
            cols: 1,
            props: {
                labelText: I18n.m.getMessage('projectOwnerTransferConfirmation'),
                key: 'checkboxConfirm',
            },
        });
        return (<Form key="changeProjectUserRoleForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('projectOwnerChangeDialogTitle', {
                    username: userName,
                    projectName: project.title,
                }),
                formText: toType === 'user' ? I18n.m.getMessage('projectOwnerChangeDialogText') : '',
            }} stickyActions={false} showButtonsIfOffline command={new ChangeProjectOwner(currentData, projectUserId)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('projectOwnerChangeAction')} additionalButtons={[
                <ContainedButton key="cancel" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeProject } from 'upmesh-core/src/client/commands/project/ChangeProject';
import { ChangeProjectState, } from 'upmesh-core/src/client/commands/companies/projectstates/ChangeProjectState';
import { I18n } from '../../i18n/I18n';
import { ColorComponent } from './ColorComponent';
import { CurrentProject } from './CurrentProject';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { CompanyProjectStatePicker } from './CompanyProjectStatePicker';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export class EditProjectDialog extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.onSaved = () => {
            const { project, selectedState } = this.state;
            if (project != null && selectedState != null) {
                const changeCommand = new ChangeProjectState({
                    stateId: selectedState.id === '-1' ? null : selectedState.id,
                    projectId: project.id,
                    companyId: CompanyUserInfo.companySettings?.id ?? '',
                });
                changeCommand.execute(ClientStore.commandStore).catch((err) => {
                    console.error('could not change project state', err);
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
            Routing.instance.closeDialog(true)(null);
        };
        this.colorComponent = (props) => {
            const { project } = this.state;
            return <ColorComponent project={project} {...props}/>;
        };
        this.stateComponent = (props) => {
            const { project } = this.state;
            return (<CompanyProjectStatePicker onPressChip={(e) => {
                    const projectState = e;
                    this.setState({ selectedState: projectState });
                }} projectId={project != null && project.id != null ? project.id : ''} {...props}/>);
        };
        this.state = {};
    }
    componentDidMount() {
        this.loadProject().catch((err) => console.debug(err));
    }
    async loadProject() {
        await DateLocale.waitFor(() => CurrentProject.instance.getCurrentProject() != null, 300, 15000);
        const project = CurrentProject.instance.getCurrentProject();
        if (project == null) {
            Routing.instance.goTo('/');
        }
        else {
            this.setState({ project });
        }
    }
    render() {
        const { project } = this.state;
        if (project == null) {
            return (<View>
          <Spinner />
        </View>);
        }
        const currentData = {
            ...project,
        };
        const dataOptions = new Map();
        dataOptions.set('title', {
            formType: 'string',
            cols: 2,
            props: { labelText: I18n.m.getMessage('projectName') },
        });
        dataOptions.set('projectType', {
            formType: 'string',
            optional: true,
            cols: 2,
            props: { labelText: I18n.m.getMessage('projectType') },
        });
        if (CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.projectStateNames.length > 0) {
            dataOptions.set('custom_project_data', {
                formType: 'individiual',
                optional: true,
                cols: 2,
                props: { labelText: I18n.m.getMessage('projectType') },
                component: this.stateComponent,
            });
        }
        dataOptions.set('projectStart', {
            formType: 'Date',
            optional: true,
            cols: 1,
            props: { labelText: I18n.m.getMessage('projectStart') },
        });
        dataOptions.set('projectEnd', {
            formType: 'Date',
            optional: true,
            cols: 1,
            props: { labelText: I18n.m.getMessage('projectEnd') },
        });
        dataOptions.set('constructionStart', {
            formType: 'Date',
            optional: true,
            cols: 1,
            props: { labelText: I18n.m.getMessage('projectBuildingStart') },
        });
        dataOptions.set('constructionEnd', {
            formType: 'Date',
            optional: true,
            cols: 1,
            props: { labelText: I18n.m.getMessage('projectBuildingEnd') },
        });
        dataOptions.set('description', {
            formType: 'string',
            optional: true,
            cols: 2,
            props: { labelText: I18n.m.getMessage('projectDescription'), numberOfLines: 4 },
        });
        if (!(CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.projectStateNames.length > 0)) {
            dataOptions.set('color', {
                formType: 'individiual',
                optional: true,
                cols: 2,
                props: { labelText: I18n.m.getMessage('projectType') },
                component: this.colorComponent,
            });
        }
        return (<Form formHeaderProps={{
                formTitle: I18n.m.getMessage('projectEditTitle'),
            }} disableSaveOnNothingChanged={false} baseCols={2} stickyHeader stickyActions command={new ChangeProject(currentData, project.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('projectDetailsSave')}/>);
    }
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeProjectOwner = void 0;
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeProjectOwner = function (_ClientProjectCommand) {
  function ChangeProjectOwner(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeProjectOwner);
    _this = _callSuper(this, ChangeProjectOwner, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.waitForResult = false;
    _this.commandName = 'ChangeProjectOwner';
    return _this;
  }
  (0, _inherits2.default)(ChangeProjectOwner, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeProjectOwner, [{
    key: "getProject",
    value: function () {
      var _getProject = (0, _asyncToGenerator2.default)(function* () {
        if (this._project == null) this._project = yield CommandReadModels_1.CommandReadModels.project.getById(this.data.projectId);
        return this._project;
      });
      function getProject() {
        return _getProject.apply(this, arguments);
      }
      return getProject;
    }()
  }, {
    key: "getOldProjectUser",
    value: function () {
      var _getOldProjectUser = (0, _asyncToGenerator2.default)(function* () {
        if (this._oldProjectUserOrCompany == null) {
          if (this.data.newOwnerProjectUserIdOrCompanyId.startsWith('company')) {
            this._oldProjectUserOrCompany = yield CommandReadModels_1.CommandReadModels.company.getById(this.data.newOwnerProjectUserIdOrCompanyId.slice(7));
          } else {
            this._oldProjectUserOrCompany = yield CommandReadModels_1.CommandReadModels.projectUser.getById(this.data.newOwnerProjectUserIdOrCompanyId);
          }
        }
        return this._oldProjectUserOrCompany;
      });
      function getOldProjectUser() {
        return _getOldProjectUser.apply(this, arguments);
      }
      return getOldProjectUser;
    }()
  }, {
    key: "getOldOwner",
    value: function () {
      var _getOldOwner = (0, _asyncToGenerator2.default)(function* () {
        if (this._oldOwner == null) {
          var project = yield this.getProject();
          if (this.data.newOwnerProjectUserIdOrCompanyId.startsWith('company')) {
            var companyId = project.creator.slice(7);
            this._oldOwner = yield CommandReadModels_1.CommandReadModels.company.getById(companyId);
          } else {
            this._oldOwner = yield AuthClient_1.AuthClient.instance.modals.user.getById(project.creator);
          }
        }
        return this._oldOwner;
      });
      function getOldOwner() {
        return _getOldOwner.apply(this, arguments);
      }
      return getOldOwner;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield _superPropGet(ChangeProjectOwner, "checkProjectStatusAndGeneralAccess", this, 3)([this.data.projectId, userId]);
        var oldProjectUserOrCompany = yield this.getOldProjectUser();
        var project = yield this.getProject();
        yield this.getOldOwner();
        if (!this.data.newOwnerProjectUserIdOrCompanyId.startsWith('company')) {
          var oldProjectUser = oldProjectUserOrCompany;
          if (oldProjectUser.userId == null || oldProjectUser.userId.length === 0) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
          if (project.creator !== userId) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
        } else {
          var userCompany = null;
          var companyId = project.creator.slice(7);
          var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
            filter: `companyId eq '${companyId}' and userId eq '${userId}' and deleted ne true`,
            top: 1
          });
          if (companyMember.length > 0) userCompany = companyMember[0];
          if (userCompany == null || project.creator !== `company${userCompany.companyId}` || project.projectSubscription !== 'enterprise') {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
          var pu = yield CommandReadModels_1.CommandReadModels.projectUser.get({
            filter: `projectId eq '${project.id}' and userId eq '${userId}' and roleId eq '1' and status eq 'accepted'`,
            top: 1
          });
          if (pu.length !== 1) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
        }
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return null;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        if (_key == null || _key === 'confirmation') {
          if (this.data.confirmation !== true) {
            throw {
              message: 'Die Übertragung des Projektes muss bestätigt werden.',
              messageCode: 'ownerChangeConfirmation',
              key: 'confirmation'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeProjectOwner = ChangeProjectOwner;
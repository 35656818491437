import WebViewer from '@pdftron/webviewer';
import React, { useEffect, useRef } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ChangeBookmark } from 'upmesh-core/src/client/commands/storedfile/ChangeBookmark';
import { CreateBookmark } from 'upmesh-core/src/client/commands/storedfile/CreateBookmark';
import { BookmarkEntity } from 'upmesh-core/src/client/query/entities/BookmarkEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export default function PdfViewerComponent(props) {
    const containerRef = useRef(null);
    let lastBookmarkEvent = null;
    let loadedBookmarks = null;
    let storedFile = null;
    const loadBookmarks = async () => {
        if (props.storedFileId != null && props.storedFileId.length > 0) {
            storedFile = await UpmeshClient.instance.modals.storedFile.getById(props.storedFileId);
            const bookmarks = await UpmeshClient.instance.modals.bookmark.get({
                filter: `userId eq '${CurrentUser.userId}' and forEntityId eq '${storedFile.id}' and deleted ne true`,
            });
            if (bookmarks.length > 0) {
                loadedBookmarks = bookmarks[0];
                return bookmarks[0];
            }
        }
        return null;
    };
    const saveBookmarks = async () => {
        if (props.storedFileId != null &&
            props.storedFileId.length > 0 &&
            lastBookmarkEvent != null &&
            storedFile != null) {
            const data = BookmarkEntity.convertFromPDFTron(lastBookmarkEvent);
            if (loadedBookmarks != null) {
                const changeBookmarkCommand = new ChangeBookmark({
                    bookmarks: data,
                }, loadedBookmarks.id);
                changeBookmarkCommand.execute().catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            }
            else {
                const createBookmarkCommand = new CreateBookmark({
                    forEntityId: storedFile.id,
                    bookmarks: data,
                });
                createBookmarkCommand.execute().catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            }
        }
    };
    useEffect(() => {
        const container = containerRef.current;
        if (container != null) {
            WebViewer({
                licenseKey: 'upmesh UG:OEM:Upmesh   Enterprise Version::B+:AMS(20260809):B8B6FD03B6ACD35809994E616F5F0DF2D3F11FF55BDA082000B61AC433F5C7',
                path: `${window.location.protocol}//${window.location.host}/pdftron`,
                initialDoc: props.document,
                enableMeasurement: true,
                fullAPI: true,
                annotationUser: CurrentUser.entity != null ? CurrentUser.entity.getFullName() : 'Guest',
            }, container)
                .then((instance) => {
                props.setRef(instance.Core);
                instance.UI.setHeaderItems(function (header) {
                    header.unshift({ type: 'divider' });
                    header.unshift({ type: 'actionButton', onClick: props.close });
                });
                instance.UI.disableElements(['toolbarGroup-Shapes']);
                instance.UI.disableElements(['toolbarGroup-Forms']);
                instance.UI.disableElements(['toolbarGroup-Insert']);
                instance.UI.disableElements(['toolbarGroup-Edit']);
                loadBookmarks()
                    .then((result) => {
                    if (result != null) {
                        const pdftronBookmarks = result.convertToPDFTron();
                        if (pdftronBookmarks != null) {
                            instance.UI.importBookmarks(pdftronBookmarks);
                        }
                    }
                })
                    .catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
                instance.UI.enableElements(['bookmarksPanel', 'bookmarksPanelButton']);
                instance.UI.enableBookmarkIconShortcutVisibility();
                instance.UI.openElements(['leftPanel']);
                instance.UI.addEventListener('userBookmarksChanged', (e) => {
                    lastBookmarkEvent = e.detail;
                });
                if (CurrentUser.entity != null) {
                    instance.UI.setLanguage(CurrentUser.entity.locale).catch((err) => console.warn('cant set language for pdftron web', err));
                }
                const { documentViewer } = instance.Core;
                documentViewer.addEventListener('documentLoaded', () => {
                });
                const { onChange } = props;
                if (onChange != null) {
                    documentViewer.addEventListener('layoutChanged', () => {
                        onChange();
                    });
                    documentViewer.addEventListener('pagesUpdated', () => {
                        onChange();
                    });
                    instance.Core.annotationManager.getFieldManager().forEachField(() => { });
                    const { annotationManager } = instance.Core;
                    annotationManager.addEventListener('fieldChanged', () => {
                        onChange();
                    });
                    annotationManager.addEventListener('setNoteText', () => {
                        onChange();
                    });
                    annotationManager.addEventListener('annotationChanged', (annotations, _action, info) => {
                        if (annotations != null && annotations.length > 0) {
                            const changed = !info['imported'];
                            if (changed) {
                                onChange();
                            }
                        }
                        annotationManager.getFieldManager().forEachField((f) => {
                            f.addEventListener('change', () => onChange());
                            f.addEventListener('commit', () => onChange());
                        });
                    });
                }
            })
                .catch((err) => console.debug(err));
        }
        return function () {
            if (JSON.stringify(loadedBookmarks?.convertToPDFTron()) !== JSON.stringify(lastBookmarkEvent) &&
                lastBookmarkEvent != null) {
                saveBookmarks().catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            }
        };
    }, [props.document]);
    const styles = `iframe{width:100%;}`;
    return (<div>
      <style>{styles}</style>
      <div ref={containerRef} style={{ width: '100%', height: '100vh' }}/>
    </div>);
}

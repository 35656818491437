import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { ProOnlyDialogContent } from 'materialTheme/src/theme/components/ProOnlyDialogContent';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Linking, Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CommandReadModels } from 'upmesh-core/src/server/webserver/commands/CommandReadModels';
import { I18n } from '../../i18n/I18n';
export class SubscriptionAccountCard extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.gotoModules = () => {
            if (Platform.OS === 'web') {
                if (this.state.currentPlan !== 'basic' && this.state.currentPlan !== 'enterprise') {
                    Routing.instance.goTo('/subscriptionOrder/0');
                }
                else {
                    Routing.instance.goTo('/subscriptionPlanView');
                }
            }
            else {
                const url = 'http://app.upmesh.de/subscriptionPlanView';
                Linking.openURL(url).catch((err) => console.debug(err));
            }
        };
        this.updateVersion = () => {
            this.getVersion();
        };
        this.state = { currentPlan: 'basic', isLoading: true };
    }
    componentDidMount() {
        if (this.props.contractId != null) {
            const body = JSON.stringify({ Event: 'ContractCreated', ContractId: this.props.contractId });
            const headers = {};
            headers['Content-Type'] = `application/json`;
            fetch(`${UpmeshClient.instance.url}/subscription/billwerk/contracts`, {
                body,
                headers,
                method: 'POST',
            })
                .then((result) => {
                console.debug('create Contract', result);
                if (result.status >= 200) {
                    Routing.instance.goTo('/account', true);
                    this.getVersion();
                    requestAnimationFrame(() => {
                        this.openConfirmationDialog();
                    });
                }
                else {
                    console.debug('cant create Contract', result);
                }
            })
                .catch((e) => {
                console.debug('cant create Contract', e);
            });
        }
        else {
            this.getVersion();
        }
    }
    componentWillUnmount() {
        if (this.listenerID != null) {
            UpmeshClient.eventDispatcher.detach('Subscription', this.listenerID);
        }
    }
    openConfirmationDialog() {
        if (Dialog.instance != null) {
            RouterControl.instance.openUnRoutedDialog({
                scrollable: true,
                contentPadding: false,
                content: (<ProOnlyDialogContent headline={I18n.m.getMessage('subscriptionThankYou')} description={I18n.m.getMessage('subscriptionThankYouText')} buttonText={I18n.m.getMessage('subscriptionThankYouContinue')} buttonFunction={Dialog.instance.close} imageSource={require('../../assets/thanks_for_upgrading.png')} imagePosition="above"/>),
            });
        }
    }
    render() {
        const { currentPlan, isLoading, lockedText } = this.state;
        const text = I18n.m.getMessage(`subscriptionManagement${currentPlan.charAt(0).toUpperCase() + currentPlan.slice(1)}`);
        return (<View>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue, marginTop: 8 }}>
          <MaterialText>{I18n.m.getMessage('subscriptionManagement')}</MaterialText>
        </View>

        <Card>
          {isLoading ? (<View style={{ width: '100%', ...ThemeManager.style.contentPadding }}>
              <Spinner />
            </View>) : (<View style={{ width: '100%' }}>
              <View style={{ width: '100%', ...ThemeManager.style.contentPadding }}>
                <View style={{ flexDirection: 'row' }}>
                  <MaterialText>{I18n.m.getMessage('subscriptionManagementCurrent')}</MaterialText>
                  <MaterialText>{text}</MaterialText>
                  <MaterialText>{lockedText}</MaterialText>
                </View>
              </View>
              <View style={{
                    width: '100%',
                    paddingTop: ThemeManager.style.contentPaddingValue / 4,
                    paddingBottom: ThemeManager.style.contentPaddingValue / 4,
                    paddingLeft: ThemeManager.style.contentPaddingValue,
                    borderWidth: 0,
                    borderColor: ThemeManager.style.borderColor,
                    borderTopWidth: ThemeManager.style.borderWidth,
                }}>
                <ContainedButton title={I18n.m.getMessage('subscriptionManagementSeeMore')} onPress={this.gotoModules} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
              </View>
            </View>)}
        </Card>
      </View>);
    }
    getVersion() {
        if (this.listenerID == null) {
            this.listenerID = UpmeshClient.eventDispatcher.attach({
                readModelName: 'Subscription',
                callback: this.updateVersion,
            });
        }
        RightsManager.hasVersion(CurrentUser.userId)
            .then((currentPlan) => {
            CommandReadModels.subscription
                .get({
                filter: `userId eq '${CurrentUser.userId}' and expireDate gt ${new Date().toISOString()}`,
                orderby: 'createdAt ASC',
            })
                .then((subs) => {
                let subscription;
                if (subs.length > 0) {
                    subscription = subs[0];
                    const lockedText = subscription.locked
                        ? `(${I18n.m.getMessage('subscriptionManagementLocked')} - ${I18n.m.getMessage('subscriptionManagementLockedReasonPaymentEscalated')})`
                        : '';
                    this.setState({
                        subscription,
                        lockedText,
                        currentPlan,
                        isLoading: false,
                    });
                }
                else {
                    this.setState({ subscription, currentPlan, isLoading: false });
                }
            })
                .catch((err) => console.debug(err));
        })
            .catch((err) => console.debug(err));
    }
}

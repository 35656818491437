"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteWorkingTimeModel = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var WorkingTimeModelDeleted_1 = require("../../../events/company/workingtimemodel/WorkingTimeModelDeleted");
var DeleteWorkingTimeModel = function (_CommandRoot_1$Comman) {
  function DeleteWorkingTimeModel(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteWorkingTimeModel);
    _this = _callSuper(this, DeleteWorkingTimeModel, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'DeleteWorkingTimeModel';
    return _this;
  }
  (0, _inherits2.default)(DeleteWorkingTimeModel, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteWorkingTimeModel, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        this.workingTimeModel = yield CommandReadModels_1.CommandReadModels.workingTimeModel.getById(this.entityId);
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(this.workingTimeModel.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var companySettings = yield CommandReadModels_1.CommandReadModels.companySettings.getById(this.workingTimeModel.companyId);
        if (this.workingTimeModel.id === companySettings.defaultWorkingTimeModelId) {
          throw {
            message: 'Das Standard Arbeitszeitmodell kann nicht gelöscht werden',
            messageCode: 'workingTimeModelCantDeleteDefault'
          };
        }
        var members = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `workingTimeModelId eq '${this.entityId}'`
        });
        if (members.length > 0) {
          var users = [];
          for (var m of members) {
            var importent = true;
            if (m.deleted) {
              var c = yield CommandReadModels_1.CommandReadModels.timeTracking.get({
                filter: `memberId eq '${m.id}' and deleted ne true`,
                top: 1
              });
              if (c.length === 0) importent = false;
            }
            if (importent) users.push(`${m.firstName ? m.firstName : ''} ${m.lastName ? m.lastName : ''}`);
          }
          if (users.length > 0) {
            throw {
              message: 'Das Arbeitszeitmodell kann nicht gelöscht werden solange es einem aktiven Benutzer zugewiesen ist oder ein' + ' gelöschter Benutzer noch Zeiten erfasst hat. Benutzer: {{users}}',
              messageCode: 'workingTimeModelCantDeleteUsed',
              messageData: {
                users: users.join(', ')
              }
            };
          }
        }
        return new WorkingTimeModelDeleted_1.WorkingTimeModelDeleted(this.entityId, Object.assign({}, this.data), this.workingTimeModel.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteWorkingTimeModel = DeleteWorkingTimeModel;
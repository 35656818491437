import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { Linking, Platform } from 'react-native';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { I18n } from './i18n/I18n';
export class UpdateHint {
    static async openLink(link) {
        const canOpen = await Linking.canOpenURL(link);
        if (canOpen) {
            await Linking.openURL(link);
        }
        else {
            Routing.instance.alert.post({ text: I18n.m.getMessage('downloadLinkError') });
        }
    }
}
UpdateHint.showUpdateHintForConnection = () => {
    if (Alert.instance != null) {
        Routing.instance.alert.post({
            text: I18n.m.getMessage('updateNeededText'),
            title: I18n.m.getMessage('updateNeededTitle'),
            buttons: [
                <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="later" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                <ContainedButton key="now" title={I18n.m.getMessage('updateNeededButton')} onPress={UpdateHint.gotoStore}/>,
            ],
        });
    }
    else {
        setTimeout(UpdateHint.showUpdateHintForConnection, 1500);
    }
};
UpdateHint.gotoStore = () => {
    Alert.instance?.close();
    if (Platform.OS === 'web') {
        window.location.href = `${window.location.href}?upd=${new Date().getTime()}`;
    }
    else {
        let link = `https://app.upmesh.de`;
        if (Platform.OS === 'android')
            link = 'https://play.google.com/store/apps/details?id=com.upmesh';
        else if (Platform.OS === 'ios')
            link = 'https://apps.apple.com/us/app/upmesh-baudokumentation/id1475092940';
        UpdateHint.openLink(link).catch((err) => console.debug('cant open link', err));
    }
    console.log('open store');
};

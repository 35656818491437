import { ConfigAll } from './ConfigAll';
export class Config extends ConfigAll {
    static get instance() {
        if (Config._instance == null) {
            Config._instance = new Config();
        }
        return Config._instance;
    }
    constructor() {
        super();
        if (Config._instance == null) {
            Config._instance = this;
        }
        else {
            return Config._instance;
        }
    }
}

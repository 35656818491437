"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpgradeProject = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ProjectSubscriptionChanged_1 = require("../../events/project/ProjectSubscriptionChanged");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var UpgradeProject = function (_ClientProjectCommand) {
  function UpgradeProject(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, UpgradeProject);
    _this = _callSuper(this, UpgradeProject, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'UpgradeProject';
    return _this;
  }
  (0, _inherits2.default)(UpgradeProject, _ClientProjectCommand);
  return (0, _createClass2.default)(UpgradeProject, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var members = yield CommandReadModels_1.CommandReadModels.instance.projectUser.get({
          filter: `projectId eq '${this.data.project.id}' and (status eq 'accepted' or status eq 'invited')`
        });
        var downgradeList = [];
        for (var member of members) {
          if (!(member.roleId === '2' || member.roleId === '3' || member.roleId === '4')) {
            if (member.userId == null || member.userId.length === 0) {
              throw 'please downgrade all team members to projects members before, ' + 'which has no pro/enterprise account and are no subconstractors or costumers';
            }
            var v = yield RightsManager_1.RightsManager.hasVersion(member.userId);
            if (v === 'basic') {
              downgradeList.push(member);
            }
          }
        }
        if (downgradeList.length > 0) {
          throw {
            downgradeList: downgradeList,
            error: 'please downgrade all team members to projects members before, ' + 'which has no pro/enterprise account and are no subconstractors or costumers'
          };
        }
        if (_key == null || _key === 'version') {
          if (this.data.version === 'enterprise') {
            var companies = yield ReadModels_1.ReadModels.get('company').get({
              filter: `users/userId eq '${userId}'`
            });
            if (companies.length === 0) {
              throw {
                message: 'Keine Berechtigung',
                messageCode: 'forbiddenCommand'
              };
            }
            this.company = companies[0];
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === this.data.project.creator) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = this.data.version === 'enterprise' ? {
          projectSubscription: this.data.version,
          companyId: this.company.id,
          oldOwnerId: this.data.project.creator
        } : {
          projectSubscription: this.data.version
        };
        return new ProjectSubscriptionChanged_1.ProjectSubscriptionChanged(this.data.project.id, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.UpgradeProject = UpgradeProject;
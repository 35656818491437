"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TicketFilter = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var UpmeshClient_1 = require("../../UpmeshClient");
var Filter_1 = require("./Filter");
var TicketFilter = function () {
  function TicketFilter() {
    (0, _classCallCheck2.default)(this, TicketFilter);
  }
  return (0, _createClass2.default)(TicketFilter, null, [{
    key: "isSet",
    value: function isSet(filter) {
      return filter != null && (filter.st != null || filter.u != null && filter.u.length > 0 || filter.s != null && filter.s.length > 0 || filter.a === 1 || filter.a === 2 || filter.d === 1 || filter.d === 2 || filter.sub != null || filter.p != null && filter.p.length > 0 || filter.t != null && filter.t.length > 0 || filter.tt != null && filter.tt.length > 0 || filter.c != null && filter.c.length > 0 || filter.cf != null && filter.cf.length > 0 || filter.is != null || filter.ie != null);
    }
  }, {
    key: "filterByArchivedAndDeleted",
    value: function filterByArchivedAndDeleted(tickets, a, d) {
      if (a === undefined && d === 1) {
        return tickets.filter(function (ticket) {
          return ticket.deleted && !ticket.archived || !ticket.archived && !ticket.deleted;
        });
      }
      if (a === undefined && d === 2) {
        return tickets.filter(function (ticket) {
          return ticket.deleted && !ticket.archived;
        });
      }
      if (a === 1 && d === undefined) {
        return tickets.filter(function (ticket) {
          return ticket.archived && !ticket.deleted || !ticket.archived && !ticket.deleted;
        });
      }
      if (a === 1 && d === 2) {
        return tickets.filter(function (ticket) {
          return ticket.deleted || ticket.deleted && ticket.archived;
        });
      }
      if (a === 2 && d === 1) {
        return tickets.filter(function (ticket) {
          return ticket.archived || ticket.archived && ticket.deleted;
        });
      }
      if (a === 1 && d === 1) {
        return tickets;
      }
      if (a === 2 && d === undefined) {
        return tickets.filter(function (ticket) {
          return ticket.archived && !ticket.deleted;
        });
      }
      if (a === 2 && d === 2) {
        return tickets.filter(function (ticket) {
          return ticket.archived && ticket.deleted;
        });
      }
      return tickets.filter(function (ticket) {
        return !ticket.archived && !ticket.deleted;
      });
    }
  }, {
    key: "filterTickets",
    value: function () {
      var _filterTickets = (0, _asyncToGenerator2.default)(function* (tickets, filter) {
        if (filter != null && TicketFilter.isSet(filter)) {
          var counted = 0;
          var filteredTickets = [];
          var filteredByArchivedDeleted = TicketFilter.filterByArchivedAndDeleted(tickets, filter.a, filter.d);
          filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByArchivedDeleted));
          counted += 1;
          if (filter.sub != null && filter.sub === '2') {
            var filteredBySubs = yield TicketFilter.filterTicketsByHideSubs(tickets, true);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredBySubs));
            counted += 1;
          } else if (filter.sub != null && filter.sub === '1') {
            var filteredByMain = yield TicketFilter.filterTicketsByHideMain(tickets, true);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByMain));
            counted += 1;
          } else if (filter.sub != null) {
            var filteredBySubsFrom = yield TicketFilter.filterTicketsBySubsFrom(tickets, filter.sub);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredBySubsFrom));
            counted += 1;
          }
          if (filter.u != null && filter.u.length > 0) {
            if (filter.u2 != null && filter.u2.length > 0) {
              var andOrOr = filter.u3 && filter.u3.length > 0 ? 'and' : 'or';
              if (andOrOr === 'or') counted += 1;
              if (filter.u2.indexOf('createdBy') >= 0) {
                var filteredByUserId = yield TicketFilter.filterTicketsByCreators(tickets, filter.u);
                filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByUserId));
                if (andOrOr === 'and') counted += 1;
              }
              if (filter.u2.indexOf('watchedBy') >= 0) {
                var _filteredByUserId = yield TicketFilter.filterTicketsWatcherIds(tickets, filter.u);
                filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(_filteredByUserId));
                if (andOrOr === 'and') counted += 1;
              }
              if (filter.u2.indexOf('assignedTo') >= 0) {
                var _filteredByUserId2 = yield TicketFilter.filterTicketsByAssignedTo(tickets, filter.u);
                filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(_filteredByUserId2));
                if (andOrOr === 'and') counted += 1;
              }
              if (filter.u2.indexOf('lastModifiedBy') >= 0) {
                var _filteredByUserId3 = yield TicketFilter.filterTicketsByLastModifiedBy(tickets, filter.u);
                filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(_filteredByUserId3));
                if (andOrOr === 'and') counted += 1;
              }
              if (filter.u2.indexOf('approvedBy') >= 0) {
                var _filteredByUserId4 = yield TicketFilter.filterTicketsByApprover(tickets, filter.u);
                filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(_filteredByUserId4));
                if (andOrOr === 'and') counted += 1;
              }
            } else {
              var _filteredByUserId5 = yield TicketFilter.filterTicketsByUserIds(tickets, filter.u);
              filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(_filteredByUserId5));
              counted += 1;
            }
          }
          if (filter.s != null && filter.s.length > 0) {
            var filteredByStatus = yield TicketFilter.filterTicketsByStatus(tickets, filter.s);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByStatus));
            counted += 1;
          }
          if (filter.t != null && filter.t.length > 0) {
            var filteredByTags = yield TicketFilter.filterTicketsByTagStringAnd(tickets, filter.t);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByTags));
            counted += 1;
          }
          if (filter.tt != null && filter.tt.length > 0) {
            var filteredByTicketTypes = yield TicketFilter.filterTicketsByStringArray('type', tickets, filter.tt);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByTicketTypes));
            counted += 1;
          }
          if (filter.c != null && filter.c.length > 0) {
            var filteredByCrafts = yield TicketFilter.filterTicketsByStringArray('craft', tickets, filter.c);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByCrafts));
            counted += 1;
          }
          if (filter.p != null && filter.p.length > 0) {
            var filteredByProjectIds = yield TicketFilter.filterTicketsByStringArray('projectId', tickets, filter.p);
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByProjectIds));
            counted += 1;
          }
          if (filter.st != null) {
            var filterTicketsByCompletionSmallerThan = yield TicketFilter.filterTicketsByCompletionSmallerThan(tickets, new Date(filter.st));
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filterTicketsByCompletionSmallerThan));
            counted += 1;
          }
          if (filter.is != null && filter.ie != null) {
            var filterTicketsByActivity = yield TicketFilter.filterTicketsByInterval(tickets, new Date(filter.is), new Date(filter.ie));
            filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filterTicketsByActivity));
            counted += 1;
          }
          if (filter.cf != null && filter.cf.length > 0) {
            for (var f of filter.cf) {
              var filteredByCustomFields = yield TicketFilter.filterTicketsByCustomFields(tickets, f);
              filteredTickets.push.apply(filteredTickets, (0, _toConsumableArray2.default)(filteredByCustomFields));
              counted += 1;
            }
          }
          var counts = [];
          filteredTickets.forEach(function (item) {
            if (counts[item.id] === undefined) {
              counts[item.id] = 0;
            }
            counts[item.id] += 1;
            return counts;
          });
          var unique = filteredTickets.filter(function (x, i, l) {
            return l.indexOf(x) === i;
          });
          var filterByCounts = function filterByCounts(element, _index, _array) {
            if (counts[element.id] !== undefined && counts[element.id] >= counted) {
              return true;
            }
            return false;
          };
          var filteredByCounts = unique.filter(filterByCounts);
          return filteredByCounts;
        }
        return TicketFilter.filterByArchivedAndDeleted(tickets, undefined, undefined);
      });
      function filterTickets(_x, _x2) {
        return _filterTickets.apply(this, arguments);
      }
      return filterTickets;
    }()
  }, {
    key: "filterTicketsByText",
    value: function () {
      var _filterTicketsByText = (0, _asyncToGenerator2.default)(function* (text, tickets) {
        var includePlanTags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var words = text.trim().split(' ');
        if (tickets == null || tickets.length === 0) {
          return tickets;
        }
        var planCache = {};
        if (includePlanTags) {
          for (var i = 0; i < tickets.length; i += 1) {
            var t = tickets[i];
            if (t != null && t.planId != null && t.planId.length > 0) {
              try {
                var plan = planCache[t.planId] != null ? planCache[t.planId] : yield UpmeshClient_1.UpmeshClient.instance.modals.plan.getById(t.planId);
                planCache[t.planId] = plan;
                var tags = [];
                if (t.tags != null && t.tags.length > 0) {
                  tags.push.apply(tags, (0, _toConsumableArray2.default)(t.tags));
                }
                if (plan != null && plan.tags != null && plan.tags.length > 0) {
                  tags.push.apply(tags, (0, _toConsumableArray2.default)(plan.tags));
                }
                tickets[i].tags = tags;
              } catch (err) {}
            }
          }
        }
        function filterByTitle(element, _index, _array) {
          var matches = 0;
          for (var _i = 0; _i < words.length; _i += 1) {
            var w = words[_i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchA = element.title.match(regexp);
              if (matchA != null && matchA.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        function filterByAddress(element, _index, _array) {
          var matches = 0;
          for (var _i2 = 0; _i2 < words.length; _i2 += 1) {
            var w = words[_i2].trim();
            if (w.length > 0) {
              var _element$address;
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchA = (_element$address = element.address) == null ? void 0 : _element$address.match(regexp);
              if (matchA != null && matchA.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        function filterByBuilderAddress(element, _index, _array) {
          var matches = 0;
          for (var _i3 = 0; _i3 < words.length; _i3 += 1) {
            var w = words[_i3].trim();
            if (w.length > 0) {
              var _element$builder;
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchA = (_element$builder = element.builder) == null ? void 0 : _element$builder.address.address.match(regexp);
              if (matchA != null && matchA.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        function filterByBuilderName(element, _index, _array) {
          var matches = 0;
          for (var _i4 = 0; _i4 < words.length; _i4 += 1) {
            var w = words[_i4].trim();
            if (w.length > 0) {
              var _element$builder2;
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchA = (_element$builder2 = element.builder) == null ? void 0 : _element$builder2.name.match(regexp);
              if (matchA != null && matchA.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        function filterByType(element, _index, _array) {
          var matches = 0;
          for (var _i5 = 0; _i5 < words.length; _i5 += 1) {
            var w = words[_i5].trim();
            if (w.length > 0) {
              var _element$type;
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchA = (_element$type = element.type) == null ? void 0 : _element$type.match(regexp);
              if (matchA != null && matchA.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        function filterByCraft(element, _index, _array) {
          var matches = 0;
          for (var _i6 = 0; _i6 < words.length; _i6 += 1) {
            var w = words[_i6].trim();
            if (w.length > 0) {
              var _element$craft;
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchA = (_element$craft = element.craft) == null ? void 0 : _element$craft.match(regexp);
              if (matchA != null && matchA.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        function filterByTags(element, _index, _array) {
          if (element.tags != null && element.tags.length > 0) {
            var _loop = function _loop(_i7) {
                if (words[_i7] != null && words[_i7].length > 0) {
                  return {
                    v: element.tags.findIndex(function (t) {
                      var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[_i7]), 'i');
                      var matchN = t.tagName.match(regexp);
                      return matchN != null && matchN.length > 0;
                    }) > -1
                  };
                }
              },
              _ret;
            for (var _i7 = 0; _i7 < words.length; _i7 += 1) {
              _ret = _loop(_i7);
              if (_ret) return _ret.v;
            }
          }
          return false;
        }
        function filterByCustomFields(element, _index, _array) {
          if (element.fields != null && element.fields.length > 0) {
            var _loop2 = function _loop2(_i8) {
                if (words[_i8] != null && words[_i8].length > 0) {
                  return {
                    v: element.fields.findIndex(function (t) {
                      var regexp = new RegExp(Filter_1.Filter.escapeRegExp(words[_i8]), 'i');
                      var matchN = null;
                      try {
                        matchN = t.value ? t.value.toString().match(regexp) : null;
                      } catch (e) {}
                      var matchN2 = null;
                      try {
                        matchN2 = t.formattedValue ? t.formattedValue.toString().match(regexp) : null;
                      } catch (e) {}
                      return matchN != null && matchN.length > 0 || matchN2 != null && matchN2.length > 0;
                    }) > -1
                  };
                }
              },
              _ret2;
            for (var _i8 = 0; _i8 < words.length; _i8 += 1) {
              _ret2 = _loop2(_i8);
              if (_ret2) return _ret2.v;
            }
          }
          return false;
        }
        function filterByDescription(element, _index, _array) {
          if (element.description != null && element.description.length > 0) {
            var matches = 0;
            for (var _i9 = 0; _i9 < words.length; _i9 += 1) {
              var w = words[_i9].trim();
              if (w.length > 0) {
                var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
                var matchN = element.description.match(regexp);
                if (matchN != null && matchN.length > 0) {
                  matches += 1;
                }
              } else {
                matches += 1;
              }
            }
            return matches === words.length;
          }
          return false;
        }
        function filterByNumber(element, _index, _array) {
          if (element.ticketNumber != null && element.ticketNumber > 0) {
            var w = text.trim();
            var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
            var matchN = element.ticketNumber.toString().match(regexp);
            if (matchN != null && matchN.length > 0) {
              return true;
            }
          }
          return false;
        }
        if (text != null && text.length > 0) {
          var filteredByTitle = tickets.filter(filterByTitle);
          var filteredByAddress = tickets.filter(filterByAddress);
          var filteredByBuilderAddress = tickets.filter(filterByBuilderAddress);
          var filteredByBuilderName = tickets.filter(filterByBuilderName);
          var filteredByType = tickets.filter(filterByType);
          var filteredByCraft = tickets.filter(filterByCraft);
          var filteredByDescription = tickets.filter(filterByDescription);
          var filteredByNumber = tickets.filter(filterByNumber);
          var filteredByTags = tickets.filter(filterByTags);
          var filteredByCustomFields = tickets.filter(filterByCustomFields);
          var result = [].concat((0, _toConsumableArray2.default)(filteredByTitle), (0, _toConsumableArray2.default)(filteredByAddress), (0, _toConsumableArray2.default)(filteredByBuilderAddress), (0, _toConsumableArray2.default)(filteredByBuilderName), (0, _toConsumableArray2.default)(filteredByType), (0, _toConsumableArray2.default)(filteredByCraft), (0, _toConsumableArray2.default)(filteredByDescription), (0, _toConsumableArray2.default)(filteredByNumber), (0, _toConsumableArray2.default)(filteredByTags), (0, _toConsumableArray2.default)(filteredByCustomFields));
          var counts = [];
          result.forEach(function (item) {
            if (counts[item.id] === undefined) {
              counts[item.id] = 0;
            }
            counts[item.id] += 1;
            return counts;
          });
          var unique = result.filter(function (x, i, l) {
            return l.indexOf(x) === i;
          });
          return unique.sort(function (v1, v2) {
            return counts[v2.id] - counts[v1.id];
          });
        }
        return tickets;
      });
      function filterTicketsByText(_x3, _x4) {
        return _filterTicketsByText.apply(this, arguments);
      }
      return filterTicketsByText;
    }()
  }, {
    key: "filterTicketsByCreators",
    value: function () {
      var _filterTicketsByCreators = (0, _asyncToGenerator2.default)(function* (tickets, userIds) {
        if (userIds != null && userIds.length > 0) {
          var filterByCreatorId = function filterByCreatorId(element, _index, _array) {
            for (var i = 0; i < userIds.length; i += 1) {
              if (userIds[i] === 'notAssigned') return element.createdBy == null || element.createdBy.length === 0;
              if (userIds[i] != null && userIds[i].length > 0) {
                if (userIds[i] === element.createdBy) {
                  return true;
                }
              }
            }
            return false;
          };
          return tickets.filter(filterByCreatorId);
        }
        return [];
      });
      function filterTicketsByCreators(_x5, _x6) {
        return _filterTicketsByCreators.apply(this, arguments);
      }
      return filterTicketsByCreators;
    }()
  }, {
    key: "filterTicketsByApprover",
    value: function () {
      var _filterTicketsByApprover = (0, _asyncToGenerator2.default)(function* (tickets, userIds) {
        if (userIds != null && userIds.length > 0) {
          var filterByApproverId = function filterByApproverId(element, _index, _array) {
            for (var i = 0; i < userIds.length; i += 1) {
              if (userIds[i] === 'notAssigned') {
                return element.approverUserId == null || element.approverUserId === '';
              }
              if (userIds[i] != null && userIds[i].length > 0) {
                if (userIds[i] === element.approverUserId) {
                  return true;
                }
              }
            }
            return false;
          };
          return tickets.filter(filterByApproverId);
        }
        return [];
      });
      function filterTicketsByApprover(_x7, _x8) {
        return _filterTicketsByApprover.apply(this, arguments);
      }
      return filterTicketsByApprover;
    }()
  }, {
    key: "filterTicketsByPlanIds",
    value: function () {
      var _filterTicketsByPlanIds = (0, _asyncToGenerator2.default)(function* (tickets, planIds) {
        function filter(element, _index, _array) {
          if (planIds != null && planIds.length > 0) {
            for (var i = 0; i < planIds.length; i += 1) {
              if (planIds[i] != null && planIds[i].length > 0 && element.planId != null) {
                if (planIds[i] === element.planId) {
                  return true;
                }
              }
            }
          } else if (element.planId == null || element.planId.length === 0) {
            return true;
          }
          return false;
        }
        return tickets.filter(filter);
      });
      function filterTicketsByPlanIds(_x9, _x10) {
        return _filterTicketsByPlanIds.apply(this, arguments);
      }
      return filterTicketsByPlanIds;
    }()
  }, {
    key: "filterTicketsWatcherIds",
    value: function () {
      var _filterTicketsWatcherIds = (0, _asyncToGenerator2.default)(function* (tickets, userIds) {
        function filterByWatcherIds(element, _index, _array) {
          if (userIds != null && userIds.length > 0) {
            for (var i = 0; i < userIds.length; i += 1) {
              if (userIds[i] === 'notAssigned') return element.watchers == null || element.watchers.length === 0;
              if (userIds[i] != null && userIds[i].length > 0) {
                if (element.watchers != null && element.watchers.length > 0) {
                  for (var j = 0; j < element.watchers.length; j += 1) {
                    if (userIds[i] === element.watchers[j].userId) {
                      return true;
                    }
                  }
                }
              }
            }
          } else if (element.watchers == null || element.watchers.length === 0) {
            return true;
          }
          return false;
        }
        return tickets.filter(filterByWatcherIds);
      });
      function filterTicketsWatcherIds(_x11, _x12) {
        return _filterTicketsWatcherIds.apply(this, arguments);
      }
      return filterTicketsWatcherIds;
    }()
  }, {
    key: "filterTicketsByAssignedTo",
    value: function () {
      var _filterTicketsByAssignedTo = (0, _asyncToGenerator2.default)(function* (tickets, userIds) {
        function filterByAssignedTo(element, _index, _array) {
          if (userIds != null && userIds.length > 0) {
            for (var i = 0; i < userIds.length; i += 1) {
              if (userIds[i] === 'notAssigned') {
                return element.assignedToUserId == null || element.assignedToUserId === '';
              }
              if (userIds[i] != null && userIds[i].length > 0) {
                if (userIds[i] === element.assignedToUserId) {
                  return true;
                }
              }
            }
          } else if (element.assignedToUserId == null || element.assignedToUserId.length === 0) {
            return true;
          }
          return false;
        }
        return tickets.filter(filterByAssignedTo);
      });
      function filterTicketsByAssignedTo(_x13, _x14) {
        return _filterTicketsByAssignedTo.apply(this, arguments);
      }
      return filterTicketsByAssignedTo;
    }()
  }, {
    key: "filterTicketsByLastModifiedBy",
    value: function () {
      var _filterTicketsByLastModifiedBy = (0, _asyncToGenerator2.default)(function* (tickets, userIds) {
        function filterByLastModifiedBy(element, _index, _array) {
          if (userIds != null && userIds.length > 0) {
            for (var i = 0; i < userIds.length; i += 1) {
              if (userIds[i] === 'notAssigned') {
                return element.lastModifiedBy == null || element.lastModifiedBy.length === 0;
              }
              if (userIds[i] != null && userIds[i].length > 0) {
                if (userIds[i] === element.lastModifiedBy) {
                  return true;
                }
              }
            }
          } else if (element.lastModifiedBy == null || element.lastModifiedBy.length === 0) {
            return true;
          }
          return false;
        }
        return tickets.filter(filterByLastModifiedBy);
      });
      function filterTicketsByLastModifiedBy(_x15, _x16) {
        return _filterTicketsByLastModifiedBy.apply(this, arguments);
      }
      return filterTicketsByLastModifiedBy;
    }()
  }, {
    key: "filterTicketsByUserIds",
    value: function () {
      var _filterTicketsByUserIds = (0, _asyncToGenerator2.default)(function* (tickets, userIds) {
        if (userIds != null && userIds.length > 0) {
          var filteredByCreatorId = TicketFilter.filterTicketsByCreators(tickets, userIds);
          var filteredByWatcherIds = TicketFilter.filterTicketsWatcherIds(tickets, userIds);
          var filteredByAssignedTo = TicketFilter.filterTicketsByAssignedTo(tickets, userIds);
          var filteredByApprovedBy = TicketFilter.filterTicketsByApprover(tickets, userIds);
          var promises = yield Promise.all([filteredByCreatorId, filteredByWatcherIds, filteredByAssignedTo, filteredByApprovedBy]);
          var result = [];
          promises.forEach(function (array) {
            result = result.concat(array);
          });
          var counts = [];
          result.forEach(function (item) {
            if (counts[item.id] === undefined) {
              counts[item.id] = 0;
            }
            counts[item.id] += 1;
            return counts;
          });
          var unique = result.filter(function (x, i, l) {
            return l.indexOf(x) === i;
          });
          var sorted = unique.sort(function (v1, v2) {
            return counts[v2.id] - counts[v1.id];
          });
          return sorted;
        }
        return tickets;
      });
      function filterTicketsByUserIds(_x17, _x18) {
        return _filterTicketsByUserIds.apply(this, arguments);
      }
      return filterTicketsByUserIds;
    }()
  }, {
    key: "filterTicketsByStatus",
    value: function () {
      var _filterTicketsByStatus = (0, _asyncToGenerator2.default)(function* (tickets, status) {
        if (status == null || status.length === 0) {
          return [];
        }
        function filterByStati(element, _index, _array) {
          if (status != null && status.length > 0) {
            for (var i = 0; i < status.length; i += 1) {
              if (status[i] != null && status[i].length > 0) {
                if (status[i] === `${element.ticketStatus}`) {
                  return true;
                }
              }
            }
          }
          return false;
        }
        return tickets.filter(filterByStati);
      });
      function filterTicketsByStatus(_x19, _x20) {
        return _filterTicketsByStatus.apply(this, arguments);
      }
      return filterTicketsByStatus;
    }()
  }, {
    key: "filterTicketsByCompletionGreaterThan",
    value: function () {
      var _filterTicketsByCompletionGreaterThan = (0, _asyncToGenerator2.default)(function* (tickets, date) {
        if (date == null) {
          return [];
        }
        function filterByDate(element, _index, _array) {
          if (date != null && element.completionOn != null) {
            if (date <= element.completionOn) {
              return true;
            }
          }
          return false;
        }
        return tickets.filter(filterByDate);
      });
      function filterTicketsByCompletionGreaterThan(_x21, _x22) {
        return _filterTicketsByCompletionGreaterThan.apply(this, arguments);
      }
      return filterTicketsByCompletionGreaterThan;
    }()
  }, {
    key: "filterTicketsByCompletionSmallerThan",
    value: function () {
      var _filterTicketsByCompletionSmallerThan = (0, _asyncToGenerator2.default)(function* (tickets, date) {
        if (date == null) {
          return [];
        }
        function filterByDate(element, _index, _array) {
          if (date != null && element.completionOn != null) {
            if (date >= element.completionOn) {
              return true;
            }
          }
          return false;
        }
        return tickets.filter(filterByDate);
      });
      function filterTicketsByCompletionSmallerThan(_x23, _x24) {
        return _filterTicketsByCompletionSmallerThan.apply(this, arguments);
      }
      return filterTicketsByCompletionSmallerThan;
    }()
  }, {
    key: "filterTicketsByHideSubs",
    value: function () {
      var _filterTicketsByHideSubs = (0, _asyncToGenerator2.default)(function* (tickets, hideSubs) {
        if (!hideSubs) {
          return tickets;
        }
        var filterBySubs = function filterBySubs(element, _index, _array) {
          return element.subTicketFrom == null;
        };
        return tickets.filter(filterBySubs);
      });
      function filterTicketsByHideSubs(_x25, _x26) {
        return _filterTicketsByHideSubs.apply(this, arguments);
      }
      return filterTicketsByHideSubs;
    }()
  }, {
    key: "filterTicketsByHideMain",
    value: function () {
      var _filterTicketsByHideMain = (0, _asyncToGenerator2.default)(function* (tickets, hideMain) {
        if (!hideMain) {
          return tickets;
        }
        var filterByMain = function filterByMain(element, _index, _array) {
          return element.subTicketFrom != null;
        };
        return tickets.filter(filterByMain);
      });
      function filterTicketsByHideMain(_x27, _x28) {
        return _filterTicketsByHideMain.apply(this, arguments);
      }
      return filterTicketsByHideMain;
    }()
  }, {
    key: "filterTicketsBySubsFrom",
    value: function () {
      var _filterTicketsBySubsFrom = (0, _asyncToGenerator2.default)(function* (tickets, parentId) {
        if (parentId == null) {
          return tickets;
        }
        var filterBySubTicketFrom = function filterBySubTicketFrom(element, _index, _array) {
          return element.subTicketFrom === parentId;
        };
        return tickets.filter(filterBySubTicketFrom);
      });
      function filterTicketsBySubsFrom(_x29, _x30) {
        return _filterTicketsBySubsFrom.apply(this, arguments);
      }
      return filterTicketsBySubsFrom;
    }()
  }, {
    key: "filterTicketsByTags",
    value: function () {
      var _filterTicketsByTags = (0, _asyncToGenerator2.default)(function* (tickets, tags) {
        function filterByTags(element, _index, _array) {
          if (tags != null && tags.length > 0) {
            for (var i = 0; i < tags.length; i += 1) {
              if (tags[i] != null && tags[i].tagName != null) {
                if (element.tags != null && element.tags.length > 0) {
                  for (var j = 0; j < element.tags.length; j += 1) {
                    if (tags[i].tagName === element.tags[j].tagName) {
                      return true;
                    }
                  }
                }
              }
            }
          } else if (element.tags == null || element.tags.length === 0) {
            return true;
          }
          return false;
        }
        return tickets.filter(filterByTags);
      });
      function filterTicketsByTags(_x31, _x32) {
        return _filterTicketsByTags.apply(this, arguments);
      }
      return filterTicketsByTags;
    }()
  }, {
    key: "filterTicketsByTagStringAnd",
    value: function () {
      var _filterTicketsByTagStringAnd = (0, _asyncToGenerator2.default)(function* (tickets, tags) {
        function filterByTags(element, _index, _array) {
          if (element.tags == null || element.tags.length === 0) {
            return false;
          }
          var c = 0;
          var _loop3 = function _loop3(i) {
            var index = element.tags.findIndex(function (a) {
              return a.tagName === tags[i];
            });
            if (index > -1) c += 1;
          };
          for (var i = 0; i < tags.length; i += 1) {
            _loop3(i);
          }
          return c === tags.length;
        }
        if (tags != null && tags.length > 0) return tickets.filter(filterByTags);
        return tickets;
      });
      function filterTicketsByTagStringAnd(_x33, _x34) {
        return _filterTicketsByTagStringAnd.apply(this, arguments);
      }
      return filterTicketsByTagStringAnd;
    }()
  }, {
    key: "filterTicketsByCustomFields",
    value: function () {
      var _filterTicketsByCustomFields = (0, _asyncToGenerator2.default)(function* (tickets, filter) {
        function filterElements(element, _index, _array) {
          var f = filter;
          if (f.type === 'Date' && f.value.from && f.value.to && element.fields != null && element.fields.length > 0) {
            var from = new Date(f.value.from);
            var to = new Date(f.value.to);
            for (var e of element.fields) {
              if (e.id === f.id && e.value) {
                if (e.value['date']) {
                  var d = new Date(e.value['date']);
                  if (d.getTime() > from.getTime() && d.getTime() < to.getTime()) return true;
                } else if (e.value['from'] && e.value['to']) {
                  var _f = new Date(e.value['from']);
                  var t = new Date(e.value['to']);
                  if (_f.getTime() > from.getTime() && _f.getTime() < to.getTime() || t.getTime() > from.getTime() && t.getTime() < to.getTime()) return true;
                }
              }
            }
          } else if ((f.type === 'List' || f.type === 'MultiselectList' || f.type === 'CheckboxList') && f.value && element.fields != null && element.fields.length > 0) {
            for (var _e of element.fields) {
              if (_e.id === f.id && _e.value) {
                var v = f.type === 'List' ? [_e.value] : _e.value;
                for (var a of v) {
                  for (var i = 0; i < filter.value.length; i += 1) {
                    if (filter.value[i] != null && filter.value[i].length > 0) {
                      if (filter.value[i].toLowerCase() === 'null' && (a == null || a.length === 0)) {
                        return true;
                      }
                      if (a != null && a.length > 0) {
                        if (filter.value[i].toLowerCase() === a.toLowerCase()) {
                          return true;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          return false;
        }
        return tickets.filter(filterElements);
      });
      function filterTicketsByCustomFields(_x35, _x36) {
        return _filterTicketsByCustomFields.apply(this, arguments);
      }
      return filterTicketsByCustomFields;
    }()
  }, {
    key: "filterTicketsByStringArray",
    value: function () {
      var _filterTicketsByStringArray = (0, _asyncToGenerator2.default)(function* (field, tickets, filter) {
        function filterElements(element, _index, _array) {
          if (filter != null && filter.length > 0) {
            for (var i = 0; i < filter.length; i += 1) {
              if (filter[i] != null && filter[i].length > 0) {
                var _element$field;
                if (filter[i].toLowerCase() === 'null' && (element[field] == null || ((_element$field = element[field]) == null ? void 0 : _element$field.length) === 0)) {
                  return true;
                }
                if (element[field] != null && element[field].length > 0) {
                  if (filter[i].toLowerCase() === element[field].toLowerCase()) {
                    return true;
                  }
                }
              }
            }
          } else if (element[field] == null || element[field].length === 0) {
            return true;
          }
          return false;
        }
        return tickets.filter(filterElements);
      });
      function filterTicketsByStringArray(_x37, _x38, _x39) {
        return _filterTicketsByStringArray.apply(this, arguments);
      }
      return filterTicketsByStringArray;
    }()
  }, {
    key: "filterTicketsByTagStringOr",
    value: function () {
      var _filterTicketsByTagStringOr = (0, _asyncToGenerator2.default)(function* (tickets, filter) {
        function filterByTags(element, _index, _array) {
          if (filter != null && filter.length > 0) {
            for (var i = 0; i < filter.length; i += 1) {
              if (filter[i].toLowerCase() === 'null' && (element.tags == null || element.tags.length === 0)) {
                return true;
              }
              if (filter[i] != null && filter[i].length > 0) {
                if (element.tags != null && element.tags.length > 0) {
                  for (var j = 0; j < element.tags.length; j += 1) {
                    if (filter[i].toLowerCase() === element.tags[j].tagName.toLowerCase()) {
                      return true;
                    }
                  }
                }
              }
            }
          } else if (element.tags == null || element.tags.length === 0) {
            return true;
          }
          return false;
        }
        return tickets.filter(filterByTags);
      });
      function filterTicketsByTagStringOr(_x40, _x41) {
        return _filterTicketsByTagStringOr.apply(this, arguments);
      }
      return filterTicketsByTagStringOr;
    }()
  }, {
    key: "filterTicketsByInterval",
    value: function () {
      var _filterTicketsByInterval = (0, _asyncToGenerator2.default)(function* (tickets, periodStart, periodEnd) {
        var logBookEventFilter = TicketFilter.createLogBookEventFilter();
        var logBookPromises = [];
        var model = ReadModels_1.ReadModels.get('LogBook');
        tickets.forEach(function (ticket) {
          logBookPromises.push(model.get({
            filter: `entityId eq '${ticket.id}' and (${logBookEventFilter})`
          }));
        });
        var logBookEntrys = yield Promise.all(logBookPromises);
        var resultTickets = [];
        for (var i = 0; i < tickets.length; i += 1) {
          if (logBookEntrys[i].length != null && logBookEntrys[i].length > 0) {
            var found = false;
            for (var j = 0; j < logBookEntrys[i].length && !found; j += 1) {
              var entry = logBookEntrys[i][j];
              if (periodStart <= entry.createdAt && entry.createdAt <= periodEnd) {
                found = true;
              }
            }
            if (found) {
              resultTickets.push(tickets[i]);
            }
          }
        }
        return resultTickets;
      });
      function filterTicketsByInterval(_x42, _x43, _x44) {
        return _filterTicketsByInterval.apply(this, arguments);
      }
      return filterTicketsByInterval;
    }()
  }, {
    key: "createLogBookEventFilter",
    value: function createLogBookEventFilter() {
      var logBookEvents = ['TicketCreated', 'TicketDescriptionChanged', 'TicketNumberChanged', 'TicketStatusChanged', 'TicketTagsChanged', 'TicketApproverRemoved', 'TicketAssignerRemoved', 'TicketCompletionOnChanged', 'TicketCompletionOnRemoved', 'TicketTitleChanged', 'TicketTypeChanged', 'TicketPlanAffiliationChanged', 'TicketPlanPositionChanged', 'TicketWatcherAdded', 'TicketWatcherRemoved', 'TicketAssignedChanged', 'TicketApproverChanged'];
      var odataFilter = [];
      logBookEvents.forEach(function (event) {
        odataFilter.push(`eventName eq '${event}'`);
      });
      return odataFilter.join(' or ');
    }
  }]);
}();
exports.TicketFilter = TicketFilter;
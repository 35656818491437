import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
export class DashBoardItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (<View style={{
                margin: ThemeManager.style.contentPaddingValue,
                marginBottom: 0,
                flexDirection: 'row',
                alignItems: 'center',
                alignSelf: 'stretch',
                justifyContent: 'space-between',
            }}>
        <Icon toolTip="" iconIconMoon={this.props.iconIconMoon === true} iconSize={64} color={this.props.iconColor} icon={this.props.icon}/>
        <View style={{
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                alignContent: 'flex-end',
                backgroundColor: 'transparent',
                flex: 1,
            }}>
          <View style={{ width: '100%' }}>
            <MaterialText type={MaterialTextTypes.H5} fixedWidth="100%" numberOfLines={1} textAlign="right">
              {this.props.title}
            </MaterialText>
          </View>
          <View>
            <MaterialText color={ThemeManager.style.primaryTextColor} type={MaterialTextTypes.H3}>
              {this.props.counts}
              {this.props.counts2 != null ? ` / ${this.props.counts2}` : ''}
            </MaterialText>
          </View>
        </View>
      </View>);
    }
}

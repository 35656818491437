import { Url } from 'cqrs-shared/src/uri/Url';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import BackButton from 'materialTheme/src/views/components/BackButton';
import { PageView } from 'materialTheme/src/views/root/PageView';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { CompanyCostCenterView } from './CompanyCostCenterView';
import { CompanyCraftView } from './CompanyCraftView';
import { CompanyExtraPayView } from './CompanyExtraPayView';
import { CompanyFileTypeView } from './CompanyFileTypeView';
import { CompanyGroupsView } from './CompanyGroupsView';
import { CompanyHolidaysView } from './CompanyHolidaysView';
import { CompanyListsView } from './CompanyListsView';
import { CompanyProfileView } from './CompanyProfileView';
import { CompanyProjectStateView } from './CompanyProjectStateView';
import { CompanyProjectsView } from './CompanyProjectsView';
import { CompanyRegionsView } from './CompanyRegionsView';
import { CompanyReportTemplatesView } from './CompanyReportTemplatesView';
import { CompanyTagsView } from './CompanyTagsView';
import { CompanyTasksView } from './CompanyTasksView';
import { CompanyTicketLayoutsView } from './CompanyTicketLayoutsView';
import { CompanyTicketstatusView } from './CompanyTicketstatusView';
import { CompanyTicketTypeView } from './CompanyTicketTypeView';
import { CompanyUserView } from './CompanyUserView';
import { CompanyViewMenu } from './CompanyViewMenu';
import { CompanyWorkingTimeModelView } from './CompanyWorkingTimeModelView';
import NoCompanyConnectionCard from './NoCompanyConnectionCard';
const styles = {
    tabContent: {
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
};
export class CompanyView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.init = () => {
            const asyncNow = async () => {
                const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                    filter: `userId eq '${CurrentUser.userId}' and deleted ne true`,
                    top: 1,
                });
                let company = null;
                if (companyMember.length > 0)
                    company = await UpmeshClient.instance.modals.company.getById(companyMember[0].companyId);
                let companySettings;
                if (company != null) {
                    try {
                        companySettings = await UpmeshClient.instance.modals.companySettings.getById(company.id);
                    }
                    catch (e) {
                        if (AuthClient.instance?.serverConnected()) {
                            console.debug('cant get company Settings!', e);
                            await UpmeshClient.instance.syncDispatcher.db?.deleteDB();
                            if (Platform.OS === 'web') {
                                window.location.reload();
                                return;
                            }
                            try {
                                await new Promise((resolve, reject) => {
                                    AuthClient.instance.syncDispatcher.syncStop.once(() => {
                                        resolve();
                                    });
                                    AuthClient.instance.startSync(true).catch(reject);
                                });
                                companySettings = await UpmeshClient.instance.modals.companySettings.getById(company.id);
                            }
                            catch (e) {
                                console.warn('cant get company Settings 2!', e);
                                await AuthClient.instance.logOut(false, Url.getURLfromString('/login'));
                            }
                        }
                    }
                }
                if (company == null) {
                    CompanyUserInfo.company = null;
                }
                else if ((company != null && CompanyUserInfo.company == null) ||
                    JSON.stringify(company) !== JSON.stringify(CompanyUserInfo.company)) {
                    CompanyUserInfo.company = company;
                }
                this.setState({
                    company,
                    companySettings,
                    isLoading: false,
                    myRole: company != null ? this.myRole() : 'user',
                });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.breakViewWidth = 756;
        this.renderCompanyProfileView = () => {
            const { size } = this.props;
            const { company, companySettings } = this.state;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyProfileView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyUserListView = () => {
            const { company, companySettings } = this.state;
            const { size, q } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyUserView companySettings={companySettings} company={company} size={size} q={q}/>
      </View>);
        };
        this.renderCraftSettingsView = () => {
            const { company, companySettings } = this.state;
            if (company == null || companySettings == null)
                return null;
            const { size } = this.props;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyCraftView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderFileTypeSettingsView = () => {
            const { company, companySettings } = this.state;
            if (company == null || companySettings == null)
                return null;
            const { size } = this.props;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyFileTypeView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderTagsSettingsView = () => {
            const { company, companySettings } = this.state;
            if (company == null || companySettings == null)
                return null;
            const { size } = this.props;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyTagsView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyProjectsView = () => {
            const { company, companySettings } = this.state;
            if (company == null || companySettings == null)
                return null;
            const { size } = this.props;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyProjectsView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderTabContent = () => {
            const { company, companySettings } = this.state;
            const activeTab = this.props.pathvars.tab;
            if (company == null || companySettings == null)
                return null;
            let tabContent = null;
            if (activeTab == null)
                tabContent = this.renderCompanyProfileView();
            else
                switch (activeTab) {
                    case 'profile':
                        tabContent = this.renderCompanyProfileView();
                        break;
                    case 'user':
                        tabContent = this.renderCompanyUserListView();
                        break;
                    case 'groups':
                        tabContent = this.renderCompanyGroupsView();
                        break;
                    case 'projects':
                        tabContent = this.renderCompanyProjectsView();
                        break;
                    case 'types':
                        tabContent = this.renderTicketSettingsView();
                        break;
                    case 'lists':
                        tabContent = this.renderCompanyListsView();
                        break;
                    case 'ticketLayouts':
                        tabContent = this.renderCompanyTicketLayoutsView();
                        break;
                    case 'reportTemplates':
                        tabContent = this.renderCompanyReportTemplatesView();
                        break;
                    case 'projectStatus':
                        tabContent = this.renderProjectStateView();
                        break;
                    case 'status':
                        tabContent = this.renderTicketstatusView();
                        break;
                    case 'crafts':
                        tabContent = this.renderCraftSettingsView();
                        break;
                    case 'tags':
                        tabContent = this.renderTagsSettingsView();
                        break;
                    case 'categories':
                        tabContent = this.renderFileTypeSettingsView();
                        break;
                    case 'tasks':
                        if (companySettings && companySettings.hasModule('timeTracking') === true) {
                            tabContent = this.renderCompanyTasksView();
                        }
                        break;
                    case 'timeModels':
                        if (companySettings && companySettings.hasModule('timeTracking') === true) {
                            tabContent = this.renderCompanyWorkingTimeModelView();
                        }
                        break;
                    case 'costCenters':
                        if (companySettings && companySettings.hasModule('timeTracking') === true) {
                            tabContent = this.renderCompanyCostCenterView();
                        }
                        break;
                    case 'extraPay':
                        if (companySettings && companySettings.hasModule('timeTracking') === true) {
                            tabContent = this.renderCompanyExtraPayView();
                        }
                        break;
                    case 'region':
                        if (companySettings && companySettings.hasModule('timeTracking') === true) {
                            tabContent = this.renderCompanyRegionView();
                        }
                        break;
                    case 'holiday':
                        if (companySettings && companySettings.hasModule('timeTracking') === true) {
                            tabContent = this.renderCompanyHolidayView();
                        }
                        break;
                    default:
                        tabContent = this.renderCompanyProjectsView();
                }
            return (<View key={`tabContent${activeTab}`} style={styles.tabContent}>
        {tabContent}
      </View>);
        };
        this.renderTicketSettingsView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyTicketTypeView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderProjectStateView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyProjectStateView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderTicketstatusView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyTicketstatusView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyTasksView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyTasksView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyGroupsView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyGroupsView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyWorkingTimeModelView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyWorkingTimeModelView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyCostCenterView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyCostCenterView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyExtraPayView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyExtraPayView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyRegionView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyRegionsView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyHolidayView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyHolidaysView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyTicketLayoutsView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyTicketLayoutsView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyListsView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyListsView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.renderCompanyReportTemplatesView = () => {
            const { company, companySettings } = this.state;
            const { size } = this.props;
            if (company == null || companySettings == null)
                return null;
            return (<View style={{ height: '100%', width: '100%', alignContent: 'center' }}>
        <CompanyReportTemplatesView size={size} company={company} companySettings={companySettings}/>
      </View>);
        };
        this.state = {
            isLoading: true,
            myRole: 'user',
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'companyView',
            callback: this.init,
            readModelName: 'Company',
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'companyView2',
            callback: this.init,
            readModelName: 'CompanySettings',
        });
        this.init();
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Company', 'companyView');
        UpmeshClient.eventDispatcher.detach('CompanySettings', 'companyView2');
    }
    render() {
        const { size } = this.props;
        const activeTab = this.props.pathvars.tab;
        const leftButtons = [
            <BackButton key="backButtonAccountView" onAllPlatforms={activeTab != null && size.contentWidth < this.breakViewWidth}/>,
        ];
        return (<PageView showAccountIcon headerProps={{
                leftButtons,
                rightButtons: [],
                title: I18n.m.getMessage('accountCompanySettingsPageTitle'),
            }} scrollable={false} style={{ backgroundColor: ThemeManager.style.appBgColor }}>
        {this.renderContent()}
      </PageView>);
    }
    renderContent() {
        const { company, isLoading } = this.state;
        const activeTab = this.props.pathvars.tab;
        if (isLoading)
            return <Spinner />;
        if (company == null || CompanyUserInfo.me == null)
            return <NoCompanyConnectionCard />;
        const { size } = this.props;
        const myRole = CompanyUserInfo.me.role;
        if (!(myRole === 'admin'))
            return this.renderCompanyProfileView();
        if (size.contentWidth < this.breakViewWidth) {
            if (activeTab == null) {
                return (<View style={{ width: '100%' }}>
            <CompanyViewMenu size={size} onPress={(key) => Routing.instance.goTo(`/company/${key}`)} selected={activeTab} sMode={size.contentWidth < this.breakViewWidth}/>
          </View>);
            }
            return (<View style={{
                    height: size.contentHeight - ThemeManager.style.headerHeight,
                    width: size.contentWidth,
                    alignContent: 'center',
                }}>
          {this.renderTabContent()}
        </View>);
        }
        return (<View style={{
                flexDirection: 'row',
                width: size.contentWidth,
                height: size.contentHeight - ThemeManager.style.headerHeight,
            }}>
        <View style={{
                height: size.contentHeight - ThemeManager.style.headerHeight,
                width: 312,
                alignContent: 'center',
            }}>
          <CompanyViewMenu size={size} onPress={(key) => Routing.instance.goTo(`/company/${key}`, Platform.OS === 'ios' && size.contentWidth >= this.breakViewWidth)} selected={activeTab} sMode={size.contentWidth < this.breakViewWidth}/>
        </View>
        <View style={{
                height: size.contentHeight - ThemeManager.style.headerHeight,
                width: size.contentWidth - 312,
                alignContent: 'center',
            }}>
          {this.renderTabContent()}
        </View>
      </View>);
    }
}

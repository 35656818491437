"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlanVersion = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var PlanVersionEntity_1 = require("./entities/PlanVersionEntity");
var PlanVersion = function (_ReadModelClientOffli) {
  function PlanVersion() {
    var _this;
    (0, _classCallCheck2.default)(this, PlanVersion);
    _this = _callSuper(this, PlanVersion, arguments);
    _this.exampleEntity = new PlanVersionEntity_1.PlanVersionEntity();
    _this.onEvents = [{
      eventName: 'PlanVersionCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'PlanVersionActivated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'PlanVersionChanged',
      affect: 'one',
      identifier: 'entityId'
    }];
    _this.onPlanVersionActivated = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (e, _ent) {
        if (e.data.markerScaleShift == null || _this.config == null) return undefined;
        var entity = yield _this.config.repo.getById(e.data.activePlanId);
        entity.markerScaleShift = e.data.markerScaleShift;
        entity.lastModifiedAt = new Date();
        return entity;
      });
      return function (_x, _x2) {
        return _ref.apply(this, arguments);
      };
    }();
    return _this;
  }
  (0, _inherits2.default)(PlanVersion, _ReadModelClientOffli);
  return (0, _createClass2.default)(PlanVersion);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.PlanVersion = PlanVersion;
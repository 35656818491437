import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { ProjectImage } from '../../project/ProjectImage';
import { CompanyUserInfo } from '../CompanyUserInfo';
export function GanttChartWidgetOptions(props) {
    const [title, setTitle] = useState(props.initValues && props.initValues.title ? props.initValues.title : '');
    const [projectIds, setProjectIds] = useState(props.initValues && props.initValues.projectIds ? props.initValues.projectIds : []);
    const [teammemberIds, setTeammemberIds] = useState(props.initValues && props.initValues.teammemberIds ? props.initValues.teammemberIds : []);
    const [timeType, setTimeType] = useState(props.initValues && props.initValues.timeType ? props.initValues.timeType : 'project');
    const [allProjects, setAllProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const getProjectChips = (projects) => {
        const chips = [];
        for (let i = 0; i < projects.length; i += 1) {
            let color;
            const { state } = projects[i];
            if (state != null) {
                color = state.color;
            }
            else if (projects[i].color) {
                color = projects[i].color;
            }
            chips.push({
                title: projects[i].title,
                thumbnail: <ProjectImage project={projects[i]} size={24}/>,
                backgroundColor: color,
                textColor: color ? '#ffffff' : ThemeManager.style.black87,
                id: projects[i].id,
            });
        }
        return chips;
    };
    const getUserChips = (user) => {
        const chips = [];
        for (let i = 0; i < user.length; i += 1) {
            chips.push({
                title: user[i].getFullName(),
                thumbnail: <UserImage user={user[i]} size={24}/>,
                onPressChip: () => {
                    const users = selectedUsers;
                    users.push(allUsers[i]);
                    setSelectedUsers(users);
                    const ids = teammemberIds;
                    ids.push(user[i].id);
                    setTeammemberIds(ids);
                },
                id: user[i].id,
            });
        }
        return chips;
    };
    const init = async () => {
        const projects = await UpmeshClient.instance.modals.project.get({
            filter: 'deleted ne true and archived ne true',
        });
        const { companySettings } = CompanyUserInfo;
        if (companySettings) {
            for (const p of projects) {
                const projectInfo = await UpmeshClient.instance.modals.companyProjectInfo.get({
                    filter: `projectId eq '${p.id}' and companyId eq '${companySettings.id}' and deleted ne true`,
                });
                if (projectInfo.length > 0) {
                    p.state = companySettings.projectStateNames.find((elem) => elem.id === projectInfo[0].projectStateId);
                }
            }
        }
        setAllProjects(getProjectChips(projects));
        if (projectIds.length > 0 && props.initValues && props.initValues.projectIds) {
            const selectedProjects = projects.filter((p) => projectIds.indexOf(p.id) > -1);
            setSelectedProjects(getProjectChips(selectedProjects));
        }
        const user = await AuthClient.instance.modals.user.get({ filter: `deleted ne true` });
        setAllUsers(getUserChips(user));
        if (teammemberIds.length > 0 && props.initValues && props.initValues.projectIds) {
            const selectedUser = user.filter((p) => teammemberIds.indexOf(p.id) > -1);
            setSelectedUsers(getUserChips(selectedUser));
        }
    };
    useEffect(() => {
        init().catch((err) => console.debug(err));
    }, []);
    const save = () => {
        props.onSaved({
            title,
            projectIds,
            teammemberIds,
            timeType,
        });
    };
    return (<>
      <DialogTitle>
        {props.type === 'edit'
            ? I18n.m.getMessage('widgetOptionDialogEditTitle')
            : I18n.m.getMessage('widgetOptionDialogAddTitle')}
      </DialogTitle>
      <DialogContent>
        <FormInputFilled initValue={title} labelText={I18n.m.getMessage('widgetIframeTitle')} onChange={(t) => setTitle(t)}/>
        <SegmentedButton buttons={[
            { title: I18n.m.getMessage('projectTime'), key: 'project' },
            { title: I18n.m.getMessage('buildTime'), key: 'build' },
        ]} onPress={(s, _b) => setTimeType(s === 0 ? 'project' : 'build')} singleSelectSelected={timeType === 'project' ? 0 : 1}/>
        <View style={{ height: 8 }}/>
        <ChipGroup editable availableChips={allProjects} chips={selectedProjects} canAddNewChips={false} canAddNewChipsViaSearchbar={false} label={I18n.m.getMessage('projects')} onChanged={(newProjects) => {
            setSelectedProjects(newProjects);
            const projects = [];
            for (const n of newProjects) {
                if (n.id)
                    projects.push(n.id);
            }
            setProjectIds(projects);
        }}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="#ffffff" onPress={Dialog.instance?.close}/>
        <ContainedButton title={I18n.m.getMessage('save')} onPress={save}/>
      </DialogActions>
    </>);
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PasswordStrength = void 0;
var badPasswords = require("popular-passwords");
var PasswordStrength = function () {
  function PasswordStrength() {
    (0, _classCallCheck2.default)(this, PasswordStrength);
  }
  return (0, _createClass2.default)(PasswordStrength, null, [{
    key: "checkPassword",
    value: function checkPassword(pw) {
      var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
      var minExcellent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 30;
      var score = 0;
      if (pw.length < min) {
        return score;
      }
      score += Math.floor(pw.length / 8 * 100) / 1000;
      if (this.byStep(pw)) {
        return 0;
      }
      if (this.isAsdf(pw)) {
        return 0;
      }
      if (~this.words.indexOf(pw) > 0) {
        return 0;
      }
      if (~badPasswords.indexOf(pw.toLocaleLowerCase()) > 0) {
        return 0;
      }
      if (/[a-z]/.test(pw)) {
        var match = pw.match(/[a-z]/g);
        score += match != null && match.length > 1 ? 0.2 : 0.1;
      }
      if (/[A-Z]/.test(pw)) {
        var _match = pw.match(/[A-Z]/g);
        score += _match != null && _match.length > 1 ? 0.2 : 0.1;
      }
      if (/[0-9]/.test(pw)) {
        var _match2 = pw.match(/[0-9]/g);
        score += _match2 != null && _match2.length > 1 ? 0.2 : 0.1;
      }
      if (/[^0-9a-zA-Z]/.test(pw)) {
        var _match3 = pw.match(/[^0-9a-zA-Z]/g);
        score += _match3 != null && _match3.length > 1 ? 0.2 : 0.1;
      }
      if (score >= 1) {
        score = 1;
      }
      if (pw.length < minExcellent) {
        score -= (minExcellent - pw.length) / (minExcellent * 10);
      }
      return score;
    }
  }, {
    key: "byStep",
    value: function byStep(raw) {
      var delta = raw.charCodeAt(1) - raw.charCodeAt(0);
      for (var i = 0; i < raw.length - 1; i += 1) {
        if (raw.charCodeAt(i + 1) - raw.charCodeAt(i) !== delta) {
          return false;
        }
      }
      return true;
    }
  }, {
    key: "isAsdf",
    value: function isAsdf(raw) {
      var ASDF = ['qwertyuiop', 'qwertzuiop', 'asdfghjkl', 'zxcvbnm', 'yxcvbnm'];
      var reverse = raw.split('').reverse().join('');
      var asdf = ASDF.join('');
      if (~asdf.indexOf(raw) > 0 || ~asdf.indexOf(reverse) > 0) {
        return true;
      }
      asdf = ASDF.reverse().join('');
      if (~asdf.indexOf(raw) > 0 || ~asdf.indexOf(reverse) > 0) {
        return true;
      }
      return false;
    }
  }]);
}();
exports.PasswordStrength = PasswordStrength;
PasswordStrength.words = ['bau2cloud', 'upmesh'];
import React, { useEffect, useState } from 'react';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { FlatList, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { FolderSelectorListItem } from './FolderSelectorListItem';
import { I18n } from '../../i18n/I18n';
export function FolderSelectorLastUploads(props) {
    const [shownFolders, setShownFolders] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const getProjectFolderFlatTitle = (folder) => {
        const { folders, excludedFolders } = props;
        const f = folder;
        let t = folder.title;
        if (folder.subFolderFromId != null) {
            if (excludedFolders?.includes(folder.subFolderFromId))
                return undefined;
            const getMain = folders.find((e) => e.id === folder.subFolderFromId);
            if (getMain != null) {
                const mainFolder = getProjectFolderFlatTitle(getMain);
                if (mainFolder == null)
                    return undefined;
                t = `${mainFolder.flatTitle} › ${t}`;
            }
        }
        else {
            t = `${I18n.m.getMessage('filesFolderPublic')} › ${t}`;
        }
        f.flatTitle = t;
        return f;
    };
    const getFolderListItemProps = (folderId) => {
        const { folders, excludedFolders, onSelect } = props;
        if (folderId === 'public' || folderId === 'private') {
            const f = {
                id: folderId,
                title: folderId === 'public'
                    ? I18n.m.getMessage('selectorFolderPublic')
                    : I18n.m.getMessage('selectorFolderPrivate'),
                flatTitle: undefined,
                onSelect,
            };
            return f;
        }
        if (excludedFolders && excludedFolders.includes(folderId))
            return undefined;
        const folder = folders.find((f) => f.id === folderId);
        if (folder == null)
            return undefined;
        const flatFolder = getProjectFolderFlatTitle(folder);
        if (flatFolder == null)
            return undefined;
        const f = {
            id: flatFolder.id,
            title: flatFolder.title,
            flatTitle: flatFolder.flatTitle,
            onSelect,
        };
        return f;
    };
    const init = async () => {
        const { projectId, excludedFolders } = props;
        const files = await UpmeshClient.instance.modals.storedFile.get({
            filter: `projectId eq '${projectId}' and deleted ne true and userId eq '${CurrentUser.userId}'`,
            orderby: 'createdAt',
        });
        const visibleFolders = [];
        let index = 0;
        while (index < files.length && visibleFolders.length < 10) {
            const file = files[index];
            const folderId = file.folder ? file.folder : 'private';
            const isIncluded = visibleFolders.findIndex((f) => f.id === folderId) > -1;
            if (!isIncluded && (!excludedFolders || !excludedFolders.includes(folderId))) {
                const vFolder = getFolderListItemProps(folderId);
                if (vFolder)
                    visibleFolders.push(vFolder);
            }
            index += 1;
        }
        setShownFolders(visibleFolders);
    };
    useEffect(() => {
        init().catch((err) => console.debug(err));
    }, [props.folders]);
    const renderRow = ({ item }) => {
        if (searchValue.length === 0 || item.title.toLowerCase().includes(searchValue))
            return (<FolderSelectorListItem id={item.id} title={item.title} flatTitle={item.flatTitle} onSelect={props.onSelect}/>);
        return <View />;
    };
    return (<View>
      <View style={{ paddingTop: 8, paddingBottom: 16 }}>
        <SearchBar searchBarValue={searchValue} searchOnChange={(s) => setSearchValue(s.toLowerCase())}/>
      </View>
      <View style={{ height: props.height - 60, paddingBottom: 16 }}>
        <FlatList scrollEnabled data={shownFolders} renderItem={renderRow} style={{ height: '100%', width: '100%' }} keyExtractor={(item) => item.id}/>
      </View>
    </View>);
}

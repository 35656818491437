import React from 'react';
import { View } from 'react-native';
import { TicketsItem } from './TicketsItem';
import { FilesItem } from './FilesItem';
import { PlansItem } from './PlansItem';
import { JournalItem } from './JournalItem';
import { TeamItem } from './TeamItem';
export function DashboardItemWidget(props) {
    const { type } = props;
    if (type === 'tickets')
        return <TicketsItem />;
    if (type === 'files')
        return <FilesItem />;
    if (type === 'plans')
        return <PlansItem />;
    if (type === 'journals')
        return <JournalItem />;
    if (type === 'team')
        return <TeamItem />;
    return <View />;
}

import color from 'color';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { UploadTypes } from 'materialTheme/src/theme/components/upload/UploadTypes';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { StoredFileEntity } from 'upmesh-core/src/client/query/entities/StoredFileEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { CurrentProject } from '../project/CurrentProject';
const notFoundImage = require('../../assets/img/no_documents.png');
export class FileStorageSelector extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.onSelectFile = (file, docus) => {
            const { documents, selected } = this.state;
            let docs;
            if (docus != null)
                docs = docus;
            else
                docs = documents != null ? [...documents] : undefined;
            if (docs != null) {
                if (file != null) {
                    const index = docs.findIndex((d) => d.id === file.id);
                    if (index > -1)
                        docs[index].bgColor = color(ThemeManager.style.brandPrimary).alpha(0.16).toString();
                }
                if (selected != null) {
                    const oldIndex = docs.findIndex((d) => d.id === selected.id);
                    if (oldIndex > -1)
                        docs[oldIndex].bgColor = undefined;
                }
            }
            return { selected: file, documents: docs };
        };
        this.onSubmitSelected = () => {
            const { selected } = this.state;
            this.props.onSelect(selected);
            if (this.props.shouldUseBetween) {
                DialogBetween.instance?.close();
            }
            else {
                Dialog.instance?.close();
            }
        };
        this.headerAndButtonsHeight = 64 + 36 + 40 + 52 + 52 + 16;
        this.goToFolder = (folderId) => (_e) => {
            const documents = FileStorageSelector.getDocWithFolder(this.props.folders, folderId, this.props.filter);
            const selected = this.onSelectFile(undefined, documents);
            this.setState({ documents: selected.documents, currentFolder: folderId, selected: selected.selected });
        };
        this.onPressRow = (item) => {
            if (item.mimeType === 'folder') {
                this.goToFolder(item.id)(null);
            }
            else {
                this.setState(this.onSelectFile(item));
            }
        };
        this.state = {
            documents: FileStorageSelector.getDocWithFolder(props.folders, '', props.filter),
            currentFolder: '',
            selected: undefined,
            searchText: '',
        };
    }
    static getDocWithFolder(folders, currentFolder, filter, searchText) {
        if (currentFolder === '') {
            const docs = [];
            const def = new StoredFileEntity();
            docs.push({
                ...def,
                title: ` ${I18n.m.getMessage('filesFolderPrivate')}`,
                id: 'private',
                orgFilename: ` ${I18n.m.getMessage('filesFolderPrivate')}`,
                mimeType: 'folder',
                deleted: false,
                folder: '',
                uploadedByUsername: '',
                uploadedByUser: new UserEntity(),
                createdBy: '',
                createdAt: new Date(),
                userId: '',
                fileSizeInBytes: 0,
            });
            docs.push({
                ...def,
                title: ` ${I18n.m.getMessage('filesFolderPublic')}`,
                id: 'public',
                orgFilename: ` ${I18n.m.getMessage('filesFolderPublic')}`,
                mimeType: 'folder',
                deleted: false,
                folder: '',
                uploadedByUsername: '',
                uploadedByUser: new UserEntity(),
                createdBy: '',
                createdAt: new Date(),
                userId: '',
                fileSizeInBytes: 0,
            });
            return docs;
        }
        const documents = CurrentProject.instance.getCurrentFiles();
        let docs = documents == null
            ? []
            : documents.filter((f) => (currentFolder === 'private' &&
                f.folder == null &&
                (f.forEntityId == null || f.forEntityId.length === 0)) ||
                f.folder === currentFolder);
        if (filter != null && filter.length > 0 && filter.indexOf('allFiles') === -1) {
            docs = docs.filter((element) => {
                for (const currentFilter of filter) {
                    if (currentFilter === 'images' && element.mimeType.startsWith('image/')) {
                        return true;
                    }
                    if (currentFilter === 'plainText' && element.mimeType.startsWith('text/plain')) {
                        return true;
                    }
                    if (currentFilter === 'audio' && element.mimeType.startsWith('audio/')) {
                        return true;
                    }
                    if (currentFilter === 'pdf' && element.mimeType.startsWith('application/pdf')) {
                        return true;
                    }
                }
                return false;
            });
        }
        const oldSelection = docs.findIndex((d) => d.bgColor != null);
        if (oldSelection > -1)
            docs[oldSelection].bgColor = undefined;
        let searchedFolders = folders;
        if (searchText != null && searchText.length > 0) {
            docs = docs.filter((d) => {
                return d.orgFilename.toLowerCase().includes(searchText.toLowerCase());
            });
            searchedFolders = searchedFolders.filter((f) => {
                return f.title.toLowerCase().includes(searchText.toLowerCase());
            });
        }
        searchedFolders.forEach((f) => {
            const subFolderFromId = f.subFolderFromId == null ? 'public' : f.subFolderFromId;
            if (subFolderFromId === currentFolder) {
                let uploadedByUsername = '';
                let uploadedByUser = new UserEntity();
                try {
                    const u = CurrentProject.instance.getCurrentProjectTeam('all');
                    const us = u.find((t) => t.user.userId === f.createdBy);
                    if (us != null) {
                        uploadedByUsername = us.user.getFullName();
                        uploadedByUser = us.user;
                    }
                }
                catch (e) {
                    console.debug('cant get Teammember', e);
                }
                const s = {
                    title: ` ${f.title}`,
                    id: f.id,
                    orgFilename: ` ${f.title}`,
                    mimeType: 'folder',
                    deleted: f.deleted,
                    folder: f.subFolderFromId,
                    uploadedByUsername,
                    uploadedByUser,
                    createdBy: f.createdBy,
                    createdAt: f.createdAt,
                    userId: f.createdBy,
                    fileSizeInBytes: 0,
                };
                s['hiddenData'] = f;
                docs.unshift(s);
            }
        });
        return docs;
    }
    getMaxTableHeight() {
        const { dialogState } = this.props;
        if (dialogState == null)
            return 256;
        if (ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM) {
            return dialogState.windowHeight - this.headerAndButtonsHeight;
        }
        return dialogState.windowHeight * 0.9 - this.headerAndButtonsHeight;
    }
    getLocationElement(folderId, folderName) {
        return (<MaterialText key={`location${folderId}`} type={MaterialTextTypes.Body1} onPress={this.goToFolder(folderId)}>
        {folderName}
      </MaterialText>);
    }
    generateFolderLocations(folderId) {
        const { folders } = this.props;
        const items = [];
        const f = folders.find((e) => e.id === folderId);
        if (f != null) {
            items.unshift({ title: f.title, id: f.id });
            if (f.subFolderFromId != null && f.subFolderFromId.length > 0) {
                items.unshift(...this.generateFolderLocations(f.subFolderFromId));
            }
        }
        return items;
    }
    generateLocations() {
        const { documents, currentFolder } = this.state;
        const items = [this.getLocationElement('', I18n.m.getMessage('menuProjectFiles'))];
        if (currentFolder !== '') {
            items.push(<MaterialText key="sep_1" type={MaterialTextTypes.Body1}>
          {' › '}
        </MaterialText>);
        }
        if (currentFolder === 'all')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('menuProjectFiles')));
        else if (currentFolder === 'private')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPrivate')));
        else if (currentFolder === 'public')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPublic')));
        else if (currentFolder === 'tickets')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('tickets')));
        else if (currentFolder !== '') {
            items.push(this.getLocationElement('public', I18n.m.getMessage('filesFolderPublic')));
            const locations = this.generateFolderLocations(currentFolder);
            locations.forEach((f) => {
                items.push(<MaterialText key={`sep_${f.id}`} type={MaterialTextTypes.Body1}>
            {' › '}
          </MaterialText>);
                items.push(this.getLocationElement(f.id, f.title));
            });
        }
        items.push(<MaterialText key="documentsCount" type={MaterialTextTypes.Body1}>
        ({documents == null ? '0' : documents.length})
      </MaterialText>);
        return items;
    }
    render() {
        const { dialogState } = this.props;
        const { documents, currentFolder, searchText, selected } = this.state;
        const maxTableHeight = this.getMaxTableHeight();
        let searchbarWidth = 512;
        if (dialogState != null) {
            searchbarWidth = dialogState.windowWidth;
            if (ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM)
                searchbarWidth *= 0.9;
            searchbarWidth -= 16;
        }
        return (<>
        <DialogTitle key='titleFileStorageSelector"' iconRight={{
                icon: 'close',
                toolTip: '',
                onPress: () => {
                    if (this.props.shouldUseBetween)
                        DialogBetween.instance?.close();
                    else
                        Dialog.instance?.close();
                },
            }}>
          {I18n.m.getMessage('selectFile')}
        </DialogTitle>
        <DialogContent key="contentFileStorageSelector">
          <SearchBar searchBarValue={searchText} searchOnChange={(searchText) => {
                const { folders, filter } = this.props;
                const { currentFolder } = this.state;
                const docs = FileStorageSelector.getDocWithFolder(folders, currentFolder, filter, searchText);
                this.setState({ searchText, documents: docs });
            }} maxWidth={searchbarWidth} key='searchBarFileStorageSelector"'/>
          <View key="folderLocation" style={{
                width: '100%',
                paddingTop: 16,
                paddingLeft: 8,
                flexDirection: 'row',
                flexWrap: 'wrap',
                height: 40,
            }}>
            {this.generateLocations()}
          </View>
          <View style={{ height: maxTableHeight, width: '100%' }}>
            <Table key={`table_${currentFolder}`} tableName="StoredFilesSelector" actionItemsLength={0} onRowPress={this.onPressRow} maxHeight={maxTableHeight} emptyTableImage={notFoundImage} emptyTableText={I18n.m.getMessage('filesUploadDescriptionUploadButton')} emptyTableHint={I18n.m.getMessage('filesUploadHintUploadButton')} data={documents} sortBy="orgFilename" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('filesTitle'),
                    keyInData: 'orgFilename',
                    style: { paddingHorizontal: 16, width: 300 },
                    dataType: 'string',
                    cellRenderer: (item, column, index) => {
                        const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${item.id}?lm=${item.lastModifiedAt}`;
                        const localFile = item.mimeType === 'folder'
                            ? undefined
                            : OfflineDataDownloader.isMediaSynced(item.projectId, item.id, item.getFileId());
                        let panoramaImage = false;
                        if (item.mimeType.startsWith('image') && item.metaData != null) {
                            try {
                                item.metaData = JSON.parse(item.metaData);
                            }
                            catch (e) {
                                console.debug('cant parse item.metaData');
                            }
                        }
                        if (item.mimeType.startsWith('image') &&
                            item.metaData != null &&
                            !Number.isNaN(item.metaData['width']) &&
                            !Number.isNaN(item.metaData['height']) &&
                            item.metaData['width'] / item.metaData['height'] === 2) {
                            panoramaImage = true;
                        }
                        const thumb = item.mimeType === 'folder'
                            ? {
                                thumbnail: <Icon icon="folder" toolTip=""/>,
                                width: 24,
                                rounded: true,
                            }
                            : UploadTypes.getThumbnailForFileName({ targetUrl, name: item.orgFilename, preview: localFile }, undefined, undefined, panoramaImage);
                        return (<View key={`cell-${item.id}-${index}`} style={[
                                {
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    height: '100%',
                                    paddingHorizontal: 16,
                                    overflow: 'hidden',
                                },
                                column.style,
                            ]}>
                        <View key={`file:${item.id}_thumb`} style={{ width: 72, justifyContent: 'center', alignItems: 'flex-start' }}>
                          {thumb.thumbnail}
                        </View>
                        <View key={`file:${item.id}_text`} style={{ width: '90%', justifyContent: 'center', alignItems: 'center' }}>
                          <MaterialText centeredBox fixedWidth="100%" numberOfLines={1}>
                            {item.orgFilename}
                          </MaterialText>
                        </View>
                      </View>);
                    },
                },
            ]}/>
          </View>
        </DialogContent>
        <DialogActions onBottom key="dialogActionsFileStorageSelector">
          <ContainedButton title={I18n.m.getMessage('abort')} onPress={() => {
                if (this.props.shouldUseBetween) {
                    DialogBetween.instance?.close();
                }
                else {
                    Dialog.instance?.close();
                }
            }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          <ContainedButton title={I18n.m.getMessage('add')} onPress={this.onSubmitSelected} disabled={selected == null}/>
        </DialogActions>
      </>);
    }
}
FileStorageSelector.contextType = ConnectionContext;
FileStorageSelector.openSelector = (e) => (props) => {
    const openPosition = e != null
        ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY }
        : { x: ResizeEvent.current.windowWidth / 2, y: ResizeEvent.current.windowHeight / 2 };
    if (props.shouldUseBetween) {
        DialogBetween.instance?.open({
            openPosition,
            showCloseIcon: false,
            content: (<FileStorageSelector folders={props.folders} shouldUseBetween={props.shouldUseBetween} onSelect={props.onSelect} filter={props.filter}/>),
            fullscreenResponsive: true,
            scrollable: false,
            contentPadding: false,
            maxWidth: 1024,
            width: 1024,
        });
    }
    else {
        Dialog.instance?.open({
            openPosition,
            showCloseIcon: false,
            content: (<FileStorageSelector folders={props.folders} shouldUseBetween={props.shouldUseBetween} onSelect={props.onSelect} filter={props.filter}/>),
            fullscreenResponsive: true,
            scrollable: false,
            contentPadding: false,
            maxWidth: 1024,
            width: 1024,
        });
    }
};

import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { WidgetItemContainer } from 'materialTheme/src/theme/components/widgets/WidgetItemContainer';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { DeleteProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/DeleteProjectAddress';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { CreateUserProjectSettings } from 'upmesh-core/src/client/commands/project/dashboard/CreateUserProjectSettings';
import { ChangeUserProjectSettingsDashboard } from 'upmesh-core/src/client/commands/project/dashboard/ChangeUserProjectSettingsDashboard';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FabWithCamera } from '../FabWithCamera';
import { IframeWidget } from '../root/board/IframeWidget';
import { IframeWidgetOptions } from '../root/board/IframeWidgetOptions';
import { DashboardWidgetPreview } from '../root/DashboardWidgetPreview';
import { PageView } from '../root/PageView';
import { ChartItemWidget } from './board/ChartItemWidget';
import { DashboardItemWidget } from './board/DashboardItemWidget';
import { DashboardItemWidgetOptions } from './board/DashboardItemWidgetOptions';
import { FocusTicketItem } from './board/FocusTicketItem';
import { ImageItem } from './board/ImageItem';
import { ImageItemOptions } from './board/ImageItemOptions';
import { LogsItem } from './board/LogsItem';
import { NotesWidget } from './board/NotesWidget';
import { ProjectAddressItem } from './board/ProjectAddressItem';
import { ProjectAddressItemOptions } from './board/ProjectAddressItemOptions';
import { ProjectItem } from './board/ProjectItem';
import { WeatherItem } from './board/WeatherItem';
import { CurrentProject } from './CurrentProject';
import { SpeechCommandDialog } from './SpeechCommandDialog';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export function ProjectView(props) {
    const [items, setItems] = useState();
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [canEditAddress, setCanEditAddress] = useState();
    const [addresses, setAddresses] = useState(undefined);
    const { project } = props;
    if (project == null) {
        console.warn('no Project on ProjectView', props);
        return null;
    }
    const defaultItems = useRef([
        {
            widgetId: '10',
            settings: {},
            id: '1',
            width: 4,
            height: 6,
            top: 0,
            left: 0,
        },
        {
            widgetId: '4',
            settings: {},
            id: '2',
            width: 8,
            height: 6,
            top: 0,
            left: 4,
        },
        {
            widgetId: '8',
            settings: {},
            id: '3',
            width: 6,
            height: 7,
            top: 6,
            left: 0,
        },
        {
            widgetId: '3',
            settings: {},
            id: '4',
            width: 6,
            height: 7,
            top: 6,
            left: 6,
        },
        {
            widgetId: '12',
            settings: {},
            id: '5',
            width: 6,
            height: 6,
            top: 13,
            left: 0,
        },
        {
            widgetId: '9',
            settings: {},
            id: '6',
            width: 6,
            height: 6,
            top: 13,
            left: 6,
        },
        {
            widgetId: '13',
            settings: {},
            id: '7',
            width: 8,
            height: 10,
            top: 19,
            left: 4,
        },
        {
            widgetId: '11',
            settings: { type: 'tickets' },
            id: '8',
            width: 4,
            height: 2,
            top: 19,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'plans' },
            id: '9',
            width: 4,
            height: 2,
            top: 21,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'files' },
            id: '10',
            width: 4,
            height: 2,
            top: 23,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'journals' },
            id: '11',
            width: 4,
            height: 2,
            top: 25,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'team' },
            id: '12',
            width: 4,
            height: 2,
            top: 27,
            left: 0,
        },
    ]);
    const onDeleteProjectAddress = async (id) => {
        const c = new DeleteProjectAddress({}, id);
        await c.execute();
    };
    const loadAddresses = async () => {
        const adr = await UpmeshClient.instance.modals.projectAddress.get({
            filter: `projectId eq '${project.id}' and deleted ne true and type eq 'contact'`,
        });
        setAddresses(adr);
        return adr;
    };
    const loadCanEditAddress = async () => {
        if (canEditAddress == null) {
            const canE = await RightsManager.hasWriteRight(project.id, CurrentUser.userId, 'commandChangeProject');
            setCanEditAddress(canE);
        }
    };
    useEffect(() => {
        if (items != null) {
            items?.forEach((a) => {
                if (a.widgetId === '15') {
                    a.notDeletable = !canEditAddress;
                    a.notEditable = !canEditAddress;
                    a.onDeleted = () => {
                        onDeleteProjectAddress(a.settings['addressId']).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                    };
                }
            });
        }
    }, [items]);
    const updateDashboard = (e) => {
        if (e && e.entities && e.entities.size > 0) {
            try {
                e.entities.forEach((a) => {
                    if (a.entity && a.entity.projectId === CurrentProject.instance.getCurrentProjectId()) {
                        const fromEvent = JSON.parse(a.entity.dashboard);
                        setItems(fromEvent);
                    }
                });
            }
            catch (err) {
                console.warn('cant load Global Dashboard Items', err);
            }
        }
    };
    const updateAddress = (e) => {
        if (e && e.entities && e.entities.size > 0) {
            let thisProject = false;
            try {
                e.entities.forEach((a) => {
                    if (a.entity && a.entity.projectId === project.id) {
                        thisProject = true;
                    }
                });
                if (thisProject) {
                    loadAddresses().catch((err) => console.warn(err));
                }
            }
            catch (err) {
                console.warn('cant load Global Dashboard Items', err);
            }
        }
    };
    useEffect(() => {
        if (addresses == null || !settingsLoaded || canEditAddress == null) {
            setLoaded(true);
            return;
        }
        const newItems = [];
        if (items != null) {
            items.forEach((a) => {
                if (a.widgetId === '15') {
                    const { addressId } = a.settings;
                    if (addressId) {
                        const isIn = addresses.find((b) => b.id === addressId && !b.deleted);
                        if (isIn) {
                            newItems.push(a);
                        }
                    }
                    else {
                        newItems.push(a);
                    }
                }
            });
        }
        addresses.forEach((a) => {
            const item = {
                widgetId: '15',
                settings: { addressId: a.id },
                id: a.id,
                height: 6,
                width: 6,
                notDeletable: !canEditAddress,
                notEditable: !canEditAddress,
                onDeleted: () => {
                    onDeleteProjectAddress(a.id).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                },
            };
            const isInDefault = defaultItems.current?.find((b) => b.widgetId === '15' && b.settings['addressId'] === a.id);
            if (!isInDefault) {
                defaultItems.current.push(item);
            }
            const isIn = newItems?.find((b) => b.widgetId === '15' && b.settings['addressId'] === a.id);
            if (!isIn) {
                newItems.push(item);
            }
        });
        setLoaded(true);
    }, [addresses, setCanEditAddress, settingsLoaded]);
    const loadDashboardSettingsFromOldAndNew = async () => {
        const entitySettings = (await UpmeshClient.instance.modals.userProjectSettings.get({
            filter: `projectId eq '${CurrentProject.instance.getCurrentProjectId()}'`,
        }))[0];
        if (entitySettings != null && entitySettings.dashboard != null) {
            const loadedItems = JSON.parse(entitySettings.dashboard);
            setItems(loadedItems);
        }
        else {
            const logEntries = await UpmeshClient.instance.modals.logBook.get({
                filter: `eventName eq ProjectCopied and entityId eq '${CurrentProject.instance.getCurrentProjectId()}'`,
            });
            if (logEntries.length > 0 && logEntries[0].data.oldProjectId != null) {
                const { oldProjectId } = logEntries[0].data;
                const oldSettings = (await UpmeshClient.instance.modals.userProjectSettings.get({
                    filter: `projectId eq '${oldProjectId}'`,
                }))[0];
                if (oldSettings != null) {
                    const loadedItems = JSON.parse(oldSettings.dashboard);
                    setItems(loadedItems);
                    if (entitySettings != null) {
                        const changeUps = new ChangeUserProjectSettingsDashboard({
                            dashboard: oldSettings.dashboard,
                        }, entitySettings.id);
                        changeUps.execute().catch((err) => {
                            console.error('could not store changed dashboard settings from old project', err);
                        });
                    }
                    else {
                        const createUps = new CreateUserProjectSettings({
                            projectId: CurrentProject.instance.getCurrentProjectId() ?? '',
                            dashboard: oldSettings.dashboard,
                        });
                        createUps.execute().catch((err) => {
                            console.error('could not create new project user settings', err);
                        });
                    }
                }
            }
        }
        setSettingsLoaded(true);
    };
    useEffect(() => {
        const addressKey = uuid.v4();
        const projectSettingsKey = uuid.v4();
        UpmeshClient.eventDispatcher.attach({
            attachKey: addressKey,
            readModelName: 'ProjectAddress',
            callback: updateAddress,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: projectSettingsKey,
            readModelName: 'UserProjectSettings',
            callback: updateDashboard,
        });
        loadCanEditAddress().catch((err) => console.warn(err));
        loadAddresses().catch((err) => console.warn(err));
        loadDashboardSettingsFromOldAndNew().catch((err) => {
            console.error('could not load dashboard settings', err);
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('ProjectAddress', projectSettingsKey);
            UpmeshClient.eventDispatcher.detach('UserProjectSettings', projectSettingsKey);
        };
    }, []);
    const templates = [
        {
            id: '3',
            multiple: false,
            previewImage: DashboardWidgetPreview.fokusTickets,
            widgetName: I18n.m.getMessage('dashboardFocusTicketsItem'),
            component: (props) => (<FocusTicketItem {...props} projectId={CurrentProject.instance.getCurrentProjectId()}/>),
            defaultSize: { width: 6, height: 7 },
            minWidth: 4,
            minHeight: 7,
        },
        {
            id: '4',
            multiple: false,
            widgetName: I18n.m.getMessage('activities'),
            previewImage: DashboardWidgetPreview.activites,
            component: (props) => <LogsItem {...props} projectLogBook projectId={project?.id}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 4,
            minHeight: 6,
            maxHeight: 8,
        },
        {
            id: '8',
            multiple: false,
            previewImage: DashboardWidgetPreview.overview,
            widgetName: I18n.m.getMessage('dashboardChartItemTicketOverview'),
            component: (props) => (<ChartItemWidget {...props} projectId={CurrentProject.instance.getCurrentProjectId()}/>),
            defaultSize: { width: 6, height: 6 },
            minWidth: 3,
            minHeight: 6,
        },
        {
            id: '9',
            multiple: false,
            previewImage: DashboardWidgetPreview.notes,
            widgetName: I18n.m.getMessage('dashboardNotes'),
            component: (props) => <NotesWidget {...props} projectId={CurrentProject.instance.getCurrentProjectId()}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 3,
            minHeight: 6,
        },
        {
            id: '10',
            multiple: false,
            previewImage: DashboardWidgetPreview.projectInfo,
            widgetName: I18n.m.getMessage('projectInfo'),
            component: (props) => <ProjectItem {...props}/>,
            defaultSize: { width: 6, height: 5 },
            minWidth: 3,
            minHeight: 4,
        },
        {
            id: '11',
            multiple: true,
            previewImage: DashboardWidgetPreview.stats,
            widgetName: I18n.m.getMessage('statistics'),
            component: (props) => <DashboardItemWidget {...props}/>,
            defaultSettings: { type: 'tickets' },
            optionWindow: (props) => <DashboardItemWidgetOptions {...props}/>,
            defaultSize: { width: 3, height: 2 },
            minWidth: 3,
            minHeight: 2,
            maxHeight: 2,
        },
        {
            id: '12',
            previewImage: DashboardWidgetPreview.projectContact,
            multiple: false,
            widgetName: I18n.m.getMessage('projectContactLabel'),
            component: (props) => <ProjectAddressItem {...props} type="constructionSite" addAcs/>,
            defaultSize: { width: 6, height: 5 },
            minWidth: 3,
            minHeight: 4,
        },
        {
            id: '13',
            previewImage: DashboardWidgetPreview.weather,
            multiple: false,
            widgetName: I18n.m.getMessage('weather'),
            component: (props) => <WeatherItem {...props} project={project}/>,
            defaultSize: { width: 6, height: 5 },
            minWidth: 3,
            minHeight: 4,
        },
        {
            id: '14',
            previewImage: DashboardWidgetPreview.iFrame,
            multiple: true,
            widgetName: I18n.m.getMessage('website'),
            component: (props) => <IframeWidget {...props}/>,
            optionWindow: (props) => <IframeWidgetOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
        {
            id: '15',
            previewImage: DashboardWidgetPreview.contact,
            multiple: true,
            widgetName: I18n.m.getMessage('contactPersons'),
            component: (props) => <ProjectAddressItem {...props} type="contact" hideEdit/>,
            optionWindow: (props) => (<ProjectAddressItemOptions {...props} projectId={project?.id} subTitle={I18n.m.getMessage('projectContactWidgetSubTitle')}/>),
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
        {
            id: '16',
            previewImage: DashboardWidgetPreview.images,
            multiple: true,
            widgetName: I18n.m.getMessage('images'),
            component: (props) => <ImageItem {...props} projectId={project.id}/>,
            optionWindow: (props) => <ImageItemOptions {...props} projectId={project.id}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
    ];
    const saveChanges = (e) => {
        UpmeshClient.instance.modals.userProjectSettings
            .get({ filter: `projectId eq '${project.id}'` })
            .then((ups) => {
            if (ups.length > 0) {
                const changeUps = new ChangeUserProjectSettingsDashboard({
                    dashboard: JSON.stringify(e),
                }, ups[0].id);
                changeUps.execute().catch((err) => {
                    console.error('could not store changed dashboard settings from old project', err);
                });
            }
            else {
                const createUps = new CreateUserProjectSettings({
                    projectId: project.id,
                    dashboard: JSON.stringify(e),
                });
                createUps.execute().catch((err) => {
                    console.error('could not create new project user settings', err);
                });
            }
        })
            .catch((err) => {
            console.error('could not save user project settings dashboard', err);
        });
        setItems(e);
    };
    const renderContent = () => {
        if (!loaded)
            return <Spinner />;
        const sViewHeight = props.size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        return (<View style={{ width: props.size.contentWidth, height: props.size.contentHeight - ThemeManager.style.headerHeight }}>
        <WidgetItemContainer templates={templates} savedItems={items} defaultItems={defaultItems.current} height={props.size.contentHeight - ThemeManager.style.headerHeight - sViewHeight} onChanged={saveChanges}/>
      </View>);
    };
    const additionalActions = [];
    if (CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('moduleUpmeshKi')) {
        additionalActions.push({
            icon: 'robot',
            onPress: (_e) => {
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <SpeechCommandDialog projectId={project.id}/>,
                    contentPadding: false,
                    fullscreenResponsive: true,
                    scrollable: false,
                    showCloseIcon: true,
                });
            },
            text: I18n.m.getMessage('kiFabTitle'),
        });
    }
    return (<PageView headerProps={{ title: project != null && project.title ? project.title : '' }} style={{
            width: props.size.contentWidth,
            overflow: 'hidden',
            position: 'absolute',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            alignItems: 'center',
        }}>
      <FabWithCamera projectId={project.id} additionalActionButtons={additionalActions} size={props.size}/>
      {renderContent()}
    </PageView>);
}

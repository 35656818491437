import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { View } from 'react-native';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { TabContent } from 'materialTheme/src/theme/components/tabs/TabBarContent';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { I18n } from '../../i18n/I18n';
import { FolderStructure } from './FolderStructure';
import { FolderSelectorLastUploads } from './FolderSelectorLastUploads';
import { FolderSelectorSuggestions } from './FolderSelectorSuggestions';
export function FolderSelector(props) {
    const [searchValue, setSearchValue] = useState('');
    const [currentFolder, setCurrentFolder] = useState('');
    const maxDialogWith = 512;
    const isFullscreen = ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM;
    const tabWidth = (isFullscreen ? ResizeEvent.current.windowWidth : maxDialogWith) - 32;
    const tabHeight = (isFullscreen ? ResizeEvent.current.windowHeight : 0.9 * ResizeEvent.current.windowHeight) - 183;
    const getSuggestions = () => {
        const { projectId, folders, excludedFolders } = props;
        return (<FolderSelectorSuggestions projectId={projectId} folders={folders} onSelect={props.onSelect} excludedFolders={excludedFolders} height={tabHeight}/>);
    };
    const getFolderList = () => {
        const { folders, excludedFolders, projectId } = props;
        let exFolders = ['tickets', 'reports'];
        if (excludedFolders)
            exFolders = exFolders.concat(excludedFolders);
        return (<View>
        <View style={{ paddingTop: 8, paddingBottom: 16 }}>
          <SearchBar searchBarValue={searchValue} searchOnChange={(s) => setSearchValue(s.toLowerCase())}/>
        </View>
        <View style={{
                height: tabHeight - 52 - 60,
                width: '100%',
            }}>
          <FolderStructure folder={currentFolder} folders={folders} width="100%" projectId={projectId} excludedFolders={exFolders} onPressFolder={(s) => (_e) => setCurrentFolder(s)} searchString={searchValue} borderRadiusRight bgColor="#ffffff"/>
        </View>
        <DialogActions onBottom>
          <ContainedButton title={I18n.m.getMessage('abort')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={props.closeFunction}/>
          <ContainedButton title={I18n.m.getMessage('apply')} onPress={props.onSelect(currentFolder)} disabled={currentFolder.length === 0}/>
        </DialogActions>
      </View>);
    };
    const getLastUploads = () => {
        const { projectId, folders, excludedFolders } = props;
        return (<FolderSelectorLastUploads projectId={projectId} folders={folders} onSelect={props.onSelect} excludedFolders={excludedFolders} height={tabHeight}/>);
    };
    return (<View style={{ width: '100%', height: '100%' }}>
      <View style={{ paddingHorizontal: 16 }}>
        <TabBar contentHeight={0} tabs={[
            { title: I18n.m.getMessage('selectorFoldersSuggestions') },
            { title: I18n.m.getMessage('selectorFolders') },
            { title: I18n.m.getMessage('selectorFoldersLastUploads') },
        ]} width={tabWidth} tabId="FolderSelector" onChangedSelected={() => setCurrentFolder('')}/>
        <TabContent tabId="FolderSelector" contents={[getSuggestions(), getFolderList(), getLastUploads()]} height={tabHeight} width={tabWidth}/>
      </View>
    </View>);
}

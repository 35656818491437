var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddEmailDialog = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _AddEmail = require("upmesh-auth-core/build/src/client/commands/user/AddEmail");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _Form = require("../../../theme/components/forminput/Form");
var _I18n = require("../../../theme/i18n/I18n");
var _Routing = require("../../../theme/routing/Routing");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var AddEmailDialog = exports.AddEmailDialog = function (_PureComponent) {
  function AddEmailDialog() {
    var _this;
    (0, _classCallCheck2.default)(this, AddEmailDialog);
    _this = _callSuper(this, AddEmailDialog, arguments);
    _this.onSaved = function () {
      _Routing.Routing.instance.closeDialog(true)(null);
    };
    return _this;
  }
  (0, _inherits2.default)(AddEmailDialog, _PureComponent);
  return (0, _createClass2.default)(AddEmailDialog, [{
    key: "render",
    value: function render() {
      var currentData = {
        email: '',
        userId: _CurrentUser.CurrentUser.userId
      };
      var dataOptions = new Map();
      dataOptions.set('email', {
        formType: 'email',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('email')
        }
      });
      return (0, _jsxRuntime.jsx)(_Form.Form, {
        formHeaderProps: {
          formTitle: _I18n.I18n.m.getMessage('accountEmailsAdd')
        },
        stickyActions: true,
        stickyHeader: true,
        command: new _AddEmail.AddEmail(currentData),
        store: _AuthClient.AuthClient.instance.commandStore,
        dataOptions: dataOptions,
        resetButton: false,
        onSaved: this.onSaved,
        saveButtonLabel: _I18n.I18n.m.getMessage('add')
      });
    }
  }]);
}(_react.PureComponent);
import React from 'react';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
export function FilesBreadCrumbs(props) {
    const generateFolderLocations = (folderId) => {
        const { folders } = props;
        const items = [];
        const f = folders.find((e) => e.id === folderId);
        if (f != null) {
            items.unshift({ title: f.title, id: f.id });
            if (f.subFolderFromId != null && f.subFolderFromId.length > 0) {
                items.unshift(...generateFolderLocations(f.subFolderFromId));
            }
        }
        return items;
    };
    const getLocationElement = (folderId, folderName) => {
        return (<MaterialText key={`folder_location:${folderId}`} type={MaterialTextTypes.Body1} onPress={props.goToFolder(folderId)}>
        {folderName}
      </MaterialText>);
    };
    const generateLocations = () => {
        const { documentsCount, currentFolder } = props;
        const items = [];
        if (currentFolder === 'all')
            items.push(getLocationElement(currentFolder, I18n.m.getMessage('menuProjectFiles')));
        else if (currentFolder === 'search')
            items.push(getLocationElement(currentFolder, I18n.m.getMessage('searchNoun')));
        else if (currentFolder === 'private')
            items.push(getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPrivate')));
        else if (currentFolder === 'public')
            items.push(getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPublic')));
        else if (currentFolder === 'reports')
            items.push(getLocationElement(currentFolder, I18n.m.getMessage('menuProjectDailyReports')));
        else if (currentFolder === 'tickets')
            items.push(getLocationElement(currentFolder, I18n.m.getMessage('tickets')));
        else {
            items.push(getLocationElement('public', I18n.m.getMessage('filesFolderPublic')));
            const locations = generateFolderLocations(currentFolder);
            locations.forEach((f) => {
                items.push(<MaterialText key={`sep_${f.id}`} type={MaterialTextTypes.Body1}>
            {' › '}
          </MaterialText>);
                items.push(getLocationElement(f.id, f.title));
            });
        }
        if (documentsCount != null)
            items.push(<MaterialText key="documentsCount" type={MaterialTextTypes.Body1}>
          ({documentsCount})
        </MaterialText>);
        return items;
    };
    return <View style={{ flexDirection: 'row' }}>{generateLocations()}</View>;
}

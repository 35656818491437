"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessagesDe = void 0;
var MessagesDe = (0, _createClass2.default)(function MessagesDe() {
  (0, _classCallCheck2.default)(this, MessagesDe);
  this.SignUpErrorGoogleUserAlreadyExisting = 'Dieser Google Account ist bereits registriert.';
  this.SignUpErrorMicrosoftUserAlreadyExisting = 'Dieser Microsoft Account ist bereits registriert.';
  this.accountLocked = 'Der Account ist gesperrt, bitte wenden Sie sich an den Support';
  this.accountMarkedForDeletion = 'Für den Account wurde die Löschung beantragt';
  this.addressErrorRequireCity = 'Stadt wird benötigt';
  this.addressErrorRequireCountry = 'Land wird benötigt';
  this.addressErrorRequireStreet = 'Straße wird benötigt';
  this.addressErrorRequireStreetNumber = 'Hausnummer wird benötigt';
  this.addressErrorRequireZip = 'Postleitzahl wird benötigt';
  this.cantDeleteEmail = 'Diese E-Mail-Adresse kann nicht gelöscht werden!';
  this.companyErrorRequireCompanyName = 'Firma wird benötigt';
  this.companyErrorRequireFunctionInCompany = 'Position wird benötigt';
  this.dbError = 'Datenbank Fehler';
  this.eMailNotFound = 'E-Mail-Adresse nicht gefunden';
  this.emailValidateError = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden';
  this.emailValidateErrorNoEmail = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, E-Mail nicht gefunden';
  this.emailValidateErrorNoSuchCode = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
  this.emailValidateErrorTitle = 'E-Mail-Adresse verifizieren';
  this.emailValidateErrorTokenExpired = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, Code abgelaufen';
  this.emailValidateErrorTokenInvalid = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
  this.emailValidationError = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden';
  this.forbidden = 'Keine Berechtigung';
  this.internalServer = 'interner Server Fehler';
  this.loginError = 'Fehlerhafte Zugangsdaten';
  this.loginErrorAccountDisabled = 'Account gesperrt, bitte wenden Sie sich an den Support!';
  this.loginErrorEmailNotValidated = 'Ihre E-Mail-Adresse ist noch nicht verifiziert. Sie sollten in Kürze eine E-Mail erhalten. Bitte bestätigen Sie' + ' zunächst den Link in der E-Mail, um sich wieder einloggen zu können.';
  this.loginErrorNoAccount = 'Account nicht vorhanden, jetzt Registrieren?';
  this.loginErrorPasswordRequired = 'Ihr Passwort wird benötigt';
  this.loginErrorWrongCredentials = 'Fehlerhafte Zugangsdaten';
  this.loginErrorWrongPassword = 'Fehlerhafte Zugangsdaten';
  this.notificationAdv = 'Mit upmesh mobil arbeiten';
  this.notificationAgb = 'Nutzungsbedingungen';
  this.notificationImprint = 'Impressum';
  this.notificationNoReply = 'Dies ist eine automatisch generierte E-Mail.';
  this.notificationPrivacy = 'Datenschutzerklärung';
  this.notificationWebinars = 'Hilfebereich';
  this.notimplemented = 'nicht implementiert';
  this.passwordErrorPwWeak = 'Passwort ist zu schwach';
  this.passwordErrorRequirePw = 'Passwort wird benötigt';
  this.passwordErrorRequireToken = 'Code wird benötigt';
  this.passwordErrorTokenInvalid = 'Code abgelaufen';
  this.registerErrorRequireEmail = 'Ihre E-Mail-Adresse wird benötigt';
  this.registerErrorRequireFirstName = 'Ihr Vorname wird benötigt';
  this.registerErrorRequireLastName = 'Ihr Nachname wird benötigt';
  this.registerErrorRequireUserId = 'User id wird benötigt';
  this.registerErrorUserAlreadyExisting = 'bereits registriert';
  this.required = '{{field}} wird benötigt';
  this.signUpErrorUserAlreadyExisting = 'bereits registriert';
  this.stringToLong = 'zu lang';
  this.unauthorized = 'nicht erlaubt';
  this.userMailNotFound = 'E-Mail-Adresse nicht gefunden';
  this.userNotFound = 'Benutzer nicht gefunden';
});
exports.MessagesDe = MessagesDe;
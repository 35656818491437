import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { I18n } from '../../../../i18n/I18n';
import { CompanyUserInfo } from '../../../root/CompanyUserInfo';
export function CopyListsDialog(props) {
    const [lists, setLists] = useState([]);
    useEffect(() => {
        const c = CompanyUserInfo.companySettings;
        if (c && c.lists) {
            const lists = [];
            for (const k in c.lists) {
                if (c.lists[k].list && c.lists[k].list.length > 0) {
                    lists.push({ name: c.lists[k].name, list: c.lists[k].list });
                }
            }
            setLists(lists);
        }
    }, []);
    const selectList = (l) => (_e) => {
        DialogUp.instance?.close();
        props.onSelect(l);
    };
    return (<>
      <DialogTitle>{I18n.m.getMessage('listCopyFromTitle')}</DialogTitle>
      <View style={{ width: '100%' }}>
        {(lists == null || lists.length === 0) && (<MaterialText>{I18n.m.getMessage('listCopyFromEmptyState')}</MaterialText>)}
        {lists &&
            lists.map((m) => {
                return <ListItem title={m.name} secondTextLine={m.subTitle} onPress={selectList(m.list)}/>;
            })}
      </View>
    </>);
}
export const openCopyListsDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    DialogUp.instance?.open({
        openPosition,
        showCloseIcon: true,
        fullscreenResponsive: true,
        closeOnTouchOutside: false,
        scrollable: true,
        contentPadding: false,
        content: <CopyListsDialog {...props}/>,
    });
};
export const hasLists = () => {
    if (CompanyUserInfo.companySettings && CompanyUserInfo.companySettings.lists) {
        for (const k in CompanyUserInfo.companySettings.lists) {
            if (CompanyUserInfo.companySettings.lists[k].list && CompanyUserInfo.companySettings.lists[k].list.length > 0) {
                return true;
            }
        }
    }
    return false;
};

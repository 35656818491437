import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
import { UpdateProjectMessageDialog } from './UpdateProjectMessageDialog';
export class ProjectTicketstatusView extends PureComponent {
    constructor(props) {
        super(props);
        this.statusKeys = [
            'ticketsDetailsStateopen',
            'ticketsDetailsStateprocessing',
            'ticketsDetailsStateclosed',
            'ticketsDetailsStatechecked',
        ];
        this.openEditDialog = (i) => (_e) => {
            const { project } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <UpdateProjectMessageDialog id={i.id} value={i.value} original={i.original} project={project}/>,
                contentPadding: false,
                scrollable: true,
                showCloseIcon: true,
            });
        };
        this.state = {};
    }
    componentDidUpdate(prevProps) {
        if (prevProps.project !== this.props.project ||
            prevProps.project.messagesOverride !== this.props.project.messagesOverride) {
            this.updateData();
        }
    }
    componentDidMount() {
        this.updateData();
    }
    updateData() {
        const { project } = this.props;
        const data = [];
        this.statusKeys.forEach((k, i) => {
            data.push({
                id: k,
                original: I18n.m.getMessage(k, {}, {}, true),
                order: i + 1,
                value: project.messagesOverride != null && project.messagesOverride[k] != null ? project.messagesOverride[k] : '',
            });
        });
        this.setState({ data });
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { data } = this.state;
        const { size } = this.props;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(108);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('ticketState')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>
          <Table tableName="ProjectTicketstatus" actionItemsLength={1} actions={(_item) => [
                { icon: 'pencil-outline', onAction: this.openEditDialog, toolTip: I18n.m.getMessage('edit') },
            ]} maxHeight={tableMaxHeight} data={data} sortBy="order" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('ticketStateOrder'),
                    keyInData: 'order',
                    style: { width: 100 },
                    sortable: true,
                    dataType: 'number',
                },
                {
                    title: I18n.m.getMessage('ticketStateOriginal'),
                    keyInData: 'original',
                    style: { width: 350 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('ticketStateCustom'),
                    keyInData: 'value',
                    style: { width: 350 },
                    sortable: true,
                    dataType: 'string',
                },
            ]}/>
        </Card>
      </View>);
    }
}

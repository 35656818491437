import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { SwitchListItem } from 'materialTheme/src/theme/components/SwitchListItem';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { CustomFieldDateOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { I18n } from '../../../../i18n/I18n';
import { TicketLayoutDefaultOptions } from './TicketLayoutDefaultOptions';
export function TicketLayoutDateFieldOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const [formError, setFormError] = useState(new Map());
    const submitForm = (_e) => {
        if (currentField.label == null || currentField.label.length === 0) {
            const s = new Map();
            s.set('label', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        setFormError(new Map());
        props.onSave(currentField);
        DialogBetween.instance?.close();
    };
    const icon = TicketLayoutsEntity.getIconName(props.field);
    return (<>
      <DialogTitle iconRight={{ icon, color: ThemeManager.style.brandSuccess, toolTip: '' }}>
        {props.newField
            ? I18n.m.getMessage('ticketLayoutOptionsTileNew')
            : I18n.m.getMessage('ticketLayoutOptionsTileEdit', { title: currentField.label })}
      </DialogTitle>
      <DialogContent>
        <TicketLayoutDefaultOptions approverCanVisible={props.approverCanVisible} assigneeCanEditVisible={props.assigneeCanEditVisible} field={currentField} onChange={(f) => {
            setCurrentField({ ...currentField, ...f });
        }} onError={(err) => {
            setFormError(err);
        }} onSuccess={() => setFormError(new Map())}>
          <SwitchListItem title={I18n.m.getMessage('ticketLayoutOptionsDateWithTime')} value={!!currentField.customField?.options.withTime} onChange={(e) => {
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'Date', options: new CustomFieldDateOptions() };
            if (customField.options == null)
                customField.options = new CustomFieldDateOptions();
            customField.options.withTime = e;
            setCurrentField({ ...currentField, customField });
        }}/>
        </TicketLayoutDefaultOptions>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => DialogBetween.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton disabled={formError.size > 0 || currentField.label == null || currentField.label.length === 0} onPress={submitForm} title={props.newField ? I18n.m.getMessage('create') : I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}

import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ChangeProjectState, } from 'upmesh-core/src/client/commands/companies/projectstates/ChangeProjectState';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function CompanyProjectStatePicker(props) {
    const [selected, setSelected] = useState(0);
    const [hasRights, setHasRights] = useState(false);
    const availableState = () => {
        const states = [];
        const rawStates = CompanyUserInfo.companySettings?.projectStateNames.sort((a, b) => a.order - b.order);
        states.push({
            title: I18n.m.getMessage('projectStateNoState'),
            onPressChipData: { order: 0, id: '-1' },
        });
        if (rawStates != null) {
            for (const elem of rawStates) {
                states.push({
                    title: elem.name,
                    onPressChipData: elem,
                    thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: elem.color }}/>,
                });
            }
        }
        return states;
    };
    useEffect(() => {
        if (props.projectId != null && props.projectId.length > 0) {
            const cCommand = new ChangeProjectState({
                stateId: CompanyUserInfo.companySettings?.projectStateNames[0].id,
                projectId: props.projectId,
                companyId: CompanyUserInfo.companySettings?.id ?? '',
            });
            cCommand
                .canI()
                .then((value) => {
                setHasRights(value === true);
            })
                .catch((err) => {
                console.debug(err);
            });
        }
        else {
            setHasRights(true);
        }
    }, []);
    useEffect(() => {
        const settings = CompanyUserInfo.companySettings;
        if (settings != null) {
            UpmeshClient.instance.modals.companyProjectInfo
                .get({
                filter: `projectId eq '${props.projectId}' and companyId eq '${settings.id}' and deleted ne true`,
            })
                .then((elems) => {
                if (elems.length > 0) {
                    const current = elems[0];
                    if (current.projectStateId != null) {
                        const state = settings.projectStateNames.find((elem) => elem.id === current.projectStateId);
                        if (state != null) {
                            setSelected(state.order);
                        }
                    }
                }
            })
                .catch((err) => {
                console.error('error on query for company project info', err);
            });
        }
    }, []);
    const onChangeState = (e) => {
        setSelected(e.order);
        if (props.onPressChip != null) {
            props.onPressChip(e);
        }
        if (props.changeDirectly === true) {
            const changeCommand = new ChangeProjectState({
                stateId: e.id === '-1' ? null : e.id,
                projectId: props.projectId,
                companyId: CompanyUserInfo.companySettings?.id ?? '',
            });
            changeCommand.execute(ClientStore.commandStore).catch((err) => {
                console.error('could not change project state', err);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
    };
    return (<View>
      {props.changeDirectly ? (<MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectState')}</MaterialText>) : null}
      <OpenableChip formInputLabel={I18n.m.getMessage('projectState')} looksLikeFI={!props.changeDirectly} disableAutoSort key="projectStateChip" dialogTitle={I18n.m.getMessage('projectState')} chipsList={availableState()} selected={selected} onPressChip={onChangeState} chipDisabled={!hasRights}/>
    </View>);
}

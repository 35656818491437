Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var _ThemeManager = require("../ThemeManager");
var styles = exports.styles = {
  header: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    height: 54,
    paddingHorizontal: 5
  },
  headerItem: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 15,
    borderColor: _ThemeManager.ThemeManager.style.borderColor,
    borderRightWidth: _ThemeManager.ThemeManager.style.borderWidth
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 5,
    justifyContent: 'flex-start'
  },
  bottomBorderStyle: {
    borderBottomColor: _ThemeManager.ThemeManager.style.borderColor,
    borderBottomWidth: _ThemeManager.ThemeManager.style.borderWidth
  },
  cellContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16
  },
  cellContent: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  emptyTableContainer: {
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  loadingSpinner: {
    width: '100%',
    padding: _ThemeManager.ThemeManager.style.contentPaddingValue,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  }
};
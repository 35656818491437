import { ScrollView } from 'react-native';
import React, { useState } from 'react';
import { ChatMessage } from './ChatMessage';
export function ChatView(props) {
    const [customWidth, setCustomWidth] = useState(512);
    const updateWidth = (e) => {
        if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
            setCustomWidth(e.nativeEvent.layout.width);
        }
    };
    const renderChat = () => {
        const currentMessages = [...props.messages];
        currentMessages.sort((a, b) => a.timestamp - b.timestamp);
        return currentMessages.map((item) => {
            return <ChatMessage key={`${item.timestamp}_${item.type}`} messageProp={item} customWidth={customWidth}/>;
        });
    };
    return (<ScrollView style={{
            backgroundColor: 'rgba(230,230,230,0.5)',
            height: props.contentHeight,
            width: '100%',
        }} onLayout={updateWidth}>
      {renderChat()}
    </ScrollView>);
}

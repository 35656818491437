"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CopyProject = void 0;
var uuid = require("uuid");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var CopyProject = function (_ClientProjectCommand) {
  function CopyProject(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CopyProject);
    _this = _callSuper(this, CopyProject, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.waitForResult = false;
    _this.commandName = 'CopyProject';
    return _this;
  }
  (0, _inherits2.default)(CopyProject, _ClientProjectCommand);
  return (0, _createClass2.default)(CopyProject, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        if (key == null || key === 'newProjectName') {
          if (this.data.newProjectName.length <= 0) {
            errors.push({
              key: 'v',
              messageCode: 'projectErrorTitleRequired',
              message: 'Titel wird benötigt'
            });
          }
          if (this.data.newProjectName.length > 40) {
            errors.push({
              key: 'newProjectName',
              messageCode: 'projectErrorTitleToLong',
              message: 'Projekt titel zu lang (max 40)'
            });
          }
        }
        if (key == null || key === 'copyTickets') {
          if (this.data.copyTickets) {
            var ticketCount = yield CommandReadModels_1.CommandReadModels.instance.ticket.count(`projectId eq '${this.project.id}' and archived ne true`);
            if (ticketCount > 500) {
              throw {
                message: 'maximal 500 Tickets können kopiert werden',
                messageCode: 'projectErrorTooManyTickets'
              };
            }
          }
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        try {
          this.project = yield CommandReadModels_1.CommandReadModels.project.getById(this.data.projectId);
          if (this.project.projectSubscription == null || this.project.projectSubscription === 'basic') {
            throw {
              message: 'Diese Funktion ist in Basic-Projekten nicht verfügbar',
              messageCode: 'notInBasicProject'
            };
          }
          yield _superPropGet(CopyProject, "checkProjectStatusAndGeneralAccess", this, 3)([this.data.projectId, userId, true]);
          if (userId === this.project.creator) return true;
          var pu = yield CommandReadModels_1.CommandReadModels.projectUser.get({
            filter: `projectId eq '${this.project.id}' and userId eq '${userId}' and status eq 'accepted'`
          });
          if (this.project.projectSubscription === 'enterprise') {
            var companyProject = yield CommandReadModels_1.CommandReadModels.instance.companyProject.get({
              filter: `id eq '${this.project.id}'`
            });
            if (companyProject.length < 1) {
              throw {
                message: 'Keine Berechtigung',
                messageCode: 'forbiddenCommand'
              };
            }
            var isInCompany = yield CommandReadModels_1.CommandReadModels.instance.companyMember.get({
              filter: `companyId eq '${companyProject[0].companyId}' and userId eq '${userId}'`
            });
            if (isInCompany.length > 0) {
              return true;
            }
          } else if (pu != null && pu.length > 0 && pu[0].roleId === '1') return true;
        } catch (e) {
          throw e;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CopyProject = CopyProject;
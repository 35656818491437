import React from 'react';
import { View } from 'react-native';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
export function ChatMessage(props) {
    const getMaxBubbleWidth = () => {
        return props.customWidth - 8 - 24 - 16;
    };
    const renderIcon = (icon) => {
        if (icon instanceof UserEntity) {
            return <UserImage size={24} user={icon}/>;
        }
        return <Icon iconSize={24} toolTip={icon.toolTip} icon={icon.icon}/>;
    };
    if (props.messageProp.type === 'GenericMessageProp') {
        const chatItem = props.messageProp;
        return (<View style={{
                paddingLeft: ThemeManager.style.getScreenRelativePixelSize(4),
                paddingRight: ThemeManager.style.getScreenRelativePixelSize(4),
                paddingBottom: ThemeManager.style.contentPaddingValue,
                flexDirection: chatItem.right ? 'row-reverse' : 'row',
                alignSelf: 'stretch',
                width: '100%',
            }}>
        {renderIcon(chatItem.icon)}
        <View style={{
                paddingTop: 12,
            }}>
          <View style={{
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
                backgroundColor: chatItem.right ? '#FCFCFC' : '#FFFFFF',
                borderWidth: ThemeManager.style.borderWidth,
                borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                borderTopLeftRadius: chatItem.right ? ThemeManager.style.getScreenRelativePixelSize(12) : 0,
                borderTopRightRadius: chatItem.right ? 0 : ThemeManager.style.getScreenRelativePixelSize(12),
                padding: ThemeManager.style.getScreenRelativePixelSize(8),
                width: getMaxBubbleWidth(),
            }}>
            <MaterialText type={MaterialTextTypes.Body1}>{chatItem.text}</MaterialText>
          </View>
        </View>
      </View>);
    }
    if (props.messageProp.type === 'CustomMessageProp') {
        const chatItem = props.messageProp;
        return (<View style={{
                paddingBottom: 24,
                paddingLeft: ThemeManager.style.getScreenRelativePixelSize(4),
                paddingRight: ThemeManager.style.getScreenRelativePixelSize(4),
                borderWidth: 0,
                borderColor: 'rgba(0,0,0,0)',
                flexDirection: chatItem.right ? 'row-reverse' : 'row',
            }}>
        {renderIcon({ toolTip: 'tooltip', icon: 'robot' })}
        <View style={{
                paddingTop: 12,
            }}>
          <View style={{
                width: '100%',
                maxWidth: '100%',
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
                backgroundColor: chatItem.right ? '#FCFCFC' : '#FFFFFF',
                borderWidth: ThemeManager.style.borderWidth,
                borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                borderTopLeftRadius: chatItem.right ? ThemeManager.style.getScreenRelativePixelSize(12) : 0,
                borderTopRightRadius: chatItem.right ? 0 : ThemeManager.style.getScreenRelativePixelSize(12),
                padding: ThemeManager.style.getScreenRelativePixelSize(8),
            }}>
            {chatItem.content}
          </View>
        </View>
      </View>);
    }
    if (props.messageProp.type === 'CustomItemProp') {
        const chatItem = props.messageProp;
        return (<View style={{
                width: '100%',
                paddingBottom: 24,
                paddingLeft: ThemeManager.style.getScreenRelativePixelSize(12),
                flexDirection: 'row',
            }}>
        {chatItem.content}
      </View>);
    }
    return <View />;
}

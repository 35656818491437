import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class FolderTagsComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.closeDialog = () => {
            DialogUp.instance?.close();
        };
        this.onChangedTags = (chips) => {
            this.setState({ currentTags: [...chips] }, () => {
                const { onChange } = this.props;
                if (onChange != null) {
                    const v = this.getValue();
                    onChange(v);
                }
            });
        };
        this.state = { currentTags: [], projectTags: [], projectTagGroups: [] };
    }
    blur() { }
    clear() { }
    focus() { }
    getId() {
        return '';
    }
    setValue(value) {
        this.init(value == null ? [] : value).catch((err) => console.debug(err));
    }
    getValue() {
        const tags = [];
        const getChips = this.chipGroup.getCurrentChips();
        getChips.forEach((c) => {
            tags.push({ tagName: c.id, color: c.backgroundColor, groupName: c.groupId });
        });
        return tags;
    }
    componentDidMount() {
        this.init(this.props.folder.tags).catch((err) => console.debug(err));
    }
    async init(tags) {
        const { projectId } = this.props.folder;
        const projectTags = [];
        const projectTagGroups = [];
        const project = await UpmeshClient.instance.modals.project.getById(projectId);
        project.tags?.forEach((t) => {
            projectTags.push({
                id: t.tagName,
                groupId: t.groupName,
                title: t.tagName,
                backgroundColor: t.color,
            });
        });
        for (const key in project.tagGroups) {
            projectTagGroups.push({
                backgroundColor: project.tagGroups[key].color,
                groupName: project.tagGroups[key].groupName,
                id: project.tagGroups[key].groupName,
            });
        }
        const currentTags = [];
        if (tags != null && tags.length > 0) {
            tags.forEach((t) => {
                currentTags.push({
                    id: t.tagName,
                    title: t.tagName,
                    backgroundColor: t.color,
                    groupId: t.groupName,
                });
            });
        }
        this.setState({ projectTagGroups, currentTags: [...currentTags], projectTags });
    }
    render() {
        const { projectTagGroups, currentTags, projectTags } = this.state;
        return (<View style={{
                paddingBottom: 8,
                width: '100%',
            }}>
        <ChipGroup ref={(c) => {
                this.chipGroup = c;
            }} label="Tags" textColor="#FFFFFF" bordered={false} key={`test${currentTags.toString()}`} availableChips={projectTags} chips={currentTags} chipGroups={projectTagGroups} onChanged={this.onChangedTags} tooltipAddChip={I18n.m.getMessage('addTags')}/>
      </View>);
    }
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientUserCommands = exports.UserCommandsData = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("../../../CurrentUser");
var UserCommandsData = (0, _createClass2.default)(function UserCommandsData() {
  (0, _classCallCheck2.default)(this, UserCommandsData);
});
exports.UserCommandsData = UserCommandsData;
var ClientUserCommands = function (_CommandRoot_1$Comman) {
  function ClientUserCommands(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CurrentUser_1.CurrentUser.userId;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ClientUserCommands);
    _this = _callSuper(this, ClientUserCommands, [data, entityId, token]);
    _this.waitForResult = true;
    _this.data = data;
    if (_this.data.userId == null) {
      _this.data.userId = CurrentUser_1.CurrentUser.userId;
    }
    return _this;
  }
  (0, _inherits2.default)(ClientUserCommands, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ClientUserCommands, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        try {
          var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
          var userId = tokenData._id;
          if (userId != null) {
            if (userId === 'all') {
              return true;
            }
            if (this.data.userId === userId) {
              return true;
            }
          }
        } catch (e) {}
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ClientUserCommands = ClientUserCommands;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginOrRegisterTitle = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _I18n = require("../../../theme/i18n/I18n");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LoginOrRegisterTitle = exports.LoginOrRegisterTitle = function (_PureComponent) {
  function LoginOrRegisterTitle() {
    (0, _classCallCheck2.default)(this, LoginOrRegisterTitle);
    return _callSuper(this, LoginOrRegisterTitle, arguments);
  }
  (0, _inherits2.default)(LoginOrRegisterTitle, _PureComponent);
  return (0, _createClass2.default)(LoginOrRegisterTitle, [{
    key: "render",
    value: function render() {
      var active = this.props.active;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          alignSelf: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'row',
          paddingBottom: 16
        },
        children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: _Routing.Routing.instance.goToButton('/login'),
          disabled: active === 'login',
          title: _I18n.I18n.m.getMessage('loginTitle'),
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 8,
            height: 2
          }
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 36,
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Button,
            color: _ThemeManager.ThemeManager.style.disabledColor,
            children: _I18n.I18n.m.getMessage('or').toUpperCase()
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 8,
            height: 2
          }
        }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: _Routing.Routing.instance.goToButton('/register'),
          disabled: active === 'register',
          title: _I18n.I18n.m.getMessage('loginRegister'),
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        })]
      });
    }
  }]);
}(_react.PureComponent);
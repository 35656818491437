"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthConfig = void 0;
var AuthConfig = (0, _createClass2.default)(function AuthConfig() {
  (0, _classCallCheck2.default)(this, AuthConfig);
  this.remoteUrl = 'http://127.0.0.1:3012';
  this.discardOnErrors = 10;
});
exports.AuthConfig = AuthConfig;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeAbsence = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var AbsenceChanged_1 = require("../../../events/company/absence/AbsenceChanged");
var CreateAbsence_1 = require("./CreateAbsence");
var ChangeAbsence = function (_CommandRoot_1$Comman) {
  function ChangeAbsence(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeAbsence);
    _this = _callSuper(this, ChangeAbsence, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeAbsence';
    _this.isPayroll = false;
    _this.datesToMinutes();
    return _this;
  }
  (0, _inherits2.default)(ChangeAbsence, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeAbsence, [{
    key: "datesToMinutes",
    value: function datesToMinutes() {
      if (this.data.starts != null) this.data.starts = CreateAbsence_1.CreateAbsence.roundDateToMinute(this.data.starts);
      if (this.data.ends != null) this.data.ends = CreateAbsence_1.CreateAbsence.roundDateToMinute(this.data.ends);
    }
  }, {
    key: "getAbsence",
    value: function () {
      var _getAbsence = (0, _asyncToGenerator2.default)(function* () {
        if (this._absence == null) this._absence = yield CommandReadModels_1.CommandReadModels.absence.getById(this.entityId);
        return this._absence;
      });
      function getAbsence() {
        return _getAbsence.apply(this, arguments);
      }
      return getAbsence;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var a = yield this.getAbsence();
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${a.companyId}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && (companyMember[0].payroll || companyMember[0].canPermitAbsenceFor === 'all')) {
          this.isPayroll = true;
          return true;
        }
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].canPermitAbsenceFor === 'group' && companyMember[0].id !== a.memberId) {
          var member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(a.memberId);
          if (member.groupId === companyMember[0].groupId) {
            this.isPayroll = true;
            return true;
          }
        }
        if (a.state === 'open' && (userId === a.createdBy || userId === a.userId)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "hasChanged",
    value: function hasChanged(a) {
      return this.data.costCenterId != null && a.costCenterId !== this.data.costCenterId || this.data.ends != null && a.ends.getTime() !== this.data.ends.getTime() || this.data.starts != null && a.starts.getTime() !== this.data.starts.getTime() || this.data.note != null && a.note !== this.data.note || this.data.projectId != null && a.projectId !== this.data.projectId || this.data.foreignId != null && a.foreignId !== this.data.foreignId || this.data.state != null && this.isPayroll && a.state !== this.data.state || this.data.taskId != null && a.taskId !== this.data.taskId || this.data.state != null && this.data.state !== a.state;
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var a = yield this.getAbsence();
        var hasChanged = this.hasChanged(a);
        if (!hasChanged) return [];
        var d = {};
        if (this.data.costCenterId != null && a.costCenterId !== this.data.costCenterId) d.costCenterId = this.data.costCenterId;
        if (this.data.ends != null && a.ends.getTime() !== this.data.ends.getTime()) d.ends = this.data.ends;
        if (this.data.starts != null && a.starts.getTime() !== this.data.starts.getTime()) d.starts = this.data.starts;
        if (this.data.note != null && a.note !== this.data.note) d.note = this.data.note;
        if (this.data.projectId != null && a.projectId !== this.data.projectId) d.projectId = this.data.projectId;
        if (this.data.foreignId != null && a.foreignId !== this.data.foreignId) d.foreignId = this.data.foreignId;
        if (this.data.state != null && this.isPayroll && a.state !== this.data.state) d.state = this.data.state;
        if (this.data.taskId != null && a.taskId !== this.data.taskId) d.taskId = this.data.taskId;
        if (this.data.state === 'approved' && this.isPayroll) d.approvedBy = userId;
        return new AbsenceChanged_1.AbsenceChanged(d, this.entityId, a.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var a = yield this.getAbsence();
        yield ChangeAbsence.validateAbsenceData(a, this.data, key);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "validateAbsenceData",
    value: function () {
      var _validateAbsenceData = (0, _asyncToGenerator2.default)(function* (a, data, key) {
        var errors = [];
        if (data.ends != null && data.starts != null) {
          if (data.starts.getTime() >= data.ends.getTime()) {
            errors.push({
              message: 'Endzeit vor Startzeit',
              messageCode: 'absenceErrorEndsBeforeStart',
              key: 'ends'
            });
          }
        }
        if (key == null || key === 'costCenterId') {
          if ((data.costCenterId == null || data.costCenterId.length === 0) && a.costCenterId == null && data.state === 'approved') {
            errors.push({
              message: 'Die Kostenstelle wird benötigt',
              messageCode: 'required',
              key: 'costCenterId'
            });
          } else if (data.costCenterId != null && data.costCenterId.length > 0) {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null || data.projectId.length === 0) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validateAbsenceData(_x2, _x3, _x4) {
        return _validateAbsenceData.apply(this, arguments);
      }
      return validateAbsenceData;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeAbsence = ChangeAbsence;
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
export class TimeTrackingDownload {
    static async downloadCSV(timeTrackingIds, lock, exportInformation) {
        const resultData = await TimeTrackingDownload.createCSV(timeTrackingIds, lock, exportInformation);
        const orgFilename = `timeTrackingExport.csv`;
        StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
            link: resultData.targetUrl,
            orgFilename,
        })).catch((e) => {
            console.error('cant download export time tracking result', resultData.targetUrl, e);
            throw e;
        });
        return resultData.warnings;
    }
    static async createCSV(entries, lock, exportInformation) {
        const f = JSON.stringify({
            entries,
            exportInformation,
        });
        let url = `${UpmeshClient.instance.url}/timetracking/csv/${I18n.m.currentLanguage}`;
        if (lock)
            url = `${url}?lock=true`;
        const headers = {};
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const result = await fetch(url, { headers, method: 'POST', body: f });
        if (result.status === 200) {
            const resultBody = await result.json();
            const link = encodeURIComponent(resultBody.token);
            return {
                targetUrl: `${UpmeshClient.instance.url}/timetracking/csv/${link}`,
                warnings: resultBody.warnings,
            };
        }
        try {
            throw await result.json();
        }
        catch (e) {
            throw e;
        }
    }
}

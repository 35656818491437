var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentHeaderStatus = exports.ContentHeaderEventHandler = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _tsEvents = require("ts-events");
var ContentHeaderStatus = exports.ContentHeaderStatus = (0, _createClass2.default)(function ContentHeaderStatus() {
  (0, _classCallCheck2.default)(this, ContentHeaderStatus);
});
var ContentHeaderEventHandler = exports.ContentHeaderEventHandler = (0, _createClass2.default)(function ContentHeaderEventHandler() {
  (0, _classCallCheck2.default)(this, ContentHeaderEventHandler);
});
ContentHeaderEventHandler.statusEvent = new _tsEvents.AsyncEvent();
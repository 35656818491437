var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MenuAnimation = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _Animation2 = _interopRequireDefault(require("./Animation"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MenuAnimation = exports.MenuAnimation = function (_Animation) {
  function MenuAnimation() {
    var _this;
    var toValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var animationDuration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 195;
    (0, _classCallCheck2.default)(this, MenuAnimation);
    _this = _callSuper(this, MenuAnimation, [toValue]);
    _this.animationDuration = animationDuration;
    _this.animations = _this.createAnimations();
    return _this;
  }
  (0, _inherits2.default)(MenuAnimation, _Animation);
  return (0, _createClass2.default)(MenuAnimation, [{
    key: "createAnimations",
    value: function createAnimations() {
      return {
        opacity: this.animate
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      _reactNativeWeb.Animated.timing(this.animate, {
        toValue: _toValue,
        duration: this.animationDuration,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
      }).start(function () {
        if (onFinished != null) {
          onFinished();
        }
      });
    }
  }]);
}(_Animation2.default);
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyProjectState, } from 'upmesh-core/src/client/commands/companies/projectstates/AddCompanyProjectState';
import { ChangeCompanyProjectState, } from 'upmesh-core/src/client/commands/companies/projectstates/ChangeCompanyProjectState';
import { I18n } from '../../i18n/I18n';
import { ColorComponent } from '../project/ColorComponent';
export class AddProjectStateToCompanyDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
        this.availableColors = [
            '#0073ff',
            '#00c6ff',
            '#8360c3',
            '#0f9b0f',
            '#38ef7d',
            '#FFE000',
            '#F37335',
            '#FF4B2B',
            '#203A43',
            '#bdc3c7',
        ];
        this.colorComponent = (props) => {
            return <ColorComponent {...props} propColors={this.availableColors} initColor={this.getInitColor()}/>;
        };
    }
    getInitColor() {
        const { projectState } = this.props;
        if (projectState != null && projectState.color.length > 0) {
            return projectState.color;
        }
        return this.availableColors[0];
    }
    render() {
        const { projectState, companySettings } = this.props;
        const currentData = {
            order: projectState?.order ?? companySettings.projectStateNames.length + 1,
            name: projectState?.name ?? '',
            color: projectState?.color ?? '#000000',
        };
        const dataOptions = new Map();
        dataOptions.set('name', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('projectStateName') },
        });
        dataOptions.set('color', {
            formType: 'individiual',
            cols: 2,
            props: { labelText: I18n.m.getMessage('color') },
            component: this.colorComponent,
        });
        if (projectState != null) {
            return (<Form key="changeCompanyProjectStateForm" formHeaderProps={{
                    formTitle: I18n.m.getMessage('projectStateChangeTitle'),
                }} stickyActions={false} command={new ChangeCompanyProjectState({ order: currentData.order, name: currentData.name, color: currentData.color, id: projectState.id }, companySettings.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('change')} additionalButtons={[
                    <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                ]}/>);
        }
        return (<Form key="addCompanyProjectStateForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('projectStateAddTitle'),
            }} stickyActions={false} command={new AddCompanyProjectState(currentData, companySettings.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('add')} additionalButtons={[
                <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}

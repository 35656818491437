var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeAdressDialog = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../../upmesh-client/node_modules/react"));
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _ChangeUserAdress = require("upmesh-auth-core/build/src/client/commands/user/ChangeUserAdress");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _Form = require("../../../../theme/components/forminput/Form");
var _I18n = require("../../../../theme/i18n/I18n");
var _Routing = require("../../../../theme/routing/Routing");
var _jsxRuntime = require("../../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ChangeAdressDialog = exports.ChangeAdressDialog = function (_PureComponent) {
  function ChangeAdressDialog(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, ChangeAdressDialog);
    _this = _callSuper(this, ChangeAdressDialog, [props, context]);
    _this.onChange = function (en) {
      en.entities.forEach(function (e) {
        _this.setState({
          userAdress: e.entity
        }, function () {
          return _this.forceUpdate();
        });
      });
    };
    _this.onSaved = function () {
      _Routing.Routing.instance.closeDialog(true)(null);
    };
    _this.state = {
      userAdress: undefined
    };
    return _this;
  }
  (0, _inherits2.default)(ChangeAdressDialog, _PureComponent);
  return (0, _createClass2.default)(ChangeAdressDialog, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.attachKey = _AuthClient.AuthClient.instance.eventDispatcher.attach({
        readModelName: 'UserAdress',
        callback: this.onChange
      });
      this.init().catch(function (err) {
        return void 0;
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.attachKey != null) {
        _AuthClient.AuthClient.instance.eventDispatcher.detach('UserAdress', this.attachKey);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var userAdress = this.state.userAdress;
      var currentData = {
        street: userAdress != null && userAdress.street != null ? userAdress.street : '',
        streetnumber: userAdress != null && userAdress.streetnumber != null ? userAdress.streetnumber : '',
        zip: userAdress != null && userAdress.zip != null ? userAdress.zip : '',
        city: userAdress != null && userAdress.city != null ? userAdress.city : '',
        country: userAdress != null && userAdress.country != null ? userAdress.country : ''
      };
      var dataOptions = new Map();
      dataOptions.set('street', {
        formType: 'string',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('accountChangeAddressStreet'),
          autoFocus: true
        }
      });
      dataOptions.set('streetnumber', {
        formType: 'string',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('accountChangeAddressStreetN')
        }
      });
      dataOptions.set('zip', {
        formType: 'string',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('accountChangeAddressZip')
        }
      });
      dataOptions.set('city', {
        formType: 'string',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('accountChangeAddressCity')
        }
      });
      dataOptions.set('country', {
        formType: 'string',
        cols: 2,
        props: {
          labelText: _I18n.I18n.m.getMessage('accountChangeAddressCountry')
        }
      });
      return (0, _jsxRuntime.jsx)(_Form.Form, {
        baseCols: 2,
        formHeaderProps: {
          formTitle: _I18n.I18n.m.getMessage('accountChangeAddressTitle')
        },
        stickyActions: true,
        stickyHeader: true,
        command: new _ChangeUserAdress.ChangeUserAdress(currentData),
        store: _AuthClient.AuthClient.instance.commandStore,
        dataOptions: dataOptions,
        resetButton: true,
        onSaved: this.onSaved
      }, userAdress != null ? userAdress.toString() : 'k');
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* () {
        var adress = yield _AuthClient.AuthClient.instance.modals.userAdress.get({
          filter: `userId eq '${_CurrentUser.CurrentUser.userId}'`
        });
        if (adress.length > 0) {
          this.setState({
            userAdress: adress[0]
          });
        }
      });
      function init() {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }]);
}(_react.PureComponent);
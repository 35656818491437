var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Alert = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _Dialog2 = require("./Dialog");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Alert = exports.Alert = function (_Dialog) {
  function Alert(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Alert);
    _this = _callSuper(this, Alert, [props]);
    _this.zIndex = 1500;
    return _this;
  }
  (0, _inherits2.default)(Alert, _Dialog);
  return (0, _createClass2.default)(Alert, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      Alert._instance = this;
    }
  }], [{
    key: "instance",
    get: function get() {
      if (Alert._instance != null) {
        return Alert._instance;
      }
      return null;
    }
  }]);
}(_Dialog2.Dialog);
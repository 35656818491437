var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spinner = Spinner;
var _react = _interopRequireDefault(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function Spinner(_ref) {
  var small = _ref.small,
    color = _ref.color,
    style = _ref.style;
  var size = small === true ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48);
  var setColor = color != null ? color : _ThemeManager.ThemeManager.style.brandSecondary;
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: Object.assign({
      alignSelf: 'center'
    }, style, {
      width: size,
      height: size
    }),
    children: (0, _jsxRuntime.jsx)(_reactNativeWeb.ActivityIndicator, {
      size: size,
      color: setColor
    })
  });
}
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { PanResponder, View } from 'react-native';
export class CustomMarkerComponent extends PureComponent {
    static get defaultProps() {
        return {
            onPress: null,
            afterDrag: null,
            style: { left: 0, top: 0 },
            borderSize: ThemeManager.style.getScreenRelativePixelSize(1),
        };
    }
    constructor(props) {
        super(props);
        this.onMouseEnter = () => {
            const { callout, openCallout, id, index } = this.props;
            if (callout === true) {
                this.setState({ showCallout: true }, () => {
                    if (openCallout != null) {
                        openCallout(id, index);
                    }
                });
            }
        };
        this.onMouseLeave = () => {
            const { callout, closeCallout, id, index } = this.props;
            if (callout === true) {
                this.setState({ showCallout: false }, () => {
                    if (closeCallout != null) {
                        closeCallout(id, index);
                    }
                });
            }
        };
        this.onPress = (e) => {
            const { callout, openTicket, index, id, openCallout } = this.props;
            if (callout === true) {
                const { showCallout } = this.state;
                if (showCallout) {
                    if (openTicket != null) {
                        openTicket(e, id, index);
                    }
                }
                else {
                    this.setState({ showCallout: false }, () => {
                        if (openCallout != null) {
                            openCallout(id, index);
                        }
                    });
                }
            }
        };
        this._onPressMarker = (_event) => {
        };
        const color = props.marker && props.marker.color ? props.marker.color : null;
        const borderColor = props.marker && props.marker.borderStyle && props.marker.borderStyle.color
            ? props.marker.borderStyle.color
            : 'rgba(0,0,0,0.87)';
        const borderSize = props.marker && props.marker.borderStyle && props.marker.borderStyle.size ? props.marker.borderStyle.size : 1;
        this.createPanResponder();
        this.state = {
            color,
            borderColor,
            borderSize: Math.ceil(ThemeManager.style.getScreenRelativePixelSize(borderSize)),
            dragableHighlight: 1,
            style: props.style,
            showCallout: false,
            calloutPosition: { x: 0, y: 0 },
        };
    }
    createPanResponder() {
        this.markerPanResponder = PanResponder.create({
            onStartShouldSetPanResponder: () => true,
            onPanResponderGrant: (_e, _gesture) => {
            },
            onPanResponderMove: (_e, gesture) => {
                if (this.props.marker.dragable && Math.abs(gesture.dx) + Math.abs(gesture.dy) > 0) {
                    this.dragging = true;
                    this.imgStyles.style.left = gesture.dx;
                    this.imgStyles.style.top = gesture.dy;
                    if (this.img) {
                        this.img.setNativeProps(this.imgStyles);
                    }
                }
            },
            onPanResponderRelease: (_e, _gesture) => {
                if (!this.dragging) {
                }
                else {
                    this.dragging = false;
                    if (typeof this.props.afterDrag === 'function') {
                        this.props.afterDrag(this.props.id, this.props.index, this.imgStyles.style);
                    }
                    this.imgStyles.style.left = 0;
                    this.imgStyles.style.top = 0;
                    if (this.img) {
                        this.img.setNativeProps(this.imgStyles);
                    }
                }
            },
        });
    }
    componentDidMount() {
        this.changeDragableHighlight();
        this.dragging = false;
        this.imgStyles = {
            style: {
                left: 0,
                top: 0,
            },
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const { color, style } = prevState;
        if ((nextProps.marker.color != null && color !== nextProps.marker.color) ||
            (nextProps.style != null && style !== nextProps.style)) {
            return { color: nextProps.marker.color, style: nextProps.style };
        }
        return null;
    }
    render() {
        const { color, borderColor, borderSize } = this.state;
        const { size } = this.props;
        return (<View style={{ width: size, height: size, ...this.props.style }} {...this.markerPanResponder.panHandlers}>
        <Ripple style={{ position: 'absolute', top: 0, left: 0, width: size, height: size }} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onPress={this.onPress}>
          <View style={{ position: 'absolute', top: 0, left: 0 }}>
            <Icon icon="map-marker" iconSize={size} color={borderColor} toolTip="" outerSize={size}/>
          </View>
          <View style={{ position: 'absolute', top: borderSize, left: borderSize }}>
            <Icon icon="map-marker" iconSize={size - borderSize * 2} color={color} toolTip="" outerSize={size - borderSize * 2}/>
            
            
            
            
            
            
            
            
            
            
            
          </View>
        </Ripple>
      </View>);
    }
    changeDragableHighlight() {
        if (this.oldDragable !== this.props.marker.dragable) {
            if (!this.props.marker.dragable) {
                this.setState({ dragableHighlight: 1 });
            }
            else {
                this.setState({ dragableHighlight: 0.5 });
            }
            this.oldDragable = this.props.marker.dragable;
        }
    }
}

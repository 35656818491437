import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { ScrollView } from 'react-native';
import { I18n } from '../../../i18n/I18n';
export function ProjectSettingsMenu(props) {
    const elements = [
        { title: I18n.m.getMessage('projectInfos'), key: 'profile', icon: 'information-outline' },
        { title: I18n.m.getMessage('ticketState'), key: 'status', icon: 'state-machine' },
        { title: I18n.m.getMessage('ticketTypes'), key: 'types', icon: 'ticket-outline' },
        { title: I18n.m.getMessage('ticketLayouts'), key: 'ticketLayouts', icon: 'form-select' },
        { title: I18n.m.getMessage('crafts'), key: 'crafts', icon: 'wrench-outline' },
        { title: I18n.m.getMessage('tags'), key: 'tags', icon: 'tag-outline' },
        {
            title: I18n.m.getMessage('reportTicketActivitiesCategories'),
            key: 'categories',
            icon: 'format-list-bulleted-type',
        },
    ];
    const selectPage = (key) => {
        const { onPress } = props;
        onPress(key);
    };
    const renderElement = (s) => {
        const { selected, sMode } = props;
        let isSelected = selected != null && s.key === selected;
        if (!sMode === true && selected == null && s.key === 'profile')
            isSelected = true;
        return (<Ripple key={s.key} style={{
                flexDirection: 'row',
                width: '100%',
                borderRadius: ThemeManager.style.borderRadius,
                backgroundColor: isSelected ? ThemeManager.style.brandPrimary : 'transparent',
                paddingLeft: 6,
                height: 40,
            }} accessibilityLabel={s.key} onPress={() => selectPage(s.key)}>
        <Icon toolTip="" icon={s.icon} color={isSelected ? '#ffffff' : ThemeManager.style.defaultIconColor}/>
        <MaterialText type={MaterialTextTypes.Body1} color={isSelected ? '#ffffff' : ThemeManager.style.black87} centeredBox>
          {s.title}
        </MaterialText>
      </Ripple>);
    };
    const renderList = () => {
        const list = [];
        elements.forEach((e) => list.push(renderElement(e)));
        return list;
    };
    return (<ScrollView style={{
            width: '100%',
            padding: 8,
            height: props.size.contentHeight - ThemeManager.style.headerHeight,
        }}>
      {renderList()}
    </ScrollView>);
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateAbsence = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var AbsenceCreated_1 = require("../../../events/company/absence/AbsenceCreated");
var CreateAbsence = function (_CommandRoot_1$Comman) {
  function CreateAbsence(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateAbsence);
    _this = _callSuper(this, CreateAbsence, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateAbsence';
    _this.isPayroll = false;
    _this.datesToMinutes();
    return _this;
  }
  (0, _inherits2.default)(CreateAbsence, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateAbsence, [{
    key: "datesToMinutes",
    value: function datesToMinutes() {
      this.data.starts = CreateAbsence.roundDateToMinute(this.data.starts);
      this.data.ends = CreateAbsence.roundDateToMinute(this.data.ends);
    }
  }, {
    key: "getCompany",
    value: function () {
      var _getCompany = (0, _asyncToGenerator2.default)(function* () {
        if (this._company == null) this._company = yield CommandReadModels_1.CommandReadModels.company.getById(this.data.companyId);
        return this._company;
      });
      function getCompany() {
        return _getCompany.apply(this, arguments);
      }
      return getCompany;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield this.getCompany();
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].payroll) {
          this.isPayroll = true;
          return true;
        }
        if (companyMember[0].absenceFor === 'nobody') {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        this.isPayroll = companyMember[0].canPermitAbsenceFor === 'all';
        if (this.data.memberId != null && this.data.memberId !== companyMember[0].id && companyMember[0].absenceFor === 'group') {
          var forMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId);
          if (forMember.groupId !== companyMember[0].groupId) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
        }
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].canPermitAbsenceFor === 'group' && companyMember[0].id !== this.data.memberId) {
          var member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId);
          if (member.groupId === companyMember[0].groupId) {
            this.isPayroll = true;
            return true;
          }
        }
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive') return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var companyMemberUserId = this.data.userId != null ? this.data.userId : (yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId)).userId;
        return new AbsenceCreated_1.AbsenceCreated({
          costCenterId: this.data.costCenterId,
          projectId: this.data.projectId,
          memberId: this.data.memberId,
          userId: companyMemberUserId,
          companyId: this.data.companyId,
          starts: new Date(this.data.starts),
          ends: new Date(this.data.ends),
          note: this.data.note,
          taskId: this.data.taskId,
          state: this.data.state && this.isPayroll ? this.data.state : 'open',
          approvedBy: this.data.state === 'approved' ? userId : undefined,
          foreignId: this.data.foreignId
        }, this.entityId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        yield CreateAbsence.validateAbsenceData(this.data, key);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "roundDateToMinute",
    value: function roundDateToMinute(date) {
      var d = new Date(date);
      d.setMinutes(d.getMinutes(), 0, 0);
      return d;
    }
  }, {
    key: "validateAbsenceData",
    value: function () {
      var _validateAbsenceData = (0, _asyncToGenerator2.default)(function* (data, key) {
        var errors = [];
        if (key == null || key === 'companyId') {
          if (data.companyId == null || data.companyId.length === 0) {
            errors.push({
              message: 'Die Firma wird benötigt',
              messageCode: 'required',
              key: 'companyId'
            });
          }
        }
        if (key == null || key === 'costCenterId') {
          if ((data.costCenterId == null || data.costCenterId.length === 0) && data.state === 'approved') {
            errors.push({
              message: 'Die Kostenstelle wird benötigt',
              messageCode: 'required',
              key: 'costCenterId'
            });
          } else if (data.costCenterId != null && data.costCenterId.length > 0) {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null || data.projectId.length === 0) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        if (key == null || key === 'memberId') {
          if (data.memberId == null || data.memberId.length === 0) {
            errors.push({
              message: 'Ein Benutzer wird benötigt',
              messageCode: 'required',
              key: 'memberId'
            });
          }
        }
        if (key == null || key === 'taskId') {
          if (data.taskId == null || data.taskId.length === 0) {
            errors.push({
              message: 'Eine Tätigkeit wird benötigt',
              messageCode: 'required',
              key: 'taskId'
            });
          }
        }
        if (key == null || key === 'starts') {
          if (data.starts == null || typeof data.starts.getTime !== 'function' || data.starts.getTime() === 0 || Number.isNaN(data.starts.getTime())) {
            errors.push({
              message: 'Die Startzeit wird benötigt',
              messageCode: 'required',
              key: 'starts'
            });
          }
        }
        if (key == null || key === 'ends') {
          if (data.ends == null || typeof data.ends.getTime !== 'function' || data.ends.getTime() === 0 || Number.isNaN(data.ends.getTime())) {
            errors.push({
              message: 'Die Endzeit wird benötigt',
              messageCode: 'required',
              key: 'ends'
            });
          }
          if (data.ends != null && data.starts != null) {
            if (data.starts.getTime() >= data.ends.getTime()) {
              errors.push({
                message: 'Endzeit vor Startzeit',
                messageCode: 'absenceErrorEndsBeforeStart',
                key: 'ends'
              });
            }
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validateAbsenceData(_x2, _x3) {
        return _validateAbsenceData.apply(this, arguments);
      }
      return validateAbsenceData;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateAbsence = CreateAbsence;
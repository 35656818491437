import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentProject } from '../project/CurrentProject';
import { TicketCardContent } from './TicketCardContent';
export class TicketCallOut extends PureComponent {
    constructor(props) {
        super(props);
        this.openTicket = (e) => {
            Routing.instance.openDialog('ticket', { id: this.props.ticket.id })(e);
        };
    }
    componentDidMount() {
        this.init(this.props).catch((err) => console.debug(err));
    }
    async init(_p) {
        this.setState({ init: true });
    }
    render() {
        const { fixedHeight, style } = this.props;
        const el = ThemeManager.style.elevation8;
        return (<View style={{
                width: 256,
                height: fixedHeight || 'auto',
                padding: 0,
                backgroundColor: '#FFFFFF',
                borderRadius: ThemeManager.style.borderRadius,
                ...el,
                ...style,
            }}>
        <View style={{ borderRadius: ThemeManager.style.borderRadius, overflow: 'hidden' }}>
          <TicketCardContent {...this.props} project={CurrentProject.instance.getCurrentProject()}/>
        </View>
      </View>);
    }
}

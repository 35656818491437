var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("../../../upmesh-client/node_modules/react"));
var _UserSettingsEntity = require("upmesh-auth-core/build/src/client/query/entities/UserSettingsEntity");
var userContext = _react.default.createContext({
  locale: null,
  user: null,
  settings: new _UserSettingsEntity.UserSettingsEntity()
});
var _default = exports.default = userContext;
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { UIManager } from 'react-native';
import { ChangeFolder } from 'upmesh-core/src/client/commands/folder/ChangeFolder';
import { CreateFolder } from 'upmesh-core/src/client/commands/folder/CreateFolder';
import { I18n } from '../../i18n/I18n';
import { ChangeFolderDialog } from './ChangeFolderDialog';
import { CreateFolderDialog } from './CreateFolderDialog';
import { MoveFolderDialog } from './MoveFolderDialog';
import { FolderStructure } from '../selectors/FolderStructure';
import { saveVisitedFolder } from '../selectors/saveVisitedFolders';
export class FilesViewFolders extends PureComponent {
    constructor(props) {
        super(props);
        this.changeFolder = (f) => (_e) => {
            const { projectId } = this.props;
            Routing.instance.goTo(`/projects/${projectId}/files/${f}`);
            saveVisitedFolder(f, projectId).catch((err) => console.debug(err));
        };
        this.openFolderMenu = (folderId) => (e) => {
            UIManager.measure(e.nativeEvent.target, (_originX, _originY, _width, height, pageX, pageY) => {
                const folder = this.props.folders.find((f) => f.id === folderId);
                const client = { height, width: 300, x: pageX, y: pageY };
                const items = [
                    {
                        title: I18n.m.getMessage('createSubfolder'),
                        onPress: this.createSubFolder(folder == null ? undefined : folder.id),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="folder-outline"/>, width: 24 },
                    },
                ];
                if (folder != null) {
                    items.push({
                        title: I18n.m.getMessage('edit'),
                        onPress: this.editFolder(folder),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="pencil-outline"/>, width: 24 },
                    }, {
                        title: I18n.m.getMessage('moveFolder'),
                        onPress: this.moveFolder(folder),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="folder-move-outline"/>, width: 24 },
                    }, {
                        title: I18n.m.getMessage('delete'),
                        onPress: () => this.props.deleteFolder(folder.id),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="delete-outline"/>, width: 24 },
                    });
                }
                MenuRaw.instance?.open({
                    client,
                    filterFormInput: false,
                    items,
                });
            });
        };
        this.moveFolder = (item) => (_e) => {
            MenuRaw.instance?.close();
            const { folders, projectId } = this.props;
            MoveFolderDialog.open(item, folders, projectId);
        };
        this.createSubFolder = (folderId) => () => {
            MenuRaw.instance?.close();
            const { projectId } = this.props;
            const c = new CreateFolder({
                subFolderFromId: folderId != null ? folderId : null,
                title: '',
                access: [],
                description: '',
                tags: [],
                projectId,
            });
            c.canI()
                .then(() => CreateFolderDialog.openDialog({ projectId, folderId }))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.editFolder = (folder) => () => {
            MenuRaw.instance?.close();
            const c = new ChangeFolder({
                title: folder.title,
                access: folder.access,
                tags: folder.tags,
                description: folder.description,
            }, folder.id);
            c.canI()
                .then(() => ChangeFolderDialog.openDialog({ folder }))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.state = {};
    }
    render() {
        const { width, folders, folder, projectId } = this.props;
        return (<FolderStructure folders={folders} folder={folder} projectId={projectId} width={width} onPressFolder={this.changeFolder} onPressMenuItem={this.openFolderMenu} menuIcon="dots-vertical"/>);
    }
}

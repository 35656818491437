var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DialogContainer = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ResizeEvent = require("../../ResizeEvent");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _DialogAnimation = require("../animations/DialogAnimation");
var _Overlay = require("../Overlay");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DialogContainer = exports.DialogContainer = function (_PureComponent) {
  function DialogContainer(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, DialogContainer);
    _this = _callSuper(this, DialogContainer, [props, context]);
    _this._sideBarWidth = 0;
    _this.isClosing = false;
    _this.onClosed = function (onClosed) {
      if (onClosed != null) onClosed();
      _this.sideBarWidth = 0;
      _this.setState({
        closed: true
      }, function () {
        _Routing.Routing.instance.dialogClosedEvent.post(_this.dialogId);
      });
    };
    _this.shouldClose = function (id) {
      if (id === _this.dialogId) {
        _this.close();
      }
    };
    _this.state = Object.assign({
      open: props.opened,
      overlayProps: {
        onPress: _Routing.Routing.instance.closeDialog(true),
        showOverlay: true,
        animationDuration: 195
      }
    }, DialogContainer.getSize(props));
    var click = {
      x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
      y: _ResizeEvent.ResizeEvent.current.windowHeight / 2
    };
    if (props.hiddenState != null && props.hiddenState.click != null) {
      click = props.hiddenState.click;
    }
    _this.dialogId = props.dialogId;
    _this.dialogAnimation = new _DialogAnimation.DialogAnimation(props.opened === true ? 1 : 0, click, 195, 24);
    return _this;
  }
  (0, _inherits2.default)(DialogContainer, _PureComponent);
  return (0, _createClass2.default)(DialogContainer, [{
    key: "sideBarWidth",
    set: function set(width) {
      this._sideBarWidth = width;
      this.props.onSideBarWidthChange(width);
    }
  }, {
    key: "close",
    value: function close(onClosed) {
      var _this2 = this;
      if (!this.isClosing) {
        this.isClosing = true;
        this.setState({
          overlayProps: {
            showOverlay: false
          }
        }, function () {
          if (_this2.sideBarWidth > 0) {
            _this2.onClosed(onClosed);
          } else {
            _this2.dialogAnimation.toValue(0, function () {
              return _this2.onClosed(onClosed);
            });
          }
        });
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this3 = this;
      _Routing.Routing.instance.dialogShouldCloseEvent.attach(this.shouldClose);
      if (!this.state.open) {
        this.dialogAnimation.toValue(1, function () {
          return _this3.setState({
            open: true
          });
        });
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.sideBarWidth = 0;
      _Routing.Routing.instance.dialogShouldCloseEvent.detach(this.shouldClose);
    }
  }, {
    key: "render",
    value: function render() {
      var closed = this.state.closed;
      if (closed) return null;
      var children = this.props.children;
      return this.renderModal(children);
    }
  }, {
    key: "renderContent",
    value: function renderContent(children) {
      if (this.stickyContent != null) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            height: '100%',
            position: 'relative'
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: '100%',
              height: '100%'
            },
            children: _react.default.cloneElement(children, Object.assign({}, this.state, this.props, children.props))
          }), this.stickyContent]
        });
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          height: '100%'
        },
        children: _react.default.cloneElement(children, Object.assign({}, this.state, this.props, children.props))
      });
    }
  }, {
    key: "renderModal",
    value: function renderModal(children, overlayStyle) {
      var _this$state = this.state,
        width = _this$state.width,
        height = _this$state.height,
        maxWidth = _this$state.maxWidth,
        maxHeight = _this$state.maxHeight,
        fullscreen = _this$state.fullscreen;
      var size = this.props.size;
      var overlayProps = Object.assign({}, _Overlay.Overlay.defaultProps, this.state.overlayProps, overlayStyle);
      var elevation = _ThemeManager.ThemeManager.style.elevation24;
      var sidePanel = this._sideBarWidth > 0;
      if (sidePanel && !fullscreen) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: Object.assign({
            width: this._sideBarWidth,
            height: size.contentHeight + size.contentBottom + size.contentTop,
            maxWidth: this._sideBarWidth,
            top: 0,
            paddingTop: size.contentTop,
            right: 0,
            paddingBottom: size.contentBottom,
            bottom: 0,
            backgroundColor: '#FFFFFF'
          }, _ThemeManager.ThemeManager.style.borderStyle, {
            borderWidth: 0,
            borderLeftWidth: _ThemeManager.ThemeManager.style.borderWidth,
            borderRadius: 0,
            position: 'absolute'
          }),
          children: this.renderContent(children)
        });
      }
      if (fullscreen) {
        overlayProps.backgroundColor = '#FFFFFF';
        overlayProps.opacity = 1;
        elevation = {
          zIndex: 24
        };
      }
      var style = {
        width: width,
        height: height,
        maxWidth: maxWidth,
        maxHeight: maxHeight
      };
      if (fullscreen) {
        style = {
          width: size.contentWidth,
          height: size.contentHeight + size.contentBottom,
          maxWidth: size.contentWidth,
          maxHeight: size.contentHeight + size.contentBottom - size.currentKeyboardHeight,
          top: size.contentTop,
          left: size.contentLeft,
          right: size.contentRight,
          paddingBottom: size.contentBottom,
          bottom: size.currentKeyboardHeight,
          borderRadius: 0
        };
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({}, _ThemeManager.ThemeManager.style.dialogModalBackground, {
          justifyContent: fullscreen || _ResizeEvent.ResizeEvent.current.currentKeyboardHeight > 0 ? 'flex-start' : 'center',
          alignItems: fullscreen ? 'flex-start' : 'center'
        }),
        children: [sidePanel ? null : (0, _jsxRuntime.jsx)(_Overlay.Overlay, Object.assign({}, overlayProps)), (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          style: Object.assign({}, _ThemeManager.ThemeManager.style.dialogContainerBox, elevation, this.dialogAnimation.animations, {
            flexDirection: 'column'
          }, style),
          children: this.renderContent(children)
        })]
      });
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nexProps) {
      return Object.assign({}, DialogContainer.getSize(nexProps));
    }
  }, {
    key: "getSize",
    value: function getSize(props) {
      var dialogType = props.dialogType;
      var fullscreen;
      var width = '90%';
      var height = 'auto';
      var maxWidth = props.maxWidth != null ? props.maxWidth : 560;
      var maxHeight = props.maxHeight != null ? props.maxHeight : '90%';
      if (dialogType === 'fullscreen' || dialogType === 'responsible' && _ResizeEvent.ResizeEvent.current.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM) {
        fullscreen = true;
        width = maxWidth = _ResizeEvent.ResizeEvent.current.contentWidth;
        height = maxHeight = _ResizeEvent.ResizeEvent.current.contentHeight;
      } else if (_ResizeEvent.ResizeEvent.current.currentKeyboardHeight > 0) {
        maxHeight = _ResizeEvent.ResizeEvent.current.contentHeight - _ResizeEvent.ResizeEvent.current.currentKeyboardHeight;
      }
      return {
        fullscreen: fullscreen,
        width: width,
        height: height,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        currentKeyboardHeight: _ResizeEvent.ResizeEvent.current.currentKeyboardHeight
      };
    }
  }]);
}(_react.PureComponent);
import React, { useEffect, useState } from 'react';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { View } from 'react-native';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { SUPPORTED_EXPORTS, } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { I18n } from '../../i18n/I18n';
export function NovalineOptions(props) {
    const [novalineMandantNr, setNovalineMandantNr] = useState('');
    const [novalineKundenNr, setNovalineKundenNr] = useState('');
    const [novalineCostUnitAsCostCenter, setNovalineCostUnitAsCostCenter] = useState(false);
    useEffect(() => {
        const value = SimpleStorage.get(`timeTrackingOptions${SUPPORTED_EXPORTS.novaline.short}`);
        if (value != null && value.length > 0) {
            const storedData = JSON.parse(value);
            setNovalineKundenNr(storedData.novalineKundenNr);
            setNovalineMandantNr(storedData.novalineMandantNr);
            setNovalineCostUnitAsCostCenter(storedData.novalineCostUnitAsCostCenter);
        }
    }, []);
    useEffect(() => {
        const data = SUPPORTED_EXPORTS.novaline;
        data.additionalData.novalineCostUnitAsCostCenter = novalineCostUnitAsCostCenter;
        data.additionalData.novalineKundenNr = novalineKundenNr;
        data.additionalData.novalineMandantNr = novalineMandantNr;
        props.onChange(data);
    }, [novalineMandantNr, novalineKundenNr, novalineCostUnitAsCostCenter]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            SimpleStorage.set(`timeTrackingOptions${SUPPORTED_EXPORTS.novaline.short}`, JSON.stringify({
                novalineCostUnitAsCostCenter,
                novalineKundenNr,
                novalineMandantNr,
            }));
        }, 250);
        return () => {
            clearTimeout(timeout);
        };
    }, [novalineMandantNr, novalineKundenNr, novalineCostUnitAsCostCenter]);
    const novalineMandantRegex = /^\d{1,8}$/g;
    const novalineKundeNrRegex = /^\d{1,8}$/g;
    return (<View>
      <MaterialText>{I18n.m.getMessage('novalineExportMandantNumberNeeded')}</MaterialText>
      <FormInputFilled labelText={I18n.m.getMessage('timeTrackingExportDatevMandantenNrNeeded')} onChange={setNovalineMandantNr} numberOfLines={1} value={novalineMandantNr}/>
      {!novalineMandantRegex.test(novalineMandantNr) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('novalineExportMandantNumberInvalid', { error: novalineMandantNr })}
        </MaterialText>) : null}
      <MaterialText>{I18n.m.getMessage('novalineExportKundenNummerNeeded')}</MaterialText>
      <FormInputFilled labelText={I18n.m.getMessage('timeTrackingExportNovalineKndNrHelper')} onChange={setNovalineKundenNr} numberOfLines={1} value={novalineKundenNr}/>
      {!novalineKundeNrRegex.test(novalineKundenNr) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('novalineExportKundenNummerInvalid', { error: novalineKundenNr })}
        </MaterialText>) : null}
      <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
        <MaterialText>{I18n.m.getMessage('novalineExportCostUnitAsCostCenter')}</MaterialText>
        <Switch initValue={novalineCostUnitAsCostCenter} value={novalineCostUnitAsCostCenter} onChange={setNovalineCostUnitAsCostCenter}/>
      </View>
    </View>);
}

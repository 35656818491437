var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidateEmailComp = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ValidateEmail = require("upmesh-auth-core/build/src/client/commands/guest/ValidateEmail");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _Spinner = require("../../../theme/components/Spinner");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _I18n = require("../../../theme/i18n/I18n");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ValidateEmailComp = exports.ValidateEmailComp = function (_PureComponent) {
  function ValidateEmailComp(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ValidateEmailComp);
    _this = _callSuper(this, ValidateEmailComp, [props]);
    var code = _this.props.t != null ? _this.props.t : '';
    _this.state = {
      code: code
    };
    return _this;
  }
  (0, _inherits2.default)(ValidateEmailComp, _PureComponent);
  return (0, _createClass2.default)(ValidateEmailComp, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.validate().catch(function (err) {
        return void 0;
      });
    }
  }, {
    key: "render",
    value: function render() {
      var validationResult = this.state.validationResult;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          alignContent: 'flex-start',
          width: '100%'
        },
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: _I18n.I18n.m.getMessage('emailValidateTitle')
        }), validationResult != null ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%'
          },
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            children: validationResult
          }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('ok'),
            onPress: _Routing.Routing.instance.goToButton('/account', true),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          })]
        }) : (0, _jsxRuntime.jsx)(_Spinner.Spinner, {
          small: true
        })]
      });
    }
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* () {
        var c = new _ValidateEmail.ValidateEmail({
          token: this.state.code
        });
        try {
          yield c.execute(_AuthClient.AuthClient.instance.commandStore);
          this.setState({
            validationResult: _I18n.I18n.m.getMessage('emailValidateSuccess')
          });
        } catch (e) {
          if (e.messageCode != null) {
            var validationResult = _I18n.I18n.m.getMessage(e.messageCode);
            this.setState({
              validationResult: validationResult
            });
          } else if (e.message != null) {
            this.setState({
              validationResult: e.message
            });
          } else {
            this.setState({
              validationResult: JSON.stringify(e)
            });
          }
        }
      });
      function validate() {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(_react.PureComponent);
import { AutoComplete } from 'materialTheme/src/theme/components/forminput/autocomplete/AutoComplete';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { ScrollHandler } from 'materialTheme/src/theme/components/scroll/ScrollHandler';
import { getStyleForType, MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { TextInput, View } from 'react-native';
import { ChangeTicketSubTicketFrom } from 'upmesh-core/src/client/commands/tickets/ChangeTicketSubTicketFrom';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { TicketStatus } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CurrentProject } from '../../project/CurrentProject';
import { TicketCard } from '../TicketCard';
export function SubTickets(props) {
    const [subTickets, setSubTickets] = useState([]);
    const [currentValue, setCurrentValue] = useState('');
    const [counter, setCounter] = useState(0);
    const [addNew, setAddNew] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const [percent, setPercent] = useState(0);
    const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);
    const currentTimer = useRef(null);
    const loadSubTickets = async () => {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        const s = await UpmeshClient.instance.modals.ticket.get({ filter: `subTicketFrom eq '${props.ticket.id}'` });
        const tickets = [];
        let finished = 0;
        for (const t of s) {
            if (t.ticketStatus === TicketStatus.checked)
                finished += 1;
            const ts = projectId != null && projectId === props.ticket.projectId
                ?
                    await CurrentProject.instance.getTicketData(t)
                : t;
            tickets.push(ts);
        }
        setSubTickets(tickets);
        if (tickets.length > 0) {
            setPercent(Math.round((finished / tickets.length) * 100));
        }
        else {
            setPercent(0);
        }
    };
    useEffect(() => {
        loadSubTickets().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    }, [counter]);
    useEffect(() => {
        const t = setTimeout(() => {
            if (currentValue != null && currentValue.length > 2) {
                UpmeshClient.instance.modals.ticket
                    .get({
                    filter: `projectId eq '${props.ticket.projectId}' and (contains(title, '${currentValue}') or contains(ticketNumber, '${currentValue}'))`,
                })
                    .then((e) => {
                    const c = [];
                    e.forEach((t) => {
                        c.push({
                            title: `#${t.ticketNumber ? t.ticketNumber : '?'} ${t.title}`,
                            data: t.id,
                            onPress: () => {
                                if (currentTimer.current) {
                                    clearTimeout(currentTimer.current);
                                    currentTimer.current = null;
                                    const c = new ChangeTicketSubTicketFrom({ ticketId: props.ticket.id }, t.id);
                                    c.execute()
                                        .then(() => {
                                        loadSubTickets().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                                    })
                                        .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                                    setCurrentValue('');
                                    setAutoCompleteSuggestions([]);
                                    setHasFocus(false);
                                    setCounter(counter + 1);
                                }
                            },
                        });
                    });
                    setAutoCompleteSuggestions(c);
                })
                    .catch((err) => console.debug(err));
            }
        }, 300);
        return () => {
            if (t)
                clearTimeout(t);
        };
    }, [currentValue]);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: `Ticketsubs_${props.ticket.id}`,
            readModelName: 'Ticket',
            callback: (e) => {
                if (e && e.entities.size) {
                    e.entities.forEach((en) => {
                        if (en.entity && en.entity.subTicketFrom === props.ticket.id) {
                            loadSubTickets().catch((err) => console.debug(err));
                        }
                    });
                }
            },
        });
        return () => {
            UpmeshClient.eventDispatcher.detach(`Ticket`, `Ticketsubs_${props.ticket.id}`);
        };
    }, []);
    const createSubTicket = (title) => {
        if (title != null && title.trim().length > 0) {
            const c = new CreateTicket({
                title: title.trim(),
                subTicketFrom: props.ticket.id,
                projectId: props.ticket.projectId,
            });
            c.execute()
                .then(() => {
                setCounter(counter + 1);
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        }
    };
    const scrollEvent = ScrollHandler.getScrollEvent(props.scrollContainer);
    const textInputRef = useRef(null);
    useEffect(() => {
        if (textInputRef.current) {
            ScrollHandler.scrollTo({
                containerId: 'ticketDetails',
                ref: textInputRef.current,
                animated: true,
                y: 0,
                x: 0,
            });
        }
    }, [hasFocus]);
    return (<View style={{ width: '100%', ...props.labelStyle }}>
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ flex: 1 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('subTickets')}</MaterialText>
        </View>
        <View style={{ width: 32 }}>
          <Icon disabled={!props.canEditSubTickets} toolTip="" outerSize={32} icon="plus" onPress={() => {
            setCounter(counter + 1);
            setAddNew(true);
        }}/>
        </View>
      </View>
      <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ flex: 1 }}>
          <ProgressBar progressInPercent={percent}/>
        </View>
        <View style={{ width: 48, paddingRight: 8, justifyContent: 'flex-end' }}>
          <MaterialText numberOfLines={1} fixedWidth="100%" textAlign="right" type={MaterialTextTypes.Body2}>
            {percent}%
          </MaterialText>
        </View>
      </View>
      <View style={{ width: '100%' }}>
        
        {subTickets.map((t) => {
            return (<TicketCard ticket={t} key={`tCard${t.id}`} bullseye={false} activeMultiselect={false} status project={CurrentProject.instance.getCurrentProject()}/>);
        })}
      </View>
      {(subTickets.length === 0 || addNew) && (<View style={{
                width: '100%',
                padding: 4,
            }}>
          <View style={{
                width: '100%',
                padding: 6,
                ...ThemeManager.style.borderStyle,
                borderColor: hasFocus ? ThemeManager.style.brandPrimary : ThemeManager.style.borderColor,
                borderRadius: ThemeManager.style.borderRadius,
            }}>
            <TextInput ref={textInputRef} key={`ti_${counter.toString()}`} onFocus={() => {
                setHasFocus(true);
            }} numberOfLines={1} onChangeText={(e) => {
                setCurrentValue(e);
            }} autoFocus onBlur={() => {
                currentTimer.current = setTimeout(() => {
                    if (currentValue.length === 0) {
                        setHasFocus(false);
                        setAddNew(false);
                    }
                    createSubTicket(currentValue);
                    setAutoCompleteSuggestions([]);
                    setCurrentValue('');
                }, 300);
            }} placeholder={I18n.m.getMessage('filesTicketTitle')} onSubmitEditing={() => {
                setAddNew(true);
            }} style={[
                getStyleForType(MaterialTextTypes.Body1),
                {
                    width: '100%',
                    fontSize: ThemeManager.style.getTextInputFontSize(),
                },
            ]}/>
          </View>
          {autoCompleteSuggestions && autoCompleteSuggestions.length > 0 && (<View style={{ position: 'relative' }}>
              <AutoComplete key="autoComplete" style={{
                    position: 'absolute',
                    zIndex: 0,
                    top: 0,
                    height: 270,
                    left: ThemeManager.style.getScreenRelativePixelSize(0),
                    right: 0,
                    opacity: 0,
                }} maxHeight={300} autoCompleteSuggestions={autoCompleteSuggestions} scrollEvent={scrollEvent}/>
            </View>)}
        </View>)}
    </View>);
}

import React from 'react';
import { View } from 'react-native';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { I18n } from '../../i18n/I18n';
export function TicketReadView(props) {
    const getText = (text) => {
        if (text != null && text.length > 0) {
            return text;
        }
        return '/';
    };
    return (<View>
      <MaterialText strong>{I18n.m.getMessage('ticketReadViewTitle')}</MaterialText>
      <MaterialText>{getText(props.ticket.title)}</MaterialText>
      <MaterialText strong>{I18n.m.getMessage('ticketReadViewDescription')}</MaterialText>
      <MaterialText>{getText(props.ticket.description)}</MaterialText>
      <MaterialText strong>{I18n.m.getMessage('ticketReadViewDueDate')}</MaterialText>
      <MaterialText>{props.ticket.completionOn}</MaterialText>
    </View>);
}

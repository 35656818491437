import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Styles } from 'materialTheme/src/theme/Styles';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
export class B2cStyles extends Styles {
    constructor() {
        super();
        this.activeBgColor = 'rgba(33,150,243,1)';
        this.appBgColor = '#F6F6F8';
        this.appBgColorDarken = '#EBEBEB';
        this.borderColor = '#D8D8D8';
        this.borderRadius = 6;
        this.brandDanger = '#F44336';
        this.brandDark = '#252932';
        this.brandInfo = '#03A9F4';
        this.brandLight = '#EEEEEE';
        this.brandPrimary = '#0073ff';
        this.brandSidebar = '#252932';
        this.brandSuccess = '#00cd9e';
        this.brandWarning = '#ff9800';
        this.cardBackgroundColor = '#FFFFFF';
        this.datePickerColor = '#2196F3';
        this.datePickerTextColor = '#FFFFFF';
        this.defaultButtonTextColor = '#FFF';
        this.headerBG = '#FFFFFF';
        this.headerBGDarken = '#FAFAFA';
        this.headerButtonColor = this.defaultIconColor;
        this.hintTextColor = 'rgba(0,0,0,0.38)';
        this.homeButtonTarget = ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? '/projects' : '/dashboard';
        this.lightBoxOverlayOpacity = 1;
        this.lisItemHoverColor = 'rgba(246, 246, 248, 0.5)';
        this.lisItemSelectedColor = 'rgba(33,33,33,0.5)';
        this.menuBgColor = '#FFFFFF';
        this.menuBorderColor = this.borderColor;
        this.menuButtonColorActive = this.brandPrimary;
        this.menuRightBorderColor = this.borderColor;
        this.planThumbOverlayColor = this.white87;
        this.planThumbOverlayColorActive = this.brandPrimary;
        this.planThumbOverlayTextColorActive = '#FFFFFF';
        this.primaryTextColor = '#212121';
        this.headerTitleColor = this.primaryTextColor;
        this.planThumbOverlayTextColor = this.primaryTextColor;
        this.secondaryTextColor = '#616161';
        this.defaultIconColor = this.secondaryTextColor;
        this.menuButtonColor = this.secondaryTextColor;
        this.statusbarStyle = 'dark-content';
        this._basename = '/';
    }
    get contentPaddingValue() {
        return 16;
    }
    get desktopMultiplicatorSize() {
        return 1;
    }
    get statusBarColor() {
        return 'transparent';
    }
}

import { Card } from 'materialTheme/src/theme/components/Card';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { TicketCardContent } from './TicketCardContent';
export class TicketCard extends PureComponent {
    constructor() {
        super(...arguments);
        this.onMultiSelect = () => {
            const { onMultiSelect, ticket } = this.props;
            if (onMultiSelect) {
                onMultiSelect(ticket);
            }
        };
    }
    render() {
        const { bullseye, status, activeMultiselect, selected, showProjects, project } = this.props;
        return (<View style={{
                width: '100%',
                height: 'auto',
                padding: 6,
                overflow: 'visible',
            }}>
        <Card outerPadding={0} style={{
                width: '100%',
                height: 'auto',
                overflow: 'visible',
                margin: 0,
            }}>
          <View style={{ width: '100%', height: 'auto', overflow: 'hidden', borderRadius: 6 }}>
            <TicketCardContent {...this.props} project={project} bullseye={bullseye} status={status} activeMultiselect={activeMultiselect} onMultiSelect={this.onMultiSelect} selected={selected} showProjects={showProjects}/>
          </View>
        </Card>
      </View>);
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Onboarding = Onboarding;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../../i18n/I18n");
var _ThemeManager = require("../../../ThemeManager");
var _ContainedButton = require("../../button/ContainedButton");
var _Icon = require("../../Icon");
var _MaterialText = require("../../text/MaterialText");
var _Measurement = require("../../utils/Measurement");
var _OnboardingHandler = require("./OnboardingHandler");
var _SvgOverlayWithMask = require("./SvgOverlayWithMask");
var _jsxRuntime = require("../../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function Onboarding(props) {
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    visible = _useState2[0],
    setVisible = _useState2[1];
  var _useState3 = (0, _react.useState)(1),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    currentIndex = _useState4[0],
    setCurrentIndex = _useState4[1];
  var _useState5 = (0, _react.useState)({
      size: {
        x: 36,
        y: 36
      },
      position: {
        x: 10,
        y: 6
      }
    }),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    mask = _useState6[0],
    setMask = _useState6[1];
  var elements = (0, _react.useRef)([]);
  var toggleVisibile = function toggleVisibile(e) {
    setVisible(e.show);
  };
  var showElement = function () {
    var _ref = (0, _asyncToGenerator2.default)(function* (index) {
      if (elements.current.length > index) {
        var e = elements.current[index];
        var p = yield _Measurement.Measurement.measure(e.ref);
        setMask({
          size: {
            x: p.width + 4,
            y: p.height + 4
          },
          position: {
            x: p.pageX - 2,
            y: p.pageY - 2
          }
        });
      }
    });
    return function showElement(_x) {
      return _ref.apply(this, arguments);
    };
  }();
  var startTour = function startTour() {
    var e = Array.from(_OnboardingHandler.OnboardingHandler.registeredElements.values());
    e.sort(function (a, b) {
      return a.order - b.order;
    });
    elements.current = [].concat(e);
    setCurrentIndex(0);
  };
  (0, _react.useEffect)(function () {
    showElement(currentIndex).catch(function (err) {
      return void 0;
    });
  }, [currentIndex]);
  (0, _react.useEffect)(function () {
    if (visible) {
      startTour();
    }
  }, [visible]);
  (0, _react.useEffect)(function () {
    _OnboardingHandler.OnboardingHandler.eventHandler.attach(toggleVisibile);
    return function () {
      _OnboardingHandler.OnboardingHandler.eventHandler.detach(toggleVisibile);
    };
  }, []);
  if (!visible) return null;
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle),
    children: [(0, _jsxRuntime.jsx)(_SvgOverlayWithMask.SvgOverlayWithMask, {
      size: mask.size,
      position: mask.position,
      currentStep: {
        shape: 'rectangle',
        borderRadius: _ThemeManager.ThemeManager.style.borderRadius
      }
    }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        alignSelf: 'center',
        width: props.size.windowWidth - 100,
        height: 'auto',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        top: mask.position.y + mask.size.y + 8,
        left: 50,
        borderRadius: _ThemeManager.ThemeManager.style.borderRadius
      },
      children: [elements.current && elements.current.length > currentIndex && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          padding: 8,
          width: '100%',
          height: 'auto',
          justifyContent: 'center',
          paddingBottom: 64,
          paddingTop: 16
        },
        children: [elements.current[currentIndex].element != null && elements.current[currentIndex].element, (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          fixedWidth: "100%",
          centeredBox: true,
          centeredText: true,
          textAlign: "center",
          type: _MaterialText.MaterialTextTypes.H6,
          children: elements.current[currentIndex].title
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 8
          }
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          fixedWidth: "100%",
          centeredBox: true,
          centeredText: true,
          textAlign: "center",
          children: elements.current[currentIndex].text
        })]
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          bottom: 0,
          width: '100%',
          padding: 8,
          paddingHorizontal: 16
        },
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              flexDirection: 'row'
            },
            children: elements.current.map(function (_a, index) {
              return (0, _jsxRuntime.jsx)(_Icon.Icon, {
                icon: currentIndex === index ? 'circle' : 'circle-medium',
                iconSize: 24,
                outerSize: 24,
                toolTip: "",
                onPress: function onPress() {
                  setCurrentIndex(index);
                }
              }, JSON.stringify(_a));
            })
          }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              flexDirection: 'row',
              justifyContent: 'flex-end'
            },
            children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('back'),
              disabled: currentIndex === 0,
              onPress: function onPress() {
                setCurrentIndex(currentIndex - 1);
              }
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: 8
              }
            }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('next'),
              disabled: currentIndex >= elements.current.length - 1,
              onPress: function onPress() {
                setCurrentIndex(currentIndex + 1);
              }
            })]
          })]
        })
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: 4,
          right: -8,
          width: 48,
          height: 48
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: "",
          icon: "close",
          onPress: function onPress() {
            _OnboardingHandler.OnboardingHandler.eventHandler.post({
              show: false
            });
          }
        })
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: 16,
          right: 16
        }
      })]
    })]
  });
}
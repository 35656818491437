"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Envirement = void 0;
var Envirement = (0, _createClass2.default)(function Envirement() {
  (0, _classCallCheck2.default)(this, Envirement);
});
exports.Envirement = Envirement;
Envirement.minimumClientVersion = 2023072102;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../upmesh-client/node_modules/react-native-web");
var _ErrorReporter = require("odatarepos/build/src/reporting/ErrorReporter");
var _I18n = require("../theme/i18n/I18n");
var _MaterialText = require("../theme/components/text/MaterialText");
var _jsxRuntime = require("../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var errorMessageContainerStyle = {
  flex: 1,
  alignSelf: 'center',
  justifyContent: 'center'
};
var ErrorBoundary = function (_Component) {
  function ErrorBoundary(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ErrorBoundary);
    _this = _callSuper(this, ErrorBoundary, [props]);
    _this.state = {
      hasError: false
    };
    return _this;
  }
  (0, _inherits2.default)(ErrorBoundary, _Component);
  return (0, _createClass2.default)(ErrorBoundary, [{
    key: "componentDidCatch",
    value: function componentDidCatch(error, errorInfo) {
      _ErrorReporter.ErrorReporter.sendReport({
        subject: `Uncaught error in ${this.props.componentName} error`,
        data: error,
        type: 'error'
      });
    }
  }, {
    key: "render",
    value: function render() {
      if (this.state.hasError === true) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: errorMessageContainerStyle,
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            numberOfLines: 2,
            color: "red",
            children: _I18n.I18n.m.getMessage('unexpectedError')
          })
        });
      }
      return this.props.children;
    }
  }], [{
    key: "getDerivedStateFromError",
    value: function getDerivedStateFromError(_) {
      return {
        hasError: true
      };
    }
  }]);
}(_react.Component);
var _default = exports.default = ErrorBoundary;
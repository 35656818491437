import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ChangeUserProjectSettingsFolders } from 'upmesh-core/src/client/commands/project/dashboard/ChangeUserProjectSettingsFolders';
import { CreateUserProjectSettings } from 'upmesh-core/src/client/commands/project/dashboard/CreateUserProjectSettings';
export async function saveVisitedFolder(folderId, projectId) {
    if (folderId == null)
        return;
    const entitySettings = (await UpmeshClient.instance.modals.userProjectSettings.get({
        filter: `projectId eq '${projectId}' and userId eq '${CurrentUser.userId}' and deleted ne true`,
    }))[0];
    const folderList = entitySettings && entitySettings.lastFoldersVisited ? entitySettings.lastFoldersVisited : [];
    const folderIndex = folderList.indexOf(folderId);
    if (folderIndex > -1) {
        folderList.splice(folderIndex, 1);
    }
    folderList.unshift(folderId);
    if (folderList.length > 20)
        folderList.splice(19, folderList.length - 10);
    if (entitySettings != null) {
        const changeUps = new ChangeUserProjectSettingsFolders({
            lastFoldersVisited: folderList,
        }, entitySettings.id);
        changeUps.execute().catch((err) => {
            console.error('could not store changed folders', err);
        });
    }
    else {
        const createUps = new CreateUserProjectSettings({
            projectId,
            lastFoldersVisited: folderList,
        });
        createUps.execute().catch((err) => {
            console.error('could not create new project user settings', err);
        });
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AudioManager = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _a;
var AudioManager = exports.AudioManager = (0, _createClass2.default)(function AudioManager() {
  (0, _classCallCheck2.default)(this, AudioManager);
});
_a = AudioManager;
AudioManager.stopPlayer = (0, _asyncToGenerator2.default)(function* () {
  return null;
});
AudioManager.startPlayer = function () {
  var _ref2 = (0, _asyncToGenerator2.default)(function* (_path, _headers, _callback, _errorHandler, _duration) {
    return null;
  });
  return function (_x, _x2, _x3, _x4, _x5) {
    return _ref2.apply(this, arguments);
  };
}();
AudioManager.seekTo = function (_percent) {
  return null;
};
AudioManager.pausePlayer = function () {
  var _ref3 = (0, _asyncToGenerator2.default)(function* (_errorHandler) {
    return null;
  });
  return function (_x6) {
    return _ref3.apply(this, arguments);
  };
}();
AudioManager.startRecording = function () {
  var _ref4 = (0, _asyncToGenerator2.default)(function* (_path, _callBack) {
    return '';
  });
  return function (_x7, _x8) {
    return _ref4.apply(this, arguments);
  };
}();
AudioManager.stopRecording = (0, _asyncToGenerator2.default)(function* () {
  return null;
});
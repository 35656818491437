var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResizeEvent = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _reactNativeWeb = require("../../../../upmesh-client/node_modules/react-native-web");
var _tsEvents = require("ts-events");
var ResizeEvent = exports.ResizeEvent = function () {
  function ResizeEvent() {
    (0, _classCallCheck2.default)(this, ResizeEvent);
  }
  return (0, _createClass2.default)(ResizeEvent, null, [{
    key: "post",
    value: function post(e) {
      if (JSON.stringify(e) !== JSON.stringify(ResizeEvent.current)) {
        ResizeEvent.current = e;
        ResizeEvent.onResize.post(e);
      }
    }
  }]);
}();
ResizeEvent.current = {
  windowWidth: _reactNativeWeb.Dimensions.get('window').width,
  windowHeight: _reactNativeWeb.Dimensions.get('window').height,
  windowBottom: 0,
  contentWidth: _reactNativeWeb.Dimensions.get('window').width,
  contentHeight: _reactNativeWeb.Dimensions.get('window').height,
  contentBottom: 0,
  contentLeft: 0,
  contentRight: 0,
  contentTop: 0,
  currentKeyboardHeight: 0,
  sidebarWidth: 0
};
ResizeEvent.onResize = new _tsEvents.AsyncEvent();
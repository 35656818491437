var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MonthSelection = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MonthSelection = exports.MonthSelection = function (_PureComponent) {
  function MonthSelection(props) {
    var _this;
    (0, _classCallCheck2.default)(this, MonthSelection);
    _this = _callSuper(this, MonthSelection, [props]);
    _this.changeMonth = function (difference) {
      _this.props.updateViewDate({
        newMonth: _this.props.viewDate.getMonth() + difference
      });
    };
    _this.changeSelection = function () {
      if (_this.props.activeSelection === 'daySelection') {
        _this.props.setSelection('yearSelection');
      } else {
        _this.props.setSelection('daySelection');
      }
    };
    return _this;
  }
  (0, _inherits2.default)(MonthSelection, _PureComponent);
  return (0, _createClass2.default)(MonthSelection, [{
    key: "render",
    value: function render() {
      var _this2 = this;
      var _this$props = this.props,
        getDateInRange = _this$props.getDateInRange,
        viewDate = _this$props.viewDate;
      var nextMonth = new Date(viewDate.getTime());
      nextMonth.setMonth(viewDate.getMonth() + 1);
      if (nextMonth.getMonth() > viewDate.getMonth() + 1) {
        nextMonth.setDate(0);
      }
      var prevMonth = new Date(viewDate.getTime());
      prevMonth.setMonth(viewDate.getMonth() - 1);
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          height: 24,
          flexDirection: 'row',
          alignItems: 'flex-start',
          marginBottom: 8,
          marginTop: 8,
          marginLeft: 24,
          marginRight: 24
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.Pressable, {
          onPress: this.changeSelection,
          children: (0, _jsxRuntime.jsxs)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Subtitle1,
            children: [this.props.monthNames[this.props.viewDate.getMonth()], " ", this.props.viewDate.getFullYear()]
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 4
          }
        }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: this.props.activeSelection === 'daySelection' ? 'menu-down' : 'menu-up',
          toolTip: _I18n.I18n.m.getMessage('selectYear'),
          outerSize: 24,
          onPress: this.changeSelection
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flex: 1
          }
        }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "chevron-left",
          toolTip: _I18n.I18n.m.getMessage('prevMonth'),
          outerSize: 24,
          onPress: function onPress() {
            return _this2.changeMonth(-1);
          },
          disabled: !getDateInRange({
            day: new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 0).getDate(),
            month: prevMonth.getMonth(),
            year: prevMonth.getFullYear()
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 24
          }
        }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "chevron-right",
          toolTip: _I18n.I18n.m.getMessage('nextMonth'),
          outerSize: 24,
          onPress: function onPress() {
            return _this2.changeMonth(1);
          },
          disabled: !getDateInRange({
            day: 1,
            month: nextMonth.getMonth(),
            year: nextMonth.getFullYear()
          })
        })]
      });
    }
  }]);
}(_react.PureComponent);
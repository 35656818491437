import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ReadModelHistoryChangeItem } from './ReadModelHistoryChangeItem';
export function ReadmodelHistory(props) {
    const [show, setShow] = useState(!!props.open);
    const [history, setHistory] = useState();
    const ignoresProperties = props.ignoresProperties != null && props.ignoresProperties.length > 0
        ? useRef(['lastModifiedAt', ...props.ignoresProperties])
        : useRef(['lastModifiedAt']);
    const connection = useContext(ConnectionContext);
    const loadEvents = async () => {
        const model = UpmeshClient.instance.modals[props.readmodel];
        if (model == null)
            return;
        const events = await model.getEventsById(props.id, true);
        const h = [];
        for (let i = 0; i < events.length; i += 1) {
            const e = events[i];
            const changes = [];
            let changed = false;
            if (i === 0) {
                changes.push({ property: 'createdEntity', hideLabel: true, readModel: props.readmodel.toString() });
                changed = true;
            }
            else {
                const lastData = events[i - 1].entity;
                for (const d in e.entity) {
                    if (!ignoresProperties.current.includes(d)) {
                        if ((lastData[d] == null && e.entity[d] != null) ||
                            (lastData[d] != null && e.entity[d] == null) ||
                            JSON.stringify(e.entity[d]) !== JSON.stringify(lastData[d])) {
                            changes.push({ property: d, from: lastData[d], to: e.entity[d], readModel: props.readmodel.toString() });
                            changed = true;
                        }
                    }
                }
            }
            if (changed) {
                const user = e.event.createdBy != null ? await UserOrCompany.getById(e.event.createdBy) : new UserEntity();
                h.push({ user, changes, date: new Date(e.event.createdAt) });
            }
        }
        setHistory(h);
    };
    useEffect(() => {
        if (show && connection.connectedToServer) {
            loadEvents().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        }
    }, [show, connection.connectedToServer]);
    const loadHistory = () => {
        if (!connection.connectedToServer) {
            Routing.instance.alert.post({ text: I18n.m.getMessage('commandOfflineNotPossible') });
        }
        else if (!show)
            setShow(true);
        else
            setShow(false);
    };
    const renderItem = (h, backgroundColor) => {
        const sView = props.windowWidth ? props.windowWidth <= 500 : ResizeEvent.current.windowWidth <= 500;
        if (sView) {
            return (<View key={JSON.stringify(h)} style={{
                    width: '100%',
                    paddingVertical: 8,
                    backgroundColor,
                }}>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <MaterialText type={MaterialTextTypes.Body2}>
              {I18n.m.dateCurrent.localeDateWithTimeString(new Date(h.date))} {h.user.getFullName()}
            </MaterialText>
          </View>
          <View style={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingVertical: 8,
                    backgroundColor,
                }}>
            <View style={{ height: '100%', alignItems: 'flex-start' }}>
              <UserImage size={56} user={h.user}/>
            </View>
            <View style={{ flex: 1 }}>
              {h.changes.map((c) => {
                    return (<View key={JSON.stringify(c)}>
                    <ReadModelHistoryChangeItem {...c}/>
                  </View>);
                })}
            </View>
          </View>
        </View>);
        }
        return (<View key={JSON.stringify(h)} style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingVertical: 8,
                backgroundColor,
            }}>
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <UserImage size={56} user={h.user}/>
            <View style={{ paddingLeft: 8 }}>
              <MaterialText>{h.user.getFullName()}</MaterialText>
              <MaterialText>{I18n.m.dateCurrent.localeDateWithTimeString(new Date(h.date))}</MaterialText>
            </View>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          {h.changes.map((c) => {
                return (<View key={JSON.stringify(c)}>
                <ReadModelHistoryChangeItem {...c}/>
              </View>);
            })}
        </View>
      </View>);
    };
    return (<View style={{ width: '100%' }}>
      <Ripple onPress={loadHistory}>
        <View style={{ width: '100%', paddingVertical: 4, justifyContent: 'space-between', flexDirection: 'row' }}>
          <MaterialText centeredBox strong>
            {I18n.m.getMessage('activities')}
          </MaterialText>
          <Icon icon={!show || !connection.connectedToServer ? 'chevron-up' : 'chevron-down'} toolTip=""/>
        </View>
      </Ripple>
      {show && connection.connectedToServer && (<View style={{ width: '100%' }}>
          {history == null ? (<Spinner />) : (history.map((h, i) => {
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#fafafa';
                return renderItem(h, backgroundColor);
            }))}
        </View>)}
    </View>);
}

import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
export function NotesItemOptions(props) {
    const [projectList, setProjectList] = useState([]);
    const [project, setProject] = useState(props.initValues && props.initValues.projectId ? props.initValues.projectId : '');
    useEffect(() => {
        UpmeshClient.instance.modals.project
            .get({
            filter: 'deleted ne true and archived ne true',
        })
            .then((p) => {
            const pr = [{ title: I18n.m.getMessage('allProjects'), data: '' }];
            p.forEach((project) => {
                pr.push({ title: project.title, data: project.id });
            });
            setProjectList(pr);
        })
            .catch((err) => console.debug('err', err));
    }, []);
    const save = () => {
        props.onSaved({ projectId: project && project.length > 0 ? project : undefined });
    };
    const selectedIndex = projectList.findIndex((c) => c.data === project);
    return (<>
      <DialogTitle>
        {props.type === 'edit'
            ? I18n.m.getMessage('widgetOptionDialogEditTitle')
            : I18n.m.getMessage('widgetOptionDialogAddTitle')}
      </DialogTitle>
      <DialogContent>
        {projectList != null && projectList.length > 0 && (<FormInputPicker labelText={I18n.m.getMessage('project')} list={projectList} onChange={(e) => {
                setProject(e.value.data);
            }} selectedIndex={selectedIndex}/>)}
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="#ffffff" onPress={Dialog.instance?.close}/>
        <ContainedButton title={I18n.m.getMessage('save')} onPress={save}/>
      </DialogActions>
    </>);
}

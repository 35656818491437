var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ForgotPwFormPU = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _RequestPasswordForgetToken = require("upmesh-auth-core/build/src/client/commands/guest/RequestPasswordForgetToken");
var _Alert = require("../../../theme/components/Alert");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _Form = require("../../../theme/components/forminput/Form");
var _Toast = require("../../../theme/components/Toast");
var _I18n = require("../../../theme/i18n/I18n");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ForgotPwFormPU = exports.ForgotPwFormPU = function (_PureComponent) {
  function ForgotPwFormPU(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ForgotPwFormPU);
    _this = _callSuper(this, ForgotPwFormPU, [props]);
    _this.title = _I18n.I18n.m.getMessage('forgotPwTitle');
    _this.catchError = function (e) {
      if (e['messageCode'] != null && e['messageCode'] === 'loginErrorNoAccount') {
        _Routing.Routing.instance.alert.post({
          text: _I18n.I18n.m.getMessage('forgotPwErrorNoSuchAccount'),
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('ok'),
            onPress: _this.clearEmail,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "okButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('forgotPwRegisterNow'),
            onPress: _this.gotoRegister,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "registerButton")]
        });
        return true;
      }
      return false;
    };
    _this.clearEmail = function () {
      var _Alert$instance;
      (_Alert$instance = _Alert.Alert.instance) == null || _Alert$instance.close();
    };
    _this.gotoRegister = function () {
      var _Toast$instance, _Alert$instance2;
      (_Toast$instance = _Toast.Toast.instance) == null || _Toast$instance.close();
      (_Alert$instance2 = _Alert.Alert.instance) == null || _Alert$instance2.close(function () {
        _Routing.Routing.instance.goTo(`/register?email=${_this.state.loginemail}`);
      });
    };
    _this.onSaved = function () {
      var _Toast$instance2, _Toast$instance3;
      _Routing.Routing.instance.goTo('/login');
      (_Toast$instance2 = _Toast.Toast.instance) == null || _Toast$instance2.open({
        title: _I18n.I18n.m.getMessage('forgotPwSuccess'),
        buttonTitle: _I18n.I18n.m.getMessage('ok'),
        onPressButton: (_Toast$instance3 = _Toast.Toast.instance) == null ? void 0 : _Toast$instance3.close
      });
    };
    var loginemail = '';
    _this.state = {
      loginemail: loginemail,
      isLoading: false,
      emailTextFieldActive: false,
      emailError: ''
    };
    return _this;
  }
  (0, _inherits2.default)(ForgotPwFormPU, _PureComponent);
  return (0, _createClass2.default)(ForgotPwFormPU, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.checkData();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) {
        _reactNativeWeb.Keyboard.dismiss();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var dataOptions = new Map();
      dataOptions.set('email', {
        formType: 'email',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('email'),
          autoFocus: true
        }
      });
      return (0, _jsxRuntime.jsx)(_Form.Form, {
        baseCols: 1,
        catchError: this.catchError,
        dataOptions: dataOptions,
        formHeaderProps: {
          formTitle: _I18n.I18n.m.getMessage('forgotPwTitle'),
          formText: _I18n.I18n.m.getMessage('forgotPwText')
        },
        store: _AuthClient.AuthClient.instance.commandStore,
        command: new _RequestPasswordForgetToken.RequestPasswordForgetMail({}, ''),
        additionalButtons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: _Routing.Routing.instance.goBackButton,
          title: _I18n.I18n.m.getMessage('abort'),
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "abort")],
        onSaved: this.onSaved,
        saveButtonLabel: _I18n.I18n.m.getMessage('forgotPwSubmit'),
        stickyHeader: true,
        stickyActions: true
      });
    }
  }, {
    key: "checkData",
    value: function checkData() {
      var loginemail = '';
      if (this.props.data && this.props.data.email && this.props.data.email.length > 1) {
        loginemail = this.props.data.email;
        this.setState({
          loginemail: loginemail
        });
      }
    }
  }]);
}(_react.PureComponent);
ForgotPwFormPU.defaultProps = {
  data: null
};
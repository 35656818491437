import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextEditableV2 } from 'materialTheme/src/theme/components/text/MaterialTextEditableV2';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ChangeTicketDescription, } from 'upmesh-core/src/client/commands/tickets/ChangeTicketDescription';
import { ChangeTicketTitle } from 'upmesh-core/src/client/commands/tickets/ChangeTicketTitle';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
export class TicketTitleInput extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const projectTickets = await UpmeshClient.instance.modals.ticket.get({
                filter: `projectId eq '${CurrentProject.instance.getCurrentProjectId()}' and deleted ne true`,
            });
            const titleAutoComplete = [];
            projectTickets.forEach((ticket) => {
                if (ticket.title.length > 0 &&
                    !titleAutoComplete.includes(ticket.title) &&
                    ticket.title !== I18n.m.getMessage('ticketsDetailsTitlePlaceholder')) {
                    titleAutoComplete.push(ticket.title);
                }
            });
            this.setState({ titleAutoComplete });
        };
        this.changedDescription = (text) => {
            const { errorResult } = this.props;
            const { ticket } = this.props;
            const onErrorDesc = ticket.description;
            const description = text;
            ticket.description = text;
            const c = new ChangeTicketDescription({ description }, ticket.id);
            c.execute().catch((e) => {
                ticket.description = onErrorDesc;
                this.setState({ ticket });
                errorResult(e);
            });
        };
        this.changedTitle = (text) => {
            try {
                const { ticket } = this.props;
                const { errorResult } = this.props;
                const onErrorTitle = ticket.title;
                const title = text == null || text.length === 0 ? I18n.m.getMessage('ticketsDetailsTitlePlaceholder') : text;
                ticket.title = title;
                const c = new ChangeTicketTitle({ title }, ticket.id);
                c.execute().catch((e) => {
                    ticket.title = onErrorTitle;
                    this.setState({ ticket });
                    errorResult(e);
                });
            }
            catch (err) {
                console.error('ERROR', err);
            }
        };
        this.state = { allowedToChangeTitle: false, ticket: props.ticket };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(prevState.ticket) !== JSON.stringify(nextProps.ticket)) {
            return { ticket: nextProps.ticket };
        }
        return null;
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
        this.getDisabled()
            .then((state) => {
            this.setState(state);
        })
            .catch((err) => console.debug('cant check setDisabled ', err));
    }
    render() {
        const { gap, titleError } = this.props;
        const { ticket, titleAutoComplete } = this.state;
        const { allowedToChangeTitle } = this.state;
        return (<View style={{ overflow: 'visible' }}>
        <MaterialTextEditableV2 toolTip={I18n.m.getMessage('tooltipEditTicketTitle')} key={`title_${allowedToChangeTitle}`} materailTextProps={{ type: MaterialTextTypes.H6, recognizeUrl: true }} onChanged={this.changedTitle} placeholder={I18n.m.getMessage('ticketsDetailsEditableAddTitle')} editable={allowedToChangeTitle} autoCompleteList={titleAutoComplete}>
          {ticket.title === I18n.m.getMessage('ticketsDetailsTitlePlaceholder') && allowedToChangeTitle
                ? ''
                : ticket.title}
        </MaterialTextEditableV2>
        {titleError != null && titleError.length > 0 ? (<MaterialText type={MaterialTextTypes.Caption} color={ThemeManager.style.brandDanger} recognizeUrl>
            {titleError}
          </MaterialText>) : null}
        <View style={{ height: gap }}/>
      </View>);
    }
    async checkDisabledDesc() {
        const { ticket } = this.props;
        const cDesc = new ChangeTicketDescription({}, ticket.id);
        try {
            const canIDesc = await cDesc.canI();
            return !(typeof canIDesc === 'boolean' && canIDesc);
        }
        catch (e) {
            return true;
        }
    }
    async checkDisabledTitle() {
        const { ticket } = this.props;
        const cTitle = new ChangeTicketTitle({}, ticket.id);
        try {
            const canITitle = await cTitle.canI();
            return !(typeof canITitle === 'boolean' && canITitle);
        }
        catch (e) {
            return true;
        }
    }
    async getDisabled() {
        const allowedToChangeTitle = !(await this.checkDisabledTitle());
        return { allowedToChangeTitle };
    }
}

import { Card } from 'materialTheme/src/theme/components/Card';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { ProjectImage } from '../../project/ProjectImage';
export class PlanCard extends PureComponent {
    constructor(props) {
        super(props);
        this.openPlan = (e) => {
            const { onOpen } = this.props;
            const { plan } = this.state;
            DialogBetween.instance?.close(() => {
                Routing.instance.goToButton(`/projects/${plan.projectId}/plans/${plan.id}`)(e);
                if (onOpen != null)
                    onOpen(plan, e);
            });
        };
        this.state = {
            project: null,
            plan: this.props.plan,
        };
    }
    componentDidMount() {
        this.init(this.state.plan).catch((err) => console.debug(err));
    }
    async init(plan) {
        let project = null;
        project = await UpmeshClient.instance.modals.project.getById(plan.projectId);
        this.setState({ project });
    }
    render() {
        const { plan } = this.props;
        const { project } = this.state;
        return (<View style={{ width: '100%', height: 'auto', padding: 6, overflow: 'visible' }}>
        <Card outerPadding={0} style={{ width: '100%', height: 'auto', overflow: 'visible', margin: 0 }}>
          <View pointerEvents="box-none" style={{ width: '100%', height: 'auto', overflow: 'hidden', borderRadius: 6, paddingRight: 4, margin: 0 }}>
            <Ripple onPress={this.openPlan} style={{
                width: '100%',
                padding: ThemeManager.style.getScreenRelativePixelSize(4),
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                alignItems: 'center',
                height: 'auto',
            }}>
              <View style={{ height: 'auto', maxWidth: '100%', alignSelf: 'center', flexGrow: 1 }}>
                <MaterialText numberOfLines={1} type={MaterialTextTypes.Body1} ellipsizeMode="tail">
                  {plan.title}
                </MaterialText>
              </View>
              {project == null ? null : (<View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    alignSelf: 'flex-end',
                    height: 'auto',
                    minHeight: 44,
                    flexGrow: 1,
                }}>
                  <ProjectImage project={project} size={24}/>
                </View>)}
            </Ripple>
          </View>
        </Card>
      </View>);
    }
}

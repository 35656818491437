"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventRootOdata = void 0;
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var EventRootOdata = function (_OdataEntity_1$OdataE) {
  function EventRootOdata(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, EventRootOdata);
    _this = _callSuper(this, EventRootOdata, [obj]);
    _this.entityId = obj.entityId;
    _this.eventName = obj.eventName;
    _this.aggregate = obj.aggregate;
    _this.aggregateId = obj.aggregateId;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(EventRootOdata, _OdataEntity_1$OdataE);
  return (0, _createClass2.default)(EventRootOdata, [{
    key: "createOne",
    value: function createOne(obj) {
      return new EventRootOdata(obj);
    }
  }, {
    key: "entityName",
    get: function get() {
      return 'event';
    }
  }, {
    key: "beforeGet",
    value: function () {
      var _beforeGet = (0, _asyncToGenerator2.default)(function* (options, _userId) {
        return options;
      });
      function beforeGet(_x, _x2) {
        return _beforeGet.apply(this, arguments);
      }
      return beforeGet;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        if (this.eventName == null || this.eventName.length === 0) {
          throw StdApplicationError_1.StdApplicationError.badRequest('no eventname defined');
        }
        if (this.entityId == null || this.entityId.length === 0) {
          throw StdApplicationError_1.StdApplicationError.badRequest('no entityId defined');
        }
        return true;
      });
      function validate(_x3) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = new Map();
      baseMap.set('id', new OdataEntity_1.OdataIndex(true, true));
      baseMap.set('entityId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('eventName', new OdataEntity_1.OdataIndex(true));
      baseMap.set('aggregate', new OdataEntity_1.OdataIndex(true));
      baseMap.set('aggregateId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('data', new OdataEntity_1.OdataIndex());
      baseMap.set('createdAt', new OdataEntity_1.OdataIndex(true));
      baseMap.set('createdBy', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
}(OdataEntity_1.OdataEntity);
exports.EventRootOdata = EventRootOdata;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectTagRemoved = void 0;
var ProjectEvents_1 = require("../../_root/ProjectEvents");
var ProjectTagRemoved = function (_ProjectEvents_1$Proj) {
  function ProjectTagRemoved(entityId, data) {
    var _this;
    (0, _classCallCheck2.default)(this, ProjectTagRemoved);
    _this = _callSuper(this, ProjectTagRemoved, [entityId, data, 'ProjectTagRemoved', entityId]);
    _this.eventName = 'ProjectTagRemoved';
    return _this;
  }
  (0, _inherits2.default)(ProjectTagRemoved, _ProjectEvents_1$Proj);
  return (0, _createClass2.default)(ProjectTagRemoved);
}(ProjectEvents_1.ProjectEvents);
exports.ProjectTagRemoved = ProjectTagRemoved;
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
export class HomeRedirectView extends PureComponent {
    constructor() {
        super(...arguments);
        this.mounted = false;
    }
    componentDidMount() {
        this.mounted = true;
        setTimeout(() => {
            if (this.mounted) {
                if (CurrentUser.token != null && CurrentUser.token.length > 0) {
                    if (ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM) {
                        Routing.instance.goTo('/projects', true);
                    }
                    else {
                        Routing.instance.goTo('/dashboard', true);
                    }
                }
                else {
                    Routing.instance.goTo('/login', true);
                }
            }
        }, 1000);
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        return <View />;
    }
}

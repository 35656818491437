import { TextButton } from 'materialTheme/src/theme/components/button/TextButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeCostCenter, } from 'upmesh-core/src/client/commands/companies/costcenter/ChangeCostCenter';
import { CreateCostCenter, } from 'upmesh-core/src/client/commands/companies/costcenter/CreateCostCenter';
import { I18n } from '../../i18n/I18n';
export class AddOrChangeCostCenterToCompanyDialog extends PureComponent {
    constructor() {
        super(...arguments);
        this.onSaved = (_result) => {
            Dialog.instance?.close();
        };
    }
    render() {
        const { company, costCenter } = this.props;
        if (costCenter != null) {
            const currentData = {
                mark: costCenter.mark,
                description: costCenter.description == null ? '' : costCenter.description,
                costType: costCenter.costType,
                foreignId: costCenter.foreignId,
                active: costCenter.active != null ? costCenter.active : true,
            };
            const dataOptions = new Map();
            dataOptions.set('mark', {
                formType: 'string',
                cols: 1,
                props: { labelText: I18n.m.getMessage('costCenterMark') },
            });
            dataOptions.set('description', {
                formType: 'string',
                cols: 1,
                props: { labelText: I18n.m.getMessage('costCenterDescription') },
            });
            dataOptions.set('costType', {
                formType: 'switch',
                cols: 1,
                mapValue: (value, _fromFormInput) => {
                    if (value === 'costCenter')
                        return false;
                    if (value === 'costUnit')
                        return true;
                    if (value === true)
                        return 'costUnit';
                    return 'costCenter';
                },
                props: { labelText: I18n.m.getMessage('costCenterIsCostUnit') },
            });
            dataOptions.set('active', {
                formType: 'switch',
                cols: 1,
                props: { labelText: I18n.m.getMessage('active') },
            });
            return (<Form key="AddOrChangeCostCenterToCompanyForm" formHeaderProps={{
                    formTitle: I18n.m.getMessage('companyChangeCostCenterDialogTitle'),
                }} stickyActions={false} command={new ChangeCostCenter(currentData, costCenter.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
        }
        const currentData = {
            mark: '',
            description: '',
            companyId: company.id,
            costType: 'costCenter',
            foreignId: '',
            active: true,
        };
        const dataOptions = new Map();
        dataOptions.set('mark', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('costCenterMark') },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('costCenterDescription') },
        });
        dataOptions.set('costType', {
            formType: 'switch',
            cols: 1,
            mapValue: (value, _fromFormInput) => {
                if (value === 'costCenter')
                    return false;
                if (value === 'costUnit')
                    return true;
                if (value === true)
                    return 'costUnit';
                return 'costCenter';
            },
            props: { labelText: I18n.m.getMessage('costCenterIsCostUnit') },
        });
        dataOptions.set('active', {
            formType: 'switch',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskActive') },
        });
        return (<Form key="AddOrChangeCostCenterToCompanyForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyAddCostCenterDialogTitle'),
            }} stickyActions stickyHeader command={new CreateCostCenter(currentData)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={this.onSaved} saveButtonLabel={I18n.m.getMessage('add')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
    }
}

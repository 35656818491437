"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LokiInMemoryAdapter = void 0;
var lokijs_1 = require("../../../../../../upmesh-client/node_modules/lokijs");
var LokiInMemoryAdapter = function () {
  function LokiInMemoryAdapter() {
    (0, _classCallCheck2.default)(this, LokiInMemoryAdapter);
  }
  return (0, _createClass2.default)(LokiInMemoryAdapter, null, [{
    key: "adapter",
    get: function get() {
      return new lokijs_1.LokiMemoryAdapter({
        asyncResponses: false
      });
    }
  }]);
}();
exports.LokiInMemoryAdapter = LokiInMemoryAdapter;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateProject = void 0;
var color = require("color");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var CreateProject = function (_ClientProjectCommand) {
  function CreateProject() {
    var _this;
    (0, _classCallCheck2.default)(this, CreateProject);
    _this = _callSuper(this, CreateProject, arguments);
    _this.aggregate = 'project';
    _this.commandName = 'CreateProject';
    return _this;
  }
  (0, _inherits2.default)(CreateProject, _ClientProjectCommand);
  return (0, _createClass2.default)(CreateProject, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return null;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        if (!('title' in this.data)) {
          throw {
            key: 'title',
            messageCode: 'projectErrorTitleRequired',
            message: 'Titel wird benötigt'
          };
        }
        if (key == null || key === 'title') {
          if (this.data.title.length <= 0) {
            errors.push({
              key: 'title',
              messageCode: 'projectErrorTitleRequired',
              message: 'Titel wird benötigt'
            });
          }
          if (this.data.title.length > 40) {
            errors.push({
              key: 'title',
              messageCode: 'projectErrorTitleToLong',
              message: 'Projekt titel zu lang (max 40)'
            });
          }
        }
        if (key == null || key === 'projectType') {
          if (this.data.projectType != null && this.data.projectType.length > 40) {
            errors.push({
              key: 'projectType',
              messageCode: 'projectErrorTypeToLong',
              message: 'Projektart zu lang (max 40)'
            });
          }
        }
        if (key == null || key === 'projectStart' || key === 'projectEnd') {
          if (this.data.projectStart != null && this.data.projectEnd != null && this.data.projectEnd < this.data.projectStart) {
            errors.push({
              key: 'projectStart',
              messageCode: 'projectErrorProjectStartAfterEnd',
              message: 'Projektstart sollte vor Ende sein'
            });
          }
        }
        if (key == null || key === 'constructionStart' || key === 'constructionEnd') {
          if (this.data.constructionStart != null && this.data.constructionEnd != null && this.data.constructionEnd < this.data.constructionStart) {
            errors.push({
              key: 'constructionStart',
              messageCode: 'projectErrorConstructionStartAfterEnd',
              message: 'Baustart sollte vor Ende sein'
            });
          }
        }
        if (this.data.color != null) {
          try {
            color(this.data.color);
          } catch (err) {
            errors.push({
              key: 'color',
              messageCode: 'projectErrorInvalidColor',
              message: 'Invalide Projektfarbe'
            });
          }
        }
        if (this.data.template != null && this.data.template.length > 0) {
          var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
          var templateProject = yield CommandReadModels_1.CommandReadModels.instance.project.get({
            filter: `id eq '${this.data.template}' and deleted ne true and template eq true`
          }, userId);
          if (templateProject.length === 0) {
            errors.push({
              key: 'template',
              messageCode: 'projectErrorTemplateNotFound',
              message: 'Das Vorlagenprojekt wurde nicht gefunden'
            });
          }
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CreateProject = CreateProject;
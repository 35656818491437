var _a;
import { AsyncEvent } from 'ts-events';
export class OfflineDataDownloader {
    static async init() {
        console.debug('not supported for web');
    }
    static async reset() {
        console.debug('not supported for web');
    }
    static isPlanVersionSynced(_projectId, _planversionId) {
        return undefined;
    }
    static isMediaSynced(_projectId, _mediaId) {
        return undefined;
    }
    static getProjects() {
        return [];
    }
    static getProject(_projectId) {
        return undefined;
    }
}
_a = OfflineDataDownloader;
OfflineDataDownloader.event = new AsyncEvent();
OfflineDataDownloader.progress = new AsyncEvent();
OfflineDataDownloader.initialized = false;
OfflineDataDownloader.changeProject = async (_project, _action) => {
    console.debug('not supported for web');
};

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.svgMaskPathMorph = exports.defaultSvgPath = exports.circleSvgPath = void 0;
var _flubber = require("flubber");
var _lodash = require("lodash");
var _memoizeOne = _interopRequireDefault(require("memoize-one"));
var headPath = /^M0,0H\d*\.?\d*V\d*\.?\d*H0V0Z/;
var cleanPath = (0, _memoizeOne.default)(function (path) {
  return path.replace(headPath, '').trim();
});
var getCanvasPath = (0, _memoizeOne.default)(function (path) {
  var canvasPath = path.match(headPath);
  if (canvasPath) {
    return canvasPath[0];
  }
  return '';
});
var getBorderRadiusOrDefault = function getBorderRadiusOrDefault(borderRadius) {
  var defaultRadius = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return borderRadius || borderRadius === 0 ? borderRadius : defaultRadius;
};
var defaultSvgPath = exports.defaultSvgPath = function defaultSvgPath(_ref) {
  var size = _ref.size,
    position = _ref.position,
    radius = _ref.borderRadius,
    borderRadiusObject = _ref.borderRadiusObject;
  if (radius || borderRadiusObject) {
    var borderRadiusTopRight = getBorderRadiusOrDefault(borderRadiusObject == null ? void 0 : borderRadiusObject.topRight, radius);
    var borderRadiusTopLeft = getBorderRadiusOrDefault(borderRadiusObject == null ? void 0 : borderRadiusObject.topLeft, radius);
    var borderRadiusBottomRight = getBorderRadiusOrDefault(borderRadiusObject == null ? void 0 : borderRadiusObject.bottomRight, radius);
    var borderRadiusBottomLeft = getBorderRadiusOrDefault(borderRadiusObject == null ? void 0 : borderRadiusObject.bottomLeft, radius);
    return `M${position.x},${position.y}H${position.x + size.x} a${borderRadiusTopRight},${borderRadiusTopRight} 0 0 1 ${borderRadiusTopRight},${borderRadiusTopRight}V${position.y + size.y - borderRadiusTopRight} a${borderRadiusBottomRight},${borderRadiusBottomRight} 0 0 1 -${borderRadiusBottomRight},${borderRadiusBottomRight}H${position.x} a${borderRadiusBottomLeft},${borderRadiusBottomLeft} 0 0 1 -${borderRadiusBottomLeft},-${borderRadiusBottomLeft}V${position.y + (borderRadiusBottomLeft > borderRadiusTopLeft ? borderRadiusTopLeft : borderRadiusBottomLeft)} a${borderRadiusTopLeft},${borderRadiusTopLeft} 0 0 1 ${borderRadiusTopLeft},-${borderRadiusTopLeft}Z`;
  }
  return `M${position.x},${position.y}H${position.x + size.x}V${position.y + size.y}H${position.x}V${position.y}Z`;
};
var circleSvgPath = exports.circleSvgPath = function circleSvgPath(_ref2) {
  var size = _ref2.size,
    position = _ref2.position;
  var radius = Math.round(Math.max(size.x, size.y) / 2);
  return [`M${position.x - size.x / 8},${position.y + size.y / 2}`, `a${radius} ${radius} 0 1 0 ${radius * 2} 0 ${radius} ${radius} 0 1 0-${radius * 2} 0`].join('');
};
var sizeOffset = (0, _memoizeOne.default)(function (size) {
  var maskOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return maskOffset ? {
    x: size.x + maskOffset,
    y: size.y + maskOffset
  } : size;
});
var positionOffset = (0, _memoizeOne.default)(function (position) {
  var maskOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return maskOffset ? {
    x: position.x - maskOffset / 2,
    y: position.y - maskOffset / 2
  } : position;
});
var getMaxSegmentLength = (0, _memoizeOne.default)(function (shape) {
  switch (shape) {
    case 'circle':
    case 'circle_and_keep':
      return 7;
    case 'rectangle_and_keep':
      return 25;
    default:
      return 15;
  }
});
var getSplitPathSliceOne = (0, _memoizeOne.default)(function (path) {
  var splitPath = (0, _flubber.splitPathString)(path);
  return splitPath.length > 1 ? splitPath.slice(1).join('') : path;
});
var getInterpolator = (0, _memoizeOne.default)(function (previousPath, shape, position, size) {
  var maskOffset = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var borderRadius = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
  var borderRadiusObject = arguments.length > 6 ? arguments[6] : undefined;
  var options = {
    maxSegmentLength: getMaxSegmentLength(shape)
  };
  var optionsKeep = {
    single: true
  };
  var getDefaultInterpolate = function getDefaultInterpolate() {
    return (0, _flubber.interpolate)(previousPath, defaultSvgPath({
      size: sizeOffset(size, maskOffset),
      position: positionOffset(position, maskOffset),
      borderRadius: borderRadius,
      borderRadiusObject: borderRadiusObject
    }), options);
  };
  var getCircleInterpolator = function getCircleInterpolator() {
    return (0, _flubber.toCircle)(previousPath, position.x + size.x / 2, position.y + size.y / 2, Math.max(size.x, size.y) / 2 + maskOffset, options);
  };
  switch (shape) {
    case 'circle':
      return getCircleInterpolator();
    case 'rectangle':
      return getDefaultInterpolate();
    case 'circle_and_keep':
      {
        var path = getSplitPathSliceOne(previousPath);
        return (0, _flubber.separate)(previousPath, [path, circleSvgPath({
          size: sizeOffset(size, maskOffset),
          position: position
        })], optionsKeep);
      }
    case 'rectangle_and_keep':
      {
        var _path = getSplitPathSliceOne(previousPath);
        return (0, _flubber.separate)(previousPath, [_path, defaultSvgPath({
          size: sizeOffset(size, maskOffset),
          position: positionOffset(position, maskOffset),
          borderRadius: borderRadius,
          borderRadiusObject: borderRadiusObject
        })], optionsKeep);
      }
    default:
      return getDefaultInterpolate();
  }
});
var svgMaskPathMorph = exports.svgMaskPathMorph = function svgMaskPathMorph(_ref3) {
  var previousPath = _ref3.previousPath,
    animation = _ref3.animation,
    _ref3$to = _ref3.to,
    position = _ref3$to.position,
    size = _ref3$to.size,
    shape = _ref3$to.shape,
    maskOffset = _ref3$to.maskOffset,
    borderRadius = _ref3$to.borderRadius,
    borderRadiusObject = _ref3$to.borderRadiusObject;
  var interpolator = getInterpolator(cleanPath(previousPath), shape, position, size, maskOffset, borderRadius, borderRadiusObject);
  return `${getCanvasPath(previousPath)}${interpolator((0, _lodash.clamp)(animation._value, 0, 1))}`;
};
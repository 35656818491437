import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Map } from 'materialTheme/src/theme/components/Map';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { CreateMap } from 'upmesh-core/src/client/commands/plans/CreateMap';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
export class AddMapPU extends PureComponent {
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('addMapTitle');
        this.apiUrl = '';
        this.onCustomAddressTextSelected = (text) => {
            const { address } = this.state;
            address.addressInput = text;
            this.setState({ address: new AddressEntity({ ...address }) });
        };
        this.setMapRef = (r) => {
            this.map = r;
        };
        this.blockChangeRegion = false;
        this.onChangeTitle = (mapName) => {
            this.setState({ mapName });
        };
        this.onAddressChanged = (newAddress) => {
            console.debug(' --onAddressSelected called-- 1', newAddress);
            this.setState((prevState) => {
                const updatedAddress = {
                    ...prevState.address,
                    ...newAddress,
                };
                return {
                    address: new AddressEntity(updatedAddress),
                    zoomLevel: 15,
                    inputValue: newAddress.addressInput != null ? newAddress.addressInput : '',
                };
            });
        };
        this.onSave = (_e) => {
            const { mapName, address } = this.state;
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId == null)
                return;
            this.setState({ isLoading: true }, () => {
                const addMap = new CreateMap({
                    projectId,
                    address,
                    title: mapName,
                    activatedBy: CurrentUser.userId,
                    activatedOn: new Date(),
                });
                addMap
                    .execute()
                    .then((_r) => {
                    Routing.instance.goTo(`/projects/${projectId}/plans/${addMap.entityId}`, true);
                })
                    .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            });
        };
        this.apiUrl = `${AuthClient.instance.url.replace('3011/auth/', '3012/')}/backend/googleapis`;
        this.state = {
            address: new AddressEntity({ latitude: 51.1139155, longitude: 9.9943952 }),
            inputValue: '',
            mapName: '',
            isLoading: false,
            zoomLevel: 1,
            placesKey: 0,
        };
    }
    componentWillUnmount() {
        if (Keyboard != null)
            Keyboard.dismiss();
    }
    renderOfflineMessage() {
        return (<View style={{
                ...ThemeManager.style.contentPadding,
                paddingBottom: 4,
            }}>
        <MaterialText centeredText>{I18n.m.getMessage('commandOfflineNotPossible')}</MaterialText>
        <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <View>
            <ContainedButton onPress={Routing.instance.goBackButton} title={I18n.m.getMessage('ok')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          </View>
        </View>
      </View>);
    }
    render() {
        const { address, inputValue, mapName, zoomLevel, isLoading } = this.state;
        const { size } = this.props;
        const s = size != null ? size : ResizeEvent.current;
        if (!AuthClient.instance.serverConnection.isConnected) {
            return this.renderOfflineMessage();
        }
        const height = s.windowWidth <= ThemeManager.style.breakpointM ? s.contentHeight - 128 : s.windowHeight * 0.9 - 196;
        return [
            <DialogTitle key="title">{this.title}</DialogTitle>,
            <DialogContent key="content">
        <FormInputFilled maxLength={40} labelText={I18n.m.getMessage('addMapMapTitle')} onChange={this.onChangeTitle}/>
        <View style={{ position: 'relative', top: 0, width: '100%', height: height - 48 }} pointerEvents="box-none">
          <Map showAddressInput ref={this.setMapRef} style={{ width: '100%', height: height - 48 }} address={address} allowScaling showCurrentLocation={false} zoomLevel={zoomLevel} showGoToCurrentLocationButton onChangeAddressInput={this.onAddressChanged}/>
        </View>
      </DialogContent>,
            <DialogActions onBottom key="buttons">
        <ContainedButton key="close" onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('close')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton key="save" loading={isLoading} disabled={mapName.length === 0 ||
                    inputValue.length === 0 ||
                    address.addressInput == null ||
                    address.addressInput.length === 0} onPress={this.onSave} title={I18n.m.getMessage('add')} backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>
      </DialogActions>,
        ];
    }
}
AddMapPU.defaultProps = {
    data: null,
};

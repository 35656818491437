"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteExtraPayTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ExtraPayTrackingDeleted_1 = require("../../../events/company/extrapay/ExtraPayTrackingDeleted");
var DeleteExtraPayTracking = function (_CommandRoot_1$Comman) {
  function DeleteExtraPayTracking(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteExtraPayTracking);
    _this = _callSuper(this, DeleteExtraPayTracking, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'DeleteExtraPayTracking';
    return _this;
  }
  (0, _inherits2.default)(DeleteExtraPayTracking, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteExtraPayTracking, [{
    key: "getExtraPayTracking",
    value: function () {
      var _getExtraPayTracking = (0, _asyncToGenerator2.default)(function* () {
        if (this._extraPayTracking == null) this._extraPayTracking = yield CommandReadModels_1.CommandReadModels.extraPayTracking.getById(this.entityId);
        return this._extraPayTracking;
      });
      function getExtraPayTracking() {
        return _getExtraPayTracking.apply(this, arguments);
      }
      return getExtraPayTracking;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var extraPayTracking = yield this.getExtraPayTracking();
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(extraPayTracking.companyId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1) {
          if (extraPayTracking.status === 'Locked' && companyMember[0].payroll) return true;
          if (extraPayTracking.status === 'Locked' && !companyMember[0].payroll) {
            throw {
              message: 'Keine Berechtigung',
              messageCode: 'forbiddenCommand'
            };
          }
          if (extraPayTracking.status === 'Exported') {
            throw {
              message: 'Der Eintrag ist für Veränderungen gesperrt',
              messageCode: 'timeTrackingEntryLocked'
            };
          }
          if (companyMember[0].role !== 'inactive') return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var extraPayTracking = yield this.getExtraPayTracking();
        if (extraPayTracking.deleted) return [];
        return new ExtraPayTrackingDeleted_1.ExtraPayTrackingDeleted(this.entityId, {}, extraPayTracking.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteExtraPayTracking = DeleteExtraPayTracking;
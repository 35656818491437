var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToolTipEventHandler = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _tsEvents = require("ts-events");
var ToolTipEventHandler = exports.ToolTipEventHandler = function () {
  function ToolTipEventHandler() {
    (0, _classCallCheck2.default)(this, ToolTipEventHandler);
  }
  return (0, _createClass2.default)(ToolTipEventHandler, null, [{
    key: "toolTipCloseEvent",
    get: function get() {
      if (ToolTipEventHandler._tooltipCloseEvent == null) {
        ToolTipEventHandler._tooltipCloseEvent = new _tsEvents.AsyncEvent();
      }
      return ToolTipEventHandler._tooltipCloseEvent;
    }
  }, {
    key: "toolTipOpenEvent",
    get: function get() {
      if (ToolTipEventHandler._tooltipOpenEvent == null) {
        ToolTipEventHandler._tooltipOpenEvent = new _tsEvents.AsyncEvent();
      }
      return ToolTipEventHandler._tooltipOpenEvent;
    }
  }, {
    key: "closeToolTip",
    value: function closeToolTip(toolTipId) {
      this.toolTipCloseEvent.post({
        id: toolTipId
      });
    }
  }, {
    key: "openToolTip",
    value: function openToolTip(t) {
      ToolTipEventHandler.toolTipOpenEvent.post(t);
      if (ToolTipEventHandler.to) clearTimeout(ToolTipEventHandler.to);
      ToolTipEventHandler.to = setTimeout(function () {
        ToolTipEventHandler.closeToolTip(t.id);
      }, 2500);
    }
  }]);
}();
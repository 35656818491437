import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeExtraPayTracking, } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPayTracking';
import { ChangeExtraPayTrackingStatus } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPayTrackingStatus';
import { DeleteExtraPayTracking } from 'upmesh-core/src/client/commands/companies/extrapay/DeleteExtraPayTracking';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { DeleteTimeTracking } from 'upmesh-core/src/client/commands/companies/timetracking/DeleteTimeTracking';
import { LockTimeTracking } from 'upmesh-core/src/client/commands/companies/timetracking/LockTimeTracking';
import { UnlockTimeTracking } from 'upmesh-core/src/client/commands/companies/timetracking/UnlockTimeTracking';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class TimeTrackingBulkChangeDialogContent extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const { changedTopic } = this.props;
            const projectPickerList = [];
            const costCenterPickerList = [];
            const taskList = [];
            if (changedTopic === 'project') {
                const projects = await UpmeshClient.instance.modals.project.get({ filter: `archived ne true and deleted ne true` }, CurrentUser.userId);
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({ filter: `deleted ne true and costType eq 'costUnit'` }, CurrentUser.userId);
                projects.forEach((pr) => {
                    let costCenterId;
                    costCenters.forEach((c) => {
                        c.projectIds?.forEach((p) => {
                            if (p === pr.id)
                                costCenterId = c.id;
                        });
                    });
                    if (costCenterId != null)
                        projectPickerList.push({ title: pr.title, data: { id: pr.id, costCenterId } });
                });
            }
            else if (changedTopic === 'task') {
                const tasks = await UpmeshClient.instance.modals.task.get({}, CurrentUser.userId);
                tasks.forEach((ta) => taskList.push({ title: `${ta.mark} - ${ta.description}`, data: ta.id }));
            }
            else if (changedTopic === 'costcenter') {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({ filter: `deleted ne true and active ne false` }, CurrentUser.userId);
                costCenters.forEach((pr) => {
                    if ((pr.projectIds == null && pr.costType === 'costCenter') || pr.projectIds?.length === 1) {
                        const projectId = pr.projectIds != null ? pr.projectIds[0] : '';
                        costCenterPickerList.push({ title: pr.getName(), data: { id: pr.id, projectId } });
                    }
                });
            }
            this.setState({ costCenterPickerList, projectPickerList, taskList, isLoading: false });
        };
        this.renderConfirmationQuestion = () => {
            const { selectedIDs, changedTopic } = this.props;
            if (changedTopic !== 'delete') {
                return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('timeTrackingBulkChangesConfirmationDialogHeader')}
          </MaterialText>
          <MaterialText>{I18n.m.getMessage('bulkChangesConfirmationDialog', { count: selectedIDs.size })}</MaterialText>

          <View style={{ alignSelf: 'flex-end', flexDirection: 'row', paddingTop: ThemeManager.style.contentPaddingValue }}>
            <ContainedButton title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            <ContainedButton title={I18n.m.getMessage('apply')} onPress={this.select()}/>
          </View>
        </View>);
            }
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
        <MaterialText type={MaterialTextTypes.H6}>
          {I18n.m.getMessage('timeTrackingBulkChangesConfirmationDialogHeaderDelete')}
        </MaterialText>
        <MaterialText>
          {I18n.m.getMessage('timeTrackingBulkChangesConfirmationDialogTextDelete', { count: selectedIDs.size })}
        </MaterialText>

        <View style={{ alignSelf: 'flex-end', flexDirection: 'row', paddingTop: ThemeManager.style.contentPaddingValue }}>
          <ContainedButton title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          <ContainedButton title={I18n.m.getMessage('delete')} onPress={this.select()} backgroundColor={ThemeManager.style.brandDanger}/>
        </View>
      </View>);
        };
        this.renderProgressBar = () => {
            const { selectedIDs } = this.props;
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
        <MaterialText>
          {I18n.m.getMessage('bulkChangesChangingEntries', {
                    count: this.state.changeProgress,
                    sum: selectedIDs.size,
                })}
        </MaterialText>
        <View style={{ height: ThemeManager.style.getScreenRelativePixelSize(16) }}/>
        <ProgressBar progressInPercent={(this.state.changeProgress / selectedIDs.size) * 100}/>
      </View>);
        };
        this.closeDialog = () => {
            setTimeout(() => {
                Dialog.instance?.close();
            }, 1000);
        };
        this.getSelectedTimeTrackingEntries = () => {
            const { allEntries, selectedIDs } = this.props;
            const selectedEntries = [];
            allEntries.forEach((t) => {
                if (t.type === 'TimeTrackingEntity' && selectedIDs.has(t.id))
                    selectedEntries.push(t.orgEntry);
            });
            return selectedEntries;
        };
        this.getSelectedExtraPayEntries = () => {
            const { allEntries, selectedIDs } = this.props;
            const selectedEntries = [];
            allEntries.forEach((t) => {
                if (t.type === 'ExtraPayTrackingEntity' && selectedIDs.has(t.id))
                    selectedEntries.push(t.orgEntry);
            });
            return selectedEntries;
        };
        this.renderProject = (project) => {
            const { newProjectID } = this.state;
            return (<ListItem key={`project${project.item.data.id}`} backgroundColor={newProjectID === project.item.data.id ? ThemeManager.style.chipDefaultBg : '#FFFFFF'} title={project.item.title} onPress={() => this.setState({ newProjectID: project.item.data.id, newCostCenterID: project.item.data.costCenterId })}/>);
        };
        this.renderCostCenter = (cc) => {
            const { newCostCenterID } = this.state;
            return (<ListItem key={`costCenter${cc.item.data.id}`} backgroundColor={newCostCenterID === cc.item.data.id ? ThemeManager.style.chipDefaultBg : '#FFFFFF'} title={cc.item.title} onPress={() => this.setState({ newProjectID: cc.item.data.projectId, newCostCenterID: cc.item.data.id })}/>);
        };
        this.renderTask = (task) => {
            const { newTaskID } = this.state;
            return (<ListItem key={`project${task.item.data}`} backgroundColor={newTaskID === task.item.data ? ThemeManager.style.chipDefaultBg : '#FFFFFF'} title={task.item.title} onPress={() => this.setState({ newTaskID: task.item.data })}/>);
        };
        this.select = () => (_e) => {
            const { changedTopic } = this.props;
            this.setState({ page: 'progress', changeProgress: 0 });
            if (changedTopic === 'delete') {
                this.delete().catch((err) => console.debug(err));
            }
            if (changedTopic === 'status') {
                this.changeStatus().catch((err) => console.debug(err));
            }
            else {
                this.setChanges().catch((err) => console.debug(err));
            }
        };
        this.delete = async () => {
            const { onChange, errorDialog, selectedIDs, allEntries } = this.props;
            let errorCount = 0;
            if (selectedIDs != null) {
                const sIds = Array.from(selectedIDs);
                for (let i = 0; i < sIds.length; i += 1) {
                    this.setState({ changeProgress: i + 1 });
                    const entry = allEntries.find((e) => e.id === sIds[i]);
                    if (entry != null) {
                        try {
                            if (entry.type === 'TimeTrackingEntity') {
                                const create = new DeleteTimeTracking({}, sIds[i]);
                                await create.execute(ClientStore.commandStore);
                            }
                            else {
                                const create = new DeleteExtraPayTracking({}, sIds[i]);
                                await create.execute(ClientStore.commandStore);
                            }
                        }
                        catch (e) {
                            errorCount += 1;
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.changeStatus = async () => {
            const { onChange, errorDialog, selectedIDs } = this.props;
            const { selectedStatus } = this.state;
            let errorCount = 0;
            if (selectedIDs != null && selectedStatus != null) {
                const selectedTimeEntries = this.getSelectedTimeTrackingEntries();
                for (let i = 0; i < selectedIDs.size; i += 1) {
                    this.setState({ changeProgress: i + 1 });
                    const entry = selectedTimeEntries[i];
                    try {
                        if (selectedStatus === 'Recorded' && entry.status !== 'Recorded') {
                            const create = new UnlockTimeTracking({}, entry.id);
                            await create.execute(ClientStore.commandStore);
                        }
                        else if (selectedStatus === 'Locked' && entry.status !== 'Locked') {
                            const create = new LockTimeTracking({ to: 'Locked' }, entry.id);
                            await create.execute(ClientStore.commandStore);
                        }
                    }
                    catch (e) {
                        errorCount += 1;
                    }
                }
                const selectedExtraPayEntries = this.getSelectedExtraPayEntries();
                for (let i = 0; i < selectedIDs.size; i += 1) {
                    this.setState({ changeProgress: i + 1 });
                    const entry = selectedExtraPayEntries[i];
                    try {
                        const c = new ChangeExtraPayTrackingStatus({ to: selectedStatus }, entry.id);
                        await c.execute(ClientStore.commandStore);
                    }
                    catch (e) {
                        errorCount += 1;
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.setChanges = async () => {
            const { onChange, errorDialog, changedTopic } = this.props;
            const { newProjectID, newTaskID, newCostCenterID } = this.state;
            let errorCount = 0;
            const selectedTimeTrackingEntries = this.getSelectedTimeTrackingEntries();
            if (selectedTimeTrackingEntries != null) {
                for (let i = 0; i < selectedTimeTrackingEntries.length; i += 1) {
                    this.setState({ changeProgress: i + 1 });
                    const entry = selectedTimeTrackingEntries[i];
                    const data = {
                        starts: entry.starts,
                        changeTime: new Date(),
                        ends: entry.ends,
                        pause: entry.pause,
                        note: entry.note,
                        taskId: changedTopic === 'task' ? newTaskID : entry.taskId,
                        projectId: changedTopic === 'project' || changedTopic === 'costcenter' ? newProjectID : entry.projectId,
                        costCenterId: changedTopic === 'costcenter' || changedTopic === 'project' ? newCostCenterID : entry.costCenterId,
                    };
                    try {
                        const c = new ChangeTimeTracking(data, entry.id);
                        await c.execute(ClientStore.commandStore);
                    }
                    catch (e) {
                        errorCount += 1;
                    }
                }
            }
            if (changedTopic === 'project' || changedTopic === 'costcenter') {
                const selectedExtraPayEntries = this.getSelectedExtraPayEntries();
                if (selectedExtraPayEntries != null) {
                    for (let i = 0; i < selectedExtraPayEntries.length; i += 1) {
                        this.setState({ changeProgress: i + 1 });
                        const entry = selectedExtraPayEntries[i];
                        const data = {
                            projectId: changedTopic === 'project' || changedTopic === 'costcenter' ? newProjectID : entry.projectId,
                            costCenterId: changedTopic === 'costcenter' || changedTopic === 'project' ? newCostCenterID : entry.costCenterId,
                        };
                        try {
                            const c = new ChangeExtraPayTracking(data, entry.id);
                            await c.execute(ClientStore.commandStore);
                        }
                        catch (e) {
                            errorCount += 1;
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.state = {
            changeProgress: 0,
            page: 'selection',
            projectPickerList: [],
            taskList: [],
            costCenterPickerList: [],
            isLoading: true,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant load data', err));
    }
    render() {
        const { changedTopic } = this.props;
        const { page } = this.state;
        if (page === 'selection') {
            if (changedTopic === 'task')
                return this.renderTaskDialog();
            if (changedTopic === 'project')
                return this.renderProjectDialog();
            if (changedTopic === 'costcenter')
                return this.renderCostCenterDialog();
            if (changedTopic === 'status')
                return this.renderStatusDialog();
            if (changedTopic === 'delete')
                return this.renderConfirmationQuestion();
        }
        else if (page === 'confirmation')
            return this.renderConfirmationQuestion();
        else if (page === 'progress')
            return this.renderProgressBar();
        return <View />;
    }
    renderProjectDialog() {
        const { projectPickerList, newProjectID } = this.state;
        return [
            <DialogTitle key="project_title">{I18n.m.getMessage('timeTrackingChangeProject')}</DialogTitle>,
            <DialogContent key="project__content">
        {projectPickerList?.map((p) => this.renderProject({ item: p }))}
      </DialogContent>,
            <DialogActions key="project_Actions">
        <ContainedButton title={I18n.m.getMessage('abort')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton disabled={projectPickerList == null} title={I18n.m.getMessage('apply')} onPress={newProjectID != null ? () => this.setState({ page: 'confirmation', changeProgress: 0 }) : undefined}/>
      </DialogActions>,
        ];
    }
    renderCostCenterDialog() {
        const { costCenterPickerList, newCostCenterID } = this.state;
        return [
            <DialogTitle key="cc_title">{I18n.m.getMessage('timeTrackingBulkChangesChangeCostCenter')}</DialogTitle>,
            <DialogContent key="cc_content">
        {costCenterPickerList?.map((p) => this.renderCostCenter({ item: p }))}
      </DialogContent>,
            <DialogActions key="cc_actions">
        <ContainedButton title={I18n.m.getMessage('abort')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton disabled={costCenterPickerList == null} title={I18n.m.getMessage('apply')} onPress={newCostCenterID != null ? () => this.setState({ page: 'confirmation', changeProgress: 0 }) : undefined}/>
      </DialogActions>,
        ];
    }
    renderStatusDialog() {
        const { selectedStatus } = this.state;
        return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
        <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('timeTrackingChangeStatus')}</MaterialText>
        <ListItem title={I18n.m.getMessage('timeTrackingFilterStatusRecorded')} thumbnail={{
                thumbnail: (<Icon icon="checkbox-blank-circle" toolTip="" color={selectedStatus === 'Recorded' ? ThemeManager.style.brandPrimary : ThemeManager.style.black54}/>),
                width: 24,
                rounded: true,
            }} backgroundColor={selectedStatus === 'Recorded' ? ThemeManager.style.chipDefaultBg : '#FFFFFF'} onPress={() => this.setState({ selectedStatus: 'Recorded' })}/>
        <ListItem title={I18n.m.getMessage('timeTrackingFilterStatusLocked')} thumbnail={{
                thumbnail: (<Icon icon="checkbox-blank-circle" toolTip="" color={selectedStatus === 'Locked' ? ThemeManager.style.brandPrimary : ThemeManager.style.black54}/>),
                width: 24,
                rounded: true,
            }} backgroundColor={selectedStatus === 'Locked' ? ThemeManager.style.chipDefaultBg : '#FFFFFF'} onPress={() => this.setState({ selectedStatus: 'Locked' })}/>
        <View style={{ alignSelf: 'flex-end', paddingTop: 36 }}>
          <View style={{ flexDirection: 'row' }}>
            <ContainedButton title={I18n.m.getMessage('abort')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            <ContainedButton disabled={selectedStatus == null} title={I18n.m.getMessage('apply')} onPress={selectedStatus != null ? () => this.setState({ page: 'confirmation', changeProgress: 0 }) : undefined}/>
          </View>
        </View>
      </View>);
    }
    renderTaskDialog() {
        const { taskList, newTaskID } = this.state;
        return [
            <DialogTitle key="taks_title">{I18n.m.getMessage('timeTrackingBulkChangesChangeTask')}</DialogTitle>,
            <DialogContent key="taks_content">{taskList?.map((p) => this.renderTask({ item: p }))}</DialogContent>,
            <DialogActions key="task_actions">
        <ContainedButton title={I18n.m.getMessage('abort')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton disabled={taskList == null} title={I18n.m.getMessage('apply')} onPress={newTaskID != null ? () => this.setState({ page: 'confirmation', changeProgress: 0 }) : undefined}/>
      </DialogActions>,
        ];
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("../../../upmesh-client/node_modules/react"));
var connectionContext = _react.default.createContext({
  offline: true,
  connectedToServer: false,
  type: 'unknown'
});
var _default = exports.default = connectionContext;
import { View, FlatList } from 'react-native';
import React from 'react';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { DropArea } from 'materialTheme/src/theme/components/dragndrop/DropArea';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { compareDates, getItemKey } from './calendarUtil';
import { I18n } from '../../../i18n/I18n';
export default function DayCalendar(props) {
    const { currentDate, dayHeight, filteredTickets, iosContentInset, onDrop, renderTicketCard } = props;
    const weekdayNamesShort = I18n.m.dateCurrent.weekdayNamesShort();
    const isCurrentDate = compareDates(currentDate, new Date());
    return (<View style={{ backgroundColor: ThemeManager.style.brandPrimary }}>
      <DropArea dropFilter={() => true} onDrop={onDrop(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0))} zIndex={1}>
        <View style={{ backgroundColor: ThemeManager.style.appBgColor, height: dayHeight }}>
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <View style={{
            backgroundColor: ThemeManager.style.appBgColor,
            width: '80%',
            maxWidth: 768,
        }}>
              <View style={{ height: 48 }}>
                <MaterialText centeredBox centeredText textAlign="center">
                  {weekdayNamesShort[currentDate.getDay()].slice(0, 2)}
                </MaterialText>
                <MaterialText centeredBox centeredText textAlign="center" additionalTextStyle={{
            textDecorationLine: isCurrentDate ? 'underline' : undefined,
        }}>
                  {currentDate.getDate()}
                </MaterialText>
              </View>
              <View style={{
            width: '100%',
            height: dayHeight - 48,
            maxWidth: 768,
            borderColor: '#ccc',
            borderTopWidth: 0.5,
            backgroundColor: isCurrentDate ? 'rgba(0, 115, 255, 0.05)' : '#f8f8f8',
        }}>
                <FlatList style={{ height: dayHeight - 48 }} key="ticketList" keyExtractor={getItemKey()} renderItem={renderTicketCard} numColumns={1} data={filteredTickets} contentContainerStyle={{ padding: 8, width: '100%', maxWidth: 768, alignSelf: 'center' }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>
              </View>
            </View>
          </View>
        </View>
      </DropArea>
    </View>);
}

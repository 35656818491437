import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useRef, useState } from 'react';
import { I18n } from '../../../i18n/I18n';
export function DashboardItemWidgetOptions(props) {
    const list = useRef([
        { title: I18n.m.getMessage('tickets'), data: 'tickets' },
        { title: I18n.m.getMessage('plans'), data: 'plans' },
        { title: I18n.m.getMessage('files'), data: 'files' },
        { title: I18n.m.getMessage('journal'), data: 'journals' },
        { title: I18n.m.getMessage('team'), data: 'team' },
    ]);
    const [type, setType] = useState(props.initValues && props.initValues.type ? props.initValues.type : '');
    const selectedIndex = list.current.findIndex((c) => c.data === type);
    const save = () => props.onSaved({
        type: list.current[selectedIndex >= 0 ? selectedIndex : 0].data,
    });
    return (<>
      <DialogTitle>
        {props.type === 'edit'
            ? I18n.m.getMessage('widgetOptionDialogEditTitle')
            : I18n.m.getMessage('widgetOptionDialogAddTitle')}
      </DialogTitle>
      <DialogContent>
        {list != null && list.current != null && list.current.length > 0 && (<FormInputPicker labelText={I18n.m.getMessage('type')} list={list.current} onChange={(e) => {
                setType(e.value.data);
            }} selectedIndex={selectedIndex >= 0 ? selectedIndex : 0}/>)}
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="#ffffff" onPress={Dialog.instance?.close}/>
        <ContainedButton title={I18n.m.getMessage('save')} onPress={save}/>
      </DialogActions>
    </>);
}

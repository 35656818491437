var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SecureLocalStoreB = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _SecureStoreC = require("./control/SecureStoreC");
var SecureLocalStoreB = exports.SecureLocalStoreB = function () {
  function SecureLocalStoreB() {
    (0, _classCallCheck2.default)(this, SecureLocalStoreB);
  }
  return (0, _createClass2.default)(SecureLocalStoreB, [{
    key: "setItem",
    value: function () {
      var _setItem = (0, _asyncToGenerator2.default)(function* (key, value, expireDate) {
        yield _SecureStoreC.SecureStoreC.instance.setItem(key, value, expireDate);
      });
      function setItem(_x, _x2, _x3) {
        return _setItem.apply(this, arguments);
      }
      return setItem;
    }()
  }, {
    key: "getItem",
    value: function () {
      var _getItem = (0, _asyncToGenerator2.default)(function* (key) {
        var item = yield _SecureStoreC.SecureStoreC.instance.getItem(key);
        if (item != null) {
          return item;
        }
        return undefined;
      });
      function getItem(_x4) {
        return _getItem.apply(this, arguments);
      }
      return getItem;
    }()
  }, {
    key: "removeItem",
    value: function () {
      var _removeItem = (0, _asyncToGenerator2.default)(function* (key) {
        yield _SecureStoreC.SecureStoreC.instance.removeItem(key);
      });
      function removeItem(_x5) {
        return _removeItem.apply(this, arguments);
      }
      return removeItem;
    }()
  }], [{
    key: "instance",
    get: function get() {
      if (SecureLocalStoreB._instance == null) {
        SecureLocalStoreB._instance = new SecureLocalStoreB();
      }
      return SecureLocalStoreB._instance;
    }
  }]);
}();
import { Card } from 'materialTheme/src/theme/components/Card';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { UploadTypes } from 'materialTheme/src/theme/components/upload/UploadTypes';
import { ProjectImage } from '../../project/ProjectImage';
import { OfflineDataDownloader } from '../../../repo/file/OfflineDataDownloader';
export class FileCard extends PureComponent {
    constructor(props) {
        super(props);
        this.renderThumb = (onPress) => {
            const { file } = this.props;
            const imageSize = { height: 36, width: 36 };
            if (file) {
                const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${file.id}?lm=${file.lastModifiedAt}`;
                const localFile = OfflineDataDownloader.isMediaSynced(file.projectId, file.id, file.getFileId());
                let panoramaImage = false;
                if (file.mimeType.startsWith('image') && file.metaData != null) {
                    try {
                        file.metaData = JSON.parse(file.metaData);
                    }
                    catch (e) {
                        console.debug('cant parse item.metaData');
                    }
                }
                if (file.mimeType.startsWith('image') &&
                    file.metaData != null &&
                    !Number.isNaN(file.metaData['width']) &&
                    !Number.isNaN(file.metaData['height']) &&
                    file.metaData['width'] / file.metaData['height'] === 2) {
                    panoramaImage = true;
                }
                const thumb = UploadTypes.getImageForFileName({ targetUrl, name: file.orgFilename, preview: localFile }, onPress, () => { }, {
                    id: file.id,
                    metaData: file.imageMetadata,
                }, panoramaImage, imageSize.width, imageSize.height);
                return <View style={{ paddingRight: 4 }}>{thumb}</View>;
            }
            return <Icon icon="file-outline" toolTip="" outerSize={imageSize.height} iconSize={imageSize.height}/>;
        };
        this.state = {
            project: null,
            hasFolderEditRights: false,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        const { file } = this.props;
        let project = null;
        project = await UpmeshClient.instance.modals.project.getById(file.projectId);
        const user = await AuthClient.instance.modals.user.getById(file.userId);
        const fileWithUser = {
            uploadedByUser: user,
            uploadedByUsername: user.getFullName(),
            ...file,
        };
        this.setState({ project, file: fileWithUser });
    }
    render() {
        const { project, file } = this.state;
        let onPress = (_e) => { };
        if (file != null && file.fileId != null) {
            onPress = () => {
                DialogBetween.instance?.close(() => Routing.instance.goTo(`/projects/${file.projectId}/files?q=${file.orgFilename}`));
            };
        }
        return (<View style={{ width: '100%', height: 'auto', padding: 6, overflow: 'visible', minHeight: 44 }}>
        <Card outerPadding={0} style={{ width: '100%', height: 'auto', overflow: 'visible', margin: 0 }}>
          <Ripple onPress={onPress} style={{
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                borderRadius: 6,
                paddingRight: 4,
                margin: 0,
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}>
            <View style={{
                maxWidth: '100%',
                padding: ThemeManager.style.getScreenRelativePixelSize(4),
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 4,
                flex: 1,
            }}>
              {this.renderThumb(onPress)}
              <MaterialText centeredBox>{file != null ? file.orgFilename : this.props.file.orgFilename}</MaterialText>
            </View>
            {project != null ? (<View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignContent: 'flex-end',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    alignSelf: 'flex-end',
                    height: 'auto',
                    paddingBottom: 10,
                }}>
                <ProjectImage project={project} size={24}/>
              </View>) : null}
          </Ripple>
        </Card>
      </View>);
    }
}

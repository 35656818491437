import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ResponsiveColsList } from 'materialTheme/src/theme/components/ResponsiveColsList';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { UploadTypes } from 'materialTheme/src/theme/components/upload/UploadTypes';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
export function ImageItem(props) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [images, setImages] = useState();
    const loadImagesFromFolder = async () => {
        const files = await UpmeshClient.instance.modals.storedFile.get({
            filter: `projectId eq '${props.projectId}' and folder eq '${props.folderId}' and fileType eq 'image' and deleted ne true`,
            orderby: 'orgFilename ASC, createdAt ASC',
        });
        const m = new Map();
        files.forEach((f) => {
            if ((f.orgFilename.includes('jpg') || f.orgFilename.includes('jpeg') || f.orgFilename.includes('png')) &&
                !m.has(f.orgFilename))
                m.set(f.orgFilename, f);
        });
        setImages(Array.from(m.values()));
        setCurrentIndex(0);
    };
    useEffect(() => {
        loadImagesFromFolder().catch((err) => console.warn(err));
    }, []);
    const renderImage = (f) => {
        const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${f.getFileId()}?lm=${f.lastModifiedAt}`;
        return (<View style={{ width: imageSize.width, height: imageSize.height, position: 'relative' }} key={`image_container_${f.id}`}>
        <View style={{ width: imageSize.width, height: imageSize.height, position: 'absolute' }} key={`image_${f.id}_${f.getFileId()}`}>
          {UploadTypes.getImageForFileName({ targetUrl, name: f.orgFilename }, undefined, undefined, {
                id: f.id,
                metaData: f.imageMetadata,
            }, false, imageSize.width, imageSize.height)}
        </View>
        <View style={{
                width: imageSize.width,
                height: 24,
                position: 'absolute',
                backgroundColor: 'rgba(255,255,255,0.75)',
            }} key={`image_${f.id}`}>
          <MaterialText fixedWidth="100%" textAlign="center">
            {f.orgFilename}
          </MaterialText>
        </View>
      </View>);
    };
    const renderContent = () => {
        if (images == null || images.length === 0) {
            return (<View>
          <MaterialText>{I18n.m.getMessage('noImages')}</MaterialText>
        </View>);
        }
        const contents = [];
        images.forEach((i) => {
            contents.push(renderImage(i));
        });
        return (<View style={{ width: imageSize.width, height: imageSize.height, position: 'relative' }}>
        <View style={{ width: imageSize.width, height: imageSize.height, position: 'absolute' }}>
          <ResponsiveColsList changedSelection={(i) => {
                if (currentIndex !== i)
                    setCurrentIndex(i);
            }} showButtons shortSwipe snapInSections targetWidthPerSection={imageSize.width} height={imageSize.height} width={imageSize.width} currentIndex={currentIndex} contents={contents}/>
        </View>
        {images.length > 1 && (<View style={{ width: 36, height: 36, position: 'absolute', left: 4, bottom: 4 }}>
            <Icon icon="chevron-left" toolTip="" onPress={() => {
                    const newIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1;
                    setCurrentIndex(newIndex);
                }}/>
          </View>)}
        {images.length > 1 && (<View style={{ width: 36, height: 36, position: 'absolute', right: 4, bottom: 4 }}>
            <Icon icon="chevron-right" toolTip="" onPress={() => {
                    const newIndex = currentIndex + 1 < images.length ? currentIndex + 1 : 0;
                    setCurrentIndex(newIndex);
                }}/>
          </View>)}
      </View>);
    };
    return (<View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }} onLayout={(e) => setImageSize({ ...e.nativeEvent.layout })}>
      {images == null || imageSize.height === 0 ? <Spinner /> : renderContent()}
    </View>);
}

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadModelEntity = void 0;
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var ReadModelEntity = function (_OdataEntity_1$OdataE) {
  function ReadModelEntity() {
    (0, _classCallCheck2.default)(this, ReadModelEntity);
    return _callSuper(this, ReadModelEntity, arguments);
  }
  (0, _inherits2.default)(ReadModelEntity, _OdataEntity_1$OdataE);
  return (0, _createClass2.default)(ReadModelEntity, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        return true;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "createBaseMap",
    value: function createBaseMap() {
      var map = new Map();
      map.set('id', new OdataEntity_1.OdataIndex(true, true));
      map.set('createdBy', new OdataEntity_1.OdataIndex(true));
      map.set('createdAt', new OdataEntity_1.OdataIndex(true));
      map.set('lastModifiedAt', new OdataEntity_1.OdataIndex(true));
      map.set('lastModifiedBy', new OdataEntity_1.OdataIndex(true));
      map.set('entityName', new OdataEntity_1.OdataIndex(true));
      map.set('_access', new OdataEntity_1.OdataIndex(true));
      return map;
    }
  }]);
}(OdataEntity_1.OdataEntity);
exports.ReadModelEntity = ReadModelEntity;
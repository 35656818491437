import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from './i18n/I18n';
export class WhatsNew extends PureComponent {
    constructor() {
        super(...arguments);
        this.renderListItem = (text) => (<View style={{ flexDirection: 'row', width: '100%', paddingTop: 4 }}>
      <View style={{ width: 24 }}>
        <MaterialText strong>•</MaterialText>
      </View>
      <View style={{ flex: 1, flexGrow: 1 }}>
        <MaterialText>{text}</MaterialText>
      </View>
    </View>);
    }
    renderFeatures() {
        const { releaseNotes } = this.props;
        if (releaseNotes == null)
            return null;
        if (releaseNotes.features == null || releaseNotes.features.length === 0)
            return null;
        return (<View style={{ paddingTop: 8, width: '100%' }}>
        <MaterialText strong>{I18n.m.getMessage('whatsNewFeatures')}</MaterialText>
        {releaseNotes.features.map(this.renderListItem)}
      </View>);
    }
    renderBugs() {
        const { releaseNotes } = this.props;
        if (releaseNotes == null)
            return null;
        if (releaseNotes.bugs == null || releaseNotes.bugs.length === 0)
            return null;
        return (<View style={{ paddingTop: 8, width: '100%' }}>
        <MaterialText strong>{I18n.m.getMessage('whatsNewBugs')}</MaterialText>
        {releaseNotes.bugs.map(this.renderListItem)}
      </View>);
    }
    render() {
        const { versionName } = this.props;
        return (<View style={{
                width: '100%',
            }}>
        <DialogTitle>{I18n.m.getMessage('whatsNewTitle', { version: versionName })}</DialogTitle>
        <DialogContent>
          {this.renderFeatures()}
          {this.renderBugs()}
        </DialogContent>
      </View>);
    }
}

import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../CurrentProject';
import { DashBoardItem } from './DashBoardItem';
export class JournalItem extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.loadDashData = async () => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null && this.mounted) {
                const journals = await UpmeshClient.instance.modals.journal.count(`projectId eq '${projectId}'`);
                this.setState({ projectId, counts: journals });
            }
        };
        this.state = {
            counts: 0,
            projectId: props.projectId,
            canRead: true,
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.loadDashData().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        const { counts, projectId, canRead } = this.state;
        if (!canRead) {
            return null;
        }
        return (<Ripple style={{ width: '100%', paddingBottom: 16 }} onPress={Routing.instance.goToButton(`/projects/${projectId}/dailyreports`)}>
        <View style={{ width: '100%' }}>
          <DashBoardItem title={I18n.m.getMessage('menuProjectDailyReports')} icon="calendar" iconColor={ThemeManager.style.brandWarning} counts={counts}/>
        </View>
      </Ripple>);
    }
}

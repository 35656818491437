var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MicrosoftButton = MicrosoftButton;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var msal = _interopRequireWildcard(require("@azure/msal-browser"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _Ripple = require("../../../theme/components/utils/Ripple");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var microsoftImage = require("../../../assets/microsoftIcon.jpg");
function MicrosoftButton(props) {
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    init = _useState2[0],
    setInit = _useState2[1];
  var myMSALObj = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    function inititialize() {
      return _inititialize.apply(this, arguments);
    }
    function _inititialize() {
      _inititialize = (0, _asyncToGenerator2.default)(function* () {
        var msalConfig = {
          auth: {
            clientId: '1b7d0af4-b4b6-4591-9a24-c6904dbaa179',
            redirectUri: window.location.href
          }
        };
        myMSALObj.current = new msal.PublicClientApplication(msalConfig);
        setInit(true);
      });
      return _inititialize.apply(this, arguments);
    }
    inititialize().catch(function (err) {
      return void 0;
    });
  }, []);
  var signIn = function () {
    var _ref = (0, _asyncToGenerator2.default)(function* () {
      if (myMSALObj.current == null) return;
      var loginRequest = {
        scopes: ['user.read']
      };
      try {
        var data = yield myMSALObj.current.loginPopup(loginRequest);
        var idToken = data.uniqueId;
        var accessToken = data.accessToken;
        props.onAccess({
          accessToken: accessToken,
          idToken: idToken
        });
      } catch (err) {
        var errorMessage = err.message ? err.message : err.toString();
        _Routing.Routing.instance.alert.post({
          text: errorMessage
        });
      }
    });
    return function signIn() {
      return _ref.apply(this, arguments);
    };
  }();
  var onPress = function onPress(_e) {
    signIn().catch(function (err) {
      return void 0;
    });
  };
  var width = props.width,
    title = props.title,
    disabled = props.disabled;
  if (!init) return null;
  return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
    disabled: disabled,
    onPress: onPress,
    backgroundColor: "#FFFFFF",
    hoverColor: "#EFEFEF",
    style: Object.assign({
      width: width != null ? width : '100%',
      height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48)
    }, _ThemeManager.ThemeManager.style.borderStyle, {
      position: 'relative',
      justifyContent: 'center',
      borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
      padding: 0
    }),
    children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: width != null ? width : '100%',
        height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48),
        justifyContent: 'center',
        borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
        paddingLeft: width != null ? _ThemeManager.ThemeManager.style.contentPaddingValue + _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) : _ThemeManager.ThemeManager.style.contentPaddingValue,
        paddingRight: _ThemeManager.ThemeManager.style.contentPaddingValue
      },
      children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        type: _MaterialText.MaterialTextTypes.Button,
        centeredText: true,
        centeredBox: true,
        children: title.toLocaleUpperCase()
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
        source: microsoftImage,
        style: {
          width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
          height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
          position: 'absolute',
          left: _ThemeManager.ThemeManager.style.contentPaddingValue
        },
        resizeMode: "contain"
      })]
    })
  });
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeCompanyList = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CompanyListChanged_1 = require("../../../../server/events/companies/lists/CompanyListChanged");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ChangeCompanyList = function (_CommandRoot_1$Comman) {
  function ChangeCompanyList(data, companyId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeCompanyList);
    _this = _callSuper(this, ChangeCompanyList, [data, companyId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeCompanyList';
    return _this;
  }
  (0, _inherits2.default)(ChangeCompanyList, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeCompanyList, [{
    key: "getCompanySettings",
    value: function () {
      var _getCompanySettings = (0, _asyncToGenerator2.default)(function* () {
        if (this._cs == null) this._cs = yield CommandReadModels_1.CommandReadModels.companySettings.getById(this.entityId);
        return this._cs;
      });
      function getCompanySettings() {
        return _getCompanySettings.apply(this, arguments);
      }
      return getCompanySettings;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.entityId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var companySettings = yield this.getCompanySettings();
        if (companySettings.lists == null || companySettings.lists[this.data.listKey] == null) {
          throw StdApplicationError_1.StdApplicationError.notFound('list not found');
        }
        var list = companySettings.lists[this.data.listKey];
        var hasChanged = JSON.stringify(list.list) !== JSON.stringify(this.data.list) || this.data.name !== list.name;
        if (!hasChanged) return [];
        return new CompanyListChanged_1.CompanyListChanged(this.entityId, {
          name: this.data.name,
          list: this.data.list,
          listKey: this.data.listKey
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        var companySettings = yield this.getCompanySettings();
        if (companySettings.lists == null || companySettings.lists[this.data.listKey] == null) {
          throw StdApplicationError_1.StdApplicationError.notFound('list not found');
        }
        if (key == null || key === 'name') {
          if (this.data.name == null || this.data.name.length === 0) {
            errors.push({
              key: 'name',
              messageCode: 'required',
              message: 'Name wird benötigt'
            });
          }
        }
        if (key == null || key === 'list') {
          if (this.data.list.length <= 0) {
            errors.push({
              key: 'list',
              messageCode: 'required',
              message: 'Liste wird benötigt'
            });
          }
          if (companySettings.lists != null) {
            var foundWithName = false;
            for (var l in companySettings.lists) {
              if (companySettings.lists[l].name === this.data.name && this.data.listKey !== l) {
                foundWithName = true;
                break;
              }
            }
            if (foundWithName) {
              errors.push({
                message: 'Eine Liste mit dem Namen "{{name}}" existiert bereits',
                messageCode: 'companyListAlreadyExists',
                messageData: {
                  name: this.data.name
                }
              });
            }
          }
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeCompanyList = ChangeCompanyList;
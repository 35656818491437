"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeTicketApprover = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var TicketApproverChanged_1 = require("../../events/tickets/TicketApproverChanged");
var TicketApproverRemoved_1 = require("../../events/tickets/TicketApproverRemoved");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeTicketApprover = function (_ClientProjectCommand) {
  function ChangeTicketApprover(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeTicketApprover);
    _this = _callSuper(this, ChangeTicketApprover, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeTicketApprover';
    return _this;
  }
  (0, _inherits2.default)(ChangeTicketApprover, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeTicketApprover, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var ticket = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        yield _superPropGet(ChangeTicketApprover, "checkProjectStatusAndGeneralAccess", this, 3)([ticket.projectId, userId]);
        if (ticket.creator === userId) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'canChangeAllTickets')) {
          return true;
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(ticket.projectId, userId, 'commandChangeTicketApprover')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var rm = yield CommandReadModels_1.CommandReadModels.ticket.getById(this.entityId);
        if (rm.approverUserId === this.data.approverUserId) return [];
        if (this.data.approverUserId == null || this.data.approverUserId === '0' || this.data.approverUserId === '') {
          if (rm.approverUserId != null && rm.approverUserId.length > 1) {
            return new TicketApproverRemoved_1.TicketApproverRemoved(this.entityId, {
              userId: rm.approverUserId
            }, rm.projectId);
          }
          return [];
        }
        var user = yield CommandReadModels_1.CommandReadModels.projectUser.count(`projectId eq '${rm.projectId}' and userId eq '${this.data.approverUserId}' and status eq 'accepted'`);
        if (user === 0) {
          var project = yield CommandReadModels_1.CommandReadModels.project.getById(rm.projectId);
          if (project.creator !== this.data.approverUserId) {
            throw {
              message: 'Der Benutzer wurde nicht gefunden',
              messageCode: 'userNotFound'
            };
          }
        }
        var changed = new TicketApproverChanged_1.TicketApproverChanged(this.entityId, Object.assign({}, this.data), rm.projectId);
        if (rm.approverUserId != null && rm.approverUserId.length > 1) {
          return [new TicketApproverRemoved_1.TicketApproverRemoved(this.entityId, {
            userId: rm.approverUserId
          }, rm.projectId), changed];
        }
        return changed;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeTicketApprover = ChangeTicketApprover;
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export function ReadModelHistoryChangeItem(props) {
    const [data, setData] = useState([]);
    const getStringByProperty = async (prop, value, extraCondition) => {
        let s;
        if (typeof value === 'string' && value.length > 0)
            s = value;
        if (value != null) {
            if (prop === 'starts') {
                if (extraCondition === 'date') {
                    const d = new Date(value);
                    s = I18n.m.dateCurrent.localeDateString(d);
                }
                else {
                    const d = new Date(value);
                    s =
                        props.readModel.toLowerCase() === 'timetracking'
                            ? I18n.m.dateCurrent.getLocalTimeString(d)
                            : I18n.m.dateCurrent.localeDateWithTimeString(d);
                }
            }
            else if (prop === 'ends') {
                const d = new Date(value);
                s =
                    props.readModel.toLowerCase() === 'timetracking'
                        ? I18n.m.dateCurrent.getLocalTimeString(d)
                        : I18n.m.dateCurrent.localeDateWithTimeString(d);
            }
            else if (prop === 'pause' && value.hours != null) {
                s = `${value.hours.toString(10).padStart(2, '0')}:${value.minutes.toString(10).padStart(2, '0')}`;
            }
            else if (prop === 'status' && props.readModel.toLowerCase() === 'timetracking') {
                s = I18n.m.getMessage(`timeTrackingFilterStatus${value}`);
            }
            else if (prop === 'state' && props.readModel.toLowerCase() === 'absence') {
                s = I18n.m.getMessage(`absenceState${value.charAt(0).toUpperCase()}${value.slice(1)}`);
            }
            else if (prop === 'createdAt') {
                const d = new Date(value);
                s = I18n.m.dateCurrent.localeDateWithTimeString(d);
            }
            else if (prop === 'taskId') {
                try {
                    if (value.length > 0) {
                        const c = await UpmeshClient.instance.modals.task.getById(value);
                        s = c.getName();
                    }
                }
                catch (err) {
                    console.debug('cant get costCenter', err);
                }
            }
            else if (props.readModel.toLowerCase() === 'extrapaytracking' && prop.toLowerCase() === 'extrafield') {
                s =
                    typeof value === 'number'
                        ? value.toString(10)
                        : value.from != null
                            ? `${value.from.hours.toString(10).padStart(2, '0')}:${value.from.minutes
                                .toString(10)
                                .padStart(2, '0')} - ${value.to.hours.toString(10).padStart(2, '0')}:${value.to.minutes
                                .toString(10)
                                .padStart(2, '0')}`
                            : '';
            }
            else if (prop === 'extraPayId') {
                try {
                    if (value.length > 0) {
                        const c = await UpmeshClient.instance.modals.extraPay.getById(value);
                        s = c.getName();
                    }
                }
                catch (err) {
                    console.debug('cant get costCenter', err);
                }
            }
            else if (prop === 'costCenterId') {
                try {
                    if (value.length > 0) {
                        const c = await UpmeshClient.instance.modals.costCenter.getById(value);
                        s = c.getName();
                    }
                }
                catch (err) {
                    console.debug('cant get costCenter', err);
                }
            }
            else if (prop === 'projectId') {
                try {
                    if (value.length > 0) {
                        const c = await UpmeshClient.instance.modals.project.getById(value);
                        s = c.title;
                    }
                }
                catch (err) {
                    console.debug('cant get Project', err);
                }
            }
            else {
                try {
                    const st = JSON.stringify(value);
                    if (st != null && st.length > 0 && st !== '""')
                        s = st;
                }
                catch (err) {
                    console.debug('cant JSON.stringify', err);
                }
            }
        }
        return s;
    };
    const loadData = async () => {
        const d = [];
        if (props.property === 'createdEntity') {
            d.push({ to: I18n.m.getMessage('created') });
        }
        else if (props.readModel.toLowerCase() === 'timetracking' && props.property === 'starts') {
            const f = await getStringByProperty(props.property, props.from, 'date');
            const t = await getStringByProperty(props.property, props.to, 'date');
            if (f?.toLowerCase() !== t?.toLowerCase())
                d.push({ from: f, to: t, messageCode: `${props.property}Date` });
        }
        const f = await getStringByProperty(props.property, props.from);
        const t = await getStringByProperty(props.property, props.to);
        if (f?.toLowerCase() !== t?.toLowerCase())
            d.push({ from: f, to: t, messageCode: `${props.property}` });
        return d;
    };
    useEffect(() => {
        let mounted = true;
        loadData()
            .then((e) => {
            if (mounted) {
                if (e.length > 0)
                    setData(e);
            }
            return () => {
                mounted = false;
            };
        })
            .catch((err) => console.debug(err));
    }, []);
    if (data.length === 0)
        return null;
    return (<View style={{ flex: 1 }}>
      {data.map((d) => {
            const { from, to, messageCode } = d;
            return (<View key={JSON.stringify(d)} style={{ alignItems: 'flex-end', width: '100%', marginVertical: 2 }}>
            {!props.hideLabel && messageCode != null && (<View style={{ maxWidth: '100%' }}>
                <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage(messageCode)}</MaterialText>
              </View>)}
            <View style={{ flexDirection: 'row', maxWidth: '100%' }}>
              {from != null && <MaterialText centeredBox>{from}</MaterialText>}
              {from != null && to != null && <Icon outerSize={24} iconSize={24} icon="chevron-right" toolTip=""/>}
              {to != null && <MaterialText centeredBox>{to}</MaterialText>}
            </View>
          </View>);
        })}
    </View>);
}

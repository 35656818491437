import { Url } from 'cqrs-shared/src/uri/Url';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { UploadButton } from 'materialTheme/src/theme/components/upload/UploadButton';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
export class FilesUploadPU extends PureComponent {
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('filesUploadTitle');
        this.init = async () => {
            const { data } = this.props;
            if (data == null || data.target == null)
                return Routing.instance.closeDialog(true)(null);
            await WaitFor.instance.waitFor(() => AuthClient.instance.ready);
            if (AuthClient.instance.ready) {
                await WaitFor.instance.waitFor(() => CurrentUser.userId != null);
                await WaitFor.instance.waitFor(() => CurrentProject.instance.getCurrentProjectId() != null);
                const projectId = CurrentProject.instance.getCurrentProjectId();
                const folder = data.target === 'private' ? null : data.target;
                if (projectId != null) {
                    return this.setState({
                        isLoading: false,
                        folder,
                    });
                }
            }
            return Routing.instance.closeDialog(true)(null);
        };
        this.onLayout = (e) => {
            let width = e.nativeEvent.layout.width > 0 ? e.nativeEvent.layout.width : 0.9 * ResizeEvent.current.windowWidth;
            width = Math.min(width, 300);
            this.setState({ maxWidth: width });
        };
        this.state = {
            isLoading: true,
            maxWidth: 300,
            folder: 'public',
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    renderSpinner() {
        return (<View onLayout={this.onLayout} style={{
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                minHeight: 242,
            }}>
        <Spinner />
      </View>);
    }
    renderOfflineMessage() {
        return (<View onLayout={this.onLayout} style={{
                ...ThemeManager.style.contentPadding,
                paddingBottom: 4,
            }}>
        <MaterialText centeredText>{I18n.m.getMessage('commandOfflineNotPossible')}</MaterialText>
        <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <View>
            <ContainedButton onPress={Routing.instance.goBackButton} title={I18n.m.getMessage('ok')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          </View>
        </View>
      </View>);
    }
    renderButtons() {
        return [
            <ContainedButton key="close" onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('close')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent"/>,
        ];
    }
    render() {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        const { folder, isLoading } = this.state;
        if (isLoading || projectId == null) {
            return this.renderSpinner();
        }
        if (!AuthClient.instance.serverConnection.isConnected) {
            return this.renderOfflineMessage();
        }
        const height = ResizeEvent.current.windowHeight <= ThemeManager.style.breakpointM
            ? ResizeEvent.current.contentHeight - 128
            : ResizeEvent.current.windowHeight * 0.9 - 128;
        return [
            <DialogTitle key="title">{this.title}</DialogTitle>,
            <View key="content" collapsable={false} style={{
                    height,
                    paddingTop: 0,
                    padding: ThemeManager.style.contentPaddingValue,
                    overflow: 'hidden',
                    flex: 1,
                }}>
        <UploadButton endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`)} uploadText={I18n.m.getMessage('filesUploadTextButton')} uploadIcon="cloud-upload-outline" multiple id={`${projectId}_documents_upload`} filter={['allFiles']} data={{ projectId, folder }}/>
      </View>,
            <DialogActions onBottom key="buttons">
        {this.renderButtons()}
      </DialogActions>,
        ];
    }
}
FilesUploadPU.defaultProps = {
    data: null,
};

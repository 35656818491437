"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommandOfflineRegister = void 0;
var CommandOfflineRegister = function () {
  function CommandOfflineRegister() {
    (0, _classCallCheck2.default)(this, CommandOfflineRegister);
  }
  return (0, _createClass2.default)(CommandOfflineRegister, null, [{
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* () {});
      function init() {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "registerCommands",
    value: function registerCommands(command) {
      if (!CommandOfflineRegister.isRegistered(command)) {
        CommandOfflineRegister.registeredCommands.set(`${command.aggregate}_${command.commandName}`, command);
      }
    }
  }, {
    key: "isRegistered",
    value: function isRegistered(command) {
      return CommandOfflineRegister.registeredCommands.has(`${command.aggregate}_${command.commandName}`);
    }
  }, {
    key: "getRegisteredCommand",
    value: function getRegisteredCommand(name) {
      return CommandOfflineRegister.registeredCommands.get(name);
    }
  }]);
}();
exports.CommandOfflineRegister = CommandOfflineRegister;
CommandOfflineRegister.registeredCommands = new Map();
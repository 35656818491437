import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { DeleteAbsence } from 'upmesh-core/src/client/commands/companies/absence/DeleteAbsence';
import { AbsenceEntity } from 'upmesh-core/src/client/query/entities/AbsenceEntity';
import { AbsenceExtendedEntity, AbsenceFilter } from 'upmesh-core/src/client/query/filter/AbsenceFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { GlobalBar } from '../root/GlobalBar';
import { PageView } from '../root/PageView';
import { openAbsenceCreationChangeDialog } from './AbsenceCreationChangeDialog';
import { openAbsenceFilterDialog } from './AbsenceFilterDialog';
import { AbsenceStatusChip } from './AbsenceStatusChip';
const noTimeTrackings = require('../../assets/img/noTimeTrackings.png');
const renderStatusCell = (item, column, index, sLayout) => {
    const chip = <AbsenceStatusChip absence={new AbsenceEntity(item)}/>;
    return (<View key={`cell-${item.id}-${index}`} style={[
            {
                height: '100%',
                minHeight: sLayout ? undefined : 54,
                maxWidth: '100%',
                minWidth: 196,
                alignSelf: sLayout ? 'flex-end' : 'flex-start',
                justifyContent: sLayout ? 'flex-end' : 'center',
                paddingHorizontal: 16,
            },
            column.style,
        ]}>
      {column.keyInData ? chip : null}
    </View>);
};
let _absenceTableColumn;
const absenceTableColumn = () => {
    if (_absenceTableColumn != null)
        return _absenceTableColumn;
    _absenceTableColumn = [
        {
            title: I18n.m.getMessage('absenceState'),
            keyInData: 'state',
            dataType: 'string',
            style: { width: 196, minWidth: 196 },
            minWidth: 196,
            cellRenderer: renderStatusCell,
        },
        {
            title: I18n.m.getMessage('employee'),
            keyInData: 'memberName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('absenceTask'),
            keyInData: 'taskName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: {
                icon: 'clock-start',
                toolTip: I18n.m.getMessage('absenceStarts'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'starts',
            dataType: 'DateWithTime',
            style: { width: 180 },
        },
        {
            title: {
                icon: 'clock-end',
                toolTip: I18n.m.getMessage('absenceEnds'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'ends',
            dataType: 'DateWithTime',
            style: { width: 90 },
        },
        {
            title: I18n.m.getMessage('creator'),
            keyInData: 'creatorName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('absenceNote'),
            keyInData: 'note',
            dataType: 'string',
            style: { width: 200 },
        },
    ];
    if (CompanyUserInfo.me?.payroll) {
        _absenceTableColumn.push({
            title: I18n.m.getMessage('costCenter'),
            keyInData: 'costCenterName',
            dataType: 'string',
            style: { width: 200 },
        }, {
            title: I18n.m.getMessage('project'),
            keyInData: 'projectTitle',
            dataType: 'string',
            style: { width: 200 },
        });
    }
    return _absenceTableColumn;
};
let searchTimer;
export function AbsencesView(props) {
    const [updated, setUpdate] = useState(0);
    const [searchWords, setSearchWords] = useState('');
    const [tableData, setTableData] = useState([]);
    const [allEntries, setAllEntries] = useState([]);
    const [initialFilter, setInitialFilter] = useState(new AbsenceFilter());
    const searchNow = (t) => {
        initialFilter.words = t;
        const f = JSON.stringify(initialFilter);
        Routing.instance.changeQueryParameter({ fab: f }, true);
    };
    const searchAbsences = (t) => {
        setSearchWords(t);
        if (searchTimer != null) {
            clearTimeout(searchTimer);
        }
        searchTimer = window.setTimeout(() => searchNow(t), 250);
    };
    useEffect(() => {
        let f = new AbsenceFilter();
        if (props.fab != null) {
            try {
                f = JSON.parse(props.fab);
                SimpleStorage.set(`${CurrentUser.userId}_saved_absencesfilter`, props.fab);
            }
            catch (e) {
                console.debug('cant read AbsenseFilter', e);
            }
        }
        setInitialFilter(f);
        if (f.words != null && f.words.length > 0)
            setSearchWords(f.words);
        else
            setSearchWords('');
    }, [props.fab]);
    useEffect(() => {
        let isMounted = true;
        UpmeshClient.instance.modals.absence
            .get({ filter: 'deleted ne true' })
            .then((d) => {
            const promises = [];
            for (const a of d) {
                promises.push(AbsenceExtendedEntity.convert(a, !!CompanyUserInfo.me?.payroll));
            }
            return Promise.all(promises);
        })
            .then((data) => {
            if (isMounted) {
                setAllEntries(data);
                const filtered = AbsenceFilter.filter(data, initialFilter);
                setTableData(filtered);
            }
        })
            .catch((err) => console.error(err));
        return () => {
            isMounted = false;
        };
    }, [initialFilter, updated]);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'AbsenceView',
            readModelName: 'Absence',
            callback: (_en) => {
                setUpdate(new Date().getTime());
            },
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('Absence', 'AbsenceView');
        };
    }, []);
    useEffect(() => {
        if (CompanyUserInfo.me?.absenceFor !== 'nobody') {
            Fab.instance?.open({
                fabIcon: 'plus',
                fabIconMoon: false,
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                onPressFab: openAbsenceCreationChangeDialog({}),
                title: I18n.m.getMessage('add'),
                extraPaddingBottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
            });
            return () => {
                Fab.instance?.close();
            };
        }
        return undefined;
    }, []);
    const sDisplay = !(ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM);
    const editItem = (item) => (_e) => {
        openAbsenceCreationChangeDialog({ absence: new AbsenceEntity(item.orgEntry) })(_e);
    };
    const deleteItem = (item) => (_e) => {
        Routing.instance.alert.post({
            text: I18n.m.getMessage('absenceDeleteQuestion'),
            buttons: [
                <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={() => {
                        Alert.instance?.close();
                        const e = new DeleteAbsence({}, item.id);
                        e.execute(AuthClient.instance?.commandStore)
                            .then(() => {
                            setUpdate(new Date().getTime());
                        })
                            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                    }} backgroundColor={ThemeManager.style.brandDanger}/>,
            ],
        });
    };
    const isFilterActive = initialFilter != null &&
        (initialFilter.dateTo != null ||
            (initialFilter.state != null && initialFilter.state.length > 0) ||
            initialFilter.date != null ||
            (initialFilter.userIds != null && initialFilter.userIds.length > 0) ||
            (initialFilter.projectIds != null && initialFilter.projectIds.length > 0) ||
            (initialFilter.costCenterIds != null && initialFilter.costCenterIds.length > 0) ||
            (initialFilter.taskIds != null && initialFilter.taskIds.length > 0));
    let filterIcon = (<Icon icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={openAbsenceFilterDialog({
            all: allEntries,
            initialFilter,
        })} color={isFilterActive ? ThemeManager.style.brandWarning : 'white'} key="filter-outline"/>);
    if (isFilterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
        filterIcon = (<View key={`filterButtonBigOuter${isFilterActive}`}>
        <ContainedButton title={I18n.m.getMessage('filterChange')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={openAbsenceFilterDialog({
                all: allEntries,
                initialFilter,
            })} backgroundColor={ThemeManager.style.brandWarning}/>
      </View>);
    }
    return (<View style={{
            width: '100%',
            backgroundColor: 'transparent',
            position: 'absolute',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
        }}>
      {!sDisplay ? <GlobalBar key="global" user={CurrentUser.entity} size={props.size} site="absences"/> : undefined}
      <PageView showAccountIcon={sDisplay} showMenu={false} style={{
            backgroundColor: 'transparent',
            flexGrow: 1,
            width: props.secondViewWidth,
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        }} headerProps={{
            withElevation: false,
            withBorder: false,
            textColor: '#FFFFFF',
            title: '',
            backgroundColor: 'transparent',
            searchBarProps: {
                backgroundColor: 'transparent',
                textColor: '#ffffff',
                iconColor: '#ffffff',
                hoverColor: ThemeManager.style.getDefaultHoverColor('#ffffff'),
                searchOnChange: searchAbsences,
                tooltip: I18n.m.getMessage('searchProjects'),
                onlyAppBar: true,
                searchBarValue: searchWords,
                searchBarPlaceholder: I18n.m.getMessage('absences'),
            },
            rightButtons: [filterIcon],
        }} secondaryHeader scrollable={false}>
        <View style={{
            width: '100%',
            height: props.secondViewHeight,
            backgroundColor: 'transparent',
            alignItems: 'center',
        }}>
          <Card style={{ height: props.secondViewHeight - 64 }}>
            <Table tableName="AbsenceTableView" maxHeight={props.secondViewHeight - 80} actionItemsLength={2} data={tableData} sortBy="starts" sortDirection="asc" keyExtractor={(item) => `${item.id}_${item.orgEntry.lastModifiedAt}`} columns={absenceTableColumn()} actions={(_item) => [
            { icon: 'pencil-outline', toolTip: I18n.m.getMessage('edit'), onAction: editItem },
            { icon: 'delete-outline', toolTip: I18n.m.getMessage('delete'), onAction: deleteItem },
        ]} emptyTableImage={noTimeTrackings} emptyTableHint={CompanyUserInfo.me?.absenceFor !== 'nobody'
            ? I18n.m.getMessage('absenceNoDataHint')
            : I18n.m.getMessage('absenceNoDataHintNoRights')} emptyTableText={I18n.m.getMessage('absenceNoDataText')}/>
          </Card>
        </View>
      </PageView>
    </View>);
}

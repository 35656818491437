"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var I18n_1 = require("../../../i18n/I18n");
var CompanySettingsEntity_1 = require("./CompanySettingsEntity");
var TaskEntity = function (_ReadModelEntity_1$Re) {
  function TaskEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, TaskEntity);
    _this = _callSuper(this, TaskEntity, [obj]);
    _this.entityName = 'Task';
    _this.deleted = false;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(TaskEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(TaskEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new TaskEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('mark', new OdataEntity_1.OdataIndex(true));
      baseMap.set('description', new OdataEntity_1.OdataIndex(true));
      baseMap.set('productive', new OdataEntity_1.OdataIndex());
      baseMap.set('taskTypeId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('wageType', new OdataEntity_1.OdataIndex());
      baseMap.set('followUpWageType1', new OdataEntity_1.OdataIndex());
      baseMap.set('followUpWageType2', new OdataEntity_1.OdataIndex());
      baseMap.set('active', new OdataEntity_1.OdataIndex(true));
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }, {
    key: "getTaskType",
    value: function () {
      var _getTaskType = (0, _asyncToGenerator2.default)(function* () {
        if (this.taskTypeId == null) {
          var values = {
            lng: CurrentUser_1.CurrentUser.entity != null ? CurrentUser_1.CurrentUser.entity.locale : 'de'
          };
          if (this.productive) return {
            title: I18n_1.I18n.m.getMessage('taskTypeWork', values),
            id: 'work',
            productive: true
          };
          return {
            title: I18n_1.I18n.m.getMessage('taskTypeOther', values),
            id: 'other',
            productive: false
          };
        }
        return CompanySettingsEntity_1.CompanySettingsEntity.getTaskTypeById(this.taskTypeId);
      });
      function getTaskType() {
        return _getTaskType.apply(this, arguments);
      }
      return getTaskType;
    }()
  }, {
    key: "getName",
    value: function getName() {
      if (this.description != null && this.description.length > 0) {
        return `${this.mark} - ${this.description}`;
      }
      return `${this.mark}`;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.TaskEntity = TaskEntity;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadTypes = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var mime = _interopRequireWildcard(require("mime"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _Icon = require("../Icon");
var _CacheableImage = require("../images/CacheableImage");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var UploadTypes = exports.UploadTypes = function () {
  function UploadTypes() {
    (0, _classCallCheck2.default)(this, UploadTypes);
  }
  return (0, _createClass2.default)(UploadTypes, null, [{
    key: "getThumbnailForFileName",
    value: function getThumbnailForFileName(file, onPress, lightboxProps) {
      var panoramaImage = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var bigImage = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var type = mime.getType(file.name);
      if (type === 'image/jpeg' || type === 'image/png') {
        var image = (0, _jsxRuntime.jsx)(_CacheableImage.CacheableImage, {
          onlyOnConnectedServer: false,
          tempFileId: file['id'] != null ? file['id'] : undefined,
          panoramaImage: panoramaImage,
          lightBoxProps: Object.assign({
            group: 'thumb'
          }, lightboxProps),
          localSource: file.preview,
          source: {
            uri: file['id'] != null ? `${file.targetUrl}/${file['id']}` : `${file.targetUrl}`
          },
          style: {
            width: bigImage ? 56 : 40,
            height: bigImage ? 56 : 40
          },
          resizeMode: "contain",
          resizeMethod: "auto",
          width: bigImage ? 56 : 40,
          height: bigImage ? 56 : 40,
          lightBox: lightboxProps != null
        }, file['id'] != null ? file['id'] : file['targetUrl'] != null ? file['targetUrl'] : uuid.v1());
        if (onPress != null) {
          return {
            thumbnail: (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
              onPress: onPress(file),
              style: {
                width: bigImage ? 56 : 40,
                height: bigImage ? 56 : 40
              },
              children: image
            }),
            width: bigImage ? 56 : 40
          };
        }
        return {
          thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: bigImage ? 56 : 40,
              height: bigImage ? 56 : 40
            },
            children: image
          }),
          width: bigImage ? 56 : 40
        };
      }
      var thumbnailSrc = 'file-outline';
      if (type != null) {
        if (type.includes('excel') || type.includes('spreadsheet')) thumbnailSrc = 'file-excel-outline';else if (type.includes('/pdf')) thumbnailSrc = 'file-pdf-box';else if (type.includes('text/plain')) thumbnailSrc = 'file-document-outline';else if (type.includes('msword') || type.includes('wordprocess')) thumbnailSrc = 'file-word-outline';else if (type.includes('mspowerpoint')) thumbnailSrc = 'file-powerpoint-outline';else if (type.includes('image/')) thumbnailSrc = 'file-image-outline';else if (type.includes('video/')) thumbnailSrc = 'file-video-outline';else if (type.includes('audio/')) thumbnailSrc = 'file-music-outline';else if (type.includes('zip') || type.includes('compressed')) thumbnailSrc = 'folder-zip-outline';else if (type.includes('cad') || type.includes('drafting') || type.includes('dxf')) thumbnailSrc = 'file-cad';
      }
      return {
        thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: thumbnailSrc,
          toolTip: "",
          onPress: onPress != null ? onPress(file) : undefined
        }),
        width: bigImage ? 56 : 40,
        rounded: true
      };
    }
  }, {
    key: "getImageForFileName",
    value: function getImageForFileName(file, _onPress, onLongPress, lightboxProps) {
      var panoramaImage = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var width = arguments.length > 5 ? arguments[5] : undefined;
      var height = arguments.length > 6 ? arguments[6] : undefined;
      var type = mime.getType(file.name);
      if (type === 'image/jpeg' || type === 'image/png') {
        var image = (0, _jsxRuntime.jsx)(_CacheableImage.CacheableImage, {
          onlyOnConnectedServer: false,
          tempFileId: file['id'] != null ? file['id'] : undefined,
          panoramaImage: panoramaImage,
          lightBoxProps: Object.assign({
            group: 'thumb'
          }, lightboxProps),
          localSource: file.preview,
          source: {
            uri: file['id'] != null ? `${file.targetUrl}/${file['id']}` : `${file.targetUrl}`
          },
          style: {
            width: width || 40,
            height: height || 40
          },
          resizeMode: "contain",
          resizeMethod: "auto",
          width: width || 40,
          height: height || 40,
          lightBox: lightboxProps != null
        }, file['id'] != null ? file['id'] : file['targetUrl'] != null ? file['targetUrl'] : uuid.v1());
        if (_onPress != null) {
          return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            onPress: function onPress() {
              return _onPress(file);
            },
            onLongPress: onLongPress ? function () {
              return onLongPress(file);
            } : undefined,
            style: {
              width: width || 40,
              height: height || 40
            },
            children: image
          }, `file${file.id}`);
        }
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: width || 40,
            height: height || 40
          },
          children: image
        }, `file${file.id}`);
      }
      var thumbnailSrc = 'file-outline';
      if (type != null) {
        if (type.includes('excel') || type.includes('spreadsheet')) thumbnailSrc = 'file-excel-outline';else if (type.includes('/pdf')) thumbnailSrc = 'file-pdf-box';else if (type.includes('text/plain')) thumbnailSrc = 'file-document-outline';else if (type.includes('msword') || type.includes('wordprocess')) thumbnailSrc = 'file-word-outline';else if (type.includes('mspowerpoint')) thumbnailSrc = 'file-powerpoint-outline';else if (type.includes('image/')) thumbnailSrc = 'file-image-outline';else if (type.includes('video/')) thumbnailSrc = 'file-video-outline';else if (type.includes('audio/')) thumbnailSrc = 'file-music-outline';else if (type.includes('zip') || type.includes('compressed')) thumbnailSrc = 'folder-zip-outline';else if (type.includes('cad') || type.includes('drafting') || type.includes('dxf')) thumbnailSrc = 'file-cad';
      }
      var outerSize;
      if (height != null) {
        outerSize = height;
        if (width != null && height > width) outerSize = width;
      } else if (width != null) {
        outerSize = width;
      }
      return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
        style: {
          height: height,
          width: width,
          alignItems: 'center',
          alignContent: 'center',
          alignSelf: 'center'
        },
        onPress: _onPress != null ? function () {
          return _onPress(file);
        } : undefined,
        onLongPress: onLongPress != null ? function () {
          return onLongPress(file);
        } : undefined,
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: thumbnailSrc,
          toolTip: "",
          onPress: _onPress != null ? function () {
            return _onPress(file);
          } : undefined,
          outerSize: outerSize,
          iconSize: outerSize != null ? outerSize / 2 : undefined
        })
      }, `file${file.id}`);
    }
  }]);
}();
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SplashScreen = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var SplashScreen = exports.SplashScreen = function () {
  function SplashScreen() {
    (0, _classCallCheck2.default)(this, SplashScreen);
  }
  return (0, _createClass2.default)(SplashScreen, null, [{
    key: "hide",
    value: function hide() {}
  }]);
}();
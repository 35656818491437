export class SharedFiles {
    static get count() {
        if (SharedFiles._files != null && SharedFiles._files.length > 0)
            return SharedFiles._files.length;
        if (SharedFiles._fileIds != null && SharedFiles._fileIds.length > 0)
            return SharedFiles._fileIds.length;
        return 0;
    }
    static get fileIds() {
        return SharedFiles._fileIds;
    }
    static set fileIds(fileIds) {
        SharedFiles._files = null;
        SharedFiles._fileIds = fileIds;
    }
    static get files() {
        return SharedFiles._files;
    }
    static set files(files) {
        SharedFiles._files = files;
        SharedFiles._fileIds = null;
    }
    static get excludeFolders() {
        return SharedFiles._excludeFolders;
    }
    static set excludeFolders(excludeFolders) {
        SharedFiles._excludeFolders = excludeFolders;
    }
}

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _Icon = require("../../theme/components/Icon");
var _I18n = require("../../theme/i18n/I18n");
var _Routing = require("../../theme/routing/Routing");
var _ThemeManager = require("../../theme/ThemeManager");
var _StoredStartPages = require("../../utils/StoredStartPages");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var BackButton = exports.default = function (_PureComponent) {
  function BackButton() {
    var _this;
    (0, _classCallCheck2.default)(this, BackButton);
    _this = _callSuper(this, BackButton, arguments);
    _this.isGoBackAvailable = false;
    _this.goBack = function () {
      if (_this.isGoBackAvailable) {
        _Routing.Routing.instance.goBack();
      } else {
        _Routing.Routing.instance.goTo(`/${_StoredStartPages.StoredStartPages.getStoredStartPage()}`);
      }
    };
    return _this;
  }
  (0, _inherits2.default)(BackButton, _PureComponent);
  return (0, _createClass2.default)(BackButton, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        onAllPlatforms = _this$props.onAllPlatforms,
        color = _this$props.color;
      this.isGoBackAvailable = (_reactNativeWeb.Platform.OS === 'ios' || !!onAllPlatforms) && _Routing.Routing.instance.isGoBackAvailable();
      var icon = this.isGoBackAvailable ? 'arrow-left' : 'home-outline';
      var tooltip = this.isGoBackAvailable ? _I18n.I18n.m.getMessage('back') : _I18n.I18n.m.getMessage('goToHome');
      return (0, _jsxRuntime.jsx)(_Icon.Icon, {
        color: color || _ThemeManager.ThemeManager.style.headerButtonColor,
        onPress: this.goBack,
        toolTip: tooltip,
        icon: icon
      }, "goBack");
    }
  }]);
}(_react.PureComponent);
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateApiToken } from 'upmesh-core/src/client/commands/companies/apitoken/CreateApiToken';
import { RevokeApiToken } from 'upmesh-core/src/client/commands/companies/apitoken/RevokeApiToken';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class UserTokenCard extends PureComponent {
    constructor(props) {
        super(props);
        this.onUpdateTokens = () => {
            this.loadTokens();
        };
        this.tokenRenderer = (item, column) => {
            const additionalTextStyle = item.expired != null ? { textDecorationLine: 'line-through' } : undefined;
            return (<View key={`times_${item.id}_${uuid.v1()}`} style={[
                    {
                        justifyContent: 'center',
                        paddingHorizontal: 16,
                        alignItems: 'center',
                    },
                    column.style,
                ]}>
        <MaterialText centeredBox additionalTextStyle={additionalTextStyle} type={MaterialTextTypes.Body2} numberOfLines={1}>
          {item.token}
        </MaterialText>
      </View>);
        };
        this.createTokenQuestion = () => {
            this.apiNote = '';
            Dialog.instance?.open({
                title: I18n.m.getMessage('apiTokenAddQuestionUser'),
                content: (<FormInputFilled labelText={I18n.m.getMessage('apiTokenAddQuestionNote')} onChangeText={this.onChangeApiNote}/>),
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="cancel" title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage('create')} onPress={this.createToken()}/>,
                ],
                contentPadding: true,
            });
        };
        this.onChangeApiNote = (t) => {
            this.apiNote = t;
        };
        this.createToken = () => () => {
            Dialog.instance?.close(() => {
                const { company } = this.state;
                if (company != null) {
                    const t = new CreateApiToken({ companyToken: false, companyId: company.id, note: this.apiNote });
                    t.execute(ClientStore.commandStore)
                        .then(() => setTimeout(this.loadTokens, 300))
                        .catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
                }
            });
        };
        this.loadTokens = () => {
            const { company } = this.state;
            if (company == null)
                return;
            UpmeshClient.instance.modals.apiToken
                .get({ filter: `createdBy eq '${CurrentUser.userId}' and companyToken ne true` })
                .then((tokens) => {
                if (tokens.length > 0) {
                    const promises = [];
                    tokens.forEach((e) => {
                        promises.push(new Promise((resolve, reject) => {
                            AuthClient.instance.modals.user
                                .getById(e.createdBy)
                                .then((user) => {
                                resolve({ ...e, userName: user.getFullName() });
                            })
                                .catch(reject);
                        }));
                    });
                    return Promise.all(promises);
                }
                return [];
            })
                .then((apiTokens) => {
                this.setState({ apiTokens });
            })
                .catch((err) => console.debug('cant load tokens', err));
        };
        this.revokeTokenQuestion = (token) => () => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('apiTokenRevokeQuestion'),
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton backgroundColor={ThemeManager.style.brandDanger} key="save" title={I18n.m.getMessage('apiTokenRevoke')} onPress={this.revokeToken(token)}/>,
                ],
            });
        };
        this.revokeToken = (token) => () => {
            Alert.instance?.close(() => {
                const { company } = this.state;
                if (company != null) {
                    const t = new RevokeApiToken({ companyId: company.id }, token.id);
                    t.execute(ClientStore.commandStore)
                        .then(() => this.loadTokens)
                        .catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
                }
            });
        };
        this.copyTokenToClipboard = (token) => () => {
            try {
                navigator.clipboard
                    .writeText(token.token)
                    .then(() => {
                    alert(`Copied text: ${token.token}`);
                })
                    .catch((err) => {
                    alert(`cant copy to clipboard: ${err}`);
                });
            }
            catch (e) {
                alert(`cant copy to clipboard: ${e}`);
            }
        };
        this.state = {
            apiTokens: [],
            apiTokenNote: '',
            company: CompanyUserInfo.company,
        };
    }
    componentDidMount() {
        this.loadTokens();
        if (this.state.company)
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'ApiToken',
                callback: this.onUpdateTokens,
                attachKey: 'profileTokens',
            });
    }
    componentWillUnmount() {
        if (this.state.company)
            UpmeshClient.eventDispatcher.detach('ApiToken', 'profileTokens');
    }
    render() {
        const { apiTokens, company } = this.state;
        if (company == null)
            return <View />;
        return (<View style={{ width: '100%' }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue, marginTop: 16 }}>
          <MaterialText>{I18n.m.getMessage('apiTokenTitle')}</MaterialText>
        </View>

        <Card style={{ width: '100%' }}>
          <View style={{ width: '100%' }}>
            {apiTokens.length === 0 ? (<View style={{ padding: 16 }}>
                <MaterialText>{I18n.m.getMessage('apiTokenNotYet')}</MaterialText>
              </View>) : (<Table actionItemsLength={2} tableName="UserApiTokens" actions={(_item) => {
                    const e = [
                        {
                            icon: 'delete',
                            onAction: this.revokeToken,
                            disabled: _item.expired != null,
                            toolTip: I18n.m.getMessage('apiTokenRevoke'),
                        },
                    ];
                    if (Platform.OS === 'web' && _item.expired == null)
                        e.unshift({
                            icon: 'clipboard-text-outline',
                            onAction: this.copyTokenToClipboard,
                            toolTip: I18n.m.getMessage('apiTokenCopyToClipoard'),
                            disabled: false,
                        });
                    return e;
                }} maxHeight={0} data={apiTokens} sortBy="expired" sortDirection="desc" columns={[
                    {
                        title: I18n.m.getMessage('apiToken'),
                        keyInData: 'token',
                        cellRenderer: this.tokenRenderer,
                        style: { width: 200 },
                        sortable: true,
                        dataType: 'string',
                    },
                    {
                        title: I18n.m.getMessage('apiTokenAddQuestionNote'),
                        keyInData: 'note',
                        style: { width: 200 },
                        sortable: true,
                        dataType: 'string',
                    },
                    {
                        title: I18n.m.getMessage('reportTicketCreated'),
                        keyInData: 'createdAt',
                        style: { width: 250 },
                        sortable: true,
                        dataType: 'DateWithTime',
                    },
                    {
                        title: I18n.m.getMessage('apiTokenRevoked'),
                        keyInData: 'expired',
                        style: { width: 200 },
                        sortable: true,
                        dataType: 'DateWithTime',
                    },
                ]}/>)}

            <View key="add" style={{
                width: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue / 4,
                paddingBottom: ThemeManager.style.contentPaddingValue / 4,
                paddingLeft: ThemeManager.style.contentPaddingValue,
                borderWidth: 0,
                borderColor: ThemeManager.style.borderColor,
                borderTopWidth: ThemeManager.style.borderWidth,
            }}>
              <ContainedButton iconPosition="left" icon={{ icon: 'plus' }} onPress={this.createTokenQuestion} full={false} title={I18n.m.getMessage('apiTokenAddText')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            </View>
          </View>
        </Card>
      </View>);
    }
}

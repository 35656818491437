"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoteFileHandler = void 0;
var cross_fetch_1 = require("cross-fetch");
var StdApplicationError_1 = require("../../entities/StdApplicationError");
var ODataFileHandler_1 = require("../../odata/root/ODataFileHandler");
var RemoteFileHandler = function (_ODataFileHandler_1$O) {
  function RemoteFileHandler(url) {
    var _this;
    (0, _classCallCheck2.default)(this, RemoteFileHandler);
    _this = _callSuper(this, RemoteFileHandler);
    _this.url = url;
    return _this;
  }
  (0, _inherits2.default)(RemoteFileHandler, _ODataFileHandler_1$O);
  return (0, _createClass2.default)(RemoteFileHandler, [{
    key: "getFullPathForId",
    value: function getFullPathForId(id, subfolder) {
      if (subfolder != null) {
        return `${this.url}/${id}?sub=${subfolder}`;
      }
      return `${this.url}/${id}`;
    }
  }, {
    key: "getFileById",
    value: function () {
      var _getFileById = (0, _asyncToGenerator2.default)(function* (id, subfolder) {
        var fullPath = this.getFullPathForId(id, subfolder);
        return new ODataFileHandler_1.ODataFile(fullPath, id);
      });
      function getFileById(_x, _x2) {
        return _getFileById.apply(this, arguments);
      }
      return getFileById;
    }()
  }, {
    key: "deleteFileById",
    value: function () {
      var _deleteFileById = (0, _asyncToGenerator2.default)(function* (_id, _subfolder) {
        return false;
      });
      function deleteFileById(_x3, _x4) {
        return _deleteFileById.apply(this, arguments);
      }
      return deleteFileById;
    }()
  }, {
    key: "deleteFolder",
    value: function () {
      var _deleteFolder = (0, _asyncToGenerator2.default)(function* (_subfolder) {
        return false;
      });
      function deleteFolder(_x5) {
        return _deleteFolder.apply(this, arguments);
      }
      return deleteFolder;
    }()
  }, {
    key: "getData",
    value: function () {
      var _getData = (0, _asyncToGenerator2.default)(function* (f) {
        var data;
        data = yield new Promise(function (resolve, reject) {
          try {
            var reader = new FileReader();
            var senddata = {
              name: f.name,
              date: f.lastModifiedDate,
              size: f.size,
              type: f.type,
              fileData: null
            };
            reader.onload = function () {
              var _ref = (0, _asyncToGenerator2.default)(function* (theFileData) {
                var t = theFileData.currentTarget;
                senddata.fileData = t.result;
                data = new FormData();
                data.append('myFile', f, f.name);
                resolve(data);
              });
              return function (_x7) {
                return _ref.apply(this, arguments);
              };
            }();
            reader.readAsDataURL(f);
          } catch (e) {
            reject(e);
          }
        });
        return data;
      });
      function getData(_x6) {
        return _getData.apply(this, arguments);
      }
      return getData;
    }()
  }, {
    key: "saveFileById",
    value: function () {
      var _saveFileById = (0, _asyncToGenerator2.default)(function* (file, id) {
        var fullPath = this.getFullPathForId(id);
        var f = file;
        var data = yield this.getData(f);
        var headers = {};
        headers.Accept = 'application/json';
        var result = yield (0, cross_fetch_1.default)(fullPath, {
          headers: headers,
          method: 'POST',
          body: data
        });
        if (result == null) {
          throw StdApplicationError_1.StdApplicationError.badRequest('no result');
        }
        if (result.status === 200) {
          return new ODataFileHandler_1.ODataFile(fullPath, id);
        }
        throw StdApplicationError_1.StdApplicationError.badRequest();
      });
      function saveFileById(_x8, _x9) {
        return _saveFileById.apply(this, arguments);
      }
      return saveFileById;
    }()
  }]);
}(ODataFileHandler_1.ODataFileHandler);
exports.RemoteFileHandler = RemoteFileHandler;
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OpacityAnimated = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _BaseAnimated2 = require("./BaseAnimated");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var OpacityAnimated = exports.OpacityAnimated = function (_BaseAnimated) {
  function OpacityAnimated(props) {
    var _this;
    (0, _classCallCheck2.default)(this, OpacityAnimated);
    _this = _callSuper(this, OpacityAnimated, [props]);
    _this.state = Object.assign({}, _this.state, {
      fromOpacity: props.from,
      toOpacity: props.to
    });
    return _this;
  }
  (0, _inherits2.default)(OpacityAnimated, _BaseAnimated);
  return (0, _createClass2.default)(OpacityAnimated, [{
    key: "getAnimated",
    value: function getAnimated() {
      var opacity = this.animationState.interpolate({
        inputRange: [0, 1],
        outputRange: this.props.inverted === true ? [this.state.toOpacity, this.state.fromOpacity] : [this.state.fromOpacity, this.state.toOpacity]
      });
      return {
        opacity: opacity
      };
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.to !== prevState.toOpacity || nextProps.from !== prevState.fromOpacity || nextProps.animationState !== prevState.animationState) {
        return {
          toOpacity: nextProps.to,
          fromOpacity: nextProps.from,
          animationState: nextProps.animationState
        };
      }
      return null;
    }
  }]);
}(_BaseAnimated2.BaseAnimated);